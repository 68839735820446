import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import {Button, Form, Input, message, PageHeader} from "antd";
import ApiServer from "../../../../ApiServer";
import "./index.less"

interface ReloadReflexSystemModel extends WithTranslation {
    apiServer: ApiServer
}

interface ReloadReflexSystemState {

}

class ReloadReflexSystem extends React.Component<ReloadReflexSystemModel, ReloadReflexSystemState> {

    onFinish = (values: any) => {

        this.reloadReflexSystem(values.containerNumber)
    }

    reloadReflexSystem = (containerNumber: string) => {
        this.props.apiServer.apiSystem.postReflexSystemReload(containerNumber).then(r => {
            if (r.data.data && JSON.parse(r.data.data)["message"] === "OK") {
                message.success(this.props.t("_reload-reflex-system.reload-success"))
            } else {
                message.error(this.props.t("_reload-reflex-system.reload-fail"))
            }
        })
    }

    render() {
        return (
            <PageHeader
                title={this.props.t("reload-reflex-system")}
                subTitle={this.props.t("_reload-reflex-system.sub-title")}
            >
                <div className={"reload-operation-container"}>
                    <Form layout={"inline"} onFinish={this.onFinish}>
                        <Form.Item name={"containerNumber"} rules={[{
                            required: true,
                            message: this.props.t("_reload-reflex-system.input-docker-number")
                        }]}>
                            <Input placeholder={this.props.t("_reload-reflex-system.input-docker-number")}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type={"primary"}
                                    htmlType={"submit"}>{this.props.t("_reload-reflex-system.reload")}</Button>
                        </Form.Item>
                    </Form>
                </div>
            </PageHeader>
        )
    }
}

export default withTranslation()(ReloadReflexSystem)

