import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { PageHeader } from "antd";
import ApiServer from "../../../../ApiServer";
import { projectMetaDataColumnProvider, ProjectMetaDataModel } from "../../@mod";
import WorkBenchTable from "../../../../components/table/workbench-table";
import { ActionEventHandler } from "../../../../components";
import { ProjectStatusDto } from "../../../../api";

export interface MarketViewModel extends WithTranslation {
    apiServer: ApiServer
    projectList: ProjectMetaDataModel[]
    headerTitle: string
    headerSubTitle: string
    genHeaderExtra: () => React.ReactNode
    onDetail: (model: ProjectMetaDataModel) => void
    genSearchBar: () => React.ReactNode
}

interface ServiceListViewState {
    projectLoading: boolean,
    projectStatusList: ProjectStatusDto[]
}

class ProjectListView extends React.Component<MarketViewModel, ServiceListViewState> {
    constructor(props: MarketViewModel) {
        super(props)
        this.state = {
            projectLoading: false,
            projectStatusList: []
        };
    }


    componentDidMount() {
        this.loadStatus()
    }

    loadStatus = () => {
        this.props.apiServer.apiProjectMeta.getProjectStatusMetaData().then(r => {
            if (r.data.data?.dtoList) {
                this.setState({
                    projectStatusList: r.data.data?.dtoList
                })
            }
        })
    }

    render(): React.ReactNode {
        return <div className={"project-market"}>
            <PageHeader title={this.props.headerTitle} subTitle={this.props.headerSubTitle} extra={this.props.genHeaderExtra()}>
                {this.props.genSearchBar()}
                <WorkBenchTable<ProjectMetaDataModel>
                    data={this.props.projectList}
                    rowKey={(record => record.id)}
                    dataLoading={this.state.projectLoading}
                    columnProvider={(a: ActionEventHandler<ProjectMetaDataModel>) => projectMetaDataColumnProvider(this.props, this.state.projectStatusList, a)}
                    onDetail={this.props.onDetail}
                />
            </PageHeader>
        </div>;
    }
}

export default withTranslation()(ProjectListView);