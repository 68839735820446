import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { ActionEventHandler } from "../../../../../../components";
import ColumnTextFilter from "../../../../../../components/table/column-text-filter";
import TicketTimeLine from "../ticket-time-line";

export interface TicketTimeModel {
    ticketId :number,
    creator : string,
    expectClose:number,
    createAt:number,
    closeAt:number,
    records : ThreadTimeModel[],
    creatorId:number

}

export interface ThreadTimeModel {
    start: number,
    end: number
}



export const timeThreadColumnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<TicketTimeModel>): ColumnType<TicketTimeModel>[] => {
	

	return [
		{
			title: translater("statistics-tickets.ticket-number"),
			dataIndex: 'ticketId',
			key: 'ticketId',
			render: (text: string,record:TicketTimeModel) => <Button type="link" onClick={()=>handler(record,"detail")}>#{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:TicketTimeModel) => record.ticketId===value,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.name")}/>)
			},
			
		},
		{
			title: translater("statistics-tickets.created-at"),
			dataIndex: 'createAt',
			key: 'createAt',
			filterSearch: true,
            render :(v:string,record:TicketTimeModel)=>{
                return moment(v).format(translater("tickets.time-pattern-short"))
            }
		},{
			title: translater("statistics-tickets.expect-close-at"),
			dataIndex: 'expectClose',
			key: 'expectClose',
			filterSearch: true,
            render :(v:string,record:TicketTimeModel)=>{
                return moment(v).format(translater("tickets.time-pattern-short"))
            }
        },{
			title: translater("statistics-tickets.close-at"),
			dataIndex: 'closeAt',
			key: 'closeAt',
			filterSearch: true,
            render :(v:string,record:TicketTimeModel)=>{
                return moment(v).format(translater("tickets.time-pattern-short"))
            }
        },{
			title: translater("statistics-tickets.creator"),
			dataIndex: 'creator',
			key: 'creator',
			filterSearch: true,
            onFilter: (value:string|number|boolean, record:TicketTimeModel) => record.ticketId===value,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("statistics-tickets.creator")}/>)
			}
        },{
            title: translater("statistics-tickets.cost"),
			dataIndex: 'cost',
			key: 'cost',
            render:(v:string,record:TicketTimeModel) =>{
                return Math.round((record.closeAt -record.createAt)/1000/60)
            }
        },{
            title: translater("statistics-tickets.effe"),
			dataIndex: 'effe',
			key: 'effe',
            render:(v:string,record:TicketTimeModel) =>{
                let effec = record.expectClose -record.closeAt;
                if(effec < 0){
                    return (<div style={{color:"red"}} >
                        {translater("statistics-tickets.delay")+Math.abs(Math.round(effec/1000/60))+translater("statistics-tickets.completed")}</div>)
                }else{
                    return (<div style={{color:"green"}} >{translater("statistics-tickets.bring-forward")+Math.round(effec/1000/60)+translater("statistics-tickets.completed")}</div>)
                }
            }
        },{
            title: translater("statistics-tickets.time-series"),
			dataIndex: 'effe',
            width:"40%",
			key: 'effe',
            render:(v:string,record:TicketTimeModel) =>{

                if(record.closeAt< record.expectClose) {
                return (
                    <TicketTimeLine ticketTimeModel={record}/>
                )
                }else{
                    return (
                        <TicketTimeLine ticketTimeModel={record}/>
                    )
                }
            }
        }

		
	];
}