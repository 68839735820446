import { Button, Col, Divider, Input, notification, Row, Select, Tag, Tooltip } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MailBoxModel, MailVendorModel, RemoteInstanceModel, TagModel } from "../../@mod";
import './index.less';
import { SaveOutlined, InfoCircleOutlined,CheckCircleOutlined } from "@ant-design/icons";

export interface EmailConfigureEditorViewModel extends WithTranslation {
    instances: RemoteInstanceModel[],
    vendors: MailVendorModel[]
    mailBoxModel: MailBoxModel,
    tags: TagModel[],
    onSave?: (depolyLocation: string[], emailAddress: string, password: string, tags: string[], mailBoxVendor: string, instanceId: number) => void,
}

interface EmailConfigureEditorViewState {
    depolyLocation: string[],
    emailAddress: string,
    password: string,
    passwordConfirm: string
    tags: string[],
    mailBoxVendor: string
}

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const style: React.CSSProperties = { padding: '8px 0' };

class EmailConfigureEditor extends React.Component<EmailConfigureEditorViewModel, EmailConfigureEditorViewState>{
    constructor(props: EmailConfigureEditorViewModel) {
        super(props)
        this.state = {
            depolyLocation: this.props.mailBoxModel.deployTo,
            emailAddress: this.props.mailBoxModel.emailAddress,
            password: this.props.mailBoxModel.cred,
            passwordConfirm: this.props.mailBoxModel.cred,
            tags: this.props.mailBoxModel.tags?.map(y => String(y.id)) ?? [],
            mailBoxVendor: String(this.props.mailBoxModel.vendor?.id ?? this.props.vendors[0].id),
            
        }
    }




    renderDeploySelector = () => {
        return this.props.instances.map(x => {
            return (
                <Select.Option key={x.id} label={x.name}>
                    {x.name} - {x.identifier} : {this.props.t("mails.conf.publicIp")} [{x.publicIp}]
                </Select.Option>
            )
        });
    }

    renderVendorSelector = () => {
        return this.props.vendors.map(x => {
            return (
                <Select.Option key={x.id} label={x.vendorName} >
                    {x.vendorName} : [{x.receiveProtocol} - {x.sendProtocol}]
                </Select.Option>
            )
        });
    }
    renderTagsSelector = () => {
        return this.props.tags.map(x => {
            return (
                <Select.Option key={x.id} label={x.name} >
                    <Tag color={x.color} >{x.name}</Tag>
                </Select.Option>
            )
        });

    }

    confirm = () => {
        if (this.validateStatus() !== "error" && this.validatePasswordStatus() !== "error") {
            if (this.props.onSave) {
                this.props.onSave(
                    this.state.depolyLocation,
                    this.state.emailAddress,
                    this.state.password,
                    this.state.tags,
                    this.state.mailBoxVendor,
                    this.props.mailBoxModel.id
                )
            }
        } else {
            notification.error({ message: this.props.t("mails.conf.input-validation-fail") })
        }

    }

    onchange = (t) => {
        this.setState({ emailAddress: t.target.value });
    }

    validateStatus = () => {
        return !emailPattern.test(this.state.emailAddress) ? "error" : ""

    }

    validatePasswordStatus = () => {
        return (this.state.password!=="" &&  this.state.password === this.state.passwordConfirm) ? "" : "error";
    }




    render(): React.ReactNode {
        return (
            <div className="email-config-editor">
                <Divider orientation="left">{this.props.t("mails.conf.basic-conf")}</Divider>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <div className="item-label">{this.props.t("mails.conf.email-address")}:</div>
                    </Col>
                    <Col className="gutter-row" span={12} >
                        {this.props.mailBoxModel.id === 0 && <Input type={"text"} placeholder="exmaple@example.com" required
                            status={this.validateStatus()}
                            value={this.state.emailAddress}
                            onChange={this.onchange}
                            suffix={
                                <Tooltip title={this.props.t("mails.conf.email-address-info")}>
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />}
                        {this.props.mailBoxModel.id !== 0 && this.props.mailBoxModel.emailAddress}
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="required-mark">*</div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <div className="item-label">{this.props.t("mails.conf.email-provider")}:</div>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Select style={{ width: "100%", textAlign: "left" }} value={this.state.mailBoxVendor} onChange={(x) => {
                            this.setState({ mailBoxVendor: x })
                            console.log(x);
                        }}>
                            {this.renderVendorSelector()}
                        </Select>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="required-mark">*</div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <div className="item-label">{this.props.t("mails.conf.login-password")}:</div>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input type={"password"} required
                            value={this.state.password}
                            status={this.validatePasswordStatus()}
                            onChange={(v) => { this.setState({ password: v.target.value }) }}
                            suffix={
                                <Tooltip title={this.props.t("mails.conf.email-password-info")}>
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="required-mark">*</div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <div className="item-label">{this.props.t("mails.conf.confirm-password")}:</div>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Input type={"password"} required
                            value={this.state.passwordConfirm}
                            status={this.validatePasswordStatus()}
                            onChange={(v) => { this.setState({ passwordConfirm: v.target.value }) }}
                            suffix={
                                <Tooltip title={this.props.t("mails.conf.email-password-confirm-info")}>
                                    <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </Tooltip>
                            }
                        />
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <div className="required-mark">*</div>
                    </Col>
                </Row>
                <Divider orientation="left">{this.props.t("mails.conf.deploy-conf")}</Divider>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <div className="item-label">{this.props.t("mails.conf.deploy-location")}:</div>
                    </Col>
                    <Col className="gutter-row" span={12} >
                        <Select style={{ width: "100%" }} mode="multiple"
                            defaultValue={this.state.depolyLocation}
                            allowClear
                            onChange={x => {
                                console.log(x)
                                this.setState({
                                    depolyLocation: x
                                })
                            }}
                        >
                            {this.renderDeploySelector()}
                        </Select>
                    </Col>
                    <Col className="gutter-row" span={12}>

                    </Col>
                </Row>
                <Divider orientation="left">{this.props.t("mails.conf.info-conf")}</Divider>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <div className="item-label">{this.props.t("mails.conf.tags")}:</div>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Select style={{ width: "100%" }} mode="multiple" defaultValue={this.state.tags} onChange={v => {
                            this.setState({ tags: v })
                        }}>
                            {this.renderTagsSelector()}
                        </Select>
                    </Col>
                    <Col className="gutter-row" span={12}>

                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>

                    </Col>
                    <Col className="gutter-row" span={12}>
                        <div className="button-field">
                            <Button onClick={this.confirm} icon={<SaveOutlined />} type="primary">{this.props.t("mails.conf.save")}</Button>
                            <Button type="default" danger> {this.props.t("mails.conf.reset")}</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withTranslation()(EmailConfigureEditor);
