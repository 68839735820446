import { Button, Drawer, notification, PageHeader, Space, Switch } from "antd";
import moment from "moment-timezone";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import WorkBenchTable from "../../../../components/table/workbench-table";
import { ContentSchemaModel, loadAllTags, loadContentSchema, loadMetaData, loadUserGroupModels, loadUsers, MetaDataSource, TagModel, UserGroupModel } from "../../@mod";
import { loadTicketTemplate, NewTicketTemplateRecord, TemplateInstance, ticketNewTemplateColumn } from "./@mod";
import TemplateEditor from "./template-editor";


export interface NewTicketTemplateViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface NewTicketTemplateState {
    isLoading: boolean
    metaSource: MetaDataSource,
    showTemplateEditor: boolean,
    editingRecord: NewTicketTemplateRecord,
    allTags: TagModel[],
    records:NewTicketTemplateRecord[]
    schemas:ContentSchemaModel[]
    userGroups:UserGroupModel[]

}


class NewTicketTemplate extends React.Component<NewTicketTemplateViewModel, NewTicketTemplateState>{

    constructor(props: NewTicketTemplateViewModel) {
        super(props)
        this.state = {
            isLoading: false,
            showTemplateEditor: false,
            metaSource: {
                ticketSource: [],
                ticketStatus: [],
                ticketPriority: [],
                ticketType: [],
            },
            editingRecord: this.defaultEditTemplate,
            allTags: [],
            records: [],
            schemas: [],
            userGroups:[]
        }
    }
    get defaultEditTemplate(): NewTicketTemplateRecord {
        let instance: TemplateInstance = {
            title: this.props.t("new-ticket-template.new-ticket-title"),
            subtitle: this.props.t("new-ticket-template.new-ticket-subtitle"),
            timelimit: 60,
            type: 1,
            status: 1,
            priority: 1,
            source: 1,
            assignedGroup: 1,
            assignedUser: 1,
            tags: [],
            contentSchema:[],
            defaultContent:""
        }

        return {
            id: 0,
            title: this.props.t("new-ticket-template.new-title"),
            created: moment.now().toString(),
            updated: moment.now().toString(),
            template: instance,
            enabled: true
        }
    }



    loadData = async () => {
        this.setState({
            isLoading:true
        })

        let metas = await loadMetaData(this.props.apiServer);
        let tags = await loadAllTags(this.props.apiServer);
        let templates = await loadTicketTemplate(this.props.apiServer);
        let contentSchemas = await loadContentSchema(this.props.apiServer);
        let userGroups = await loadUserGroupModels(this.props.apiServer);

        this.setState({
            metaSource: metas,
            allTags: tags ?? [],
            isLoading:false,
            records: templates,
            schemas:contentSchemas,
            userGroups:userGroups
        })


    }

    l: Promise<void> | undefined;

    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData().finally(() => {
                this.l = undefined;
            });
        }
    }

    


    saveTemplate = () => {
        if (this.state.editingRecord.id === 0) {
            this.props.apiServer.apiProductivity.postTicketTemplate(
                {
                    title: this.state.editingRecord.title,
                    content: JSON.stringify(this.state.editingRecord.template)
                }
            ).then(x => {
                if (x.data.data?.success) {
                    notification.success({ message: this.props.t("new-ticket-template.new-ticket-template-create-succeed") })
                    this.loadData()
                } else {
                    notification.warn({ message: this.props.t("new-ticket-template.new-ticket-template-create-fail") })
                }
                this.setState({
                    showTemplateEditor:false
                })
            })

        } else {

            this.props.apiServer.apiProductivity.putTicketTemplate(
                this.state.editingRecord.id,
                {
                    title: this.state.editingRecord.title,
                    content: JSON.stringify(this.state.editingRecord.template)
                }
            ).then(x=>{
                if (x.data.data?.success) {
                    notification.success({ message: this.props.t("new-ticket-template.new-ticket-template-update-succeed") })
                    this.loadData()
                } else {
                    notification.warn({ message: this.props.t("new-ticket-template.new-ticket-template-update-fail") })
                }

                this.setState({
                    showTemplateEditor:false
                })
            })
        }

    }

    newTemplate = () => {
        this.setState({
            showTemplateEditor: true,
            editingRecord: this.defaultEditTemplate
        })
    }
    editTemplate = (r:NewTicketTemplateRecord)=>{
        this.setState({
            showTemplateEditor: true,
            editingRecord: r
        })
    }

    usableChanged= (v)=>{
        if(v){
            this.props.apiServer.apiProductivity.putTicketTemplateEnabled(this.state.editingRecord.id)
            .then(x=>{
                if(x.data.data?.success){
                    let record=this.state.editingRecord;
                    record.enabled = true
                    this.setState(this.state);
                }
            })
        }else{
            this.props.apiServer.apiProductivity.putTicketTemplateDisabled(this.state.editingRecord.id)
            .then(x=>{
                if(x.data.data?.success){
                    let record=this.state.editingRecord;
                    record.enabled = false
                }
                this.setState(this.state);
            })
        }
        

    }

    loadUser= (userGroupId:number)=>{
        return loadUsers(this.props.apiServer,userGroupId);
    }


    render(): React.ReactNode {
        return (<div>
            <PageHeader
                title={this.props.t("ticket-new-template")}
                subTitle={this.props.t("ticket-new-template-desc")}
                extra={
                    <Button type="primary" key={"1"} onClick={this.newTemplate} >{this.props.t("create-ticket-new-template")}</Button>
                }>
                <WorkBenchTable<NewTicketTemplateRecord>
                    onDetail={
                        this.editTemplate
                    }
                    data={this.state.records}
                    dataLoading={false}
                    columnProvider={(handler) => ticketNewTemplateColumn(this.props.t, handler)
                    } />
            </PageHeader>
            <Drawer visible={this.state.showTemplateEditor}
                width={8 * 60}
                destroyOnClose
                onClose={() => this.setState({ showTemplateEditor: false })}
                bodyStyle={{ paddingBottom: 80 }}
                extra={
                    <Space>
                        {
                            this.state.editingRecord.id!=0 &&
                            <Switch style={{width:60}} checkedChildren={this.props.t("new-ticket-template.enabled")} unCheckedChildren={this.props.t("new-ticket-template.disabled")} checked={this.state.editingRecord.enabled} 
                            onChange={this.usableChanged}
                            />
                        }
                        <Button onClick={() => this.setState({ showTemplateEditor: false })}
                            key="cancle">
                            {this.props.t("new-ticket-template.cancle")}
                        </Button>
                        <Button onClick={this.saveTemplate} type="primary" key="save">
                            {this.props.t("new-ticket-template.save")}
                        </Button>
      
                    </Space>
                }>
                <TemplateEditor
                    metaSource={this.state.metaSource}
                    record={this.state.editingRecord}
                    allTags={this.state.allTags}
                    schemas={this.state.schemas}
                    userGroups={this.state.userGroups}
                    loadUser={this.loadUser}
                />
            </Drawer>
        </div>)
    }
}



export default withTranslation()(NewTicketTemplate);