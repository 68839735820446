import React from "react";
import ApiServer from "../../../../ApiServer";



export interface ActionModel {
    name:string,
    display:any,
    parameters:any
}



export const fetchEscalationActions =async (apiServer:ApiServer) : Promise<ActionModel[]> => {

    return apiServer.apiSystem.getEscalationActions()
    .then(x=>{
        return x.data.data?.actions?.map(y=>{
            var actionModel :ActionModel= {
                name:y.name??"",
                display:y.display,
                parameters:y.parameters
            }
            return actionModel
        })||[]
    });
    
}
