import { Button, Input, notification, Result, Steps } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { SmallDashOutlined, SmileOutlined, ToolOutlined, ClockCircleOutlined } from '@ant-design/icons';
import ApiServer from "../../../../../ApiServer";
import { RouteContext, withRouter } from "../../../../../utils";
import './index.less';
import ProcessInitial from "../process-initial";
import ProcessConfigure from "../process-configure";
import TimeShiftEditor from "../time-shift-editor";

const { Step } = Steps

export interface ProcessEditorViewModel extends WithTranslation {
	router?: RouteContext
	apiServer: ApiServer
}

interface ProcessEditorState {
	id: number
	currentStep: number,
	processData: any,
	status: "info" | "success" | "error",
	message: string
}



class ProcessEditor extends React.Component<ProcessEditorViewModel, ProcessEditorState>{

	constructor(props: ProcessEditorViewModel) {
		super(props)

		this.state = {
			id: Number(this.props.router?.params["id"]),
			currentStep: 0,
			processData: {
				"flows": [],
				"forms": [],
				"approval": [],
				"assignee":[],
				"data": [],
				"escalations": [],
				"totalSLA": 1440,
				"name": "",
				"description": ""

			},
			message: "",
			status: "info"
		}
	}

	saveProcess = async () => {


		if (this.state.processData.name === "") {
			notification.error({ message: this.props.t("auto.please-enter-name") });
			return;
		}
		this.setState({
			message: this.props.t("auto.verifying")
		})
		let verified = await this.props.apiServer.apiProductivity.verifyProcessRequest({
			processData: JSON.stringify(this.state.processData)
		}).then(
			x => {
				if (!x.data.data?.succeed) {
					x.data.data?.errorMessage?.forEach(y => {
						notification.error({ message: y })
					})
					this.setState({
						message: this.props.t("auto.verify-fail"),
						status: "error"
					})
				} else {
					this.setState({
						message: this.props.t("auto.verify-succeed"),
						status: "success"
					})
					console.log(x.data.data.inputSchema);

				}
				return x.data.data?.succeed
			}
		)
		if (verified) {
			if (this.state.id === 0) {
				await this.props.apiServer.apiProductivity.putNewProcess({
					name: this.state.processData.name,
					desc: this.state.processData.description,
					processTemplate: JSON.stringify(this.state.processData)
				}).then(x => {
					if (x.data.data?.succeed) {
						this.setState({
							message: this.props.t("auto.save-succeed"),
							status: "success"
						})
						notification.success({ message: this.props.t("auto.save-template-succeed") });
						this.props.router?.navigate("/system/productivity/" + x.data.data.id + "/process");
					}
				})
			} else {
				await this.props.apiServer.apiProductivity.postProcess(this.state.id, {
					processTemplate: JSON.stringify(this.state.processData),
					name: this.state.processData.name,
					desc: this.state.processData.description,
				}).then(x => {
					if (x.data.data?.succeed) {
						this.setState({
							message: this.props.t("auto.save-succeed"),
							status: "success"
						})
						notification.success({ message: this.props.t("auto.save-template-succeed") });
					}
				})
			}
		}

	}

	getSnapshotBeforeUpdate(preProps: ProcessEditorViewModel, state: any) {
		if (this.state.id !== Number(preProps.router?.params["id"])) {
			this.setState({
				id: Number(preProps.router?.params["id"])
			});
		}
		return null;
	}

	componentDidUpdate() {


	}
	loadData = async () => {
		await this.props.apiServer.apiProductivity.getProcess(this.state.id)
			.then(x => {
				if (x.data.data?.succeed) {
					let template = JSON.parse(x.data.data.dto?.processTemplate ?? "{}");
					console.log(template)
					this.setState({
						processData: template
					})
				}
			})

	}

	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => {
					this.l = undefined;
				});

		}

	}
	flushState = () => {
		this.setState({ processData: this.state.processData })
	}

	goPrev = () => {
		this.setState({
			currentStep: this.state.currentStep - 1,
			"message": "",
			"status": "info"
		})
		this.flushState()
	}

	setProcessName = (v) => {
		let processData = this.state.processData;
		processData.name = v.target.value;
		this.setState({
			processData: processData
		})
	}

	setProcessDescription = (v) => {
		let processData = this.state.processData;
		processData.description = v.target.value;
		this.setState({
			processData: processData
		})
	}

	getCompletedEditor = () => {
		return (
			<div>
				<div>
					{this.state.message}
				</div>
				<div className="summary">
					<div className="processCompleted">
						<div>
							{this.props.t("auto.process-name")}
						</div>
						<div>
							<Input value={this.state.processData.name}
								onChange={this.setProcessName}
								style={{ minWidth: 250 }}
							/>
						</div>
					</div>
					<div className="processCompleted">
						<div>
							{this.props.t("auto.process-description")}
						</div>
						<div>
							<Input value={this.state.processData.description}
								onChange={this.setProcessDescription}
								style={{ minWidth: 250 }}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
	goList = ()=>{
		this.props.router?.navigate("/system/productivity/automation")
	}

	render(): React.ReactNode {
		return (
			<div className="process-editor">
				<div className="editor-title">
					{this.props.t("auto.process-editor-title")}
				</div>
				<div className="editor-step">
					<Steps current={this.state.currentStep}>
						<Step title={this.props.t("auto.process-def")} icon={<SmallDashOutlined />} />
						<Step title={this.props.t("auto.process-config")} icon={<ToolOutlined />} />
						<Step title={this.props.t("auto.time-shift-config")} icon={<ClockCircleOutlined />} />
						<Step title={this.props.t("auto.done")} icon={<SmileOutlined />} />
					</Steps>
				</div>
				<div className="editor-step-detail">
					{
						this.state.currentStep === 0 &&
						<ProcessInitial initialStage={this.state.processData.flows} />
					}
					{
						this.state.currentStep === 1 &&
						<ProcessConfigure apiServer={this.props.apiServer} process={this.state.processData} />
					}
					{
						this.state.currentStep === 2 &&
						<TimeShiftEditor refObject={this.state.processData} escalations={this.state.processData.escalations}></TimeShiftEditor>
					}
					{
						this.state.currentStep === 3 &&
						<Result
							status={this.state.status}
							title={this.getCompletedEditor()}
							extra={[
								<Button type="primary" onClick={this.saveProcess} >{this.props.t("auto.save-process")}</Button>,
								<Button onClick={this.goPrev} >{this.props.t("auto.back-to-edit")}</Button>,
								<Button danger onClick={this.goList} >{this.props.t("auto.back-to-list")}</Button>
							]}
						/>
					}

				</div>
				<div className="editor-step-control">
					{
						this.state.currentStep !== 3 &&
						<Button type="primary" onClick={() => {
							this.flushState();
							this.setState({ currentStep: this.state.currentStep + 1 })
						}}  >{this.props.t("auto.next")}</Button>
					}
					{
						(this.state.currentStep !== 0 && this.state.currentStep !== 3) &&
						<Button type="default" onClick={this.goPrev}>{this.props.t("auto.prev")}</Button>
					}
				</div>
			</div>);
	}
}

export default withRouter(withTranslation()(ProcessEditor));
