import moment from "moment-timezone";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { DataPoint } from "../../../../components/charts/@mod";
import { DataModel } from "../../@mod";
import './index.less';
import { Statistic } from "antd";
import LineChart from "../../../../components/charts/line-chart";
import AreaChart from "../../../../components/charts/area-chart";



export interface HistorySummaryViewModel extends WithTranslation {
    summaryDataPoint: DataPoint;
    apiServer: ApiServer
    name: string

}

interface HistorySummaryViewState {
    historyData: DataModel[]
}



class HistorySummary extends React.Component<HistorySummaryViewModel, HistorySummaryViewState>{

    

    constructor(props: HistorySummaryViewModel) {
        super(props)
        this.state ={
            historyData:[]
        }
    }

    loadData = async () => {
        await this.props.apiServer.apiStatistic.getHistoryStatisticsData(
            moment().add(-30, "days").format('yyyy-MM-DD HH:mm:ss').toString(),
            moment().format('yyyy-MM-DD HH:mm:ss').toString(),
            this.props.summaryDataPoint.type
        ).then(x => {
            var data = x.data.data?.dataValues?.map(x => {
                let data: DataModel = {
                    name: moment.parseZone(x.dataDate).format("yyyy-MM-DD"),
                    value: x.value ?? 0,
                    description: x.description
                }
                return data;
            }) ?? [];
            //console.log(data)
            this.setState({
                historyData: data
            })
        })
    }

    componentDidMount() {
        this.loadData();
    }

    get charData() {
        let chartData: DataPoint[] = [];
        for (var i =30;i > this.state.historyData.length+1;i--){
            chartData.push({
                type:moment().add(-i, "days").format('yyyy-MM-DD').toString(),
                value:0
            });
        }

        this.state.historyData.map(x => {
            let dataPoint: DataPoint = {
                type: x.name,
                value: x.value
            }
            return dataPoint;
        }).forEach(x=>chartData.push(x));
        //console.log(chartData)
        return chartData;
    }

    render(): React.ReactNode {
        return (
            <div className="history-summary-view">
                <div className="summary-chart">
                    <Statistic title={this.props.name} value={this.props.summaryDataPoint.value} />

                </div>
                <div className="history-chart">
                    <AreaChart
                        data={
                            this.charData
                        }
                    />
                </div>
            </div>
        )
    }
}



export default withTranslation()(HistorySummary)


