import { Button } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import { FileOutlined } from '@ant-design/icons'

export interface AttachmentThread {
	"attachmentId":number
}


export interface AttachmentFileViewModel {
	apiServer:ApiServer,
	attachmentThread: AttachmentThread,
	ticketId: number
}

interface AttachmentFileViewState {
	attachmentId: number,
	ticketId: number,
	fileName:string
}

class AttachmentFileView extends React.Component<AttachmentFileViewModel,AttachmentFileViewState>{

	constructor(props:AttachmentFileViewModel){
		super(props)
		this.state = {
			attachmentId :this.props.attachmentThread.attachmentId,
			ticketId: this.props.ticketId,
			fileName:"",
		}
	}

	loadData = async ()=> {
		this.props.apiServer.apiTickets.getAttachmentMeta(
			this.state.ticketId,this.state.attachmentId)
			.then(x=>{
				this.setState({
					fileName:x.data.data?.attachmentDto?.name??""
				})
			})

	}

	downloadAttachment = ()=>{
		this.props.apiServer.apiAttachment.getAttachment(this.state.attachmentId)
			.then(x=>{
				if(x.data.data?.succeed){
					var element = document.createElement('a');
					element.target="_blank";
					element.href= x.data.data.attachmentInfo?.downloadUrl??""
					document.body.appendChild(element)
					element.click()
					URL.revokeObjectURL(element.href);
					document.body.removeChild(element);

				}
			})
	}

	l:Promise<void>| undefined;
	componentDidMount(){
		if(!this.l){
			this.l = this.loadData().finally(()=>this.l=undefined);
		}
	}

	render(): React.ReactNode {
		return (
		<div>
			<div>
				<FileOutlined />
				<Button type="link" onClick={this.downloadAttachment} >{this.state.fileName}</Button>
			</div>
		</div>)
	}

}

export default withTranslation()(AttachmentFileView);