import React from "react";
import { Chart, Util } from '@antv/g2';
import { SERIAL_COLOR, DataPoint } from "../@mod";
import './index.less';

export interface BarChartViewModel {
    data: DataPoint[],
    width?: number,
    height?: number,
    valueFormat?: (value: any) => any,
    nameFormat?: (name: any) => any

}


interface BarChartViewState {

}


class BarChart extends React.Component<BarChartViewModel, BarChartViewState>{


    containerId: string
    serialColor = SERIAL_COLOR;

    constructor(props: BarChartViewModel) {
        super(props)

        this.containerId = "bar-chart-container-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random();

    }
    chart: Chart | undefined;


    componentDidUpdate() {
        if (this.chart == null && this.props.data.length > 0) {
            var colors = this.props.data.map((x, index) => {
                return x.color ?? this.serialColor[index] ?? ""
            });

            let nameFormat = this.props.nameFormat ?? function (any) {
                return any.name;
            }
            let valueFormat = this.props.valueFormat ?? function (any) {
                return any.value
            }
            this.chart = new Chart(
                {
                    container: this.containerId,
                    autoFit: true,
                    width: this.props.width,
                    height: this.props.height

                }
            );
            this.chart.data(this.props.data);
            this.chart.scale('value', { nice: true });
            this.chart.coordinate().transpose();
            this.chart.tooltip({
                showMarkers: false,
                customItems: (items) => {
                    return items.map(x => {
                        let toolTipItem = {
                            ...x,
                            name: nameFormat(x),
                            value: valueFormat(x)
                        }
                        return toolTipItem;
                    }
                    )
                }
            });
            this.chart.interaction('active-region');
            this.chart.interval().position('type*value');
            this.chart.render();

        } else if (this.chart) {
            this.chart.changeData(this.props.data)
        }
    }


    render(): React.ReactNode {
        return <div id={this.containerId} className="bar-chart-container" >

        </div>
    }
}


export default BarChart;