import { Button, Input, List, Select } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Escalation } from "../@mod";
import './index.less'

export interface TimeShiftViewModel extends WithTranslation {
	escalations: Escalation[],
	refObject:any
}

interface TimeShiftState {
	escalations: Escalation[],
	ref:any
}

class TimeShift extends React.Component<TimeShiftViewModel, TimeShiftState>{
	constructor(props: TimeShiftViewModel) {
		super(props)
		this.state = {
			escalations: this.props.escalations,
			ref:this.props.refObject
		}
	}

	getSnapshotBeforeUpdate(preProps:TimeShiftViewModel,preState:TimeShiftState){
		if(preProps.escalations.length !== this.props.escalations.length){
			this.setState({
				escalations:this.props.escalations
			})
		}
	}
	componentDidUpdate(){

	}

	flushState = ()=>{
		this.setState(
			{escalations:this.state.escalations}
		)
	}


	renderListItem = () => {
		return this.state.escalations.map(e => {
			return (
				<List.Item>
					<div className="escalation-item">
						<div className="escalation-condition">
							<div className="condition-item">
								<div className="label">{this.props.t("auto.when")}</div>
							</div>
							<div className="condition-item">

								<Select value={e.when.axis}  onChange={(v)=>{e.when.axis=v;this.flushState(); }}>
									<Select.Option value="lastUpdated">{this.props.t("auto.lastUpdated")}</Select.Option>
									<Select.Option value="created" >{this.props.t("auto.created")}</Select.Option>
									<Select.Option value="sla" >{this.props.t("auto.sla")}</Select.Option>
								</Select>
							</div>
							<div className="condition-item">
								<div className="label">{this.props.t("auto.time-at")}</div>
							</div>
							<div className="condition-item">
								<Input type="number" value={e.when.time} style={{ maxWidth: 100 }} onChange={(t)=>{e.when.time=Number(t.target.value);this.flushState();}} ></Input>
							</div>
							<div className="condition-item">
								<div className="label">{this.props.t("auto.time-met")}</div>
							</div>

							<div className="condition-item">
								<Select value={e.when.condition} onChange={(v)=>{e.when.condition=v;this.flushState(); }} >
									<Select.Option value="before">{this.props.t("auto.before")}</Select.Option>
									<Select.Option value="after" >{this.props.t("auto.after")}</Select.Option>
								</Select>
							</div>
							<div className="condition-item">
								<Select value={e.target} mode="tags"  onChange={(v)=>{e.target=v;this.flushState(); }}>
									<Select.Option value="assignToParent" >{this.props.t("auto.assignToParent")}</Select.Option>
								</Select>
							</div>
							<div className="condition-item">
								<Button type="primary" shape="round" danger onClick={
									()=>{
										let original = this.state.escalations;
										let target = original.filter(y=>y!=e);
										original.length=0;
										target.forEach(x=>original.push(x));
										this.setState({
											escalations:original
										})
									}
								}
								>{this.props.t("auto.delete")}</Button>
							</div>
						</div>
					</div>
				</List.Item>
			)
		})
	}

	addEscalation = () => {
		let es = this.state.escalations;
		es.push({
			when: {
				"axis": "lastUpdated",
				"condition": "after",
				"time": 120
			},
			target: [
				"assignToParent"
			]
		});
		this.setState({
			escalations: es
		})
	}

	setSla = (v)=>{
	  let ref	=this.state.ref; 
	  ref.totalSLA = Number(v);
	  this.flushState();

	}

	render(): React.ReactNode {
		return (
			<div className="time-shift-editor">
				<div className="time-shift-label">
					<h1>{this.props.t("auto.time-shift-title")}</h1>
				</div>
				<div className="time-shift-buttons">
					<Button type="primary" onClick={this.addEscalation} > {this.props.t("auto.add-time-shift-item")} </Button>
				</div>
				<div>
					<List>
						{this.renderListItem()}
					</List>
				</div>
				<div className="time-limited">
					<div className="label">{this.props.t("auto.sla")}</div>
					<div><Input value={this.state.ref.totalSLA} onChange={(v)=>this.setSla(v.target.value)} /></div>
					<div className="label">{this.props.t("auto.minutes")}</div>
				</div>
			</div>
		)
	}

}

export default withTranslation()(TimeShift);

