import {Button, Card, Checkbox, Descriptions, Input, notification, Popover, Switch, Tag, Tooltip} from "antd";
import moment from "moment";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import ApiServer from "../../../../ApiServer";
import UserAvatar from "../../../../components/user-avatar";
import {
    AccessModel,
    loadEmailBoxAccess,
    MailBoxModel,
    MailVendorModel,
    RemoteInstanceModel,
    saveAccessChange,
    TagModel,
    UserModel
} from "../../@mod";
import UserSelector from "../user-selector";
import './index.less';
import {InfoCircleOutlined} from "@ant-design/icons"


export interface MailBoxAccessEditorViewModel extends WithTranslation {
    apiServer: ApiServer,
    mailBoxModel: MailBoxModel,
    instances: RemoteInstanceModel[],
    vendors: MailVendorModel[]
    tags: TagModel[],
    user: UserModel[]

}

interface MailBoxAccessEditorViewState {
    mailBoxAccess: AccessModel[],
    mailBoxAccessOriginal: AccessModel[],
    dataloading: boolean
}


class MailBoxAccessEditor extends React.Component<MailBoxAccessEditorViewModel, MailBoxAccessEditorViewState>{

    constructor(props: MailBoxAccessEditorViewModel) {
        super(props)
        this.state = {
            mailBoxAccess: [],
            dataloading: false,
            mailBoxAccessOriginal: []
        }
    }

    loadData = async (): Promise<void> => {
        this.setState({
            dataloading: true
        })

        await loadEmailBoxAccess(this.props.apiServer, this.props.mailBoxModel.id)
            .then(x => {
                var original = JSON.parse(JSON.stringify(x));
                this.setState({
                    mailBoxAccess: x,
                    mailBoxAccessOriginal: original
                })
            })
        this.setState({
            dataloading: false
        })
    }



    l: Promise<void> | undefined
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.l = undefined;

                })
        }
    }


    renderTags = () => {
        return this.props.mailBoxModel.tags?.map(x => {
            return (<Tag key={x.id} color={x.color}>{x.name}</Tag>)
        })
    }

    renderAccessItems = () => {
        let onDatachanged = (a: AccessModel) => {
            a.changed = true
            this.setState({
                mailBoxAccess: this.state.mailBoxAccess
            })
        }

        let onDataReset = (a: AccessModel) => {
            a.changed = false
            this.setState({
                mailBoxAccess: this.state.mailBoxAccess
            })
        }

        let checkCanRead = (a: AccessModel) => {
            a.canRead = a.canRead !== true
            onDatachanged(a);
        }

        let checkCanSend = (a: AccessModel) => {
            a.canSend = a.canSend !== true
            onDatachanged(a);
        }

        let checkCanApprove = (a: AccessModel) => {
            a.canApprove = a.canApprove !== true
            onDatachanged(a);
        }

        let readLimitChanged = (a: AccessModel, val) => {
            a.readLimit = val
            onDatachanged(a);
        }

        let sendLimitChanged = (a: AccessModel, val) => {
            a.sendLimit = val
            onDatachanged(a);
        }

        let checkedChanged = (a:AccessModel)=>{
            a.checked =(a.checked!==true);
            this.setState({
                mailBoxAccess: this.state.mailBoxAccess
            })
        }

        let saveChange = (a: AccessModel) => {
            a.changed = false;
            saveAccessChange(
                this.props.apiServer,
                a,
                this.props.mailBoxModel.id
            ).then(x => {
                if (x) {
                    let org = this.state.mailBoxAccessOriginal.find(o => o.user?.key === a.user?.key);
                    if (org) {
                        org.sendLimit = a.sendLimit;
                        org.readLimit = a.readLimit;
                        org.canRead = a.canRead;
                        org.canSend = a.canSend;
                        org.canApprove = a.canApprove;
                        this.setState({
                            mailBoxAccessOriginal: this.state.mailBoxAccessOriginal
                        })
                        notification.success({ message: this.props.t("mails.access-control.save-access-succeed") })
                    } else {
                        notification.warning({ message: this.props.t("mails.access-control.save-access-fail") })
                        resetChange(a);
                    }
                }
            })
            onDataReset(a)
        }

        let resetChange = (a: AccessModel) => {
            let original = this.state.mailBoxAccessOriginal.find(o => o.user?.key === a.user?.key);
            if (original) {
                a.canRead = original.canRead;
                a.canSend = original.canSend;
                a.canApprove = original.canApprove;
                a.sendLimit = original.sendLimit;
                a.readLimit = original.readLimit;
                a.changed = false;
            }
            onDataReset(a);
        }


        return this.state.mailBoxAccess.map(x => {
            return (
                <div className="access-item"  key={x.user?.key}>
                    <div className="check-box">
                        <Checkbox checked={x.checked} onChange={()=>checkedChanged(x)} />

                    </div>
                    <div className="avtar">
                        <UserAvatar userName={x.user?.fullName ?? ""} />

                    </div>
                    <div className="name">
                        <div>
                            {x.user?.fullName} {"(@" + x.user?.name + ")"}
                        </div>
                        <div>
                            {x.user?.organization}
                        </div>
                    </div>
                    <div className="controls">
                        <Switch
                            checked={x.canRead}
                            checkedChildren={this.props.t("mails.access-control.can-recv")}
                            unCheckedChildren={this.props.t("mails.access-control.can-not-recv")}
                            onChange={() => checkCanRead(x)}
                        />

                        <Switch
                            checked={x.canSend}
                            checkedChildren={this.props.t("mails.access-control.can-send")}
                            unCheckedChildren={this.props.t("mails.access-control.can-not-send")}
                            onChange={() => checkCanSend(x)}
                        />

                        <Switch
                            checked={x.canApprove}
                            checkedChildren={this.props.t("mails.access-control.can-approve")}
                            unCheckedChildren={this.props.t("mails.access-control.can-not-approve")}
                            onChange={() => checkCanApprove(x)}
                        />

                        <div className="limit">

                            <span className="label">
                                {this.props.t("mails.access-control.recv-limt")}
                            </span>

                            <div>
                                <Input style={{width: 150}}
                                       type="number"
                                       value={x.readLimit}
                                       onChange={(t) => readLimitChanged(x, Number(t.target.value))}
                                       suffix={
                                           <Tooltip title={this.props.t("mails.access-control.recv-limit-info")}>
                                               <InfoCircleOutlined/>
                                           </Tooltip>
                                       }/>
                            </div>
                        </div>
                        <div className="limit">
                            <span className="label">
                                {this.props.t("mails.access-control.send-limt")}
                            </span>

                            <div>
                                <Input style={{ width: 150 }}
                                    type="number"
                                    value={x.sendLimit}
                                    onChange={(t) => sendLimitChanged(x, Number(t.target.value))}
                                    suffix={
                                        <Tooltip title={this.props.t("mails.access-control.send-limit-info")}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    } />
                            </div>
                        </div>
                        <div className="save-button">
                            <Button disabled={!x.changed} style={{ marginRight: 8 }} type="primary" onClick={() => saveChange(x)} >{this.props.t("mails.access-control.save-access")}</Button>
                            <Button disabled={!x.changed} type="default" onClick={() => resetChange(x)} >{this.props.t("mails.access-control.reset-access")}</Button>
                        </div>
                    </div>
                </div>
            )

        })

    }
    isDisableRevoke = ()=>{

        return this.state.mailBoxAccess.findIndex(x=>(x.checked??false) === true) < 0
    }

    revokeAccess= ()=>{
        this.props.apiServer.apiMail.delMailBoxAccess(
            this.props.mailBoxModel.id,
            {
                userIds: this.state.mailBoxAccess.filter(x=>x.checked).map(x=>Number(x.user?.key??"0"))
            }
        ).then(x=>{
            if(x.data.data?.success){
                notification.success({"message":this.props.t("mails.access-control.revoke-access-succeed")})
                this.loadData();
            }
        })
    }

    addAccess = (ids: number[]) => {

        this.props.apiServer.apiMail.postMailBoxAccess(this.props.mailBoxModel.id,
            {
                access: ids.map(i => {
                    return {
                        userId: i,
                        canRead: true,
                        canSend: false,
                        canApprove: false,
                        readLimit: 30,
                        sendLimit: 30
                    }
                })
            }
        ).then(r => {
            if (r.data.data?.success) {
                notification.success({ message: this.props.t("mails.access-control.new-access-added-success") })
                this.loadData();
            }
            else {
                notification.error({ message: this.props.t("mails.access-control.new-access-add-fail") })
            }
        })

    }

    render(): React.ReactNode {
        return (
            <div>
                <div className="mail-box-access-editor">
                    <Card>
                        <div>
                            <Descriptions >
                                <Descriptions.Item
                                    label={this.props.t("mails.access-control.serial")}>
                                    {this.props.mailBoxModel.id}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={this.props.t("mails.access-control.emailAddress")}>
                                    {this.props.mailBoxModel.emailAddress}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={this.props.t("mails.access-control.lastestUpdate")}>
                                    {this.props.mailBoxModel.updateter}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={this.props.t("mails.access-control.created")}>
                                    {moment.parseZone(this.props.mailBoxModel.created).format("YYYY-MM-DD HH:mm:ss")}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={this.props.t("mails.access-control.lastUpdated")}>
                                    {moment.parseZone(this.props.mailBoxModel.updated).format("YYYY-MM-DD HH:mm:ss")}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                        <div className="control-bar">
                            <div>{this.renderTags()}</div>
                            <div className="actions">
                                <Popover placement="leftTop" content={<UserSelector
                                    user={this.props.user}
                                    userIdNotDisplay={this.state.mailBoxAccess.map(x => Number(x.user?.key ?? 0))}
                                    onUserSelected={this.addAccess}
                                />} trigger="click"
                                    destroyTooltipOnHide >
                                    <Button type="primary" >{this.props.t("mails.access-control.new-access-granted")}</Button>
                                </Popover>
                                <Button loading={this.state.dataloading} disabled={
                                    this.isDisableRevoke()

                                } 
                                    onClick= {
                                        ()=>this.revokeAccess()
                                    }
                                type="primary" danger >{this.props.t("mails.access-control.delete-access-granted")}</Button>
                                <Button loading={this.state.dataloading} type="primary" >{this.props.t("mails.access-control.search")}</Button>
                                <Input disabled></Input>
                            </div>
                        </div>

                    </Card>
                </div>
                <div className="mail-box-access-list">
                    <Card>
                        {this.renderAccessItems()}

                    </Card>
                </div>
            </div>
        )
    }
}

export default withTranslation()(MailBoxAccessEditor);