import { Button, Card, Select } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import usergroups from "../../access/members/usergroups";
import { UserGroupModel, UserModel } from "../@mod";
import "./index.less";



export interface AssignPanelViewModel extends WithTranslation {
    loadUserGroup: () => Promise<UserGroupModel[]>,
    loadUserGroupUser: (userGroupId: number) => Promise<UserModel[]>,
    onAssigneConfirmed: (userGroupId, userId) => void,
    onCancel?: () => void,
    isAdmin:boolean
}

interface AssignPanelViewState {
    userGroups: UserGroupModel[]
    users: UserModel[],
    selectedUserGroup?: UserGroupModel
    selectedUser?: UserModel;
}

class AssignPanel extends React.Component<AssignPanelViewModel, AssignPanelViewState>{

    l: Promise<void> | undefined;
    constructor(props: AssignPanelViewModel) {
        super(props)
        this.state = {
            userGroups: [],
            users: []
        }
    }


    loadData = async (): Promise<void> => {
        

        await this.props.loadUserGroup()
            .then(x => {
                if(!this.props.isAdmin){
                    x = x.filter(x=> x.name === "Default");
                }
                let defaultSelectedGroup;
                if (x.length > 0) {
                    let defaultGroupIndex = x.findIndex(x => x.name === "Default");
                    if (defaultGroupIndex >= 0) {
                        defaultSelectedGroup = x[defaultGroupIndex];
                    } else {
                        defaultSelectedGroup = x[0];
                    }
                }
                this.setState({
                    userGroups: x,
                    selectedUserGroup: defaultSelectedGroup
                })

                this.userGroupChanged(defaultSelectedGroup.id);
            })
    }

    userGroupChanged = async (userId: number): Promise<void> => {
        this.setState({
            users: []
        })
        this.props.loadUserGroupUser(userId)
            .then(x => {
                this.setState({
                    users: x
                })

                if(x.length>0){
                    this.setState({
                        selectedUser : x[0]
                    })
                }
            })
        }


    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.l = undefined
                })
        }
    }

    renderUserGroupOptions = () => {
        return this.state.userGroups.map(
            x => {
                return (<Select.Option value={x.id} key={x.id}>
                    {x.name}
                </Select.Option>)
            }

        )
    }

    renderUserOptions = () => {
		return this.state.users.map(
			x => (
				<Select.Option value={x.id} key={x.id} username={x.name} org={x.organization}  >
					<div className={"user-name " + ((x.active??false)? "user-active" : "user-inactive")}>{x.name} </div>
					<div className={((x.active??false)?"user-organzation " : "user-inactive")} >{x.organization}</div>
				</Select.Option>

			));
    }

    onUserGroupChanged = (id: number) => {
        this.setState({
            selectedUserGroup: this.state.userGroups.find(x => x.id === id)
        })
        this.userGroupChanged(id);

    }
    onUserChanged = (id: number) => {
        this.setState({
            selectedUser: this.state.users.find(x => x.id === id)
        })
    }

    onConfirm = () => {
        this.props.onAssigneConfirmed(
            this.state.selectedUserGroup?.id,
            this.state.selectedUser?.id
        )
    }

    onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel()
        }
    }

    filterOption = (v:any,m:any|undefined)=>{
		if(m){
			if(m.username.toLocaleLowerCase().indexOf(v.toLocaleLowerCase())>=0){
				return true;
			}
			if(m.org.indexOf(v) >=0){
				return true
			}
		}
		return false;
	}


    render(): React.ReactNode {
        return (
            <div>
                <Card title={this.props.t("tickets.batch-assigned-to")} extra={
                    <div className="button-bar">
                        <Button type="primary" onClick={this.onConfirm}>{this.props.t("tickets.assigned-to")}</Button>
                        <Button type="primary" danger onClick={this.onCancel}>{this.props.t("tickets.cancel")}</Button>
                    </div>
                }
                    className="assign-to-panel"
                >
                    <div className="fields">
                        <div className="title">{this.props.t("user-group")}</div>
                        <div className="selector">
                            <Select style={{ width: 300 }} value={this.state.selectedUserGroup?.id}
                                onChange={this.onUserGroupChanged}
                                
                            >
                                {this.renderUserGroupOptions()}
                            </Select>
                        </div>
                    </div>
                    <div className="fields">
                        <div className="title">{this.props.t("users")}</div>
                        <div className="selector" >
                            <Select style={{ width: 300 }} value={this.state.selectedUser?.id}
                                onChange={this.onUserChanged}
                                filterOption={this.filterOption}
                                showSearch
                            >
                                {this.renderUserOptions()}
                            </Select>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

export default withTranslation()(AssignPanel);

