import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import './index.less'


export interface AccessControlLandingPageModel extends WithTranslation {

}

class AccessControlLandingPage extends React.Component<AccessControlLandingPageModel,{}>{


	componentDidMount(){
	};
		render(): React.ReactNode {
			return (
			<div className="access-control-landingpage">
				<div className="description">
					 {this.props.t("access-control-landing")}
				</div>
				<div>
					{this.props.t("access-control-landing-desc")}
			
				</div>
			</div>)
		}
}

export default withTranslation()(AccessControlLandingPage);

