import { PageHeader} from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import WorkbenchTable from "../../../../components/table/workbench-table";
import './index.less'
import {PrivilegeModel,columnsProvider} from "./@mod"
import { ActionEventHandler } from "../../../../components";


interface PrivilegePageModel extends WithTranslation {
	apiServer: ApiServer
}

interface PrivilegePageState {
	privileges:PrivilegeModel[],
	dataLoading:boolean
}

class PrivilegePage extends React.Component<PrivilegePageModel, PrivilegePageState>{
	constructor(props:PrivilegePageModel){
		super(props)
		this.state ={
			privileges:[],
			dataLoading:true
		}
	}

	l: Promise<void>|undefined;
	componentDidMount(){
		if(!this.l){
			this.l = this.loadData().finally(()=>{
				this.setState(
					{
						dataLoading:false
					}
				)
			})
		}

	}

	async loadData(){
		await this.props.apiServer.apiPrivilege.getPrivilegeList()
		.then(x=>{
			this.setState({ 
				privileges:x.data.data?.list?.map(y=>{
				let p : PrivilegeModel ={
					key: (y.id??0).toString(),
					id:y.id??0,
					name:y.name??"",
					path:y.path??"",
					verb:y.verb??"",
					enabled:y.enabled??false
				}
				return p;
			})??[]}
			)
		})

	}

	render(): React.ReactNode {
		return (
			<div className="privilege-container site-drawer-render-in-current-wrapper" id="user-container" >
				<PageHeader title={this.props.t("privilege-editor.self")} extra={
					[
						<div className="user-global-button" key="global-button">
						</div>

					]}
					subTitle={this.props.t("privilege-editor.editor-subtitle")}
				>
					<Content>
						<WorkbenchTable<PrivilegeModel>
							data={this.state.privileges}
							dataLoading ={this.state.dataLoading}
							columnProvider={
								(a:ActionEventHandler<PrivilegeModel>)=>columnsProvider(this.props.t,a)
							}/>
					</Content>

				</PageHeader>
			</div>
		)
	}
}


export default withTranslation()(PrivilegePage);