import {Card, message, Modal} from "antd";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {ProjectServiceItemMetaDataDto, UserDto} from "../../../../api";
import ApiServer from "../../../../ApiServer";
import WorkBenchTable from "../../../../components/table/workbench-table";
import {executableTaskSelections, TASK_TYPES, taskMetaDataColumnProvider} from "../../@mod";
import TaskEditor from "../../project-template/editor/task-editor";
import {InboxOutlined} from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import {deliverableColumnProvider} from "../task-list/task-detail/@mod";
import URI from "urijs";
import {Axios} from "axios";
import {RcFile} from "antd/lib/upload";
import "./index.less"
import {getTicketPriorityList, TicketPriorityModel} from "../../project-template/editor/@mod";

interface TaskMetaDataListModel extends WithTranslation {
    serviceId?: number
    apiServer: ApiServer
}

interface TaskMetaDataListState {
    taskMetaDataList: ProjectServiceItemMetaDataDto[]
    showTaskMetaDataEditor: boolean
    showKnowledgeUploader: boolean
    selectedTask: ProjectServiceItemMetaDataDto
    loadingknowledgeList: boolean
    knowledgeList: any[]
    userList: UserDto[]
    ticketPriorityList: TicketPriorityModel[]
}

class TaskMetaDataList extends React.Component<TaskMetaDataListModel, TaskMetaDataListState> {
    constructor(props: TaskMetaDataListModel) {
        super(props)
        this.state = {
            taskMetaDataList: [],
            showTaskMetaDataEditor: false,
            showKnowledgeUploader: false,
            selectedTask: {},
            knowledgeList: [],
            loadingknowledgeList: false,
            userList: [],
            ticketPriorityList: []
        }
        props.apiServer.apiUser.getUserList().then(r => {
            this.setState({
                userList: r.data.data?.list ?? []
            })
        })
    }


    componentDidMount(): void {
        this.loadTaskMetaData()
        this.loadTicketPriorityList()
    }

    onTaskMetaAction = (data: ProjectServiceItemMetaDataDto, action, index) => {
        if (action === "edit") {
            this.setState({
                selectedTask: data,
                showTaskMetaDataEditor: true
            })
        } else if (action === "upload-knowledge") {
            this.setState({
                selectedTask: data,
                showKnowledgeUploader: true
            })
            this.loadTaskKnowledge(data.id)
        }
    }

    onKnowledgeAction = (model, actionType: string, index) => {
        if (actionType === "delete" && this.state.selectedTask) {
            if (this.state.selectedTask.id) {
                this.props.apiServer.apiProjectMeta.deleteTaskKnowledge(this.state.selectedTask.id, {
                    attachmentId: model.id
                }).then(r => {
                    if (r.data.data?.success) {
                        message.success(this.props.t("project.task.delete-success")).then()
                        this.loadTaskKnowledge()
                    } else {
                        message.error(this.props.t("project.task.delete-fail")).then()
                    }

                })
                return
            }
            message.error(this.props.t("project.task.cannot-delete-submited"))
        } else if (actionType === "download") {
            this.props.apiServer.apiAttachment.getAttachment(model.id)
                .then(x => {
                    if (x.data.data?.succeed) {
                        var element = document.createElement('a');
                        element.target = "_blank";
                        element.href = x.data.data.attachmentInfo?.downloadUrl ?? ""
                        document.body.appendChild(element)
                        element.click()
                        URL.revokeObjectURL(element.href);
                        document.body.removeChild(element);
                    }
                })
        }
    }


    loadTaskMetaData = () => {
        if (this.props.serviceId) {
            this.props.apiServer.apiProjectMeta.getItems(this.props.serviceId).then(r => {
                let taskMetaDataList = r.data.data?.dtos
                this.setState({
                    taskMetaDataList: taskMetaDataList ?? []
                })
            })
        }
    }

    loadTicketPriorityList = () => {
        getTicketPriorityList(this.props.apiServer).then(x => {
            this.setState({
                ticketPriorityList: x
            })
        })
    }

    onFinishTaskMetaDataEdit = (values) => {
        if (this.state.selectedTask?.id) {
            this.props.apiServer.apiProjectMeta.putItem(
                this.state.selectedTask.id,
                {
                    title: values.title,
                    type: values.type,
                    description: values.description,
                    defaultExecutor: values.defaultExecutor,
                    required: values.required,
                    timeEstimationHigherLimit: values.timeEstimationHigherLimit,
                    timeEstimationLowerLimit: values.timeEstimationLowerLimit,
                }
            ).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("project.template.task.meta.update-success"))
                    this.componentDidMount()
                    this.setState({
                        showTaskMetaDataEditor: false
                    })
                }
            })
        }
    }

    loadTaskKnowledge = (taskId?) => {
        if (taskId || this.state.selectedTask.id) {
            this.props.apiServer.apiProjectMeta.getItemKnowledge(taskId ? taskId : this.state.selectedTask.id).then(r => {
                if (r.data.data?.knowledgeList) {
                    this.setState({
                        knowledgeList: r.data.data?.knowledgeList
                    })
                }
            })
        }
    }

    onCancelEditMetaData = () => {
        this.setState({
            showTaskMetaDataEditor: false
        })
    }

    createSpace = (rc: RcFile) => {
        let name = rc.name;
        return this.props.apiServer.apiAttachment.putNewUploadRequest(
            {
                fileName: name
            }
        ).then(x => {
            if (x.data.data?.attachmentDto?.uploadUrl) {
                return x.data.data?.attachmentDto?.uploadUrl + "&id=" + x.data.data.attachmentDto.id
            }
            return "";
        })
    };

    customerRequest = (option: any) => {
        if (this.state.selectedTask) {
            let uri = new URI(option.action);
            let a = new Axios({
                baseURL: uri.scheme() + "://" + uri.host(),
                onUploadProgress: (a) => {
                    option.onProgress({ percent: a.loaded / a.total });
                }
            })
            let fileId = uri.query(true)["id"]
            uri.removeQuery("id");
            a.put(uri.toString(), option.file)
                .then(async (x) => {
                    option.onSuccess(x.data, x.request)
                    await this.props.apiServer.apiProjectMeta.postCommitItemKnowledge(this.state.selectedTask?.id ?? -1, {
                        attachmentId: fileId
                    });
                    await this.props.apiServer.apiAttachment.postCompleteUpload(fileId);
                    this.loadTaskKnowledge();
                })
        }
    }

    render(): React.ReactNode {
        return (
            <div className="task-meta-list-container">
                <WorkBenchTable<ProjectServiceItemMetaDataDto>
                    data={this.state.taskMetaDataList.filter(t => (t.type?.code ?? 0) >= 1003)}
                    dataLoading={false}
                    columnProvider={() => taskMetaDataColumnProvider(this.props, this.onTaskMetaAction)}
                />
                {/* task editor */}
                <Modal
                    visible={this.state.showTaskMetaDataEditor}
                    onCancel={() => this.setState({ showTaskMetaDataEditor: false })}
                    footer={null}
                    destroyOnClose={true}
                >
                    <TaskEditor
                        taskInfo={
                            this.state.selectedTask.type?.code ?
                                {
                                    ...this.state.selectedTask,
                                    type: TASK_TYPES[this.state.selectedTask.type?.code]["value"]
                                } : {}
                        }
                        onFinish={this.onFinishTaskMetaDataEdit}
                        userList={this.state.userList}
                        cancelEdit={this.onCancelEditMetaData}
                        taskSelections={executableTaskSelections(this.props.t)}
                        ticketPriorityList={this.state.ticketPriorityList}
                    />
                </Modal>

                {/* knowledge uploader */}
                <Modal
                    title={this.state.selectedTask.title}
                    visible={this.state.showKnowledgeUploader}
                    onCancel={() => this.setState({ showKnowledgeUploader: false })}
                    footer={null}
                    width={800}
                    destroyOnClose={true}
                    className={"knowledge-list"}
                >
                    <Card>
                        <Dragger
                            name='file'
                            multiple={true}
                            action={this.createSpace}
                            customRequest={this.customerRequest}
                        >
                            <div className="drop-box">
                                <span >
                                    <InboxOutlined className="upload-drag-icon" />
                                </span>
                                <div className="upload-text">
                                    {this.props.t("upload-text")}
                                </div>
                                <div className="ant-upload-hint">
                                    {this.props.t("upload-hit")}
                                </div>
                            </div>
                        </Dragger>
                    </Card>
                    <Card className="bottom-card">
                        <WorkBenchTable
                            disableCheckBox={true}
                            data={this.state.knowledgeList}
                            dataLoading={this.state.loadingknowledgeList}
                            columnProvider={(a) => deliverableColumnProvider(this.props, this.onKnowledgeAction)}
                        />
                    </Card>

                </Modal>
            </div>
        )
    }
}

export default withTranslation()(TaskMetaDataList)