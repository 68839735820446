import { Button, DatePicker, Input, notification, Popover, Select, Tag } from "antd";
import moment, { Moment } from "moment";
import momentTz from "moment-timezone";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { PlusOutlined } from '@ant-design/icons'
import {
	PriorityModel,
	StatusModel,
	TicketTypeModel,
	SourceModel,
	loadTicketPriority,
	loadTicketSource,
	loadTicketStatus,
	loadTicketType,
	loadAllTags,
	TagModel,
	UserGroupModel,
	loadUserGroupModels,
	UserModel,
	loadUsers,
	loadTicketTemplate,
	NewTicketTemplateRecord,
	loadContentSchemaList
} from "../@mod";
import TagEditor from "../tag-editor";
import './index.less'
import { RouteContext, withRouter } from "../../../utils";
import UserSelector from "../user-selector";
import { ContentSchemaModel } from "../@mod";
import { loadContent } from "./@mod";
import content from "../../../layout/content";

export interface NewTicketEditorModel extends WithTranslation {
	apiServer: ApiServer,
	getSaver?: (f: () => Promise<boolean>) => void
	router?: RouteContext
}

interface NewTicketEditorState {
	priority: PriorityModel[],
	status: StatusModel[],
	type: TicketTypeModel[],
	source: SourceModel[],
	userGroup: UserGroupModel[]
	allTags: TagModel[],
	users: UserModel[]
	contentSchema: ContentSchemaModel[],

	templateRecords: NewTicketTemplateRecord[]
	selectedTemplate?: NewTicketTemplateRecord

	selectedTags: TagModel[]
	title: string,
	subtitle: string,
	sla: string,
	assignGroup: number,
	assignUser: number,
	selectedPriority: number,
	selectedStatus: number,
	selectedType: number,
	selectedSource: number
	selectedContentSchmemas: number[],
	defaultContent: string
}



class NewTicketEditor extends React.Component<NewTicketEditorModel, NewTicketEditorState>{

	constructor(props: NewTicketEditorModel) {
		super(props)
		this.state = {
			priority: [],
			status: [],
			type: [],
			source: [],
			allTags: [],
			subtitle: "",
			title: "",
			sla: "",
			selectedTags: [],
			userGroup: [],
			users: [],
			assignUser: 0,
			assignGroup: 0,
			selectedPriority: 0,
			selectedStatus: 0,
			selectedType: 0,
			selectedSource: 0,
			templateRecords: [],
			contentSchema: [],
			selectedContentSchmemas: [],
			defaultContent: ""
		}
		this.props.getSaver?.(this.save);
	}

	l: Promise<void> | undefined;

	loadData = async () => {
		let isAdmin = await this.props.apiServer.apiSystem.getLoginUser()
			.then(
				x => {
					return x.data.data?.admin ?? false;
				}
			)
		let usergroup = (await loadUserGroupModels(this.props.apiServer))
			.filter(
				x => isAdmin || x.name === "Default");


		let defaultAssignGroup = usergroup.length > 0 ? usergroup[0].id : 0;
		let prioritySelection = await loadTicketPriority(this.props.apiServer);
		let statusSelection = await loadTicketStatus(this.props.apiServer);
		let typeSelection = await loadTicketType(this.props.apiServer);
		let sourceSelection = await loadTicketSource(this.props.apiServer);
		let template = await loadTicketTemplate(this.props.apiServer);
		let contentSchema = await loadContentSchemaList(this.props.apiServer)
		this.setState({
			selectedPriority: 3,
			selectedStatus: 1,
			selectedType: 1,
			selectedSource: 1,
			priority: prioritySelection,
			status: statusSelection,
			type: typeSelection,
			source: sourceSelection,
			allTags: await loadAllTags(this.props.apiServer),
			userGroup: usergroup,
			assignGroup: defaultAssignGroup,
			templateRecords: template,
			contentSchema: contentSchema
		})
		if (defaultAssignGroup !== 0) {
			this.onGroupChanged(defaultAssignGroup);
		}

	}

	saveData = async () => {

		let ticketId = await this.props.apiServer.apiTickets.postTicket(
			{
				status: this.state.selectedStatus,
				priority: this.state.selectedPriority,
				source: this.state.selectedSource,
				type: this.state.selectedType,
				subject: this.state.title,
				subTitle: this.state.subtitle,
				sla: moment(this.state.sla).unix().toString() + "000",
				assigned: this.state.assignUser,
				assignedGroup: this.state.assignGroup
			}
		).then(async (x) => {
			if (x.data.data?.succeed) {
				let newTicketId = x.data.data?.ticket?.id;
				notification.success({ message: this.props.t("tickets.new-tickets-created") })
				let ticketId = x.data.data.ticket?.id ?? 0;
				let contentId = x.data.data.ticket?.contentId ?? 0;
				if ((this.state.selectedContentSchmemas && this.state.selectedContentSchmemas.length > 0)
					||
					(this.state.defaultContent && this.state.defaultContent.trim() !== "")
				) {
					let content = await loadContent(this.props.apiServer, ticketId, contentId);
					this.state.selectedContentSchmemas.forEach((schemaId, index) => {
						content["form_" + index] = {
							"type": "form",
							"value": "{}",
							"schema": schemaId
						}
					})
					content["default"].value = this.state.defaultContent;

					await this.props.apiServer.apiTickets.putTicketContent(ticketId, contentId, {
						data: JSON.stringify(content)
					})
				}


				this.props.router?.navigate("/ticket/" + newTicketId + "/detail")
				return newTicketId
			}
			return 0

		}) ?? 0;
		if (ticketId > 0 && this.state.selectedTags.length > 0) {
			for (var i = 0; i < this.state.selectedTags.length; i++) {
				await this.props.apiServer.apiTickets.putTicketTags(ticketId,
					{
						tagId: this.state.selectedTags[i].id
					});
			}
		}

	}

	save = async (): Promise<boolean> => {
		if (this.state.assignGroup !== 0 &&
			this.state.assignUser !== 0 &&
			this.state.selectedPriority !== 0 &&
			this.state.selectedSource !== 0 &&
			this.state.selectedStatus !== 0 &&
			this.state.selectedType !== 0 &&
			this.state.title.trim() !== "" &&
			this.state.subtitle.trim() !== "" &&
			this.state.sla.trim() !== ""
		) {
			await this.saveData()
			return true;
		}
		return false;

	}

	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(
					() => this.l = undefined
				)
		}

	}


	onStateChanged = (d) => {
		let {
			priority,
			type,
			status,
			source
		} = d
		console.log(d);
		this.setState(
			{
				selectedPriority: priority,
				selectedType: type,
				selectedStatus: status,
				selectedSource: source
			}
		);

	}

	disabledDate = (current: Moment) => {
		return !current.isAfter(moment());
	}

	slaChanged = (t, textVal) => {
		let m = moment(textVal);
		console.log(textVal)
		if (m.isValid()) {
			this.setState({
				sla: m.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
			})
		}

	}

	getSlaValue = (): Moment | null => {
		let m = moment(this.state.sla)
		if (m.isValid()) {
			return m;
		}
		return null;
	}

	newTag = (id: number) => {
		let selected = this.state.selectedTags;
		let newClick = this.state.allTags.find(x => x.id === id);
		if (newClick && !selected.includes(newClick)) {
			selected.push(newClick);
			this.setState({
				selectedTags: selected
			})
		}
	}
	removeTag = (e: TagModel) => {
		let selected = this.state.selectedTags;
		selected = selected.filter(x => x.id !== e.id)
		this.setState({
			selectedTags: selected
		})
	}

	renderSeletedTag = () => {
		return this.state.selectedTags.map(x => (
			<Tag className="tag-instance"
				closable
				onClose={() => { this.removeTag(x) }}
				color={x.color} key={x.name} >{x.name}</Tag>
		))
	}

	renderUserGroupOption = () => {
		return this.state.userGroup.map(x => (
			<Select.Option value={x.id} key={x.id} >{x.name}</Select.Option>
		))
	}

	renderUserOption = () => {
		return this.state.users.map(x => (
			<Select.Option value={x.id} key={x.id} >{x.name}</Select.Option>
		))
	}

	getSelectOption = (datArray: any[], filter: string | undefined = undefined) => {
		if (filter) {
			datArray = datArray.filter(x => x.name !== filter);
		}

		return datArray.map(dat => (
			<Select.Option key={dat.id} value={dat.id}>
				{dat.description[this.props.i18n.language]}
			</Select.Option>
		))
	}

	onGroupChanged = (v: number) => {
		this.setState({ assignGroup: v })

		loadUsers(this.props.apiServer, v)
			.then((x) => {
				this.setState({
					users: x,
					assignUser: x.length > 0 ? x[0].id : 0
				})
			});
	}

	renderTemplates = () => {
		return this.state.templateRecords.map(x => {
			return (
				<Select.Option key={x.id} value={x.id} title={x.title} >
					{x.title}
				</Select.Option>
			)
		})
	}

	filterTemplates = (t: string, s: any | undefined) => {
		return s.title.indexOf(t) >= 0;
	}

	templateChanged = (t) => {
		var templateRecord = this.state.templateRecords.find(x => x.id == t);
		this.setState({
			selectedTemplate: templateRecord
		})
	}
	fillTemplate = async () => {
		if (this.state.selectedTemplate) {
			let template = this.state.selectedTemplate.template;
			let sla = moment().add(template.timelimit, "hour").toString();
			let existGroup = this.state.userGroup.findIndex(q => q.id === template.assignedGroup);
			let users = this.state.users;
			let user = this.state.assignUser;
			let assignGroup = this.state.assignGroup
			if (existGroup >= 0) {
				assignGroup = template.assignedGroup;
				users = await loadUsers(this.props.apiServer, template.assignedGroup);
				user = template.assignedUser
				if (user < 0) {
					let currentUser =
						await this.props.apiServer.apiSystem.getLoginUser()
							.then(x => x.data.data?.user?.id ?? 0);
					if (users.findIndex(q => q.id === currentUser) >= 0) {
						user = currentUser;
					}
					user = Math.abs(user);
				}
			}
			this.setState({
				title: template.title,
				subtitle: template.subtitle,
				sla: sla,
				selectedPriority: template.priority,
				selectedSource: template.source,
				selectedType: template.type,
				selectedStatus: template.status,
				selectedTags: this.state.allTags.filter(x => template.tags.findIndex(y => y === x.id) >= 0),
				assignGroup: assignGroup,
				assignUser: user,
				users: users,
				selectedContentSchmemas: template.contentSchema ?? [],
				defaultContent: template.defaultContent
			})
		}
	}

	onUserChanged = (v: number) => {
		this.setState({
			assignUser: v
		})

	}

	renderSelectedItem = () => {
		return this.state.users.map(
			x => (
				<Select.Option value={x.id} key={x.id} username={x.name} org={x.organization}  >
					<div className={"user-name " + ((x.active??false)? "user-active" : "user-inactive")}>{x.name} </div>
					<div className={((x.active??false)?"user-organzation " : "user-inactive")} >{x.organization}</div>
				</Select.Option>

			));
	}

	filterOption = (v: any, m: any | undefined) => {
		if (m) {
			if (m.username.indexOf(v) >= 0) {
				return true;
			}
			if (m.org.indexOf(v) >= 0) {
				return true
			}
		}
		return false;
	}

	renderContentSchemaOptions = () => {
		return this.state.contentSchema.map(x => {
			return (
				<Select.Option key={x.id} value={x.id}>
					{x.name}
				</Select.Option>)

		})
	}

	contentSchemaChanged = (v) => {

		this.setState({
			selectedContentSchmemas: v
		})
	}

	defaultContentChanged = (v) => {
		this.setState({
			defaultContent: v.target.value
		})
	}


	render(): React.ReactNode {
		return (
			<div className="new-ticket-editor-container">
				<div className="template-selector">
					<div className="field">
						{this.props.t("tickets.template")}
					</div>
					<div className="field-input">
						<Select style={{ width: "100%" }}
							showSearch
							value={this.state.selectedTemplate?.id}
							onChange={this.templateChanged}
							filterOption={(t, s) => this.filterTemplates(t, s)}
						>
							{this.renderTemplates()}
						</Select>
					</div>
					<div className="field-input fill-template">
						<Button type="primary" onClick={this.fillTemplate} >{this.props.t("tickets.fill-template")}</Button>
					</div>
				</div>

				<div className="title-field">
					<div className="field">
						<div className="field-lable">
							{this.props.t("tickets.title")}<span>*</span>
						</div>
						<div className="field-input">
							<Input status={this.state.title === "" ? "error" : ""}
								placeholder={this.props.t("tickets.title-placeholder")}
								value={this.state.title}
								onChange={(e) => { this.setState({ title: e.target.value }) }}
							/>
						</div>
					</div>
					<div className="field">
						<div className="field-lable">
							{this.props.t("tickets.subtitle")} <span>*</span>
						</div>
						<div className="field-input">
							<Input status={this.state.subtitle === "" ? "error" : ""}
								placeholder={this.props.t("tickets.subtitle-placeholder")}
								value={this.state.subtitle}
								onChange={(e) => { this.setState({ subtitle: e.target.value }) }}
							/>
						</div>
					</div>
					<div className="field">
						<div className="field-lable">
							{this.props.t("tickets.sla")} <span>*</span>
						</div>
						<div className="field-input">
							<DatePicker showTime={true}
								style={{ width: "100%" }}
								placeholder={
									this.props.t("tickets.input-sla-placeholder")
								}
								status={this.state.sla === "" ? "error" : ""}
								disabledDate={this.disabledDate}
								onChange={this.slaChanged}
								value={this.getSlaValue()}
							/>
						</div>

					</div>
				</div>
				<div className="status-field">
					<div className="status-view-new-editor">
						<div className="field">
							<div className="title">
								{this.props.t("tickets.type.self")}
							</div>
							<Select onChange={(v: number) => { this.setState({ selectedType: v }); }}
								value={this.state.selectedType}>
								{this.getSelectOption(this.state.type, "AUTOMATION")}
							</Select>
						</div>
						<div className="field">
							<div className="title">
								{this.props.t("tickets.status.self")}
							</div>
							<Select onChange={(v: number) => { this.setState({ selectedStatus: v }); }}
								value={this.state.selectedStatus}>
								{this.getSelectOption(this.state.status)}
							</Select>
						</div>
						<div className="field">
							<div className="title">
								{this.props.t("tickets.priority.self")}

							</div>
							<Select onChange={(v: number) => { this.setState({ selectedPriority: v }); }}
								value={this.state.selectedPriority}>
								{this.getSelectOption(this.state.priority)}
							</Select>
						</div>
						<div className="field">
							<div className="title">
								{this.props.t("tickets.source.self")}

							</div>
							<Select onChange={(v: number) => { this.setState({ selectedSource: v }); }}
								value={this.state.selectedSource}>
								{this.getSelectOption(this.state.source)}
							</Select>
						</div>

					</div>
				</div>
				<div className="assigned-field">
					<div className="field">
						<div className="field-lable">
							{this.props.t("tickets.assign-to-group")}
						</div>
						<div className="field-input">
							<Select style={{ width: "100%" }}
								value={this.state.assignGroup}
								onChange={this.onGroupChanged}>
								{this.renderUserGroupOption()}
							</Select>
						</div>
					</div>
					<div className="field">
						<div className="field-lable">
							{this.props.t("tickets.assign-to-user")}
						</div>
						<div className="field-input">
							<Select
								style={{ width: "100%" }}
								value={this.state.assignUser}
								onChange={(v) => this.onUserChanged(v)}
								showSearch
								filterOption={this.filterOption}
							>
								{this.renderSelectedItem()}
							</Select>
						</div>
					</div>
				</div>
				<div className="field">
					<div className="field-lable">
						<span style={{ marginRight: 16 }}>
							{this.props.t("tickets.tags")}
						</span>
						<Popover trigger="click"
							placement="top"
							content={<TagEditor allTags={this.state.allTags} onTagAdd={this.newTag} />}>
							<Tag className="site-tag-plus" >
								<PlusOutlined />
							</Tag>
						</Popover>
					</div>
					<div className="field-input">
						{this.renderSeletedTag()}
					</div>
				</div>
				<div className="field">
					<div className="field-lable">
						{this.props.t("new-ticket-template.default-template")}
					</div>
					<div className="field-input">
						<Select mode="multiple"
							style={{ width: "100%" }}
							showSearch
							value={this.state.selectedContentSchmemas}
							onChange={this.contentSchemaChanged}>

							{this.renderContentSchemaOptions()}
						</Select>
					</div>
				</div>

				<div className="field">
					<div className="field-lable">
						<span className="title">{this.props.t("new-ticket-template.default-content")}</span>
					</div>
					<div className="field-input">
						<Input.TextArea autoSize onChange={this.defaultContentChanged} value={this.state.defaultContent}></Input.TextArea>
					</div>
				</div>
			</div>)
	}

}

export default withRouter(withTranslation()(NewTicketEditor));