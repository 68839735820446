import { Button, Input, List, Select } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ProcessModel } from "../@mod";
import './index.less'

export interface ProcessInitialViewModel extends WithTranslation {
	initialStage: ProcessModel[],

}

interface ProcessInitialViewState {
	stages: ProcessModel[]
}


class ProcessInitialView extends React.Component<ProcessInitialViewModel, ProcessInitialViewState>{
	constructor(props: ProcessInitialViewModel) {
		super(props)
		this.state = {
			stages: this.props.initialStage
		}
	}

	addstage = () => {
		let stages = this.state.stages;
		stages.push({
			stage: 0,
			name: "new-name",
			required: {
				"type": "form",
				"ownership":[],
				"ref":""
			}
		});
		for (var i = 0; i < stages.length; i++) {
			if (stages[i].stage === 0) {
				stages[i].stage = i + 1;
			}
		}
		this.setState({
			stages: stages
		})
	}

	getSnapshotBeforeUpdate(preProps){
		if(this.props.initialStage.length!== preProps.initialStage.length){
			this.setState({
				stages:this.props.initialStage
			})
		}
		return null;

	}
	componentDidUpdate(){

	}

	flushState = () => {
		this.setState({ stages: this.state.stages })
	}

	renderListEditor = () => {
		return this.state.stages.map(x =>
			<List.Item key={x.stage}>
				<div className="editor-item">
					<div className="label">{this.props.t("auto.process-stage-index")}</div>
					<Input type="number" value={x.stage} onChange={(v) => { x.stage = Number(v.target.value); this.flushState() }} style={{ minWidth: "100px" }} />
					<div className="label">{this.props.t("auto.process-stage-name")}</div>
					<Input type="text" value={x.name} style={{ minWidth: "150px" }} onChange={(v) => { x.name = v.target.value; this.flushState() }} />
					<div className="label">{this.props.t("auto.process-stage-type")}</div>
					<Select value={x.required.type} onChange={(v) => { x.required.type = v; this.flushState() }}
						style={{ minWidth: "150px" }} >
						<Select.Option value="form">
							{this.props.t("auto.form")}
						</Select.Option>
						<Select.Option value="approval">
							{this.props.t("auto.approval")}
						</Select.Option>
						<Select.Option value="assignee">
							{this.props.t("auto.assignee")}
						</Select.Option>
						<Select.Option value="close">
							{this.props.t("auto.close-state")}
						</Select.Option>
					</Select>
					<Button type="primary" shape="round" danger onClick={
						() => {

							let original = this.state.stages;
							// eslint-disable-next-line eqeqeq
							let target = original.filter(y=>y!=x);
							original.length=0;
							target.forEach(x=>original.push(x));
							this.setState({
								stages:original
							})
						}
					} >
						{this.props.t("auto.delete")}
					</Button>
				</div>
			</List.Item>
		);
	}
	arrangeStage = () => {
		let stages = this.state.stages;
		let stageSorted = stages.sort((x, y) => x.stage > y.stage ? 1 : -1);
		for (var i = 0; i < stages.length; i++) {
			stageSorted[i].stage = i + 1;
		}
		this.setState({
			stages: stages
		})
	}

	render(): React.ReactNode {
		return (
			<div className="process-initial-editor">
				<div className="initial-button">
					<Button type="primary" onClick={this.addstage}>{this.props.t("auto.add-new-process")}</Button>
					<Button onClick={this.arrangeStage}>{this.props.t("auto.arrange-stage")}</Button>
				</div>
				<div className="process-list">
					<List>
						{this.renderListEditor()}
					</List>
				</div>
			</div>
		);
	}
}


export default withTranslation()(ProcessInitialView);



