import { Button, Popover } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { UserDto } from "../../../../../api";
import ApiServer from "../../../../../ApiServer";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { taskBatchOperationColumnProvider } from "../../../@mod";
import UserSelector from "../../../component/user-selector";
import "./index.less"

interface TaskBatchOperationPanelModel extends WithTranslation {
    taskList: any[]
    afterUpdateExecutor: () => void
    userList?: UserDto[]
    apiServer: ApiServer
}

interface TaskBatchOperationPanelState {
    selectedTasksForBatchOperation: any[]
    showUserSelector: boolean
    selectedUser: any
}



class TaskBatchOperationPanel extends React.Component<TaskBatchOperationPanelModel, TaskBatchOperationPanelState> {

    constructor(props: TaskBatchOperationPanelModel) {
        super(props)
        this.state = {
            selectedTasksForBatchOperation: [],
            showUserSelector: false,
            selectedUser: {}
        }
    }

    selectedTaskForBatchOperation = (taskList: any[]) => {
        this.setState({
            selectedTasksForBatchOperation: taskList
        })
    }

    selectedUser = (values: any) => {
        if (values) {
            this.updateExecutor(values)
        }
        this.setState({
            showUserSelector: false
        })
    }

    cancelSelectUser = () => {
        this.setState({ showUserSelector: false })
    }

    genUserSelector = () => {
        return (
            <UserSelector
                userList={this.props.userList}
                selectedUser={this.selectedUser}
                cancelSelect={this.cancelSelectUser}
                apiServer={this.props.apiServer} />
        )
    }

    updateExecutor = (selectedExecutor) => {
        let tasks = this.state.selectedTasksForBatchOperation
        tasks.forEach(t => {
            t.defaultExecutor = selectedExecutor.id
            t.defaultExecutorName = selectedExecutor.name
        })
        this.props.afterUpdateExecutor()
    }

    render(): React.ReactNode {
        return (
            <div className={"batch-operation-container"}>
                <div className="operation-bar">
                    <Popover
                        visible={this.state.showUserSelector && this.state.selectedTasksForBatchOperation.length > 0}
                        content={this.genUserSelector()}
                        trigger={"click"}
                    >
                        <Button
                            disabled={this.state.selectedTasksForBatchOperation.length === 0}
                            onClick={() => this.setState({ showUserSelector: true })}
                        >{this.props.t("project.template.update-executor")}</Button>
                    </Popover>
                </div>
                <WorkBenchTable<any>
                    hidePagination
                    rowKey={record => record.title}
                    data={this.props.taskList}
                    dataLoading={false}
                    columnProvider={() => taskBatchOperationColumnProvider(this.props, () => { })}
                    onSelected={this.selectedTaskForBatchOperation}
                />
            </div>
        )
    }

}

export default withTranslation()(TaskBatchOperationPanel)