import { Button, Input, Select, Statistic, Switch, Tag } from "antd";
import { title } from "process";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import './index.less';


export interface TicketCardViewModel extends WithTranslation {
    apiServer: ApiServer
    ticketNumber: number
}

interface TicketCardViewState {

}

class TicketCard extends React.Component<TicketCardViewModel, TicketCardViewState>{





    render(): React.ReactNode {
        return (
            <div>
                <div className="ticket-card-search">
                    <div className="search-option">
                        <Input.Group compact>
                            <Select style={{ width: '30%' }} defaultValue="subject"  >
                                <Select.Option value="subject" >标题</Select.Option>
                                <Select.Option value="subtitle" >子标题</Select.Option>
                                <Select.Option value="subtitle" >状态</Select.Option>
                                <Select.Option value="subtitle" >类型</Select.Option>
                                <Select.Option value="subtitle" >优先级</Select.Option>
                                <Select.Option value="subtitle" >来源</Select.Option>
                                <Select.Option value="subtitle" >标签</Select.Option>
                                <Select.Option value="subtitle" >创建人</Select.Option>
                            </Select>
                            <Input style={{ width: '70%', textAlign: "left" }} type={"text"}
                                onChange={(t) => this.setState({ searchText: t.target.value })} />
                        </Input.Group>

                    </div>
                    <div className="search-option">
                        <Button type="primary">添加</Button>
                        
                    </div>
                    <div className="search-option">
                        <Button>反向添加</Button>
                    </div>
                    <div className="search-option">
                        <Button>导出</Button>
                    </div>
                    <div className="search-option">
                        <Button>批量指派</Button>
                    </div>
                </div>
                <div className="ticket-card-filter">
                    <Tag closable>标题: Coupon</Tag>
                    <Tag closable>类型: 事故</Tag>
                    <Tag closable>优先级: 普通</Tag>
                    <Tag closable>创建人: 张岚-Lan(@zhanglan02)</Tag>
                </div>
                <div className="ticket-card-layout">
                    <div className="ticket-card-up">
                        <div className="ticket-left-bar">
                            <div>
                                <Tag>#19863</Tag>
                            </div>
                            <div>
                                张岚-Lan(@zhanglan02) 正在处理
                            </div>

                        </div>
                        <div className="ticket-right-bar">

                            <div className="time-watch">
                                <Statistic
                                    valueStyle={{ color: "red" }}
                                    value={this.props.t("tickets.time-up")} />
                            </div>
                            <div className="switch">

                                <Switch checkedChildren="私有" unCheckedChildren="公有"></Switch>
                            </div>
                        </div>
                    </div>
                    <div className="ticket-card-down">
                        <div className="ticket-card-down-left">
                            <div className="ticket-subject">
                                Coupon变动-ASIN:B09VSM1HM3-JP202203004-LY.emeetJP1
                            </div>
                            <div className="ticket-subtitle">
                                Coupon状态-Submitted[2022-10-17 06:00:00/2022-10-24 05:59:59/Beijing]
                            </div>

                        </div>
                        <div className="ticket-card-down-right">
                            <div className="source">
                                监控
                            </div>
                            <div className="type">
                                事故
                            </div>
                            <div className="priority">
                                普通
                            </div>
                        </div>
                    </div>
                    <div className="other-info">
                        <div className="ticket-tags">
                            <Tag >广告</Tag>
                            <Tag >运营</Tag>
                            <Tag >测试1</Tag>
                            <Tag >测试2</Tag>
                        </div>
                        <div className="audit-info">
                            <span style={{ color: "blue" }}>张岚-Lan(@zhanglan02)</span> 2022-12-21 20:22:22 创建
                        </div>
                    </div>
                </div>
                <div className="ticket-card-layout">
                    <div className="ticket-card-up">
                        <div className="ticket-left-bar">
                            <div>
                                <Tag>#19863</Tag>
                            </div>
                            <div>
                                张岚-Lan(@zhanglan02) 正在处理
                            </div>

                        </div>
                        <div className="ticket-right-bar">

                            <div className="time-watch">
                                <Statistic
                                    valueStyle={{ color: "red" }}
                                    value={this.props.t("tickets.time-up")} />
                            </div>
                            <div className="switch">

                                <Switch checkedChildren="私有" unCheckedChildren="公有"></Switch>
                            </div>
                        </div>
                    </div>
                    <div className="ticket-card-down">
                        <div className="ticket-card-down-left">
                            <div className="ticket-subject">
                                Coupon变动-ASIN:B09VSM1HM3-JP202203004-LY.emeetJP1
                            </div>
                            <div className="ticket-subtitle">
                                Coupon状态-Submitted[2022-10-17 06:00:00/2022-10-24 05:59:59/Beijing]
                            </div>

                        </div>
                        <div className="ticket-card-down-right">
                            <div className="source">
                                监控
                            </div>
                            <div className="type">
                                事故
                            </div>
                            <div className="priority">
                                普通
                            </div>
                        </div>
                    </div>
                    <div className="other-info">
                        <div className="ticket-tags">
                            <Tag >广告</Tag>
                            <Tag >运营</Tag>
                            <Tag >测试1</Tag>
                            <Tag >测试2</Tag>
                        </div>
                        <div className="audit-info">
                            <span style={{ color: "blue" }}>张岚-Lan(@zhanglan02)</span> 2022-12-21 20:22:22 创建
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(TicketCard);