import ApiServer from "../../../../../ApiServer";
import {TicketPriorityDto} from "../../../../../api";

export interface TicketPriorityModel {
    id: number,
    desc: { [key: string]: string }
}


export const convertPriorityDto2Model = (dto: TicketPriorityDto): TicketPriorityModel => {
    return {
        id: dto.id ?? 0,
        desc: dto.lang ?? {}
    }
}

export const getTicketPriorityList = async (apiServer: ApiServer): Promise<TicketPriorityModel[]> => {
    const resp = await apiServer.apiMeta.getTicketPriority(false)
    if (resp.data.data?.list) {
        return resp.data.data.list.map(x => convertPriorityDto2Model(x))
    }
    return []
}