import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import ProjectListView from "../component/project-list";
import { ProjectMetaDataModel, ProjectProcessModel } from "../@mod";
import { Button, message, Modal, Popconfirm } from "antd";
import ProjectDetailView from "../component/project-detail";
import ProjectTemplateEditor from "./editor";
import "./index.less"
import TaskMetaDataList from "../component/task-meta-data-list";

interface ProjectTemplateViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface ProjectTemplateViewState {
    projectList: ProjectMetaDataModel[]
    searchedProjectList: ProjectMetaDataModel[]
    selectedProjectMetaDataModel?: ProjectMetaDataModel
    processOfShownProject: ProjectProcessModel[],
    isShowTemplateEditor: boolean
    showProjectDetail: boolean
    disableProjectBaseInfoEditor: boolean
    isDraft: boolean
    showTaskMetaDataList: boolean
}

class ProjectTemplateView extends React.Component<ProjectTemplateViewModel, ProjectTemplateViewState> {
    constructor(props: ProjectTemplateViewModel) {
        super(props);
        this.state = {
            projectList: [],
            searchedProjectList: [],
            isShowTemplateEditor: false,
            showProjectDetail: false,
            processOfShownProject: [],
            disableProjectBaseInfoEditor: true,
            isDraft: false,
            showTaskMetaDataList: false
        }
    }


    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        this.props.apiServer.apiSystem.getLoginUser().then(r => {
            if (r.data.data?.user) {
                let loginUser = r.data.data?.user
                this.props.apiServer.apiProjectMeta.getMetaDataList(
                    undefined, loginUser?.id, undefined, undefined, undefined, undefined, undefined
                ).then(r => {
                    if (r.data.data?.list) {
                        let projectList: ProjectMetaDataModel[] = r.data.data.list?.map((metaData): ProjectMetaDataModel => {
                            return {
                                createdBy: metaData.createdBy ?? 0,
                                createdByName: metaData.createdByName ?? "",
                                createdTime: metaData.createdTime ?? "",
                                description: metaData.description ?? "",
                                id: metaData.id ?? 0,
                                owner: metaData.owner ?? 0,
                                ownerName: metaData.ownerName ?? "",
                                status: metaData.status ?? {},
                                template: metaData.template ?? "",
                                title: metaData.title ?? "",
                                updateBy: metaData.updatedBy ?? 0,
                                updateByName: metaData.updatedByName ?? "",
                                updateByTime: metaData.updatedTime ?? "",
                                version: metaData.version ?? ""
                            }
                        });
                        this.setState({
                            projectList: projectList,
                            searchedProjectList: projectList
                        })
                    }
                })
            }
        })
    }

    changeProjectStatus = (nextStatus: number, operate) => {
        if (this.state.selectedProjectMetaDataModel) {
            this.props.apiServer.apiProjectMeta.postProjectServiceStatus(this.state.selectedProjectMetaDataModel.id, {
                newStatus: nextStatus
            }).then(r => {
                if (r.data.data?.success) {
                    message.success(operate + this.props.t("project.template.success")).then()
                    this.loadData()
                    this.setState({
                        showProjectDetail: false
                    })
                } else {
                    message.success(operate + this.props.t("project.template.fail")).then()
                }
            })
        }
    }

    saveProjectBaseInfo = () => {
        if (this.state.selectedProjectMetaDataModel) {
            this.props.apiServer.apiProjectMeta.putProjectServiceMetaData(this.state.selectedProjectMetaDataModel.id, {
                description: ""
            }).then(r => {
            })
            this.setState({ disableProjectBaseInfoEditor: true })
        }
    }

    deleteDraft = () => {
        if (this.state.selectedProjectMetaDataModel) {
            this.props.apiServer.apiProjectMeta.deleteProjectDraft(this.state.selectedProjectMetaDataModel?.id).then(r => {
                if (r.data.data?.success) {
                    this.setState({
                        showProjectDetail: false
                    })
                    this.componentDidMount()
                    message.success(this.props.t("project.template.success")).then()
                } else {
                    message.error(this.props.t("project.template.fail")).then()
                }
            })

        }
    }

    genProjectDetailFooter = () => {
        let isDraft = this.state.selectedProjectMetaDataModel?.status.code === 0
        if (isDraft) {
            return (
                <div>
                    <Popconfirm title={this.props.t("project.template.confirm-delete")} onConfirm={this.deleteDraft}>
                        <Button danger>{this.props.t("project.template.delete-draft")}</Button>
                    </Popconfirm>
                    <Button type={"primary"} onClick={() => this.setState({ isShowTemplateEditor: true, isDraft: true })}>{this.props.t("project.template.continue-editing")}</Button>
                </div>
            )
        }
        let isPublished = this.state.selectedProjectMetaDataModel?.status.code === 1
        let publishBtnText = isPublished ? this.props.t("project.template.freeze") : this.props.t("project.template.active")
        let nextStatus = isPublished ? 2 : 1
        return (
            <div>
                <Button type={"primary"} onClick={this.showTaskMetaData}>
                    {this.props.t("project.template.task-manager")}
                </Button>
                <Button type={"primary"} danger onClick={this.updateTemplate}>
                    {this.props.t("project.template.upgrade-template")}
                </Button>
                <Popconfirm title={this.props.t("project.template.confirm-blank") + publishBtnText + "?"} onConfirm={() => this.changeProjectStatus(nextStatus, publishBtnText)}>
                    <Button type={"default"} danger>{publishBtnText}</Button>
                </Popconfirm>
            </div>
        )
    }

    updateTemplate = () => {
        this.setState({ isShowTemplateEditor: true, isDraft: false })
    }

    search = (values) => {
        let searchResult = this.state.projectList.filter(p => {
            let filter: boolean = true
            if (values.projectName !== undefined) {
                filter = p.title.toLowerCase().includes(values.projectName.toLowerCase())
            }
            if (values.owner !== undefined) {
                filter &&= p.ownerName.toLowerCase().includes(values.owner.toLowerCase())
            }
            return filter
        })
        this.setState({
            searchedProjectList: searchResult
        })
    }

    genSearchBar = () => {
        return (
            <div className={"operation-bar"}>
                {/*<Form*/}
                {/*    onFinish={this.search}*/}
                {/*    layout={"inline"}*/}
                {/*>*/}
                {/*    <FormItem label={this.props.t("project.template.project-title")} name={"projectName"}><Input/></FormItem>*/}
                {/*    <FormItem>*/}
                {/*        <Button className={"opt-btn"} type={"primary"} htmlType={"submit"}>{this.props.t("project.template.search")}</Button>*/}
                {/*    </FormItem>*/}
                {/*</Form>*/}
            </div>
        );
    }

    showTaskMetaData = () => {
        this.setState({
            showTaskMetaDataList: true
        })
    }

    onDetail = (model: ProjectMetaDataModel) => {
        this.setState({
            selectedProjectMetaDataModel: model,
            showProjectDetail: true
        });
    }

    genHeaderExtra = () => {
        return (
            (<Button danger type={"default"}
                onClick={() => {
                    this.setState({
                        isShowTemplateEditor: true,
                        selectedProjectMetaDataModel: undefined,
                        isDraft: true
                    })
                }}
            >{this.props.t("project.template.publish")}</Button>)
        )
    }

    onPublishSuccess = () => {
        this.setState({
            isShowTemplateEditor: false,
            showProjectDetail: false
        })
        this.componentDidMount()
    }

    hidenTemplateEditor = () => {
        this.componentDidMount()
        this.setState({
            isShowTemplateEditor: false,
            showProjectDetail: false
        })
    }

    render() {
        return (
            <div>
                <ProjectListView
                    apiServer={this.props.apiServer}
                    projectList={this.state.searchedProjectList}
                    headerTitle={this.props.t("project.template.template-title")}
                    headerSubTitle={this.props.t("project.template.template-sub-title")}
                    onDetail={this.onDetail}
                    genHeaderExtra={this.genHeaderExtra}
                    genSearchBar={this.genSearchBar}
                />
                <Modal
                    title={this.props.t("project.template.editor")}
                    visible={this.state.isShowTemplateEditor}
                    onCancel={this.hidenTemplateEditor}
                    footer={null}
                    width={1600}
                    zIndex={1000}
                    destroyOnClose={true}
                    maskClosable={false}
                    keyboard={false}
                >
                    <ProjectTemplateEditor
                        templateId={this.state.selectedProjectMetaDataModel?.id}
                        apiServer={this.props.apiServer}
                        projectTemplate={this.state.selectedProjectMetaDataModel?.template}
                        isDraft={this.state.isDraft}
                        onPublishSuccess={this.onPublishSuccess}
                    />
                </Modal>

                <Modal
                    visible={this.state.showProjectDetail}
                    onCancel={() => this.setState({ showProjectDetail: false })}
                    footer={this.genProjectDetailFooter()}
                    destroyOnClose={true}
                >
                    <div className="padding-16px">
                        <ProjectDetailView
                            projectToShow={this.state.selectedProjectMetaDataModel}
                            projectProcess={this.state.processOfShownProject}
                            disableEditor={this.state.disableProjectBaseInfoEditor}
                        />
                    </div>

                </Modal>

                <Modal
                    visible={this.state.showTaskMetaDataList}
                    onCancel={() => this.setState({ showTaskMetaDataList: false })}
                    title={this.props.t("project.template.task.meta.list-title")}
                    destroyOnClose={true}
                    width={1600}
                    style={{ top: 20 }}
                    footer={null}
                >
                    <TaskMetaDataList
                        serviceId={this.state.selectedProjectMetaDataModel?.id}
                        apiServer={this.props.apiServer}
                    />
                </Modal>
            </div>
        )
    }
}

export default withTranslation()(ProjectTemplateView)