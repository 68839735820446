import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ChangedModel, PriorityModel, SourceModel, StatusModel, TicketTypeModel } from "../../@mod";
import { CaretRightOutlined } from '@ant-design/icons'
import "./index.less"

export interface StatusChangeViewModel extends WithTranslation {
	ticketStatusList: StatusModel[]
	ticketPriorityList: PriorityModel[]
	ticketSourceList: SourceModel[]
	ticketTypeList: TicketTypeModel[]
	changeModel: ChangedModel[]
}


interface StatusChangeViewState {

}

class StatusChangeView extends React.Component<StatusChangeViewModel, StatusChangeViewState>{

	renderStateChange = () => {

		return this.props.changeModel.map((x,index) => {
			let beforeDescription: any;
			let afterDescription: any;
			if (x.type === "status") {
				let statusBefore = this.props.ticketStatusList.filter(y => y.id === x.before)[0];
				let statusAfter = this.props.ticketStatusList.filter(y => y.id === x.after)[0];
				if (statusBefore && statusAfter) {
					beforeDescription = statusBefore.description;
					afterDescription = statusAfter.description;
				}

			} 
			if (x.type === "priority") {
				let priorityBefore = this.props.ticketPriorityList.filter(y => y.id === x.before)[0];
				let priorityAfter = this.props.ticketPriorityList.filter(y => y.id === x.after)[0];
				if (priorityBefore && priorityAfter) {
					beforeDescription = priorityBefore.description;
					afterDescription = priorityAfter.description;
				}

			}

			if (x.type === "source") {
				let sourceBefore = this.props.ticketSourceList.filter(y => y.id === x.before)[0];
				let sourceAfter = this.props.ticketSourceList.filter(y => y.id === x.after)[0];
				if (sourceBefore && sourceAfter) {
					beforeDescription = sourceBefore.description;
					afterDescription = sourceAfter.description;
				}

			}

			if (x.type === "type") {
				let typeBefore = this.props.ticketTypeList.filter(y => y.id === x.before)[0];
				let typeAfter = this.props.ticketTypeList.filter(y => y.id === x.after)[0];
				if (typeBefore && typeAfter) {
					beforeDescription = typeBefore.description;
					afterDescription = typeAfter.description;
				}
			}

			if (beforeDescription && afterDescription) {
				return (
					<div className="state-change-view" key={index}>
						<div className="item">
							{beforeDescription[this.props.i18n.language]}

						</div>
						<div className="pointer">
							<CaretRightOutlined />
						</div>
						<div className="item">
							{afterDescription[this.props.i18n.language]}
						</div>
					</div>
				)
			}
			return (<></>);
		})
	}


	render(): React.ReactNode {
		return (
			<div>
				{this.renderStateChange()}
			</div>
		)
	}

}

export default withTranslation()(StatusChangeView);