import { Tabs } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import './index.less'
import MailBoxList from '../../box-list'
import { loadMyMailBoxes, MailBoxModel, myMailBoxColumnProvider, TagModel } from "../../@mod";
import BoxDetail from "./box-detail";


export interface MyMailBoxesViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface MyMailBoxesViewState {
    activeView: string,
    dataLoading: boolean,
    mailBoxes: MailBoxModel[],
    mailBoxesOnView: MailBoxModel[]

}

class MyMailBoxes extends React.Component<MyMailBoxesViewModel, MyMailBoxesViewState>{

    constructor(props: MyMailBoxesViewModel) {
        super(props)
        this.state = {
            activeView: "default",
            dataLoading: false,
            mailBoxes: [],
            mailBoxesOnView: []
        }
    }
    loadData = async () => {
        this.setState(
            {
                mailBoxes: [],
                mailBoxesOnView: []
            }
        )
        var mailBoxes = await loadMyMailBoxes(this.props.apiServer)
        this.setState(
            {
                mailBoxes: mailBoxes,
                mailBoxesOnView: []
            }
        )
    }

    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.setState({
                        dataLoading: false
                    })
                })
        }
    }

    onNavigate = (box: MailBoxModel) => {
        let onView = this.state.mailBoxesOnView
        if (onView.findIndex(x => x.id === box.id) >= 0) {
            this.activeKey(String(box.id))
        } else {
            onView.push(box);
            this.setState({
                mailBoxesOnView: onView
            })
        }
    }

    aggreateTags = ()=>{
        let tags: TagModel[] = [];
        this.state.mailBoxes.forEach(x=>{
            x.tags?.forEach(t=>{
                if(tags.findIndex(q=>q.id===t.id)<0){
                    tags.push(t);
                }
            })
        })
        return tags;
    }



    renderTabs = () => {
        let tabs: React.ReactNode[] = [];
        tabs.push(
            <Tabs.TabPane closable={false} tab={this.props.t("mails.my-boxes")} key={"default"}>
                <div>
                    <div className="function-bar">
                        <div className="left">

                        </div>
                        <div className="right">

                        </div>
                    </div>
                    <MailBoxList mailBoxes={this.state.mailBoxes}
                        dataLoading={this.state.dataLoading}
                        onNavigate={this.onNavigate}
                        columnTypeProvider={(x) => myMailBoxColumnProvider(this.props.t, x, this.props.apiServer,undefined,this.aggreateTags())}
                    />
                </div>
            </Tabs.TabPane>
        )

        this.state.mailBoxesOnView.forEach(x => {
            tabs.push(
                <Tabs.TabPane closable={true} tab={x.emailAddress} key={String(x.id)} style={{ height: "100%" }} >
                    <BoxDetail apiServer={this.props.apiServer} box={x} />
                </Tabs.TabPane>
            );

        });




        return tabs;
    }

    activeKey = (k: string) => {
        this.setState({
            activeView: k
        })
    }

    editTabs = (e, a) => {
        if (a === "remove") {
            let onView = this.state.mailBoxesOnView;
            onView = onView.filter(x => String(x.id) !== e);
            this.setState({
                mailBoxesOnView: onView
            })
        }

        console.log(e, a)

    }

    render(): React.ReactNode {
        return (
            <Tabs type="editable-card"
                hideAdd={true}
                onTabClick={this.activeKey}
                activeKey={this.state.activeView}
                onEdit={this.editTabs}
                tabPosition="top"
                className="detail-tab"

            >

                {this.renderTabs()}
            </Tabs>
        )
    }
}


export default withTranslation()(MyMailBoxes);

