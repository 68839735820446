import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import "./index.less"
import {MailApprovalModel} from "../@mod";
import ApiServer from "../../../../ApiServer";
import {Button, Descriptions, notification} from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import moment from "moment-timezone";
import {MailSendingDto} from "../../../../api";
import {LinkOutlined} from "@ant-design/icons";

interface EMailDetailModel extends WithTranslation {
    email?: MailApprovalModel
    apiServer: ApiServer
}

interface EmailDetailState {
    details: MailSendingDto,
    cc: string[],
    bcc: string[],
    to: string[],
    mailContent: string
}

class EmailDetail extends React.Component<EMailDetailModel, EmailDetailState> {
    constructor(props: EMailDetailModel) {
        super(props);
        this.state = {
            details: {},
            cc: [],
            bcc: [],
            to: [],
            mailContent: ""
        }
    }

    cidMap: any = {}

    componentDidMount() {
        this.loadDetails()
        this.loadMailboxContacts()
    }

    loadDetails = () => {
        if (this.props.email?.mailBoxId && this.props.email.id) {
            this.props.apiServer.apiMail.getSendingMailDetail(this.props.email?.mailBoxId, this.props.email?.id).then(x => {
                if (x.data.data?.success) {
                    var details = x.data.data.sendingMail;
                    this.replaceCidAsRemoteRef(details?.detail?.body ?? "", details?.detail?.cid ?? []).then(x => this.setState({
                        mailContent: x
                    }))
                    this.setState({
                        details: details ?? {}
                    })
                }
            })
        }
    }

    loadMailboxContacts = () => {
        if (this.props.email?.mailBoxId) {
            this.props.apiServer.apiMail.getAggregatedContactsByMailbox(this.props.email?.mailBoxId).then(x => {
                if (x.data.data?.dto?.contacts) {
                    const contacts = x.data.data?.dto?.contacts;
                    this.setState({
                        cc: contacts["cc"].map(x => x.emailContact?.emailAddress ?? ""),
                        bcc: contacts["bcc"].map(x => x.emailContact?.emailAddress ?? ""),
                        to: contacts["to"].map(x => x.emailContact?.emailAddress ?? "")
                    })
                }
            })
        }

    }

    renderContacts = (contacts: Array<string>): React.ReactNode => {
        if (contacts.length === 0) {
            return <span>{this.props.t("mails.approval.no-contacts")}</span>
        }
        return (
            <div>
                {contacts.map(c => <span style={{
                    marginRight: "8px",
                    color: this.state.to.filter(t => t === c).length > 0 ? "black" : "red"
                }}>{c}</span>)}
            </div>
        )
    }

    renderAttachments = (): React.ReactNode => {
        return <div>
            {
                this.state.details.detail?.attachments?.map((a, index) => {
                        const attachment = JSON.parse(a);
                        return <li key={index}>
                            <Button icon={<LinkOutlined/>} type={"link"}
                                    onClick={() => this.downloadFile(attachment["uid"], attachment["fileName"])}> {attachment["fileName"]} </Button>
                        </li>
                    }
                )
            }
        </div>
    }

    replaceCidAsRemoteRef = async (body: string, cids: string[]) => {
        let displayValue = body
        for (var i = 0; i < cids.length; i++) {
            var cid = JSON.parse(cids[i]);
            var letCid = "cid:" + cid.uid + "/" + cid.fileName;
            var downloadUrl = "";
            if (this.cidMap[letCid]) {
                if (moment().add(-20, "minute") < this.cidMap[letCid].presignDate) {
                    downloadUrl = this.cidMap[letCid].presignUrl;
                }
            } else {
                downloadUrl = await this.getDownloadUrl(cid.uid, cid.fileName);
                console.log("cid: ", cid);
                this.cidMap[letCid] = {
                    presignDate: moment(),
                    presignUrl: downloadUrl
                }
            }
            displayValue = displayValue.replace(letCid, downloadUrl);

        }
        return displayValue;
    }

    getDownloadUrl = (uid, fileName) => {
        return this.props.apiServer.apiMail.getSendingEmailAttachmentTarget(
            this.props.email?.mailBoxId ?? 0,
            this.props.email?.id ?? 0,
            fileName,
            uid,
            "download"
        ).then(x => {
            return x.data.data?.url ?? ""
        })
    }

    downloadFile = (uid, fileName) => {
        this.getDownloadUrl(uid, fileName)
            .then(x => {
                if (x) {
                    var element = document.createElement('a');
                    element.target = "_blank";
                    element.href = x;
                    document.body.appendChild(element)
                    element.click()
                    URL.revokeObjectURL(element.href);
                    document.body.removeChild(element);
                } else {
                    notification.warn({message: this.props.t("mails.download-attachment-fail")})
                }
            })
    }

    render() {
        return (
            <div className={"email-detail-container"}>
                {
                    this.props.email && (
                        <div>
                            <div className={"email-info-card"}>
                                <div className={"card-title"}>{this.props.t("mails.approval.base-info")}</div>
                                <div className="card-content">
                                    <Descriptions column={4}>
                                        <DescriptionsItem children={this.state.details.id} key={0}
                                                          label={this.props.t("mails.approval.id")}/>
                                        <DescriptionsItem children={this.state.details.mailBox?.emailAddress} key={4}
                                                          label={this.props.t("mails.approval.mailBox")}/>
                                        <DescriptionsItem children={this.state.details.subject} key={1}
                                                          label={this.props.t("mails.approval.emailTitle")}/>
                                        <DescriptionsItem children={this.state.details.description} key={5}
                                                          label={this.props.t("mails.approval.emailDesc")}/>
                                        <DescriptionsItem children={this.state.details.creator?.fullName} key={3}
                                                          label={this.props.t("mails.approval.creator")}/>
                                        <DescriptionsItem
                                            children={moment.parseZone(this.state.details.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                            key={7}
                                            label={this.props.t("mails.approval.createTime")}/>
                                        <DescriptionsItem children={this.state.details.updater?.fullName} key={2}
                                                          label={this.props.t("mails.approval.updater")}/>
                                        <DescriptionsItem
                                            children={moment.parseZone(this.state.details.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                                            key={6}
                                            label={this.props.t("mails.approval.updateTime")}/>

                                    </Descriptions>
                                </div>
                            </div>
                            <div className={"email-info-card"}>
                                <div className={"card-title"}>{this.props.t("mails.approval.contacts")}</div>
                                <div className="card-content">
                                    <Descriptions column={1}>
                                        <DescriptionsItem
                                            children={this.renderContacts(this.state.details.detail?.to ?? [])} key={0}
                                            label={this.props.t("mails.approval.send-to")}/>
                                        <DescriptionsItem
                                            children={this.renderContacts(this.state.details.detail?.cc ?? [])}
                                            key={1}
                                            label={this.props.t("mails.approval.send-cc")}/>
                                        <DescriptionsItem
                                            children={this.renderContacts(this.state.details.detail?.bcc ?? [])} key={2}
                                            label={this.props.t("mails.approval.send-bcc")}/>
                                    </Descriptions>
                                </div>
                            </div>

                            <div className={"email-info-card content-attachment"}>
                                <div className={"mail"}>
                                    <div className={"card-title"}>{this.props.t("mails.approval.mail-content")}</div>
                                    <div className="card-content mail-content">
                                        <div
                                            dangerouslySetInnerHTML={{__html: this.state.mailContent}}></div>
                                    </div>
                                </div>
                                <div className={"attachment"}>
                                    <div className={"card-title"}>{this.props.t("mails.approval.attachment")}</div>
                                    <div className="card-content attachment-list">
                                        {this.renderAttachments()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

export default withTranslation()(EmailDetail)