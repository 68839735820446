import { Collapse, Pagination, Skeleton, Statistic, Tag } from "antd";
import Countdown from "antd/lib/statistic/Countdown";
import moment from "moment";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../ApiServer";
import { RouteContext, withRouter } from "../../utils";
import { convertTo, TicketModel } from "./@mod";
import "./index.less"

export interface TicketLandingPageModel extends WithTranslation {
	apiServer: ApiServer
	router?: RouteContext
}

export interface TicketLandingPageState {
	loading: boolean,
	loginUserName: string
	loginUserOrg: string
	loginUserId: number,
	currentTime: string,
	relatedTicket: TicketModel[],
	start: number,
	end: number,
	closeStart: number,
	closeEnd: number

}

class TicketLandingPage extends React.Component<TicketLandingPageModel, TicketLandingPageState>{

	constructor(props: TicketLandingPageModel) {
		super(props)
		this.state = {
			loading: true,
			loginUserName: "",
			loginUserId: 0,
			loginUserOrg: "",
			currentTime: moment().format(this.props.t("time-pattern-long-second")),
			relatedTicket: [],
			start: 0,
			end: 5,
			closeStart: 0,
			closeEnd: 5
		}
		setInterval(() => {
			this.setState({
				currentTime: moment().format(this.props.t("time-pattern-long-second"))
			})
		}, 1000)
	}

	loadData = async () => {
		this.setState({
			loading: true
		})
		await this.props.apiServer.apiSystem.getLoginUser()
			.then(x => {
				this.setState({
					loginUserName: (x.data.data?.user?.fullName ?? "") + ("(@" + (x.data.data?.user?.name ?? "") + ")"),
					loginUserOrg: x.data.data?.user?.organization ?? "",
					loginUserId: x.data.data?.user?.id ?? 0
				})
			});

		await this.props.apiServer.apiTickets.getRelatedList()
			.then(x => {
				if (x.data.data?.tickets) {
					this.setState({
						relatedTicket: x.data.data?.tickets?.map(convertTo)
					})
				}
			}
			);

		this.setState({
			loading: false
		})

	}

	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData().finally(
				() => { this.l = undefined; }
			)
		}

	}

	ticketClicked = (model: TicketModel) => {

	}

	relatedPageChanged = (page: number, pageSize: number) => {
		console.log(page, pageSize);

		let start = (page - 1) * (pageSize);
		let end = page * (pageSize);
		console.log(start, end);
		this.setState({
			start: start,
			end: end
		});
	}

	assignToMePageChanged = (page: number, pageSize: number) => {
		let start = (page - 1) * (pageSize);
		let end = page * (pageSize);
		console.log(start, end);
		this.setState({
			closeStart: start,
			closeEnd: end
		});
	}


	render(): React.ReactNode {
		return (
			<div className="dashboard-container" >
				<div className="time-welcome">
					{
						this.state.loading &&
						<Skeleton.Input active size="default" block ></Skeleton.Input>
					}
		
					{
						!this.state.loading &&
						<div className="welcome">
							{this.props.t("tickets.welcome")}
						</div>
								/*
						<div className="welcome">
							<div className="welcome-label">{this.props.t("tickets.welcome")}</div>
							<div className="welcome-principal">{this.state.loginUserName} - {this.state.loginUserOrg}</div>
							<div className="welcome-label">{this.props.t("tickets.current-time")}</div>
							<div className="welcome-timmer">{this.state.currentTime}</div>
						</div>*/
					}
				</div>
				<div>
					{this.state.loading && <Skeleton />}
					{
						!this.state.loading &&
						<div className="topbar">
							<div className="stats">
								<div className="label">{this.props.t("tickets.assign-to-me")}</div>
								<div className="value">{this.state.relatedTicket.filter(x=>x.assigned?.id===this.state.loginUserId).length}</div>
							</div>
							<div className="stats">
								<div className="label">{this.props.t("tickets.related-tickets")}</div>
								<div className="value">{this.nonClosedTicket.length}</div>
							</div>
							<div className="stats">
								<div className="label">{this.props.t("tickets.over-due")}</div>
								<div className="value">{this.overDue.length}</div>
							</div>
							<div className="stats">
							<div className="label">{this.props.t("tickets.close-over-due")}</div>
								<div className="value">{this.closeOverDue.length}</div>
							</div>
							<div className="stats">
							<div className="label">{this.props.t("tickets.critical")}</div>
								<div className="value">{this.critical.length}</div>
							</div>
							<div className="stats">
							<div className="label">{this.props.t("tickets.in-process")}</div>
								<div className="value">{this.runningTickets.length}</div>
							</div>
						</div>
					}
				</div>
				<div >
					{this.state.loading && <Skeleton />}
					{!this.state.loading &&
						<div className="summary-container">
							<div className="ticket-list">
								<div className="label">
									{this.props.t("tickets.related-tickets")}
								</div>
								<div>
									<Collapse accordion>
										{this.getPanels()}
									</Collapse>
								</div>
								<div className="pagination">
									<Pagination
										pageSize={5}
										defaultCurrent={1}
										total={this.nonClosedTicket.length}
										onChange={this.relatedPageChanged}
									/>
								</div>
							</div>
							<div className="ticket-list">
								<div className="label">
									{this.props.t("tickets.assign-to-me")}
								</div>
								<div>
									<Collapse accordion >
										{this.getAssignToMePanels()}
									</Collapse>
								</div>
								<div className="pagination">
									<Pagination onChange={this.assignToMePageChanged}
										pageSize={5}
										defaultCurrent={1} total={this.assignToMe.length} />
								</div>
							</div>
						</div>
					}
				</div>

			</div>
		)
	}

	getPanels = () => {
		return this.nonClosedTicket.slice(
			this.state.start,
			this.state.end
		).map(x => {
			return (
				<Collapse.Panel header={this.renderTicketHeader(x)} key={x.id}>
					{this.renderTicket(x)}
				</Collapse.Panel>
			)
		})
	}

	get assignToMe(){
		return this.state.relatedTicket.filter(x=> x.assigned?.id === this.state.loginUserId);
	}

	get nonClosedTicket() {
		return this.state.relatedTicket.filter(x => x.status.name !== "CLOSE");
	}

	get closeTickets() {
		return this.state.relatedTicket.filter(x => x.status.name === "CLOSE");
	}

	get runningTickets(){
		return this.state.relatedTicket.filter(x=>x.status.name==="IN_PROCESS");
	}

	getAssignToMePanels = () => {
		return this.assignToMe.slice(
			this.state.closeStart,
			this.state.closeEnd
		).map(x => {
			return (
				<Collapse.Panel header={this.renderTicketHeader(x)} key={x.id}>
					{this.renderTicket(x)}
				</Collapse.Panel>)
		})
	}

	renderTicket = (t: TicketModel) => {

		return (
			<div className="ticket-info">
				<Statistic
					title={this.props.t("tickets.source.self")}
					value={t?.source.description[this.props.i18n.language]}
				/>
				<Statistic title={this.props.t("tickets.type.self")}
					value={t?.type.description[this.props.i18n.language]}
				/>
				<Statistic
					title={this.props.t("tickets.priority.self")}
					value={t?.priority.description[this.props.i18n.language]}
					valueStyle={{
						color: t?.priority.description["color"]
					}}
				/>
				{this.getDeadLine(t)}
			</div>)
	}

	get overDue(){
		return this.nonClosedTicket.filter(t=>{
			var sla = moment.parseZone(t?.sla)
			let dur = moment.duration(sla.diff(moment.now()));
			return dur.asHours() < 0;
		})
	}

	get closeOverDue(){
		return this.nonClosedTicket.filter(t=>{
			var sla = moment.parseZone(t?.sla)
			let dur = moment.duration(sla.diff(moment.now()));
			return dur.asHours() < 2 && dur.asHours() > 0;
		})
	}

	get critical(){
		return this.nonClosedTicket.filter(
			t=>t.priority.name==="CRITICAL"
		);
	}

	getDeadLine = (t: TicketModel) => {

		if (t?.sla) {
			var sla = moment.parseZone(t?.sla)
			let dur = moment.duration(sla.diff(moment.now()));
			if (dur.asHours() < 0) {
				return (
					<Statistic title={this.props.t("tickets.time-left")}
						valueStyle={{ color: "red" }}
						value={this.props.t("tickets.time-up")}></Statistic>
				)

			} else {
				return (
					<Countdown title={this.props.t("tickets.time-left")}
						value={Date.now() + dur.asMilliseconds()}
						format={"D|HH:mm:ss"}
					/>
				)
			}
		}

	}
	renderTicketHeader = (t: TicketModel) => {
		return (
			<div className="ticket-header">
				<div className="ticket-subject">
					{t.subject}
				</div>
				<div className="ticket-subtitle">
					<Tag>{t.status.description[this.props.i18n.language]}</Tag>{t.subtitle}
				</div>
			</div>)
	}
}


export default withRouter(withTranslation()(TicketLandingPage))