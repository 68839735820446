import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import {Button, Form, Modal, Select, Tag} from "antd";
import TemplateContentView from "../../template/component/template-content-view";
import ApiServer from "../../../../ApiServer";
import {MailTemplateModel} from "../../template/@mod";

interface TemplateSelectorModel extends WithTranslation {
    apiServer: ApiServer,
    selected: (values) => void,
    templates: MailTemplateModel[],
    btnText: string
}

interface TemplateSelectorState {
    showTemplate: boolean
    selectedTemplateIdToView?: number
}

class TemplateSelector extends React.Component<TemplateSelectorModel, TemplateSelectorState> {

    constructor(props: TemplateSelectorModel) {
        super(props);
        this.state = {
            showTemplate: false
        }
    }

    viewContentOfTemplate = (e: React.MouseEvent<HTMLElement, MouseEvent>, templateId: number) => {
        e.stopPropagation()
        if (templateId < 0) {
            return
        }
        this.setState({
            selectedTemplateIdToView: templateId,
            showTemplate: true
        })

    }

    render() {
        return <div className="template-selector">
            <Form
                layout="inline"
                onFinish={this.props.selected}
            >
                <Form.Item
                    label={this.props.t("mails.mail-template.template")}
                    name={"templateId"}
                    rules={[{required: true, message: this.props.t("mails.mail-template.select-template")}]}
                >
                    <Select style={{width: 400}}>
                        {
                            this.props.templates.map(m => {
                                return <Select.Option children={(
                                    <div>
                                        <span style={{marginRight: '8px'}}>{m.title}</span>
                                        {m.tags?.map(t => <Tag color={t.color} children={t.name}/>)}
                                        <Button type="link" size="small"
                                                onClick={(e) => this.viewContentOfTemplate(e, m.id ?? -1)}
                                                style={{float: "right"}}>
                                            {this.props.t("mails.mail-template.view")}
                                        </Button>
                                    </div>
                                )} key={m.id} value={m.id}/>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">{this.props.btnText}</Button>
                </Form.Item>
            </Form>

            <Modal
                title={this.props.t("mails.mail-template.template-view")}
                visible={this.state.showTemplate}
                onCancel={() => this.setState({showTemplate: false})}
                width={1600}
                destroyOnClose
                footer={null}
                zIndex={1500}
            >
                <div style={{overflow: "auto"}}>
                    <TemplateContentView apiServer={this.props.apiServer}
                                         templateId={this.state.selectedTemplateIdToView ?? -1}/>
                </div>
            </Modal>
        </div>;
    }
}

export default withTranslation()(TemplateSelector)