import React from "react";
import './index.less';
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import { Button, notification, Popover, Tooltip } from "antd";
import DefaultTextEditor from "../default-text-editor";
import {
	PlusOutlined
} from "@ant-design/icons"
import FormTemplateSelector from "../form-template-selector";
import FormEditor from "../form-editor";

interface DescriptionViewModel extends WithTranslation {
	ticketId: number,
	contentId: number,
	apiServer: ApiServer
}

interface DescriptionViewState {
	text?: ContentModel,
	popupVisiable: boolean[]
}

interface ContentModel {

	[key: string]: ContentItemModel
}

interface ContentItemModel {
	type: string,
	schema?: number,
	filter?: any,
	value: string,
	canEdit?: boolean
}


class DescriptionView extends React.Component<DescriptionViewModel, DescriptionViewState>{

	constructor(props: DescriptionViewModel) {
		super(props)
		this.state = {
			text: undefined,
			popupVisiable: [false, false]
		}
	}

	componentDidUpdate() {

	}

	getSnapshotBeforeUpdate(beforeProps: DescriptionViewModel, beforeState: DescriptionViewState) {
		if (this.props.contentId !== beforeProps.contentId) {
			if (this.props.contentId !== 0) {
				this.props.apiServer.apiTickets.getTicketContent(this.props.ticketId, this.props.contentId)
					.then(x => x.data.data?.contentDto?.content ?? "")
					.then(x => {
						try {
							let contentModel: ContentModel | undefined = JSON.parse(x);
							if (contentModel) {
								this.setState({ text: contentModel })
							}
						} catch {
							notification.error({ message: this.props.t("tickets.content-parse-fail") })
						}
					})
			}
		}
		return null;
	}


	saveData = (k: string, v: ContentItemModel) => {
		let contentModel = this.state.text;
		if (contentModel) {
			contentModel[k] = v;
		}
		this.saveDescription(contentModel);

	}

	saveDescription = (v?: ContentModel) => {
		if (v) {
			let contentModel = v;
			this.props.apiServer.apiTickets.putTicketContent(this.props.ticketId, this.props.contentId,
				{
					data: JSON.stringify(contentModel)
				}).then(x => {
					if (x.data.data?.succeed) {
						notification.success({ message: this.props.t("tickets.content-update-succeed") })
						this.setState({
							text: contentModel
						});
					} else {
						notification.error({ message: this.props.t("tickets.content-update-fail") })
					}
				})
		}
	}

	getUploadUrl = (uid: string, fileName: string): Promise<{ url: string, id: number }> => {
		return this.props.apiServer.apiAttachment
			.putNewUploadRequest(
				{
					fileName: uid + fileName
				}
			).then(x => {
				if (x.data.data?.succeed) {
					return {
						url: x.data.data?.attachmentDto?.uploadUrl ?? "",
						id: x.data.data?.attachmentDto?.id ?? 0
					};
				}
				return {
					url: "",
					id: 0
				}
			}

			)
	}

	getDownloadUrl = (id:number): Promise<{ url: string, id: number }> =>{
		return this.props.apiServer.apiAttachment
		.getAttachment(id).then(
			q=>{
				if(q.data.data?.succeed){
					return {
						url : q.data.data.attachmentInfo?.downloadUrl??"",
						id : id
					}
				}
				return {
					url: "",
					id: 0
				}
			}
		)

	}

	get getEditor() {
		if (this.state.text) {
			let contentModel: ContentModel = this.state.text;
			let editors: React.ReactNode[] = [];
			for (let k in contentModel) {
				let docName = k
				if (docName === "default") {
					editors.push(<DefaultTextEditor key={k} onCommit={(v) => {
						this.saveData(k, {
							"type": "text",
							value: v
						});

					}} getUploadUrl={this.getUploadUrl} getDownloadUrl={ this.getDownloadUrl } value={contentModel[k].value} />)

				} else {
					if (contentModel[k].type === "form") {
						editors.push((<FormEditor
							onCommit={(v) => {
								this.saveData(k, {
									"type": "form",
									value: v,
									schema: contentModel[k].schema,
									canEdit: true
								})
							}}
							canRemove={true}
							onRemove={() => this.removeForm(k)}
							apiServer={this.props.apiServer}
							schemaId={contentModel[k].schema ?? 0}
							content={contentModel[k].value}
							canEdit={contentModel[k].canEdit ?? true}
							key={k}></FormEditor>))
					}
				}
			}
			return (<div>{editors}</div>)

		}
		return (<></>)

	}

	removeForm = (field: string) => {
		let contentModel = this.state.text;
		if (contentModel) {
			if (contentModel[field]) {
				let newConent: ContentModel = {

				};
				for (let k in contentModel) {
					if (k !== field) {
						newConent[k] = contentModel[k];
					}
				}
				this.setState(
					{
						text: newConent
					}
				)
				this.saveDescription(newConent);
			}
		}
	}

	newFormCreated = (i: number) => {
		let visiable = this.state.popupVisiable;
		visiable = visiable.map(x => false)
		this.setState({
			popupVisiable: visiable
		})

		let contentModel = this.state.text;
		if (contentModel) {
			if (!contentModel["form_" + i]) {
				contentModel["form_" + i] = {
					"type": "form",
					"value": "{}",
					schema: i,
				}
				console.log(contentModel)
				this.setState({
					text: contentModel
				})
				this.saveDescription(contentModel);
			}
			else {
				notification.warn({ message: this.props.t("tickets.form-exists") })
			}
		}
	}

	popupVisiableChange = (i: number, v: boolean) => {
		let vcontrol = this.state.popupVisiable;
		vcontrol[i] = v;
		this.setState({
			popupVisiable: vcontrol
		})

	}

	getAddFormButton = (index: number) => {
		return (
			<Popover trigger="click"
				placement="left"
				destroyTooltipOnHide
				onVisibleChange={(v) => this.popupVisiableChange(index, v)}
				visible={this.state.popupVisiable[index]}
				content={<FormTemplateSelector
					onNewFormActed={this.newFormCreated}
					cancle={() => { }}
					apiServer={this.props.apiServer}></FormTemplateSelector>}>
				<Tooltip title={this.props.t("tickets.add-form")}  >
					<Button type="primary"
						shape="circle"
						icon={<PlusOutlined />}
					/>
				</Tooltip>
			</Popover>);
	}


	render(): React.ReactNode {
		return (
			<div className="description-view-container" >
				<div className="description-control-bar">
					{this.getAddFormButton(1)}
				</div>
				{this.getEditor}
				<div className="description-control-bottom-bar">
					{this.getAddFormButton(2)}
				</div>
			</div>
		)
	}
}
export default withTranslation()(DescriptionView)