import { Button, Select } from "antd";

import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { UserModel } from "../../@mod";
import './index.less'
import {KeyOutlined}from "@ant-design/icons"



export interface AccessUserSelectorViewModel extends WithTranslation {
    user: UserModel[],
    userIdNotDisplay: number[],
    onUserSelected?: (ids: number[]) => void
    
}

interface AccessUserSelectorViewState {
    selected :string[]
}


class AccessUserSelector extends React.Component<AccessUserSelectorViewModel, AccessUserSelectorViewState>{


    constructor(props: AccessUserSelectorViewModel) {
        super(props)
        this.state = {
            selected:[]
        }

    }

    authorizeClicked = ()=>{
        if(this.props.onUserSelected){
            this.props.onUserSelected(this.state.selected.map(x=>Number(x)));
            this.setState({
                selected : []
            })
        }
        
    }

    renderUserOption = () => {
        return this.props.user.filter(x=>this.props.userIdNotDisplay.findIndex(y=>String(y)===x.key) <0 ).map(x => {
            return (<Select.Option key={x.key} >
                {x.fullName}{"(@" + x.name + ")"}
            </Select.Option>
            )
        })

    }



    render(): React.ReactNode {
        return (<div className="access-user-selector">
            <Select mode="multiple" value={this.state.selected}
            style={{width:"300px"}}
                onChange={
                    (q)=>{
                        this.setState({
                            selected:q
                        })
                    }
                }
                filterOption= {
                    (inputValue: string, option?: any)=>{
                        let insearch = false;
                        if(option.children[0]){
                            insearch = option.children[0].indexOf(inputValue) >=0;
                        }
                        if(!insearch && option.children[1]){
                            insearch = option.children[1].indexOf(inputValue) >=0;
                        }
                        return insearch;
                    }
                }
            >
                

                {this.renderUserOption()}
            </Select>
            <Button onClick={this.authorizeClicked} disabled={this.state.selected.length===0} icon={<KeyOutlined/>} type="primary">{this.props.t("mails.access-control.grant")}</Button>
        </div>)
    }
}


export default withTranslation()(AccessUserSelector);