import { Button, Input, notification, Tabs } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { loadAllMailBoxes, loadAllTags, loadVendors, mailBoxConfigureColumnProvider, MailBoxModel, MailVendorModel, RemoteInstanceModel, savingConfigure, SECURITY_DOMAIN, TagModel } from "../@mod";
import MailBoxList from '../box-list'
import './index.less';
import { DeleteOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons"
import EmailConfigureEditor from './editor'
import { loadActiveInstances } from "../../cluster/@mod";


export interface MailBoxConfigureViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface MailBoxConfigureViewState {
    activeView: string,
    dataLoading: false,
    mailBoxes: MailBoxModel[],
    mailBoxesOnView: MailBoxModel[],
    instances: RemoteInstanceModel[],
    vendors: MailVendorModel[]
    tags: TagModel[]

}

class MailBoxConfigure extends React.Component<MailBoxConfigureViewModel, MailBoxConfigureViewState>{

    constructor(props: MailBoxConfigureViewModel) {
        super(props)
        this.state = {
            activeView: "default",
            dataLoading: false,
            mailBoxes: [],
            mailBoxesOnView: [],
            instances: [],
            vendors: [],
            tags: []
        }

    }


    loadData = async () => {
        this.setState(
            {
                mailBoxes: [],
                mailBoxesOnView: []
            }
        )
        var mailBoxes = await loadAllMailBoxes(this.props.apiServer)
        var instances = await loadActiveInstances(this.props.apiServer)
        var vendors = await loadVendors(this.props.apiServer)
        var tags = await loadAllTags(this.props.apiServer);

        console.log(mailBoxes);
        this.setState(
            {
                mailBoxes: mailBoxes,
                instances: instances,
                mailBoxesOnView: [],
                vendors: vendors,
                tags: tags
            }
        )
    }

    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.setState({
                        dataLoading: false
                    })
                })
        }
    }

    onNavigate = (box: MailBoxModel) => {
        let onView = this.state.mailBoxesOnView;
        if (onView.findIndex(x => x.id == box.id) >= 0) {
            this.setState({
                activeView: String(box.id)
            })
        } else {
            onView.push(box);
            this.setState({
                mailBoxesOnView: onView
            })
        }
    }

    newEmailConf = () => {
        var boxOnView = this.state.mailBoxesOnView;
        if (boxOnView.findIndex(x => x.id === 0) >= 0) {
            this.setState({
                activeView: "0"
            })
        } else {
            boxOnView.push(
                {
                    id: 0,
                    emailAddress: "example@hotmail.com",
                    cred: "",
                    description: {},
                    creator: "",
                    updateter: "",
                    created: "",
                    updated: "",
                    displayCred: false,
                    deployTo: []
                }
            )
            this.setState({
                activeView: "0",
                mailBoxesOnView: boxOnView
            })
        }
    }

    save = (depolyLocation, emailAddress, password, tags, mailBoxVendor, id) => {
        savingConfigure(this.props.apiServer, depolyLocation, emailAddress, password, tags, mailBoxVendor, id)
            .then(x => {
                if (x) {
                    notification.success({ message: this.props.t("mails.conf.create-mailbox-succeed") })
                    var closeThe0One = this.state.mailBoxesOnView.filter(x => x.id != 0);
                    this.setState({
                        mailBoxesOnView: closeThe0One,
                        activeView: "default"
                    })
                    this.loadData();

                } else {
                    notification.success({ message: this.props.t("mails.conf.create-mailbox-fail") })
                }

            })


    }
    onVerify = (model: MailBoxModel) => {
        this.props.apiServer.apiMail.getMailValidationMessage(model.id)
            .then(x => {
                if (x.data.data?.success && x.data.data.queryKey) {
                    let queryKey = x.data.data.queryKey;
                    model.isVerifing = true;
                    var total = 0;
                    var timer = setInterval(
                        () => {
                            this.props.apiServer.apiMail.getMailAction(
                                queryKey
                            ).then(q => {
                                total++;
                                if (q.data.data?.success) {
                                    let status = q.data.data.actionStatusDtos ?? [];
                                    var succeed: number[] = []
                                    var fail: number[] = []
                                    status.forEach(element => {
                                        if (element.succeed) { // 该标记表示命令已经发送到并返回
                                            var result = JSON.parse(element.result ?? "{}");
                                            if (result.succeed) {
                                                succeed.push(element.instanceId ?? 0)
                                            } else {
                                                fail.push(element.instanceId ?? 0);
                                            }
                                        } else {
                                            fail.push(element.instanceId ?? 0);
                                        }
                                        model.successInstance = succeed;
                                        model.failInstance = fail;

                                    });
                                    if (succeed.length + fail.length >= model.deployTo.length) {

                                        model.isVerifing = false;
                                        clearInterval(timer);
                                    }

                                }
                                if (total > 20) {
                                    model.isVerifing = false;
                                    model.failInstance = model.deployTo.map(x => Number(x));
                                    clearInterval(timer);
                                }
                                this.setState({
                                    mailBoxes: this.state.mailBoxes
                                })
                            })
                        }, 3000);
                    this.setState({
                        mailBoxes: this.state.mailBoxes
                    })
                }
            }
            )
    }

    aggreateTags = ()=>{
        let tags: TagModel[] = [];
        this.state.mailBoxes.forEach(x=>{
            x.tags?.forEach(t=>{
                if(tags.findIndex(q=>q.id===t.id)<0){
                    tags.push(t);
                }
            })
        })
        return tags;
    }

    renderTabs = () => {
        let tabs: React.ReactNode[] = [];
        tabs.push(
            <Tabs.TabPane closable={false} tab={this.props.t("mails.mail-configure")} key={"default"}>
                <div>
                    <div className="function-bar">
                        <div className="left">
                            <Button type="default" danger icon={<DeleteOutlined />} > {this.props.t("mails.conf.unregister-selection")}</Button>
                            <Button type="primary" onClick={this.newEmailConf} icon={<PlusOutlined />} > {this.props.t("mails.conf.new-mail-box")}</Button>
                        </div>
                        <div className="right">
                            <Input type="text" placeholder={this.props.t("mails.conf.search")} ></Input>
                            <Button type="primary" icon={<SearchOutlined />} > {this.props.t("mails.conf.search")}</Button>
                        </div>
                    </div>
                    <MailBoxList dataLoading={this.state.dataLoading}
                        mailBoxes={this.state.mailBoxes} onNavigate={this.onNavigate}
                        onVerify={this.onVerify}
                        columnTypeProvider={(x) => mailBoxConfigureColumnProvider(this.props.t, x, this.props.apiServer, this.state.instances,this.aggreateTags())}
                    />
                </div>
            </Tabs.TabPane>
        )

        this.state.mailBoxesOnView.forEach(
            mailModel => {
                tabs.push(
                    <Tabs.TabPane closable={true} tab={mailModel.emailAddress} key={mailModel.id}>
                        <div className="mail-config-editor">
                            <EmailConfigureEditor
                                tags={this.state.tags}
                                vendors={this.state.vendors}
                                instances={this.state.instances}
                                mailBoxModel={mailModel}
                                onSave={this.save}
                            />
                        </div>
                    </Tabs.TabPane>

                )
            }

        )
        return tabs;
    }




    activeKey = (k: string) => {
        this.setState({
            activeView: k
        })
    }

    editTabs = (e, a) => {
        var boxOnView = this.state.mailBoxesOnView;
        if (a === "remove") {
            boxOnView = boxOnView.filter(x => x.id !== Number(e));
            var activeView = this.state.activeView;
            if (this.state.activeView === e) {
                activeView = "default"
            }
            this.setState({
                mailBoxesOnView: boxOnView,
                activeView: activeView
            })
        }
    }

    render(): React.ReactNode {
        return (
            <div className="boxes-conf-container">
                <Tabs type="editable-card"
                    hideAdd={true}
                    onTabClick={this.activeKey}
                    activeKey={this.state.activeView}
                    onEdit={this.editTabs}
                    tabPosition="top"
                    className="detail-tab">
                    {this.renderTabs()}
                </Tabs>
            </div>)
    }
}

export default withTranslation()(MailBoxConfigure);