import { Spin } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";

interface TemplateContentViewModel extends WithTranslation {
    apiServer: ApiServer,
    templateId: number
}

interface TemplateContentViewState {
    contentOfTemplate: string,
    loadingTemplateContent: boolean
}

class TemplateContentView extends React.Component<TemplateContentViewModel, TemplateContentViewState> {

    constructor(props: TemplateContentViewModel) {
        super(props)
        this.state = {
            contentOfTemplate: "",
            loadingTemplateContent: false
        }
    }

    componentDidMount(): void {
        this.loadTemplateContent()
    }

    loadTemplateContent = () => {
        this.setState({
            loadingTemplateContent: true
        })
        this.props.apiServer.apiMail.getMailTemplateContent(this.props.templateId).then(x => {
            this.setState({
                contentOfTemplate: x.data.data?.content ?? ""
            })
        }).finally(() => {
            this.setState({
                loadingTemplateContent: false
            })
        })
    }

    render(): React.ReactNode {
        return (
            <div className="template-content-container">
                <div>
                    {
                        this.state.loadingTemplateContent
                            ? <div><Spin tip={this.props.t("mails.mail-template.loading")} /></div>
                            : <div dangerouslySetInnerHTML={{ __html: this.state.contentOfTemplate }} />
                    }
                </div>
            </div>
        )
    }
}

export default withTranslation()(TemplateContentView)