import { Button, Input, notification, Select } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import './index.less'


export interface NewFormEditorViewModel extends WithTranslation {
	apiServer: ApiServer,
	onNewFormActed?: (schemaId: number, name: string) => void
	cancle?: () => void
}

interface NewFormEditorState {
	contentSchema: ContentSchemaModel[]
	name: string

}

interface ContentSchemaModel {
	id: number,
	name: string
}

class NewFormEditor extends React.Component<NewFormEditorViewModel, NewFormEditorState>{
	constructor(props: NewFormEditorViewModel) {
		super(props)
		this.state = {
			contentSchema: [],
			name: ""
		}
	}
	selectedId: number = 0

	loadData = async () => {
		await this.props.apiServer.apiMeta.getContentSchema()
			.then(x => {
				if (x.data.data?.list) {
					let data = x.data.data.list.map(u => {
						let model: ContentSchemaModel = {
							id: u.id ?? 0,
							name: u.name ?? ""
						}
						return model
					}) || [];
					this.setState({
						contentSchema: data
					})
				}
			})
	}

	l: Promise<void> | undefined;

	componentDidMount = () => {
		if (!this.l) {
			this.l = this.loadData().finally(() => this.l = undefined);
		}
	}

	getOptions = () => {
		return this.state.contentSchema.map(x => {
			return (
				<Select.Option value={x.id} key={x.id}>{x.name}</Select.Option>
			)
		})
	}

	addFormClicked = () => {
		if (this.selectedId !== 0 && this.state.name !== "") {
			this.props.onNewFormActed?.(this.selectedId, this.state.name);
		} else {
			notification.error({ message: this.props.t("auto.add-form-validation-fail") })
		}

	}

	render(): React.ReactNode {
		return (
			<div className="new-form-editor">
				<div>
					<div>
						<Input value={this.state.name}
							placeholder={this.props.t("auto.ref-form-name")}
							onChange={(t) => {
								this.setState({
									name: t.target.value
								})
							}}></Input></div>
				</div>
				<div className="selector">
					<Select style={{ width: "300px" }}
						showSearch
						filterOption={
							(input, option) => {
								return (option?.children?.toString().indexOf(input) ?? -1) >= 0
							}
						}
						onChange={(v) => this.selectedId = v}>
						{this.getOptions()}
					</Select>

						<Button type="primary" onClick={this.addFormClicked}>{this.props.t("auto.add-form")}</Button>
						<Button onClick={() => this.props.cancle?.()} >{this.props.t("auto.cancel")}</Button>
				</div>
			</div>)
	}
}

export default withTranslation()(NewFormEditor)