import { Button } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import DynamicEditor from "../../../../../components/dynamic-editor";
import './index.less'

export interface FormEditorViewModel extends WithTranslation {
	schemaId: number,
	content: string,
	apiServer: ApiServer,
	onCommit: (v: string) => void,
	onRemove :()=>void,
	canEdit: boolean
	canRemove?:boolean
	
}

interface FormEditorState {
	enableEdit: boolean,
	loadingData: boolean,
	schema: object,
	uiSchema?: object,
}

class FormEditor extends React.Component<FormEditorViewModel, FormEditorState>{

	constructor(props: FormEditorViewModel) {
		super(props)
		this.state = {
			enableEdit: false,
			loadingData: true,
			schema: {}
		}
	}

	loadData = async () => {
		let { schema, uiSchema } = await this.props.apiServer.apiMeta.getContentSchemaById(this.props.schemaId)
			.then(x => {

				if (x.data.data?.succeed) {
					let uiSchema = x.data.data.contentSchema?.uiSchema;
					return {
						schema: JSON.parse(x.data.data.contentSchema?.schema ?? "{}"),
						uiSchema: uiSchema ? JSON.parse(uiSchema) : undefined,
					}
				}
				return {

				}
			});
		if (schema) {
			schema = await this.props.apiServer.resolve(schema);
		}
		this.setState(
			{
				schema: schema,
				uiSchema: uiSchema,
			}
		)
	}

	l: Promise<void> | undefined;

	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => {
					this.l = undefined;
					this.setState({
						loadingData: false
					})
				})
		}

	}
	componentDidUpdate() {

	}

	getSnapshotBeforeUpdate(prevProps: FormEditorViewModel, prevState: FormEditorState) {

		return null;
	}

	onSave = (t: any) => {
		this.setState({ enableEdit: false })
		this.props.onCommit(JSON.stringify(t));
	}

	onRemove = ()=>{
		this.props.onRemove();
	}

	render(): React.ReactNode {
		return (<div className="form-editor">
			{ 
			this.props.canEdit && <div className="form-editor-bar">
				<Button loading={this.state.loadingData} disabled={this.state.enableEdit} onClick={() => this.setState({ enableEdit: true })} >
					{this.props.t("tickets.edit-default-detail")}</Button>
				{this.props.canRemove && <Button loading={this.state.loadingData} disabled={!this.state.enableEdit} 
					onClick={this.onRemove} 
					danger
				>{this.props.t("tickets.remove-this-form")}</Button>}
			</div>
			}
			<div className="form-editor-field">
				{
					!this.state.loadingData &&
					<DynamicEditor
						omitExtraData={true}
						widthPercent={45}
						uiSchema={this.state.uiSchema}
						schema={this.state.schema}
						content={JSON.parse(this.props.content)}
						isReadOnly={!this.state.enableEdit}
						onSave={this.onSave}
					/>
				}
			</div>
		</div>)
	}
}

export default withTranslation()(FormEditor);