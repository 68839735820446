import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./index.less"
import TextEditor, { EditorOperation } from "../../../../../../components/text-editor"
import { Button, DatePicker, Input, Popover, Select, Switch } from "antd";

import {
    DeleteOutlined,
    DeliveredProcedureOutlined,
    EditOutlined,
    FileTextOutlined,
    InboxOutlined,
    MessageOutlined,
    PaperClipOutlined,
    SendOutlined,
    ClockCircleOutlined
} from "@ant-design/icons"
import Dragger from "antd/lib/upload/Dragger";
import TextArea from "antd/lib/input/TextArea";
import { findReplaceTagOn as findReplaceImageSrc, isEmailAddress } from "../../../../../../utils/regex";
import { Axios } from "axios";
import { dataURLtoBlob, makeString } from "../../../../../../utils";
import moment from "moment";
import TemplateSelector from "../../../../component/template-selector";
import ApiServer from "../../../../../../ApiServer";
import { BindingContactModel, MailContactModel, MailSendModel } from "../../../../@mod";
import { MailTemplateModel } from "../../../../template/@mod";


export interface MailEditorViewModel extends WithTranslation {
    sendModel: MailSendModel
    save: (sendModel: MailSendModel) => void
    upload: (uid, fileName) => Promise<string>,
    download: (uid, fileName) => void,
    downloadUrl: (uid, fileName) => Promise<string>,
    delete: (sendModel: MailSendModel) => void,
    send: (sendModel: MailSendModel, timeToSchedule?:string) => void,
    cidUpload: (uid, fileName) => Promise<string>,
    bindingContacts?: BindingContactModel,
    boundMailTemplates?: MailTemplateModel[]
    apiServer: ApiServer
}

interface MailEditorViewState {
    value: string,
    displayMailEditor: boolean;
    sendModel: MailSendModel,
    showTemplateSelector: boolean,
    mailContent: Promise<string>,
    scheduleToSend: moment.Moment | null

}

class MailEditor extends React.Component<MailEditorViewModel, MailEditorViewState>{

    cidMap: any = {

    }

    constructor(prop: MailEditorViewModel) {
        super(prop)
        this.state = {
            value: (prop.sendModel?.content.body) ?? "",
            displayMailEditor: false,
            sendModel: prop.sendModel,
            showTemplateSelector: false,
            mailContent: this.replaceCidAsRemoteRef(this.props.sendModel),
            scheduleToSend: null
        }

    }

    componentDidUpdate() {

    }

    canEdit = () => {

        let can = this.state.sendModel.sendStatus === "draft";
        if (this.textEditorEnable) {
            this.textEditorEnable(can);
        }
        return can;
    }

    canSend = () => {
        return this.state.sendModel.sendStatus === "draft";
    }


    async componentDidMount() {
        if (this.textEditor) {
            let replaced = await this.replaceCidAsRemoteRef(this.props.sendModel);
            this.textEditor?.setRawHtml?.(replaced)
        }
    }

    async getSnapshotBeforeUpdate(preProps: MailEditorViewModel, preState: MailEditorViewState) {
        if (preProps.sendModel.id !== this.props.sendModel.id) {
            this.setState({
                sendModel: this.props.sendModel
            })
            if (this.textEditor) {
                let replaced = await this.replaceCidAsRemoteRef(this.props.sendModel);
                this.textEditor?.setRawHtml?.(replaced)
            }
        }
        return null;
    }

    onchange = (
        delta: any,
        oldDelta: any,
        content: any) => {
        if (delta.ops.length === 2) {
            var retain = delta.ops[0];
            var majorOps = delta.ops[1];
            if (majorOps.insert && majorOps.insert.image) {
                if (majorOps.insert.image.indexOf("data:image/png;base64,") === 0) {
                    // 插入了一个图片
                    let blob = dataURLtoBlob(majorOps.insert.image)
                    let fileName = makeString();
                    let uid = makeString();
                    let file = new File([blob], fileName, {
                        type: blob.type,
                        lastModified: Date.now(),
                    });
                    this.props.cidUpload(uid, file.name)
                        .then(async (x) => {
                            var a = new Axios({ baseURL: x })
                            await a.put(x, file)
                            let newCidAttachment = {
                                uid: uid,
                                fileName: fileName,
                                type: file.type,
                            }
                            let downloadUrl = await this.props.downloadUrl(uid, fileName);
                            this.state.sendModel.content.cid.push(JSON.stringify(newCidAttachment));
                            this.textEditor?.delete?.(retain.retain, 1);
                            this.textEditor?.addImage?.(retain.retain, downloadUrl)
                        })
                }
            }
        }
        var sendModel = this.state.sendModel
        sendModel.content.body = this.replaceRemoteRefToCid(content);
        this.onStateChanged(sendModel)
    }

    replaceRemoteRefToCid = (content): string => {
        let contentForDisplay = content;
        findReplaceImageSrc(content, (find) => {
            for (var id in this.state.sendModel.content.cid) {
                var cid = JSON.parse(this.state.sendModel.content.cid[id])
                if (find.indexOf(cid.uid + "/" + cid.fileName) > 0) {
                    contentForDisplay = contentForDisplay.replace(find, "cid:" + cid.uid + "/" + cid.fileName);
                }
            }
        });

        return contentForDisplay;
    }

    replaceCidAsRemoteRef = async (val: MailSendModel) => {
        let displayValue = val.content.body
        for (var i = 0; i < val.content.cid.length; i++) {
            var cid = JSON.parse(val.content.cid[i]);
            var letCid = "cid:" + cid.uid + "/" + cid.fileName;
            var downloadUrl = "";
            if (this.cidMap[letCid]) {
                if (moment().add(-20, "minute") < this.cidMap[letCid].presignDate) {
                    downloadUrl = this.cidMap[letCid].presignUrl;
                }
            } else {
                downloadUrl = await this.props.downloadUrl(cid.uid, cid.fileName);
                this.cidMap[letCid] = {
                    presignDate: moment(),
                    presignUrl: downloadUrl
                }
            }
            displayValue = displayValue.replace(letCid, downloadUrl);

        }
        return displayValue;
    }

    displayMailEditor = () => {
        return this.state.displayMailEditor ? "" : " no-display";
    }

    displayMailMeta = () => {
        return !this.state.displayMailEditor ? "" : " no-display";
    }

    clickDisplayMailEditor = () => {
        this.setState({
            displayMailEditor: !this.state.displayMailEditor
        })
    }

    iconForWrapButton = () => {
        return this.state.displayMailEditor ? <MessageOutlined /> : <EditOutlined />;
    }
    customerRequest = async (option: any) => {
        let f = option.file;
        let uid = f.uid;
        let fileName = f.name;
        let type = f.type;

        let url = await this.props.upload(uid, fileName);

        var a = new Axios({
            baseURL: url,
            onUploadProgress: (a) => {
                option.onProgress({ percent: a.loaded / a.total });
            }
        })

        await a.put(url, option.file)
            .then(async (x) => {
                option.onSuccess(x.data, x.request)
            })
            .then(
                (any) => {
                    let newAttachment = {
                        uid: uid,
                        fileName: fileName,
                        type: type
                    }
                    let attachment = this.state.sendModel.content.attachment;
                    attachment.push(JSON.stringify(newAttachment));
                    this.onAttachmentChange(attachment, {});
                })

    }


    onStateChanged = (sendModel) => {
        this.setState({
            sendModel: sendModel
        })
    }

    onAttachmentChange = (value, options) => {
        var sendModel = this.state.sendModel
        sendModel.content.attachment = value;
        this.onStateChanged(sendModel);
    }


    onToChanged = (value, options) => {
        if (this.state.sendModel) {
            value = value.filter(x => isEmailAddress(x))
            var sendModel = this.state.sendModel
            sendModel.content.to = value;
            this.onStateChanged(sendModel);
        }
    }

    onBccChanged = (value, options) => {
        if (this.state.sendModel) {
            value = value.filter(x => isEmailAddress(x))
            var sendModel = this.state.sendModel
            sendModel.content.bcc = value;
            this.onStateChanged(sendModel);
        }
    }

    onCcChanged = (value, options) => {
        value = value.filter(x => isEmailAddress(x))
        var sendModel = this.state.sendModel
        sendModel.content.cc = value;
        this.onStateChanged(sendModel);

    }
    subjectChanged = (value) => {
        var sendModel = this.state.sendModel;
        sendModel.content.subject = value;
        this.onStateChanged(sendModel);
    }
    onSplitChanged = (value) => {
        var sendModel = this.state.sendModel;
        sendModel.content.split = value;
        this.onStateChanged(sendModel);
    }
    mailDescriptionChanged = (value) => {
        var sendModel = this.state.sendModel;
        sendModel.mailDescription = value.target.value;
        this.onStateChanged(sendModel);
    }
    textEditor: EditorOperation | undefined
    textEditorEnable: ((b) => void) | undefined;

    setEditorOperator = (f: EditorOperation) => {
        this.textEditor = f
    }

    setEditorEnable = (f: (b) => void) => {
        this.textEditorEnable = f
    }

    saveAsDraft = () => {
        var cids = this.state.sendModel.content.cid;
        this.props.save(this.state.sendModel);

    }

    attachmentDownload = (f) => {
        this.props.download(
            f.uid,
            f.fileName
        );
    }

    useTemplate = async (values) => {
        this.setState({
            showTemplateSelector: false
        })
        let content = await this.props.apiServer.apiMail.getMailTemplateContent(values.templateId).then(x => {
            if (x.data.data?.success) {
                return x.data.data.content
            } else {
                return ""
            }
        }) ?? ""
        this.setState({
            mailContent: Promise.resolve(content)
        })
        return content
    }

    renderAttachements = () => {
        return this.state.sendModel.content.attachment.map(x => {
            let attchObj = JSON.parse(x)
            return (
                <div key={attchObj.uid} className="attachment-item" onClick={() => this.attachmentDownload(attchObj)}>
                    <div className="fileName"><PaperClipOutlined /> {attchObj.fileName}</div>
                    <div className="deleteButton"><Button type="primary" danger shape="circle"
                        icon={<DeleteOutlined />}></Button></div>
                </div>
            )
        })
    }

    genContactSelector = (contacts?: MailContactModel[]) => {
        if (contacts) {
            return <>
                {contacts.map(contact =>
                    <Select.Option key={"x" + contact.id}
                        value={contact.emailAddress}>{`${contact.emailAddress}(${contact.nickname})`}</Select.Option>
                )}
            </>
        }
    }

    renderTemplateSelector = (): React.ReactNode => {
        return (
            <>
                <div>
                    <TemplateSelector
                        apiServer={this.props.apiServer}
                        selected={this.useTemplate}
                        templates={this.props.boundMailTemplates ?? []}
                        btnText={this.props.t("mails.select-this-template")}
                    />
                </div>
            </>
        )
    }

    renderSelection = (): React.ReactNode => {

        return (
            <div>
                <Button type="link"
                    onClick={() => {
                        this.setState({
                            scheduleToSend: moment().hour(moment().hour() + 8)
                        })
                    }}
                >{this.props.t("mails.schedule-8-hours-after")}

                </Button>
                <Button type="link"
                    onClick={() => {
                        this.setState({
                            scheduleToSend: moment().date(moment().date() + 1)
                        })
                    }}
                >{this.props.t("mails.schedule-1-day-after")}</Button>
                <Button type="link"
                    onClick={() => {
                        this.setState({
                            scheduleToSend: moment().week(moment().week() + 1)
                        })
                    }}

                >{this.props.t("mails.schedule-1-week-after")}</Button>
            </div>
        )
    }



    render(): React.ReactNode {
        return (
            <div className="editor-view">
                <div className="function-buttons">
                    <div className="function-left">
                        <Button type="dashed" onClick={this.clickDisplayMailEditor} icon={this.iconForWrapButton()}>
                            {this.state.displayMailEditor ? this.props.t("mails.message-target") : this.props.t("mails.mail-detail")}
                        </Button>
                        {this.canEdit() && <Button danger icon={<DeleteOutlined />}
                            onClick={() => this.props.delete(this.state.sendModel)}>{this.props.t("mails.delete")} </Button>
                        }
                    </div>
                    <div className="function-right">
                    {this.canSend() && this.state.displayMailEditor &&
                            <Popover trigger={"click"}
                                content={this.renderTemplateSelector()}
                                visible={this.state.showTemplateSelector}
                            >
                                <Button icon={<FileTextOutlined />}
                                    onClick={() => this.setState({ showTemplateSelector: !this.state.showTemplateSelector })}>{this.props.t("mails.select-template")} </Button>
                            </Popover>
                        }
                        {
                            this.canSend() &&
                            <Popover trigger="click"
                                placement="bottom"
                                defaultVisible={false}
                                destroyTooltipOnHide={true}
                                content={
                                    (
                                        <div className="time-selector">
                                            <DatePicker
                                                value={this.state.scheduleToSend}
                                                showTime
                                                placeholder={this.props.t("mails.schedule-date-time")}
                                                renderExtraFooter={this.renderSelection}
                                                onChange={(c)=>{
                                                    this.setState({
                                                        scheduleToSend: c
                                                    })
                                                }}
                                            />
                                            <Button type="primary" icon={<SendOutlined />}
                                                disabled={this.state.scheduleToSend==null}
                                                style={{ marginLeft: 8 }}
                                                onClick={() => this.props.send(this.state.sendModel, String(this.state.scheduleToSend?.unix())+"000")}>
                                                {this.props.t("mails.send")}
                                            </Button>
                                        </div>
                                    )
                                }
                            >
                                <Button type="default" icon={<ClockCircleOutlined />}>{this.props.t("mails.send-scheduled")} </Button>
                            </Popover>
                        }

                        {
                            this.canSend() &&
                            <Button type="primary" icon={<SendOutlined />}
                                onClick={() => this.props.send(this.state.sendModel)}>{this.props.t("mails.send")} </Button>

                        }
                    
                        {this.canEdit() && <Button icon={<DeliveredProcedureOutlined />}
                            onClick={this.saveAsDraft}>{this.props.t("mails.save-draft")} </Button>
                        }

                    </div>
                </div>

                <div className="mail-form" style={{ display: !this.state.displayMailEditor ? "flex" : "none" }}>
                    <div className="mail-meta-item">
                        <div className="col-button">
                            <Button className="button-detail">{this.props.t("mails.send-box")}</Button>
                        </div>
                        <div className="col-input">
                            <Input type={"text"} value={this.state.sendModel?.box?.emailAddress} disabled color="red" />
                        </div>
                    </div>
                    <div className="mail-meta-item">
                        <div className="col-button">
                            <Button className="button-detail">{this.props.t("mails.send-to")}</Button>
                        </div>
                        <div className="col-input">
                            <Select mode="tags" className="width-100"
                                placeholder={this.props.t("mails.send-to-placeholder")}
                                value={this.state.sendModel.content.to}
                                onChange={this.onToChanged}
                                disabled={!this.canEdit()}
                            >
                                {this.genContactSelector(this.props.bindingContacts?.to)}
                            </Select>
                        </div>
                    </div>
                    <div className="mail-meta-item">
                        <div className="col-button">
                            <Button className="button-detail">{this.props.t("mails.send-cc")}</Button>
                        </div>
                        <div className="col-input">
                            <Select mode="tags" className="width-100"
                                placeholder={this.props.t("mails.send-cc-placeholder")}
                                onChange={this.onCcChanged}
                                value={this.state.sendModel.content.cc}
                                disabled={!this.canEdit()}
                            >
                                {this.genContactSelector(this.props.bindingContacts?.cc)}
                            </Select>
                        </div>
                    </div>
                    <div className="mail-meta-item">
                        <div className="col-button">
                            <Button className="button-detail">{this.props.t("mails.send-bcc")}</Button>
                        </div>
                        <div className="col-input">
                            <Select mode="tags" className="width-100"
                                placeholder={this.props.t("mails.send-bcc-placeholder")}
                                value={this.state.sendModel.content.bcc}
                                onChange={this.onBccChanged}
                                disabled={!this.canEdit()}
                            >
                                {this.genContactSelector(this.props.bindingContacts?.bcc)}
                            </Select>
                        </div>
                    </div>
                    <div className="mail-meta-item">
                        <Input type={"text"} placeholder={this.props.t("mails.subject")}
                            onChange={(v) => this.subjectChanged(v.target.value)}
                            value={this.state.sendModel.content.subject}
                            disabled={!this.canEdit()}
                        ></Input>
                    </div>
                    <div className="mail-meta-item">
                        <Switch checkedChildren={this.props.t("mails.split-send")}
                            unCheckedChildren={this.props.t("mails.normal-send")}
                            onChange={(v) => this.onSplitChanged(v)}
                            checked={this.state.sendModel.content.split}
                            disabled={!this.canEdit()}
                        ></Switch>
                    </div>
                    <div className="mail-meta-description">
                        <div className="description-label">{this.props.t("mails.decriptions")} </div>
                        <TextArea showCount rows={6}
                            placeholder={this.props.t("mails.decriptions")}
                            size="middle" style={{ width: "100%" }}
                            maxLength={6000}
                            value={this.state.sendModel.mailDescription}
                            onChange={this.mailDescriptionChanged}
                            disabled={!this.canEdit()}
                        >
                        </TextArea>
                    </div>
                    <div className="mail-attachments">
                        <div>
                            {this.renderAttachements()}
                        </div>
                        {this.canEdit() &&
                            <Dragger
                                name='file'
                                multiple={true}
                                customRequest={this.customerRequest}
                            >
                                <div className="drop-box">
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined className="upload-drag-icon" />
                                    </p>
                                    <p className="ant-upload-text">
                                        {this.props.t("upload-text")}
                                    </p>
                                    <p className="ant-upload-hint">
                                        {this.props.t("upload-hit")}
                                    </p>
                                </div>
                            </Dragger>
                        }
                    </div>
                </div>

                <div className={"quill-editor" + this.displayMailEditor()} >
                    <div className="tool-bar" style={{ display: this.state.displayMailEditor ? "block" : "none" }} id={"toolbar-" + String(this.state.sendModel.box?.id ?? 0)} >
                        <select className="ql-header">
                            <option value={1}></option>
                            <option value={2}></option>
                            <option value={3}></option>
                            <option value={4}></option>
                            <option value={5}></option>
                            <option selected></option>
                        </select>
                        <button className="ql-bold"></button>
                        <button className="ql-italic"></button>
                        <button className="ql-underline"></button>
                        <button className="ql-strike"></button>
                        <button className="ql-blockquote"></button>
                        <button className="ql-indent" value={"+1"}></button>
                        <button className="ql-indent" value={"-1"}></button>
                        <button className="ql-list" value={"ordered"}></button>
                        <button className="ql-list" value={"bullet"}></button>
                        <button className="ql-link"></button>
                        <button className="ql-image"></button>
                        <button className="ql-clean"></button>
                    </div>
                    {/*<TextEditor value={this.replaceCidAsRemoteRef(this.props.sendModel)}*/}
                    <TextEditor value={this.state.mailContent}
                        onChanged={this.onchange}
                        containerId={String(this.state.sendModel.box?.id ?? 0)}
                        editorOperator={this.setEditorOperator}
                        enableOperator={this.setEditorEnable}
                    />
                </div>


            </div>
        )
    }

}

export default withTranslation()(MailEditor);

