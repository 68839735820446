import moment from "moment";
import React, { ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import UserAvatar from "../../../../components/user-avatar";
import { PriorityModel, SourceModel, StatusModel, ThreadModel, TicketTypeModel } from "../@mod";
import {UserModel,UserGroupModel} from  "../../@mod"
import AssigneeChangeView from "./assignee-change-view";
import AttachmentFileView from "./attachment-file-view";
import './index.less'
import StatusChangeView from "./status-change-view";
import SubjectChangeView from "./subject-change-view";



export interface ThreadViewModel extends WithTranslation {
	apiServer: ApiServer
	threadModel: ThreadModel,
	userList: UserModel[],
	userGroup: UserGroupModel[],
	ticketStatusList: StatusModel[]
	ticketPriorityList: PriorityModel[]
	ticketSourceList: SourceModel[]
	ticketTypeList: TicketTypeModel[]

}

interface ThreadViewState {
	threadModel: ThreadModel,
	userList: UserModel[],
	userGroup: UserGroupModel[],
}


class ThreadView extends React.Component<ThreadViewModel, ThreadViewState> {

	constructor(props: ThreadViewModel) {
		super(props)
		this.state = {
			threadModel: this.props.threadModel,
			userList: this.props.userList,
			userGroup: this.props.userGroup
		}
	}


	dynamicContent: string[] = [
		"newticket",
	]

	getContent = () => {

		var t = this.state.threadModel.type.toLowerCase();
		if (this.dynamicContent.includes(t)) {
			var o = JSON.parse(this.state.threadModel.message);
			return o[this.props.i18n.language];
		}
		if (t === "assignchange" || t === "batchassignchanged") {
			let o = JSON.parse(this.state.threadModel.message);
			return (<AssigneeChangeView changed={o} userList={this.state.userList} userGroupList={this.state.userGroup} />)
		}
		if (t === "corestatuschange") {
			if (this.state.threadModel.message != "") {
				let o = JSON.parse(this.state.threadModel.message);
				return (<StatusChangeView changeModel={o}
					ticketPriorityList={this.props.ticketPriorityList}
					ticketSourceList={this.props.ticketSourceList}
					ticketStatusList={this.props.ticketStatusList}
					ticketTypeList={this.props.ticketTypeList}
				/>
				)
			}
			return <></>
		}
		if( t === "subjectchange"){
			 let o =  JSON.parse(this.state.threadModel.message);
			return (<SubjectChangeView changedObject={o} />)
		}

		if (t === "fileattached") {
			let o = JSON.parse(this.state.threadModel.message);
			return (<AttachmentFileView
				apiServer={this.props.apiServer}
				attachmentThread={o}
				ticketId={this.props.threadModel.ticketId}
			></AttachmentFileView>)
		}


		return (<pre>{this.props.threadModel.message}</pre>)
	}

	getSnapshotBeforeUpdate(beforeProps: ThreadViewModel, beforeState: ThreadViewState) {
		if (beforeProps.threadModel && beforeProps.threadModel.message !== this.state.threadModel.message) {
			this.setState({
				threadModel: this.props.threadModel,
				userList: this.props.userList,
				userGroup: this.props.userGroup
			})
		}
		return null;
	}

	componentDidUpdate() {

	}


	getThreadContent = (): ReactNode => {
		return (
			<div className="thread-detail" >
				<div className="thread-header">
					<div className="user-avatar">
						<UserAvatar userName={this.props.threadModel.createdBy.name}></UserAvatar>
					</div>
					<div className="content">
						<div className="abstract">
							{this.state.threadModel.createdBy.name} - {this.state.threadModel.createdBy.organization}
						</div>
						<div className="thread-type">
							<div>
								{moment.parseZone(this.state.threadModel.createdAt).format(this.props.t("time-pattern-long-second"))}
							</div>
							<div>
								{this.props.t("tickets.threads." + this.state.threadModel.type.toLowerCase())}
							</div>
						</div>
					</div>
				</div>
				<div className="thread-content">
					{this.getContent()}
				</div>
			</div>
		)
	}

	render(): React.ReactNode {
		return (
			this.getThreadContent()
		)
	}
}

export default withTranslation()(ThreadView)