import {Button, Collapse, Input, message, Modal, PageHeader, Popconfirm, Popover, Tag} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, {ReactNode} from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import withRouter, {WithRouteContext} from "../../../../utils/route-utils";
import {
    deliverableColumnProviderEditor,
    judgeParentItem,
    judgeParentTask,
    parseTemplate,
    ServiceTemplateModel,
    taskColumnProviderEditor
} from "../../@mod";
import './index.less';
import {BarsOutlined, EditOutlined, MinusOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons"
import ApiServer from "../../../../ApiServer";
import WorkBenchTable from "../../../../components/table/workbench-table";
import {UserDto} from "../../../../api";
import TaskEditorView from "./task-editor";
import DeliverableEditor from "./deliverable-editor";
import MilestoneEditor from "./milestone-editor";
import TaskBatchOperationPanel from "./task-batch-operation";
import EditableTextColumn from "../../../../components/table/editable-text-column";
import Question from "../../../../components/question";
import UserSelector from "../../component/user-selector";
import {getTicketPriorityList, TicketPriorityModel} from "./@mod";

export interface ProjectTemplateEditorViewModel extends WithTranslation, WithRouteContext {
    apiServer: ApiServer
    projectTemplate: string
    templateId: number
    isDraft: boolean
    onPublishSuccess: () => void
}


interface ProjectTemplateEditorState {
    template?: ServiceTemplateModel,
    milestoneList: any,
    preRequirements: any
    showDeliverableInput: boolean
    showMilestoneInput: boolean
    showTaskInput: boolean
    showOwnerEditor: boolean
    showVersionEditor: boolean
    selectedTaskForAdding?: any
    selectedTaskForEdit?: any
    selectedProjectOwner?: any
    newProjectVersion?: string
    userList?: UserDto[]
    taskEditorOnEditStatus: boolean
    deliverableEditorOnEditStatus: boolean
    selectedDeliverableForEditing?: any
    // draftId: number
    loadingSaveDraft: boolean
    onDraftStatus: boolean
    showTaskBatchOperationPanel: boolean
    flatTaskList: any[]
    selectedTasksForBatchOperation: any[]
    templateId: number,
    ticketPriorityList: TicketPriorityModel[]
}

class ProjectTemplateEditor extends React.Component<ProjectTemplateEditorViewModel, ProjectTemplateEditorState> {
    constructor(props: ProjectTemplateEditorViewModel) {
        super(props)
        this.state = {
            milestoneList: [],
            preRequirements: [],
            showDeliverableInput: false,
            showMilestoneInput: false,
            showTaskInput: false,
            showOwnerEditor: false,
            showVersionEditor: false,
            taskEditorOnEditStatus: false,
            deliverableEditorOnEditStatus: false,
            // draftId: props.templateId,
            loadingSaveDraft: false,
            onDraftStatus: props.isDraft,
            showTaskBatchOperationPanel: false,
            flatTaskList: [],
            selectedTasksForBatchOperation: [],
            templateId: props.templateId,
            ticketPriorityList: []
        }
    }

    get preRequirementTitle() {
        return this.props.t("project.template.pre-required")
    }

    componentDidMount() {
        this.loadInitializingData()
        this.parseProjectTemplate()
        this.loadTicketPriorityList()
    }

    loadInitializingData = () => {
        this.props.apiServer.apiUser.getUserList(false).then(r => {
            if (r.data.data?.list) {
                this.setState({
                    userList: [
                        {
                            id: -999,
                            fullName: "订阅者-subscriber"
                        },
                        ...r.data.data?.list]
                })
            }
        })
    }

    loadTicketPriorityList = () => {
        getTicketPriorityList(this.props.apiServer).then(x => {
            this.setState({
                ticketPriorityList: x
            })
        })
    }

    calTaskOrder(parent) {
        if (parent.children) {
            for (let index in parent.children) {
                parent.children[index].orderCode = index
                this.calTaskOrder(parent.children[index])
            }
        }
    }

    calMilestoneEstimatedTime = (milestone) => {
        let timeEstimationLowerLimit = 0
        let timeEstimationHigherLimit = 0
        if ("serial_milestone" === milestone.type) {
            for (let index in milestone.children) {
                timeEstimationLowerLimit += Number(milestone.children[index].timeEstimationLowerLimit)
                timeEstimationHigherLimit += Number(milestone.children[index].timeEstimationHigherLimit)
            }
        } else if ("parallel_milestone" === milestone.type) {
            for (let index in milestone.children) {
                timeEstimationLowerLimit = Math.max(Number(milestone.children[index].timeEstimationLowerLimit), timeEstimationLowerLimit)
                timeEstimationHigherLimit = Math.max(Number(milestone.children[index].timeEstimationHigherLimit), timeEstimationHigherLimit)
            }
        }
        milestone["timeEstimationLowerLimit"] = timeEstimationLowerLimit
        milestone["timeEstimationHigherLimit"] = timeEstimationHigherLimit
    }


    prepareProjectRequestBody = (): any => {
        for (let index in this.state.milestoneList) {
            this.calTaskOrder(this.state.milestoneList[index])
            this.calMilestoneEstimatedTime(this.state.milestoneList[index])
        }
        let toSave = {
            serviceName: this.state.template?.title,
            serviceDescription: this.state.template?.description,
            templateProvider: this.state.template?.ownerId,
            templateVersion: this.state.template?.version,
            milestones: [
                ...this.state.preRequirements,
                ...this.state.milestoneList
            ],
            templateCoordinator: [],
            templateCategory: []
        }
        let parsed = JSON.stringify(toSave, (key, value) => {
            if (key !== "parent") {
                return value
            }
        })
        return {
            title: toSave.serviceName ?? "",
            owner: Number(toSave.templateProvider),
            description: toSave.serviceDescription,
            template: parsed,
            version: toSave.templateVersion
        }
    };

    saveDraft = () => {
        this.setState({
            loadingSaveDraft: true
        })
        let requestBody = this.prepareProjectRequestBody()
        requestBody["draftId"] = this.state.templateId
        this.props.apiServer.apiProjectMeta.postProjectServiceDraft(requestBody).then(r => {
            if (r.data.data?.success) {
                if (r.data.data.draftId) {
                    this.setState({
                        templateId: r.data.data.draftId,
                        onDraftStatus: true
                    })
                }
                message.success(this.props.t("project.template.save-success")).then()
            } else {
                message.error(this.props.t("project.template.save-fail")).then()
            }
        }).finally(() => {
            this.setState({
                loadingSaveDraft: false
            })
        })
    }

    saveProjectTemplate = (active: boolean) => {
        let requestBody = this.prepareProjectRequestBody()
        requestBody["projectId"] = this.state.templateId
        requestBody["active"] = active
        this.props.apiServer.apiProjectMeta.postProjectService(requestBody).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("project.template.publish-success")).then()
                this.props.onPublishSuccess()
            } else {
                message.error(this.props.t("project.template.publish-fail")).then()
            }
        })
    }

    parseProjectTemplate = () => {
        let milestoneList = []
        let serviceTemplateModel = {
            id: NaN,
            title: this.props.t("project.template.input-title"),
            description: this.props.t("project.template.input-description"),
            serviceOrder: {},
            version: this.props.t("project.template.input-version"),
            ownerId: -1,
            ownerName: "",
            publishDate: "",
            status: ""
        }
        let preRequirements = [
            {
                "type": "PRE_REQUIRED",
                "title": this.preRequirementTitle,
                "orderCode": 0,
                children: []
            }
        ]
        if (this.props.projectTemplate) {
            serviceTemplateModel = parseTemplate(JSON.parse(this.props.projectTemplate));
            this.props.apiServer.apiUser.getUser(serviceTemplateModel.ownerId, false).then(r => {
                if (r.data.data?.user) {
                    serviceTemplateModel["ownerName"] = r.data.data.user.fullName ?? ""
                }
                let objData: any = serviceTemplateModel?.serviceOrder
                milestoneList = objData.serviceOrder
                preRequirements = objData.preRequirements
            }).finally(() => {
                this.setState({
                    milestoneList: milestoneList,
                    preRequirements: preRequirements,
                    template: serviceTemplateModel
                })
            })
        } else {
            this.props.apiServer.apiSystem.getLoginUser().then(r => {
                if (r.data.data?.user) {
                    let loginUser = r.data.data.user
                    if (loginUser.id) {
                        serviceTemplateModel.ownerId = loginUser.id
                    }
                    if (loginUser.fullName) {
                        serviceTemplateModel.ownerName = loginUser.fullName
                    }
                }
                this.setState({
                    milestoneList: milestoneList,
                    preRequirements: preRequirements,
                    template: serviceTemplateModel
                })
            })
        }

    }

    showAddDeliverableInput = (event) => {
        this.setState({
            showDeliverableInput: true,
            deliverableEditorOnEditStatus: false,
            selectedDeliverableForEditing: undefined
        })
        event.stopPropagation()
    }

    showEditDeliverableInput = (deliverable) => {
        this.setState({
            showDeliverableInput: true,
            deliverableEditorOnEditStatus: true,
            selectedDeliverableForEditing: deliverable
        })
    }

    showAddTaskInput = (parentTask, event?) => {
        this.setState({
            selectedTaskForAdding: parentTask,
            showTaskInput: true,
            taskEditorOnEditStatus: false
        })
        if (event) {
            event.stopPropagation()
        }
    }

    showEditTaskInput = (selectedTask, event?) => {
        this.setState({
            selectedTaskForEdit: selectedTask,
            showTaskInput: true,
            taskEditorOnEditStatus: true
        })
        if (event) {
            event.stopPropagation()
        }
    }

    flatTask = (): any[] => {
        let res: any[] = []
        this.state.milestoneList.forEach(milestone => {
            res = [...res, ...this.recursivlyFlatTask(milestone)]
        })
        return res
    }

    recursivlyFlatTask(task: any): any[] {
        let res: any[] = []
        res.push(task)
        if (task.children) {
            task.children.forEach(child => {
                res = [...res, ...this.recursivlyFlatTask(child)]
            })
        }
        return res
    }

    showTaskBatchOperationPanel = () => {
        let flatTaskList = this.flatTask()
        this.setState({
            flatTaskList: flatTaskList,
            showTaskBatchOperationPanel: true
        })
    }

    prepareTaskListForBatchOperation = (): any[] => {
        let taskData = this.state.flatTaskList.filter(t => !judgeParentItem(t.type))
        taskData.forEach(t => t.children = undefined)
        return taskData
    }

    renderPreRequirement = (preRequirements: any[]) => {
        return preRequirements.map(x => (
            <Collapse.Panel key={x.title} header={x.title} extra={this.genPreRequirementExtra()}>
                <div>
                    <div className="delveriable-label">{this.props.t("project.template.deliverable-content")}</div>
                    {this.renderDeliverable(x.children)}
                </div>
            </Collapse.Panel>
        ));
    }

    onFinishDeliverable = (values) => {
        if (this.state.deliverableEditorOnEditStatus) {
            this.onFinishEditDeliverable(values)
            return
        }
        this.onFinishAddDeliverable(values)
    }

    onFinishAddDeliverable = (values) => {
        let newPreRequirements = [...this.state.preRequirements]
        newPreRequirements[0].children = [{
            type: values.type,
            title: values.title,
            description: values.description,
            required: values.required,
            orderCode: 0
        }, ...newPreRequirements[0].children]
        this.setState({
            preRequirements: newPreRequirements,
            showDeliverableInput: false
        });
    }

    onFinishEditDeliverable = (values) => {
        let deliverable = this.state.selectedDeliverableForEditing
        deliverable.title = values.title
        deliverable.description = values.description
        deliverable.type = values.type
        deliverable.required = values.required
        this.setState({
            selectedDeliverableForEditing: deliverable,
            showDeliverableInput: false
        });
    }

    onFinishAddMilestone = (values) => {
        this.state.milestoneList.push({
            title: values.title,
            type: values.type
        })
        this.setState({
            milestoneList: this.state.milestoneList,
            showMilestoneInput: false
        })
    }


    onFinishTask = (values) => {
        let executor = this.state.userList?.filter(u => u.id === values.defaultExecutor).shift()
        values["defaultExecutorName"] = executor?.fullName
        if (this.state.taskEditorOnEditStatus) {
            this.onEditTask(values)
            return
        }
        this.onAddTask(values)
    }

    onEditTask = (values) => {
        let task = this.state.selectedTaskForEdit
        task.title = values.title
        task.description = values.description
        task.type = values.type
        task.defaultExecutor = values.defaultExecutor
        task.defaultExecutorName = values.defaultExecutorName
        task.required = values.required
        task.timeEstimationLowerLimit = values.timeEstimationLowerLimit
        task.timeEstimationHigherLimit = values.timeEstimationHigherLimit
        task.parameters = values.parameters
        this.setState({
            selectedTaskForEdit: task,
            showTaskInput: false
        })
    }

    onAddTask = (values) => {
        let selectedTask = this.state.selectedTaskForAdding
        if (!selectedTask["children"]) {
            selectedTask["children"] = []
        }
        const isParentTask = judgeParentTask(values.type)
        if (isParentTask) {
            values.timeEstimationLowerLimit = 0
            values.timeEstimationHigherLimit = 0
        }
        selectedTask["children"] = [...selectedTask["children"], {
            title: values.title,
            type: values.type,
            parent: selectedTask,
            children: [],
            timeEstimationLowerLimit: Number(values.timeEstimationLowerLimit),
            timeEstimationHigherLimit: Number(values.timeEstimationHigherLimit),
            required: values.required,
            description: values.description,
            defaultExecutor: values.defaultExecutor,
            defaultExecutorName: values.defaultExecutorName,
            parameters: values.parameters
        }]
        this.setState({
            milestoneList: this.state.milestoneList,
            showTaskInput: false
        })
    }

    removeDeliverable = (model, index) => {
        let preRequirements = [...this.state.preRequirements]
        let deliverable = preRequirements[0].children
        if (index !== undefined) {
            preRequirements[0].children = [
                ...deliverable.slice(0, index),
                ...deliverable.slice(index + 1, deliverable.length)
            ]
        } else {
            preRequirements[0].children = deliverable.filter(d => d.title !== model.title)
        }
        this.setState({
            preRequirements: preRequirements
        })
    }

    onDeliverableAction = (model: any, actionType: string, index?: number) => {
        if (actionType === "delete") {
            this.removeDeliverable(model, index)
        } else if (actionType === "edit") {

            this.showEditDeliverableInput(model)
        }
    };

    onTaskAction = (model: any, actionType: string, index?: number) => {
        if (actionType === "delete") {
            this.removeTask(model)
        } else if (actionType === "new-line") {
            this.showAddTaskInput(model)
        } else if (actionType === "up") {
            this.upTask(model, index)
        } else if (actionType === "edit") {
            this.showEditTaskInput(model)
        }
    }

    removeTask = (model) => {
        model.parent.children = model.parent.children.filter(d => d.title !== model.title)
        this.setState({
            milestoneList: this.state.milestoneList
        })
    }

    upTask = (model, index) => {
        if (index !== undefined) {
            let newIndex = index - 1
            if (newIndex >= 0) {
                let children = model.parent.children
                model.parent.children = [
                    ...children.slice(0, newIndex),
                    children[index],
                    children[newIndex],
                    ...children.slice(index + 1, children.length),
                ]
                this.setState({
                    milestoneList: this.state.milestoneList
                })
            }
        }
    }

    afterBatchUpdateExecutor = () => {
        this.setState({
            showTaskBatchOperationPanel: false
        })
    }

    removeMilestone = (event, model, index) => {
        this.setState({
            milestoneList: this.state.milestoneList.filter((m, idx) => idx !== index)
        })
        event.stopPropagation()
    }

    renderDeliverable = (deliverable: any[]) => {
        if (deliverable) {
            return (<WorkBenchTable<any>
                disableCheckBox={true}
                data={deliverable}
                dataLoading={false}
                columnProvider={() => deliverableColumnProviderEditor(this.props.t, this.onDeliverableAction)}
            />
            )
        }
        return <></>
    }

    editingOnChanged = (t: any) => {
        this.setState({ milestoneList: this.state.milestoneList })
    }

    renderTaskList = (milestone: any, serviceTasks: any[]) => {
        return (
            <WorkBenchTable<any>
                rowKey={(r, i) => r.title + i?.toString() ?? "0"}
                hidePagination={true}
                disableCheckBox
                data={serviceTasks}
                dataLoading={false}
                onChanged={this.editingOnChanged}
                columnProvider={() => taskColumnProviderEditor(this.props.t, this.onTaskAction)}
                expandable={
                    {
                        rowExpandable: record =>
                            record.type === "serial_task" ||
                            record.type === "parallel_task",
                        expandedRowRender: record => {
                            return (
                                <div key={record.title}>
                                    {
                                        this.renderTaskList(record, record.children)
                                    }
                                </div>);
                        }
                    }
                }
            />
        )
    }

    renderMilestoneList = (milestones: any[]) => {
        if (milestones) {
            return milestones.map((x, index) => (
                <Collapse.Panel key={x.title} header={x.title + " - " + this.props.t("project.template.task.types." + x.type.toLowerCase())} extra={this.genMilestoneExtra(x, index)}>
                    <div>
                        {this.renderTaskList(x, x.children)}
                    </div>
                </Collapse.Panel>
            ));
        }
        return <></>;
    }

    genPreRequirementExtra = (): React.ReactNode => {
        return (
            <Popover content={this.props.t("project.template.add-deliverable")}>
                <Button
                    className={"add-btn"}
                    icon={<PlusOutlined style={{ color: "#306cd2" }} />}
                    shape={"circle"}
                    size={"small"}
                    onClick={(event) => this.showAddDeliverableInput(event)}
                />
            </Popover>
        )
    }

    genMilestoneExtra = (milestone, index): React.ReactNode => {
        return (
            <div>
                <Popover
                    content={this.props.t("project.template.add-task")}
                >
                    <Button
                        className={"add-btn left-btn"}
                        icon={<PlusOutlined style={{ color: "#306cd2" }} />}
                        size={"small"}
                        onClick={(event) => this.showAddTaskInput(milestone, event)}
                        shape={"circle"}
                    />
                </Popover>
                <Popover
                    content={this.props.t("project.template.delete")}
                >
                    <Popconfirm
                        title={this.props.t("project.template.confirm-delete")}
                        onCancel={(e => e?.stopPropagation())}
                        onConfirm={(event) => this.removeMilestone(event, milestone, index)}
                    >
                        <Button
                            icon={<MinusOutlined />}
                            danger
                            size={"small"}
                            onClick={(event) => event.stopPropagation()}
                            shape={"circle"}
                        />
                    </Popconfirm>
                </Popover>
            </div>
        )
    }

    renderProjectTemplate = () => {
        let preRequirements: any[] = this.state.preRequirements;
        let milestoneList: any[] = this.state.milestoneList;
        if (preRequirements && milestoneList) {
            return (
                <>
                    <div className="service-order-section">
                        <div className="label">
                            <span>{this.props.t("project.template.pre-requirement")}</span>
                            &nbsp;
                            <Question answer={this.props.t("project.template.editor-comment.pre-requirement")} />
                        </div>
                        <Collapse className="section-list" defaultActiveKey={this.preRequirementTitle}>
                            {this.renderPreRequirement(preRequirements)}
                        </Collapse>
                    </div>
                    <div className="service-order-section">
                        <div className="label" style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>{this.props.t("project.template.milestone")}&nbsp;
                                <Question answer={this.props.t("project.template.editor-comment.milestone")} /></span>

                            <div>

                                <Popover
                                    content={this.props.t("project.template.add-milestone")}
                                >
                                    <Button
                                        className={"add-btn"}
                                        style={{ marginRight: "16px" }}
                                        icon={<PlusOutlined style={{ color: "#306cd2" }} />}
                                        shape={"circle"}
                                        size={"small"}
                                        onClick={() => { this.setState({ showMilestoneInput: true }) }}
                                    />
                                </Popover>
                            </div>
                        </div>
                        <Collapse className="section-list">
                            {this.renderMilestoneList(milestoneList)}
                        </Collapse>
                    </div>
                </>
            );
        }
        return <></>
    }

    renderDeliverableAddEditor = () => {
        return (
            <>
                <Modal
                    visible={this.state.showDeliverableInput}
                    title={this.state.deliverableEditorOnEditStatus ? this.props.t("project.template.edit-deliverable") : this.props.t("project.template.add-deliverable")}
                    zIndex={1000}
                    closable={false}
                    onCancel={() => { this.setState({ showDeliverableInput: false }) }}
                    footer={null}
                    destroyOnClose={true}
                >
                    <DeliverableEditor
                        onFinish={this.onFinishDeliverable}
                        onCancel={() => this.setState({ showDeliverableInput: false })}
                        deliverable={this.state.selectedDeliverableForEditing}
                    />
                </Modal>
            </>
        )
    }

    renderMilestoneAddEditor = () => {
        return (
            <>
                <Modal
                    visible={this.state.showMilestoneInput}
                    title={this.props.t("project.template.add-milestone")}
                    zIndex={1000}
                    closable={false}
                    onCancel={() => { this.setState({ showMilestoneInput: false }) }}
                    footer={null}
                >
                    <MilestoneEditor
                        onFinish={this.onFinishAddMilestone}
                        onCancel={() => { this.setState({ showMilestoneInput: false }) }}
                    />
                </Modal>
            </>
        )
    }

    renderTaskEditorTitle(): ReactNode {
        return this.state.taskEditorOnEditStatus
            ? (this.props.t("project.template.edit-task") + " - " + this.state.selectedTaskForEdit?.title)
            : (this.props.t("project.template.add-task") + " - " + this.state.selectedTaskForAdding?.title)
    }

    renderTaskAddEditor = () => {
        return (
            <>
                <Modal
                    visible={this.state.showTaskInput}
                    title={this.renderTaskEditorTitle()}
                    zIndex={1000}
                    closable={false}
                    onCancel={() => {
                        this.setState({ showTaskInput: false })
                    }}
                    footer={null}
                    destroyOnClose={true}
                >
                    <TaskEditorView
                        onFinish={this.onFinishTask}
                        cancelEdit={() => this.setState({ showTaskInput: false })}
                        userList={this.state.userList ?? []}
                        taskInfo={this.state.taskEditorOnEditStatus ? this.state.selectedTaskForEdit : {}}
                        ticketPriorityList={this.state.ticketPriorityList}
                    />
                </Modal>
            </>
        );
    }

    renderTaskBatchOperationPanel = () => (
        <>
            <Modal
                visible={this.state.showTaskBatchOperationPanel}
                style={{ top: 120 }}
                onCancel={() => this.setState({ showTaskBatchOperationPanel: false })}
                width={1500}
                destroyOnClose
                footer={null}
            >
                <TaskBatchOperationPanel
                    apiServer={this.props.apiServer}
                    taskList={this.prepareTaskListForBatchOperation()}
                    afterUpdateExecutor={this.afterBatchUpdateExecutor}
                    userList={this.state.userList}
                />
            </Modal>
        </>
    )

    updateProjectOwner = (user) => {
        if (this.state.template) {
            this.setState({
                template: {
                    ...this.state.template,
                    ownerId: user.id,
                    ownerName: user.name
                }
            })
        }
        this.closeUpdateProjectOwnerView()
    }

    updateProjectVersion = () => {
        if (this.state.template) {
            this.setState({
                template: {
                    ...this.state.template,
                    version: this.state.newProjectVersion ?? ""
                }
            })
        }
        this.closeUpdateProjectVersionView()
    }

    closeUpdateProjectOwnerView = () => {
        this.setState({
            showOwnerEditor: false
        })
    }

    closeUpdateProjectVersionView = () => {
        this.setState({
            showVersionEditor: false
        })
    }

    onProjectOwnerChange = (option) => {
        this.setState({
            selectedProjectOwner: {
                id: option.value,
                name: option.children
            }
        })
    }

    genEditProjectOwner = () => {
        return (
            <UserSelector
                style={{ width: 200 }}
                apiServer={this.props.apiServer}
                selectedUser={this.updateProjectOwner}
                cancelSelect={this.closeUpdateProjectOwnerView} />
        )
    }

    genEditProjectVersion = () => {
        return (
            <div style={{ display: "flex" }}>
                <Input onChange={event => this.setState({ newProjectVersion: event.target.value })} />
                <Button type={"primary"} onClick={this.updateProjectVersion}>确认</Button>
                <Button onClick={this.closeUpdateProjectVersionView}>取消</Button>
            </div>
        )
    }

    genProjectTitle = () => {
        let title = (<div>{this.state.template?.title}&nbsp;
            <Question answer={this.props.t("project.template.editor-comment.title")} /></div>)
        if (this.state.onDraftStatus) {
            title = (<div style={{ display: "flex" }}>
                <EditableTextColumn<any>
                    record={this.state.template ? this.state.template : {}}
                    editingField={"title"}
                />&nbsp;
                <Question answer={this.props.t("project.template.editor-comment.title")} />
            </div>)
        }
        return (
            title
        )
    }

    render(): React.ReactNode {
        return (
            <div className="service-template-editor">
                <PageHeader
                    title={this.genProjectTitle()}
                    tags={[
                        <Popover
                            visible={this.state.showVersionEditor}
                            content={this.genEditProjectVersion}
                        >

                            <Tag color={"blue"} onClick={() => this.setState({ showVersionEditor: true, showOwnerEditor: false })}>
                                {this.props.t("project.template.version") + " -"}{this.state.template?.version}
                                &nbsp;
                                <Question answer={this.props.t("project.template.editor-comment.version")} />
                            </Tag>
                        </Popover>
                        ,
                        <Popover visible={this.state.showOwnerEditor} content={this.genEditProjectOwner}>
                            <Tag color={"cyan"} onClick={() => this.setState({ showOwnerEditor: true, showVersionEditor: false })}>
                                {this.props.t("project.template.owner") + " -"}{this.state.template?.ownerName}
                                &nbsp;
                                <Question answer={this.props.t("project.template.editor-comment.owner")} />
                            </Tag>
                        </Popover>
                    ]}
                    extra={[
                        <Button
                            type="default"
                            icon={<BarsOutlined />}
                            onClick={this.showTaskBatchOperationPanel}
                            loading={this.state.loadingSaveDraft}
                        >
                            {this.props.t("project.template.batch-operation")}
                            <Question answer={this.props.t("project.template.editor-comment.batch-operation")} />
                        </Button>,

                        <Button
                            type="default"
                            icon={<EditOutlined />}
                            onClick={this.saveDraft}
                            loading={this.state.loadingSaveDraft}
                        >
                            {this.props.t("project.template.save-draft")}
                            <Question answer={this.props.t("project.template.editor-comment.save-draft")} />
                        </Button>

                        ,
                        <Popconfirm
                            title={this.props.t("project.template.whether-active-after-publish")}
                            cancelText={this.props.t("project.template.no")} okText={this.props.t("project.template.yes")}
                            onCancel={() => this.saveProjectTemplate(false)}
                            onConfirm={() => this.saveProjectTemplate(true)}
                        >
                            <Button type="primary" icon={<SaveOutlined />}>
                                {this.props.t("project.template.publish")}
                                <Question answer={this.props.t("project.template.editor-comment.publish-project")} />
                            </Button>
                        </Popconfirm>
                    ]}
                    footer={
                        <Button
                            type="default"
                            icon={<EditOutlined />}
                            onClick={this.saveDraft}
                            loading={this.state.loadingSaveDraft}
                        >
                            {this.props.t("project.template.save-draft")}
                            <Question answer={this.props.t("project.template.editor-comment.save-draft")} />
                        </Button>
                    }
                >
                    <div className="detail-container">
                        <div className="label">
                            {this.props.t("project.template.template-description-title")}&nbsp;
                            <Question answer={this.props.t("project.template.editor-comment.project-description")} />
                        </div>
                        <div className="description">
                            <TextArea rows={4} readOnly={false}
                                onChange={e => {
                                    if (this.state.template) {
                                        this.setState({
                                            template: {
                                                ...this.state.template,
                                                description: e.target.value
                                            }
                                        })
                                    }
                                }}
                                value={this.state.template?.description} />
                        </div>
                        <div className="label">
                            {this.props.t("project.template.template-view-title")}
                        </div>
                        <div className="service-order-container">
                            {this.renderProjectTemplate()}
                        </div>
                    </div>
                </PageHeader>
                {this.renderDeliverableAddEditor()}
                {this.renderMilestoneAddEditor()}
                {this.renderTaskAddEditor()}
                {this.renderTaskBatchOperationPanel()}
            </div>
        );
    }
}
export default withRouter(withTranslation()(ProjectTemplateEditor));