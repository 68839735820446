import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import ApiServer from "../../../ApiServer";
import { ActionEventHandler } from "../../../components";
import ColumnTextFilter from "../../../components/table/column-text-filter";
import { StopOutlined, LogoutOutlined } from "@ant-design/icons";
import './index.less';
import '../../../config/ex-icon'
import { ExtendsIcons } from "../../../config/ex-icon";
import ConnectiveStatus from "../component/connective-status";


export interface RemoteInstanceModel {
    id: number,
    type: string,
    name: string,
    identifier: string,
    status: string,
    publicIp: string,
    accessIp: string,
    tags: any,
    connectiveStatus: ConnectiveStatusModel
}

export interface ConnectiveStatusModel {
    name?: string,
    desc?: { [key: string]: string; }
}



export const loadEnrollPendingInstances = function (apiServer: ApiServer): Promise<RemoteInstanceModel[]> {
    return apiServer.apiCluster.getRemoteInstances()
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.remoteInstances?.map(i => {
                    let r: RemoteInstanceModel = {
                        id: i.id ?? 0,
                        type: i.type ?? "",
                        name: i.name ?? "",
                        identifier: i.identifier ?? "",
                        status: i.status ?? "",
                        publicIp: i.publicIp ?? "",
                        accessIp: i.accessIp ?? "",
                        tags: i.tags,
                        connectiveStatus: {
                            ...i.networkStatusDto
                        }
                    }
                    return r;
                }) ?? [];
            }
            return []
        });
}

export const loadEnrolledInstances = function (apiServer: ApiServer): Promise<RemoteInstanceModel[]> {
    return apiServer.apiCluster.getRemoteInstances("enrolled")
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.remoteInstances?.map(i => {
                    let r: RemoteInstanceModel = {
                        id: i.id ?? 0,
                        type: i.type ?? "",
                        name: i.name ?? "",
                        identifier: i.identifier ?? "",
                        status: i.status ?? "",
                        publicIp: i.publicIp ?? "",
                        accessIp: i.accessIp ?? "",
                        tags: i.tags,
                        connectiveStatus: {
                            ...i.networkStatusDto
                        }
                    }
                    return r;
                }) ?? [];
            }
            return []
        });
}

export const loadActiveInstances = function (apiServer: ApiServer): Promise<RemoteInstanceModel[]> {
    return apiServer.apiCluster.getRemoteInstances("normal-active")
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.remoteInstances?.map(i => {
                    let r: RemoteInstanceModel = {
                        id: i.id ?? 0,
                        type: i.type ?? "",
                        name: i.name ?? "",
                        identifier: i.identifier ?? "",
                        status: i.status ?? "",
                        publicIp: i.publicIp ?? "",
                        accessIp: i.accessIp ?? "",
                        tags: i.tags,
                        connectiveStatus: {
                            ...i.networkStatusDto
                        }
                    }
                    return r;
                }) ?? [];
            }
            return []
        });
}

export const loadRemovedInstances = function (apiServer: ApiServer): Promise<RemoteInstanceModel[]> {
    return apiServer.apiCluster.getRemoteInstances("removed")
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.remoteInstances?.map(i => {
                    let r: RemoteInstanceModel = {
                        id: i.id ?? 0,
                        type: i.type ?? "",
                        name: i.name ?? "",
                        identifier: i.identifier ?? "",
                        status: i.status ?? "",
                        publicIp: i.publicIp ?? "",
                        accessIp: i.accessIp ?? "",
                        tags: i.tags,
                        connectiveStatus: {
                            ...i.networkStatusDto
                        }
                    }
                    return r;
                }) ?? [];
            }
            return []
        });
}

export const loadRejectedInstances = function (apiServer: ApiServer): Promise<RemoteInstanceModel[]> {
    return apiServer.apiCluster.getRemoteInstances("enroll-reject")
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.remoteInstances?.map(i => {
                    let r: RemoteInstanceModel = {
                        id: i.id ?? 0,
                        type: i.type ?? "",
                        name: i.name ?? "",
                        identifier: i.identifier ?? "",
                        status: i.status ?? "",
                        publicIp: i.publicIp ?? "",
                        accessIp: i.accessIp ?? "",
                        tags: i.tags,
                        connectiveStatus: {
                            ...i.networkStatusDto
                        }
                    }
                    return r;
                }) ?? [];
            }
            return []
        });
}

export const loadBlockedInstances = function (apiServer: ApiServer): Promise<RemoteInstanceModel[]> {
    return apiServer.apiCluster.getRemoteInstances("blocked")
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.remoteInstances?.map(i => {
                    let r: RemoteInstanceModel = {
                        id: i.id ?? 0,
                        type: i.type ?? "",
                        name: i.name ?? "",
                        identifier: i.identifier ?? "",
                        status: i.status ?? "",
                        publicIp: i.publicIp ?? "",
                        accessIp: i.accessIp ?? "",
                        tags: i.tags,
                        connectiveStatus: {
                            ...i.networkStatusDto
                        }
                    }
                    return r;
                }) ?? [];
            }
            return []
        });
}


export const pendingInstancesColumns = (translater: ((d: string) => string), handler: ActionEventHandler<RemoteInstanceModel>, language: string, apiServer: ApiServer): ColumnType<RemoteInstanceModel>[] => {

    return [

        {
            title: translater("cluster-mgr.instance.name"),
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: RemoteInstanceModel) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: RemoteInstanceModel) => record.identifier.indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("cluster-mgr.instance.name")} />)
            },
        },
        {
            title: translater("cluster-mgr.instance.identifier"),
            dataIndex: 'identifier',
            key: 'identifier',
            render: (text: string, record: RemoteInstanceModel) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: RemoteInstanceModel) => record.name.indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("cluster-mgr.instance.identifier")} />)
            },
        },
        {
            title: translater("cluster-mgr.instance.type"),
            dataIndex: 'type',
            key: 'type',
            render: (text) => {
                return translater("cluster-mgr.instance.instance-types." + text)
            }
        },
        {
            title: translater("cluster-mgr.instance.publicIp"),
            dataIndex: 'publicIp',
            key: 'publicIp',
        },
        {
            title: translater("cluster-mgr.instance.accessIp"),
            dataIndex: 'accessIp',
            key: 'accessIp',
        },
        {
            title: translater("cluster-mgr.instance.connective-status.desc"),
            dataIndex: 'connectiveStatus',
            key: 'connectiveStatus',
            render: (t, record) => {
                return <ConnectiveStatus state={record.connectiveStatus} clusterIdentifier={record.identifier} apiServer={apiServer} />
            }
        },
        {
            title: translater("cluster-mgr.instance.status"),
            dataIndex: 'status',
            key: 'status',
            render: (t) => {
                return translater("cluster-mgr.instance.instance-status." + t)
            }

        },
        {
            title: translater("cluster-mgr.instance.operation"),
            dataIndex: 'id',
            key: 'id',
            render: (t, r) => {
                if (r.status === "enroll-pending") {
                    return (
                        <div className="instance-operation-buttons">
                            <Button onClick={() => { handler(r, "approve") }} type="primary" >{translater("cluster-mgr.instance.approve-enroll")}</Button>
                            <Button onClick={() => { handler(r, "reject") }} danger type="primary">{translater("cluster-mgr.instance.reject-enroll")}</Button>
                        </div>
                    )
                } else if (r.status === "enrolled") {
                    return (
                        <div className="instance-operation-buttons">
                            <Button onClick={() => { handler(r, "remove") }} danger type="primary">{translater("cluster-mgr.instance.remove")}</Button>
                        </div>
                    )
                } else if (r.status === "normal-active") {
                    return (
                        <div className="instance-operation-buttons">
                            <Button onClick={() => { handler(r, "block") }} danger icon={<StopOutlined />} type="dashed">{translater("cluster-mgr.instance.block")}</Button>
                            <Button onClick={() => { handler(r, "remove") }} danger icon={<LogoutOutlined />} type="primary">{translater("cluster-mgr.instance.remove")}</Button>
                        </div>
                    )
                } else if (r.status === "blocked") {
                    return (
                        <div className="instance-operation-buttons">
                            <Button icon={<ExtendsIcons type="icon-shujuhuifu" />} onClick={() => { handler(r, "approve") }} type="primary">{translater("cluster-mgr.instance.resume-active")}</Button>
                            <Button onClick={() => { handler(r, "remove") }} danger icon={<LogoutOutlined />} type="primary">{translater("cluster-mgr.instance.remove")}</Button>
                        </div>
                    )
                }
                else if (r.status === "removed") {
                    return (
                        <div className="instance-operation-buttons">
                            <Button onClick={() => { handler(r, "approve") }} type="primary">{translater("cluster-mgr.instance.accept-enroll")}</Button>
                        </div>
                    )
                }
            }
        }
    ]
}

