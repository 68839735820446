import { Button, Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserModel } from "../../../@mod";
import ApprovalButton from "./approval-button";
import './index.less'





export interface ApprovalViewModel extends WithTranslation {
	editable: boolean
	reject?: string
	value: string
	onApprovalSubmit?: (content: string) => void
	approverName: string,
	showApprover: boolean,
	userModels: UserModel[],
	onback?:()=> void
}

export interface ApprovalMessage {
	message?: string,
	approved?: boolean,
	date?: string,
	operator?: string,

}

export interface ApprovalContent {
	lastApproved?: boolean,
	lastMessage?: string,
	rejectTo?: number,
	history?: ApprovalMessage[]

}

interface ApprovalViewState {
	content?: ApprovalContent
}



class ApprovalView extends React.Component<ApprovalViewModel, ApprovalViewState>{

	constructor(props: ApprovalViewModel) {
		super(props)
		this.state = {
			content: JSON.parse(this.props.value)
		}
	}

	getSnapshotBeforeUpdate(preProps: ApprovalViewModel, propState: ApprovalViewState) {
		if (preProps.value !== this.props.value) {
			this.setState({
				content: JSON.parse(this.props.value)
			})
		}
		return null;
	}

	componentDidUpdate() { }

	agree = (message: string) => {
		let content = this.state.content;
		if (!content) {
			content = {}
		}
		content.lastApproved = true;
		content.lastMessage = message;
		if (!content.history) {
			content.history = [];
		}
		this.setState({
			content: content
		})
		this.props.onApprovalSubmit?.(JSON.stringify(content));
	}
	disagree = (message: string, disagreeTo: number) => {
		let content = this.state.content;
		if (!content) {
			content = {}
		}
		if (!content.history) {
			content.history = [];
		}
		content.lastApproved = false;
		content.lastMessage = message;
		content.rejectTo = disagreeTo;
		this.setState({
			content: content
		})
		this.props.onApprovalSubmit?.(JSON.stringify(content));
	}

	getDisagreeButton = () => {
		let rejectButtons = this.props.reject?.split("|");
		if (rejectButtons) {
			return rejectButtons.map(x => {
				let buttonItem = x.split(":");
				let text = buttonItem[0];
				let rejectTo = buttonItem[1];
				return (
					<ApprovalButton
						defaultMessage=""
						displayText={this.props.t("auto.reject-to") + text}
						type="primary"
						danger
						onSubmit={(v) => { this.disagree(v, Number(rejectTo)) }}
					/>
				)
			})
		}
	}

	getApprovalEditors = () => {
		return (this.props.editable && this.props.showApprover && <div className="approval-form">
			<div>{this.props.t("auto.approval-process")}</div>
			<ApprovalButton defaultMessage={this.props.t("auto.agree")}
				displayText={this.props.t("auto.agree")}
				type="primary"
				onSubmit={this.agree}
			></ApprovalButton>
			{this.getDisagreeButton()}
		</div>)
	}
	getOperator = (opId: number) => {
		let u = this.props.userModels.filter(y => y.id === opId);
		if (u.length > 0) {
			return <div>
				<div style={{fontWeight:"bold"}}>{u[0].name}</div>
				<div style={{color:"grey"}} >{u[0].organization}</div>
			</div>;
		}
		return <></>;
	}

	getApprovalHistory = () => {
		console.log(this.state.content)
		if (this.state.content?.history) {
			let historyContent: JSX.Element[] = [];
			historyContent.push(
				<Row gutter={[8, 16]} key={1} >
					<Col span={6}> <h1>{this.props.t("auto.operator")}</h1></Col>
					<Col span={2}> <h1>{this.props.t("auto.approval")}</h1></Col>
					<Col span={10}> <h1>{this.props.t("auto.message")}</h1></Col>
					<Col span={6}> <h1>{this.props.t("auto.date")}</h1></Col>
				</Row>)

			this.state.content.history.map(x => {
				historyContent.push(
					<Row gutter={[8, 16]} key={x.date}>
						<Col span={6}> {this.getOperator(Number(x.operator))}</Col>
						<Col span={2}>{x.approved ? this.props.t("auto.agree") : this.props.t("auto.reject")}</Col>
						<Col span={10}>{x.message}</Col>
						<Col span={6}>{moment.parseZone(x.date).format(this.props.t("time-pattern-long-second"))}</Col>
					</Row>);
			})
			return historyContent;
		}


		return (<></>)
	}

	getApprovalUserName = () => {
		return (this.props.editable && !this.props.showApprover &&
			<div>
				<div>{this.props.t("auto.please-contact")} {this.props.approverName} {this.props.t("auto.getting-approval")} </div>
				<div><Button type="link" onClick={() => { this.props.onback?.() }} >{this.props.t("auto.back")} </Button></div>
			</div>
		)
	}

	render(): React.ReactNode {

		return (
			<>
				{this.getApprovalEditors()}
				{this.getApprovalUserName()}

				<div className="approval-history-container">
					<div className="approve-title"><h1>{this.props.t("auto.approve-history")}</h1></div>
					<div className="approval-history-table">{this.getApprovalHistory()}</div>
					
				</div>
			</>
		)
	}

}



export default withTranslation()(ApprovalView);