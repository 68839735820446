import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import NotificationSetting from "../component/notification-setting";
import ApiServer from "../../../../ApiServer";



export interface VoiceConfigurePageModel extends WithTranslation {
	apiServer: ApiServer
}
interface VoiceConfigurePageState{

}

class VoiceConfigurePage extends React.Component<VoiceConfigurePageModel,VoiceConfigurePageState>{

	render(): React.ReactNode {
		return (
			<NotificationSetting
				channelType={"voice"}
				channelConfigurationTitle={this.props.t("notification-setting.channel-configuration.config-voice-parameters")}
				messageTemplateContainerTitle={this.props.t("notification-setting.message-template.voice-message-template")}
				apiServer={this.props.apiServer}
			/>
		);
	}
}

export default withTranslation()(VoiceConfigurePage);