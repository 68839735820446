import { Button, Switch } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { RouteContext, withRouter } from "../../../utils";
import { convertTo, TicketModel } from "../@mod";
import AssignedTickets from "../assigned-tickets";
import {
	CheckCircleOutlined,
	CaretRightOutlined
} from '@ant-design/icons'



export interface MyRelatedTicketsViewModel extends WithTranslation {
	apiServer: ApiServer
	router?: RouteContext
}

interface MyRelatedTicketViewState {
	loading: boolean,
	relatedTicket: TicketModel[],
	title: string,
	subtitle: string,
	loadClosed: boolean

}

class MyRelatedTickets extends React.Component<MyRelatedTicketsViewModel, MyRelatedTicketViewState>{

	constructor(props) {
		super(props)
		let willLoadClosed = localStorage.getItem("load-closed");
		this.state = {
			title: "my-related-ticket",
			relatedTicket: [],
			loading: true,
			subtitle: "tickets.my-related-ticket",
			loadClosed: willLoadClosed == null ? false :
				willLoadClosed.toLowerCase() === "true"
		}
	}

	loadData = async (closed?: boolean) => {
		this.setState({
			loading: true
		})

		console.log(this.state.loadClosed)
		await this.props.apiServer.apiTickets
			.getRelatedList(closed ?? this.state.loadClosed)
			.then(x => {
				if (x.data.data?.tickets) {
					this.setState({
						relatedTicket: x.data.data?.tickets?.map(convertTo),

					})
				}
			}
			);

		this.setState({
			loading: false
		})
	}


	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData().finally(
				() => { this.l = undefined; }
			)
		}

	}

	onLoadAll = () => {
		let willLoadClosed = !this.state.loadClosed;
		localStorage.setItem("load-closed", willLoadClosed.toString())
		console.log("onload", willLoadClosed)
		this.setState({
			loadClosed: willLoadClosed
		})
		this.loadData(willLoadClosed);
	}

	ticketUpdate = (id: number) => {

		this.setState({
			loading: true
		})


		this.props.apiServer.apiTickets.getTicket(id)
			.then(x => {
				if (x.data.data?.succeed && x.data.data.ticket) {
					var list = this.state.relatedTicket;
					list = list.filter(x => x.id != id)
					list.push(convertTo(x.data.data?.ticket));
					this.setState({
						relatedTicket: list
					})
				}
			}).finally(
				() => {
					this.setState({
						loading: false
					})
				})
	}

	getButton = () => {
		if (this.state.loadClosed) {
			return <Button
				loading={this.state.loading}
				type="ghost"
				onClick={this.onLoadAll}
				icon={<CheckCircleOutlined />}
			>
				{this.props.t("tickets.include-closed")}
			</Button>
		} else {
			return <Button
				loading={this.state.loading}
				type="primary"
				onClick={this.onLoadAll}
				icon={<CaretRightOutlined />}>
				{this.props.t("tickets.exclude-closed")}
			</Button>
		}
	}


	render(): React.ReactNode {
		return (
			<div className="my-related">
				<AssignedTickets
					title={this.state.title}
					subtitle={this.state.subtitle}
					tickets={this.state.relatedTicket}
					loading={this.state.loading}
					apiServer={this.props.apiServer}
					withCreateNewTicketButton
					ticketUpdate={this.ticketUpdate}
					extButton={
						this.getButton()
					}
				></AssignedTickets>
			</div>)
	}
}


export default withRouter(withTranslation()(MyRelatedTickets))