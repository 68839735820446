
import { Button, Drawer, notification, PageHeader, Popover, Space, Statistic } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { RouteContext, withRouter } from "../../../utils";

import { loadUserGroupModels, loadUserGroups, loadUsers, PriorityModel, SourceModel, StatusModel, TicketModel, TicketTypeModel, UserGroupModel, UserModel } from "../@mod";
import NewTicketEditor from "../new-ticket-editor";
import { saveAs } from 'file-saver';

import TicketList from "../ticket-list";
import './index.less'

import CSV from 'comma-separated-values';
import moment from "moment";
import { ExportOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import TicketDetail from "../ticket-detail";
import AssignPanel from "../assign-panel";


export interface AssignedTicketPageModel extends WithTranslation {
	title: string;
	subtitle: string;
	tickets: TicketModel[],
	loading: boolean,
	apiServer: ApiServer
	router?: RouteContext,
	withCreateNewTicketButton?: boolean,
	extButton?: ReactNode,
	extButton2?: ReactNode,
	skippedColumn?: string[]
	enableGroupAssign?: boolean,

	reload?: () => void,
	ticketUpdate ? :(ticketId:number) => void
}

export interface AssignedTicketPageState {
	showNewAccountEditor: boolean,
	ticketTypes: TicketTypeModel[],
	ticketPriority: PriorityModel[],
	ticketStatus: StatusModel[],
	ticketSource: SourceModel[],
	showTicketDetail: boolean,
	ticektToShow: number,
	selectedTicket: TicketModel[],
	assignPanelVisable: boolean,
	isAdmin: boolean,
}


interface TicketCreationProps {
	priority: number,
	type: number,
	status: number,
	source: number
}


class AssignedTicketPage extends React.Component<AssignedTicketPageModel, AssignedTicketPageState>{

	creationProps: TicketCreationProps | undefined;
	constructor(props: AssignedTicketPageModel) {
		super(props)
		this.state = {
			showNewAccountEditor: false,
			ticketTypes: [],
			ticketPriority: [],
			ticketStatus: [],
			ticketSource: [],
			showTicketDetail: false,
			ticektToShow: 0,
			selectedTicket: [],
			assignPanelVisable: false,
			isAdmin:false
		}

	}

	initCreationProps = () => {
		this.creationProps = {
			priority: 0,
			source: 0,
			status: 0,
			type: 0
		};

	}

	l: Promise<void> | undefined

	loadData = async () => {
		await  this.props.apiServer.apiSystem.getLoginUser()
		.then(
			x => {
				 this.setState({ isAdmin : x.data.data?.admin ?? false});
			}
		)
		await this.props.apiServer.apiMeta.getTicketTypeList(false)
			.then(x => {
				let ticketTypes = x.data.data?.list?.map(y => {
					let ticketType: TicketTypeModel = {
						name: y.name ?? "",
						description: y.description,
						id: y.id ?? 0
					}
					return ticketType;
				})
				this.setState({
					ticketTypes: ticketTypes ?? []
				})
			})
		await this.props.apiServer.apiMeta.getTicketPriority(false)
			.then(
				x => {
					let priority = x.data.data?.list?.map(
						y => {
							let p: PriorityModel = {
								id: y.id ?? 0,
								name: y.name ?? "",
								description: y.lang

							}
							return p;
						}
					)
					this.setState({
						ticketPriority: priority ?? []
					})
				}
			);
		await this.props.apiServer.apiMeta.getTicketStatusList(false)
			.then(x => {
				let status = x.data.data?.list?.map(x => {
					let status: StatusModel = {
						id: x.id ?? 0,
						name: x.name ?? "",
						description: x.description
					}
					return status;
				}) ?? []
				this.setState({ ticketStatus: status })
			})

		await this.props.apiServer.apiMeta.getTicketSourceList(false)
			.then(x => {
				let sources = x.data.data?.list?.map(y => {
					let source: SourceModel = {
						id: y.id ?? 0,
						name: y.name ?? "",
						description: y.lang
					}
					return source;
				}) ?? []
				this.setState({ ticketSource: sources })
			})

	}

	componentDidMount = () => {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => {
					this.l = undefined;
				})

		}
	}


	renderType = () => {
		return this.state.ticketTypes.map(
			x => {
				return (
					<div className="stat-item" key={x.id}>
						<Statistic title={x.description[this.props.i18n.language]} loading={this.props.loading}
							value={this.props.tickets.filter(y => y.type.id === x.id).length}
						></Statistic>
					</div>)
			}
		)
	}

	renderPriority = () => {
		return this.state.ticketPriority.map(
			x => {
				return (
					<div className="stat-item" key={x.id}>
						<Statistic title={x.description[this.props.i18n.language]}
							loading={this.props.loading}
							value={this.props.tickets.filter(y => y.priority.id === x.id).length}
							valueStyle={{ color: x.description["color"] }}
						/>
					</div>
				)
			}
		)
	}

	navigateToDetail = (t: TicketModel) => {
		//this.props.router?.navigate("/ticket/" + t.id + "/detail");
		
		this.setState({
			ticektToShow: t.id,
			showTicketDetail: true
		})
	}

	newTicketClicked = () => {
		this.setState(
			{
				showNewAccountEditor: true
			}
		)
	}

	saveNewTicket = () => {
		if (this.saveHandler) {
			this.saveHandler().then(x => {
				if (x) {
					this.setState({
						showNewAccountEditor: false
					})
				}
			})
		}
	}


	saveHandler: (() => Promise<boolean>) | undefined;

	exportCurrentView = () => {
		var csvData = new CSV(this.props.tickets.map(x => {
			var sla = moment.parseZone(x?.sla)
			let dur = moment.duration(sla.diff(moment.now()));
			return {
				id: x.id,
				subject: x.subject,
				subTitle: x.subject,
				assignedTo: x.assigned?.name,
				status: x.status.description[this.props.i18n.language],
				priority: x.priority.description[this.props.i18n.language],
				source: x.source.description[this.props.i18n.language],
				type: x.type.description[this.props.i18n.language],
				timeSLA: Math.round(dur.asHours()),
				updated: moment.parseZone(x.updateTime).format("YYYY-MM-DD HH:mm:ss"),
				created: moment.parseZone(x.createTime).format("YYYY-MM-DD HH:mm:ss")
			}
		}), {
			lineDelimiter: '\n',
			cellDelimiter: ',',
			header: [
				this.props.t("tickets.id"),
				this.props.t("tickets.subject"),
				this.props.t("tickets.subtitle"),
				this.props.t("tickets.assigned"),
				this.props.t("tickets.status.self"),
				this.props.t("tickets.priority.self"),
				this.props.t("tickets.source.self"),
				this.props.t("tickets.type.self"),
				this.props.t("tickets.sla") + "(h)",
				this.props.t("tickets.updateTime"),
				this.props.t("tickets.createTime")
			]
		}).encode();

		var blob = new Blob(['\ufeff', csvData], { type: "text/csv;charset=GBK" })
		saveAs(blob, "export.csv")
	}
	/*
	var element = document.createElement('a');
	element.target="_blank";
	element.href= x.data.data.attachmentInfo?.downloadUrl??""
	document.body.appendChild(element)
	element.click()
	URL.revokeObjectURL(element.href);
	document.body.removeChild(element);
*/
	exportSelected = () => {
		if (this.state.selectedTicket.length > 0) {
			if (!this.isExported) {
				this.isExported = true;
				let param: any = {
					withAttachment: false,
					ticketIds: this.state.selectedTicket.map(x => x.id)
				}
				this.props.apiServer.apiExport.postExport(
					1, {
					parameter: JSON.stringify(param)
				})
					.then(x => {
						setTimeout(() => {
							this.props.apiServer.apiExport.getExportJobDownloadUrl(
								1, x.data.data?.job?.jobId ?? ""
							).then(y => {
								if (y.data.data?.succeed) {
									var element = document.createElement('a');
									element.target = "_blank";
									element.href = y.data.data.downloadUrl ?? ""
									document.body.appendChild(element)
									element.click()
									URL.revokeObjectURL(element.href);
									document.body.removeChild(element);
								} else {
									notification.warn({ message: this.props.t("tickets.can-not-completed-in-time") })
								}
							});
						}, 3000)
					});
				notification.success({ message: this.props.t("tickets.export-job-queued") })
			} else {
				notification.warn({ message: this.props.t("tickets.do-no-duplicate-export") })
			}
		} else {
			notification.warn({ message: this.props.t("tickets.no-ticket-selected") })
		}

	}
	isExported: boolean = false

	ticketSelected = (t: TicketModel[]) => {
		this.isExported = false;
		this.setState({
			selectedTicket: t
		})
	}

	loadUserGroup = async (): Promise<UserGroupModel[]> => {
		return loadUserGroupModels(this.props.apiServer)
	}

	loadUserGroupUser = async (userGroupId): Promise<UserModel[]> => {
		return loadUsers(this.props.apiServer, userGroupId);
	}

	assignConfirmed = (userGroupId, userId) => {
		this.setState({
			assignPanelVisable: false
		});

		this.props.apiServer
			.apiTickets.putAssignTickets(
				{
					userGroupId: userGroupId,
					userId: userId,
					ticketIds: this.state.selectedTicket
						.map(x => x.id)
				}
			).then(x => {
				if (x.data.data?.success) {
					notification.success({
						message: this.props.t("tickets.batch-operation-succeed") + x.data.data.successCount
					})
					if (this.props.reload) {
						this.props.reload();
					}
				} else {
					notification.error({
						message: this.props.t("tickets.batch-operation-failed")
					})
				}

			})

	}

	renderTicketList = () => {
		return (
			<div style={{ display: this.state.showTicketDetail ? "none" : "block" }}>
				<div className="my-ticket-page-container site-drawer-render-in-current-wrapper" >
					<PageHeader title={this.props.t(this.props.title)}
						subTitle={this.props.t(this.props.subtitle)}
						extra={
							[
								<div key="ext1">{this.props.extButton ? this.props.extButton : <></>}</div>,
								<div key="ext2">{this.props.extButton2 ? this.props.extButton2 : <></>}</div>,
								<div>{
									this.props.enableGroupAssign && this.state.selectedTicket.length > 0 &&
									<div>
										<Popover
											destroyTooltipOnHide
											placement="bottom"
											visible={this.state.assignPanelVisable}
											content={
												<AssignPanel loadUserGroup={this.loadUserGroup}
													loadUserGroupUser={this.loadUserGroupUser}
													onAssigneConfirmed={this.assignConfirmed}
													isAdmin= {this.state.isAdmin}
													onCancel={() => {
														this.setState({ assignPanelVisable: !this.state.assignPanelVisable })
													}}
												/>
											}
										>
											<Button icon={<ArrowLeftOutlined />} onClick={() => this.setState({ assignPanelVisable: !this.state.assignPanelVisable })} >
												{this.props.t("tickets.assigned-to")}
											</Button>
										</Popover>
									</div>
								}
								</div>,
								<Button key={"export"} shape="round" loading={this.props.loading} icon={<ExportOutlined />} onClick={this.exportSelected} >{this.props.t("tickets.export-detail-selected")}</Button>,
								<div key="new">{this.props.withCreateNewTicketButton && <Button type="primary" onClick={this.newTicketClicked} loading={this.props.loading} >{this.props.t("tickets.new-ticket")}</Button>}</div>
							]
						}
						footer={(
							<Content>
								<TicketList tickets={this.props.tickets}
									dataLoading={this.props.loading}
									ondetail={this.navigateToDetail}
									corestatus={
										{
											status: this.state.ticketStatus,
											priority: this.state.ticketPriority,
											type: this.state.ticketTypes,
											source: this.state.ticketSource
										}
									}
									skippedColumn={this.props.skippedColumn}
									onSelected={
										this.ticketSelected
									}
								></TicketList>
							</Content>
						)}>
						<div className="stats">
							{this.renderType()}
							{this.renderPriority()}
						</div>
					</PageHeader>
				</div>


				{this.props.withCreateNewTicketButton &&
					<Drawer visible={this.state.showNewAccountEditor}
						width={8 * 60}
						destroyOnClose
						onClose={() => this.setState({ showNewAccountEditor: false })}
						bodyStyle={{ paddingBottom: 80 }}
						extra={
							<Space>
								<Button onClick={() => this.setState({ showNewAccountEditor: false })}
									key="cancle">
									{this.props.t("tickets.cancel")}</Button>
								<Button onClick={this.saveNewTicket} type="primary" key="save">
									{this.props.t("tickets.save-new-tickets")}
								</Button>
							</Space>
						}
					>
						<NewTicketEditor apiServer={this.props.apiServer}
							getSaver={(s) => this.saveHandler = s}

						/>
					</Drawer>
				}
			</div>

		)
	}

	detailClickBack = () => {
		if(this.props.ticketUpdate){
			this.props.ticketUpdate(this.state.ticektToShow)
		}

		this.setState({
			showTicketDetail: false
		})
	}

	renderTicketDetail = () => {
		if (this.state.showTicketDetail) {
			return <div>
				<TicketDetail
					apiServer={this.props.apiServer}
					ticketId={this.state.ticektToShow}
					back={this.detailClickBack}
				>
				</TicketDetail>

			</div>
		}
		return <></>
	}


	render(): React.ReactNode {
		return (
			<>
				{this.renderTicketList()}
				{this.renderTicketDetail()}
			</>
		)


	}
}

export default withRouter(withTranslation()(AssignedTicketPage));