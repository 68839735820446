/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Layout, Menu, AutoComplete, Input, Badge, Avatar, Button } from 'antd'
import { BellOutlined, SettingOutlined, MailOutlined, GlobalOutlined } from '@ant-design/icons';
import "./index.less"
import { RouteContext, withRoute, WithRouteContext } from "../../utils";
import PopupButton from "../../components/popup-button";
import LanguageMenu from "./language";
import { i18nMenu } from "../../config/i18n-menu"
import ApiServer from "../../ApiServer";
import UserAvatar from "../../components/user-avatar";

const { Header } = Layout;


interface ManagementHeaderModel extends WithTranslation, WithRouteContext {
	apiServer: ApiServer
}

interface ManagementHeaderState {
	userName: string
	fullName: string,
	org: string
	colorIndex: number,
	menus: string[]
}

class ManagementHeader extends React.Component<ManagementHeaderModel, ManagementHeaderState>{


	constructor(props: ManagementHeaderModel) {
		super(props)
		this.state = {
			userName: "AA",
			colorIndex: 0,
			fullName: "foo",
			org: "foo",
			menus: []
		};
		this.loadState();
		setInterval(
			() => {
				this.loadState()
			},
			60000
		)

	}

	loadState = async () => {
		await this.props.apiServer.apiSystem.getLoginUser()
			.then(
				x => {
					let user = x.data.data?.user;
					if (user?.name) {
						this.setState({
							userName: user.name,
							org: user.organization ?? "",
							fullName: user.fullName ?? ""
						})
					}
				}
			)
		await this.props.apiServer.apiSystem.getNavigation()
			.then(
				x => {
					this.setState(
						{
							menus: x.data.data?.allowedModules ?? []
						}
					)
				}
			)
	}

	//TODO: refactory to dynamic 
	get mainNavigate() {
		let { t } = this.props;
		return this.state.menus.map((key) => ({
			key,
			label: t(key),
		}));
	}
	menuClick = (dat: any) => {
		//console.log(dat)
		this.props.router?.navigate(dat.key + "/landing", {
			replace: true
		})
	}

	languageChanged = (lang: string) => {
		this.props.i18n.changeLanguage(lang);
		localStorage.setItem("lang", lang);
	}

	renderUserProfile = () => {
		return (
			<div className="login-info-container">
				<div className="item">
					<div className="label">
						{this.props.t("login-as")}:</div>
					<div>@{this.state.userName}</div>
				</div>
				<div className="item">
					<div className="label">
						{this.props.t("full-name")}:</div>
					<div>{this.state.fullName}</div>
				</div>
				<div className="item">
					<div className="label">
						{this.props.t("organazation")}:</div>
					<div>{this.state.org}</div>
				</div>
				<div className="button">
					<Button size="middle" danger type="primary" onClick={this.props.apiServer.logout} >{this.props.t("logoff")}</Button>
				</div>
			</div>
		)
	}

	render(): React.ReactNode {
		return (
			<Header className="header">
				<div className="logo">
					<Avatar size="large" shape="square" style={{ background: '#001529' }}>
						TT
					</Avatar>
				</div>
				<Menu className="menu" theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={this.mainNavigate}
					onClick={this.menuClick}
				/>
				<div className="search-bar">
					<AutoComplete dropdownMatchSelectWidth={252}>
						<Input.Search size="middle" placeholder={this.props.t('header.search')} enterButton />
					</AutoComplete>
				</div>
				<div className="status-bar">
					<div className="status-item">
						<div>
							<PopupButton title={this.props.t("account-status")}
								buttonIcon={
									<Badge count={0} size="small">
										<UserAvatar userName={this.state.userName} />
									</Badge>
								}
								content={this.renderUserProfile()}
								badge={0}
								placement="bottomRight"
							/>
						</div>
					</div>
					<div className="status-item">
						<a href="#">
							<PopupButton
								title="Alarm"
								content={<></>}
								buttonIcon={<BellOutlined className="outline-button" />}
								badge={0}
								placement="bottom" />
						</a>
					</div>
					<div className="status-item">
						<div>
							<PopupButton
								title="Mail"
								content={<></>}
								buttonIcon={<MailOutlined className="outline-button" />}
								badge={0}
								placement="bottom" />
						</div>
					</div>
					<div className="status-item">
						<a href="#">
							<PopupButton
								title="Settings"
								content={<></>}
								buttonIcon={<SettingOutlined className="outline-button" />}
								badge={0}
								placement="bottom" />
						</a>
					</div>
					<div className="status-item">
						<div>
							<PopupButton
								title={this.props.t("language")}
								content={<LanguageMenu languageList={i18nMenu} onLanguageChanged={this.languageChanged} />}
								buttonIcon={<GlobalOutlined className="outline-button" />}
								badge={0}
								placement="bottom"
							/>
						</div>
					</div>
				</div>
			</Header>
		);
	}
}

export default withRoute(withTranslation()(ManagementHeader));