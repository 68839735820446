import {Button, Input, message, Popconfirm} from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {NotificationConfigurationModel} from "../../../../@mod";
import './index.less'

export interface NotificationSettingBoxViewModel extends WithTranslation {
	item: NotificationConfigurationModel
	changeConfiguration: (key: string, value: string) => void
	resetConfiguration: (key: string) => void
	operator ?:(o:NotificationSettingBoxOperator) =>void
}

export interface NotificationSettingBoxOperator {
	reset : ()=>void
	setValue: (string) =>void
}

interface NotificationSettingBoxState {
	value: string,
	preValue: string,
	cannotEdit: boolean
}


class NotificationSettingBox extends React.Component<NotificationSettingBoxViewModel, NotificationSettingBoxState>{

	constructor(props: NotificationSettingBoxViewModel) {
		super(props)
		this.state = {
			value: props.item.value,
			preValue: props.item.value,
			cannotEdit: true
		}
	}

	componentDidMount() {
		this.props.operator?.(
			{
				setValue:this.onValueChange,
				reset: this.reset
			}
		)
	}

	onValueChange = (v: string) => {
		this.setState({
			value: v,
		})
	}

	saveChannelConfiguration = () => {
		this.props.changeConfiguration(this.props.item.key, this.state.value)
		this.setState({
			cannotEdit: true
		})
	}

	reset = () => {
		this.props.resetConfiguration(this.props.item.key);
	}

	render(): React.ReactNode {
		return (<div className="setting-box-container">
				<div className="title">
					{this.props.item.key}
				</div>
				<div className="box">
					<Input
						disabled={this.state.cannotEdit}
						value={this.state.value}
						onChange={(t) => this.onValueChange(t.target.value)}
					/>

					<Button  className="button"
							onClick={() => {message.error(this.props.t("notification-setting.channel-configuration.not-authorized-to-view")).then(r => {})}}>
						{this.props.t("notification-setting.channel-configuration.view")}
					</Button>
					<Button type="primary" className="button"
							onClick={() => this.setState({cannotEdit: !this.state.cannotEdit})}>
						{this.state.cannotEdit ? this.props.t("notification-setting.channel-configuration.update") : this.props.t("notification-setting.channel-configuration.cancel")}
					</Button>
					<Popconfirm
						title={this.props.t("notification-setting.channel-configuration.confirm-save-configuration")}
						onConfirm={this.saveChannelConfiguration}
						disabled={this.state.cannotEdit}
					>
						<Button type="primary"
								danger
								className="button"
								disabled={this.state.cannotEdit}
						>
							{this.props.t("notification-setting.channel-configuration.save")}
						</Button>
					</Popconfirm>
					<Popconfirm
						title={this.props.t("notification-setting.channel-configuration.confirm-reset-configuration")}
						onConfirm={this.reset}
					>
						<Button danger
								className="button"
						>
							{this.props.t("notification-setting.channel-configuration.reset")}
						</Button>
					</Popconfirm>
				</div>
				<div className="key">
					{this.props.item.key}
				</div>
			</div>
		);
	}
}

export default withTranslation()(NotificationSettingBox);