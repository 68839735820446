import { Form, Input, Button, FormInstance } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserModel } from "../@mod";
import './index.less'



interface UserEditorModel extends WithTranslation {
	user: UserModel,
	save: (user:UserModel) => void
	cancel: ()=>void
}

interface UserEditorState {
	userOnEdit: UserModel

}


const tailLayout = {
	wrapperCol: { offset: 8, span: 16 },
};

class UserEditor extends React.Component<UserEditorModel, UserEditorState>{


	formRef: React.RefObject<FormInstance<UserModel>>;
	constructor(props: UserEditorModel) {
		super(props)
		this.formRef = React.createRef();
		this.state = {
			userOnEdit: this.props.user
		}
	}

	componentDidMount() {
		this.formRef?.current!.setFieldsValue(
			this.props.user
		)
	}

	componentDidUpdate() {
		
	}

	getSnapshotBeforeUpdate(prevProps: Readonly<UserEditorModel>, prevState: Readonly<UserEditorState>) {

		if (prevProps.user !== this.props.user) {
			this.formRef?.current!.setFieldsValue(
				this.props.user
			)
			this.setState({
				userOnEdit: this.props.user
			})
			this.forceUpdate()
		}
		return null;
	}

	onFinished = (values: UserModel) => {
		console.log("Finished",values);
		values.key = this.props.user.key;
		values.source = "0";
		this.props.save(values)

	};

	onCancle = () => {
		this.props.cancel();
	}


	render(): React.ReactNode {
		return (<div>
			<Form layout="horizontal"
				labelCol={{ span: 3 }}
				wrapperCol={{ span: 16 }}
				onFinish={this.onFinished}
				name="control-ref"
				ref={this.formRef}
			>
				<Form.Item label={this.props.t("user-editor.name")} name="name" >
					<Input></Input>
				</Form.Item>
				<Form.Item label={this.props.t("user-editor.email")} name="email" >
					<Input ></Input>
				</Form.Item>
				<Form.Item label={this.props.t("user-editor.phone")} name="phone" >
					<Input ></Input>
				</Form.Item>
				<Form.Item label={this.props.t("user-editor.organization")} name="organization" >
					<Input ></Input>
				</Form.Item>
				<Form.Item {...tailLayout} className="editor-button-bar" >
					<Button type="primary" htmlType="submit" className="button" >
						{this.props.t("user-editor.save")}
					</Button>
					<Button type="primary" className="button" onClick={this.onCancle}>
						{this.props.t("user-editor.cancle")}
					</Button>
				</Form.Item>
			</Form>
		</div>)
	}

}


export default withTranslation()(UserEditor)