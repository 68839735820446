import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { SERVICE_STATUS, TaskModel, TASK_TYPES } from "../../../@mod";
import { Button, Card, message, PageHeader, Popconfirm, Popover, Select, Tabs } from "antd";
import { SafetyCertificateOutlined, InboxOutlined, SettingOutlined, StepForwardOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import ApiServer from "../../../../../ApiServer";
import "./index.less"
import { RcFile } from "antd/lib/upload";
import URI from "urijs";
import { Axios } from "axios";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { deliverableColumnProvider } from "./@mod";
import { UserDto } from "../../../../../api";
import moment from "moment";
import { RouteContext, withRouter } from "../../../../../utils";
import { convertProjectServiceStatusChangeLogDtoToModel, StatusLogInfoModel } from "../../status-log-line/@mod";
import StatusLogLine from "../../status-log-line";

interface TaskDetailViewModel extends WithTranslation {
    apiServer: ApiServer
    taskToShow?: TaskModel
    afterSubmitTask: (model?: TaskModel) => void,
    navigateServicePage?: boolean,
    router?: RouteContext
}

interface TaskDetailViewState {
    taskToShow?: TaskModel
    isCannotOperate: boolean
    isCannotSubmitTask: boolean
    deliverableList: any[]
    knowledgeList: any[]
    loadingDeliverableList: boolean
    loadingKnowledgeList: boolean
    loginUser?: UserDto
    userList?: UserDto[]
    selectedExecutor?: any
    showUpdateExecutor: boolean,
    statusLogOfService: StatusLogInfoModel[],
    startFollowupTaskBtnLoading: boolean
}

class TaskDetailView extends React.Component<TaskDetailViewModel, TaskDetailViewState> {
    constructor(props: TaskDetailViewModel) {
        super(props);
        let statusCode = Number(this.props.taskToShow?.status["code"])
        this.state = {
            isCannotOperate: statusCode !== SERVICE_STATUS.PROCESSING && statusCode !== SERVICE_STATUS.REDOING,
            isCannotSubmitTask: true,
            deliverableList: [],
            knowledgeList: [],
            loadingDeliverableList: false,
            loadingKnowledgeList: false,
            taskToShow: props.taskToShow,
            showUpdateExecutor: false,
            statusLogOfService: [],
            startFollowupTaskBtnLoading: false
        }
    }

    calCannotOperate = () => {
        let statusCode = Number(this.state.taskToShow?.status["code"])
        let isCannotOperate = statusCode === SERVICE_STATUS.PENDING_ACCEPTANCE
            || statusCode === SERVICE_STATUS.FINISHED
            || this.state.loginUser?.id !== this.state.taskToShow?.executor.id
        this.setState({
            isCannotOperate: isCannotOperate
        })
    }

    componentDidMount() {
        this.loadDeliverable()
        this.loginUser()
        this.loadUserList()
    }

    loadUserList = () => {
        this.props.apiServer.apiUser.getUserList().then(r => {
            this.setState({
                userList: r.data.data?.list
            })
        })
    }

    loginUser = () => {
        this.props.apiServer.apiSystem.getLoginUser().then(r => {
            if (r.data.data?.user) {
                this.setState({
                    loginUser: r.data.data.user,
                    isCannotOperate: this.state.isCannotOperate || r.data.data.user.id !== this.state.taskToShow?.executor.id
                })
            }
        })
    }

    loadDeliverable = () => {
        if (this.props.taskToShow) {
            this.setState({
                loadingDeliverableList: true
            })
            this.props.apiServer.apiProjectTask.getDeliveryMaterial(this.props.taskToShow?.id, this.props.taskToShow.redoCount).then(r => {
                if (r.data.data?.dtos) {
                    let needUpload = TASK_TYPES[this.props.taskToShow?.type.code ?? ""]?.needUpload
                    this.setState({
                        deliverableList: r.data.data.dtos,
                        isCannotSubmitTask: needUpload && r.data.data.dtos.length === 0
                    })
                }
            }).finally(() => {
                this.setState({
                    loadingDeliverableList: false
                })
            })
        }
    }

    loadKnowledge = () => {
        if (this.state.taskToShow?.itemId) {
            this.setState({
                loadingKnowledgeList: true
            })
            this.props.apiServer.apiProjectMeta.getItemKnowledge(this.state.taskToShow?.itemId).then(r => {
                if (r.data.data?.knowledgeList) {
                    this.setState({
                        knowledgeList: r.data.data?.knowledgeList
                    })
                }
            }).finally(() => {
                this.setState({
                    loadingKnowledgeList: false
                })
            })
        }
    }

    onTaskExecutorChange = (op) => {
        this.setState({
            selectedExecutor: {
                id: op.value,
                name: op.children
            }
        })
    }

    updateTaskExecutor = () => {
        if (this.state.taskToShow) {
            this.props.apiServer.apiProjectTask.postChangeExecutor(this.state.taskToShow?.id, {
                newExecutor: this.state.selectedExecutor.id
            }).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("project.task.update-executor-success")).then()
                    if (this.state.taskToShow) {
                        let task = this.state.taskToShow
                        task.executor.id = this.state.selectedExecutor.id
                        task.executor.fullName = this.state.selectedExecutor.name
                        this.calCannotOperate()
                    }
                } else {
                    message.error(this.props.t("project.task.update-executor-fail")).then()
                }
            })
        }
        this.closeUpdateTaskExecutorView()
    }

    closeUpdateTaskExecutorView = () => {
        this.setState({
            showUpdateExecutor: false
        })
    }

    startFollowUpTask = () => {
        if (this.props.taskToShow) {
            this.setState({
                startFollowupTaskBtnLoading: true
            })
            this.props.apiServer.apiProjectTask.postStartFollowupItem(this.props.taskToShow.id).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("project.task.start-follow-up-task-success"))
                    this.props.afterSubmitTask()
                    return
                }
                message.error(this.props.t("project.task.start-follow-up-task-failed"))
            }).finally(() => {
                this.setState({
                    startFollowupTaskBtnLoading: false
                })
            })
        }
    }

    genEditTaskExecutor = () => {
        return (
            <>
                <div>
                    <Select
                        style={{ width: "240px" }}
                        onChange={(v, a) => {
                            this.onTaskExecutorChange(a)
                        }}
                        showSearch
                        filterOption={(input, option) => String(option?.children).toLowerCase().includes(input.toLowerCase())}
                    >
                        {
                            this.state.userList?.map(u => <Select.Option key={u.id}
                                value={u.id}>{u.fullName}</Select.Option>)
                        }
                    </Select>
                    <Button type={"primary"} onClick={this.updateTaskExecutor}>{this.props.t("project.task.confirm")}</Button>
                    <Button onClick={this.closeUpdateTaskExecutorView}>{this.props.t("project.task.cancel")}</Button>
                </div>
            </>
        )
    }


    genBaseInfoExtra = (): React.ReactNode => {
        let btn: React.ReactNode = <></>
        if (this.props.taskToShow) {
            let task = this.props.taskToShow
            if (task.status.code === SERVICE_STATUS.PENDING_ACCEPTANCE) {
                btn = (
                    <>
                        <Button
                            type="primary"
                            onClick={this.startFollowUpTask}
                            loading={this.state.startFollowupTaskBtnLoading}
                            icon={<StepForwardOutlined />}
                        >{this.props.t("project.task.start-follow-up-task")}</Button>
                    </>
                )
            } else {
                btn = (
                    <div className={"operation-bar"}>
                        <Popover trigger={"click"} content={this.genEditTaskExecutor()} visible={this.state.showUpdateExecutor}>
                            <Button
                                disabled={this.state.isCannotOperate}
                                className={"left-btn"}
                                icon={<SettingOutlined />}
                                onClick={() => this.setState({ showUpdateExecutor: true })}
                            >{this.props.t("project.task.change-executor")}</Button>
                        </Popover>
                        <Popconfirm
                            title={this.props.t("project.task.confirm-submit-task")}
                            onConfirm={this.submitTask}
                            disabled={this.state.isCannotOperate || this.state.isCannotSubmitTask}
                        >
                            <Button
                                disabled={this.state.isCannotOperate || this.state.isCannotSubmitTask}
                                type={"primary"}
                                icon={<SafetyCertificateOutlined />}
                            >{this.props.t("project.task.submit-task")}</Button>
                        </Popconfirm>
                    </div>
                )
            }


        }
        return btn
    }

    submitTask = () => {
        if (this.props.taskToShow?.id) {
            let requestData = {
                newStatus: SERVICE_STATUS.PENDING_ACCEPTANCE
            }
            this.props.apiServer.apiProjectTask.postChangeServiceItemStatus(this.props.taskToShow?.id, requestData).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("project.task.submit-success")).then()
                    this.props.afterSubmitTask(this.props.taskToShow)
                } else {
                    message.error(this.props.t("project.task.submit-fail")).then()
                }
            })
        }
    }

    createSpace = (rc: RcFile) => {
        let name = rc.name;
        return this.props.apiServer.apiAttachment.putNewUploadRequest(
            {
                fileName: name
            }
        ).then(x => {
            if (x.data.data?.attachmentDto?.uploadUrl) {
                return x.data.data?.attachmentDto?.uploadUrl + "&id=" + x.data.data.attachmentDto.id
            }
            return "";
        })
    };

    customerRequest = (option: any) => {
        if (this.props.taskToShow) {
            let uri = new URI(option.action);
            let a = new Axios({
                baseURL: uri.scheme() + "://" + uri.host(),
                onUploadProgress: (a) => {
                    option.onProgress({ percent: a.loaded / a.total });
                }
            })
            let fileId = uri.query(true)["id"]
            uri.removeQuery("id");
            a.put(uri.toString(), option.file)
                .then(async (x) => {
                    option.onSuccess(x.data, x.request)
                    await this.props.apiServer.apiProjectTask.postCommitDeliveryMaterial(this.props.taskToShow?.id ?? -1, {
                        attachmentId: fileId
                    });
                    await this.props.apiServer.apiAttachment.postCompleteUpload(fileId);
                    this.componentDidMount();
                })
        }
    }

    onDeliverableAction = (model, actionType) => {
        if (actionType === "delete" && this.props.taskToShow) {
            if (this.props.taskToShow) {
                const taskStatus = this.props.taskToShow.status.code
                if (taskStatus === SERVICE_STATUS.PROCESSING || taskStatus === SERVICE_STATUS.REDOING) {
                    this.props.apiServer.apiProjectTask.removeDeliveryMaterial(this.props.taskToShow.id, {
                        attachmentId: model.id
                    }).then(r => {
                        if (r.data.data?.success) {
                            message.success(this.props.t("project.task.delete-success")).then()
                            this.componentDidMount()
                        } else {
                            message.error(this.props.t("project.task.delete-fail")).then()
                        }

                    })
                    return
                }
            }
            message.error(this.props.t("project.task.cannot-delete-submited"))
        } else if (actionType === "download") {
            this.props.apiServer.apiAttachment.getAttachment(model.id)
                .then(x => {
                    if (x.data.data?.succeed) {
                        var element = document.createElement('a');
                        element.target = "_blank";
                        element.href = x.data.data.attachmentInfo?.downloadUrl ?? ""
                        document.body.appendChild(element)
                        element.click()
                        URL.revokeObjectURL(element.href);
                        document.body.removeChild(element);
                    }
                })
        }
    }

    loadStatusChangeLog = () => {
        if (this.props.taskToShow?.id) {
            this.props.apiServer.apiProjectTask.getStatusChangeLog(this.props.taskToShow?.id).then(r => {
                if (r.data.data?.dtos) {
                    this.setState({
                        statusLogOfService: r.data.data.dtos
                            .sort((x1, x2) => { return Number(x2.operateTime) - Number(x1.operateTime) })
                            .map(x => {
                                return convertProjectServiceStatusChangeLogDtoToModel(x)
                            })
                    })
                }
            })
        }
    }

    onTabChange = (activeKey) => {
        switch (activeKey) {
            case "1":
                this.loadStatusChangeLog()
                break
            case "2":
                this.loadKnowledge()
                break
        }
    }

    navigateToService = () => {
        this.props.router?.navigate("/service/launched", { state: { id: this.props.taskToShow?.serviceInstance.id } })
    }

    render() {
        let status = ""
        if (this.props.taskToShow?.status.desc) {
            status = this.props.taskToShow?.status.desc[this.props.i18n.language]
        }

        let navigateServiceBtn = this.props.navigateServicePage ? (
            <Button
                type="link"
                onClick={this.navigateToService}
            >{this.props.taskToShow?.serviceInstance?.title}</Button>
        ) : (this.props.taskToShow?.serviceInstance?.title)

        return (
            <div className="task-details-container">
                <PageHeader title={this.props.t("project.task.task-detail-title")} subTitle={this.props.t("project.task.task-detail-sub-title")} extra={this.genBaseInfoExtra()}>
                    <Card title={this.props.t("project.task.base-info")}>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>
                                <p>{this.props.t("project.task.fields.title")}: {this.props.taskToShow?.title}</p>
                                <p>{this.props.t("project.task.fields.type")}: {this.props.taskToShow?.type["descZh"]}</p>
                                <p>{this.props.t("project.task.fields.status")}: {status}</p>
                                <p>{this.props.t("project.task.fields.start-time")}:
                                    {moment.parseZone(this.props.taskToShow?.startTime).format("YYYY-MM-DD HH:mm:ss")}
                                </p>
                                <p>{this.props.t("project.task.fields.lower-end-time")}:
                                    {moment.parseZone(this.props.taskToShow?.estimatedLowerEndTime).format("YYYY-MM-DD HH:mm:ss")}
                                </p>
                                <p>{this.props.t("project.task.fields.end-time")}:
                                    {this.props.taskToShow?.endTime
                                        ? moment.parseZone(this.props.taskToShow?.endTime).format("YYYY-MM-DD HH:mm:ss")
                                        : this.props.t("project.task.not-end")
                                    }
                                </p>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>{this.props.t("project.task.fields.service")}:
                                    {navigateServiceBtn}
                                </p>
                                <p>{this.props.t("project.task.fields.description")}: {this.props.taskToShow?.description}</p>
                                <p>{this.props.t("project.task.fields.redo-count")}: {this.props.taskToShow?.redoCount}</p>
                                <p>{this.props.t("project.task.fields.executor")}: {this.props.taskToShow?.executor.fullName}</p>
                                <p>{this.props.t("project.task.fields.higher-end-time")}:
                                    {moment.parseZone(this.props.taskToShow?.estimatedHigherEndTime).format("YYYY-MM-DD HH:mm:ss")}
                                </p>
                            </div>
                        </div>
                    </Card>

                    <Card className="task-details">
                        <Tabs onChange={this.onTabChange}>
                            <Tabs.TabPane tab={this.props.t("project.task.deliverable")} key={"0"}>
                                <Card>
                                    <Dragger
                                        name='file'
                                        multiple={true}
                                        disabled={this.state.isCannotOperate}
                                        action={this.createSpace}
                                        customRequest={this.customerRequest}
                                    >
                                        <div className="drop-box">
                                            <span >
                                                <InboxOutlined className="upload-drag-icon" />
                                            </span>
                                            <div className="upload-text">
                                                {this.props.t("upload-text")}
                                            </div>
                                            <div className="ant-upload-hint">
                                                {this.props.t("upload-hit")}
                                            </div>
                                        </div>
                                    </Dragger>

                                    <div style={{ marginTop: '8px' }} />
                                    <WorkBenchTable
                                        disableCheckBox={true}
                                        data={this.state.deliverableList}
                                        dataLoading={this.state.loadingDeliverableList}
                                        columnProvider={() => deliverableColumnProvider(this.props, this.onDeliverableAction)}
                                    />
                                </Card>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={this.props.t("project.task.statusChangeLog")} key={"1"}>
                                <div className="status-log-container">
                                    {
                                        this.state.statusLogOfService.length > 0
                                            ? <StatusLogLine statusLogList={this.state.statusLogOfService} />
                                            : this.props.t("project.service.status-log-empty")
                                    }
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={this.props.t("project.task.knowledge")} key={"2"}>
                                <div className="status-log-container">
                                    <WorkBenchTable
                                        disableCheckBox={true}
                                        data={this.state.knowledgeList}
                                        dataLoading={this.state.loadingKnowledgeList}
                                        columnProvider={(a) => deliverableColumnProvider(this.props, this.onDeliverableAction, false)}
                                    />
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </Card>
                </PageHeader>
            </div>
        )
    }
}

export default withRouter(withTranslation()(TaskDetailView))