import React from "react";
import { Button, Tag } from "antd";
import { ColumnType } from "antd/lib/table";
import { ActionEventHandler } from "../../../../../components";
import ColumnTextFilter from "../../../../../components/table/column-text-filter";
import { MetaModel } from "../../../@mod";



export const columnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<MetaModel>): ColumnType<MetaModel>[] => {
	
	return [
		{
			title: translater("ticket-metas.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string,record:MetaModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:MetaModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("privilege-editor.name")}/>)
			},
		},
		{
			title: translater("ticket-metas.extern"),
			dataIndex: 'description',
			key: 'description',
			render:(text:string,record:MetaModel) =>{
				var item:any[] =[];
				for(var a in record.description){
					item.push(
						<div>
							<Tag color={"blue"}>{translater("translate."+a)}</Tag>
							<span>	{record.description[a]}</span>
						</div>
					)
				}
				return item;

			}
		}
	];
}