import { Input, Select } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { EscalationRuleModel } from "../@mod";
import './index.less'

export interface TimeAxisSelectorViewModel extends WithTranslation {
    rule:EscalationRuleModel,
    onChanged ?:()=>void

}

interface TimeAxisSelectorState {

}

class TimeAxisSelector extends React.Component<TimeAxisSelectorViewModel, TimeAxisSelectorState>{

    constructor(props: TimeAxisSelectorViewModel) {
        super(props)
    }

    renderAxis = () => {
        return (
            [
                <Select.Option value="latestUpdate" key={"latestUpdate"}>
                    {this.props.t("tickets.lastestUpdate")}
                </Select.Option>,
                <Select.Option value="created"  key={"created"}>
                    {this.props.t("tickets.created")}
                </Select.Option>,
                <Select.Option value="sla"  key={"sla"}>
                    {this.props.t("tickets.sla-time")}
                </Select.Option>
            ]
        )
    }

    renderTimeDirection = () => {
        return (
            [
                <Select.Option value="before" key={"before"}>
                    {this.props.t("tickets.before")}
                </Select.Option>,
                <Select.Option value="after" key={"after"}>
                    {this.props.t("tickets.after")}
                </Select.Option>,
            ]
        )
    }


    render(): React.ReactNode {
        return (
            <div className="ticket-axis-selector">

                <div className="title"><h3>
                    {this.props.t("tickets.time-schedule")}</h3>
                </div>

                    <div className="axis-editor">
                        <div className="field">
                            <div>
                                {this.props.t("tickets.axis")}</div>
                            <Select style={{ width: '100%' }}
                                placeholder={this.props.t("tickets.axis")}
                                defaultValue={this.props.rule.timeAxis}
                                onChange={(v)=>{this.props.rule.timeAxis=v;this.props.onChanged?.();}}
                            >
                                {this.renderAxis()}
                            </Select>
                        </div>
                        <div  className="field">
                            <div>
                                {this.props.t("tickets.time-limited")}</div>
                            <Input type="number" defaultValue={this.props.rule.timeConstraint}  placeholder={this.props.t("tickets.time-limited")} 
                                                            onChange={(v)=>{this.props.rule.timeConstraint=Number(v.target.value);this.props.onChanged?.();}}
                            />
                        </div>
                        <div className="field">
                            <div >
                                {this.props.t("tickets.time-direction")}</div>
                            <Select style={{ width: '100%' }} 
                                placeholder={this.props.t("tickets.time-direction")}
                                defaultValue={this.props.rule.timeDirection}
                                onChange={(v)=>{ this.props.rule.timeDirection=v;this.props.onChanged?.();}}
                                >
                                {this.renderTimeDirection()}
                            </Select>
                        </div>

                    </div>
            </div>)
    }
}


export default withTranslation()(TimeAxisSelector);