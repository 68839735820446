import { Input, Radio } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { EscalationRuleModel } from "../@mod";
import './index.less'


export interface FrequencySelectorViewModel extends WithTranslation {
    rule:EscalationRuleModel
    onChanged ?:()=>void
}

interface FrequencySelectorState {

    
}


class FrequencySelector extends React.Component<FrequencySelectorViewModel, FrequencySelectorState>{

    constructor(props: FrequencySelectorViewModel) {
        super(props)
    }

    frequencyOpthionChanged = (v) => {

        this.setState({
            frequencyOption: v.target.value
        })
        this.props.rule.frequency.type = v.target.value;
        this.props.onChanged?.();

    }

    

    render(): React.ReactNode {
        return (
            <div className="frequency-selector">
                <div className="title">
                    <h3>{this.props.t("tickets.trigger-frequency")}</h3>
                </div>
                <div className="frequency-editor">
                    <div className="field">
                        <div>{this.props.t("tickets.frequency-option")}</div>
                        <Radio.Group buttonStyle="solid" value={this.props.rule.frequency.type}
                            onChange={this.frequencyOpthionChanged}
                            >
                            <Radio.Button value="oneTime">{this.props.t("tickets.one-time")}</Radio.Button>
                            <Radio.Button value="fixRate">{this.props.t("tickets.fix-rate")}</Radio.Button>
                            <Radio.Button value="fixTime">{this.props.t("tickets.fix-time")}</Radio.Button>
                        </Radio.Group>
                    </div>
                    {
                        this.props.rule.frequency.type === "fixRate" &&
                        <div className="field">
                            <div>{this.props.t("tickets.fix-rate-title")}</div>
                            <div className="flat-item">
                                <Input type={"number"} value={this.props.rule.frequency.fixRate} 

                                    onChange={(x)=>{ this.props.rule.frequency.fixRate=Number(x.target.value);this.props.onChanged?.();}}
                                />
                            </div>
                        </div>
                    }
                    {
                        this.props.rule.frequency.type === "fixTime" &&
                        <div className="field">
                            <div>{this.props.t("tickets.fix-time-title")}</div>
                            <div className="flat-item">
                                <Input type={"text"} defaultValue={this.props.rule.frequency.fixTime} 
                                    onChange={(x)=> {this.props.rule.frequency.fixTime=x.target.value;this.props.onChanged?.();}}
                                />
                            </div>
                        </div>
                    }


                </div>
            </div>
        )
    }
}

export default withTranslation()(FrequencySelector);