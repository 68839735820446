import { red, volcano, orange, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from "@ant-design/colors";
import { ProjectServiceItemInstanceStatusDto } from "../../../../../api";

const index = "primary";

export interface GanttValueDataModel {
    name: string,
    start: string,
    end: string,
    fastestEndDate: string,
    status: ProjectServiceItemInstanceStatusDto,
    type: number
}


export const SERIAL_COLOR = [
    blue[index],
    gold[index],
    lime[index],
    yellow[index],
    green[index],
    orange[index],
    volcano[index],
    red[index],
    cyan[index],
    geekblue[index],
    purple[index],
    grey[index],
    magenta[index]
];