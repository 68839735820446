import { Button, Descriptions, PageHeader, Tabs } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { withRoute, WithRouteContext} from "../../../../utils";
import {
	UserAddOutlined,
	DeleteOutlined,
	MinusOutlined,
	PlusOutlined
} from '@ant-design/icons'
import WorkbenchTable from "../../../../components/table/workbench-table";
import { UserModel, userGroupColumnsProvider, policyColumnsProvider } from "./@mod";
import { ActionEventHandler } from "../../../../components";

import './index.less'
import { PolicyModel } from "../../sercurity/policy/@mod";
import { PolicyDto, UserDto } from "../../../../api";
import moment from "moment";

interface UserGroupEditorModel extends WithTranslation, WithRouteContext {
	apiServer: ApiServer
}

interface UserGroupEditorState {
	id: number,
	userGroupModel?: any,
	dataLoading: boolean,
	users: UserModel[],
	usersOther: UserModel[],
	usersToRemove: UserModel[],
	usersToAdd: UserModel[],
	policiesToDisattach: PolicyModel[],
	policiesToAttach: PolicyModel[],
	policies: PolicyModel[],
	policiesNotIn: PolicyModel[]
}


class UserGroupEditor extends React.Component<UserGroupEditorModel, UserGroupEditorState>{

	constructor(props: UserGroupEditorModel) {
		super(props)
		this.state = {
			id: 0,
			userGroupModel: {
				subject: "loading",
				subtitle: "loading",
				lastUpdate: "loading",
				model: {

				},

			},
			users: [],
			usersOther: [],
			dataLoading: true,
			usersToRemove: [],
			usersToAdd: [],
			policies: [],
			policiesToDisattach: [],
			policiesToAttach:[],
			policiesNotIn:[]
		}
	}

	l: Promise<void> | undefined;
	componentDidMount() {
		this.setState(
			{
				id: Number(this.props.router?.params["id"])
			}
		)
		if (!this.l) {
			console.log("load")
			this.l = this.loadData().finally(() => {
				this.l = undefined
				this.setState(
					{
						dataLoading: false
					}
				)
			});
		}

	}

	componentDidUpdate() {

	}

	async loadData() {
		this.props.apiServer.apiGroup.getUserGroup(
			Number(this.props.router?.params["id"]),
			true,
			true,
			true,
			false
		).then(
			x => {
				let userGroup = x.data.data?.userGroup;
				let users = x.data.data?.users;
				let usersOther = x.data.data?.usersNoIn;
				let policies = x.data.data?.policies;
				let policiesNotIn= x.data.data?.policiesNotIn;
				this.setState(
					{
						dataLoading: false,
						userGroupModel: {
							subject: userGroup?.name,
							subtitle: userGroup?.description,
							model: {
								id: userGroup?.id,
								name: userGroup?.name,
								description: userGroup?.description,
								createdTime: userGroup?.createdTime,
								updatedTime: userGroup?.updatedTime,
								userCount: users?.length,
							}
						},
						users: users?.map((u:UserDto) => {
							let user: UserModel = {
								key: (u.id ?? 0).toString(),
								name: u.name ?? "",
								fullName: u.fullName ?? "",
								source: (u.source ?? 0).toString(),
								organization: u.organization ?? "",
								phone: u.phone ?? "",
								email: u.email ?? "",
								active: u.active ?? false,
								created: u.createdTime ?? "",
								updated: u.updatedTime ?? ""
							}
							return user;
						}) ?? [],
						usersOther: usersOther?.map(u => {
							let user: UserModel = {
								key: (u.id ?? 0).toString(),
								name: u.name ?? "",
								fullName: u.fullName ?? "",
								source: (u.source ?? 0).toString(),
								organization: u.organization ?? "",
								phone: u.phone ?? "",
								email: u.email ?? "",
								active: u.active ?? false,
								created: u.createdTime ?? "",
								updated: u.updatedTime ?? ""
							};
							return user;
						}) ?? [],
						policies: policies?.map((y:PolicyDto)=> {
							let p: PolicyModel = {
								key: (y.id ?? 0).toString(),
								name: y.name ?? "",
								description: y.description ?? "",
								createdTime: y.createdTime ?? "",
								updatedTime: y.updatedTime ?? "",
								allowed: y.allowed ?? false,
								id: y.id ?? 0
							};
							return p
						}) ?? [],
						policiesNotIn:policiesNotIn?.map((y:PolicyDto) => {
							let p: PolicyModel = {
								key: (y.id ?? 0).toString(),
								name: y.name ?? "",
								description: y.description ?? "",
								createdTime: y.createdTime ?? "",
								updatedTime: y.updatedTime ?? "",
								allowed: y.allowed ?? false,
								id: y.id ?? 0
							};
							return p
						}) ?? []
					})
			}
		)
	}

	getSnapshotBeforeUpdate(before: Readonly<UserGroupEditorModel>, beforeState: Readonly<UserGroupEditorState>) {
		console.log("update")
		return null;
	}

	getTags = () => {
		return (
			<></>
		)
	}

	onUserRemoveSelected = (users: UserModel[]) => {
		console.log(users);
		this.setState(
			{
				usersToRemove: users
			}
		);
	}

	onUserAddSelected = (users: UserModel[]) => {
		console.log(users);
		this.setState(
			{
				usersToAdd: users
			}
		);
	}
	onPolicyToDisattach = (policies: PolicyModel[]) => {
		this.setState(
			{
				policiesToDisattach: policies
			}
		)
	}
	onPolicyToAttach =  (policies: PolicyModel[]) => {
		this.setState(
			{
				policiesToAttach: policies
			}
		)
	}

	getFooter = () => {
		return (<>
			<Tabs defaultActiveKey="1" >
				<Tabs.TabPane tab={this.props.t("user-group-editor.user-in-group")} key="1" animated={true} >
					<div className="editor-bar">
						<div className="operation-bar">
							<Button disabled={this.state.usersToRemove.length <= 0}
								icon={<DeleteOutlined />}
								onClick={this.deleteUserFromUserGroup}
							>{this.props.t("user-group-editor.user-remove")}</Button>
						</div>
						<WorkbenchTable<UserModel>
							data={this.state.users}
							dataLoading={this.state.dataLoading}
							onSelected={this.onUserRemoveSelected}
							columnProvider={
								(a: ActionEventHandler<UserModel>) => userGroupColumnsProvider(this.props.t, a)
							} />
					</div>
				</Tabs.TabPane>

				<Tabs.TabPane tab={this.props.t("user-group-editor.user-not-in-group")} key="2" animated={true} >
					<div className="editor-bar">
						<div className="operation-bar">
							<Button disabled={this.state.usersToAdd.length <= 0}
								type="primary" icon={<UserAddOutlined />}
								onClick={this.addUserToUserGroup}>{this.props.t("user-group-editor.user-add")}</Button>
						</div>
						<WorkbenchTable<UserModel>
							data={this.state.usersOther}
							dataLoading={this.state.dataLoading}
							onSelected={this.onUserAddSelected}
							columnProvider={
								(a: ActionEventHandler<UserModel>) => userGroupColumnsProvider(this.props.t, a)
							} />
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab={this.props.t("user-group-editor.policy")} key="3" animated={true} >
					<div className="editor-bar">
						<div className="operation-bar">
							<Button disabled={this.state.policiesToDisattach.length <= 0}
								type="primary" icon={<MinusOutlined />}
								>{this.props.t("user-group-editor.unattach")}</Button>
						</div>
						<WorkbenchTable<PolicyModel>
							data={this.state.policies}
							dataLoading={this.state.dataLoading}
							onSelected={this.onPolicyToDisattach}
							columnProvider={
								(a: ActionEventHandler<PolicyModel>) => policyColumnsProvider(this.props.t, a)
							} />
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab={this.props.t("user-group-editor.not-attached-policy")} key="4" animated={true} >
					<div className="editor-bar">
						<div className="operation-bar">
							<Button disabled={this.state.policiesToDisattach.length <= 0}
								type="primary" icon={<PlusOutlined />}
								>{this.props.t("user-group-editor.attach")}</Button>
						</div>
						<WorkbenchTable<PolicyModel>
							data={this.state.policiesNotIn}
							dataLoading={this.state.dataLoading}
							onSelected={this.onPolicyToDisattach}
							columnProvider={
								(a: ActionEventHandler<PolicyModel>) => policyColumnsProvider(this.props.t, a)
							} />
					</div>
				</Tabs.TabPane>
			</Tabs>
		</>)
	}

	getContent = () => {
		return (
			<div>
				<Descriptions size="small" column={3} >
					<Descriptions.Item label={this.props.t("user-group-editor.name")} key="1" >
						{this.state.userGroupModel?.model.name}
					</Descriptions.Item>
					<Descriptions.Item label={this.props.t("user-group-editor.createdTime")} key="2" >
						{moment.parseZone(this.state.userGroupModel?.model.createdTime).format(this.props.t("time-pattern-long"))}
					</Descriptions.Item>
					<Descriptions.Item label={this.props.t("user-group-editor.updatedTime")} key="3" >
						{moment.parseZone(this.state.userGroupModel?.model.updatedTime).format(this.props.t("time-pattern-long"))}
					</Descriptions.Item>
					<Descriptions.Item label={this.props.t("user-group-editor.user-count")} key="4" >
						{this.state.userGroupModel?.model.userCount}
					</Descriptions.Item>
				</Descriptions>
			</div>

		)
	}
	addUserToUserGroup = () => {
		this.setState({
			dataLoading: true
		})
		this.props.apiServer.apiGroup.putUserToUserGroup(this.state.id, {
			userToAdd: this.state.usersToAdd.map(x => Number(x.key))
		}).then(
			x => {
				console.log("updated need to add notification");
				this.loadData()
				this.setState(
					{
						usersToAdd: []
					}
				)
			}
		);
	}

	deleteUserFromUserGroup = () => {
		console.log("trigger delete")
		this.setState({
			dataLoading: true
		})
		this.props.apiServer.apiGroup.deleteUserFromUserGroup(this.state.id, {
			userToDelete: this.state.usersToRemove.map(x => Number(x.key))
		}).then(
			x => {
				console.log("updated need to add notification");
				this.loadData()
				this.setState(
					{
						usersToRemove: []
					}
				)
			}
		);
	}

	render(): React.ReactNode {
		return (<div>
			<div className="site-page-header-ghost-wrapper">
				<PageHeader
					ghost={false}
					onBack={() => window.history.back()}
					title={this.state.userGroupModel.subject}
					subTitle={this.state.userGroupModel.subtitle}
					extra={[
						<Button loading={this.state.dataLoading} key="4" danger icon={<DeleteOutlined />} >{this.props.t("user-group-editor.delete")}</Button>
					]}
					tags={this.getTags()}
					footer={this.getFooter()}
				>
					{this.getContent()}
				</PageHeader>
			</div>
		</div>

		);
	}


}


export default withRoute(withTranslation()(UserGroupEditor))

