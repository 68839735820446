import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import React from "react";
import { ActionEventHandler } from "../../../../../components";
import ColumnTextFilter from "../../../../../components/table/column-text-filter";
import { PolicyModel } from "../../../sercurity/policy/@mod";
import UserActiveTag from "../../users/@mod/active-tag";
import PolicyActiveTag from "../../../sercurity/policy/@mod/active-tag"
import moment from "moment";


export interface UserModel {
	key:string,
	name: string,
	source: string
	organization: string,
	phone: string,
	email: string
	active: boolean,
	created: string,
	updated: string,
	fullName: string
}


export const policyColumnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<PolicyModel>): ColumnType<PolicyModel>[] => {
	
	return [
		{
			title: translater("policy-editor.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string,record:PolicyModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:PolicyModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("policy-editor.name")}/>)
			},
		},

		{
			title: translater("policy-editor.description"),
			dataIndex: 'description',
			key: 'description',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:PolicyModel) => record.description.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("policy-editor.description")}/>)
			},
		},{
			title: translater("policy-editor.createdTime"),
			dataIndex: 'createdTime',
			key: 'createdTime'
		},{
			title: translater("policy-editor.updatedTime"),
			dataIndex: 'updatedTime',
			key: 'updatedTime'
		},
		{
			title: translater("policy-editor.allowed"),
			dataIndex: 'allowed',
			key: 'allowed',
			render: (a: any) => (<PolicyActiveTag isActive={a}></PolicyActiveTag>)
		},
	];
}

export const userGroupColumnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<UserModel>): ColumnType<UserModel>[] => {
	

	return [
		{
			title: translater("user-editor.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string,record:UserModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.name")}/>)
			},
			
		},
		{
			title: translater("user-editor.fullName"),
			dataIndex: 'fullName',
			key: 'fullName',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.fullName.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.fullName")}/>)
			},

		},
		{
			title: translater("user-editor.source"),
			dataIndex: 'source',
			key: 'source',
			render:(value:any,record:UserModel,index:number)=>{
				return translater("from-server.userSource."+record.source)
			}
		},
		{
			title: translater("user-editor.organization"),
			dataIndex: 'organization',
			key: 'organization',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.organization.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.organization")}/>)
			},
		},
		{
			title: translater("user-editor.phone"),
			dataIndex: 'phone',
			key: 'phone',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.phone.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.phone")}/>)
			},
		},
		{
			title: translater("user-editor.email"),
			dataIndex: 'email',
			key: 'email',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.email.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.email")}/>)
			},
		},
		{
			title: translater("user-editor.active"),
			dataIndex: 'active',
			key: 'active',
			render: (a: any) => (<UserActiveTag isActive={a}></UserActiveTag>)
		},{
			title: translater("user-editor.created"),
			dataIndex: 'created',
			key: 'created',
			render:(value:any,record:UserModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
		},{
			title: translater("user-editor.updated"),
			dataIndex: 'updated',
			key: 'updated',
			render:(value:any,record:UserModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
		},
	];
}