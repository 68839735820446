import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { StatusLogInfoModel } from "../@mod";

interface StatusLogInfoViewModel extends WithTranslation {
    statusLogInfo: StatusLogInfoModel
}

interface StatusLogInfoState {

}

class StatusLogInfo extends React.Component<StatusLogInfoViewModel, StatusLogInfoState> {
    render(): React.ReactNode {
        let preStatusDesc = this.props.statusLogInfo.preStatus.desc ? this.props.statusLogInfo.preStatus.desc[this.props.i18n.language] : ""
        let afterStatusDesc = this.props.statusLogInfo.afterStatus.desc ? this.props.statusLogInfo.afterStatus.desc[this.props.i18n.language] : ""
        return (
            <div>
                <p>{this.props.statusLogInfo.operatorName + " -- " + this.props.statusLogInfo.date}</p>
                <p>{preStatusDesc + " -> " + afterStatusDesc}</p>
                <p>{this.props.statusLogInfo.comment}</p>
            </div>
        )
    }
}

export default withTranslation()(StatusLogInfo)