import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import './index.less';

import { Avatar, Button, Input, List, Select, Skeleton, Tag, Tooltip } from 'antd';
import { MailModel, MailSendModel } from "../../../../@mod";
import moment from "moment-timezone";
import {RightOutlined,ExclamationOutlined,CheckOutlined,SyncOutlined,EditOutlined} from "@ant-design/icons";



export interface MailsSendListViewModel extends WithTranslation {
    load?: (start, end) => Promise<MailSendModel[]>,
    itemOnView?: (mail: MailSendModel) => void,
    setManupulator?: (operator:ListViewManupulator)=>void

}
export interface ListViewManupulator {
    reload : ()=>void
}

interface MailsSendListViewState {
    start: string, // use for display
    current: string,
    mailList: MailSendModel[],
    loading: boolean,
    searchOption?: string,
    searchText: string,
    filter?: (mailList: MailSendModel) => boolean,

}


class MailsSendListView extends React.Component<MailsSendListViewModel, MailsSendListViewState> implements ListViewManupulator {

    reload = ()=>{
        this.loadState();
    }

    constructor(props: MailsSendListViewModel) {
        super(props)
        this.state = {
            start: moment().add(-30, "days").format("yyyy-MM-DD 23:59:59"),
            current: moment().format("yyyy-MM-DD 23:59:59"),
            mailList: [],
            loading: false,
            searchOption: "subject",
            searchText: ""
        }
        if(props.setManupulator) {
            props.setManupulator(this);
        }
    }

    loadState = async (): Promise<void> => {
        if (this.props.load) {
            await this.props.load(this.state.start, this.state.current)
                .then(x => {
                    this.setState({
                        mailList: x.sort((x, y) => Number(y.updatedAt) - Number(x.updatedAt))
                    })
                })
            }

    }

    doloadMore = async (): Promise<void> => {
        if (this.props.load) {
            var eariler = moment(this.state.start).add(-30, "days").format("yyyy-MM-DD 23:59:59");
            await this.props.load(eariler, this.state.start)
                .then(x => {
                    let exists = this.state.mailList;
                    x.sort((x, y) => Number(y.updatedAt) - Number(x.updatedAt)).forEach(n => {
                        exists.push(n);
                    })
                    this.setState({
                        mailList: exists,
                        start: eariler
                    })
                })
        }
    }


    componentDidMount() {
        this.loadState();
        this.applyFilter(undefined);
    }

    click = (model: MailSendModel) => {
        if (this.props.itemOnView) {
            this.props.itemOnView(model);
        }
    }

    loadMore = () => {
        this.setState({
            loading: true
        })

        this.doloadMore().finally(() => {
            this.setState({
                loading: false
            });
        })

    }

    getLoadMode = () => {
        return (
            <Button onClick={this.loadMore} loading={this.state.loading}>{this.props.t("mails.load-more")}</Button>
        )
    }
    changeSearchOption = (s) => {
        this.setState({
            searchOption: s
        })
        this.setState({
            filter: undefined,
            searchText: ""
        })
        this.applyFilter(s);
    }

    getMailListWithFilter = () => {
        if (this.state.filter) {
            return this.state.mailList.filter(this.state.filter)
        }
        return this.state.mailList;
    }
    applyFilter = (s) => {
        s = s??this.state.searchOption

        if (s) {
            switch (s) {
                case "subject":
                    this.setState({
                        filter: (m) => {
                            return m.content.subject.toLocaleLowerCase().indexOf(this.state.searchText.toLocaleLowerCase()) >= 0
                        }
                    })
                    break;
            }
        }
    }

    getFolderWithCounts = ()=>{

    
    }

    renderStatus = (m:MailSendModel)=>{
        switch(m.sendStatus){
            case "draft":
                return <Tag color={"orange"} icon={<EditOutlined />} >{m.sendStatusDesc[this.props.i18n.language]}</Tag>
            case "sent":
                return <Tag color={"green"} icon={<CheckOutlined />} >{m.sendStatusDesc[this.props.i18n.language]}</Tag>
            case "sent-fail":
                return <Tag color={"red"} icon={<ExclamationOutlined/>}>{m.sendStatusDesc[this.props.i18n.language]}</Tag>
            case "waiting-for-schedule":    
                return <Tag color={"purple"} icon={<SyncOutlined />}>{m.sendStatusDesc[this.props.i18n.language]}</Tag>
            case "sending":
                return <Tag color={"blue"} icon={<RightOutlined />}>{m.sendStatusDesc[this.props.i18n.language]}</Tag>
            default :
                return <Tag color={"blue"}>{m.sendStatusDesc[this.props.i18n.language]}</Tag>
        }
    }





    render(): React.ReactNode {

        return (
            <div className="mail-list-view">
                <div className="search-field">
                    <div className="search-input">
                        <Input.Group compact>
                            <Select style={{ width: '30%' }} defaultValue="subject" value={this.state.searchOption}
                                onChange={this.changeSearchOption}>
                                <Select.Option value="address" >{this.props.t("mails.mail-address")}</Select.Option>
                                <Select.Option value="subject" >{this.props.t("mails.mail-subject")}</Select.Option>
                            </Select>
                            {this.state.searchOption !== "folder" &&
                                <Input value={this.state.searchText} style={{ width: '70%', textAlign: "left" }} type={"text"}
                                    onChange={(t) => this.setState({ searchText: t.target.value })}
                                    
                                />} 
                          
                        </Input.Group>
                    </div>
                
                </div>


                <div className="mail-list-container">
                    <List<MailSendModel> loading={this.state.loading}
                        //loadMore={this.getLoadMode()}
                        dataSource={this.getMailListWithFilter()}
                        renderItem={(item) => {
                            return (
                                <div className="mail-item" onClick={() => this.click(item)}>
                                    <div className="title-field">
                                        <Tooltip placement="top" title={item.content.subject === "" ? this.props.t("mails.no-subject") : item.content.subject}>
                                            {item.content.subject === "" ? this.props.t("mails.no-subject") :item.content.subject}
                                        </Tooltip>
                                    </div>
                                    <div className="info">
                                        <div className="status">
                                            {this.renderStatus(item)}
                                        </div>
                                        <div className="date">
                                            {moment(item.updatedAt).format("MM-DD/HH:mm")}
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    >
                    </List>
                </div>
            </div>
        )
    }

}

export default withTranslation()(MailsSendListView);