import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import WorkBenchTable from "../../../../components/table/workbench-table";
import { ActionEventHandler } from "../../../../components";
import { removeDuplicateUser, taskColumnProvider, TaskModel } from "../../@mod";
import { ProjectServiceItemInstanceStatusDto, UserModel } from "../../../../api";
import ApiServer from "../../../../ApiServer";

interface TaskListViewModel extends WithTranslation {
    taskList?: TaskModel[]
    onDetail: (model: TaskModel) => void
    apiServer: ApiServer
}

interface TaskListViewState {
    dataLoading: boolean
    statusList: ProjectServiceItemInstanceStatusDto[],
    executorList: UserModel[],
    creatorList: UserModel[],
}


class TaskListView extends React.Component<TaskListViewModel, TaskListViewState> {
    constructor(props: TaskListViewModel) {
        super(props);
        this.state = {
            dataLoading: false,
            statusList: [],
            executorList: removeDuplicateUser(this.props.taskList?.map(t => t.executor) ?? []),
            creatorList: removeDuplicateUser(this.props.taskList?.map(t => t.createdBy) ?? []),
        }
    }

    componentDidMount() {
        this.loadStatus()
    }

    loadStatus = () => {
        this.props.apiServer.apiProjectTask.getItemInstanceStatusMetaData().then(r => {
            if (r.data.data?.dtos) {
                this.setState({
                    statusList: r.data.data.dtos
                })
            }
        })

    }

    render() {
        return (
            <div>
                <WorkBenchTable<TaskModel>
                    data={this.props.taskList ?? []}
                    dataLoading={this.state.dataLoading}
                    columnProvider={(a: ActionEventHandler<TaskModel>) => taskColumnProvider(this.props, this.state.statusList, this.state.executorList, a)}
                    rowKey={(record => record.id)}
                    onDetail={this.props.onDetail}
                />
            </div>
        );
    }
}

export default withTranslation()(TaskListView)