import Header from './header';
import Footer from './footer';
import { Layout } from 'antd';
import React from 'react';
import './index.less'
import {withTranslation, WithTranslation } from 'react-i18next';
import { Outlet } from "react-router-dom";
import ApiServer from '../ApiServer';
import { VERSION } from '../configure';


export interface ManagementLayoutModel extends WithTranslation {
	copyright: string,
	header?: React.ReactNode,
	body?: React.ReactNode,
	footer? : React.ReactNode
	apiServer:ApiServer
}

class ManagementLayout extends React.Component<ManagementLayoutModel, {}>{

	render(): React.ReactNode {
		return (
			<Layout className='layout-root'>
				<Header apiServer={this.props.apiServer}></Header>
				<Outlet></Outlet>
				<Footer clientVersion={VERSION}
					companyName=''
					serverVersion='1.1.1'
				></Footer>
			</Layout>)
	}
}

export default withTranslation()(ManagementLayout)