import { TicketPriorityDto, TicketSourceDto, TicketStatusDto, TicketTypeDto } from "../../../api";
import ApiServer from "../../../ApiServer";


export interface TagModel {
	id: number,
	name: string,
	color: string
}

export interface MetaModel {
	key?:number
	id: number,
	name: string,
	description: any
}

export interface TicketTypeModel extends MetaModel {

}
export interface TicketPriorityModel extends MetaModel {

}
export interface TicketStatusModel  extends MetaModel {
}

export interface TicketSourceModel  extends MetaModel {
}

export interface MetaDataSource {
	ticketSource: TicketSourceModel[],
	ticketStatus: TicketStatusModel[],
	ticketPriority: TicketPriorityModel[],
	ticketType: TicketTypeModel[]
}

export interface ContentSchemaModel {
	key?:number,
	id: number,
	schema?: string,
	uiSchema?: string
	name: string,
	description: string,
	createTime: string,
	updateTime: string
}


export interface UserModel {
	id: number,
	name: string,
	organization: string
}

export interface UserGroupModel {
	id: number,
	name: string
	description: string
}

export interface NotificationConfigurationModel {
	key: string,
	value:string
}

export async function loadUsers(apiServer: ApiServer, userGroupId: number): Promise<UserModel[]> {
	return apiServer.apiGroup.getUserGroup(userGroupId, true, false)
		.then(x =>
			x.data.data?.users?.map(y => {
				let u: UserModel = {
					name: (y.fullName ?? "") + "(@" + y.name + ")",
					id: y.id ?? 0,
					organization: y.organization ?? ""
				}
				return u
			}) ?? []
		)
}

export async function loadUserGroupModels(apiServer: ApiServer): Promise<UserGroupModel[]> {
	return apiServer.apiGroup.getUserGroups()
		.then(
			x => x.data.data?.list?.map(
				y => {
					let ug: UserGroupModel = {
						name: y.name ?? "",
						description: y.description ?? "",
						id: y.id ?? 0
					}
					return ug;
				}
			) ?? []
		)
}



export async function loadContentSchema(apiServer:ApiServer):Promise<ContentSchemaModel[]>{



		return await apiServer.apiMeta.getContentSchema()
		.then(
			x=>{
				let data:ContentSchemaModel[]=[];
				x.data.data?.list?.forEach(
					y=>{
						let d:ContentSchemaModel ={
							key:y.id,
							id:y.id??0,
							name:y.name??"",
							createTime:y.createdAt??"",
							updateTime:y.updatedAt??"",
							description:y.description??"",
						}
						data.push(d)
					}
				)
				return data;
			}
		)

	}


export async function loadMetaData(apiServer: ApiServer): Promise<MetaDataSource> {
	let metaSource: MetaDataSource = {
		ticketSource: [],
		ticketStatus: [],
		ticketPriority: [],
		ticketType: []
	};




	let ticketTypeLoader = apiServer.apiMeta.getTicketTypeList(true)
		.then(
			ty => {
				metaSource.ticketType = ty.data.data?.list?.map(
					(x:TicketTypeDto) => {
						let v: TicketTypeModel = {
							key:x.id,
							id: x.id ?? 0,
							name: x.name ?? "",
							description: x.description
						}
						return v;
					}
				) ?? []

			}
		);

	let ticketStatusLoader = apiServer.apiMeta.getTicketStatusList(true)
		.then(
			ts => {

				metaSource.ticketStatus =
					ts.data.data?.list?.map(
						(x:TicketStatusDto) => {
							let v: TicketStatusModel = {
								key:x.id,
								id: x.id ?? 0,
								name: x.name ?? "",
								description: x.description
							}
							return v;
						}
					) ?? []

			}
		);


	let ticketSourceLoader = apiServer.apiMeta.getTicketSourceList(true)
		.then(
			ts => {
				metaSource.ticketSource =
					ts.data.data?.list?.map(
						(x:TicketSourceDto) => {
							let v: TicketSourceModel = {
								key:x.id,
								id: x.id ?? 0,
								name: x.name ?? "",
								description: x.lang
							}
							return v;
						}
					) ?? []
			}
		)
	let ticketPriorityLoader = apiServer.apiMeta.getTicketPriority(true)
		.then(
			ts => {
				metaSource.ticketPriority =
					ts.data.data?.list?.map(
						(x:TicketPriorityDto) => {
							let v: TicketPriorityModel = {
								key:x.id,
								id: x.id ?? 0,
								name: x.name ?? "",
								description: x.lang
							}
							return v;
						}
					) ?? []

			}
		);

	await Promise.all([ticketStatusLoader,ticketTypeLoader,ticketPriorityLoader,ticketSourceLoader]);
	return metaSource;

}




export async function loadAllTags(apiServer: ApiServer): Promise<TagModel[]> {
	return apiServer.apiProductivity.getTagList()
		.then(x => {
			return x.data.data?.tagDtoList?.map((y) => {
				let t: TagModel = {
					name: y.name ?? "",
					color: y.color ?? "#000000",
					id: y.id ?? 0
				}
				return t;
			}
			) ?? []
		});
}
