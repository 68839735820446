import { Button, PageHeader } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { ActionEventHandler } from "../../../../components";
import WorkBenchTable from "../../../../components/table/workbench-table";
import { RouteContext, withRouter } from "../../../../utils";
import { ContentSchemaModel, loadContentSchema } from "../../@mod";
import { columnsProvider } from "./@mod";
import './index.less'


export interface ContentSchemasModel extends WithTranslation {
	apiServer:ApiServer,
	router?: RouteContext

}
interface ContentSchemasState {
	data:ContentSchemaModel[]
	dataLoading:boolean
}

class ContentSchemas extends React.Component<ContentSchemasModel, ContentSchemasState>{
	constructor(props:ContentSchemasModel){
		super(props)
		this.state ={
			data:[],
			dataLoading:true,
		}
	}

	loadData= async()=>{

		let data = await loadContentSchema(this.props.apiServer);
		this.setState({
			data:data
		})
	}


	l:Promise<void>|undefined;
	componentDidMount(){
		if(!this.l){
			this.l = this.loadData()
			.finally(
				()=>{
					this.l=undefined;
					this.setState({
						dataLoading:false
					})
					
				}
			)
			
		}
	}



	newContentClick = ()=>{
		this.props.router?.navigate("/system/productivity/template-editor/0")
	}

	onDetail = (cs:ContentSchemaModel)=>{
		this.props.router?.navigate("/system/productivity/template-editor/"+cs.id);

	}

	render(): React.ReactNode {
		return (
			<div className="content-schema-container site-drawer-render-in-current-wrapper" >
				<PageHeader title={this.props.t("content-schemas.title")}
				subTitle={this.props.t("content-schemas.sub-title")}
				extra ={
					[
						<Button key={1} type="primary" onClick={this.newContentClick}>{this.props.t("content-schemas.new")}</Button>
					]
				}
				>
					<WorkBenchTable<ContentSchemaModel>
						onDetail = {this.onDetail}
						data = {this.state.data}
						dataLoading ={this.state.dataLoading}
						columnProvider={(a:ActionEventHandler<ContentSchemaModel>)=>columnsProvider(this.props,a)}
						/>
		
				</PageHeader>
			</div>
		)

	}
}


export default withRouter(withTranslation()(ContentSchemas));