import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import React from "react";
import { ActionEventHandler } from "../../../../components";
import ColumnTextFilter from "../../../../components/table/column-text-filter";

export interface PluginModel {
	key?:number
	id:number
    name:string
    description:string 
    schema:string
    version:string
}

export interface PluginConfigureModel{
	id:number
    config:string
    enabled:boolean
    runAs:string
}


export const columnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<PluginModel>): ColumnType<PluginModel>[] => {
	
	return [
		{
			title: translater("plugins.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string,record:PluginModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:PluginModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("plugins.name")}/>)
			},
			
		},
		{
			title: translater("plugins.description"),
			dataIndex: 'description',
			key: 'description'
		},
		{
			title: translater("plugins.version"),
			dataIndex: 'version',
			key: 'version',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:PluginModel) => record.version.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("plugins.version")}/>)
			},
		}
	];
}