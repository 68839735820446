import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import ChannelConfiguration from "../channel-configuration";
import MessageTemplateContainer from "../message-template-container";
import "./index.less"
import ApiServer from "../../../../../ApiServer";

export interface NotificationSettingModel extends WithTranslation {
    apiServer: ApiServer,
    channelConfigurationTitle: string,
    messageTemplateContainerTitle: string,
    channelType: string
}

interface NotificationSettingState {
}

class NotificationSetting extends React.Component<NotificationSettingModel, NotificationSettingState> {
    constructor(props: NotificationSettingModel) {
        super(props);
    }

    render() {
        return (
            <div className="notification-container">
                <div>
                    <ChannelConfiguration
                        channelType={this.props.channelType}
                        title={this.props.channelConfigurationTitle}
                        apiServer={this.props.apiServer}
                    />
                </div>
                <div className="message-template-container">
                    <MessageTemplateContainer
                        title={this.props.messageTemplateContainerTitle}
                        channelType={this.props.channelType}
                        apiServer={this.props.apiServer}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(NotificationSetting)