
const initlizeState = {

}

let actionMap: Map<string, (state: any, action: any) => any> = new Map();

actionMap.set("SET_USER", (state, action) => { return { ...state, user: action.user } });


export default function Reducers(state = initlizeState, action) {

	let provider = actionMap.get(action.type);
	if (provider) {
		return provider(state, action)
	}
	return state;
}