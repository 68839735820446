import { Button, Descriptions } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { ExtendsIcons } from "../../../../config/ex-icon";
import { loadVendorEmailBox, MailBoxModel, MailVendorModel } from "../../@mod";
import './index.less'
import { LockOutlined, UnlockOutlined, EditOutlined } from '@ant-design/icons';
import WorkBenchTable from "../../../../components/table/workbench-table";

export interface VendorViewerViewModel extends WithTranslation {
    vendorModel: MailVendorModel
    apiServer: ApiServer
}


interface VendorViewerViewState {
    dataLoading:boolean,
    mailBoxes: MailBoxModel[]
}




class VendorViewer extends React.Component<VendorViewerViewModel, VendorViewerViewState>{

    constructor(props:VendorViewerViewModel){
        super(props)
        this.state ={
            dataLoading:false,
            mailBoxes:[]
        }
    }


    loadData = async ()=>{
        var mailBoxModels = await loadVendorEmailBox(
            this.props.vendorModel.id,
            this.props.apiServer
        )
        this.setState({
            mailBoxes:mailBoxModels,
            dataLoading:false
        })


    }


    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.setState({
                        dataLoading: false
                    })
                })
        }
    }

    render(): React.ReactNode {
        return (
            <div className="vendor-viewer">
                <div className="description-view">
                <Descriptions title={this.props.t("mails.email-vendor.name") + ":" + this.props.vendorModel.vendorName} bordered size="small"
                    extra={
                        [
                            <Button icon={<EditOutlined/>} type="primary">{this.props.t("mails.email-vendor.edit")}</Button>
                        ]
                    }
                    column={4}>
                    <Descriptions.Item span={4} label={this.props.t("mails.email-vendor.name")}>{this.props.vendorModel.vendorName}</Descriptions.Item>
                    <Descriptions.Item span={4} label={this.props.t("mails.email-vendor.send-endpoint")}>{this.props.vendorModel.sendHost}</Descriptions.Item>
                    <Descriptions.Item label={this.props.t("mails.email-vendor.send-protocol")}>{this.props.vendorModel.sendProtocol.toUpperCase()}</Descriptions.Item>
                    <Descriptions.Item label={this.props.t("mails.email-vendor.send-port")}>{this.props.vendorModel.sendPort}</Descriptions.Item>
                    <Descriptions.Item label={this.props.t("mails.email-vendor.send-auth")}>
                        {
                            this.props.vendorModel.sendAuth &&
                            <div style={{ color: "green" }}>
                                <ExtendsIcons type="icon-yijianshouquan" />{this.props.t("mails.email-vendor.enable-send-auth")}
                            </div>

                        }
                        {!this.props.vendorModel.ssl &&
                            <div style={{ color: "grey" }}>
                                <ExtendsIcons type="icon-yijianshouquan" />{this.props.t("mails.email-vendor.disabled-send-auth")}
                            </div>

                        }
                    </Descriptions.Item>
                    <Descriptions.Item label={this.props.t("mails.email-vendor.send-startSsl")}>
                        {
                            this.props.vendorModel.startSsl &&
                            <div style={{ color: "green" }}>
                                <LockOutlined />{this.props.t("mails.email-vendor.enabled-start-tls")}
                            </div>

                        }
                        {!this.props.vendorModel.startSsl &&
                            <div style={{ color: "grey" }}>
                                <UnlockOutlined />{this.props.t("mails.email-vendor.disabled-start-tls")}
                            </div>

                        }

                    </Descriptions.Item>

                    <Descriptions.Item span={4} label={this.props.t("mails.email-vendor.receive-endpoint")}>{this.props.vendorModel.receiveHost}</Descriptions.Item>
                    <Descriptions.Item label={this.props.t("mails.email-vendor.receive-protocol")}>{this.props.vendorModel.receiveProtocol.toUpperCase()}</Descriptions.Item>
                    <Descriptions.Item label={this.props.t("mails.email-vendor.receive-port")}>{this.props.vendorModel.receivePort}</Descriptions.Item>
                    <Descriptions.Item label={this.props.t("mails.email-vendor.receive-auth")}>

                        {
                            this.props.vendorModel.receiveAuth &&
                            <div style={{ color: "green" }}>
                                <ExtendsIcons type="icon-yijianshouquan" />
                                {this.props.t("mails.email-vendor.enable-receive-auth")}
                            </div>

                        }
                        {!this.props.vendorModel.ssl &&
                            <div style={{ color: "grey" }}>
                                <ExtendsIcons type="icon-yijianshouquan" />
                                {this.props.t("mails.email-vendor.disabled-receive-auth")}
                            </div>

                        }
                    </Descriptions.Item>
                    <Descriptions.Item label={this.props.t("mails.email-vendor.receive-ssl")}>{this.props.vendorModel.ssl}
                        {
                            this.props.vendorModel.ssl &&
                            <div style={{ color: "green" }}>
                                <LockOutlined />{this.props.t("mails.email-vendor.enabled-ssl")}
                            </div>

                        }
                        {!this.props.vendorModel.ssl &&
                            <div style={{ color: "grey" }}>
                                <UnlockOutlined />{this.props.t("mails.email-vendor.disabled-ssl")}
                            </div>

                        }
                    </Descriptions.Item>
                </Descriptions>
                </div>
                <div className="combied-mail-boxes">
                    <div className="ant-descriptions-title">
                        {this.props.t("mails.email-vendor.combined-mailbox")}
                    </div>
                    <div>
                    
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslation()(VendorViewer);