import Dragger from "antd/lib/upload/Dragger";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import './index.less';
import { InboxOutlined } from '@ant-design/icons'
import { Modal, notification, Table } from "antd";
import { columnsProvider } from '../@mod'
import { FileModel } from "../@mod";
import { RcFile } from "antd/lib/upload";
import ApiServer from "../../../../ApiServer";
import { Axios } from "axios";
import URI from "urijs";

export interface AttachmentViewModel extends WithTranslation {
	ticketId: number
	apiServer: ApiServer
}

interface AttachmentState {
	files:FileModel[],
	fileNameOnDelete:String
	visable:boolean,
	fileIdOnDelete:number
}

class AttachmentView extends React.Component<AttachmentViewModel, AttachmentState>{

	constructor(props:AttachmentViewModel){
		super(props)	
		this.state ={
			files :[],
			visable:false,
			fileNameOnDelete:"",
			fileIdOnDelete:0
		}	
	}


	l: Promise<void> |undefined;
	componentDidMount(){
		if(!this.l){
			this.l = this.loadData()
			.finally(
				()=> this.l=undefined
			)
		}

	}

	getSnapshotBeforeUpdate(preProp:AttachmentViewModel,preState:AttachmentState){
		if(preProp.ticketId!==this.props.ticketId){
			this.componentDidMount()
		}
		return null;
	}

	componentDidUpdate(){
		
	}


	loadData = async()=>{
		return this.props.apiServer.apiTickets.getTicketAttachments(this.props.ticketId)
			.then(x=>{
				if(x.data.data?.succeed){
					var fs:FileModel[]= [];
					x.data.data.list?.forEach(y=>{
						var f: FileModel={
							id : y.id??0,
							fileName:y.name??"",
							creatorName: (y.creator?.fullName??"") + "@(" + (y.creator?.name??"") + ")", 
							key :y.id,
							uploadTime: y.createdAt??"",
							driver:y.storageType??{}
						}
						fs.push(f)
					})
					this.setState({
						files:fs
					})
				}
			})
	}

	fileNameClick = (model: FileModel, actionType: string) => {
		if(actionType==="delete"){

			this.setState({
				fileIdOnDelete:model.id,
				visable:true,
				fileNameOnDelete: model.fileName??""
			})
		}else if (actionType==="detail"){
			
			this.props.apiServer.apiAttachment.getAttachment(model.id)
			.then(x=>{
				if(x.data.data?.succeed){
					var element = document.createElement('a');
					element.target="_blank";
					element.href= x.data.data.attachmentInfo?.downloadUrl??""
					document.body.appendChild(element)
					element.click()
					URL.revokeObjectURL(element.href);
					document.body.removeChild(element);

				}
			})
		}
	}

	fileAttachmendDelete =() =>{
		this.setState({
			visable:false
		})
		this.props.apiServer.apiTickets.deleteTicketAttachment(
			this.props.ticketId,this.state.fileIdOnDelete
		).then(x=>{
			if(x.data.data?.succeed){
				notification.success({message:this.props.t("tickets.attachment.delete-attachment-succeed")})
				this.loadData();
			}else{
				notification.error({message:this.props.t("tickets.attachment.delete-attachment-fail")})
			}
		})

	}

	customerRequesst =  (option: any) => {
		let uri = new URI(option.action);
		var a = new Axios({
			baseURL: uri.scheme() + "://" + uri.host(),
			onUploadProgress: (a) => {
				option.onProgress({ percent: a.loaded / a.total });
			}
		})

		var fileId = uri.query(true)["id"]
		uri.removeQuery("id");

		a.put(uri.toString(), option.file)
			.then(async (x)  => {
				option.onSuccess(x.data, x.request)
				 await this.props.apiServer.apiTickets.postTicketAttachment(this.props.ticketId,{
					attachmentId:fileId
				})
				await this.props.apiServer.apiAttachment.postCompleteUpload(fileId);
				this.componentDidMount();
			}).then(

			);
	}

	createSpace = (rc: RcFile) => {
		let name = rc.name;
		return this.props.apiServer.apiAttachment.putNewUploadRequest(
			{
				fileName: name
			}
		).then(x => {
			if (x.data.data?.attachmentDto?.uploadUrl) {
				return x.data.data?.attachmentDto?.uploadUrl + "&id="+x.data.data.attachmentDto.id
			}
			return "";
		})
	};

	render(): React.ReactNode {
		return (
			<div className="attachment-view">
				      <Modal
							title={this.props.t("tickets.attachment.delete-title-prefix")}
							visible={this.state.visable}
							onOk={this.fileAttachmendDelete}
							onCancel={(e)=>this.setState({visable:false})}
							>
								<h1>{this.state.fileNameOnDelete}</h1>
						</Modal>
				<div className="attachment-upload">
					<Dragger
						name='file'
						multiple={true}
						action={this.createSpace}
						customRequest={this.customerRequesst}
					>
						<div className="drop-box">
							<span >
								<InboxOutlined className="upload-drag-icon" />
							</span>
							<div className="upload-text">
								{this.props.t("upload-text")}
							</div>
							<div className="ant-upload-hint">
								{this.props.t("upload-hit")}
							</div>
						</div>
					</Dragger>
				</div>

				<div className="fileList">
					<Table columns={columnsProvider(this.props, this.fileNameClick)} dataSource={this.state.files} >

					</Table>
				</div>
			</div>
		)
	}
}

export default withTranslation()(AttachmentView)
