import { Timeline } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { StatusLogInfoModel } from "./@mod";
import StatusLogInfo from "./status-log-info";


interface StatusLogLineModel extends WithTranslation {
    statusLogList: StatusLogInfoModel[]
}

interface StatusLogLineState {

}


class StatusLogLine extends React.Component<StatusLogLineModel, StatusLogLineState>{
    render(): React.ReactNode {
        return (
            <>
                <Timeline mode="left">
                    {this.props.statusLogList.map(log => {
                        return <Timeline.Item key={log.id}><StatusLogInfo statusLogInfo={log} /></Timeline.Item>
                    })}
                </Timeline>
            </>
        )
    }
}





export default withTranslation()(StatusLogLine)