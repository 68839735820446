import { Button, Popconfirm, Tag } from "antd"
import { ColumnType } from "antd/lib/table"
import moment from "moment"
import React from "react"
import { MailTemplateDto } from "../../../../api"
import ApiServer from "../../../../ApiServer"
import { ActionEventHandler } from "../../../../components"
import ColumnDateRangeFilter from "../../../../components/table/column-date-range-filter"
import ColumnTextFilter from "../../../../components/table/column-text-filter"

const TIME_PATTERN = "YYYY-MM-DD HH:mm:ss"

export interface MailTemplateUserModel {
    id: number,
    fullName: string
}

export interface MailTemplateTagModel {
    id: number,
    color: string,
    name: string
}
export interface MailTemplateModel {
    id?: number,
    title: string,
    content: string,
    tags?: MailTemplateTagModel[],
    createdBy?: MailTemplateUserModel,
    createdTime?: string,
    updatedBy?: MailTemplateUserModel,
    updatedTime?: string,
}

export interface MailBoxModel {
    key?: number,
    id: number,
    emailAddress: string,
    cred: string,
    description: any,
    creator: string,
    updateter: string,
    created: string,
    updated: string,
    displayCred: boolean,
    accessModel?: any,
    tags?: MailTemplateTagModel[],
    deployTo: string[],
    isVerifing?: boolean,
    successInstance?: number[],
    failInstance?: number[]
}

export const convertMailTemplateModelForSearch = (template: MailTemplateModel): string => {
    let res: string = template.id + ""
        + template.title + ""
        + template.content + ""
        + template.createdBy?.fullName + ""
        + template.createdTime + ""
        + template.updatedBy?.fullName + ""
        + template.updatedTime + ""
        + template.tags?.map(t => t.name + ",") + ""
    return res
}

export const loadEmailTemplate = async (includsContent: boolean, apiServer: ApiServer): Promise<MailTemplateModel[]> => {
    return apiServer.apiMail.getMailTemplateList(includsContent).then(x => {
        if (x.data.data?.dtos) {
            return x.data.data?.dtos.map(dto => convertTemplateDtoToModel(dto))
        }
        return []
    })
}

export const loadTemplateContent = (templateId: number, apiServer: ApiServer) => {
    return apiServer.apiMail.getMailTemplateContent(templateId).then(x =>
        x.data.data?.success ? x.data.data.content : "")
}

export const convertTemplateDtoToModel = (dto: MailTemplateDto): MailTemplateModel => {
    return {
        id: dto.id ?? 0,
        title: dto.title ?? "",
        content: dto.content ?? "",
        tags: dto.tags?.map(t => {
            return {
                id: t.id ?? -1,
                color: t.color ?? "",
                name: t.name ?? ""
            }
        }),
        createdBy: {
            id: dto.createdBy?.id ?? -1,
            fullName: dto.createdBy?.fullName ?? ""
        },
        createdTime: dto.createdTime,
        updatedBy: {
            id: dto.updatedBy?.id ?? -1,
            fullName: dto.updatedBy?.fullName ?? ""
        },
        updatedTime: dto.updatedTime,
    }
}

export const mailTemplateColumnProvider = (
    translater: ((d: string) => string),
    handler: ActionEventHandler<MailTemplateModel>,
    bindBtn?: boolean, editBtn?: boolean, deleteBtn?: boolean, unbindBtn?: boolean
): ColumnType<MailTemplateModel>[] => {
    return [
        {
            title: translater("mails.mail-template.fields.id"),
            key: "id",
            dataIndex: "id",
            render: (value, record: MailTemplateModel, index) => {
                return <Button type="link" onClick={() => handler(record, "detail")}>{"#" + record.id}</Button>
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailTemplateModel) => Number(record.id) === Number(value),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.mail-template.fields.id")} />)
            }
        },
        {
            title: translater("mails.mail-template.fields.title"),
            key: "title",
            dataIndex: "title",
            render: (value, record: MailTemplateModel, index) => {
                return record.title
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailTemplateModel) =>
                record.title.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.mail-template.fields.emailAddress")} />)
            }
        },
        {
            title: translater("mails.mail-template.fields.tags"),
            key: "tags",
            dataIndex: "tags",
            render: (value, record: MailTemplateModel, index) => {
                return record.tags?.map(tag => {
                    return <Tag key={tag.name} color={tag.color}>{tag.name}</Tag>
                })
            }
        },
        {
            title: translater("mails.mail-template.fields.create-time"),
            key: "createdTime",
            dataIndex: "createdTime",
            render: (value, record: MailTemplateModel, index) => {
                return record.createdTime ? moment.parseZone(record.createdTime).format(TIME_PATTERN) : ""
            },
            sorter: (a: MailTemplateModel, b: MailTemplateModel) => moment(a.createdTime).unix() - moment(b.createdTime).unix(),
            showSorterTooltip: false
        },
        {
            title: translater("mails.mail-template.fields.creator"),
            key: "createdBy",
            dataIndex: "createdBy",
            render: (value, record: MailTemplateModel, index) => {
                return record.createdBy?.fullName
            },
            filterSearch: true,
            onFilter: (value: string | boolean | number, record: MailTemplateModel) => {
                return record.createdBy?.fullName.toLowerCase().includes(String(value).toLowerCase()) ?? false
            },
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.mail-template.fields.creator")} />)
            }
        },
        {
            title: translater("mails.mail-template.fields.update-time"),
            key: "updatedTime",
            dataIndex: "updatedTime",
            render: (value, record: MailTemplateModel, index) => {
                return record.updatedTime ? moment.parseZone(record.updatedTime).format(TIME_PATTERN) : ""
            },
            sorter: (a: MailTemplateModel, b: MailTemplateModel) => moment(a.updatedTime).unix() - moment(b.updatedTime).unix(),
            showSorterTooltip: false
        },
        {
            title: translater("mails.mail-template.fields.updator"),
            key: "updatedBy",
            dataIndex: "updatedBy",
            render: (value, record: MailTemplateModel, index) => {
                return record.updatedBy?.fullName
            },
            filterSearch: true,
            onFilter: (value: string | boolean | number, record: MailTemplateModel) => {
                return record.updatedBy?.fullName.toLowerCase().includes(String(value).toLowerCase()) ?? false
            },
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.mail-template.fields.updator")} />)
            }
        },
        {
            title: translater("mails.mail-template.operation"),
            render: (value, record: MailTemplateModel, index) => {
                const btns: React.ReactNode[] = []
                if (bindBtn) {
                    btns.push(
                        <Button
                            size="small"
                            onClick={() => handler(record, "bind-mail-box")}
                        >{translater("mails.mail-template.binding")}</Button>
                    )
                }

                if (editBtn) {
                    btns.push(
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => handler(record, "edit")}
                        >{translater("mails.mail-template.edit")}</Button>
                    )
                }

                if (deleteBtn) {
                    btns.push(
                        <Popconfirm
                            title={translater("mails.mail-template.confirm-delete")}
                            onConfirm={() => handler(record, "delete")}
                        >
                            <Button
                                size="small"
                                danger
                            >{translater("mails.mail-template.delete")}</Button>
                        </Popconfirm>
                    )
                }

                if (unbindBtn) {
                    btns.push(
                        <Popconfirm
                            title={translater("mails.mail-template.confirm-unbind")}
                            onConfirm={() => handler(record, "unbind")}
                        >
                            <Button
                                size="small"
                                danger
                            >{translater("mails.mail-template.unbind")}</Button>
                        </Popconfirm>
                    )
                }

                return (
                    <div className="operation-bar">
                        {btns}
                    </div>
                )
            }
        }
    ]
}

export const mailBoxColumnProvider = (translater: ((d: string) => string), handler: ActionEventHandler<MailBoxModel>, apiServer: ApiServer,): ColumnType<MailBoxModel>[] => {
    var columns: ColumnType<MailBoxModel>[] = [
        {
            title: translater("mails.conf.id"),
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: translater("mails.conf.emailAddress"),
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            render: (text: string, record: MailBoxModel) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => record.emailAddress.indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.conf.emailAddress")} />)
            },
        },
        {
            title: translater("mails.conf.createTime"),
            dataIndex: 'createTime',
            key: 'createTime',
            render: (value: any, record: MailBoxModel, index: number) => {
                return record.created === "" ? "" : moment.parseZone(record.created).format("YYYY-MM-DD HH:mm:ss");
            },
            filterDropdown(props) {
                return (<ColumnDateRangeFilter context={props} title={translater("mails.conf.createTime")} />)
            },
            sorter: (a: MailBoxModel, b: MailBoxModel) => {
                return moment(a.created).unix() - moment(b.created).unix()
            },
            showSorterTooltip: false,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => {
                value = value.toString();
                let startEnd = value.split("->");
                if (startEnd.length == 2) {
                    let start = moment(startEnd[0]);
                    let end = moment(startEnd[1]);
                    let created = moment(record.created)
                    return created.isAfter(start) && created.isBefore(end);
                }
                return true
            }
        }, {
            title: translater("mails.conf.updateTime"),
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: (value: any, record: MailBoxModel, index: number) => {
                return record.updated === "" ? "" : moment.parseZone(record.updated).format("YYYY-MM-DD HH:mm:ss");
            },
            filterDropdown(props) {
                return (<ColumnDateRangeFilter context={props} title={translater("mails.conf.updateTime")} />)
            },
            sorter: (a: MailBoxModel, b: MailBoxModel) => {
                return moment(a.updated).unix() - moment(b.updated).unix()
            },
            showSorterTooltip: false,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => {
                value = value.toString();
                let startEnd = value.split("->");
                if (startEnd.length == 2) {
                    let start = moment(startEnd[0]);
                    let end = moment(startEnd[1]);
                    let updated = moment(record.updated)
                    return updated.isAfter(start) && updated.isBefore(end);
                }
                return true
            }
        },
        {
            title: translater("mails.conf.latestUpdater"),
            dataIndex: 'updateter',
            key: 'updateter',
            render: (text: string, record: MailBoxModel) => {
                return (
                    <div>
                        <div>
                            {record.updateter}
                        </div>
                        <div>

                        </div>

                    </div>)
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => (record.updateter ?? "").indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.conf.latestUpdater")} />)
            },
        },
        {
            title: translater("mails.conf.latestUpdater"),
            dataIndex: 'updateter',
            key: 'updateter',
            render: (text: string, record: MailBoxModel) => {
                return (
                    <Popconfirm
                        title={translater("mails.mail-template.confirm-unbind")}
                        onConfirm={() => handler(record, "unbind")}
                    >
                        <Button size="small" danger>{translater("mails.mail-template.unbind")}</Button>
                    </Popconfirm>

                )
            }
        }
    ]
    return columns;
}