import { ab2str, base64ToArrayBuffer, str2ab } from './utils'


export interface RsaKeyPair{
    privateKey : string,
    publicKey :string
}


export class RsaProvider {

    cypto: SubtleCrypto = window.crypto.subtle;

    public async encrypt(plainText: string, publicKey: string): Promise<string> {

        var promKey = this.cypto.importKey(
            "spki",
            base64ToArrayBuffer(publicKey),
            {
                name: "RSA-OAEP",
                hash: "SHA-512",
            },
            true,
            ["encrypt"]
        )
        promKey.catch(r => {
            console.log(r);
        });

        let key = await promKey;
        return await this.cypto.encrypt({
            name: "RSA-OAEP"
        }, key, str2ab(plainText)
        ).then(x => {
            return ab2str(x)
        });

    }

    public async decrypt(encrypted: string, privateKey: string): Promise<string> {
        var key = await this.cypto.importKey(
            "pkcs8",
            base64ToArrayBuffer(privateKey),
            {
                name: "RSA-OAEP",
                hash: "SHA-512",
            },
            true,
            ["decrypt"]
        )

        return await this.cypto.decrypt(
            {
                name: "RSA-OAEP"
            }, key, base64ToArrayBuffer(encrypted)
        ).then(x => {
            return ab2str(x);
        })
    }

    public async generateKey() :Promise<RsaKeyPair> {
        let keyPair = await this.cypto.generateKey(
            {
                name: "RSA-OAEP",
                modulusLength: 2048,
                publicExponent: new Uint8Array([1, 0, 1]),
                hash: "SHA-512"
            },
            true,
            ["encrypt", "decrypt"]
        );


        let privateKey = await this.cypto.exportKey(
            "pkcs8", keyPair.privateKey
        )
        let publickey = await this.cypto.exportKey(
            "spki", keyPair.publicKey
        );
        
        return Promise.resolve({
            publicKey: ab2str(publickey),
            privateKey: ab2str(privateKey)
        })
    }




}