import { Button, Popconfirm, Popover } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TicketModel } from "../../../@mod";
import TicketList from "../../../ticket-list";
import TicketSearch from "../ticket-search";
import './index.less'

export interface TicketRelatedViewModel extends WithTranslation {
	title: string,
	button: string,
	dataLoading: boolean
	tickets: TicketModel[],
	ondetail?: (d: TicketModel) => void
	link?: (id: number) => void
	unlink: (ids: number[]) => void
	search: (subject: string) => Promise<TicketModel[]>
}

interface TicketRelatedViewState {
	selectedTickets: TicketModel[],
	disabledCancelBtn: boolean
}

class TicketRelatedView extends React.Component<TicketRelatedViewModel, TicketRelatedViewState>{
	constructor(props: TicketRelatedViewModel) {
		super(props)
		this.state = {
			selectedTickets: [],
			disabledCancelBtn: true
		}
	}

	linkAs = (id: number) => {
		this.props.link?.(id)
	}

	onSelect = (t: TicketModel[]) => {
		this.setState({
			selectedTickets: t,
			disabledCancelBtn: t.length === 0
		})
	}

	render(): React.ReactNode {
		return (
			<div className="related-view">
				<div className="top-bar">
					<div> {this.props.title}</div>
					<div>
					</div>

					<div>
						<Popover trigger={"click"}
							placement="left"
							destroyTooltipOnHide
							content={<TicketSearch
								ticketProvider={this.props.search}
								complete={this.linkAs}
							/>}
						>
							<Button loading={this.props.dataLoading} >{this.props.button}</Button>
						</Popover>

						<Popconfirm
							title={this.props.t("tickets.related.confirm-unlink-postfix")}
							disabled={this.state.disabledCancelBtn}
							onConfirm={() => {
								this.setState({ disabledCancelBtn: true })
								this.props.unlink(this.state.selectedTickets.map(d => { return d.id }))
							}}
						>
							<Button danger style={{ marginLeft: '8px' }}
								disabled={this.state.disabledCancelBtn}
							>
								{this.props.t("tickets.related.unlink")}
							</Button>
						</Popconfirm>
					</div>
				</div>
				<div className="data-view">
					<TicketList {...this.props} onSelected={this.onSelect} />
				</div>
			</div>);
	}

}

export default withTranslation()(TicketRelatedView);