import { Button, Modal, Popover } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MailModel } from "../../../../@mod";
import "./index.less"
import { CloseOutlined, CaretLeftOutlined, BackwardOutlined, LinkOutlined } from "@ant-design/icons"
import UserAvatar from "../../../../../../components/user-avatar";
import moment from "moment";
import ApiServer from "../../../../../../ApiServer";


export interface MailDetailViewModel extends WithTranslation {
    apiserver: ApiServer
    mail: MailModel

}

interface MailDetailViewState {
    showAttachmentList: boolean
    mailContentNames: string[]
}



class MailDetailView extends React.Component<MailDetailViewModel, MailDetailViewState>{

    constructor(props: MailDetailViewModel) {
        super(props)
        this.state = {
            showAttachmentList: false,
            mailContentNames: []
        }
    }


    showAttachmentList = () => {
        this.props.apiserver.apiMail.getMailContentNames(this.props.mail.mailBoxId, this.props.mail.id).then(r => {
            this.setState({
                showAttachmentList: true,
                mailContentNames: r.data.data?.mailContentNames ?? []
            })
        })
    }

    downloadAttachment = (attachmentName: string) => {
        let mail = this.props.mail
        this.props.apiserver.apiMail.getMailContentDownloadUrl(mail.mailBoxId, mail.id, [attachmentName]).then(r => {
            if (r.data.data?.downloadUrls) {
                r.data.data?.downloadUrls.forEach(downLoad => {
                    if (downLoad.downloadUrl) {
                        let a = document.createElement('a')
                        a.href = downLoad.downloadUrl
                        a.download = downLoad.fileName ?? ""
                        document.body.appendChild(a)
                        a.click()
                        a.remove()
                    }
                })
            }
        })
    }


    render(): React.ReactNode {
        return (
            <div className="mailBox-detail">
                <div className="toolbar">
                    <div className="title-with-close">
                        <Button shape="circle" icon={<CloseOutlined />}></Button>
                        <h1> {this.props.mail.subject === "" ? this.props.t("mails.no-subject") : this.props.mail.subject}</h1>
                    </div>
                    <div className="contract-info">
                        <div className="left-part">
                            <UserAvatar userName={this.props.mail.externInfo.send} />
                            <div className="contract-detail">
                                <div className="sender">
                                    {this.props.mail.externInfo.send}
                                </div>
                                <div className="receiver">
                                    {this.props.mail.externInfo.to}
                                </div>
                            </div>
                        </div>
                        <div className="right-part">
                            <div className="opeation">
                                <Button size="small" icon={<CaretLeftOutlined />}>
                                    {this.props.t("mails.reply")}

                                </Button>
                                <Button size="small" icon={<BackwardOutlined />}>
                                    {this.props.t("mails.reply-all")}

                                </Button>
                                <Button size="small" icon={<LinkOutlined />} onClick={this.showAttachmentList}>
                                    {this.props.t("mails.attachment-list")}
                                </Button>
                            </div>
                            <div>
                                {moment(this.props.mail.mailDate).format("yyyy-MM-DD hh:mm:ss")}
                            </div>

                        </div>
                    </div>

                </div>
                <div className="detail-content">
                    <iframe src={"/api/v1/mail/mailboxes/" + this.props.mail.mailBoxId + "/mails/" + this.props.mail.id + "/contents?content=index.html"} >

                    </iframe>
                </div>

                <Modal
                    title={this.props.t("mails.attachment-list")}
                    width={1600}
                    visible={this.state.showAttachmentList}
                    onCancel={() => this.setState({ showAttachmentList: false })}
                    footer={null}
                    className={"attachment-list"}
                >
                    <div className="attachment-list-container">
                        {this.state.mailContentNames.map(c => {
                            return <li>
                                <Button
                                    key={c}
                                    type={"link"}
                                    icon={<LinkOutlined />}
                                    onClick={() => this.downloadAttachment(c)}
                                >
                                    {c}
                                </Button>
                            </li>
                        })}
                    </div>
                </Modal>
            </div>)
    }
}


export default withTranslation()(MailDetailView);