import moment from "moment-timezone";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import BulletChart, { RangeConfigure } from "../../../../../../components/charts/ bullet-chart";
import { TicketTimeModel } from "../@mod";
import './index.less'

export interface TicketTimeLineViewModel extends WithTranslation {
    ticketTimeModel: TicketTimeModel
}

interface TicketTimeLineViewState {

}

const GREEN = "#73d13d";
const RED = "#ff4d4f";
const BLUE ="#1890ff";
const OTHER ="#ffec3d";
const SELF ="#531dab"


class TicketTimeLine extends React.Component<TicketTimeLineViewModel, TicketTimeLineViewState>{



    getRange = () => {

        let firstBlock = 0;
        let secondBlock = 0;
        let timeModel = this.props.ticketTimeModel;

        let ranges: RangeConfigure[] = [];

        /**没有超时 */
        if (timeModel.closeAt < timeModel.expectClose) {
            firstBlock = timeModel.closeAt - timeModel.createAt;
            secondBlock = timeModel.expectClose - timeModel.createAt;
            ranges.push({
                value: firstBlock,
                color: GREEN
            })
            ranges.push({
                value: secondBlock,
                color: BLUE
            })
            // 绿色
            // 蓝色
            /**超时 */
        } else {
            console.log("超时",timeModel);
            firstBlock = timeModel.expectClose - timeModel.createAt
            secondBlock = timeModel.closeAt - timeModel.createAt;
            ranges.push({
                value: firstBlock,
                color: GREEN
            })
            ranges.push({
                value: secondBlock,
                color: RED
            })
        }
        return ranges;
    }

    getValues = () => {

        let timeModel = this.props.ticketTimeModel;
        let totalTime = 0;
        totalTime = timeModel.closeAt - timeModel.createAt;
        let ranges: RangeConfigure[] = [];

        let start = timeModel.createAt;
        timeModel.records.map(x=>{
            
            
            if(x.start-start>0){
                let blk : RangeConfigure ={
                    value :x.start - start,
                    color :OTHER
                }
                ranges.push(blk);
            } 

            let blkSelf:RangeConfigure = {
                value : x.end-x.start,
                color: SELF
            }
            ranges.push(blkSelf);
            start = x.end;
        });
        let isOverDue = timeModel.closeAt> timeModel.expectClose;
        let longgerTime =!isOverDue?timeModel.expectClose:timeModel.closeAt;



        if(start < (longgerTime)  ){
            ranges.push(
                {
                    value : longgerTime - start,
                    color :!isOverDue?BLUE:OTHER
                }
            )
        }
        return ranges;

    }
    

    render(): React.ReactNode {
        return (
            <div className="time-line-view-container">
                
                <div className="thread-time-chart">
                    <BulletChart
                        ranges={this.getRange()}
                        values={this.getValues()}
                        target = {this.props.ticketTimeModel.expectClose - this.props.ticketTimeModel.createAt}
                    />
                </div>
            </div>)
    }
}


export default withTranslation()( TicketTimeLine);

