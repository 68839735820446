import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../ApiServer";
import { Badge, Button, Calendar, Card, Modal, PageHeader, Popover } from "antd";
import "./index.less"
import moment, { Moment } from "moment";
import zhCN from "antd/lib/calendar/locale/zh_CN";
import enUS from "antd/lib/calendar/locale/en_US";
import { RouteContext, withRouter } from "../../utils";
import { convertTaskDtoToModel, SERVICE_STATUS, TaskModel } from "./@mod";
import TaskDetailView from "./component/task-list/task-detail";


export interface ServiceLandingViewModel extends WithTranslation {
    apiServer: ApiServer
    router?: RouteContext
}

interface ServiceLandingViewState {
    countOfSubscribes: number
    countOfApproving: number
    countOfStart: number
    countOfAcceptance: number
    taskList: TaskModel[]
    todayTaskList: TaskModel[]
    showTaskDetails: boolean
    clickTask?: TaskModel
}

class ServiceLanding extends React.Component<ServiceLandingViewModel, ServiceLandingViewState>{
    constructor(props: ServiceLandingViewModel) {
        super(props);
        this.state = {
            countOfSubscribes: 0,
            countOfApproving: 0,
            countOfStart: 0,
            countOfAcceptance: 0,
            taskList: [],
            todayTaskList: [],
            showTaskDetails: false
        }
    }

    get timePattern() {
        return "YYYY-MM-DD"
    }

    componentDidMount() {
        this.loadData()
        this.setState({
            todayTaskList: this.filterDateEqualTask(moment())
        })
    }

    loadData = () => {
        this.props.apiServer.apiProjectInstance.getWorkbenchData().then(r => {
            if (r.data.data?.success) {
                let resData = r.data.data.dto;
                this.setState({
                    countOfApproving: resData?.countOfApproving ?? 0,
                    countOfStart: resData?.countOfStart ?? 0,
                    countOfAcceptance: resData?.countOfAcceptance ?? 0,
                    countOfSubscribes: resData?.countOfSubscribes ?? 0,
                    taskList: resData?.taskList?.filter((t) => t.status?.code !== SERVICE_STATUS.TERMINATED).map(t => convertTaskDtoToModel(t)) ?? []
                })
            }
        })
    }

    getDateTaskList = (date: Moment): TaskModel[] => {
        return this.filterDateEqualTask(date)
    }

    filterDateEqualTask = (date: Moment): TaskModel[] => {
        return this.state.taskList.filter(d => {
            return moment.parseZone(d.estimatedHigherEndTime).format(this.timePattern) === date.format(this.timePattern)
        })
    }

    unFinishedFirst = (list: TaskModel[]): TaskModel[] => {
        let unFinishedTaskList: TaskModel[] = []
        let finishedTaskList: TaskModel[] = []
        list.forEach(t => {
            if (SERVICE_STATUS.isUnfinished(t.status.code)) {
                unFinishedTaskList.push(t)
            } else {
                finishedTaskList.push(t)
            }
        })
        return [
            ...unFinishedTaskList,
            ...finishedTaskList
        ]
    }

    filterDateEarlyTask = (date: Moment): TaskModel[] => {
        let datePattern = date.format(this.timePattern)
        return this.state.taskList.filter(
            d => moment.parseZone(d.estimatedHigherEndTime) < date
                && moment.parseZone(d.estimatedHigherEndTime).format(this.timePattern) !== datePattern
        )
    }

    genDailyTaskView(taskList: TaskModel[]) {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                {
                    taskList
                        .map(t => {
                            let textColor: string
                            switch (t.status.code) {
                                case SERVICE_STATUS.FINISHED:
                                case SERVICE_STATUS.PENDING_ACCEPTANCE:
                                    textColor = "green"
                                    break
                                default:
                                    textColor = "red"
                            }
                            return <Button type={"link"} onClick={() => this.clickTask(t)} style={{ color: textColor }}>{t.title}</Button>
                        })
                }
            </div>
        )
    }

    dateCellRender = (moment: Moment) => {
        let taskList = this.getDateTaskList(moment);
        taskList = taskList.filter(d => d.status.code !== SERVICE_STATUS.WAITING_FOR_WHETHER_REDO)
        let notFinishedTaskList = taskList.filter(t => {
            let statusCode = t.status.code
            return SERVICE_STATUS.isUnfinished(statusCode)
        })

        let finishedTaskList = taskList.filter(t => {
            let statusCode = t.status.code;
            return !SERVICE_STATUS.isUnfinished(statusCode)
        })
        let badgeColor: string
        if (notFinishedTaskList.length > 0) {
            badgeColor = "red"
        } else if (finishedTaskList.length > 0) {
            badgeColor = "#52c41a"
        } else {
            badgeColor = "#eebd73"
        }
        taskList = [
            ...notFinishedTaskList,
            ...finishedTaskList,
        ]

        return (
            taskList.length > 0
                ? <Popover content={this.genDailyTaskView(taskList)} zIndex={100}>
                    <Badge count={taskList.length} style={{ backgroundColor: badgeColor }} />
                </Popover>
                : <></>
        );
    }

    navigateToSubscribePage = () => {
        this.props.router?.navigate("/service/subscribed")
    }

    navigateToApprovePage = () => {
        this.props.router?.navigate("/service/approve")
    }

    navigateToStartPage = () => {
        this.props.router?.navigate("/service/start")
    }


    navigateToAcceptPage = () => {
        this.props.router?.navigate("/service/accept")
    }

    clickTask = (task: TaskModel) => {
        this.setState({
            showTaskDetails: true,
            clickTask: task
        })
    }

    afterSubmitTask = () => {
        this.setState({ showTaskDetails: false })
        this.componentDidMount()
    }

    render(): React.ReactNode {
        return <>
            <div className={"work-card-container"}>
                <PageHeader title={this.props.t("project.workbench.title")}
                    subTitle={this.props.t("project.workbench.sub-title")}>
                    <div className={"work-count-card-container"}>
                        <Card
                            title={this.props.t("project.workbench.service-count-to-describe")}
                            className={"work-count-card"}
                            hoverable={false}
                        >
                            <span style={{ color: this.state.countOfSubscribes === 0 ? "green" : "red" }}>
                                {this.state.countOfSubscribes}
                            </span>
                            <div>
                                <Button type={"link"} disabled={false} onClick={this.navigateToSubscribePage} className={"to-handle-btn"}>
                                    {this.props.t("project.workbench.click-to-handle")}
                                </Button>
                            </div>
                        </Card>
                        <Card title={this.props.t("project.workbench.service-count-to-approve")}
                            className={"work-count-card"} hoverable={false}>
                            <span style={{ color: this.state.countOfApproving === 0 ? "green" : "red" }}>
                                {this.state.countOfApproving}
                            </span>

                            <div>
                                <Button type={"link"} disabled={false} onClick={this.navigateToApprovePage} className={"to-handle-btn"}>
                                    {this.props.t("project.workbench.click-to-handle")}
                                </Button>
                            </div>
                        </Card>
                        <Card title={this.props.t("project.workbench.service-count-to-start")}
                            className={"work-count-card"} hoverable={false}>
                            <span style={{ color: this.state.countOfStart === 0 ? "green" : "red" }}>
                                {this.state.countOfStart}
                            </span>

                            <div>
                                <Button type={"link"} disabled={false} onClick={this.navigateToStartPage} className={"to-handle-btn"}>
                                    {this.props.t("project.workbench.click-to-handle")}
                                </Button>
                            </div>
                        </Card>
                        <Card title={this.props.t("project.workbench.service-count-to-accept")}
                            className={"work-count-card"} hoverable={false}>
                            <span style={{ color: this.state.countOfAcceptance === 0 ? "green" : "red" }}>
                                {this.state.countOfAcceptance}
                            </span>
                            <div>
                                <Button type={"link"} disabled={false} onClick={this.navigateToAcceptPage} className={"to-handle-btn"}>
                                    {this.props.t("project.workbench.click-to-handle")}
                                </Button>
                            </div>
                        </Card>
                    </div>

                    <div className={"deadline-container"}>
                        <Card title={this.props.t("project.workbench.deadline-calendar")}
                            className={"deadline-card deadline-calendar"}>
                            <Calendar
                                locale={this.props.i18n.language === "cn" ? zhCN : enUS}
                                fullscreen={false}
                                dateCellRender={this.dateCellRender}
                            />
                        </Card>
                        <div style={{ display: "flex", flexDirection: "column" }} className={"deadline"}>
                            <Card title={this.props.t("project.workbench.deadline-today")}
                                className={"deadline-card"}>
                                <div style={{ overflow: "auto", height: "140px" }}>
                                    {
                                        this.unFinishedFirst(this.filterDateEqualTask(moment()))
                                            .map(t => {
                                                let taskStatusCode: number = Number(t.status.code)
                                                let isFinished = taskStatusCode === SERVICE_STATUS.PENDING_ACCEPTANCE
                                                    || taskStatusCode === SERVICE_STATUS.FINISHED

                                                return (<>
                                                    <Button
                                                        className={isFinished ? "finished-task" : "processing-task"}
                                                        key={t.id} type={"link"}
                                                        onClick={() => this.clickTask(t)}>
                                                        {t.title}
                                                    </Button>
                                                </>)
                                            })
                                    }
                                </div>
                            </Card>
                            <Card title={this.props.t("project.workbench.deadline-out-date")}
                                className={"deadline-card"}>
                                <div style={{ overflow: "auto", height: "140px" }}>
                                    {
                                        this.filterDateEarlyTask(moment())
                                            .filter(d => d.status.code === SERVICE_STATUS.PROCESSING || d.status.code === SERVICE_STATUS.REDOING)
                                            .map(t => <Button
                                                style={{ color: "red" }}
                                                key={t.id}
                                                type={"link"}
                                                onClick={() => this.clickTask(t)}>{t.title}
                                            </Button>)
                                    }
                                </div>
                            </Card>
                        </div>

                    </div>
                </PageHeader>
                <Modal
                    visible={this.state.showTaskDetails}
                    onCancel={() => this.setState({ showTaskDetails: false })}
                    width={1600}
                    zIndex={1000}
                    destroyOnClose={true}
                >
                    <TaskDetailView
                        navigateServicePage={true}
                        taskToShow={this.state.clickTask}
                        apiServer={this.props.apiServer}
                        afterSubmitTask={this.afterSubmitTask}
                    />
                </Modal>
            </div>
        </>
    }
}

export default withRouter(withTranslation()(ServiceLanding));