
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { mailContactColumnProvider, MailContactModel } from "../../@mod";

interface MailContactListViewModel extends WithTranslation {
    apiServer: ApiServer,
    mailContacts: MailContactModel[],
    loadingMailContact: boolean,
    onSelected: (datas: MailContactModel[]) => void,
    contactHandler: (record: MailContactModel, action: string) => void
    operation?: (record: MailContactModel) => React.ReactNode
}

interface MailContactListViewState {

}

class MailContactListView extends React.Component<MailContactListViewModel, MailContactListViewState> {
    contactHandler = (record: MailContactModel, action: string) => {
        this.props.contactHandler(record, action)
    }

    render(): React.ReactNode {
        return (
            <div>
                <WorkBenchTable<MailContactModel>
                    data={this.props.mailContacts}
                    dataLoading={this.props.loadingMailContact}
                    columnProvider={() => mailContactColumnProvider(this.props.t, this.contactHandler, this.props.operation)}
                    onSelected={this.props.onSelected}
                    rowKey={(record: MailContactModel) => { return record.id }}
                />
            </div>
        )
    }
}

export default withTranslation()(MailContactListView)