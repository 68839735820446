import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button, Card, Collapse, Form, FormInstance, Input, message, Modal, Popconfirm, Switch } from "antd";
import ApiServer from "../../../../../ApiServer";
import { WorkbenchVelocityTemplateDto } from "../../../../../api";
import "./index.less"
import TextArea from "antd/es/input/TextArea";
import FormItem from "antd/es/form/FormItem";

export interface MessageTemplateContainerModel extends WithTranslation {
    title: string,
    channelType: string,
    apiServer: ApiServer
}

interface MessageTemplateContainerState {
    channelInuseTemplates: WorkbenchVelocityTemplateDto[],
    eventTemplates: WorkbenchVelocityTemplateDto[],
    defaultActiveTemplate: string,
    isChangeTemplateVisible: boolean,
    selectedEvent: string,
    inUseTemplateId: string,
    activeEventTemplatePanel: string,
    editingMessageContent: string,
    editingMessageTemplate?: WorkbenchVelocityTemplateDto,
    isTemplateUpdateEditorVisible: boolean,
    isTemplateAddEditorVisible: boolean,
    eventNameRequired: boolean
}

class MessageTemplateContainer extends React.Component<MessageTemplateContainerModel, MessageTemplateContainerState> {
    formRef = React.createRef<FormInstance>()
    constructor(props: MessageTemplateContainerModel) {
        super(props);
        this.state = {
            channelInuseTemplates: [],
            eventTemplates: [],
            defaultActiveTemplate: "",
            isChangeTemplateVisible: false,
            selectedEvent: "",
            inUseTemplateId: "",
            activeEventTemplatePanel: "0",
            editingMessageContent: "",
            isTemplateUpdateEditorVisible: false,
            isTemplateAddEditorVisible: false,
            eventNameRequired: false
        }
    }

    fetchMessageTemplate = () => {
        this.props.apiServer.apiNotification.getNotificationTemplateList(this.props.channelType, undefined, true)
            .then(r => {
                if (r.data.data?.dtos) {
                    this.setState({
                        channelInuseTemplates: r.data.data.dtos,
                        defaultActiveTemplate: r.data.data.dtos[0].id ? r.data.data.dtos[0].id : ""
                    })
                }
            })
    }

    fetchTemplatesByChannelAndEvent = (eventType?: string) => {
        this.props.apiServer.apiNotification.getNotificationTemplateList(this.props.channelType, eventType ? eventType : this.state.selectedEvent)
            .then((r) => {
                let eventTemplates = r.data.data?.dtos
                if (eventTemplates) {
                    let inUseTemplateId = eventTemplates.filter(x => x.inUse)[0]?.id
                    inUseTemplateId = inUseTemplateId ? inUseTemplateId : ""
                    this.setState({
                        eventTemplates: eventTemplates,
                        inUseTemplateId: inUseTemplateId
                    })
                }
            });
    }

    showChangeMessageTemplate = (event, dto: WorkbenchVelocityTemplateDto) => {
        this.setState({
            isChangeTemplateVisible: true,
            selectedEvent: dto.event ? dto.event : ""
        })
        this.fetchTemplatesByChannelAndEvent(dto.event);
        this.addTemplateCallBack = this.fetchTemplatesByChannelAndEvent
        event.stopPropagation();
    };

    showUpdateTemplateEditor = (dto: WorkbenchVelocityTemplateDto) => {
        this.setState({
            isTemplateUpdateEditorVisible: true,
            editingMessageTemplate: dto,
            editingMessageContent: dto.templateContent ? dto.templateContent : ""
        })
    }

    showAddEventMessageTemplateEditor = () => {
        this.setState({
            eventNameRequired: false
        })
        this.showAddMessageTemplateEditor()
    }

    showAddEventEditor = () => {
        this.setState({
            eventNameRequired: true
        })
        this.addTemplateCallBack = this.fetchMessageTemplate
        this.showAddMessageTemplateEditor()
    }

    showAddMessageTemplateEditor = () => {
        this.formRef.current?.resetFields()
        this.setState({
            isTemplateAddEditorVisible: true
        })
    }

    genChannelTemplatePanelExtra = (x: WorkbenchVelocityTemplateDto): React.ReactNode => {
        return (
            <Button type={"primary"} onClick={(event) => this.showChangeMessageTemplate(event, x)}>
                {this.props.t("notification-setting.message-template.change-message-template")}
            </Button>
        )
    }

    genEventTemplatePanelExtra = (x: WorkbenchVelocityTemplateDto) => {
        return (
            <>
                <Button size={"small"} type={"primary"} className={"button"} shape="round"
                    onClick={() => this.showUpdateTemplateEditor(x)}>
                    {this.props.t("notification-setting.message-template.edit")}
                </Button>
                <Popconfirm
                    title={this.props.t("notification-setting.message-template.confirm-delete")}
                    onConfirm={(event) => this.deleteTemplate(x.id)}
                    onCancel={(event) => {
                        event?.stopPropagation()
                    }}
                >
                    <Button size={"small"} type={"primary"} className={"button"} shape="round" danger>{this.props.t("notification-setting.message-template.delete")}</Button>
                </Popconfirm>
                <Popconfirm
                    title={this.props.t("notification-setting.message-template.confirm-modify")}
                    onConfirm={(event) => this.changeEventMessageTemplate(event, x)}
                    onCancel={(event) => {
                        event?.stopPropagation()
                    }}
                    disabled={this.state.inUseTemplateId === x.id}
                >
                    <Switch
                        key={x.id}
                        checked={this.state.inUseTemplateId === x.id}
                        disabled={this.state.inUseTemplateId === x.id}
                    />
                </Popconfirm>
            </>

        );
    }

    genAddEventTemplateExtra = () => {
        return (
            <>
                <Button onClick={this.showAddEventEditor}>{this.props.t("notification-setting.message-template.add-message-template")}</Button>
            </>
        )
    };

    changeEventMessageTemplate = (event, dto: WorkbenchVelocityTemplateDto) => {
        if (event) {
            event.stopPropagation()
        }
        if (dto.id) {
            this.props.apiServer.apiNotification.putTemplateUsingState(dto.id)
                .then(r => {
                    if (r.data.data?.success) {
                        this.setState({
                            inUseTemplateId: dto.id ? dto.id : this.state.inUseTemplateId
                        })
                        message.success(this.props.t("notification-setting.message-template.change-template-success")).then()
                    } else {
                        message.error(this.props.t("notification-setting.message-template.change-template-fail")).then()
                    }
                })
        }
    }

    modifyTemplateContent = () => {
        this.props.apiServer.apiNotification.putNotificationTemplate(
            this.state.editingMessageTemplate?.id ? this.state.editingMessageTemplate.id : "",
            {
                template: this.state.editingMessageContent
            }
        ).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("notification-setting.message-template.modify-template-success")).then()
                this.fetchTemplatesByChannelAndEvent();
                this.fetchMessageTemplate();
            } else {
                message.error(this.props.t("notification-setting.message-template.modify-template-fail")).then()
            }
            this.setState({
                isTemplateUpdateEditorVisible: false
            })
        })
    }

    addTemplate = (values) => {
        this.props.apiServer.apiNotification.postTemplate({
            templateName: values.templateName,
            template: values.content,
            event: values.eventName ? values.eventName : this.state.selectedEvent,
            channel: this.props.channelType
        }).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("notification-setting.message-template.add-template-success")).then()
                this.addTemplateCallBack()
            } else {
                message.error(this.props.t("notification-setting.message-template.add-template-fail")).then()
            }
        })
        this.setState({
            isTemplateAddEditorVisible: false
        })
    }

    addTemplateCallBack = () => { }

    deleteTemplate = (templateName) => {
        if (templateName) {

        }
        this.props.apiServer.apiNotification.deleteTemplate(templateName).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("notification-setting.message-template.delete-template-success")).then()
                this.fetchTemplatesByChannelAndEvent()
            } else {
                message.error(this.props.t("notification-setting.message-template.delete-template-fail")).then()
            }
        })
    }

    componentDidMount() {
        this.fetchMessageTemplate()
    }

    render() {
        return (
            <>
                <Card
                    title={this.props.title} bordered={true}
                    headStyle={{ textAlign: "left" }}
                    extra={this.genAddEventTemplateExtra()}
                >
                    <Collapse accordion bordered={true} defaultActiveKey={0}>
                        {
                            this.state.channelInuseTemplates.map((x, index) => {
                                return (
                                    <Collapse.Panel
                                        header={this.props.t("notification-setting.message-template.event-name-postfix") + x.event}
                                        key={index}
                                        showArrow={false}
                                        className={"template-panel"}
                                        extra={this.genChannelTemplatePanelExtra(x)}>
                                        <div className='template-container'>
                                            {x.templateContent}
                                        </div>
                                    </Collapse.Panel>
                                );
                            })
                        }
                    </Collapse>
                </Card>
                <Modal
                    title={this.props.t("notification-setting.message-template.change-message-template-for-template-postfix") + this.state.selectedEvent}
                    visible={this.state.isChangeTemplateVisible}
                    closable={false}
                    width={1000}
                    footer={(
                        <>
                            <Button
                                onClick={this.showAddEventMessageTemplateEditor}
                                type={"primary"}
                            >
                                {this.props.t("notification-setting.message-template.add-message-template")}
                            </Button>
                            <Button onClick={() => {
                                this.setState({ isChangeTemplateVisible: false, activeEventTemplatePanel: "0" })
                            }}>
                                {this.props.t("notification-setting.message-template.close")}
                            </Button>
                        </>

                    )}
                >
                    <Collapse
                        accordion
                        bordered={true}
                        activeKey={this.state.activeEventTemplatePanel}
                        onChange={(key) => {
                            if (key) {
                                this.setState({ activeEventTemplatePanel: String(key) })
                            }

                        }}
                    >
                        {this.state.eventTemplates.map((x, index) => {
                            return (
                                <Collapse.Panel
                                    header={this.props.t("notification-setting.message-template.template-name-postfix") + x.id}
                                    key={index}
                                    showArrow={false}
                                    extra={this.genEventTemplatePanelExtra(x)}
                                >
                                    <div className='template-container'>
                                        {x.templateContent}
                                    </div>
                                </Collapse.Panel>
                            );
                        })}
                    </Collapse>
                </Modal>
                <Modal
                    title={this.props.t("notification-setting.message-template.edit") + " " + this.state.editingMessageTemplate?.id + " " + this.props.t("notification-setting.message-template.template")}
                    visible={this.state.isTemplateUpdateEditorVisible}
                    onCancel={() => this.setState({ isTemplateUpdateEditorVisible: false })}
                    onOk={this.modifyTemplateContent}
                >
                    <TextArea
                        value={this.state.editingMessageContent}
                        autoSize={true}
                        onChange={event => this.setState({ editingMessageContent: event.target.value })}
                    />
                </Modal>
                <Modal
                    title={this.props.t("notification-setting.message-template.add-message-template")}
                    visible={this.state.isTemplateAddEditorVisible}
                    onCancel={() => this.setState({ isTemplateAddEditorVisible: false })}
                    footer={null}
                    zIndex={1100}
                >
                    <Form
                        onFinish={this.addTemplate}
                        ref={this.formRef}
                    >
                        <FormItem
                            label={this.props.t("notification-setting.message-template.event-name")}
                            name={"eventName"}
                            rules={[{ required: this.state.eventNameRequired, message: this.props.t("notification-setting.message-template.input-event-name") }]}
                            hidden={!this.state.eventNameRequired}
                        >
                            <Input />
                        </FormItem>
                        <FormItem
                            label={this.props.t("notification-setting.message-template.template-name")}
                            name={"templateName"}
                            rules={[{ required: true, message: this.props.t("notification-setting.message-template.input-template-name") }]}
                        >
                            <Input />
                        </FormItem>
                        <FormItem
                            label={this.props.t("notification-setting.message-template.template-content")}
                            name={"content"}
                            rules={[{ required: true, message: this.props.t("notification-setting.message-template.input-template-content") }]}
                        >
                            <TextArea rows={10} />
                        </FormItem>
                        <FormItem>
                            <div style={{ float: "right" }}>
                                <Button htmlType={"submit"} type={"primary"} className={"button"}>{this.props.t("notification-setting.message-template.confirm")}</Button>
                                <Button
                                    onClick={() => this.setState({ isTemplateAddEditorVisible: false })}
                                    className={"button"}
                                >
                                    {this.props.t("notification-setting.message-template.cancel")}
                                </Button>
                            </div>

                        </FormItem>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default withTranslation()(MessageTemplateContainer)