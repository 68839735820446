import { Button, Select } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import { ContentSchemaModel, loadContentSchemaList } from "../../../@mod";
import './index.less'


export interface FormTemplateSelectorViewModel extends WithTranslation {
	apiServer: ApiServer
	onNewFormActed?: (schemaId: number) => void
	cancle?: () => void
}



interface FormTempalteSelectorState {
	contentSchema: ContentSchemaModel[],
}


class FormTemplateSelector extends React.Component<FormTemplateSelectorViewModel, FormTempalteSelectorState>{

	constructor(props: FormTemplateSelectorViewModel) {
		super(props)
		this.state = {
			contentSchema: []
		}
	}
	selectedId: number = 0;

	componentDidMount = async () => {
		let data = await loadContentSchemaList(this.props.apiServer)
		this.setState({
			contentSchema: data
		})
	}

	getOptions = () => {
		return this.state.contentSchema.map(x => {
			return (
				<Select.Option value={x.id} key={x.id}>{x.name}</Select.Option>
			)
		})
	}

	addFormClicked = () => {
		console.log("form clicked")
		if (this.selectedId !== 0) {
			this.props.onNewFormActed?.(this.selectedId);
		}

	}

	render(): React.ReactNode {
		return (
			<div className="form-template-selector">
				<div className="selector-control-bar">
					<Select style={{ width: "300px" }}
						showSearch
						filterOption={
							(input, option) => {
								return (option?.children?.toString().indexOf(input) ?? -1) >= 0
							}
						}
						onChange={(v) => this.selectedId = v}>
						{this.getOptions()}
					</Select>
					<Button type="primary" onClick={this.addFormClicked}>{this.props.t("tickets.add-form")}</Button>
					<Button onClick={() => this.props.cancle?.()} >{this.props.t("tickets.cancel")}</Button>
				</div>
			</div>
		)
	}
}

export default withTranslation()(FormTemplateSelector);