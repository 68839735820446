import { PageHeader } from "antd";
import moment from "moment";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { SettingItem } from "./@mod";
import './index.less'
import SettingBox, { SettingBoxOperator } from "./setting-box";


export interface SettingsPageModel extends WithTranslation {
	apiServer: ApiServer
}

interface SettingsPageState {
	items: SettingItem[]
}

class SettingsPage extends React.Component<SettingsPageModel, SettingsPageState>{

	constructor(props: SettingsPageModel) {
		super(props)
		this.state = {
			items: []
		}
	}

	loadData = async () => {
		await this.props.apiServer.apiSystem.getSystemSetting().then(
			x => {
				if (x.data.data?.succeed) {
					let si: SettingItem[] = [];
					x.data.data.configures?.forEach(
						y => {
							let i: SettingItem = {
								key: y.key ?? "",
								value: y.value ?? "",
								defaultValue: y.defaultValue ?? "",
								attribute: y.attribute
							}
							si.push(i);
						}
					)
					this.setState(
						{
							items: si
						}
					)
				}
			}
		)
	}

	l: Promise<void> | undefined
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => {
					this.l = undefined
				})

		}

	}
	setOperator = (so: SettingBoxOperator, key: string) => {

	}

	renderSettingBox = () => {
		return this.state.items.map(x => {
			return (
				<SettingBox item={x}
					onChange={(b, a) => { }}
					operator={(o) => this.setOperator(o, x.key)}
					apiServer={this.props.apiServer}
				></SettingBox>
			)
		});
	}

	render(): React.ReactNode {
		return (<PageHeader title={this.props.t("system-setting.self")}
			subTitle={this.props.t("system-setting.description")}>

			{this.renderSettingBox()}

		</PageHeader>)
	}
}


export default withTranslation()(SettingsPage);


