import { Popover } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { QuestionCircleOutlined } from "@ant-design/icons"

interface QuestionModel extends WithTranslation {
    answer: string
}

interface QuestionState {

}

class Question extends React.Component<QuestionModel, QuestionState> {

    render(): React.ReactNode {
        return (
            <Popover content={this.props.answer}>
                <QuestionCircleOutlined />
            </Popover>
        )
    }
}

export default withTranslation()(Question)