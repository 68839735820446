import './index.less';
import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import {Button, message, Modal, PageHeader, Popconfirm} from "antd";
import ApiServer from "../../../../../ApiServer";
import {UserModel} from "../@mod";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import {ActionEventHandler} from "../../../../../components";
import {ApiAccessKeyModel, columnsProvider} from "./@mod";

interface ApiAccessKeyPageModel extends WithTranslation {
    apiServer: ApiServer,
    user: UserModel
}

interface ApiAccessKeyState {
    responseOfApplyingApiAccessKey: {
        msg: string,
        accessKey: string,
        accessSecret: string
    },
    showApplyingResult: boolean,
    accessKeyList: ApiAccessKeyModel[],
    accessKeyLoading: boolean,
    currentUser: UserModel,
    selectedRows: ApiAccessKeyModel[],
    dataloading: boolean
}

class ApiAccessKey extends React.Component<ApiAccessKeyPageModel, ApiAccessKeyState> {
    constructor(props: ApiAccessKeyPageModel) {
        super(props);
        this.state = {
            responseOfApplyingApiAccessKey: {
                msg: "",
                accessKey: "",
                accessSecret: ""
            },
            showApplyingResult: false,
            accessKeyList: [],
            accessKeyLoading: false,
            currentUser: props.user,
            selectedRows: [],
            dataloading :false
        }
    }

    componentDidUpdate(prevProps: Readonly<ApiAccessKeyPageModel>, prevState: Readonly<ApiAccessKeyState>, snapshot?: any) {}

    getSnapshotBeforeUpdate(prevProps: Readonly<ApiAccessKeyPageModel>, prevState: Readonly<ApiAccessKeyState>): any {
        if (prevProps.user.key !== this.props.user.key) {
            this.loadData()
        }
        return null
    }
    componentDidMount(){
        this.loadData();
    }

    loadData() {
        
        if (this.props.user) {
            this.setState({
                dataloading:true
            })
            this.props.apiServer.apiUser.getApiAccessKeyOfUser(Number(this.props.user.key)).then(r => {
                if (r.data.data) {
                    let accessKeyList = r.data.data.dtos?.map((d): ApiAccessKeyModel => {
                        return {
                            userId: d.userId ?? 0,
                            accessKey: d.accessKey ?? "",
                            accessSecretPub: d.accessSecretPub ?? "",
                            asymmetricMethod: d.asymmetricMethod ?? "",
                            createdBy: d.createdBy ?? 0,
                            key: d.id ?? 0,
                            createdTime: d.createdTime ?? "",
                            updatedTime: d.updatedTime ?? "",
                            updatedBy:d.updatedBy ?? 0

                        }
                    })
                    this.setState({
                        accessKeyList: accessKeyList ?? [],
                        selectedRows: []
                    })
                }
            }).finally(()=>{
                this.setState({
                    dataloading :false
                })
            })
        }
    }

    applyAccessKey = () => {
        this.props.apiServer.apiUser.putApiAccessKey(Number(this.props.user.key)).then(r => {
            if (r.data.data) {
                this.setState({
                    responseOfApplyingApiAccessKey: {
                        accessSecret: r.data.data.accessSecret ?? "",
                        accessKey: r.data.data.accessKey ?? "",
                        msg: r.data.message ?? ""
                    },
                    showApplyingResult: true
                })
                return
            }
            message.error(this.props.t("api-access-key.failed-applying-access-key")).then()
        }).catch(() => message.error(this.props.t("api-access-key.failed-applying-access-key")).then());
    }

    saveAccessKey = () => {
        let FileSaver = require('file-saver')
        let data = {
            accessKey: this.state.responseOfApplyingApiAccessKey.accessKey,
            accessSecret: this.state.responseOfApplyingApiAccessKey.accessSecret,
            host : window.location.host
        }
        let content = JSON.stringify(data);
        let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, "workbench-access-key.json");
        message.success(this.props.t("api-access-key.saved-success")).then(r => {})
        this.notShowApplyingResult()
        this.loadData();
    }

    notShowApplyingResult = () => {
        this.setState({
            showApplyingResult: false
        })
    }

    onSelected = (d) => {
        this.setState({
            selectedRows: d
        })
    }

    deleteAccessKeys = () => {
        if (this.state.selectedRows?.length > 0) {
            this.props.apiServer.apiUser.deleteApiAccessKey({
                ids: this.state.selectedRows.map(d => d.key)
            }).then(r => {
                this.loadData()
            })
        }
    }

    render() {
        return (
            <div className="access-key">
                <Modal
                    title={this.props.t("api-access-key.result-of-applying-access-key")}
                    visible={this.state.showApplyingResult}
                    closable={false}
                    footer={[
                        <Button type="primary" onClick={this.saveAccessKey}>{this.props.t("api-access-key.save-to-local")}</Button>,
                    ]}
                >
                    <p>Access Key: {this.state.responseOfApplyingApiAccessKey.accessKey}</p>
                    <p>Access Secret: {this.state.responseOfApplyingApiAccessKey.accessSecret}</p>
                    <p className="important-msg">{this.state.responseOfApplyingApiAccessKey.msg}</p>
                </Modal>
                <PageHeader title={this.props.t("api-access-key.access-key-management")}>
                    <div className="button-bar">
                        <Button loading={this.state.dataloading} type="primary" onClick={this.applyAccessKey}>{this.props.t("api-access-key.apply-access-key")}</Button>
                        <Popconfirm title={this.props.t("api-access-key.confirm-delete-access-key")} onConfirm={this.deleteAccessKeys}>
                            <Button loading={this.state.dataloading} danger disabled={this.state.selectedRows.length === 0}>{this.props.t("api-access-key.delete-access-key")}</Button>
                        </Popconfirm>
                    </div>
                    <WorkBenchTable
                        data={this.state.accessKeyList}
                        dataLoading={this.state.accessKeyLoading}
                        columnProvider={(a: ActionEventHandler<ApiAccessKeyModel>) => columnsProvider(this.props.t, a)}
                        onSelected={this.onSelected}
                    />
                </PageHeader>
            </div>
        );
    }
}

export default withTranslation()(ApiAccessKey);
