import {Divider, message} from "antd";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import {convertTemplateDtoToModel, loadEmailTemplate, MailBoxModel, MailTemplateModel} from "../../@mod";
import MailTemplateList from "../template-list"
import "./index.less"
import TemplateSelector from "../../../component/template-selector";

interface BindMailTemplateViewModel extends WithTranslation {
    apiServer: ApiServer,
    mailBox: MailBoxModel
}

interface BindMailTemplateViewState {
    boundMailTemplates: MailTemplateModel[],
    allMailTemplates: MailTemplateModel[],
    showTemplate: boolean,
}

class BindMailTemplateView extends React.Component<BindMailTemplateViewModel, BindMailTemplateViewState> {
    constructor(props: BindMailTemplateViewModel) {
        super(props)
        this.state = {
            boundMailTemplates: [],
            allMailTemplates: [],
            showTemplate: false,
        }
    }

    componentDidMount(): void {
        this.loadAllTemplates()
        this.loadBoundTemplates()
    }

    loadAllTemplates = () => {
        loadEmailTemplate(false, this.props.apiServer).then(d => this.setState({ allMailTemplates: d }))
    }

    loadBoundTemplates = () => {
        this.props.apiServer.apiMail.getBoundTemplates(this.props.mailBox.id).then(x => {
            if (x.data.data?.dtos) {
                this.setState({
                    boundMailTemplates: x.data.data.dtos.map(d => convertTemplateDtoToModel(d))
                })
            }
        })
    }

    onMailTemplateAction = (record: MailTemplateModel, action: string) => {
        if (action === "unbind" && record.id) {
            this.unbindTemplate(record.id ?? -1)
        }
    }

    bindTemplate = (values) => {
        if (this.props.mailBox?.id) {
            this.props.apiServer.apiMail.postMailTemplateBinding(values.templateId, this.props.mailBox.id).then(x => {
                if (x.data.data?.success) {
                    this.loadBoundTemplates()
                    message.success(this.props.t("mails.mail-template.bind-success"))
                } else {
                    message.error(this.props.t("mails.mail-template.bind-fail"))
                }
            })
        }
    }

    unbindTemplate(templateId: number) {
        this.props.apiServer.apiMail.deleteMailTemplateBinding(templateId, this.props.mailBox.id).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("mails.mail-template.unbind-success"))
                this.loadBoundTemplates()
            } else {
                message.error(this.props.t("mails.mail-template.unbind-fail"))
            }
        })
    }

    render(): React.ReactNode {
        return (
            <div className="template-bound-container">
                <TemplateSelector
                    apiServer={this.props.apiServer}
                    selected={this.bindTemplate}
                    templates={this.state.allMailTemplates}
                    btnText={this.props.t("mails.mail-template.binding")}
                />
                <Divider children={this.props.t("mails.mail-template.bound-templates")}/>
                <MailTemplateList
                    apiServer={this.props.apiServer}
                    templates={this.state.boundMailTemplates}
                    onMailTemplateAction={this.onMailTemplateAction}
                    unbindOperationBtn
                />
            </div>
        )
    }

}

export default withTranslation()(BindMailTemplateView)