import { Button, Input, notification, Popover } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import './index.less';

export interface ApprovalButtonViewModel extends WithTranslation {
	onSubmit: (message: string) => void
	defaultMessage: string
	displayText: string,
	type: "primary" | "default"
	danger?: boolean
}

interface ApprovalButtonViewState {
	textBoxVisable: boolean,
	message: string
}


class ApprovalButton extends React.Component<ApprovalButtonViewModel, ApprovalButtonViewState>{

	constructor(props: ApprovalButtonViewModel) {
		super(props)
		this.state = {
			message: this.props.defaultMessage,
			textBoxVisable: false
		}
	}

	onSubmit = () => {
		if(this.state.message===""){
			notification.warn({message:this.props.t("auto.message-required")});
			return;
		}
		this.props.onSubmit(this.state.message);
		
		this.setState({
			message: "",
			textBoxVisable: false
		})
	}

	getContent = () => {
		return (<div className="approval-message">
			<Input required={this.state.message===""} placeholder={this.props.t("auto.approval-message")} value={this.state.message} onChange={(v) => this.setState({ message: v.target.value })} style={{ minWidth: "250" }} ></Input>
			<div className="approval-button">
				<Button onClick={this.onSubmit} danger={this.props.danger} type={this.props.type} >{this.props.displayText}</Button>
				<Button onClick={() => { this.setState({ textBoxVisable: false }) }} danger>{this.props.t("auto.cancle")}</Button>
			</div>
		</div>)
	}

	render(): React.ReactNode {
		return (<div>
			<Popover content={this.getContent()} visible={this.state.textBoxVisable} >
				<Button onClick={() => this.setState({ textBoxVisable: !this.state.textBoxVisable })} danger={this.props.danger} type={this.props.type} >{this.props.displayText}</Button>
			</Popover>
		</div>)
	}
}


export default withTranslation()(ApprovalButton)