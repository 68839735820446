import { Avatar, Button, message, PageHeader, Popover, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { TagModel } from "./@mod";

import './index.less'
import TagEditor from "./tag-editor";

interface TagsPageModel extends WithTranslation {
	apiServer: ApiServer
}

interface TagsPageState {
	tags: TagModel[],
	editorVisiable: boolean
}

class TagsPage extends React.Component<TagsPageModel, TagsPageState>{

	constructor(props: TagsPageModel) {
		super(props)
		this.state = {
			tags: [],
			editorVisiable: false
		}
	}

	async loadData() {
		this.props.apiServer.apiProductivity.getTagList()
			.then(
				x => {
					this.setState(
						{
							tags: x.data.data?.tagDtoList?.map(
								q => {
									let t: TagModel = {
										id: q.id ?? 0,
										name: q.name ?? "",
										color: q.color ?? "#000000",
										created: q.createdTime ?? "",
										updated: q.updatedTime ?? ""
									}
									return t;
								}
							) ?? []
						}
					)
				}
			);
	}
	l: Promise<void> | undefined;

	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(
					() => {

					}
				)
		}

	}
	newTag = (name: string, color: string) => {
		if (name && name!=="") {
			this.setState({
				editorVisiable: false
			})
			this.props.apiServer.apiProductivity.createNewTag({
				name: name,
				color: color
			}).finally(
				() => {
					this.loadData();
				}
			)
		}else{
			message.error(this.props.t("tag.name-empty"))
		}

	}

	closeEditor = () => {
		this.setState({
			editorVisiable: false
		})
	}

	render(): React.ReactNode {
		return (<div className="tag-container site-drawer-render-in-current-wrapper" id="user-container" >
			<PageHeader title={this.props.t("tag.self")}
				subTitle={this.props.t("tag.description")}
			>
				<div className="control">
					<Popover content={<TagEditor done={this.newTag} cancel={this.closeEditor} />} visible={this.state.editorVisiable}>
						<Button type="primary" onClick={() => this.setState({ editorVisiable: !this.state.editorVisiable })}>{this.props.t("tag.new-tag")}</Button>
					</Popover>
				</div>
				<div className="tags">
					{this.renderTag()}
				</div>
			</PageHeader>
		</div>)
	}

	renderTag = () => {
		return this.state.tags.map(
			(x:TagModel) =>
				<div key={x.id}>
					<Tooltip title={
						<div className="tooltip">
							<div>{this.props.t("tag.name")}:{x.name}</div>
							<div>{this.props.t("tag.created")}:{moment.parseZone(x.created).format(this.props.t("time-pattern-long"))}</div>
							<div>{this.props.t("tag.updated")}:{moment.parseZone(x.updated).format(this.props.t("time-pattern-long"))}</div>
						</div>
					} >
						<div className="tag-display">
							<div>{x.name}</div>
							<Avatar key={x.id} style={{ backgroundColor: x.color, verticalAlign: 'middle' }} size="large">
							</Avatar>
						</div>
					</Tooltip>
				</div>
		)

	}
}




export default withTranslation()(TagsPage);