import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import React from "react";
import { ActionEventHandler } from "../../../../../components";
import ColumnTextFilter from "../../../../../components/table/column-text-filter";
import ActiveTag from "../../../members/users/@mod/active-tag";

export interface PrivilegeModel{
	key:string
	id:number,
	name:string,
	verb:string,
	path:string,
	enabled:boolean
}

export const columnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<PrivilegeModel>): ColumnType<PrivilegeModel>[] => {
	
	return [
		{
			title: translater("privilege-editor.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string,record:PrivilegeModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:PrivilegeModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("privilege-editor.name")}/>)
			},
		},

		{
			title: translater("privilege-editor.path"),
			dataIndex: 'path',
			key: 'path',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:PrivilegeModel) => record.path.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("privilege-editor.path")}/>)
			},
		},{
			title: translater("privilege-editor.verb"),
			dataIndex: 'verb',
			key: 'verb'
		},
		{
			title: translater("privilege-editor.enabled"),
			dataIndex: 'enabled',
			key: 'enabled',
			render: (a: any) => (<ActiveTag isActive={a}></ActiveTag>)
			
		},
	];
}