import { Avatar, Button, Checkbox, List, notification, Popover, Select, Steps } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import { DataModel, FormSchema, renderApproval, renderAssignee, renderForm } from "./../@mod";
import './index.less'
import NewFormEditor from "./../new-form-editor";
import { v4 as uuidv4 } from 'uuid';
import NewApprovalEditor from "./../new-approval-editor";
import NewAssigneeEditor from "../new-assignee-editor";


export interface ProcessConfigViewModel extends WithTranslation {
	apiServer: ApiServer,
	process: any

}

interface ProcessConfigViewState {
	forms: FormSchema[]
	process: any,
	newFormEditorVisiable: boolean
	newApprovalEditorVisable: boolean,
	newAssigneeEditorVisable: boolean
}


class ProcessConfigView extends React.Component<ProcessConfigViewModel, ProcessConfigViewState>{

	constructor(props: ProcessConfigViewModel) {
		super(props)
		this.state = {
			forms: [],
			process: this.props.process,
			newFormEditorVisiable: false,
			newApprovalEditorVisable: false,
			newAssigneeEditorVisable: false
		}
	}

	l: Promise<void> | undefined

	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => this.l = undefined);
		}
	}

	getSnapshotBeforeUpdate(propsBefore: ProcessConfigViewModel, stateBefore: ProcessConfigViewState) {
		if (propsBefore.process.flows.length !== this.props.process.flows.length) {
			this.setState({
				process: this.props.process
			})
		}
		return null;
	}
	componentDidUpdate() {

	}


	loadData = async () => {
		///TODO:merge the same schema to one request;
		var forms = await Promise.all(this.state.process.forms.map(f =>
			this.props.apiServer.apiMeta
				.getContentSchemaById(f.schema)
				.then(x => {
					var schema = x.data.data?.contentSchema?.schema ?? "";
					var uiSchema = x.data.data?.contentSchema?.uiSchema ?? ""
					var name = x.data.data?.contentSchema?.name ?? "";
					var desc = x.data.data?.contentSchema?.description ?? "";
					let formSchema: FormSchema = {
						schema: schema,
						uiSchema: uiSchema,
						name: name,
						desc: desc,
						refName: f.name,
						def: f
					}
					return formSchema;
				})));
		this.setState({
			forms: forms
		})
	}
	renderApprovalOptions = () => {
		return this.state.process.approval.map(x =>
		(
			<Select.Option key={x.name} value={x.name}>{x.displayName}</Select.Option >
		));
	}

	renderAssigneeOptions = () => {
		if(this.state.process.assignee){
			return this.state.process.assignee.map(x =>
			(
				<Select.Option key={x.name} value={x.name}>{x.displayName}</Select.Option >
			));
		}
		return <></>
	}

	


	renderFormOptions = () => {
		return this.state.forms.map(x =>
		(
			<Select.Option key={x.def.name} value={x.def.name}>{x.def.displayName}</Select.Option >
		));

	}

	stageFormSelected = (stage: number, newSelected: string) => {
		let process = this.state.process;
		let stg_edit = process.flows.filter(x => x.stage === stage);
		if (stg_edit.length > 0) {
			stg_edit[0].required.ref = newSelected;
			this.setState({
				process: process
			})
		}
	}
	stageApprovalSelected = (stage: number, newSelected: string) => {
		let process = this.state.process;
		let stg_edit = process.flows.filter(x => x.stage === stage);
		if (stg_edit.length > 0) {
			stg_edit[0].required.ref = newSelected;
			this.setState({
				process: process
			})
		}
	}

	stageAssigneeSelected = (stage: number, newSelected: string) => {
		let process = this.state.process;
		let stg_edit = process.flows.filter(x => x.stage === stage);
		if (stg_edit.length > 0) {
			stg_edit[0].required.ref = newSelected;
			this.setState({
				process: process
			})
		}
	}

	renderConfigures = (stage: any) => {
		let selected = stage.required.ownership;
		let type: string[] = ["owner", "hands"];
		return (
			<div>
				<div className="ownership">
					{type.map(x => {
						let isSelected: boolean = selected.filter(y => y === x).length === 1;
						return (
							<Checkbox checked={isSelected} onClick={
								() => {
									if (isSelected) {
										stage.required.ownership = stage.required.ownership.filter(e => e !== x);
									} else {
										stage.required.ownership.push(x);
									}
									this.setState({
										process: this.state.process
									})
								}
							} key={x}>{this.props.t("auto." + x)}</Checkbox>

						)
					})}
				</div>
				{
					stage.required.type === "form" &&
					<div className="form-selector">
						<Select style={{ minWidth: "200px" }} value={stage.required.ref}
							onChange={(v) => { this.stageFormSelected(stage.stage, v) }}
						>
							{this.renderFormOptions()}
						</Select>
					</div>
				}

				{
					stage.required.type === "approval" &&
					<div className="form-selector">
						<Select style={{ minWidth: "200px" }} value={stage.required.ref}
							onChange={(v) => { this.stageApprovalSelected(stage.stage, v) }}
						>
							{this.renderApprovalOptions()}
						</Select>
					</div>
				}

				{
					stage.required.type === "assignee" &&
					<div className="form-selector">
						<Select style={{ minWidth: "200px" }} value={stage.required.ref}
							onChange={(v) => { this.stageAssigneeSelected(stage.stage, v) }}
						>
							{this.renderAssigneeOptions()}
						</Select>
					</div>
				}

			</div>
		)
	}


	get Steps() {
		return this.state.process.flows.map(x => {
			return <Steps.Step key={x.stage} title={x.name} />
		})
	}


	renderListItem = (item: any) => {
		return (<List.Item key={item.stage}>
			<div className="stage-container" >
				<div className="stage-left">
					<Avatar>{item.stage}</Avatar>
				</div>
				<div></div>
				<div className="stage-right">
					<div className="stage-topic">
						{item.name}
					</div>
					<div className="stage-ref">
						{item.required.type === "form" && renderForm(this.state.forms, item.required.ref)}
						{item.required.type === "approval" && renderApproval(this.state.process.approval, item.required.ref, this.state.process.flows)}
						{item.required.type === "assignee" && renderAssignee(this.state.process.assignee, item.required.ref, this.state.process.flows)}
					</div>
					<div className="stage-selection">
						{(item.required.type === "form" 
							|| item.required.type === "approval"
							|| item.required.type === "assignee")
							&& this.renderConfigures(item)}
					</div>
				</div>
			</div>
		</List.Item>)
	}

	renderRefFormItem = (f: FormSchema) => {
		return (
			<div className="editor-form-ref-container">
				<div className="ref-form-title label">
					<div style={{ fontWeight: "bold" }}>{f.def.displayName}</div>
					<div style={{ color: "grey" }}>{this.props.t("auto.ref-template")}:{f.name}</div>
				</div>
				<div className="display-at">
					<div className="label">
						{this.props.t("auto.visiable-at-state")}
					</div>
					<div className="select">
						<Select mode="tags"
							style={{ minWidth: "200px" }}
							value={f.def.displayAt}
							onChange={v => {
								f.def.displayAt = v;
								this.setState({ process: this.state.process });
							}}
						>
							{this.state.process.flows.map(x =>
								<Select.Option key={x.stage} value={x.stage}>{x.name}</Select.Option>)}
						</Select>
					</div>
				</div>
				<div className="editable-at">
					<div className="label">
						{this.props.t("auto.editable-at-state")}
					</div>
					<div className="select">
						<Select mode="tags"
							style={{ minWidth: "200px" }}
							value={f.def.editableAt}
							onChange={v => {
								f.def.editableAt = v;
								this.setState({ process: this.state.process });
							}}
						>
							{this.state.process.flows.map(x =>
								<Select.Option key={x.stage} value={x.stage}>{x.name}</Select.Option>)}
						</Select>
					</div>
				</div>
				<div className="editable-at" style={{ display: "none" }}>
					<Button type="primary" shape="round" danger onClick={
						() => {
							let original = this.state.forms;
							// it should be pointer compare
							// eslint-disable-next-line eqeqeq
							let target = original.filter(y => y != f);
							original.length = 0;
							target.forEach(x => original.push(x));
							let flows = this.state.process.flows;
							flows.forEach(flow => {
								if (flow.required.ref === f.name) {
									flow.required.ref = ""
								}
							})

							let originalf = this.state.process.forms;
							// it should be pointer compare
							// eslint-disable-next-line eqeqeq
							let targetf = originalf.filter(y => y != f.def);
							originalf.length = 0;
							targetf.forEach((x: any) => originalf.push(x));

							this.setState({
								process: this.state.process
							})
							this.loadData();
						}
					} >
						{this.props.t("auto.delete")}
					</Button>
				</div>
			</div>
		)
	}

	renderApprovalItem = (f: any) => {

		return (
			<div className="editor-form-ref-container">
				<div className="ref-form-title label">
					{f.displayName}</div>
				<div className="editable-at">
					<div className="label">
						{this.props.t("auto.visiable-at-state")}
					</div>
					<div className="select">
						<Select mode="tags"
							style={{ minWidth: "200px" }}
							value={f.displayAt}
							onChange={(v) => {
								console.log(v)
								f.displayAt = v
								this.setState({ process: this.state.process })
							}}>
							{this.state.process.flows.map(x =>
								<Select.Option key={x.stage} value={x.stage}>{x.name}</Select.Option>)}
						</Select>
					</div>
				</div>
				<div className="editable-at">
					<div className="label">
						{this.props.t("auto.editable-at-state")}
					</div>
					<div className="select">
						<Select mode="tags"
							style={{ minWidth: "200px" }}
							value={f.editableAt}
							onChange={(v) => {
								f.editableAt = v
								this.setState({ process: this.state.process })
							}}>
							{this.state.process.flows.map(x =>
								<Select.Option key={x.stage} value={x.stage}>{x.name}</Select.Option>)}
						</Select>
					</div>
				</div>
				<div className="editable-at">
					<div className="label">
						{this.props.t("auto.reject-to-state")}
					</div>
					<div className="select">
						<Select mode="tags"
							style={{ minWidth: "200px" }}
							value={f.reject}
							onChange={(v) => {
								f.reject = v
								this.setState({ process: this.state.process })
							}}>
							{this.state.process.flows.map(x =>
								<Select.Option key={x.stage} value={x.stage}>{x.name}
								</Select.Option>)}
						</Select>
					</div>
				</div>
			</div>
		)
	}
	addNewForm = (schemaId: number, name: string) => {
		console.log(schemaId)
		let proc = this.state.process;
		proc.forms.push({
			"displayName": name,
			"name": uuidv4(),
			"schema": schemaId,
			"displayAt": [],
			"editableAt": []
		});
		this.setState({
			process: proc,
			newFormEditorVisiable: false
		});
		this.loadData()
	}

	addNewApproval = (name: string) => {
		if (name === "") {
			notification.error({ message: this.props.t("auto.add-approval-fail") })
			return;
		}
		let proc = this.state.process;
		proc.approval.push({
			"displayName": name,
			"name": uuidv4(),
			"displayAt": [],
			"editableAt": [],
			"reject": [1]
		});
		this.setState({
			process: proc,
			newApprovalEditorVisable: false
		});
	}

	renderApprovalList = () => {
		return (
			<List header={

				<div>
					<h1>{this.props.t("auto.ref-approval")}</h1>
					<Popover destroyTooltipOnHide={true} trigger="click" placement="bottom"
						visible={this.state.newApprovalEditorVisable}
						content={
							<NewApprovalEditor
								onNewApprovalActed={this.addNewApproval}
								cancle={() => this.setState({ newApprovalEditorVisable: false })}
							></NewApprovalEditor>
						} >
						<Button type="primary"
							onClick={() => this.setState({ newApprovalEditorVisable: !this.state.newApprovalEditorVisable })}
						>{this.props.t("auto.new-ref-approval")}</Button>
					</Popover>

				</div>}
				dataSource={this.state.process.approval}
				renderItem={this.renderApprovalItem}>
			</List>
		)

	}

	renderFormList = () => {

		return (
			<List header={
				<div>
					<h1>{this.props.t("auto.ref-forms")}</h1>
					<Popover trigger="click" placement="bottom"
						destroyTooltipOnHide={true}
						visible={this.state.newFormEditorVisiable}
						content={
							<NewFormEditor
								apiServer={this.props.apiServer}
								onNewFormActed={this.addNewForm}
								cancle={() => this.setState({ newFormEditorVisiable: false })}
							></NewFormEditor>
						} >
						<Button type="primary"
							onClick={() => this.setState({ newFormEditorVisiable: !this.state.newFormEditorVisiable })}
						>{this.props.t("auto.new-ref-forms")}</Button>
					</Popover>
				</div>}
				dataSource={this.state.forms}
				renderItem={this.renderRefFormItem}
			>
			</List>
		)

	}

	addNewAssigneeRef = (name) => {
		if (name === "") {
			notification.error({ message: this.props.t("auto.add-assignee-fail") })
			return;
		}
		let proc = this.state.process;
		if (!proc.assignee) {
			proc.assignee = [];

		}
		proc.assignee.push({
			"displayName": name,
			"name": uuidv4(),
			"displayAt": [],
			"editableAt": []
		});
		this.setState({
			process: proc,
			newAssigneeEditorVisable: false
		});
	}

	renderAssigneeItem = (f: any) => {
		return (<div className="editor-form-ref-container">
			<div className="ref-form-title label">
				{f.displayName}</div>
			<div className="editable-at">
				<div className="label">
					{this.props.t("auto.visiable-at-state")}
				</div>
				<div className="select">
					<Select mode="tags"
						style={{ minWidth: "200px" }}
						value={f.displayAt}
						onChange={(v) => {
							f.displayAt = v
							this.setState({ process: this.state.process })
						}}>
						{this.state.process.flows.map(x =>
							<Select.Option key={x.stage} value={x.stage}>{x.name}</Select.Option>)}
					</Select>
				</div>
			</div>
			<div className="editable-at">
				<div className="label">
					{this.props.t("auto.editable-at-state")}
				</div>
				<div className="select">
					<Select mode="tags"
						style={{ minWidth: "200px" }}
						value={f.editableAt}
						onChange={(v) => {
							f.editableAt = v
							this.setState({ process: this.state.process })
						}}>
						{this.state.process.flows.map(x =>
							<Select.Option key={x.stage} value={x.stage}>{x.name}</Select.Option>)}
					</Select>
				</div>
			</div>
		</div>)
	}

	renderAssigneeList = () => {
		return (
			<List header={
				<div>
					<h1>{this.props.t("auto.ref-assignee")}</h1>
					<Popover destroyTooltipOnHide={true} trigger="click" placement="bottom"
						visible={this.state.newAssigneeEditorVisable}
						content={
							<NewAssigneeEditor
								onNewAssigneeRefActed={this.addNewAssigneeRef}
								cancle={() => this.setState({ newAssigneeEditorVisable: false })}
							></NewAssigneeEditor>
						} >
						<Button type="primary"
							onClick={() => this.setState({ newAssigneeEditorVisable: !this.state.newAssigneeEditorVisable })}
						>{this.props.t("auto.new-ref-assignee")}</Button>
					</Popover>

				</div>}
				dataSource={this.state.process.assignee??[]}
				renderItem={this.renderAssigneeItem}>
			</List>
		);

	}

	dataRefTypeChanged = (d: any, v: any) => {
		d.type = v;
		this.setState({ process: this.state.process })
	}


	renderDataRefItem = (d: any) => {
		let dataRef: DataModel = d;
		return (<List.Item>
			<div className="editor-data-ref-item">
				<div className="configure-item label">
					{this.props.t("auto.ref-data-type")}
				</div>
				<div className="configure-item">
					<Select value={dataRef.type} onChange={(v) => this.dataRefTypeChanged(d, v)} style={{ width: 100 }} >
						<Select.Option value="sku">SKU</Select.Option>
						<Select.Option value="sku-detail">{this.props.t("auto.sku-detail")}</Select.Option>
						<Select.Option value="account">{this.props.t("auto.account-info")}</Select.Option>
						<Select.Option value="asin-info">ASIN</Select.Option>
						
					</Select>
				</div>
				<div className="configure-item  label">
					{this.props.t("auto.visiable-at-state")}
				</div>
				<div className="configure-item">
					<Select mode="tags"
						style={{ minWidth: "400px" }}
						value={dataRef.displayAt}
						onChange={v => {
							dataRef.displayAt = v;
							this.setState({ process: this.state.process });
						}}>
						{this.state.process.flows.map(x =>
							<Select.Option key={x.stage} value={x.stage}>{x.name}</Select.Option>)}
					</Select>
				</div>
				<div className="configure-item">
					<Button type="primary" shape="round" danger onClick={
						() => {

							let original = this.state.process.data;
							// it should be pointer compare
							// eslint-disable-next-line eqeqeq
							let target = original.filter(y => y != d);
							original.length = 0;
							target.forEach(x => original.push(x));

							this.setState({
								process: this.state.process
							})
						}
					} >
						{this.props.t("auto.delete")}
					</Button>
				</div>
			</div>
		</List.Item>)
	}
	addDataRef = () => {
		let process = this.state.process;

		process.data.push({
			type: "sku",
			displayAt: [1]
		});
		this.setState({ process: process });
	}


	renderDataRef = () => {
		return (
			<List
				header={
					<div>
						<h1>{this.props.t("auto.ref-data")}</h1>
						<Button type="primary"
							onClick={this.addDataRef}>
							{this.props.t("auto.new-ref-data")}
						</Button>
					</div>
				}
				dataSource={this.state.process.data}
				renderItem={this.renderDataRefItem}
			>

			</List>
		)
	}

	render(): React.ReactNode {

		return (
			<div className="process-editor-container">
				<div className="step-summary">
					<Steps>
						{this.Steps}
					</Steps>
				</div>
				<div className="editor-ref">
					<div className="editor-data-ref" >
						{this.renderDataRef()}
					</div>
					<div className="editor-form-ref">
						{this.renderFormList()}
					</div>
					<div className="editor-approval-ref">
						{this.renderApprovalList()}
					</div>
					<div className="editor-assignee-ref">
						{this.renderAssigneeList()}
					</div>
				</div>
				<div className="editor-steps">
					<List
						dataSource={this.state.process.flows}
						renderItem={this.renderListItem}
					>
					</List>
				</div>
			</div>)
	}
}


export default withTranslation()(ProcessConfigView);