import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ActionEventHandler } from "../../../components";
import WorkBenchTable from "../../../components/table/workbench-table";
import { TicketModel,columnsProvider, CoreStatusMetas } from "../@mod";


export interface TicketListModel extends WithTranslation {
	tickets:TicketModel[],
	dataLoading: boolean,
	ondetail?:(d:TicketModel)=>void,
	corestatus? : CoreStatusMetas,
	onSelected?:(t:TicketModel[]) => void,
	skippedColumn?:string[]
}

interface TicketListState {
}


class TicketList extends React.Component<TicketListModel,TicketListState>{
	constructor(props:TicketListModel){
		super(props)
		this.state = {

		}
	}

	sortedData = ()=>{
		return this.props.tickets.sort((x,y)=>y.id-x.id)??[];
	}


	render(): React.ReactNode {
		return (<WorkBenchTable<TicketModel>
			data={this.sortedData()}
			dataLoading={this.props.dataLoading}
			onDetail={this.props.ondetail}
			columnProvider={(a:ActionEventHandler<TicketModel>)=>columnsProvider(this.props,a,this.props.tickets,this.props.corestatus,this.props.skippedColumn)}
			onSelected={this.props.onSelected}
			/>)
	}
}

export default withTranslation()(TicketList);