import {Button, Tag} from "antd";
import {ColumnType} from "antd/lib/table";
import moment from "moment";
import {ReactNode} from "react";
import {MailBoxDto, MailSendingDto, MailVendorDto, WorkbenchMailBoxContactDto} from "../../../api";
import ApiServer from "../../../ApiServer";
import {ActionEventHandler} from "../../../components";
import ColumnDateRangeFilter from "../../../components/table/column-date-range-filter";
import ColumnTextFilter from "../../../components/table/column-text-filter";
import {RsaProvider} from "../../../utils/cypto"
import {baseDecode} from "../../../utils/cypto/utils";
import {CheckCircleOutlined} from "@ant-design/icons";
import MailBoxProgress from "../component/progress"

export const SECURITY_DOMAIN = "mail-credential-transport";
const rsa = new RsaProvider();


export interface MailModel {
    key?: string,
    id: number,
    syncDate: string,
    mailDate: string,
    mailId: string,
    subject: string,
    size: number,
    recvCost: number,
    folder: string,
    externInfo: any, //邮件元数据
    mailBoxId: number
}


export interface MailSendModel {
    id: number,
    reviewer?: UserModel,
    content: MailEditingContentModel,
    box?: MailBoxModel,
    sendStatus: string,
    sendStatusDesc: any,
    mailDescription: string,
    creater?: UserModel,
    updater?: UserModel,
    createdAt?: string,
    updatedAt?: string

}

export interface MailEditingContentModel {
    to: string[],
    cc: string[],
    bcc: string[],
    split: boolean,
    subject: string,
    body: string,
    cid: string[],
    attachment: string[]
}


export interface UserModel {
    key: string,
    name: string,
    fullName: string,
    source: string
    organization: string,
    phone: string,
    email: string
    active: boolean,
    created: string,
    updated: string
}

export interface RemoteInstanceModel {
    id: number,
    type: string,
    name: string,
    identifier: string,
    status: string,
    publicIp: string,
    accessIp: string,
    tags: any
}



export interface MailVendorModel {
    key?: number,
    id: number,
    vendorName: string
    creator: string,
    updateter: string,

    created: string,
    updated: string,

    receiveHost: string,
    receivePort: number,
    receiveAuth: boolean,
    ssl: boolean,
    receiveProtocol: string,

    sendProtocol: string,
    sendAuth: boolean;
    sendPort: number,
    sendHost: string,
    startSsl: boolean
}

export interface MailBoxModel {
    key?: number,
    id: number,
    emailAddress: string,
    cred: string,
    description: any,
    creator: string,
    updateter: string,
    created: string,
    updated: string,
    displayCred: boolean,
    accessModel?: any,
    vendor?: MailVendorModel,
    tags?: TagModel[],
    deployTo: string[],
    isVerifing?: boolean,
    successInstance?: number[],
    failInstance?: number[]
}

export interface AccessModel {

    'canRead'?: boolean;
    'canSend'?: boolean;
    'readLimit'?: number;
    'sendLimit'?: number;
    'user'?: UserModel,
    "box": any,
    'changed'?: boolean,
    'checked'?: boolean,
    'canApprove'?: boolean,

}

export interface StateModel {
    total: number,
    completed: number,
    failed: number,
    state: any,
    stateName: string

}

export interface MailContactModel {
    id: number,
    emailAddress: string,
    nickname: string
}

export interface BindingContactModel {
    to: MailContactModel[],
    cc: MailContactModel[],
    bcc: MailContactModel[],

}

export interface MailTemplateModel {
    id: number,
    title: string
}

export const convertMailContactDtoModel = (dto: WorkbenchMailBoxContactDto): MailContactModel => {
    return {
        id: dto.emailContact?.id ?? -1,
        emailAddress: dto.emailContact?.emailAddress ?? "",
        nickname: dto.emailContact?.nickname ?? ""
    }
}

export const convertMailSendDtoToModel = (sendDto: MailSendingDto) => {
    var sendModel: MailSendModel = {
        id: sendDto.id ?? 0,
        content: {
            to: sendDto.detail?.to ?? [],
            bcc: sendDto.detail?.bcc ?? [],
            cc: sendDto.detail?.cc ?? [],
            split: sendDto.detail?.split ?? false,
            subject: sendDto.detail?.subject ?? (sendDto.subject ?? ""),
            body: sendDto.detail?.body ?? "",
            cid: sendDto.detail?.cid ?? [],
            attachment: sendDto.detail?.attachments ?? []
        },
        box: sendDto.mailBox ? convertMailBoxDtoToModel(sendDto.mailBox) : undefined,
        sendStatus: sendDto.sendStatus ?? "draft",
        sendStatusDesc: sendDto.sendStatusDescription,
        mailDescription: sendDto.description ?? "",
        creater: userDtoToModel(sendDto.creator),
        updater: userDtoToModel(sendDto.updater),
        updatedAt: sendDto.updatedAt,
        createdAt: sendDto.createdAt,
        reviewer: userDtoToModel(sendDto.reviewer)

    }
    return sendModel;
}


export const loadEmailBoxState = async function (apiServer: ApiServer, id: number): Promise<StateModel | undefined> {

    return apiServer.apiMail.getMailBoxState(id)
        .then(x => {
            if (x.data.data?.success) {
                var s = x.data.data.state;
                let state: StateModel = {
                    total: s?.total ?? 0,
                    failed: s?.failed ?? 0,
                    completed: s?.completed ?? 0,
                    state: s?.stateDescription,
                    stateName: s?.stateName ?? ""
                }
                return state
            }
        });

}

export const loadEmails = async function (apiServer: ApiServer, id: number, startDate, endDate): Promise<MailModel[]> {

    return await apiServer.apiMail.getMails(id, startDate, endDate)
        .then(x => {
            let result: MailModel[] = [];
            if (x.data.data?.success) {
                var mails = x.data.data.mails;
                mails?.forEach(m => {
                    let model: MailModel = {
                        key: m.mailId,
                        syncDate: m.syncDate ?? "",
                        mailDate: m.mailDate ?? "",
                        mailId: m.mailId ?? "",
                        subject: m.subject ?? "",
                        size: m.size ?? 0,
                        recvCost: m.recvCost ?? 0,
                        folder: m.folder ?? "",
                        externInfo: m.externInfo,
                        id: m.id ?? 0,
                        mailBoxId: m.mailBox?.id ?? 0
                    }
                    result.push(model);
                })


            }

            return result;
        });
}


export const loadEmailBoxAccess = async function (apiServer: ApiServer, id: number): Promise<AccessModel[]> {
    return await apiServer.apiMail.getMailGrantedAccess(id)
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.access?.map(q => {
                    let l: AccessModel = {
                        canRead: q.canRead,
                        canSend: q.canSend,
                        canApprove: q.canApprove,
                        readLimit: q.readLimit,
                        sendLimit: q.sendLimit,
                        user: userDtoToModel(q.user),
                        box: {}
                    };
                    return l;
                }) ?? []
            }
            return [];
        })



}

function userDtoToModel(u): UserModel {

    return {
        key: (u.id ?? 0).toString(),
        name: u.name ?? "",
        fullName: u.fullName ?? "",
        source: (u.source ?? 0).toString(),
        organization: u.organization ?? "",
        phone: u.phone ?? "",
        email: u.email ?? "",
        active: u.active ?? false,
        created: u.createdTime ?? "1654732800000",
        updated: u.updatedTime ?? "1654732800000"
    }
}

export const loadUsers = async function (apiServer: ApiServer): Promise<UserModel[]> {
    return await apiServer.apiUser.getUserList()
        .then((x) => {
            return x.data.data?.list?.map(userDtoToModel) ?? []
        })
}


export const saveAccessChange = async function (apiServer: ApiServer, a: AccessModel, mailBoxId: number): Promise<boolean> {
    return await apiServer.apiMail.postMailBoxAccess(mailBoxId, {
        access: [
            {
                canRead: a.canRead,
                canSend: a.canSend,
                canApprove: a.canApprove,
                readLimit: a.readLimit,
                sendLimit: a.sendLimit,
                userId: Number(a.user?.key ?? 0)
            }
        ]
    })
        .then(x => {
            return x.data.data?.success ?? false;
        })
}



export const loadActiveMailInstances = function (apiServer: ApiServer): Promise<RemoteInstanceModel[]> {

    return apiServer.apiCluster.getRemoteInstances("normal-active")
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.remoteInstances?.filter(q => q.type === "mail").map(i => {
                    let r: RemoteInstanceModel = {
                        id: i.id ?? 0,
                        type: i.type ?? "",
                        name: i.name ?? "",
                        identifier: i.identifier ?? "",
                        status: i.status ?? "",
                        publicIp: i.publicIp ?? "",
                        accessIp: i.accessIp ?? "",
                        tags: i.tags
                    }
                    return r;
                }) ?? [];
            }
            return []
        });
}

export const convertMailBoxDtoToModel = (y: MailBoxDto) => {
    let m: MailBoxModel = {
        key: y.id,
        id: y.id ?? 0,
        emailAddress: y.emailAddress ?? "",
        description: y.description,
        cred: y.credential ?? "",
        creator: y.creator?.fullName ?? "",
        updateter: (y.updater?.fullName ?? "") + ("(@" + y.updater?.name + ")"),
        created: y.createdAt ?? "",
        updated: y.updatedAt ?? "",
        displayCred: false,
        accessModel: undefined,
        vendor: y.vendor ? vendorDtoToModel(y.vendor) : undefined,
        tags: y.tags?.map(t => {
            let tag: TagModel = {
                id: t.id ?? 0,
                name: t.name ?? "",
                color: t.color ?? "#000000"
            }
            return tag;
        }),
        deployTo: y.combineTo ?? []
    }
    return m;
}


export const loadAllMailBoxes = async (apiServer: ApiServer): Promise<MailBoxModel[]> => {

    let kp = await rsa.generateKey()
    let rawData = await apiServer.apiMail.getMallBoxes(kp.publicKey, true)
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.availableMailBoxes?.map(y => {
                    let a: AccessModel | undefined = undefined;
                    if (y.accessDto) {
                        a = {
                            canRead: y.accessDto.canRead,
                            canSend: y.accessDto.canSend,
                            sendLimit: y.accessDto.sendLimit,
                            readLimit: y.accessDto.readLimit,
                            user: undefined,
                            box: undefined
                        }
                    }

                    let m: MailBoxModel = convertMailBoxDtoToModel(y);
                    y.accessDto = a;
                    return m;
                }) ?? []
            }
            return []

        })
    for (var i = 0; i < rawData.length; i++) {
        rawData[i].cred = baseDecode(await rsa.decrypt(rawData[i].cred, kp.privateKey));
    }
    return rawData;
}


export const loadMyMailBoxes = async (apiServer: ApiServer): Promise<MailBoxModel[]> => {

    let kp = await rsa.generateKey()
    let rawData = await apiServer.apiMail.getMallBoxes(kp.publicKey, false)
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.availableMailBoxes?.map(y => {
                    let m: MailBoxModel = {
                        key: y.id,
                        id: y.id ?? 0,
                        emailAddress: y.emailAddress ?? "",
                        description: y.description,
                        cred: y.credential ?? "",
                        creator: y.creator?.fullName ?? "",
                        updateter: y.updater?.fullName ?? "",
                        created: y.createdAt ?? "",
                        updated: y.updatedAt ?? "",
                        displayCred: false,
                        deployTo: y.combineTo ?? [],
                        tags: y.tags?.map(t => {
                            let tag: TagModel = {
                                id: t.id ?? 0,
                                name: t.name ?? "",
                                color: t.color ?? "#000000"
                            }
                            return tag;
                        })
                    }
                    return m;
                }) ?? []
            }
            return []

        })
    for (var i = 0; i < rawData.length; i++) {
        rawData[i].cred = baseDecode(await rsa.decrypt(rawData[i].cred, kp.privateKey));
    }
    return rawData;
}

export const loadVendorEmailBox = async (vendorId: number, apiServer: ApiServer): Promise<MailBoxModel[]> => {
    let kp = await rsa.generateKey()
    let rawData = await apiServer.apiMail.getMailVendorMails(vendorId, kp.publicKey)
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.mailBox?.map(y => {
                    let m: MailBoxModel = {
                        key: y.id,
                        id: y.id ?? 0,
                        emailAddress: y.emailAddress ?? "",
                        description: y.description,
                        cred: y.credential ?? "",
                        creator: y.creator?.fullName ?? "",
                        updateter: y.updater?.fullName ?? "",
                        created: y.createdAt ?? "",
                        updated: y.updatedAt ?? "",
                        displayCred: false,
                        deployTo: y.combineTo ?? []
                    }
                    return m;
                }) ?? []
            }
            return []

        })
    for (var i = 0; i < rawData.length; i++) {
        rawData[i].cred = baseDecode(await rsa.decrypt(rawData[i].cred, kp.privateKey));
    }
    return rawData;
}

function vendorDtoToModel(y: MailVendorDto): MailVendorModel {
    var vendor: MailVendorModel = {
        key: y.id,
        id: y.id ?? 0,
        vendorName: y.vendorName ?? "",
        creator: y.creator?.fullName ?? "",
        updateter: y.updater?.fullName ?? "",
        created: y.createdAt ?? "",
        updated: y.updatedAt ?? "",

        receiveHost: y.mailConfigure?.receiveHost ?? "",
        receivePort: y.mailConfigure?.receivePort ?? 0,
        receiveAuth: y.mailConfigure?.receiveAuth ?? false,
        ssl: y.mailConfigure?.ssl ?? false,
        receiveProtocol: y.mailConfigure?.receiveProtocol ?? "imap",

        sendProtocol: y.mailConfigure?.sendProtocol ?? "smtp",
        sendAuth: y.mailConfigure?.sendAuth ?? false,
        sendPort: y.mailConfigure?.sendPort ?? 0,
        sendHost: y.mailConfigure?.sendHost ?? "",
        startSsl: y.mailConfigure?.startSsl ?? false
    }
    return vendor;

}

export const loadVendors = async (apiServer: ApiServer): Promise<MailVendorModel[]> => {

    return apiServer.apiMail.getMailVendors()
        .then(x => {
            if (x.data.data?.success) {
                return x.data.data.vendors?.map(y => {
                    var vendor: MailVendorModel = {
                        id: y.id ?? 0,
                        vendorName: y.vendorName ?? "",
                        creator: y.creator?.fullName ?? "",
                        updateter: y.updater?.fullName ?? "",
                        created: y.createdAt ?? "",
                        updated: y.updatedAt ?? "",

                        receiveHost: y.mailConfigure?.receiveHost ?? "",
                        receivePort: y.mailConfigure?.receivePort ?? 0,
                        receiveAuth: y.mailConfigure?.receiveAuth ?? false,
                        ssl: y.mailConfigure?.ssl ?? false,
                        receiveProtocol: y.mailConfigure?.receiveProtocol ?? "imap",

                        sendProtocol: y.mailConfigure?.sendProtocol ?? "smtp",
                        sendAuth: y.mailConfigure?.sendAuth ?? false,
                        sendPort: y.mailConfigure?.sendPort ?? 0,
                        sendHost: y.mailConfigure?.sendHost ?? "",
                        startSsl: y.mailConfigure?.startSsl ?? false
                    }
                    return vendor;
                }) ?? [];
            } else {
                return []
            }
        });

}

export const mailBoxConfigureColumnProvider = (translater: ((d: string) => string), handler: ActionEventHandler<MailBoxModel>, apiServer: ApiServer, instances?: RemoteInstanceModel[], tags?: TagModel[], reportMailboxState?: (mailboxId: number, statemodel: StateModel) => void): ColumnType<MailBoxModel>[] => {
    var columns: ColumnType<MailBoxModel>[] = [
        {
            title: translater("mails.conf.id"),
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: translater("mails.conf.emailAddress"),
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            render: (text: string, record: MailBoxModel) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => record.emailAddress.indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.conf.emailAddress")} />)
            },
        }
        ,
        {
            title: translater("mails.conf.vendor"),
            dataIndex: 'vendor',
            key: 'vendor',
            render: (text: string, record: MailBoxModel) => record.vendor?.vendorName,
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => (record.vendor?.vendorName ?? "").indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.conf.vendor")} />)
            },
        },
        {
            title: translater("mails.conf.tags"),
            dataIndex: 'tags',
            key: 'tags',


            render: (text: string, record: MailBoxModel) => {
                return (
                    <div>
                        {record.tags &&
                            record.tags?.map(x => {
                                return (<Tag color={x.color}>{x.name}</Tag>)
                            })
                        }
                    </div>)
            },
            onFilter(value, record) {
				return (record.tags && record.tags.findIndex(x=>x.id ===value) >= 0)??false;
			},
            filters: (tags??[]).map(y=>{
				return {
				text:y?.name??"",
				value:y?.id??0};
			})??[],
        },
        {
            title: translater("mails.conf.createTime"),
            dataIndex: 'createTime',
            key: 'createTime',
            render: (value: any, record: MailBoxModel, index: number) => {
                return record.created === "" ? "" : moment.parseZone(record.created).format("YYYY-MM-DD HH:mm:ss");
            },
            filterDropdown(props) {
                return (<ColumnDateRangeFilter context={props} title={translater("mails.conf.createTime")} />)
            },
            sorter: (a: MailBoxModel, b: MailBoxModel) => {
                return moment(a.created).unix() - moment(b.created).unix()
            },
            showSorterTooltip: false,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => {
                value = value.toString();
                let startEnd = value.split("->");
                if (startEnd.length == 2) {
                    let start = moment(startEnd[0]);
                    let end = moment(startEnd[1]);
                    let created = moment(record.created)
                    return created.isAfter(start) && created.isBefore(end);
                }
                return true
            }
        }, {
            title: translater("mails.conf.updateTime"),
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: (value: any, record: MailBoxModel, index: number) => {
                return record.updated === "" ? "" : moment.parseZone(record.updated).format("YYYY-MM-DD HH:mm:ss");
            },
            filterDropdown(props) {
                return (<ColumnDateRangeFilter context={props} title={translater("mails.conf.updateTime")} />)
            },
            sorter: (a: MailBoxModel, b: MailBoxModel) => {
                return moment(a.updated).unix() - moment(b.updated).unix()
            },
            showSorterTooltip: false,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => {
                value = value.toString();
                let startEnd = value.split("->");
                if (startEnd.length == 2) {
                    let start = moment(startEnd[0]);
                    let end = moment(startEnd[1]);
                    let updated = moment(record.updated)
                    return updated.isAfter(start) && updated.isBefore(end);
                }
                return true
            }
        },
        {
            title: translater("mails.conf.latestUpdater"),
            dataIndex: 'updateter',
            key: 'updateter',
            render: (text: string, record: MailBoxModel) => {
                return (
                    <div>
                        <div>
                            {record.updateter}
                        </div>
                        <div>

                        </div>

                    </div>)
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => (record.vendor?.vendorName ?? "").indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.conf.latestUpdater")} />)
            },
        },
        {
            title: translater("mails.conf.state"),
            dataIndex: 'state',
            key: 'state',
            render: (text: string, record: MailBoxModel) => {
                return (
                    <div>
                        <MailBoxProgress
                            apiServer={apiServer}
                            mailBoxId={record.id}
                            reportState={reportMailboxState}
                        />
                    </div>)
            }
        }
    ]
    if (instances) {
        columns.push(
            {
                title: translater("mails.conf.instances"),
                dataIndex: 'instances',
                key: 'instances',
                render: (text: string, record: MailBoxModel, index) => {
                    let instanceRender: ReactNode[] = []
                    record.deployTo.forEach(element => {
                        var i = instances.find(x => String(x.id) === element);
                        if (i) {
                            let color: string | undefined = undefined;
                            if (record.successInstance && record.successInstance.findIndex(r => r === Number(element)) >= 0) {
                                color = "blue";
                            } else if (record.failInstance && record.failInstance.findIndex(r => r === Number(element)) >= 0) {
                                color = "red";
                            }
                            instanceRender.push(<Tag color={color} >{i.name}-{i.publicIp}</Tag>)
                        }
                    });

                    return (
                        <div>
                            {instanceRender}
                            <Button shape="circle" loading={record.isVerifing} icon={<CheckCircleOutlined />} onClick={() => handler(record, "verify", index)} type="primary" />
                        </div>)
                }
            }
        )
    }
    return columns;
}


export const myMailBoxColumnProvider = (translater: ((d: string) => string), handler: ActionEventHandler<MailBoxModel>, apiServer: ApiServer, instances?: RemoteInstanceModel[],tags?:TagModel[]): ColumnType<MailBoxModel>[] => {
    var columns: ColumnType<MailBoxModel>[] = [
        {
            title: translater("mails.conf.id"),
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: translater("mails.conf.emailAddress"),
            dataIndex: 'emailAddress',
            key: 'emailAddress',
            render: (text: string, record: MailBoxModel) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => record.emailAddress.indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.conf.emailAddress")} />)
            },
        },
        {
            title: translater("mails.conf.tags"),
            dataIndex: 'tags',
            key: 'tags',
            render: (text: string, record: MailBoxModel) => {
                return (
                    <div>
                        {record.tags &&
                            record.tags?.map(x => {
                                return (<Tag color={x.color}>{x.name}</Tag>)
                            })
                        }
                    </div>)
            }            ,
            filters: (tags??[]).map(y=>{
				return {
				text:y?.name??"",
				value:y?.id??0};
			})??[],
            onFilter(value, record) {
				return (record.tags && record.tags.findIndex(x=>x.id ===value) >= 0)??false;
			},
        },
        {
            title: translater("mails.conf.createTime"),
            dataIndex: 'createTime',
            key: 'createTime',
            render: (value: any, record: MailBoxModel, index: number) => {
                return record.created === "" ? "" : moment.parseZone(record.created).format("YYYY-MM-DD HH:mm:ss");
            },
            filterDropdown(props) {
                return (<ColumnDateRangeFilter context={props} title={translater("mails.conf.createTime")} />)
            },
            sorter: (a: MailBoxModel, b: MailBoxModel) => {
                return moment(a.created).unix() - moment(b.created).unix()
            },
            showSorterTooltip: false,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => {
                value = value.toString();
                let startEnd = value.split("->");
                if (startEnd.length == 2) {
                    let start = moment(startEnd[0]);
                    let end = moment(startEnd[1]);
                    let created = moment(record.created)
                    return created.isAfter(start) && created.isBefore(end);
                }
                return true
            }
        }, {
            title: translater("mails.conf.updateTime"),
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: (value: any, record: MailBoxModel, index: number) => {
                return record.updated === "" ? "" : moment.parseZone(record.updated).format("YYYY-MM-DD HH:mm:ss");
            },
            filterDropdown(props) {
                return (<ColumnDateRangeFilter context={props} title={translater("mails.conf.updateTime")} />)
            },
            sorter: (a: MailBoxModel, b: MailBoxModel) => {
                return moment(a.updated).unix() - moment(b.updated).unix()
            },
            showSorterTooltip: false,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => {
                value = value.toString();
                let startEnd = value.split("->");
                if (startEnd.length == 2) {
                    let start = moment(startEnd[0]);
                    let end = moment(startEnd[1]);
                    let updated = moment(record.updated)
                    return updated.isAfter(start) && updated.isBefore(end);
                }
                return true
            }
        },
        {
            title: translater("mails.conf.latestUpdater"),
            dataIndex: 'updateter',
            key: 'updateter',
            render: (text: string, record: MailBoxModel) => {
                return (
                    <div>
                        <div>
                            {record.updateter}
                        </div>
                        <div>

                        </div>

                    </div>)
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailBoxModel) => (record.vendor?.vendorName ?? "").indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.conf.latestUpdater")} />)
            },
        },
        {
            title: translater("mails.conf.state"),
            dataIndex: 'state',
            key: 'state',
            render: (text: string, record: MailBoxModel) => {
                return (
                    <div>
                        <MailBoxProgress
                            apiServer={apiServer}
                            mailBoxId={record.id} />
                    </div>)
            }
        }
    ]
    return columns;
}

export const vendorColumnProvider = (translater: ((d: string) => string), handler: ActionEventHandler<MailVendorModel>): ColumnType<MailVendorModel>[] => {


    return [
        {
            title: translater("mails.email-vendor.name"),
            dataIndex: 'vendorName',
            key: 'vendorName',
            render: (text: string, record: MailVendorModel) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailVendorModel) => record.vendorName.indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.email-vendor.name")} />)
            },
        },
        {
            title: translater("mails.email-vendor.send-endpoint"),
            dataIndex: 'sendHost',
            key: 'sendHost',
        }, {
            title: translater("mails.email-vendor.send-protocol"),
            dataIndex: 'sendProtocol',
            key: 'sendProtocol',
        },
        {
            title: translater("mails.email-vendor.receive-endpoint"),
            dataIndex: 'receiveHost',
            key: 'receiveHost',
        },
        {
            title: translater("mails.email-vendor.receive-protocol"),
            dataIndex: 'receiveProtocol',
            key: 'receiveProtocol',
        }
    ];

}


export interface TagModel {
    id: number,
    name: string,
    color: string
}




export async function loadAllTags(apiServer: ApiServer): Promise<TagModel[]> {
    return apiServer.apiProductivity.getTagList()
        .then(x => {
            return x.data.data?.tagDtoList?.map((y) => {
                let t: TagModel = {
                    name: y.name ?? "",
                    color: y.color ?? "#000000",
                    id: y.id ?? 0
                }
                return t;
            }
            ) ?? []
        });
}

export const savingConfigure = async (apiServer: ApiServer, depolyLocation, emailAddress, password, tags, mailBoxVendor, id) => {

    let p = await apiServer.apiSecurity.getPublicInDomain(
        SECURITY_DOMAIN
    ).then(x => {
        if (x.data.data?.publicKey) {
            return x.data.data.publicKey;
        }
        return undefined;
    })

    if (p) {
        let enc = await rsa.encrypt(password, p);
        if (id < 1) {
            await apiServer.apiMail.postMailBoxes(
                {
                    emailAddress: emailAddress,
                    credential: enc,
                    deployTo: depolyLocation,
                    vendorId: mailBoxVendor,
                }
            ).then(x => {
                if (x.data.data?.request) {
                    id = x.data.data.mailBox?.id
                }
            })
        } else {
            await apiServer.apiMail.putMailBoxes(
                id,
                {
                    credential: enc,
                    deployTo: depolyLocation,
                    vendorId: mailBoxVendor,
                }
            )
        }

        await apiServer.apiMail.postMailTags(
            id,
            {
                tagIds: tags
            }
        )
        return true;
    }

    return false


}