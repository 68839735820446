import { Button, message, Modal, notification, PageHeader, Popconfirm, Upload } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { covertWorkbenchMailContactDtoToModel, MailContactModel } from "../@mod";
import MailContactListView from "../component/mail-contact-list"
import MailContactEditorView from "../component/mail-contact-editor"
import "./index.less"
import { ImportOutlined } from "@ant-design/icons"
import ContactEditor from "../component/contact-editor";

interface MailContactManagerViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface MailContactManagerViewState {
    mailContacts: MailContactModel[],
    loadingMailContact: boolean,
    batchSelectedContacts?: MailContactModel[],
    importingContact: MailContactModel[],
    singleSelectedContacts?: MailContactModel,
    showImportEditor: boolean,
    showContactEditor: boolean,
    importHandler: any,

    // mark contact editor state, true marks on editting state or false marks on adding state
    contactEditorOnEditState: boolean
}

const fieldsSelectorCN = [
    "邮箱地址",
    "昵称"
];

const fieldsSelectorEN = [
    "emailAddress",
    "nickName",
];

class MailContactManagerView extends React.Component<MailContactManagerViewModel, MailContactManagerViewState> {

    constructor(props: MailContactManagerViewModel) {
        super(props)
        this.state = {
            mailContacts: [],
            loadingMailContact: false,
            showContactEditor: false,
            contactEditorOnEditState: false,
            showImportEditor: false,
            importingContact: [],
            importHandler: undefined
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        this.setState({
            loadingMailContact: true
        })
        this.props.apiServer.apiMail.getMailContacts().then(r => {
            if (r.data.data?.dtos) {
                this.setState({
                    mailContacts: r.data.data.dtos.map(x => covertWorkbenchMailContactDtoToModel(x))
                })
            }
        }).finally(() => {
            this.setState({
                loadingMailContact: false
            })
        })
    }

    onContactBatchSelected = (dataList: MailContactModel[]) => {
        this.setState({
            batchSelectedContacts: dataList
        })
    }

    batchDelete = (ids?: Array<number>) => {
        if (!ids && this.state.batchSelectedContacts && this.state.batchSelectedContacts.length > 0) {
            ids = this.state.batchSelectedContacts.map(x => x.id)
        }
        if (ids && ids.length > 0) {
            this.props.apiServer.apiMail.deleteMailContacts({
                contactIds: ids
            }).then(r => {
                console.log("batch delete response: ", r);
                if (r.data.data?.success) {
                    message.success(this.props.t("mails.contact.delete-success"))
                    this.componentDidMount()
                } else {
                    message.error(this.props.t("mails.contact.delete-fail"))
                }
            })
        }
    }

    onContactFinished = (values) => {
        const extendInfoToSave = {}
        const extendInfos = values.extendsInfo
        extendInfos.forEach(extendInfo => {
            extendInfoToSave[extendInfo.key] = extendInfo.value
        })
        if (this.state.contactEditorOnEditState) {
            // update 
            if (this.state.singleSelectedContacts?.id) {
                this.props.apiServer.apiMail.putMailContacts(this.state.singleSelectedContacts?.id, {
                    emailAddress: values.emailAddress,
                    nickname: values.nickname,
                    tags: values.tag,
                    enable: values.enable,
                    extendsInfo: JSON.stringify(extendInfoToSave),
                }).then(r => {
                    if (r.data.data?.success) {
                        message.success(this.props.t("mails.contact.save-success")).then()
                        this.setState({
                            showContactEditor: false
                        })
                        this.componentDidMount()
                        return
                    }
                    message.error(this.props.t("mails.contact.save-failed")).then()
                })
            }
        } else {
            // new
            this.props.apiServer.apiMail.postMailContacts({
                emailAddress: values.emailAddress,
                nickname: values.nickname,
                tags: values.tag,
                enable: values.enable,
                extendsInfo: JSON.stringify(extendInfoToSave)
            }).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("mails.contact.save-success")).then()
                    this.setState({
                        showContactEditor: false
                    })
                    this.componentDidMount()
                    return
                }
                message.error(this.props.t("mails.contact.save-failed")).then()
            })
        }
    }

    contactHandler = (record: MailContactModel, action: string) => {
        if (action === "edit") {
            this.setState({
                contactEditorOnEditState: true,
                showContactEditor: true,
                singleSelectedContacts: record
            })
        } else if (action === "delete") {
            this.batchDelete([record.id])
        }
    }

    showContactAddEditor = () => {
        this.setState({
            showContactEditor: true,
            singleSelectedContacts: undefined,
            contactEditorOnEditState: false
        })
    }
    openCSV = async (file: any) => {
        let that = this;
        let fileReader = new FileReader();
        let csvFile: File = file.file;
        fileReader.readAsText(csvFile);
        fileReader.onload = (ev: ProgressEvent<FileReader>): any => {
            let readResult = ev.target?.result;
            if (readResult) {
                console.log(readResult);
                let strCsv = readResult.toString().replaceAll('\ufeff', "");
                let lines = strCsv.split("\n");
                let headers = lines.at(0)?.split(",");
                let columnIndexes: any = [];
                // 优先使用中文字段
                for (let idx = 0; idx < fieldsSelectorEN.length; idx++) {
                    for (var hidx = 0; hidx < (headers?.length ?? 0); hidx++) {
                        if (headers && headers[hidx] === fieldsSelectorCN[idx]) {
                            columnIndexes[idx] = hidx;
                        }
                    }
                }

                for (let idx = 0; idx < fieldsSelectorCN.length; idx++) {
                    for (var hidx = 0; hidx < (headers?.length ?? 0); hidx++) {
                        if (headers && headers[hidx] === fieldsSelectorCN[idx]) {
                            columnIndexes[idx] = hidx;
                        }
                    }
                }
                let importedModels: MailContactModel[] = [];

                for (let line = 1; line < lines.length; line++) {
                    let cells = lines[line].split(",");
                    if (lines[line].length > 1) {

                        let model: MailContactModel = {
                            id: -1,
                            emailAddress: "",
                            nickname: "",
                            tag: [],
                            extendsInfo: {},
                            enable: true,
                            createdTime: "",
                            createdBy: { id: -1, fullName: "" },
                            updatedTime: "",
                            updatedBy: { id: -1, fullName: "" },
                        }
                        model.emailAddress = cells[columnIndexes[0]].replaceAll("'", "").replaceAll("\"", "").replaceAll("\r", "");
                        model.nickname = cells[columnIndexes[1]].replaceAll("'", "").replaceAll("\"", "").replaceAll("\r", "");
                        if (headers) {
                            for (let colIndex = 0; colIndex < (headers?.length ?? 0); colIndex++) {
                                model.extendsInfo[headers[colIndex]] = cells[colIndex].replaceAll("'", "").replaceAll("\"", "").replaceAll("\r", "");
                            }
                        }
                        importedModels.push(model);

                    }
                    that.setState({
                        showImportEditor: true
                    })




                    //PUSH DATA to editor
                    setTimeout(() => {
                        importedModels = importedModels.filter(
                            x => {
                                return that.state.mailContacts.findIndex(q => q.emailAddress === x.emailAddress) < 0;
                            }
                        )
                        if (that.h) {
                            that.h(importedModels);
                        }
                    })
                }
            }
        };
    }
    importExtCheck = (file: any): boolean => {
        if (file.type === "text/csv") {
            return true;
        }
        notification.error({ message: this.props.t("mails.contact.import-csv-required") })
        return false;
    }

    setImportHandler = (h: (importedContractModel: MailContactModel[]) => void) => {
        console.log(h);
        this.h = h;
    }

    doImport: (() => Promise<void>) | undefined

    cancleImportEditor = () => {
        this.setState({
            showImportEditor: false
        })
    }


    h: ((importedContractModel: MailContactModel[]) => void) | undefined;

    render(): React.ReactNode {
        return (
            <div className={"mail-contact-list-container"}>
                <PageHeader title={this.props.t("mails.contact.manager-title")} subTitle={this.props.t("mails.contact.manager-sub-title")}>

                    <div className="operation-bar">
                        <Popconfirm
                            title={this.props.t("mails.contact.confirm-delete")}
                            onConfirm={() => this.batchDelete()}
                            disabled={!this.state.batchSelectedContacts || this.state.batchSelectedContacts.length === 0}
                        >
                            <Button
                                danger
                                disabled={!this.state.batchSelectedContacts || this.state.batchSelectedContacts.length === 0}
                            >{this.props.t("mails.contact.delete-selected")}</Button>
                        </Popconfirm>
                        <Button
                            type="primary"
                            onClick={this.showContactAddEditor}

                        >{this.props.t("mails.contact.add-contact")}</Button>

                        <Upload
                            //onChange={this.openCSV}
                            showUploadList={false}
                            customRequest={this.openCSV}
                            multiple={false}
                            beforeUpload={this.importExtCheck}
                        >
                            <Button type="primary"
                                icon={<ImportOutlined />}
                            >
                                {this.props.t("mails.contact.import")}

                            </Button></Upload>
                    </div>
                    <MailContactListView
                        apiServer={this.props.apiServer}
                        loadingMailContact={this.state.loadingMailContact}
                        mailContacts={this.state.mailContacts}
                        onSelected={this.onContactBatchSelected}
                        contactHandler={this.contactHandler} />
                </PageHeader>

                <Modal visible={this.state.showImportEditor}
                    onCancel={this.cancleImportEditor}
                    closable={true}
                    okText={this.props.t("mails.contact.import")}
                    width='640px'
                    okButtonProps={
                        {
                            icon: (
                                <ImportOutlined />
                            )
                        }
                    }
                    onOk ={()=>{
                        if(this.doImport){
                            
                            this.setState({
                                showImportEditor:false
                            })
                            this.doImport().then(()=>{
                                this.loadData()    
                            })
                        }
                    }}
                >
                    <ContactEditor
                        putDoImport={d => this.doImport = d}
                        apiServer={this.props.apiServer}
                        onCompleted={() => { }}
                        putImportData={this.setImportHandler}
                    />
                </Modal>

                <Modal
                    visible={this.state.showContactEditor}
                    footer={null}
                    maskClosable={false}
                    onCancel={() => this.setState({ showContactEditor: false })}
                    destroyOnClose={true}
          
                >
                    <div style={{ padding: '16px' }}>
                        <MailContactEditorView
                            apiServer={this.props.apiServer}
                            onfinished={this.onContactFinished}
                            mailContact={this.state.singleSelectedContacts}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withTranslation()(MailContactManagerView)