import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import ApiServer from "../../../../ApiServer";
import moment from "moment";
import { TicketModel, UserModel } from "../../@mod";
import TimeChart from "../../../../components/charts/time-chart";
import './index.less'
import BarChart from "../../../../components/charts/bar-chart";
import { DataPoint } from "../../../../components/charts/@mod";
import PieChart from "../../../../components/charts/pie-chart";

export interface AgeingViewModel extends WithTranslation {
    ticketModel?: TicketModel,
    apiServer: ApiServer,
    userModels: UserModel[]
}

interface AgeingViewState {
    timeModels: ThreadTimeModel[],
    timeCost: ThreadTimeCostModel[],
    userCount: number;
}

interface ThreadTimeModel {
    user: UserModel
    startedAt: string,
    endAt: string,
    isOverdue: boolean
}

interface ThreadTimeCostModel {
    user: UserModel,
    cost: number
}

class AgeingView extends React.Component<AgeingViewModel, AgeingViewState> {
    timeFormatPattern = "YYYY-MM-DD HH:mm:ss";
    constructor(props: AgeingViewModel) {
        super(props);
        this.state = {
            timeModels: [],
            timeCost: [],
            userCount: 0
        }
    }

    componentDidMount() {
        this.props.apiServer.apiStatistic.getTicketParticipateRecord(this.props.ticketModel?.id ?? -1).then(r => {
            if (r.data.data?.success) {
                const threadList = r.data.data.dtos;
                let sla = moment.parseZone(this.props.ticketModel?.sla);

                let userId: number[] = [];
                if (threadList) {
                    let timeModels: ThreadTimeModel[] = [];
                    let timeCost: ThreadTimeCostModel[] = []
                    for (let idx = 0; idx < threadList.length; idx++) {

                        let x = threadList[idx]


                        if (userId.findIndex(y => y === x.user?.id ?? 0) < 0) {
                            userId.push(x.user?.id ?? 0);
                        }
                        let startedAt = moment.parseZone(x.startedAt);
                        let endAt = moment.parseZone(x.endAt)
                        let user = this.props.userModels.find(y => x.user?.id === y.id) ?? {
                            id: 0,
                            name: "NOT_FOUND",
                            organization: "NOT_FOUND"
                        };

                        /// TIME COST

                        let exist = timeCost.find(x => x.user.id === user.id);
                        if (exist) {
                            exist.cost += endAt.unix() - startedAt.unix()
                        } else {
                            let timeCostMode: ThreadTimeCostModel = {
                                user: user,
                                cost: endAt.unix() - startedAt.unix()
                            }
                            timeCost.push(timeCostMode);
                        }

                        /// TIME COST

                        let isOverdue = endAt.isAfter(sla);


                        if (isOverdue) {
                            let startOverDue = startedAt.isAfter(sla);
                            if (startOverDue) {
                                let timeModel: ThreadTimeModel = {
                                    user: user,
                                    startedAt: startedAt.toString(),
                                    endAt: endAt.toString(),
                                    isOverdue: isOverdue

                                }
                                timeModels.push(timeModel);
                            } else {
                                let timeModel: ThreadTimeModel = {
                                    user: user,
                                    startedAt: startedAt.toString(),
                                    endAt: sla.toString(),
                                    isOverdue: false

                                }
                                timeModels.push(timeModel);
                                timeModel = {
                                    user: user,
                                    startedAt: sla.toString(),
                                    endAt: endAt.toString(),
                                    isOverdue: true,
                                }
                                timeModels.push(timeModel);
                            }

                        } else {

                            let timeModel: ThreadTimeModel = {
                                user: this.props.userModels.find(y => x.user?.id === y.id) ?? {
                                    id: 0,
                                    name: "NOT_FOUND",
                                    organization: "NOT_FOUND"
                                },
                                startedAt: startedAt.toString(),
                                endAt: endAt.toString(),
                                isOverdue: isOverdue

                            }

                            timeModels.push(timeModel);
                        }

                    }
                    this.setState({
                        timeModels: timeModels,
                        timeCost: timeCost
                    })
                }
                this.setState({
                    userCount: userId.length
                })
            }
        })
    }

    getTimeCostPercentage = () => {
        let total = 0;
        this.state.timeCost.map(x => total += x.cost);

        return this.state.timeCost.map(x => {
            let dataPoint: DataPoint = {
                type: x.user.name,
                value: x.cost / total * 100
            }
            return dataPoint;
        })
    }

    render() {
        return (
            <div className="efficency-container">
                <div className="sequence-container">
                    <h1>{this.props.t("statistic.ticket.serial")}</h1>
                    <TimeChart
                        data={this.state.timeModels.map(x => {
                            return {
                                name: x.user.name,
                                start: x.startedAt,
                                end: x.endAt,
                                type: x.isOverdue ? 1 : 0
                            }
                        })}
                        height={60 * (this.state.userCount + 1)}
                        colors={["green", "red"]}
                        types={[this.props.t("in-plan"), this.props.t("over-due")]}
                    />
                </div>
                <div className="percentage-container" style={{ height: (this.state.userCount + 1) * 70 }}>
                    <div className="time-cost-container">
                        <h1>{this.props.t("statistic.ticket.handle-time-cost")}</h1>
                        <BarChart
                            data={this.state.timeCost.map(x => {
                                let dataPoint: DataPoint = {
                                    type: x.user.name,
                                    value: x.cost / 60
                                }
                                return dataPoint;
                            })}
                            height={(this.state.userCount + 1) * 120}
                            nameFormat={
                                (val) => {
                                    return this.props.t("time-cost")
                                }
                            }
                            valueFormat={
                                (val) => {
                                    return val.value + this.props.t("minutes")
                                }
                            }
                        />
                    </div>
                    <div className="time-percent-container">
                        <h1>{this.props.t("statistic.ticket.time-cost-rate")}</h1>
                        <PieChart
                            data={this.getTimeCostPercentage()}
                            valueFormat={
                                (val) => {
                                    return Number(val.value).toFixed(2) + "%"
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(AgeingView)