import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { WithTranslation } from "react-i18next";
import { ActionEventHandler } from "../../../../components";
import {DownloadOutlined} from "@ant-design/icons"


export interface ExportJobModel{
    attachment: string
    completedAt: number
    id: number
    jobId: string
    params: string
    requestedAt: number
    requester: any
    startedAt: number
    status: any
    type: number
}

export const columnsProvider = (
	trans: WithTranslation, handler: ActionEventHandler<ExportJobModel>): ColumnType<ExportJobModel>[] => {
	let translater: ((d: string) => string) = trans.t;
        return[
            {
                "title": translater("tickets.exports.id"),
                dataIndex: "id",
                key: "id",
                render: (text, record) => {
                    return (
                        <div>
                            #{record.id}
                        </div>)
                },
                defaultSortOrder:"descend",
                sortDirections:["descend","ascend"]
            },
            {
                "title": translater("tickets.exports.jobId"),
                dataIndex: "jobId",
                key: "jobId",
                render: (text, record) => {
                    return (
                        <div>
                            {record.jobId}
                        </div>)
                }
            },
            {
                "title": translater("tickets.exports.status"),
                dataIndex: "status",
                key: "status",
                render: (text, record) => {
                    return (
                        <div>
                            {record.status[trans.i18n.language]}
                        </div>)
                }
            },
            {
                "title": translater("tickets.exports.startedAt"),
                dataIndex: "startedAt",
                key: "startedAt",
                render: (text, record) => {
                    return (
                        <div>
                            {record.startedAt ===0 ? "" : moment.parseZone(record.startedAt).format("YYYY-MM-DD HH:mm:ss")}
                        </div>)
                }
            }
            ,
            {
                "title": translater("tickets.exports.requestedAt"),
                dataIndex: "requestedAt",
                key: "requestedAt",
                render: (text, record) => {
                    return (
                        <div>
                            {record.requestedAt ===0 ? "" : moment.parseZone(record.requestedAt).format("YYYY-MM-DD HH:mm:ss")}
                        </div>)
                }
            }
            ,
            {
                "title": translater("tickets.exports.completedAt"),
                dataIndex: "completedAt",
                key: "completedAt",
                render: (text, record) => {
                    return (
                        <div>
                            {record.completedAt ===0 ? "" : moment.parseZone(record.completedAt).format("YYYY-MM-DD HH:mm:ss")}
                        </div>)
                }
            },
            {
                title: translater("tickets.exports.operations"),
                dataIndex:"attachment",
                key:"attachment",
                render:(text, record) => {
                    if(record.completedAt!=0){
                        return <Button icon={<DownloadOutlined/>}  onClick={() => handler(record, "detail")}>{trans.t("tickets.exports.download")}</Button>
                    } else{
                        return <Button  onClick={() => handler(record, "cancle")}>{trans.t("tickets.exports.cancle")}</Button>
                    }
                   
                }
            }
        ]
    }