import {Button, Form, Input, Select, Space, Switch, Tag} from "antd";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {loadTagList, MailContactModel, MailContactTag} from "../../@mod";

import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons"
import "./index.less"
import ApiServer from "../../../../../ApiServer";

interface MailContactEditorViewModel extends WithTranslation {
    mailContact?: MailContactModel,
    apiServer: ApiServer,
    onfinished: (values: any) => void
}

interface MailContactEditorViewState {
    tags: MailContactTag[],
    initExtendInfo: any[]
}

const regExpChinese = new RegExp(/^[^\u4e00-\u9fa5]+$/)
let translator: (s: string) => string
class MailContactEditorView extends React.Component<MailContactEditorViewModel, MailContactEditorViewState> {
    constructor(props: MailContactEditorViewModel) {
        super(props)
        translator = props.t
        const initExtendInfo: any[] = []
        if (props.mailContact?.extendsInfo) {
            let extendInfo = props.mailContact?.extendsInfo
            for (const key in extendInfo) {
                initExtendInfo.push({
                    key: key,
                    value: extendInfo[key]
                })
            }
        }
        this.state = {
            tags: [],
            initExtendInfo: initExtendInfo
        }
    }

    componentDidMount(): void {
        loadTagList(this.props.apiServer).then(x=>{
            this.setState({tags: x})
        })
    }



    onFinish = (values) => {
        this.props.onfinished(values)
    }

    render(): React.ReactNode {
        return (
            <div className="mail-contace-editor-container">
                <Form labelCol={{ span: 6 }}
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        initialValue={this.props.mailContact?.emailAddress}
                        label={this.props.t("mails.contact.fields.emailAddress")}
                        name={"emailAddress"}
                        rules={[{ required: true, message: this.props.t("mails.contact.input-email-address") }]}
                    >
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item
                        initialValue={this.props.mailContact?.nickname}
                        label={this.props.t("mails.contact.fields.nickname")}
                        name={"nickname"}
                        rules={[{ required: true, message: this.props.t("mails.contact.input-nickname") }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={this.props.t("mails.contact.fields.enable")}
                        name={"enable"}
                        initialValue={this.props.mailContact?.enable ?? true}
                    >
                        <Switch defaultChecked={this.props.mailContact?.enable ?? true} />
                    </Form.Item>

                    <Form.Item
                        initialValue={this.props.mailContact?.tag.map(t => t.id)}
                        label={this.props.t("mails.contact.fields.tag")}
                        name={"tag"}
                    >
                        <Select
                            mode="multiple"
                            allowClear={true}
                        >
                            {this.state.tags.map(tag => {
                                return <Select.Option children={<Tag color={tag.color} key={tag.id}>{tag.name}</Tag>} key={tag.id} value={tag.id} />
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={this.props.t("mails.contact.fields.extendsInfo")}
                        name={"extendsInfo"}
                    >
                        <Input type="hidden"></Input>
                    </Form.Item>
                    <Form.List name="extendsInfo" initialValue={this.state.initExtendInfo}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8, marginLeft: 16 }} align="baseline">
                                        <Form.Item
                                            style={{ width: 120 }}
                                            {...restField}
                                            name={[name, 'key']}
                                            rules={[
                                                { required: true, message: this.props.t("mails.contact.extends-miss-key") },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!regExpChinese.test(value)) {
                                                            return Promise.reject(new Error(translator("mails.contact.can-not-contains-chiness")));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input placeholder={this.props.t("mails.contact.extends-key")} />
                                        </Form.Item>:
                                        <Form.Item
                                            style={{ width: 272 }}
                                            {...restField}
                                            name={[name, 'value']}
                                            rules={[{ required: true, message: this.props.t("mails.contact.extends-miss-value") }]}
                                        >
                                            <Input placeholder={this.props.t("mails.contact.extends-value")} />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        {this.props.t("mails.contact.extends-add")}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>


                    <Form.Item>
                        <div className="operation-bar">
                            <Button type="primary" htmlType="submit">{this.props.t("mails.contact.submit")}</Button>
                        </div>
                    </Form.Item>

                </Form>
            </div>
        )
    }
}

export default withTranslation()(MailContactEditorView)