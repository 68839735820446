import { Table } from "antd";
import { ColumnType } from "antd/lib/table"
import { ExpandableConfig, TableRowSelection } from "antd/lib/table/interface";
import React from "react";
import { ActionEventHandler } from "../..";

export interface WorkbenchTableModel<T = any> {
	pagesize?: number
	data: T[],
	dataLoading: boolean,
	onSelected?: (d: T[]) => void,
	onDetail?: (d: T) => void,
	onCancle?: (d: T) => void,
	onChanged?: (d: T) => void,
	onEvent?: (d: T, event: string) => void,
	columnProvider: (handler: ActionEventHandler<T>) => ColumnType<T>[]
	disableCheckBox?: boolean,
	expandable?: ExpandableConfig<T>,
	rowKey?: (record: T, index?: number) => React.Key,
	hidePagination?: boolean
}

export interface WorkbenchTableState {
	selectedRowKeys: React.Key[]
}



export default class WorkBenchTable<T extends object> extends React.Component<WorkbenchTableModel<T>, WorkbenchTableState>{

	constructor(props: WorkbenchTableModel<T>) {
		super(props)
		this.state = {
			selectedRowKeys: [],
		}
	}

	onSelectChange = (selectedRowKeys: React.Key[], selectedRows: T[]) => {
		this.setState({ selectedRowKeys });
		if (this.props.onSelected) {
			this.props.onSelected(selectedRows);
		}
	};

	onAction = (model: T, actionType: string) => {
		if (actionType === "detail" && this.props.onDetail) {
			this.props.onDetail(model);
		}
		else if (actionType === "cancle" && this.props.onCancle) {
			this.props.onCancle(model)
		}
		else if (actionType === "changed" && this.props.onChanged) {
			console.log("changed triggerd")
			this.props.onChanged(model);
		}
		else {
			this.props.onEvent?.(model, actionType);
		}

	}

	componentDidUpdate() {

	}

	getSnapshotBeforeUpdate(prevProps: Readonly<WorkbenchTableModel<T>>, prevState: Readonly<WorkbenchTableState>) {
		if (prevProps.data !== this.props.data) {
			this.setState({
				selectedRowKeys: []
			});
		}
		return null;

	}
	getRowSelect = (): TableRowSelection<T> | undefined => {
		if (this.props.disableCheckBox) {
			return undefined
		} else {
			return {
				type: "checkbox",
				onChange: this.onSelectChange,
				selectedRowKeys: this.state.selectedRowKeys
			}
		}

	}

	render(): React.ReactNode {
		return (<>
			<Table<T> columns={this.props.columnProvider(this.onAction)}
				rowKey={this.props.rowKey}
				loading={this.props.dataLoading}
				dataSource={this.props.data}
				pagination={!this.props.hidePagination ? {
					position: ["bottomLeft"],
					showTitle: true,
					defaultPageSize: this.props.pagesize ?? 12
				} : false}
				size="small"
				rowSelection={this.getRowSelect()}

				expandable={this.props.expandable}
			>
			</Table>
		</>)
	}

}