import { AutoComplete, Button, Input, Select, Spin } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TicketModel } from "../../../@mod";
import './index.less'


export interface TicketSearchViewModel extends WithTranslation {
	ticketProvider: (subject: string) => Promise<TicketModel[]>
	complete?: (id: number) => void
	cancel?: () => void


}

interface TicketSearchState {
	selectableTickets: TicketModel[],
	selected: number,
	fetching: boolean,
	displayValue: string
}

class TicketSearch extends React.Component<TicketSearchViewModel, TicketSearchState>{

	constructor(props: TicketSearchViewModel) {
		super(props)
		this.state = {
			selectableTickets: [],
			selected: 0,
			fetching: false,
			displayValue:""

		}
	}

	search = (txt: string) => {
		this.setState({
			fetching: true
		})

		this.props.ticketProvider(txt).then(
			x => {
				console.log(x)
				this.setState({
					selectableTickets: x,
					fetching: false
				});
			}
		)
	}

	get getOptions() {
		return this.state.selectableTickets.map(
			x => {
				return {
					value:String(x.id),
					text:x.subject,
					data:x
				}
			}
		)
	}
	okClick = () => {
		this.props.complete?.(this.state.selected)
	}

	cancel = () => {
		this.props.cancel?.();
	}

	onChange = (o:any,opt:any) =>{
		console.log(o);
		console.log(opt);

	}
	onSelected = (o:any,opt:any) =>{
		this.setState({
			selected:o,
			displayValue:opt.children
		})
		console.log(opt);
	}

	render(): React.ReactNode {
		return (
			<div className="ticket-selector">
				<AutoComplete style={{width:"320px"}}
					dataSource={this.getOptions}
					onSearch={this.search}
					onChange={this.onChange}
					onSelect={this.onSelected}
					value={this.state.displayValue}
				>
					<Input value={this.state.displayValue} onChange={(v)=>this.setState({
						displayValue:v.target.value
					})} />
					
				</AutoComplete>
				
				<Button type="primary" onClick={this.okClick}>{this.props.t("tickets.related.link")}</Button>
			</div>
		)
	}
}

export default withTranslation()(TicketSearch);