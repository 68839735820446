import { Select } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { UserModel } from "../@mod";
import './index.less'


export interface UserSelectorViewModel extends WithTranslation {
	users: UserModel[],
	defaultSelected?: UserModel,
	onSelected?:(user:UserModel) => void
}


interface UserSelectorState {
	selected:UserModel
}


class UserSelector extends React.Component<UserSelectorViewModel,UserSelectorState> {

	constructor(props:UserSelectorViewModel){
		super(props)
		this.state = {
			selected :this.props.defaultSelected??this.props.users[0]?? {
				name:"loading",
				id:"0",
				org:"loading"
			}
		}
	}

	getSnapshotBeforeUpdate(preProps:UserSelectorViewModel,preState:UserSelectorState){
		//it needs to be pointer compare
		// eslint-disable-next-line eqeqeq
		if(preProps.defaultSelected!=this.props.defaultSelected){
			// eslint-disable-next-line eqeqeq
			if(this.props.defaultSelected!=this.state.selected && this.props.defaultSelected){
				this.setState({selected:this.props.defaultSelected})
			}
		}
		return null;
	}
	componentDidUpdate(){

	}

	renderSelectedItem  = ()=>{
		return this.props.users.map(
			x=>(
				<Select.Option value={x.id} key={x.id} username={x.name} org={x.organization} >
					<div className="user-name">{x.name}</div>
					<div className="user-organzation">{x.organization}</div>
				</Select.Option>

			));
	}

	filterOption = (v:any,m:any|undefined)=>{
		if(m){
			if( m.username.indexOf(v)>=0){
				return true;
			}
			if(m.org.indexOf(v) >=0){
				return true
			}
		}
		return false;
	}

	onSelected =(v:number)=>{
		let selected = this.props.users.filter(x=>x.id===v)[0]
		this.setState({selected:selected})
		if(this.props.onSelected){
			this.props.onSelected(selected)
		}
	}


	render(): React.ReactNode {
		return (
		<Select showSearch
				value={this.state.selected.id}
				style={{width:"100%"}}
				filterOption={this.filterOption}
				onSelect={this.onSelected}
				>
			{this.renderSelectedItem()}
		</Select>)
	}
}


export default withTranslation()(UserSelector);

