import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import { Card, Modal, PageHeader, Tabs } from "antd";
import "./index.less"
import { TaskModel, ServiceModel, SERVICE_STATUS } from "../../../@mod";
import ServiceItemListView from "../../task-list";
import TaskDetailView from "../../task-list/task-detail";
import moment from "moment";
import StatusLogLine from "../../status-log-line";
import { convertProjectServiceStatusChangeLogDtoToModel, StatusLogInfoModel } from "../../status-log-line/@mod";
import GanttChart from "../../gantt-chart";
import { GanttValueDataModel } from "../../gantt-chart/@mod";
interface ServiceDetailViewModel extends WithTranslation {
    apiServer: ApiServer
    service?: ServiceModel
    taskList?: TaskModel[]
    genServiceOperationExtra: () => React.ReactNode
    reloadTaskList: (selectedService: ServiceModel) => void
}
interface ServiceDetailViewState {
    showTaskDetail: boolean
    selectedTask?: TaskModel
    statusLogOfService: StatusLogInfoModel[]
    loadedTaskProgressInfo: boolean
    taskProgressTimeList: GanttValueDataModel[]
    taskProgressTypeList: string[]
}
class ServiceDetailView extends React.Component<ServiceDetailViewModel, ServiceDetailViewState> {
    constructor(props: ServiceDetailViewModel) {
        super(props);
        this.state = {
            showTaskDetail: false,
            statusLogOfService: [],
            loadedTaskProgressInfo: false,
            taskProgressTimeList: [],
            taskProgressTypeList: []
        }
    }

    onDetail = (model: TaskModel) => {
        this.setState({
            selectedTask: model,
            showTaskDetail: true
        })
    }

    afterSubmitTask = () => {
        this.notShowTaskDetail()
        if (this.props.service) {
            this.props.reloadTaskList(this.props.service)
        }

    }

    notShowTaskDetail = () => {
        this.setState({
            showTaskDetail: false
        })
    }
    j
    loadStatusChangeLog = () => {
        if (this.props.service?.id) {
            this.props.apiServer.apiProjectInstance.getStatusChangeLog1(this.props.service?.id).then(r => {
                if (r.data.data?.dtos) {
                    this.setState({
                        statusLogOfService: r.data.data.dtos
                            .sort((l1, l2) => Number(l2.operateTime) - Number(l1.operateTime))
                            .map(x => {
                                return convertProjectServiceStatusChangeLogDtoToModel(x)
                            })
                    })
                }
            })
        }
    }

    onTabChange = (activeKey) => {
        switch (activeKey) {
            case "4":
                this.loadStatusChangeLog()
                break
            case "3":
                this.loadTaskProgressInfo()
                break
        }
    }

    loadTaskProgressInfo = () => {
        if (this.props.service) {
            this.props.apiServer.apiProjectInstance.getTaskProgressInfo(this.props.service.id).then(r => {
                if (r.data.data?.dtos) {
                    let types: string[] = []
                    let typesIndex = {}
                    let infoList = r.data.data.dtos
                        .sort((d1, d2) => {
                            let d1Type = d1.type ?? 0
                            let d2Type = d2.type ?? 0
                            return d1Type - d2Type
                        })
                        .map(d => {
                            let mid = d.milestoneId ? String(d.milestoneId) : ""
                            let isMilestone = d.type && d.type < 1000 && d.type > 1
                            if (isMilestone) {
                                types.push(d.title + "-" + this.props.t("project.service.milestone"))
                                typesIndex[mid] = types.length - 1
                            }
                            let res: GanttValueDataModel = {
                                name: d.title + (isMilestone ? "-" + this.props.t("project.service.milestone") : ""),
                                start: d.startDate ? moment.parseZone(d.startDate).format("YYYY-MM-DD HH:mm:ss") : "",
                                end: d.endDate ? moment.parseZone(d.endDate).format("YYYY-MM-DD HH:mm:ss") : "",
                                type: typesIndex[mid],
                                fastestEndDate: d.fastestEndDate ? moment.parseZone(d.fastestEndDate).format("YYYY-MM-DD HH:mm:ss") : "",
                                status: d.status ?? {}
                            }
                            return res
                        })
                    this.setState({
                        taskProgressTimeList: infoList,
                        taskProgressTypeList: types
                    })
                }
            })
        }
    }

    render() {
        let status = ""
        if (this.props.service?.status?.desc) {
            status = this.props.service?.status?.desc[this.props.i18n.language]
        }
        return (
            <div className={"card-bar"}>
                <PageHeader title={this.props.t("project.service.service-details-title")}
                    subTitle={this.props.t("project.service.service-details-sub-title")}
                    extra={this.props.genServiceOperationExtra()}>
                    <Card className={"card"} title={this.props.t("project.service.base-info")}>
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>
                                <p>{this.props.t("project.service.fields.title")}: {this.props.service?.title}</p>
                                <p>{this.props.t("project.service.fields.project")}: {this.props.service?.project.title + ":" + this.props.service?.project.version}</p>
                                <p>{this.props.t("project.service.fields.status")}: {status}</p>
                                {/* <p>{this.props.t("project.service.fields.status-comment") + ":" + status}</p> */}
                                <p>{this.props.t("project.service.fields.subscriber")}: {this.props.service?.createdBy.fullName}</p>
                            </div>
                            <div style={{ marginLeft: "8px", width: "50%" }}>
                                <p>{this.props.t("project.service.fields.description")}: {this.props.service?.description}</p>
                                <p>{this.props.t("project.service.fields.acceptor")}: {this.props.service?.acceptor.fullName}</p>
                                <p>{this.props.t("project.service.fields.subscribe-time")}: {moment.parseZone(this.props.service?.createdTime).format("YYYY-MM-DD HH:mm:ss")}</p>
                                <p>{this.props.t("project.service.fields.start-time")}: {this.props.service?.startTime ? moment.parseZone(this.props.service?.startTime).format("YYYY-MM-DD HH:mm:ss") : "暂未开始"}</p>
                            </div>
                        </div>
                    </Card>

                    <Card className={"card"}>
                        <Tabs onChange={this.onTabChange} destroyInactiveTabPane={true}>
                            <Tabs.TabPane tab={this.props.t("project.task.processing-task")} key={"0"}>
                                <ServiceItemListView
                                    apiServer={this.props.apiServer}
                                    taskList={
                                        this.props.taskList?.filter(
                                            d => (
                                                Number(d.status["code"]) === SERVICE_STATUS.PROCESSING
                                                || Number(d.status["code"]) === SERVICE_STATUS.REDOING
                                            )
                                                && Number(d.type["code"]) >= 1003)
                                    }
                                    onDetail={this.onDetail}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={this.props.t("project.task.finished-task")} key={"1"}>
                                <ServiceItemListView
                                    apiServer={this.props.apiServer}
                                    taskList={this.props.taskList?.filter(
                                        d => (Number(d.status["code"]) === SERVICE_STATUS.PENDING_ACCEPTANCE
                                            || Number(d.status["code"]) === SERVICE_STATUS.FINISHED
                                            || Number(d.status["code"]) === SERVICE_STATUS.TERMINATED
                                        )
                                            && Number(d.type["code"]) >= 1003)}
                                    onDetail={this.onDetail}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={this.props.t("project.task.task-progress")} key={"3"}>
                                <div className={"gantt-chart-container"}>
                                    <GanttChart
                                        data={this.state.taskProgressTimeList}
                                        types={this.state.taskProgressTypeList}
                                    // colors={["red", "green", "yellow", "blue"]}
                                    />
                                </div>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={this.props.t("project.service.status-log")} key={"4"}>
                                <div className="status-log-container">
                                    {
                                        this.state.statusLogOfService.length > 0
                                            ? <StatusLogLine statusLogList={this.state.statusLogOfService} />
                                            : this.props.t("project.service.status-log-empty")
                                    }
                                </div>
                            </Tabs.TabPane>
                        </Tabs>
                    </Card>
                </PageHeader>

                <Modal
                    visible={this.state.showTaskDetail}
                    footer={null}
                    onCancel={() => this.notShowTaskDetail()}
                    destroyOnClose={true}
                    style={{ top: 30 }}
                    width={1500}
                >
                    <TaskDetailView
                        taskToShow={this.state.selectedTask}
                        afterSubmitTask={this.afterSubmitTask}
                        apiServer={this.props.apiServer}
                    />
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(ServiceDetailView)