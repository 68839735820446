import React from "react";
import { red, volcano, orange, gold, yellow, lime, green, cyan, blue, geekblue, purple, magenta, grey, } from "@ant-design/colors";

const index = "primary";

export interface DataPoint {
    type: string,
    value: number,
    color?: string
}

export interface TimeValueDataModel {
    name: string,
    start:string,
    end:string,
    type:number
}


export const SERIAL_COLOR = [
    blue[index],
    gold[index],
    lime[index],
    yellow[index],
    green[index],
    orange[index],
    volcano[index],
    red[index],
    cyan[index],
    geekblue[index],
    purple[index],
    grey[index],
    magenta[index]
];