import { Select } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ActionModel } from "../../@mod";
import { EscalationRuleModel } from "../@mod";
import './index.less'

export interface ActionSelectorViewModel extends WithTranslation {
    actions:ActionModel[],
    rule:EscalationRuleModel
    onChanged ?:()=>void
}

interface ActionSelectorState {
    
}


class ActionSelector extends React.Component<ActionSelectorViewModel,ActionSelectorState>{

    constructor(props:ActionSelectorViewModel){
        super(props)
    }

    renderActions = ()=>{
        return this.props.actions.map(x=>{
            return (
            <Select.Option value={x.name} key={x.name}>
                {x.display[this.props.i18n.language]}
            </Select.Option>)
        })|| [];

    }

    onSelected = (v:string[])=>{
        this.props.rule.actions = v;
        this.props.onChanged?.();
    }

    render(): React.ReactNode {
        return (
        <div className="action-selector">
            <div className="title">
                <h3>{this.props.t("tickets.trigger-action")}</h3>
            </div>
            <div className="action-selector-editor">
                <div>{this.props.t("tickets.action-selector")}</div>
                <Select
                    value={this.props.rule.actions}
                    onChange={this.onSelected}
                    mode="tags"
                    style={{width:"100%"}}>
                    {this.renderActions()}
                </Select>
            </div>
            
        </div>
        )
    }
}


export default withTranslation()(ActionSelector)