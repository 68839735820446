import { notification, PageHeader } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import WorkBenchTable from "../../../components/table/workbench-table";
import { RemoteInstanceModel, pendingInstancesColumns, loadEnrollPendingInstances, loadRemovedInstances, loadRejectedInstances } from "../@mod"
import './index.less'


export interface RecycleBinViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface RecycleBinViewState {

    instances: RemoteInstanceModel[],
    dataloading: boolean

}

class RecycleBinView extends React.Component<RecycleBinViewModel, RecycleBinViewState>{


    constructor(props: RecycleBinViewModel) {
        super(props)
        this.state = {
            instances: [],
            dataloading: false
        }
    }

    loadData = async () => {
        this.setState({
            dataloading: true
        });
        let removedInstances = await loadRemovedInstances(this.props.apiServer);
        let rejectedInstances = await loadRejectedInstances(this.props.apiServer);

        let instances = removedInstances.concat(rejectedInstances);
        this.setState({
            instances: instances,
            dataloading: false
        });
    }

    l: Promise<void> | undefined;

    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData().finally(() => {
                this.setState({
                    dataloading: false
                })
            })
        }
    }

    ops = (t: RemoteInstanceModel, d: string) => {
        this.props.apiServer.apiCluster.putRemoteInstanceEnrollmentStatus(
            t.id, d
        ).then(x => {
            if (x.data.data?.success) {
                notification.success({ message: this.props.t("cluster-mgr.instance.operation-succeed") })

                this.setState({
                    instances: this.state.instances.filter(q => q.id !== x.data.data?.updatedInstance?.id)
                });
            }
            else {
                notification.error({
                    message: this.props.t("cluster-mgr.instance.operation-fail")
                })
            }
        });
    }


    onOperation = (t: RemoteInstanceModel, event: string) => {
        if (event === "approve") {
            this.ops(t, "accept");
        } else if (event === "reject") {
            this.ops(t, "deny");
        }

    }


    render(): React.ReactNode {
        return (<div className="recycle-container">
            <PageHeader title={this.props.t("cluster-mgr.recycle")} subTitle={this.props.t("cluster-mgr.recycle-description")} >
                <WorkBenchTable<RemoteInstanceModel>
                    data={this.state.instances}
                    dataLoading={this.state.dataloading}
                    columnProvider={(x) => { return pendingInstancesColumns(this.props.t, x, this.props.i18n.language, this.props.apiServer) }}
                    onEvent={this.onOperation}
                />
            </PageHeader>
        </div>)
    }
}


export default withTranslation()(RecycleBinView);