import { Button, Tag } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import ApiServer from "../../../../../ApiServer";
import { ActionEventHandler } from "../../../../../components";
import ColumnTextFilter from "../../../../../components/table/column-text-filter";



export interface TemplateInstance {
    title: string,
    subtitle: string,
    timelimit: number,
    type: number,
    status: number,
    priority: number,
    source: number,
    assignedGroup: number,
    assignedUser: number,
    tags: number[],
    contentSchema:number[]
    defaultContent:string
}


export interface NewTicketTemplateRecord {
    key?:number,
    id: number,
    title: string,
    template: TemplateInstance
    enabled :boolean,
    created: string,
    updated: string

}


export async function loadTicketTemplate(apiServer: ApiServer): Promise<NewTicketTemplateRecord[]> {
    return apiServer.apiProductivity.getTicketTemplates(true)
        .then(x => {
            let records: NewTicketTemplateRecord[] = []
            if (x.data.data?.success) {
                x.data.data.dtos?.forEach(y => {
                    let record: NewTicketTemplateRecord = {
                        key: y.id,
                        id: y.id ?? 0,
                        title: y.title ?? "",
                        created: y.createdTime ?? "",
                        updated: y.updatedTime ?? "",
                        template: JSON.parse(y.content ?? "{}"),
                        enabled: y.enabled??false
                    }
                    records.push(record);
                })
            }
            return records;
        });
}



export const ticketNewTemplateColumn = (translater: ((d: string) => string), handler: ActionEventHandler<NewTicketTemplateRecord>): ColumnType<NewTicketTemplateRecord>[] => {


    return [
        {
            title: translater("new-ticket-template.number"),
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: NewTicketTemplateRecord) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
        },
        {
            title: translater("new-ticket-template.title"),
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: NewTicketTemplateRecord) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: NewTicketTemplateRecord) => record.title.indexOf(value.toString()) >= 0,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("user-editor.name")} />)
            },

        }, {
            title: translater("new-ticket-template.created"),
            dataIndex: 'created',
            key: 'created',
            render: (value: any, record: NewTicketTemplateRecord, index: number) => {
                return (moment.parseZone(value).format(translater("time-pattern-long")))
            }
        }, {
            title: translater("new-ticket-template.updated"),
            dataIndex: 'updated',
            key: 'updated',
            render: (value: any, record: NewTicketTemplateRecord, index: number) => {
                return (moment.parseZone(value).format(translater("time-pattern-long")))
            }
        }
        , {
            title: translater("new-ticket-template.enabled"),
            dataIndex: 'enabled',
            key: 'enabled',
            render: (value: any, record: NewTicketTemplateRecord, index: number) => {
                
                if(value){
                    return <Tag color="green">{translater("new-ticket-template.enabled")}</Tag>
                }else{
                    return <Tag color="red">{translater("new-ticket-template.disabled")}</Tag>
                }
            }
        }
    ];
}