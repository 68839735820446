import { WithTranslation, withTranslation } from "react-i18next";
import React from "react";
import { Button, Form, Input, Select, Switch } from "antd";
import { deliverableSelections } from "../../../@mod";
import Question from "../../../../../components/question";
interface DeliverableEditorModel extends WithTranslation {
    onFinish: (values) => void
    onCancel: () => void
    deliverable?: any
}


interface DeliverableEditorState {

}

class DeliverableEditor extends React.Component<DeliverableEditorModel, DeliverableEditorState> {
    constructor(props: DeliverableEditorModel) {
        super(props);
        this.state = {

        }
    }


    render() {
        return (
            <div className={"deliverable-container"}>
                <Form
                    labelCol={{ span: 5 }}
                    onFinish={this.props.onFinish}
                >
                    <Form.Item label={this.props.t("project.template.deliverable.name")} name={"title"} rules={[{ required: true, message: this.props.t("project.template.input-deliverable-name") }]} initialValue={this.props.deliverable?.title}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={this.props.t("project.template.deliverable.description")} name={"description"} initialValue={this.props.deliverable?.description}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={
                            <span>
                                <Question answer={this.props.t("project.template.editor-comment.deliverable-type")} />&nbsp;
                                {this.props.t("project.template.deliverable.type")}
                            </span>
                        }
                        name={"type"} rules={[{ required: true, message: this.props.t("project.template.select-deliverable-type") }]} initialValue={this.props.deliverable?.type}>
                        <Select defaultValue={this.props.deliverable?.type}>
                            {
                                deliverableSelections(this.props.t).map(d => {
                                    return (
                                        <Select.Option value={d.value} key={d.value}>{d.text}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={this.props.t("project.template.deliverable.required")}
                        name={"required"}
                        rules={[{ required: true, message: this.props.t("project.template.deliverable.required") }]}
                        initialValue={this.props.deliverable?.required ?? false}>
                        <Switch
                            checkedChildren={this.props.t("project.template.required")}
                            unCheckedChildren={this.props.t("project.template.optional")}
                            defaultChecked={this.props.deliverable?.required ?? false}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div style={{ float: "right" }}>
                            <Button style={{ marginRight: "8px" }} onClick={this.props.onCancel}>{this.props.t("project.template.cancel")}</Button>
                            <Button type={"primary"} htmlType={"submit"}>{this.props.t("project.template.submit")}</Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
export default withTranslation()(DeliverableEditor)