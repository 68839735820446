export function isEmailAddress(email) {
    const regex = /([\w\.-_]+)@([\w\.-]+)/g;
    return regex.test(email);
}


const imageTag = /<img src=\"([\w:|/\-.?|&=;%]+)\"\/?>/g;

export function findReplaceTagOn(source, replacement) {
    var array = imageTag.exec(source);
    if (array) {
        for (var i = 1; i < array.length; i++) {
            replacement(array[i])
        }
    }
}