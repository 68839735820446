import moment from "moment-timezone";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import { DataPoint } from "../../../../../components/charts/@mod";
import PieChart from "../../../../../components/charts/pie-chart";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { ThreadTimeModel, TicketTimeModel, timeThreadColumnsProvider } from "./@mod";
import "./index.less";


export interface ParticipatorAnalysisViewModel extends WithTranslation {
    apiServer: ApiServer,
    userId: number
}

interface ParticipatedViewState {
    start: string,
    end: string,
    ticketTimeModels: TicketTimeModel[],
    dataloading: boolean
}



class ParticipatorAnalysis extends React.Component<ParticipatorAnalysisViewModel, ParticipatedViewState>{


    constructor(props: ParticipatorAnalysisViewModel) {
        super(props)
        let start = moment().format("yyyy-MM-01 00:00:00");
        let end = moment().add(1, "months").format("yyyy-MM-01 00:00:00");

        this.state = {
            start: start,
            end: end,
            ticketTimeModels: [],
            dataloading: false
        }

    }

    loadData = async () => {
        this.setState({
            dataloading: true
        })


        await this.props.apiServer.apiStatistic.getParticipatingTickets(
            this.props.userId,
            false,
            this.state.start,
            this.state.end
        ).then(x => {

            if (x.data.data?.success) {
                let records = x.data.data.participatedTickets?.map(r => {
                    let tm: TicketTimeModel = {
                        ticketId: r.ticketId ?? 0,
                        createAt: Number(r.createAt) ?? 0,
                        expectClose: Number(r.expectCloseAt) ?? 0,
                        creator: r.creator?.fullName ?? "",
                        closeAt: Number(r.closeAt) ?? 0,
                        creatorId: Number(r.creator?.id??0),
                        records: r.records?.map(rr => {
                            let ttm: ThreadTimeModel = {
                                start: Number(rr.start ?? "0"),
                                end: Number(rr.end ?? "0")
                            };
                            return ttm;
                        }) ?? []

                    }
                    return tm;

                }) ?? [];

                this.setState({
                    ticketTimeModels: records
                })
            }

        });

    }

    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.l = undefined;
                    this.setState({
                        dataloading: false
                    })

                })
        }
    }

    renderTicketTimeLine = () => {
        return (
            <WorkBenchTable<TicketTimeModel>
                data={this.state.ticketTimeModels}
                columnProvider={a => timeThreadColumnsProvider(this.props.t, a)}
                dataLoading={this.state.dataloading}


            />
        )
    }

    getCreateVsParticipated = ()=>{
        let dataPointParticipated :DataPoint ={
            value:this.state.ticketTimeModels.filter(x=>x.creatorId!==this.props.userId).length,
            type:this.props.t("statistics-tickets.participated"),
            color:"#5CCCD5"
        }

        let dataPointCreated :DataPoint ={
            value:this.state.ticketTimeModels.length- dataPointParticipated.value,
            type:this.props.t("statistics-tickets.created"),
            color:"#466AEB"
        }

        return [dataPointCreated,dataPointParticipated]
    }

    getTicketNormalVsDelay = () =>{
        let dataPointDelay : DataPoint ={
            value: this.state.ticketTimeModels.filter(x=>x.closeAt > x.expectClose).length,
            type: this.props.t("statistics-tickets.delay"),
            color:"#5CCCD5",
        }

        let dataPointNomral : DataPoint ={
            value: this.state.ticketTimeModels.length - dataPointDelay.value,
            type: this.props.t("statistics-tickets.normal"),
            color:"#466AEB",
        }
        return [dataPointDelay,dataPointNomral]
    }

    getTicketYourCauseVsOtherCause =() =>{
        let dataPointYourCause : DataPoint = {
            value : this.state.ticketTimeModels.filter(x=>{
                return x.records.findIndex(y=>y.end > x.expectClose && x.expectClose >y.start) >=0; 

            }).length,
            type: this.props.t("statistics-tickets.over-head"),
            color:"#5CCCD5",
        }

        let otherCause : DataPoint ={
            value: this.state.ticketTimeModels.length - dataPointYourCause.value,
            type: this.props.t("statistics-tickets.other-over-head"),
            color:"#466AEB",
        }

        return [dataPointYourCause,otherCause]

    }

    getTicketTotalCostVsYourCost = () => {
        let totalTicketCost = 0
        let yourCost = 0
        this.state.ticketTimeModels.forEach(element => {
            totalTicketCost += (element.closeAt-element.createAt)
            element.records.forEach(y=>{
                yourCost += (y.end-y.start)
            })
        });

        let dataPointYourCost : DataPoint = {
            value : Number(((yourCost /totalTicketCost)*100).toFixed(3)),
            type:this.props.t("statistics-tickets.your-cost-percent"),
            color:"#5CCCD5",
        }

        let dataPointOtherCost : DataPoint = {
            value : 100 - dataPointYourCost.value,
            type:this.props.t("statistics-tickets.other-cost-percent"),
            color:"#466AEB",
        }
        return [dataPointYourCost,dataPointOtherCost]
    }



    render(): React.ReactNode {
        return (
            <div className="participator-analysis-container">
                <div className="summary">
                    <div className="pie-block">
                        <PieChart
                            data={this.getCreateVsParticipated()}
                            height={240}
                        ></PieChart>
                    </div>
                    <div className="pie-block">
                        <PieChart
                            data={this.getTicketNormalVsDelay()}
                            height={240}
                        ></PieChart>
                    </div>
                    <div className="pie-block">
                        <PieChart
                            data={this.getTicketTotalCostVsYourCost()}
                            height={240}
                        ></PieChart>
                    </div>
                    <div className="pie-block">
                        <PieChart
                            data={this.getTicketYourCauseVsOtherCause()}
                            height={240}
                        ></PieChart>
                    </div>

                </div>
                <div>
                    {this.renderTicketTimeLine()}
                </div>
            </div>
        )
    }
}


export default withTranslation()(ParticipatorAnalysis);