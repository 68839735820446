import {AutoComplete, Button, Drawer, Input, PageHeader, Popconfirm} from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import WorkBenchTable from "../../../../components/table/workbench-table";
import './index.less'
import { columnsProvider, UserGroupModel } from './@mod';
import { ActionEventHandler } from "../../../../components";
import { BaseOptionType } from "antd/lib/select";
import { Content } from "antd/lib/layout/layout";
import ApiServer from "../../../../ApiServer";
import {  withRoute, WithRouteContext } from "../../../../utils";
import GroupEditor from "./usergroup-editor";


interface UserGroupsPageModel extends WithTranslation , WithRouteContext {
	apiServer: ApiServer,
}

interface UserGroupPageState {
	userGroupDataLoading: boolean,
	options: BaseOptionType[],
	userGroups: UserGroupModel[],
	selectedRows: UserGroupModel[],
	showEditor: boolean,
	onEditUserGroup: UserGroupModel
}

const DEFAULT_USER_GROUP = ["ADMINISTRATORS", "DEFAULT", "SUPERVISORS", "RESOLVERS"]

class UserGroupsPage extends React.Component<UserGroupsPageModel, UserGroupPageState>{

	constructor(props: UserGroupsPageModel) {
		super(props)
		this.state = {
			userGroupDataLoading: true,
			options: [],
			userGroups: [],
			showEditor: false,
			selectedRows: [],
			onEditUserGroup: {
				id: -1,
				name: "",
				description: "",
				key: -1,
				createdTime: "",
				updatedTime: "",
				userCount: 0
			}
		}
	}

	async loadData(){
		await this.props.apiServer.apiGroup.getUserGroups().then((r:any) => {
			let userGroup: UserGroupModel[] | undefined =
				r.data.data?.list?.map(x => {
					let u: UserGroupModel = {
						id: x.id ?? 0,
						name: x.name ?? "",
						description: x.description ?? "",
						createdTime: x.createdTime ?? "",
						updatedTime: x.updatedTime ?? "",
						userCount: x.userCount ?? 0,
						key: x.id ?? 0
					};
					return u;
				});
			if (userGroup) {
				this.setState({
					userGroups: userGroup,
					userGroupDataLoading: false
				})
			}
		});
	}
	l: Promise<void>|undefined;

	componentDidMount() {
		if(!this.l){
			this.l = this.loadData()
			.finally(()=>{
				this.l=undefined;
				this.setState({
					userGroupDataLoading:false
				}); 
			})
		}

		
	}

	newUserGroupCreate = () => {
		this.setState({
			showEditor: true
		})
	}

	onSearch = () => {

	}

	showDetail = (d: UserGroupModel) => {
		this.props.router?.navigate("/access-control/members/user-group/"+d.id);
	}

	editorClose = () => {
		this.setState({
			showEditor: false,
			onEditUserGroup: {
				id: -1,
				name: "",
				description: "",
				key: -1,
				createdTime: "",
				updatedTime: "",
				userCount: 0
			}
		})
	}

	saveNewUserGroup = (group: UserGroupModel) => {
		this.props.apiServer.apiGroup.postUserGroup({
			name: group.name,
			description: group.description
		}).then((r: any) => {
			if (r.data.data?.success) {
				this.editorClose()
				this.loadData().then(() => {});
			}
		});
	}

	onSelected = (selectedRows: UserGroupModel[]) => {
		selectedRows = selectedRows.filter(x => !DEFAULT_USER_GROUP.includes(x.name.toUpperCase()))
		this.setState({
			selectedRows: selectedRows
		})
	}

	delUserGroup = () => {
		if (this.state.selectedRows) {
			let selectedRowsId = this.state.selectedRows.map(x => {
				return x.id;
			});
			this.props.apiServer.apiGroup.deleteUserGroup({
				ids: selectedRowsId
			}).then(r => {
				if (r.data.data?.success) {
					this.loadData().then(() => {});
				}
			})
		}
	 };

	render(): React.ReactNode {
		return (
			<div className="user-group-container site-drawer-render-in-current-wrapper" id="user-container" >

				<PageHeader title={this.props.t("user-group")} extra={
					[
						<div className="user-global-button" key="global-button">
							<AutoComplete dropdownMatchSelectWidth={252}
								onSearch={this.onSearch}
								options={this.state.options}
								style={{ width: 300 }}
							>
								<Input.Search size="middle" placeholder={this.props.t("search")} enterButton loading={this.state.userGroupDataLoading} />
							</AutoComplete>
							<Popconfirm
								title={this.props.t("confirm-delete") + "?"}
								disabled={this.state.selectedRows.length < 1}
								onConfirm={this.delUserGroup}
								okText={this.props.t("confirm")}
								cancelText={this.props.t("cancel")}
								icon={<QuestionCircleOutlined style={{color: "red"}}/>}
							>
								<Button danger
										loading={this.state.userGroupDataLoading}
										disabled={this.state.selectedRows.length < 1}
								>
									{this.props.t("delete")}
								</Button>
							</Popconfirm>
							<Button type="primary" onClick={this.newUserGroupCreate} loading={this.state.userGroupDataLoading} >{this.props.t("insert")}</Button>
						</div>

					]}
					subTitle={this.props.t("user-group-editor.editor-subtitle")}
				>
					<Content>
						<WorkBenchTable<UserGroupModel>
							data={this.state.userGroups}
							dataLoading={this.state.userGroupDataLoading}
							onDetail={this.showDetail}
							columnProvider={
								(a: ActionEventHandler<UserGroupModel>) => columnsProvider(this.props.t, a)
							}
							onSelected={this.onSelected}
						/>
					</Content>

				</PageHeader>

				<Drawer
					closable={true}
					placement="right"
					visible={this.state.showEditor}
					getContainer={false}
					onClose={this.editorClose}
					size="large"
					title={this.props.t("user-group-editor.add-user-group")}
				>
					<GroupEditor userGroup={this.state.onEditUserGroup} cancel={this.editorClose} save={this.saveNewUserGroup}/>
				</Drawer>
			</div>)
	}
}


export default  withRoute(withTranslation()(UserGroupsPage));