import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import ServiceListView from "../component/service-list";
import { convertServiceDtoToModel, convertTaskDtoToModel, SERVICE_STATUS, ServiceModel, TaskModel } from "../@mod";
import { Button, message, Popconfirm } from "antd";

interface ServiceStartViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface ServiceStartViewState {
    serviceList: ServiceModel[]
    taskList: TaskModel[]
    showServiceDetails: boolean,
    selectedService?: ServiceModel
    dataLoading: boolean
}

class ServiceStartView extends React.Component<ServiceStartViewModel, ServiceStartViewState> {
    constructor(props: ServiceStartViewModel) {
        super(props);
        this.state = {
            serviceList: [],
            taskList: [],
            showServiceDetails: false,
            dataLoading: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        this.setState({
            dataLoading: true
        })
        this.props.apiServer.apiProjectInstance.getProjectInstanceOfOwnerByStatus([SERVICE_STATUS.APPROVED, SERVICE_STATUS.WAITING_FOR_WHETHER_REDO]).then(r => {
            if (r.data.data?.dtos) {
                let serviceList = r.data.data.dtos.map(model => convertServiceDtoToModel(model))
                this.setState({
                    serviceList: serviceList
                })
            }
        }).finally(() => this.setState({ dataLoading: false }))
    }

    onDetail = (model: ServiceModel) => {
        console.log("selected service: ", model);

        this.setState({
            showServiceDetails: true,
            selectedService: model
        })
        this.loadTaskList(model)
    }

    loadTaskList = (model: ServiceModel) => {
        if (model) {
            this.props.apiServer.apiProjectInstance.getItemInstanceListOfServiceInstance(model.id).then(r => {
                if (r.data.data?.dtos) {
                    let dtos = r.data.data.dtos;
                    let serviceItemList = dtos.map((dto) => convertTaskDtoToModel(dto))
                    this.setState({
                        taskList: serviceItemList
                    })
                }
            })
        }
    }

    startService = () => {
        if (this.state.selectedService) {
            const newStatus = this.state.selectedService.status.code === SERVICE_STATUS.APPROVED
                ? SERVICE_STATUS.PROCESSING : SERVICE_STATUS.REDOING
            this.props.apiServer.apiProjectInstance.changeServiceInstanceStatus(this.state.selectedService?.id, {
                newStatus: newStatus
            }).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("project.service.start-success")).then()
                    this.loadData()
                    this.setState({
                        showServiceDetails: false
                    })
                } else {
                    message.error(this.props.t("project.service.start-fail")).then()
                }
            })
        }
    }

    genServiceOperationExtra = () => {
        return (
            <div>
                <Popconfirm title={this.props.t("project.service.start-confirm")} onConfirm={this.startService}>
                    <Button type={"primary"}>{this.props.t("project.service.start-service")}</Button>
                </Popconfirm>
            </div>
        )
    }

    render() {
        return (
            <div>
                <ServiceListView
                    serviceLoading={this.state.dataLoading}
                    apiServer={this.props.apiServer}
                    serviceList={this.state.serviceList}
                    headerSubTitle={this.props.t("project.service.start-sub-title")}
                    headerTitle={this.props.t("project.service.start-title")}
                    genServiceOperationExtra={this.genServiceOperationExtra}
                    taskList={this.state.taskList}
                    onDetail={this.onDetail}
                    reloadTaskList={this.loadTaskList}
                    showServiceDetails={this.state.showServiceDetails}
                    closeServiceDetailView={() => this.setState({ showServiceDetails: false })}
                    selectedService={this.state.selectedService}
                />
            </div>
        );
    }
}

export default withTranslation()(ServiceStartView)