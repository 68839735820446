import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import ApiServer from "../../../ApiServer";
import ServiceListView from "../component/service-list";
import {convertServiceDtoToModel, convertTaskDtoToModel, ServiceModel, TaskModel} from "../@mod";

interface ServiceEngViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface ServiceEngViewState {
    serviceList: ServiceModel[]
    taskList: TaskModel[]
    showServiceDetails: boolean
    selectedService?: ServiceModel
    dataLoading: boolean
}

class ServiceEngView extends React.Component<ServiceEngViewModel, ServiceEngViewState> {
    constructor(props: ServiceEngViewModel) {
        super(props);
        this.state = {
            serviceList: [],
            taskList: [],
            showServiceDetails: false,
            dataLoading: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        this.setState({
            dataLoading: true
        })
        this.props.apiServer.apiProjectInstance.getProjectInstanceByParticipantAndParticipantType(2).then(r => {
            if (r.data.data?.dtos) {
                let serviceList = r.data.data.dtos.map(model => convertServiceDtoToModel(model))
                this.setState({
                    serviceList: serviceList
                })
            }
        }).finally(() => {
            this.setState({
                dataLoading: false
            })
        })
    }

    onDetail = (model: ServiceModel) => {
        this.setState({
            showServiceDetails: true,
            selectedService: model
        })
        this.loadTaskList(model)
    }

    loadTaskList = (model: ServiceModel) => {
        if (model) {
            this.props.apiServer.apiProjectInstance.getItemInstanceListOfServiceInstance(model.id).then(r => {
                if (r.data.data?.dtos) {
                    let dtos = r.data.data.dtos;
                    let serviceItemList = dtos.map((dto) => convertTaskDtoToModel(dto))
                    this.setState({
                        taskList: serviceItemList
                    })
                }
            })
        }
    }

    genServiceOperationExtra = () => {
        return (<></>)
    }

    render() {
        return (
            <div>
                <ServiceListView
                    serviceLoading={this.state.dataLoading}
                    apiServer={this.props.apiServer}
                    serviceList={this.state.serviceList}
                    headerSubTitle={this.props.t("project.service.eng-sub-title")}
                    headerTitle={this.props.t("project.service.eng-title")}
                    genServiceOperationExtra={this.genServiceOperationExtra}
                    taskList={this.state.taskList}
                    onDetail={this.onDetail}
                    reloadTaskList={this.loadTaskList}
                    showServiceDetails={this.state.showServiceDetails}
                    closeServiceDetailView={() => this.setState({showServiceDetails: false})}
                    selectedService={this.state.selectedService}
                />
            </div>
        );
    }
}

export default withTranslation()(ServiceEngView)