import {Progress, Tag} from "antd";
import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import ApiServer from "../../../../ApiServer";
import {loadEmailBoxState, StateModel} from "../../@mod";

interface MailBoxProgressModel extends WithTranslation {
    apiServer: ApiServer
    mailBoxId: number
    reportState?: (mailboxId: number, mailboxState: StateModel) => void
}

interface MailBoxProgressState {
    mailBoxState: StateModel
}


class MailBoxProgress extends React.Component<MailBoxProgressModel, MailBoxProgressState> {
    constructor(props: MailBoxProgressModel) {
        super(props)
        this.state = {
            mailBoxState: {
                total: 1,
                completed: 0,
                failed: 0,
                state: {},
                stateName: ""
            }
        }
    }

    timmer: any | undefined;

    componentDidMount() {
        this.loadMailboxState()
        if (!this.timmer) {
            this.timmer = window.setInterval(() => {
                this.loadMailboxState()
            }, 20000)
        }
    }

    loadMailboxState = () => {
        loadEmailBoxState(this.props.apiServer, this.props.mailBoxId)
            .then(x => {
                if (x) {
                    this.setState({
                        mailBoxState: x
                    })
                    this.props.reportState?.(this.props.mailBoxId, x)
                }
            })
    }

    componentWillUnmount() {
        if (this.timmer) {
            window.clearInterval(this.timmer);
        }
    }

    getPercent = () => {
        if (this.state.mailBoxState) {
            if (this.state.mailBoxState?.total === 0) {
                return 0;
            } else {
                return Math.round((this.state.mailBoxState?.failed + this.state.mailBoxState?.completed) / this.state.mailBoxState?.total * 100)
            }
        }
        return 0
    }


    render(): React.ReactNode {
        return (<>
            <Tag> {this.state.mailBoxState.state[this.props.i18n.language]}</Tag>
            {
                this.state.mailBoxState?.stateName === "synchronizing" &&
                <Progress percent={this.getPercent()} steps={10} />
            }

            {this.state.mailBoxState?.stateName === "synchronizing" &&
                <div>
                    {this.state.mailBoxState?.failed} / {this.state.mailBoxState?.completed} / {this.state.mailBoxState?.total}
                </div>
            }
        </>)
    }

}


export default withTranslation()(MailBoxProgress)