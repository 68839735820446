export default function getLanguageCN(obj) {
	var langCN = {
		"mail-contact": "联系人",
		"mail-contact-management": "联系人管理",
		"mailbox-binding": "邮箱绑定",
		"mail-template": "邮件模板",
		"mail-template-management": "模板管理",
		"mail-template-binding": "绑定邮箱",
		"mail-box-management": "邮箱管理",
		"mail-box-access": "访问控制",
		"mail-box-config": "邮箱配置",
		"mail-management": "邮箱管理",
		"mailbox-configure": "邮箱配置",
		"mail-box": "邮箱",
		"my-boxes": "我的邮箱",
		"mail-vendor": "邮箱提供商",
		"mail-approval": "邮件审批",
		"enrolled": "已接入",
		"tasks-management": "任务管理",
		"instances-management": "实例管理",
		"enrolled-instances": "已接入",
		"withdraw": "撤销实例",
		"enroll": "注册",
		"enrollment": "注册",
		"cluster": "集群管理",
		"mail": "邮件(Beta)",
		"time-cost": "耗时",
		"in-plan": "计划内",
		"voice":"语音",
		"over-due": "超时",
		"minutes": "分钟",
		"ticket-new-template": "工单新建模板",
		"ticket-new-template-desc": "创建和管理用于手工创建工单时的内容自动填充",
		"start-date-time": "开始时间",
		"create-ticket-new-template": "新建模板",
		"statistics": "统计",
		"overview": "概览",
		"participator": "参与者统计",
		"recycle-bin": "回收箱",
		"blocked": "已阻止",
		"operation": "系统操作",
		"reload-reflex-system": "重启响应系统",
		"_reload-reflex-system": {
			"sub-title": "后端每次发布之后获取响应系统docker容器编号重启",
			"reload": "重启",
			"input-docker-number": "请输入Docker容器id",
			"reload-success": "重启成功",
			"reload-fail": "重启失败",
		},
		"clear-abnormal-mailbox": "清除邮箱异常状态",
		"_clear-abnormal-mailbox": {
			"sub-title": "清除邮箱异常状态",
			"clear": "清理异常状态",
			"request-success": "请求成功",
		},
		"mails": {
			"schedule-8-hours-after":"8小时后",
			"schedule-1-day-after":"1天后",
			"schedule-1-week-after":"1周后",
			"schedule-date-time":"定时发送时间",
			"select-template": "选择模板...",
			"select-this-template": "选择此模板",
			"send-need-approval": "以下收件人不在该邮箱绑定的联系人列表内，该邮件需要审批后方能发送，是否申请发送？",
			"cancel-send": "取消发送",
			"continue-send": "继续发送",
			"apply-send": "申请发送",
			"no-to-exist": "缺少收件人",
			"sechdule-send-succeed": "加入调度成功",
			"delete-draft-failed": "删除草稿失败，请稍后重试",
			"delete-draft-succeed": "删除草稿成功",
			"save-draft-succeed": "保存草稿成功",
			"send-to-placeholder": "请选择或者填写收件人邮箱地址",
			"send-cc-placeholder": "请选择或者填写抄送人邮箱地址",
			"send-bcc-placeholder": "请选择或者填写密送人邮箱地址",
			"new-email-subject": "新邮件标题",
			"new-email-message": "",
			"new-email-body": "您好！\n",
			"decriptions": "邮件描述-（选填）",
			"split-send": "分开发送",
			"normal-send": "正常发送",
			"attachment": "邮件附件",
			"subject": "邮件主题",
			"send-box": "发件箱",
			"send-to": "收件人",
			"send-cc": "抄送",
			"send-bcc": "密送",
			"mail-detail": "邮件内容",
			"message-target": "邮件信息",
			"delete": "删除",
			"send": "发送",
			"send-scheduled":"定时发送",
			"save-draft": "保存草稿",
			"recv-email": "收件箱",
			"out-box": "发件箱",
			"mail-folder": "文件夹",
			"new-email": "新邮件",
			"mail-address": "邮箱",
			"mail-subject": "标题",
			"no-subject": "无标题",
			"reply": "回复",
			"reply-all": "回复所有",
			"attachment-list": "附件列表",
			"load-more": "加载更早",
			"mail-title": "标题",
			"mail-configure": "邮箱配置",
			"mail-vendor": "邮箱提供商",
			"mail-box": "邮箱",
			"assigned-to-me": "我的邮箱",
			"mail-enroll": "邮箱登记",
			"mail-vendor-list": "所有提供商",
			"my-boxes": "我的邮箱",
			"mail-management": "邮件管理",
			"access-control": "访问控制",
			"mail-approval": "邮件审批",
			"newest-mails": "最新邮件",
			"apply-success": "申请成功",
			"apply-fail": "申请失败",
			"conf": {
				"email-password-info": "请输入正确的邮箱登陆密码，一般来说，登陆密码和邮箱密码一致，但取决于不同的邮件供应商，也有可能针对不同协议采用不同密码。",
				"email-address-info": "请输入正确的邮箱地址，用于建立客户端链接",
				"email-password-confirm-info": "请输入与登陆密码一样的密码",
				"publicIp": "公网IP",
				"unregister-selection": "删除选中",
				"new-mail-box": "新邮箱",
				"search": "搜索",
				"id": "序号",
				"email-address": "邮件地址",
				"emailAddress": "邮件地址",
				"basic-conf": "基础配置",
				"deploy-conf": "部署配置",
				"info-conf": "信息配置",
				"email-provider": "邮件提供商",
				"login-password": "登陆密码",
				"confirm-password": "确认密码",
				"deploy-location": "部署位置",
				"tags": "标签",
				"save": "保存",
				"reset": "重置",
				"input-validation-fail": "请正确填写邮箱的相关信息。",
				"createTime": "创建时间",
				"updateTime": "更新时间",
				"latestUpdater": "最后更新人",
				"vendor": "提供商",
				"instances": "部署实例",
				"create-mailbox-succeed": "更新/创建邮箱成功",
				"state": "邮箱状态",
			},
			"access-control": {
				"new-access-granted": "新增授权",
				"delete-access-granted": "取消授权",
				"search": "搜索",
				"desc": "权限配置",
				"emailAddress": "邮箱地址",
				"lastestUpdate": "最后更新人",
				"created": "创建时间",
				"lastUpdated": "最后更新时间",
				"serial": "序列",
				"grant": "授权",
				"can-recv": "收取",
				"can-not-recv": "不收取",
				"can-send": "发送",
				"can-not-send": "不发送",
				"send-limt": "发送限制",
				"recv-limt": "接收限制",
				"recv-limit-info": "接收限制:单位为天数，如30，即只能接收30天内的邮件",
				"send-limit-info": "发送限制：单位为封每天，如30，即当天只能发送最多30封邮件",
				"save-access": "保存",
				"reset-access": "重置",
				"save-access-succeed": "保存成功",
				"save-access-fail": "保存失败",
				"new-access-added-success": "新增授权成功！",
				"revoke-access-succeed": "撤销权限成功",
				"can-approve": "可审批",
				"can-not-approve": "无法审批",

			},
			"email-vendor": {
				"name": "提供商名称",
				"send-endpoint": "发送地址",
				"send-protocol": "发送协议",
				"send-port": "发送端口",
				"send-auth": "启用发送授权",
				"send-startSsl": "发送使用StartTLS",
				"enabled-start-tls": "启用StartTLS",
				"disabled-start-tls": "未启用StartTLS",
				"enabled-ssl": "启用SSL",
				"disabled-ssl": "未启用SSL",
				"receive-endpoint": "接收地址",
				"receive-protocol": "接收协议",
				"receive-port": "接收端口",
				"receive-auth": "启用接收授权",
				"receive-ssl": "接收启用SSL",
				"enable-receive-auth": "已启用接收授权",
				"enable-send-auth": "已启用发送授权",
				"disable-receive-auth": "未启用接收授权",
				"disable-send-auth": "未启用发送授权",
				"edit": "编辑",
				"combined-mailbox": "已绑定邮箱"
			},
			"mailbox-configure": "邮箱配置",
			"mail-contact": {
				"desc": "联系人",
				"management": "联系人管理",
				"mailbox-binding": "邮箱绑定",
				"bind":"绑定"
			},
			"mail-template": {
				"fields": {
					"id": "编号",
					"content": "模板内容",
					"creator": "创建人",
					"create-time": "创建时间",
					"updator": "修改人",
					"update-time": "修改时间",
					"title": "标题",
					"tags": "标签",
				},
				"base-info": "基础信息",
				"desc": "邮件模板",
				"management": "模板管理",
				"management-sub-title": "管理用于发送邮件的模板",
				"delete": "删除",
				"binding": "绑定邮箱",
				"unbind": "解绑",
				"confirm-unbind": "确认解绑吗？",
				"edit": "修改",
				"confirm-delete": "确认删除吗？",
				"binding-title": "绑定邮箱",
				"editor-title": "编辑邮件模板",
				"submit": "提交",
				"submitting": "提交中",
				"cancel": "取消",
				"required-title": "标题是必需的",
				"required-content": "模板内容是必需的",
				"edit-success": "修改成功",
				"edit-fail": "修改失败",
				"bind-new-mailbox": "绑定新邮箱",
				"bound-mailboxes": "已绑定邮箱",
				"bound-templates": "已绑定模板",
				"bind-to-mailbox": "绑定到此邮箱",
				"mailbox": "邮箱",
				"select-mailbox": "请选择邮箱",
				"select-template": "请选择模板",
				"binding-header-title": "绑定邮箱",
				"binding-header-sub-title": "绑定邮箱与邮件模板关系",
				"new-template": "新增模板",
				"delete-success": "删除成功",
				"delete-fail": "删除失败",
				"save-success": "保存成功",
				"save-fail": "保存失败",
				"bind-success": "绑定成功",
				"bind-fail": "绑定失败",
				"unbind-success": "解绑成功",
				"unbind-fail": "解绑失败",
				"tags": "模板标签",
				"loading": "拼命加载中...",
				"all-templates": "所有模板",
				"operation": "操作",
				"template": "模板",
				"view": "查看",
				"template-view": "查看模板",
			},
			"contact": {
				"tagging":"添加标签",
				"delete":"删除",
				"import-title":"导入联系人",
				"import":"导入",
				"import-csv-required":"无法进行导入操作,仅支持CSV格式",
				"fields": {
					
					"id": "联系人编号",
					"emailAddress": "邮箱地址",
					"nickname": "昵称",
					"tag": "标签",
					"extendsInfo": "拓展信息",
					"enable": "是否启用",
					"createdTime": "创建时间",
					"createdBy": "创建人",
					"updatedTime": "更新时间",
					"updatedBy": "更新人",
				},
				"yes": "是",
				"no": "否",
				"operation": "操作",
				"add-contact": "添加联系人",
				"manager-title": "联系人管理",
				"manager-sub-title": "管理邮件联系人基础信息",
				"delete-selected": "删除选中",
				"input-email-address": "请输入邮件地址",
				"input-nickname": "请输入昵称",
				"extends-add": "添加拓展信息",
				"extends-key": "拓展信息名称",
				"extends-value": "拓展信息值",
				"extends-miss-key": "拓展信息名称不能为空",
				"extends-miss-value": "拓展信息值不能为空",
				"submit": "提交",
				"edit": "编辑",
				"delete": "删除",
				"save-success": "保存成功",
				"save-failed": "保存失败",
				"confirm-delete": "确认删除吗？",
				"delete-success": "删除成功",
				"delete-fail": "删除失败",
				"click-view": "点击查看",
				"extends-info": "拓展信息",
				"to": "接收人",
				"cc": "抄送人",
				"bcc": "密送人",
				"can-not-contains-chiness": "不能包含中文",
				"binding": {
					"fields": {
						"bindingTime": "绑定时间",
						"operator": " 操作人员",
					},
					"header-title": "邮箱绑定",
					"header-sub-title": "绑定联系人与邮箱关系",
					"new-binding": "新增绑定",
					"binding-success": "绑定成功",
					"binding-fail": "绑定失败",
					"unbinding": "解绑",
					"confirm-unbinding": "确定解绑吗？",
					"unbinding-success": "解绑成功",
					"unbinding-fail": "解绑失败",
					"binding-editor-title": "新增邮箱绑定关系",
					"contact": "联系人",
					"select-contact": "请选择联系人",
					"binding-type": "绑定类型",
					"select-binding-type": "请选择绑定类型",
					"binding-type-to": "接收人",
					"binding-type-cc": "抄送人",
					"binding-type-bcc": "密送人",
				}
			},
			"approval": {
				"page-title": "邮件审批",
				"page-sub-title": "需要通过审批才能发送的邮件",
				"id": "邮件编号",
				"mailBox": "邮箱",
				"emailTitle": "邮件标题",
				"emailDesc": "邮件描述",
				"creator": "创建人",
				"createTime": "创建时间",
				"updater": "更新人",
				"updateTime": "更新时间",
				"operation": "操作",
				"view-detail": "查看详情",
				"approve": "通过",
				"reject": "拒绝",
				"confirm-approve": "确认通过审批吗？",
				"confirm-reject": "确认拒绝审批吗？",
				"email-details": "邮件详情",
				"base-info": "基础信息",
				"contacts": "联系人（红色标记的表示未在该邮箱联系人列表内）",
				"mail-content": "邮件内容",
				"attachment": "附件",
				"send-to": "收件人",
				"send-cc": "抄送",
				"send-bcc": "密送",
				"no-contacts": "无联系人",
				"approve-success": "审批成功",
				"approve-fail": "审批失败",
			}
		},
		"cluster-mgr": {
			"enroll-pending": "实例注册",
			"enroll-pending-description": "正在申请加入到集群的实例",
			"instance": {
				"name": "实例名称",
				"identifier": "实例唯一标识",
				"accept-enroll": "重新注册",
				"block": "阻止实例",
				"instance-types": {
					"email": "电子邮箱",
					"crawler": "爬虫"
				},
				"resume-active": "恢复",
				"publicIp": "公网IP",
				"type": "实例类型",
				"accessIp": "访问IP",
				"tags": "标签",
				"approval": "审批",
				"operation": "操作",
				"approve-enroll": "允许加入",
				"reject-enroll": "拒绝加入",
				"status": "状态",
				"instance-status": {
					"enroll-pending": "等待接入注册",
					"enrolled": "正在接入",
					"removed": "已移除",
					"blocked": "已阻止",
					"enroll-reject": "拒绝注册",
					"normal-active": "激活"
				},
				"operation-succeed": "操作成功",
				"operation-fail": "操作失败",
				"remove": "移出集群",
				"connective-status": {
					"desc": "连接状态"
				}
			},

			"enrolled": "已接入实例",
			"enrolled-description": "实例已经接入或者已经活跃",
			"blocked": "已阻止实例",
			"blocked-description": "实例被阻止, 活跃链路保持心跳, 非活跃链路不准连接",
			"recycle": "待回收实例",
			"recycle-description": "被标记为移除或者拒绝的实例将被回收，链路中断，不允许连接"
		},
		"statistics-tickets": {
			"overview": "系统概览(每小时更新)",
			"overview-menu": "系统概览",
			"agg-tickets-type-include-closed": "按工单类型（包含已关闭）",
			"agg-tickets-type": "按工单类型",

			"agg-tickets-priority-include-closed": "按工单优先级（包含已关闭）",
			"agg-tickets-priority": "按工单优先级",

			"agg-tickets-source-include-closed": "按工单来源（包含已关闭）",
			"agg-tickets-source": "按工单来源",

			"agg-tickets-status": "按状态",
			"today-updated": "今日更新",
			"today-close": "今日关闭",
			"today-time-up": "今日超时",
			"updated": "更新工单",
			"close": "关闭工单",
			"time-up": "当天即将到期",
			"created": "创建工单",
			"participator-menu": "参与者",
			"participated": "工单参与者统计",
			"participated-overview": "总览",
			"add-participator": "添加分析",
			"ticket-number": "工单号",
			"created-at": "创建时间",
			"expect-close-at": "期望关闭时间",
			"close-at": "实际关闭时间",
			"creator": "创建人",
			"cost": "耗时(分钟)",
			"effe": "效率",
			"time-series": "时序",
			"bring-forward": "提前",
			"delay": "延期",
			"completed": "分钟",
			"participated-detail": "参与",
			"created-detail": "创建",
			"normal": "正常",
			"over-head": "跨越时效",
			"other-over-head": "正常",
			"your-cost-percent": "耗时%",
			"other-cost-percent": "其它%"

		},
		"tickets-statistics": "工单",
		"end-date-time": "结束时间",
		"template": "模板",
		"my-related-ticket": "我的关注",
		"organazation": "组织",
		"full-name": "全名",
		"account-status": "账户信息",
		"login-as": "登陆为",
		"time-pattern-long-second": "YYYY年MM月DD日 HH:mm:ss",
		"time-pattern-long": "YYYY年MM月DD日 HH:mm",
		"plugin-installed": "已安装的插件",
		"from-server": obj,
		"automation": "自动化",
		"installed": "已安装",
		"home": "首页",
		"system": "系统设置",
		"process": "流程自动化",
		"ticket-search": "工单搜索",
		"service": "项目(Preview)",
		"market": "服务集市",
		"subscribed": "订阅服务",
		"accept": "验收服务",
		"launched": "指派服务",
		"eng": "参与服务",
		"approve": "审批服务",
		"start": "启动服务",
		"processing": "正在进行",
		"statistics-project": "项目统计",
		"templates-project": "项目模板",
		"export": "导出",
		"performance": "效能管理",
		"escalation": "升级机制",
		"escalations": {
			"self": "升级机制",
			"description": "定义对下沉的工单进行通知和翻新机制",
			"add-rules": "新增规则"
		},
		"new-ticket-template": {
			"assignToCreator": "指派给创建人",
			"assignToCreatorDecription": "默认情况下会指派给创建人，如果创建人不在组中，则指派给默认指派人",
			"title": "模板名称",
			"new-ticket-title": "新工单标题",
			"new-ticket-subtitle": "新工单子标题",
			"created": "创建时间",
			"updated": "更新时间",
			"number": "编号",
			"save": "保存",
			"cancle": "取消",
			"ticket-title": "工单标题",
			"ticket-subtitle": "工单子标题",
			"sla": "默认完成需要时间（小时）",
			"new-title": "新建模板",
			"enabled": "启用",
			"disabled": "停用",
			"default-group": "默认用户组",
			"default-assignee": "默认指派人",
			"default-template": "预置表单",
			"default-content": "预置内容",
			"new-ticket-template-create-succeed": "工单初始化模板新建成功",
			"new-ticket-template-create-fail": "创建失败，请注意名称不要重复",
			"new-ticket-template-update-succeed": "工单初始化模板更新成功",
			"new-ticket-template-update-fail": "更新失败，请注意名称不要重复"

		},
		"project": {
			"workbench": {
				"title": "项目工作台",
				"sub-title": "开始快乐的一天吧",
				"service-count-to-describe": "待订阅服务数量",
				"service-count-to-approve": "待审批服务数量",
				"service-count-to-start": "待开始服务数量",
				"service-count-to-accept": "待验收服务数量",
				"click-to-handle": "点击前往处理",
				"deadline-calendar": "任务截止日历",
				"deadline-today": "今日截止任务",
				"deadline-out-date": "已超时任务",
			},
			"menus": {
				"landing": "开始",
				"market": "服务集市",
				"my-subscribed": "我的订阅",
				"my-service": "我的服务",
				"my-project": "我的项目",
				"subscribed-services": "订阅服务",
				"acceptance-services": "验收服务",
				"launched-services": "指派服务",
				"participate-services": "参与服务",
				"approval-services": "审批服务",
				"start-services": "启动服务",
				"processing-services": "正在进行",
				"project-statistic": "项目统计",
				"project-template": "项目模板",
			},
			"service": {
				"fields": {
					"id": "服务ID",
					"title": "服务标题",
					"project": "所属项目",
					"description": "服务描述",
					"status": "状态",
					"status-comment": "状态留言",
					"subscriber": "订阅人",
					"acceptor": "验收人",
					"subscribe-time": "订阅时间",
					"start-time": "开始时间",
				},
				// market
				"market-title": "项目服务集市",
				"market-sub-title": "在服务集市中寻找你所需的服务",
				"input-keyword": "输入关键字搜索",
				"search": "搜索",
				"view-details": "详情",
				"subscribe": "订阅",
				"subscribe-success": "订阅成功",
				"subscribe-fail": "订阅失败",
				// subscribe
				"subscribe-title": "订阅服务",
				"subscribe-sub-title": "你所订阅的服务都在此处",
				"submit-application": "提交申请",
				"submit-application-confirm": "确认提交申请吗？",
				"submit-application-success": "提交申请成功，请等待审批",
				"submit-application-fail": "提交申请失败",
				"delete-draft": "删除",
				"delete-draft-confirm": "确认删除吗？",
				"delete-draft-success": "删除成功",
				"delete-draft-fail": "删除失败",
				"application-title": "申请标题",
				"application-description": "申请说明",
				"input-application-title": "请输入申请标题",
				"select-acceptor": "请选择验收人",

				// accept
				"acceptance-title": "验收服务",
				"acceptance-sub-title": "你所需要验收的服务都在此处",
				"acceptance-service": "验收服务",
				"acceptance-success": "验收成功",
				"acceptance-fail": "验收失败",
				"whether-pass": "是否通过验收",
				"select-whether-pass": "请选择是否通过验收",
				"pass": "通过",
				"not-pass": "不通过",
				"cancel": "取消",
				"confirm": "确定",
				"accept-desc": "验收意见",
				"input-accept-desc": "请输入验收意见",
				// launched
				"launched-title": "指派服务",
				"launched-sub-title": "你被指派的服务都在此处",
				// eng
				"eng-title": "参与服务",
				"eng-sub-title": "你参与的服务都在此处",
				// approve
				"approve-title": "审批服务",
				"approve-sub-title": "你所需审批的服务都在此处",
				"approve": "审批",
				"approve-service": "审批服务",
				"approve-success": "审批成功",
				"approve-fail": "审批失败",
				"select-whether-pass-approval": "请选择是否通过审批",
				"whether-pass-approval": "是否通过",
				"approve-desc": "审批意见",
				"input-approve-desc": "请输入审批意见",
				"start-time": "开始时间",
				"start-now": "立即开始",
				"start-later": "稍后开始",
				//start
				"start-title": "启动服务",
				"start-sub-title": "等待你启动的服务都在此处",
				"start-success": "启动成功",
				"start-fail": "启动失败",
				"start-confirm": "确认启动服务吗？",
				"start-service": "启动服务",
				// processing
				"processing-title": "正在处理",
				"processing-sub-title": "正在处理中的服务",
				"confirm-terminate-service": "确认终止服务吗？",
				"terminate-service": "终止服务",
				"teminated-success": "终止服务成功",
				"teminated-failed": "终止服务失败",
				"terminate-reason": "终止缘由",
				"please-input-reason-for-termination": "请输入终止缘由",
				// statistic
				"statistic-title": "项目服务情况统计",
				"statistic-sub-title": "统计项目服务情况",
				"project-title": "项目名称",
				// details
				"service-details-title": "服务详情",
				"service-details-sub-title": "展示服务详情",
				"base-info": "基础信息",
				"status-log": "服务状态日志",
				"status-log-empty": "暂无状态日志",
				"milestone": "里程碑",
			},
			"task": {
				"fields": {
					"id": "任务ID",
					"title": "任务名称",
					"type": "任务类型",
					"description": "描述",
					"status": "状态",
					"executor": "执行人",
					"redo-count": "重做次数",
					"sub-item": "是否子任务",
					"leaf": "是否叶子节点",
					"service": "所属服务",
					"pre": "前置任务",
					"parent": "父任务",
					"start-time": "开始时间",
					"end-time": "实际结束时间",
					"lower-end-time": "预计最快结束时间",
					"higher-end-time": "预计最慢结束时间",
				},
				"processing-task": "正在进行的任务",
				"finished-task": "已完结的任务",
				"not-start-task": "尚未开始的任务",
				"task-progress": "任务进度情况",
				"task-detail-title": "任务详情",
				"task-detail-sub-title": "展示任务详情的页面",
				"base-info": "基础信息",
				"deliverable": "任务交付物",
				"knowledge": "任务资料",
				"change-executor": "更换执行人",
				"submit-task": "提交任务",
				"confirm-submit-task": "确认提交任务吗？",
				"yes": "是",
				"no": "否",
				"task-deliverable": {
					"name": "交付物名称",
					"storage": "存储类型",
					"uploader": "上传人",
					"upload-time": "上传时间",
					"operation": "操作",
				},
				"task-knowledge": {
					"name": "资料名称",
					"storage": "存储类型",
					"creator": "创建人",
					"create-time": "创建时间",
					"operation": "操作",
				},
				"delete": "删除",
				"download": "下载",
				"not-start": "暂未开始",
				"not-end": "暂未结束",
				"statusChangeLog": "任务状态变更日志",
				"update-executor-success": "更换执行人成功",
				"update-executor-fail": "更换执行人失败",
				"submit-success": "提交成功",
				"submit-fail": "提交失败",
				"delete-success": "删除成功",
				"delete-fail": "删除失败",
				"confirm": "确认",
				"cancel": "取消",
				"cannot-delete-submited": "任务已提交，不能删除交付物",
				"confirm-delete-deliverable": "确认删除此交付物吗?",
				"start-follow-up-task": "启动后续任务",
				"start-follow-up-task-success": "启动后续任务成功",
				"start-follow-up-task-failed": "启动后续任务失败",
				"current-status": "当前状态",
				"start-time": "开始时间",
				"fast-end-time": "预计最快结束时间",
				"slow-end-time": "预计最慢结束时间",
				"remaining-time": "任务剩余时间",
				"end-time": "结束时间",
			},
			"template": {
				"fields": {
					"id": "项目编号",
					"title": "项目名称",
					"description": "描述",
					"version": "版本",
					"owner": "所属者",
					"status": "项目状态",
					"creator": "创建人",
					"create-time": "创建时间",
				},
				"deliverable": {
					"name": "名称",
					"description": "描述",
					"type": "类型",
					"required": "是否必须",
					"operation": "操作",
				},
				"task": {
					"fields": {
						"name": "名称",
						"description": "描述",
						"type": "类型",
						"executor": "执行人",
						"required": "是否必须",
						"estimated": "预计时间",
						"operation": "操作",
						"lower-limit": "预计最短完成时间",
						"higher-limit": "预计最长完成时间",
					},
					"hour": "小时",
					"types": {
						"file": "文件",
						"parallel_task": "并序任务",
						"serial_task": "顺序任务",
						"ticket": "工单",
						"meeting": "会议纪要",
						"serial_milestone": "顺序里程碑",
						"parallel_milestone": "并序里程碑",
						"form": "表单",
						"process": "流程",
					},
					"meta": {
						"fields": {
							"id": "任务编号",
							"title": "任务名称",
							"description": "任务描述",
							"type": "任务类型",
							"owner": "所属者",
							"executor": "执行者",
							"lowerEstimatedTime": "预计最长执行时间",
							"higherEstimatedTime": "预计最短执行时间",
							"createdTime": "创建时间",
						},
						"list-title": "任务元信息列表",
						"operation": "操作",
						"edit": "编辑",
						"upload-knowledge": "上传任务资料",
						"hour": "小时",
						"undefined": "未定义",
						"update-success": "更新成功",
					}
				},
				"editor-comment": {
					"title": "项目名称",
					"version": "点击进行修改，相同项目下不能存在相同的版本号",
					"owner": "点击进行修改，指定该项目所有者，所有者将具备审批职责",
					"save-draft": "将已有内容保存为草稿，之后可继续编辑",
					"publish-project": "发布项目，可选择是否激活，若选择激活将冻结该项目其余版本",
					"project-description": "描述该项目的主要用途",
					"pre-requirement": "项目启动的先决条件，一般可用于定义服务订阅者所需交付的物件",
					"milestone": "项目里程碑，里程碑之间将按定义的顺序串行执行",
					"deliverable-type": "交付物类型",
					"milestone-type": "里程碑类型，顺序里程碑下任务将按顺序串行执行，并序里程碑下任务将同时执行",
					"task-type": "任务类型，顺序任务下的子任务将按顺序串行执行，并序任务下的子任务将同时执行",
					"task-executor": "任务执行人，顺序任务与并序任务不需要执行人",
					"batch-operation": "对任务进行批量操作",
				},
				"yes": "是",
				"no": "否",
				"template-title": "项目模板管理",
				"template-sub-title": "管理项目模板",
				"active": "激活",
				"freeze": "冻结",
				"success": "成功",
				"fail": "失败",
				"upgrade-template": "升级模板",
				"project-title": "项目名称",
				"search": "筛选",
				"editor": "项目模板编辑器",
				"publish": "发布项目",
				"confirm-blank": "确认",
				"continue-editing": "继续编辑",
				"delete-draft": "删除草稿",
				"save-success": "保存成功",
				"save-fail": "保存失败",
				"publish-success": "发布成功",
				"publish-fail": "发布失败",
				"input-title": "请在此处输入项目名称",
				"input-description": "请在此处输入项目描述",
				"input-version": "请在此处修改版本号",
				"add-deliverable": "新增交付物",
				"edit-deliverable": "编辑交付物",
				"add-task": "新增任务",
				"add-task-disable": "禁用状态，仅类型为顺序任务或并序任务可用",
				"edit-task": "编辑任务",
				"add-milestone": "新增里程碑",
				"delete": "删除",
				"confirm-delete": "确认删除吗？",
				"cancel": "取消",
				"confirm": "确定",
				"move-up": "上移",
				"click-edit-version": "点击修改版本号",
				"click-edit-owner": "点击修改所有者",
				"save-draft": "保存草稿",
				"pre-requirement": "先决条件",
				"template-view-title": "项目详情",
				"template-description-title": "项目描述",
				"milestone": "项目里程碑",
				"version": "版本号",
				"owner": "所有者",
				"deliverable-content": "交付物内容以及要求",
				"input-deliverable-name": "请输入交付物名称",
				"select-deliverable-type": "请选择交付物类型",
				"select-required": "请选择是否必须",
				"required": "必须交付",
				"optional": "可选交付",
				"submit": "提交",
				"milestone-name": "里程碑名称",
				"input-milestone-name": "请输入里程碑名称",
				"milestone-type": "里程碑类型",
				"select-milestone-type": "请选择里程碑类型",
				"description": "描述",
				"input-task-name": "请输入任务名称",
				"select-task-type": "请选择任务类型",
				"select-task-executor": "请选择任务执行人",
				"time-placeholder": "单位为小时",
				"whether-active-after-publish": "发布后是否激活该项目?",
				"pre-required": "资料收集",
				"task-manager": "任务管理",
				"batch-operation": "批量操作",
				"update-executor": "更换执行者",
				"greater-than-or-equal-zero": "需为非负数",
				"greater-than-zero": "需大于0",
				"greater-than-lower-time": "需不小于最短完成时间",
				"input-higher-limit": "请输入预计最长完成时间",
				"input-lower-limit": "请输入预计最短完成时间",
				"ticket": {
					"subject": "工单标题",
					"subtitle": "工单子标题",
					"priority": "工单优先级",
					"subject-placeholder": "工单标题默认为项目名称",
					"subtitle-placeholder": "工单子标题默认为该任务名称",
					"priority-placeholder": "工单优先级默认为普通",
				}
			},
			"confirm-select-user": "确认选择此用户吗？",
		},
		"system-setting": {
			"self": "系统设置项",
			"description": "可用系统配置项",
			"latest-update": "-最后更新时间:",
			"save": "保存",
			"reset": "重置",
			"save-success": "保存成功",
			"save-failed": "保存失败",
			"confirm-reset-title": "确认重置为默认值吗？",
			"reset-success": "重置成功",
			"reset-failed": "重置失败"
		},
		"dataProxy": {
			"unauthorized": "数据权限未开通"
		},
		"logoff": "退出",
		"user": "用户",
		"plugin": "插件",
		"accessControl": "访问控制",
		"access-control": "访问控制",
		"sms": "短信",
		"notification": "通知",
		"email": "电子邮件",
		"cropwechat": "企业微信",
		"feishu": "飞书",
		"members": "成员管理",
		"users": "用户",
		"user-group": "用户组",
		"security": "安全",
		"privilege": "权限",
		"policy": "策略",
		"productivity": "生产力",
		"templates": "表单模板",
		"tags": "标签",
		"ticket": "工单",
		"template-editor": "模板编辑器",
		"ticket-meta": "工单基础据",
		"ticket-meta-description": "工单基础数据管理",
		"settings": "设置",
		"landing": "开始",
		"language": "语言",
		"insert": "新增",
		"delete": "删除",
		"confirm": "确认",
		"confirm-delete": "确认删除",
		"cancel": "取消",
		"reset": "重置",
		"my-ticket": "我的工单",
		"team-ticket": "团队工单",
		"detail": "详细信息",
		"hours": "小时",
		"upload-text": "拖拽需要上传的文件到此处",
		"upload-hit": "支持多文件上传",
		"access-control-landing": "欢迎使用-访问控制模块",
		"access-control-landing-desc": "该模块允许您对本系统的参与者的权限，身份进行新增，修改和删除。",
		"ticket-editor": "工单编辑器",
		"plugins": {
			"installed-plugin": "已安装的插件",
			"installed-plugin-description": "已经安装在系统中的插件",
			"name": "插件名称",
			"description": "描述",
			"version": "版本",
			"configure": "插件配置",
			"no-configure": "未配置",
			"runAs": "运行账号"
		},
		"header": {
			"search": "搜索"
		},
		"translate": {
			"cn": "中文",
			"en": "英文",
			"color": "颜色"
		},
		"ticket-metas": {
			"ticket-type": "类型",
			"ticket-source": "来源",
			"ticket-priority": "优先级",
			"ticket-status": "状态",
			"name": "名称",
			"extern": "扩展属性"
		},
		"tag": {
			"self": "标签",
			"description": "定义的标签",
			"created": "创建时间",
			"updated": "更新时间",
			"name": "名称",
			"new-tag": "新建标签",
			"color": "颜色",
			"ok": "确定",
			"cancel": "取消",
			"name-empty": "标签名称不能为空"
		},
		"dynamic-editor": {
			"submit": "保存",
			"should-be-number": "必须是数字"
		},
		"user-editor": {
			"search": "搜索用户",
			"name": "名字",
			"fullName": "全名",
			"referenceId": "引用号",
			"source": "账号来源",
			"organization": "组织",
			"phone": "手机号",
			"email": "邮箱",
			"active": "是否激活",
			"actived": "激活",
			"inactive": "冻结",
			"editor-subtitle": "系统用户管理",
			"save": "保存",
			"cancle": "取消",
			"self": "编辑",
			"created": "创建时间",
			"updated": "更新时间"

		},
		"user-group-editor": {
			"name": "用户组名",
			"createdTime": "创建时间",
			"updatedTime": "更新时间",
			"desc": "描述",
			"editor-subtitle": "用户组管理",
			"user-count": "用户数",
			"delete": "删除组",
			"user-add": "添加到组",
			"user-remove": "从组内移出",
			"user-in-group": "组用户",
			"user-not-in-group": "其他用户",
			"policy": "已附加策略",
			"not-attached-policy": "未附加策略",
			"unattach": "分离策略",
			"attach": "附加策略",
			"add-user-group": "添加用户组",
			"name-placeholder": "请输入用户组名",
			"desc-placeholder": "请输入用户组描述",
			"save": "保存",
			"cancel": "取消"
		},
		"privilege-editor": {
			"self": "权限",
			"editor-subtitle": "系统所有权限",
			"name": "权限名称",
			"path": "请求路径",
			"verb": "请求谓词",
			"enabled": "是否激活"
		},
		"policy-editor": {
			"self": "策略",
			"editor-subtitle": "系统策略，用于分配权限",
			"name": "策略名称",
			"description": "策略描述",
			"createdTime": "创建时间",
			"updatedTime": "修改时间",
			"allowed": "策略类型",
			"actived": "允许",
			"inactive": "拒绝"
		},
		"content-schemas": {
			"operations": "操作",
			"field-description": "字段描述",
			"field-restrict": "字段约束",
			"title": "工单内容模板",
			"sub-title": "使用模板来形成工单数据",
			"new": "新建模板",
			"description": "描述",
			"updateTime": "更新时间",
			"createTime": "创建时间",
			"name": "模板名称",
			"editor": "模板编辑器",
			"edit": "编辑",
			"preview": "预览",
			"save": "保存模板",
			"save-succeed": "保存模板成功！",
			"new-succeed": "新建模板成功！",
			"save-fail-server": "保存模板失败-服务端错误",
			"new-fail-server": "新建模板失败-服务端错误",
			"fail-save-format": "格式错误，无法操作",
			"field-name": "字段名称-用于录入数据库",
			"field-display-name": "字段显示名称"
		},
		"auto": {
			"sku-detail": "SKU详情",
			"approve-history": "审批日志",
			"please-select-assignee": "请选择下一个节点执行人",
			"add-assignee-fail": "请输入名称",
			"ref-assignee": "指派引用（用于配置流程可操作性）",
			"new-ref-assignee": "新增指派引用",
			"add-assignee": "添加指派",
			"ref-assignee-name": "指派引用",
			"assignee": "强行指派",
			"account-info": "账户信息",
			"back": "返回",
			"please-contact": "请联系",
			"getting-approval": "进行审批",
			"message-required": "请填写审批信息",
			"message": "审批信息",
			"date": "审批时间",
			"operator": "审批人",
			"reject": "驳回",
			"reject-to": "驳回",
			"approval-process": "审批流程：",
			"cancle": "取消",
			"approval-message": "请输入审批信息",
			"agree": "同意",
			"process-name": "流程名称",
			"process-description": "流程描述",
			"ref-data": "数据引用",
			"new-ref-data": "新数据引用",
			"owner": "责任人",
			"hands": "执行人",
			"process-editor": "流程自动化编辑器",
			"visiable-at-state": "可见",
			"editable-at-state": "可编辑",
			"ref-forms": "表单引用(用于配置流程可操作性）",
			"reject-to-state": "驳回到",
			"ref-approval": "审批引用(用于配置流程可操作性）",
			"ref-template": "模板",
			"new-ref-approval": "新增审批引用",
			"new-ref-forms": "新增表单引用",
			"add-form-validation-fail": "请选择表单并为其命名！",
			"add-form": "添加表单引用",
			"ref-form-name": "引用表单名称",
			"ref-approval-name": "引用审批名称",
			"cancel": "取消",
			"add-approval": "添加审批引用",
			"process-def": "流程定义",
			"process-config": "流程配置",
			"time-shift-config": "时效配置",
			"done": "完成",
			"process-stage-index": "序号",
			"process-stage-name": "名称",
			"process-stage-type": "类型",
			"form": "表单",
			"approval": "审批",
			"close-state": "完成",
			"add-new-process": "新增流程节点",
			"process-editor-title": "工单自动化流程编辑器（Alpha:没有输入检查）",
			"delete": "删除",
			"arrange-stage": "重新排序",
			"next": "下一步",
			"prev": "上一步",
			"process-edit-complete": "流程配置完成",
			"save-process": "保存流程",
			"time-axis": "轴",
			"lastUpdated": "最后更新",
			"created": "创建时间",
			"sla": "结束时间",
			"time-condition": "条件",
			"time-met": "分钟",
			"assignToParent": "指派给上级",
			"after": "之后",
			"before": "之前",
			"when": "当",
			"time-at": "在",
			"time-shift-title": "时效配置",
			"add-time-shift-item": "添加配置",
			"minutes": "分钟之后",
			"ref-data-type": "引用数据类型",
			"save-succeed": "保存成功",
			"verifying": "正在验证.......",
			"please-enter-name": "请输入流程名",
			"verify-fail": "验证失败,请修改",
			"verify-succeed": "验证成功",
			"save-template-succeed": "流程模板保存成功",
			"back-to-edit": "返回修改",
			"back-to-list": "返回列表",
			"process-list-title": "自动化流程",
			"process-list-sub-title": "用于自动化流转工单",
			"name": "流程名称",
			"description": "流程描述",
			"updateTime": "更新时间",
			"createTime": "创建时间",
			"new": "创建流程"
		},
		"tickets": {
			"batch-operation-succeed": "批量指派操作成功，成功数量为:",
			"batch-assigned-to": "批量指派到",
			"assigned-to": "指派到",
			"efficiency": {
				"self": "时效",
			},
			"resolved":"已解决并指派到创建人",
			"data-ref-asin-info": "ASIN信息",
			"data-ref-sku-detail": "SKU信息",
			"fill-template": "使用模板填充",
			"template": "使用模板",
			"data-ref-account": "账户信息",
			"data-ref-asin": "ASIN",
			"success-copied": "已成功拷贝工单个数:",
			"copy-new-ticket": "复制为新工单",
			"download-fail": "文件下载失败",
			"new-rule-added-successed": "新规则创建成功",
			"rule-update-actions-succeed": "规则触发动作更新成功",
			"rule-enabled-succeed": "操作成功",
			"rule-update-trigger-frequency-succeed": "触发频次更新成功",
			"rule-no-changed": "规则没有任何更新",
			"rule-update-ticket-filter-succeed": "过滤规则更新成功",
			"rule-update-time-schedule-succeed": "时间规划更新成功",
			"escalation-rule-unsave-update": "更改未保存",
			"new-ticket-escalation-rule-title": "升级规则标题",
			"new-ticket-escalation-rule-description": "升级规则描述",
			"new-ticket-escalation-rules": "新建升级规则",
			"escalation-rule-unsave": "新增未保存",
			"rule-not-save-could-not-enable": "规则未保存，无法启用",
			"rule-enable": "启用",
			"rule-disable": "停用",
			"action-selector": "动作选项",
			"fix-time-title": "固定时间(CRON表达式)",
			"fix-rate-title": "固定频率(分钟/次）",
			"every": "每隔",
			"minutes": "分钟",
			"lastestUpdate": "最后更新",
			"fix-rate": "固定频率",
			"one-time": "仅一次",
			"fix-time": "固定时间",
			"created": "创建时间",
			"sla-time": "结束时间",
			"time-schedule": "时间规划",
			"axis": "时间轴",
			"ticket-selector": "工单过滤(空代表不过滤）",
			"time-direction": "时间方向",
			"before": "之前",
			"after": "之后",
			"time-limited": "时间约束(分钟)",
			"trigger-frequency": "触发频次",
			"trigger-action": "触发动作",
			"frequency-option": "频次选项",
			"exports": {
				"flush-jobs": "刷新任务",
				"id": "编号",
				"jobId": "任务ID",
				"status": "状态",
				"startedAt": "开始时间",
				"requestedAt": "请求时间",
				"completedAt": "完成时间",
				"operations": "操作",
				"download": "下载",
				"cancle": "取消"
			},
			"completed-over-due": "未按时完成",
			"completed-on-time": "已完成",
			"exports-page": "导出任务",
			"export-subtitle": "工单导出任务列表",
			"can-not-completed-in-time": "系统繁忙，暂时未能导出，请稍后去导出页面中下载",
			"export-job-queued": "导出任务已经开始",
			"do-no-duplicate-export": "请不要重复导出",
			"no-ticket-selected": "没有选中任何工单",
			"export-detail-selected": "导出选中工单",
			"no-permission-to-access-ticket": "您没有权限访问该工单，请联系管理员或者",
			"my-related-ticket": "如果您是工单的创建者,参与者，关注者，将会看到该工单",
			"watch-list-set-succee": "添加关注成功",
			"select-observer": "选择关注人",
			"exist-watcher": "已关注",
			"user-name": "名称",
			"org": "组织",
			"operatiion": "操作",
			"add-observer": "保存",
			"update-visable-fail": "更新可见性失败，请联系工单创建人或者管理员",
			"cancle": "取消",
			"setPublic": "设置为公开",
			"setPrivate": "设置为私有",
			"setPrivateConfirm": "您正在设置工单为私有，只有关注列表可见",
			"setPublicConfirm": "您正在设置工单为公开，所有人将可见",
			"watch-list": "关注列表",
			"sub-title": "副标题: ",
			"private": "私有",
			"public": "公开",
			"id": "工单号",
			"over-due": "超时",
			"close-over-due": "即将超时",
			"related-tickets": "与我相关的工单",
			"related-tickets-closed": "已关闭的工单",
			"welcome": "欢迎",
			"current-time": "当前时间",
			"critical": "紧急工单",
			"incident": "事故工单",
			"search": {
				"export": "导出",
				"automatic-re-eval": "重新评估指派人",
				"you-reeval-completed": "评估完成，请重新搜索。",
				"self": "工单搜索",
				"subtitle": "用于检索工单",
				"title": "工单标题",
				"sub-title": "工单子标题",
				"input-title": "请输入工单标题用于模糊搜索",
				"input-sub-title": "请输入工单子标题用于模糊搜索",
				"act": "搜索",
				"reset": "重置",
				"id": "工单号",
				"input-id": "请输入工单号用于精确查找",
				"source": "来源",
				"priority": "优先级",
				"status": "状态",
				"type": "类型",
				"assigned": "指派人",
				"tag": "标签",
			},
			"include-closed": "查看已关闭工单",
			"exclude-closed": "仅正在进行工单",
			"export": "导出列表",
			"in-process": "正在进行",
			"data-ref-sku": "SKU",
			"automatic-desc": "本工单处于自动流转状态",
			"clean": "清除",
			"remove-this-form": "删除表单",
			"add-comment": "添加评论",
			"time-pattern-long": "YYYY年MM月DD日 HH:mm",
			"time-pattern-short": "MM月DD日 HH:mm",
			"latest-time": "最后更新",
			"form-exists": "表单已经添加",
			"add-form": "添加表单",
			"content-update-succeed": "描述保存成功",
			"content-update-fail": "描述保存失败",
			"edit-default-detail": "编辑",
			"new-tickets-created": "工单创建成功",
			"tags": "标签",
			"assign-to-group": "指派到组",
			"assign-to-user": "指派到用户",
			"input-sla-placeholder": "请输入工单时效性",
			"title-placeholder": "请输入工单标题",
			"subtitle-placeholder": "请输入工单子标题",
			"title": "标题",
			"subtitle": "子标题",
			"cancel": "取消",
			"save-new-tickets": "保存新工单",
			"new-ticket": "新建工单",
			"add-tag-succeed": "标签添加成功",
			"remove-tag-succeed": "删除标签成功",
			"update-basic-info-succeed": "更新成功！",
			"update-status-succeed": "工单状态更新成功！",
			"ticket-status": "工单状态",
			"update-fail": "更新失败",
			"update-succeed": "更新成功",
			"subject": "标题",
			"sla": "时效性",
			"assignedGroup": "指派到组",
			"createTime": "创建时间",
			"ticket-source": "来源",
			"updateTime": "更新时间",
			"assign-to-me": "指派给我",
			"close": "关闭",
			"assign-to": "指派给...",
			"hav-assigned": "已指派",
			"not-assigned": "未指派",
			"edit": "修改状态",
			"detail": "描述",
			"time-up": "超时",
			"assigned-to-me-desc": "指派给我的工单",
			"assigned-to-team-desc": "指派给我的团队的工单",
			"details": {
				"edit": "编辑",
				"disable-edit": "关闭并预览",
				"save": "保存",
				"cancel": "取消",
				"new-assignee": "新增指派",

			},
			"threads": {
				"subject-changed": "标题变更",
				"subtitle-changed": "副标题变更",
				"subjectchange": "标题变更",
				"comment": "评论",
				"newticket": "新建工单",
				"assignchange": "指派变更",
				"fileattached": "新增附件",
				"corestatuschange": "状态变更",
				"batchassignchanged": "批量指派"
			},
			"thread": "时间线",
			"save": "保存",
			"knowledge-base": "知识库",
			"status": {
				"new": "新建",
				"self": "状态"
			},
			"time-left": "时效性",
			"creator": "创建者",
			"assigned": "指派到",
			"created-at": "创建时间",
			"effected-time": "有效时间",
			"assigned-group": "指派到团队",
			"priority": {
				"self": "优先级",
				"critical": "紧急",
				"high": "高",
				"normal": "常规",
				"low": "低",
				"very-low": "非常低"
			},
			"type": {
				"incident": "事故",
				"task": "任务",
				"alarm": "提醒",
				"self": "类型"
			},
			"attachment": {
				"file-name": "文件名",
				"upload-time": "上传时间",
				"self": "附件",
				"action": "操作",
				"delete": "删除附件",
				"storage-type": "存储位置",
				"delete-title-prefix": "您是否确定删除该文件？",
				"delete-attachment-succeed": "删除附件成功",
				"delete-attachment-fail": "删除附件失败！",
				"creator": "文件上传人",
				"fileattached": "文件上传"
			},
			"related": {
				"self": "相关工单-Alpha",
				"children-title": "子工单",
				"children-button-link": "添加子工单",
				"link-title": "相关工单",
				"link-button-link": "关联工单",
				"parent-title": "父工单",
				"parent-button-link": "附加到工单",
				"link": "链接",
				"could-not-link-self": "不能链接自身作为关联关系！",
				"link-succeed": "关联成功",
				"link-fail": "关联失败，已经有所关联",
				"unlink": "取消关联",
				"confirm-unlink-postfix": "确认取消关联吗？",
				"unlink-succeed": "取消关联成功",
				"unlink-fail": "取消关联失败"
			},
			"source": {
				"self": "来源",
				"realtime-monitor": "实时监控"
			}
		},
		"search": "搜索",
		"footer": {
			"copyright": "版权所有",
			"clientBuild": "客户端版本",
			"serverBuild": "服务端版本"
		},
		"api-access-key": {
			"access-key": "访问key",
			"access-secret-pub": "公钥",
			"asymmetric-method": "加密方式",
			"createdTime": "创建时间",
			"failed-applying-access-key": "申请密钥失败",
			"saved-success": "保存成功",
			"result-of-applying-access-key": "密钥申请结果",
			"save-to-local": "保存到本地",
			"access-key-management": "密钥管理",
			"apply-access-key": "申请密钥",
			"delete-access-key": "删除密钥",
			"confirm-delete-access-key": "确认删除选中的密钥吗?"
		},
		"notification-setting": {
			"channel-configuration": {
				"config-wechat-parameters": "配置企业微信参数",
				"config-feishu-parameters": "配置飞书参数",
				"config-voice-parameters":"配置语音参数",
				"view": "查看",
				"update": "修改",
				"cancel": "取消",
				"save": "保存",
				"reset": "重置",
				"not-authorized-to-view": "无权查看",
				"confirm-save-configuration": "确认保存此配置项吗？",
				"confirm-reset-configuration": "确认重置此配置项吗？",
			},
			"message-template": {
				"edit": "编辑",
				"delete": "删除",
				"template": "模板",
				"close": "关闭",
				"confirm": "确认",
				"cancel": "取消",
				"confirm-modify": "确认修改吗？",
				"confirm-delete": "确认删除吗？",
				"event-name": "事件名称",
				"template-name": "模板名称",
				"template-content": "模板内容",
				"event-name-postfix": "事件名称：",
				"wechat-message-template": "企业微信消息模板",
				"feishu-message-template": "飞书微信消息模板",
				"voice-message-template":"语音消息模板",
				"change-message-template": "更换消息模版",
				"change-template-success": "更换消息模板成功！",
				"change-template-fail": "更换消息模板失败！",
				"modify-template-success": "修改消息模板成功！",
				"modify-template-fail": "修改消息模板失败！",
				"add-template-success": "新增消息模板成功！",
				"add-template-fail": "新增消息模板失败！",
				"delete-template-success": "删除消息模板成功！",
				"delete-template-fail": "删除消息模板失败！",
				"template-name-postfix": "模板名称：",
				"input-event-name": "请输入事件名称",
				"input-template-name": "请输入模板名称",
				"input-template-content": "请输入模板内容",
				"add-message-template": "新增消息模板",
				"change-message-template-for-template-postfix": "更换事件消息模板：",
			}
		},
		"statistic": {
			"ticket": {
				"serial": "工单时序",
				"handle-time-cost": "处理耗时",
				"time-cost-rate": "耗时占比",
			}
		}
	};
	return langCN;
}

