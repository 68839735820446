import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { ActionEventHandler } from "../../../../../components";
import ColumnTextFilter from "../../../../../components/table/column-text-filter";
import ActiveTag from "./active-tag";

export interface PolicyModel {
	key:string,
	id:number
	name:string,
	description:string,
	createdTime:string,
	updatedTime:string,
	allowed:boolean
}



export const columnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<PolicyModel>): ColumnType<PolicyModel>[] => {
	
	return [
		{
			title: translater("policy-editor.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string,record:PolicyModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:PolicyModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("policy-editor.name")}/>)
			},
		},

		{
			title: translater("policy-editor.description"),
			dataIndex: 'description',
			key: 'description',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:PolicyModel) => record.description.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("policy-editor.description")}/>)
			},
		},{
			title: translater("policy-editor.createdTime"),
			dataIndex: 'createdTime',
			key: 'createdTime',
			render:(value:any,record:PolicyModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
			
		},{
			title: translater("policy-editor.updatedTime"),
			dataIndex: 'updatedTime',
			key: 'updatedTime',
			render:(value:any,record:PolicyModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
			
		},
		{
			title: translater("policy-editor.allowed"),
			dataIndex: 'allowed',
			key: 'allowed',
			render: (a: any) => (<ActiveTag isActive={a}></ActiveTag>)
		},
	];
}