import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import MailList, {ListViewManupulator} from "./mails-list"
import MailDetail from "./mail-detail"
import ApiServer from "../../../../../ApiServer";
import './index.less'
import {
    BindingContactModel,
    convertMailContactDtoModel,
    convertMailSendDtoToModel,
    loadEmailBoxState,
    loadEmails,
    MailBoxModel,
    MailModel,
    MailSendModel,
    StateModel
} from "../../../@mod";
import {Button, Empty, message, Modal, notification} from "antd";
import {InboxOutlined, MessageOutlined, SendOutlined, SyncOutlined} from "@ant-design/icons"
import MailsSendList from "./mails-send-list";
import MailEditor from "./mail-editor";

import MailBoxProgress from "../../../component/progress"
import {CONTACT_BINDING_TYPE} from "../../../contact/@mod";
import {convertTemplateDtoToModel, MailTemplateModel} from "../../../template/@mod";


export interface BoxDetailViewModel extends WithTranslation {
    apiServer: ApiServer,
    box: MailBoxModel,

}

interface BoxDetailViewState {
    mailOnView?: MailModel,
    mailState?: StateModel,
    linkRunning: boolean,
    mailOnEdit?: MailSendModel
    displaySendView: boolean,
    emailContacts?: BindingContactModel,
    emailTemplates: MailTemplateModel[]
    showConfirmSendMail: boolean,
    toContacts: string[]
    ccContacts: string[]
    bccContacts: string[]
    notBoundTo: string[]
    notBoundCc: string[]
    notBoundBcc: string[]
}


class BoxDetail extends React.Component<BoxDetailViewModel, BoxDetailViewState>{

    constructor(props: BoxDetailViewModel) {
        super(props)
        this.state = {
            linkRunning: false,
            displaySendView: false,
            showConfirmSendMail: false,
            toContacts: [],
            ccContacts: [],
            bccContacts: [],
            notBoundTo: [],
            notBoundCc: [],
            notBoundBcc: [],
            emailTemplates: []
        }
    }

    listOperator: ListViewManupulator | undefined;

    sendListOperator: ListViewManupulator | undefined;

    timmer: any | undefined;
    componentDidMount() {
        loadEmailBoxState(this.props.apiServer, this.props.box.id)
            .then(x => {
                if (x) {
                    this.setState({
                        mailState: x
                    })
                }
            })
        if (!this.timmer) {
            this.timmer = window.setInterval(() => {
                loadEmailBoxState(this.props.apiServer, this.props.box.id)
                    .then(x => {
                        if (x) {
                            this.setState({
                                mailState: x
                            })
                        }
                    })
                if(this.sendListOperator && this.state.displaySendView){
                    this.sendListOperator.reload();
                }
            }, 20000)
        }
        this.loadEmailContacts()
        this.loadEmailTemplates()
    }

    componentWillUnmount() {
        if (this.timmer) {
            window.clearInterval(this.timmer);
        }
    }

    loadEmailContacts = () => {
        this.props.apiServer.apiMail.getAggregatedContactsByMailbox(this.props.box.id).then(x => {
            if (x.data.data?.dto?.contacts) {
                const dto = x.data.data.dto.contacts;
                let emailContacts = {
                    to: dto[CONTACT_BINDING_TYPE.TO.name]?.map(d => convertMailContactDtoModel(d)),
                    cc: dto[CONTACT_BINDING_TYPE.CC.name]?.map(d => convertMailContactDtoModel(d)),
                    bcc: dto[CONTACT_BINDING_TYPE.BCC.name]?.map(d => convertMailContactDtoModel(d))
                }
                this.setState({
                    emailContacts: emailContacts,
                    toContacts: emailContacts.to?.map(d => d.emailAddress) ?? [],
                    ccContacts: emailContacts.cc?.map(d => d.emailAddress) ?? [],
                    bccContacts: emailContacts.bcc?.map(d => d.emailAddress) ?? [],
                })
            }
        })
    }

    loadEmailTemplates = () => {
        this.props.apiServer.apiMail.getBoundTemplates(this.props.box.id).then(x => {
            console.log("get bound mail template", x);

            if (x.data.data?.success) {
                this.setState({
                    emailTemplates: x.data.data.dtos?.map(t => convertTemplateDtoToModel(t)) ?? []
                })
            }
        })
    }

    loadRecvMail = (start, end): Promise<MailModel[]> => {
        return loadEmails(this.props.apiServer,
            this.props.box.id,
            start, end
        );
    }

    loadSendBoxMail = async (start, end): Promise<MailSendModel[]> => {
        var result = await this.props.apiServer.apiMail.getSendingMail(
            this.props.box.id
        ).then(x => {
            if (x.data.data?.success) {
                return x.data.data.sendingMails?.map(convertMailSendDtoToModel)
            }
            return []
        })
        return result ?? [];
    }

    viewMail = (m: MailModel) => {
        this.setState({
            mailOnView: m
        })
    }

    doLink = () => {
        this.setState({
            linkRunning: true
        })
        this.props.apiServer.apiMail.patchMailBox(this.props.box.id,
            {
                type: "link-recv"
            })
            .then(x => {
                this.setState({
                    linkRunning: false
                })
                this.listOperator?.reload();
            })
    }

    getDisplayRecv = () => {
        return this.state.displaySendView ? "none" : "";
    }

    getDisplaySend = () => {
        return !this.state.displaySendView ? "none" : "";
    }

    showSendView = () => {
        this.setState({
            displaySendView: !this.state.displaySendView
        })
    }

    newMessage = () => {

        this.props.apiServer.apiMail.putDraftMail(
            this.props.box.id,
            {
                sendSplit: false,
                subject: this.props.t("mails.new-email-subject"),
                message: this.props.t("mails.new-email-message"),
                body: this.props.t("mails.new-email-body")
            }
        ).then(
            x => {
                if (x.data.data?.success && x.data.data.sendingDto) {
                    let sendModel: MailSendModel = convertMailSendDtoToModel(x.data.data.sendingDto);


                    this.setState({
                        displaySendView: true,
                        mailOnEdit: sendModel
                    })
                    this.sendListOperator?.reload();

                }
            }
        )
    }

    viewSendedMail = (sendModel: MailSendModel) => {
        this.props.apiServer.apiMail
        .getSendingMailDetail(
            this.props.box.id,
            sendModel.id
        ).then(x=>{
            if(x.data.data?.success && x.data.data.sendingMail){
                this.setState({
                    displaySendView: true,
                    mailOnEdit: convertMailSendDtoToModel(x.data.data.sendingMail)
                })
            }
        })
    }


    saveMailOut = async (sendModel: MailSendModel) => {
        await this.props.apiServer.apiMail
            .postSendMail(
                sendModel.box?.id ?? 0,
                sendModel.id,
                {
                    sendSplit: sendModel.content.split,
                    subject: sendModel.content.subject,
                    message: sendModel.mailDescription,
                    to: sendModel.content.to,
                    body: sendModel.content.body,
                    cc: sendModel.content.cc,
                    bcc: sendModel.content.bcc,
                    cid: sendModel.content.cid,
                    attachment: sendModel.content.attachment
                }
            ).then(x => {
                if (x.data.data?.success) {
                    this.sendListOperator?.reload();
                    notification.success({ message: this.props.t("mails.save-draft-succeed") })
                }
            })
    }

    getUploadUrl = async (uid, fileName): Promise<string> => {
        return await this.props.apiServer.apiMail
            .getSendingEmailAttachmentTarget(
                this.state.mailOnEdit?.box?.id ?? 0,
                this.state.mailOnEdit?.id ?? 0,
                fileName,
                uid
            ).then(x => {
                if (x.data.data?.success) {
                    return x.data.data.url ?? ""
                }
                return "";
            })

    }
    downloadFile = (uid, fileName) => {
        this.props.apiServer.apiMail.getSendingEmailAttachmentTarget(
            this.state.mailOnEdit?.box?.id ?? 0,
            this.state.mailOnEdit?.id ?? 0,
            fileName,
            uid,
            "download"
        ).then(x => {
            if (x.data.data?.success) {
                var element = document.createElement('a');
                element.target = "_blank";
                element.href = x.data.data.url ?? "";
                document.body.appendChild(element)
                element.click()
                URL.revokeObjectURL(element.href);
                document.body.removeChild(element);
            } else {
                notification.warn({ message: this.props.t("mails.download-attachment-fail") })
            }
        })
    }

    getDownloadUrl = (uid,fileName) =>{
        return this.props.apiServer.apiMail.getSendingEmailAttachmentTarget(
            this.state.mailOnEdit?.box?.id ?? 0,
            this.state.mailOnEdit?.id ?? 0,
            fileName,
            uid,
            "download"
        ).then(x => {
            return x.data.data?.url ?? ""
        })
    }

    sendMail = async (sendModel: MailSendModel,schedule?:string) => {
        await this.saveMailOut(sendModel)
        if (sendModel.content.to && sendModel.content.to.length <= 0) {
            notification.error({message: this.props.t("mails.no-to-exist")})
        } else {

            let notBoundTo = sendModel.content.to.filter(x => !this.state.toContacts.includes(x))
            let notBoundCc = sendModel.content.cc.filter(x => !this.state.ccContacts.includes(x))
            let notBoundBcc = sendModel.content.bcc.filter(x => !this.state.bccContacts.includes(x))
            if (notBoundTo.length > 0 || notBoundCc.length > 0 || notBoundBcc.length > 0) {
                this.setState({
                    notBoundTo: sendModel.content.to.filter(x => !this.state.toContacts.includes(x)),
                    notBoundCc: sendModel.content.cc.filter(x => !this.state.ccContacts.includes(x)),
                    notBoundBcc: sendModel.content.bcc.filter(x => !this.state.bccContacts.includes(x))
                })
                this.showConfirmSendMail()
            } else {
                await this.doSendMail(schedule)
            }
        }
    }
    doSendMail = async (scheduleSend?:string) => {
        await this.props.apiServer.apiMail.patchSendMail(
            this.state.mailOnEdit?.box?.id ?? 0,
            this.state.mailOnEdit?.id ?? 0,
            {
                "action": "schedule-send",
                scheduledDate: scheduleSend
            }
        ).then(x => {
            if (x.data.data?.success) {
                this.setState({
                    mailOnEdit: undefined
                })
                this.sendListOperator?.reload();
                notification.info({message: this.props.t("mails.sechdule-send-succeed")})
            }
        })
    }

    doApplySendMail = () => {
        this.props.apiServer.apiMail.postMailSendApplication(
            this.state.mailOnEdit?.box?.id ?? 0,
            this.state.mailOnEdit?.id ?? 0
        ).then(x => {
            if (x.data.data?.success) {
                message.success(this.props.t("mails.apply-success")).then(x => {
                })
                this.setState({
                    mailOnEdit: undefined
                })
                this.sendListOperator?.reload();
            } else {
                message.success(this.props.t("mails.apply-fail")).then(x => {
                })
            }
        })
    }

    showConfirmSendMail = () => {
        this.setState({
            showConfirmSendMail: true
        })
    }

    deleteSendMail = (sendModel: MailSendModel) => {
        this.props.apiServer.apiMail
            .deleteDraftMail(
                this.state.mailOnEdit?.box?.id ?? 0,
                this.state.mailOnEdit?.id ?? 0,
            ).then(x => {
            if (x.data.data?.success) {
                notification.success({message: this.props.t("mails.delete-draft-succeed")})
                this.setState({
                    mailOnEdit: undefined
                })
                this.sendListOperator?.reload();
            } else {
                notification.warn({message: this.props.t("mails.delete-draft-failed")})
            }
        })

    }


    render(): React.ReactNode {
        return (
            <div className="box-detail-view">
                <div className="mail-box-status-bar ">
                    <div className="left-controls">
                        <div>
                            <Button type="primary" icon={<SendOutlined />} onClick={this.newMessage}> {this.props.t("mails.new-email")}</Button>
                        </div>
                        {!this.state.displaySendView &&
                            <div >
                                <Button style={{ marginLeft: "8px" }} icon={<MessageOutlined />} onClick={this.showSendView} > {this.props.t("mails.out-box")}</Button>
                            </div>
                        }
                        {this.state.displaySendView &&
                            <div>
                                <Button style={{ marginLeft: "8px" }} icon={<InboxOutlined />} onClick={this.showSendView}   > {this.props.t("mails.recv-email")}</Button>
                            </div>
                        }
                    </div>
                    <div className="right-controls">
                        <MailBoxProgress apiServer={this.props.apiServer} mailBoxId={this.props.box.id} />
                        <div>
                            <Button type="primary" disabled={this.state.linkRunning} shape="circle" onClick={this.doLink} icon={<SyncOutlined spin={this.state.linkRunning} />}></Button>
                        </div>
                    </div>

                </div>
                <div className="mailbox-detail" style={{ display: this.getDisplayRecv() }}>
                    <div className="left-list">
                        <MailList load={this.loadRecvMail} itemOnView={this.viewMail} setManupulator={(m) => this.listOperator = m} />
                    </div>
                    <div className="right-content-list">
                        {this.state.mailOnView &&
                            <MailDetail apiserver={this.props.apiServer} mail={this.state.mailOnView} />
                        }
                        {!this.state.mailOnView &&
                            <Empty style={{ height: "100%" }} />
                        }
                    </div>
                </div>


                <div className="mailbox-detail" style={{ display: this.getDisplaySend() }}>
                    <div className="left-list">
                        <MailsSendList
                            itemOnView={this.viewSendedMail}
                            setManupulator={(m) => this.sendListOperator = m}
                            load={this.loadSendBoxMail}
                        />
                    </div>
                    <div className="right-content-list">
                        {this.state.mailOnEdit &&
                            <MailEditor save={this.saveMailOut} sendModel={this.state.mailOnEdit}
                                        upload={this.getUploadUrl}
                                        cidUpload={this.getUploadUrl}
                                        download={this.downloadFile}
                                        downloadUrl={this.getDownloadUrl}
                                        delete={this.deleteSendMail}
                                        send={this.sendMail}
                                        bindingContacts={this.state.emailContacts}
                                        boundMailTemplates={this.state.emailTemplates}
                                        apiServer={this.props.apiServer}
                            />
                        }
                        {
                            !this.state.mailOnEdit &&
                            <Empty style={{height: "100%"}}/>
                        }

                    </div>

                </div>

                <Modal
                    visible={this.state.showConfirmSendMail}
                    onCancel={() => this.setState({showConfirmSendMail: false})}
                    width={1200}
                    cancelText={this.props.t("mails.cancel-send")}
                    okText={this.props.t("mails.apply-send")}
                    onOk={() => {
                        this.setState({
                            showConfirmSendMail: false
                        })
                        this.doApplySendMail()
                    }}
                >
                    <p>{this.props.t("mails.send-need-approval")}</p>
                    {
                        this.state.notBoundTo.length > 0 &&
                        <p>{this.props.t("mails.send-to")}：{this.state.notBoundTo.join(", ")}</p>
                    }
                    {
                        this.state.notBoundCc.length > 0 &&
                        <p>{this.props.t("mails.send-cc")}：{this.state.notBoundCc.join(", ")}</p>
                    }
                    {
                        this.state.notBoundBcc.length > 0 &&
                        <p>{this.props.t("mails.send-bcc")}：{this.state.notBoundBcc.join(", ")}</p>
                    }

                </Modal>

            </div>)
    }
}

export default withTranslation()(BoxDetail);