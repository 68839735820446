import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { Button, Card, Input, Modal } from "antd";
import ProjectListView from "../component/project-list";
import { ProjectMetaDataModel } from "../@mod";
import "./index.less"
import PieChart from "../../../components/charts/pie-chart";
import { DataPoint } from "../../../components/charts/@mod";
import FormItem from "antd/es/form/FormItem";

interface ProjectStatisticsViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface ProjectStatisticsViewState {
    projectList: ProjectMetaDataModel[]
    isShowStatistic: boolean
    pipeData: DataPoint[]
}

class ProjectStatisticsView extends React.Component<ProjectStatisticsViewModel, ProjectStatisticsViewState> {
    constructor(props: ProjectStatisticsViewModel) {
        super(props);
        this.state = {
            projectList: [],
            isShowStatistic: false,
            pipeData: []
        }
    }

    componentDidMount() {
        this.loadData()
        this.setState({
            pipeData: [
                {
                    type: "按时完成",
                    value: 100,
                },
                {
                    type: "未按时完成",
                    value: 30,
                }
            ]
        });
    }

    loadData = () => {
        this.props.apiServer.apiSystem.getLoginUser().then(r => {
            if (r.data.data?.user) {
                let loginUser = r.data.data?.user
                this.props.apiServer.apiProjectMeta.getMetaDataList(
                    undefined, loginUser?.id, undefined, undefined, undefined, undefined, undefined
                ).then(r => {
                    if (r.data.data?.list) {
                        let projectList: ProjectMetaDataModel[] = r.data.data.list?.map((metaData): ProjectMetaDataModel => {
                            return {
                                createdBy: metaData.createdBy ?? 0,
                                createdByName: metaData.createdByName ?? "",
                                createdTime: metaData.createdTime ?? "",
                                description: metaData.description ?? "",
                                id: metaData.id ?? 0,
                                owner: metaData.owner ?? 0,
                                ownerName: metaData.ownerName ?? "",
                                status: metaData.status ?? {},
                                template: metaData.template ?? "",
                                title: metaData.title ?? "",
                                updateBy: metaData.updatedBy ?? 0,
                                updateByName: metaData.updatedByName ?? "",
                                updateByTime: metaData.updatedTime ?? "",
                                version: metaData.version ?? ""
                            }
                        });
                        this.setState({
                            projectList: projectList
                        })

                    }
                })
            }
        })
    }

    genProjectDetailFooter = () => {
        return (<></>)
    }

    genHeaderExtra = () => {
        return (
            <></>
        )
    }

    search = () => {
        console.log("search in template statistic")
    }

    genSearchBar = () => {
        return (
            <div className={"operation-bar"}>
                <div><FormItem label={this.props.t("project.service.project-title")}><Input /></FormItem></div>
                <Button className={"opt-btn"} type={"primary"} onClick={this.search}>{this.props.t("project.service.search")}</Button>
            </div>
        );
    }

    onDetail = (model: ProjectMetaDataModel) => {
        this.setState({
            isShowStatistic: true
        })
    }

    render() {
        return (
            <div>

                <ProjectListView
                    apiServer={this.props.apiServer}
                    projectList={this.state.projectList}
                    headerTitle={this.props.t("project.service.statistic-title")}
                    headerSubTitle={this.props.t("project.service.statistic-sub-title")}
                    onDetail={this.onDetail}
                    genHeaderExtra={this.genHeaderExtra}
                    genSearchBar={this.genSearchBar}
                />

                <Modal
                    visible={this.state.isShowStatistic}
                    width={1600}
                    onCancel={() => this.setState({ isShowStatistic: false })}
                    footer={null}
                >
                    <div className={"statistic-container"}>
                        <div className={"count-container"}>
                            <Card className={"count-displayer"}>项目服务总数: 78</Card>
                            <Card className={"count-displayer"}>项目待审批服务数: 3</Card>
                            <Card className={"count-displayer"}>项目正在进行服务数: 36</Card>
                            <Card className={"count-displayer"}>项目待验收服务数: 8</Card>
                            <Card className={"count-displayer"}>项目已完成服务数: 34</Card>
                            <Card className={"count-displayer"}>项目服务按时完成率: 95.4%</Card>
                        </div>
                    </div>
                    <div>
                        <PieChart
                            data={this.state.pipeData}
                            width={1000}
                            height={1000}
                        />
                    </div>

                </Modal>
            </div>
        );
    }

}

export default withTranslation()(ProjectStatisticsView)