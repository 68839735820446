
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langEN from './en_US';
import langCN from './zh_CN';

export default async function configureLanguage(apiServer)
{
	let defaultLanguage = localStorage.getItem("lang");
	if(defaultLanguage == null){
	  defaultLanguage = "cn"
	}
	
	var uss = await loadUserSource(apiServer);
	var injectEnObject ={
		userSource: uss.en,
	}

	var injectCnObject ={
		userSource: uss.cn
	}


	i18n.use(initReactI18next)
	
	.init(
	  {
		resources: {
		  en :{
			translation: langEN(injectEnObject)
		  },
		  cn :{
			translation: langCN(injectCnObject)
		  }
		},
		lng :defaultLanguage,
		fallbackLng: 'en',
		interpolation :{
		  escapeValue :false
		},
		react:{
			useSuspense:false
		}
	  }
	);
};



async function  loadUserSource(apiServer){
	let list = await apiServer.apiMeta.getUserSourceList(true);

	var obj = {
		cn:{},
		en:{}
	}
	list.data.data?.list?.forEach(x =>
		{

			if(x.lang){
				obj.cn[x.name]=x.lang.cn;
				obj.en[x.name]=x.lang.en;
			}

		}
	)
	return obj;
}