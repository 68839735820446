import { Button, message, Modal, PageHeader } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { loadEmailTemplate, MailTemplateModel } from "../@mod";
import "./index.less"
import MailTemplateEditor from "../component/template-editor"
import MailTemplateList from "../component/template-list"
import BindMailboxView from "../component/bind-mailbox"

interface MailTemplateManagerViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface MailTemplateManagerViewState {
    templates: MailTemplateModel[],
    showEditor: boolean,
    showTemplateEditEditor: boolean,
    submitting: boolean,
    showDetail: boolean,
    selectedTemplate?: MailTemplateModel,
    selectedTemplateForEditting?: MailTemplateModel,
    showBinding: boolean,
}

class MailTemplateManagerView extends React.Component<MailTemplateManagerViewModel, MailTemplateManagerViewState> {
    constructor(props) {
        super(props)
        this.state = {
            templates: [],
            showEditor: false,
            showTemplateEditEditor: false,
            submitting: false,
            showDetail: false,
            showBinding: false
        }
    }

    componentDidMount(): void {
        this.loadTemplates()
    }

    loadTemplates = () => {
        loadEmailTemplate(false, this.props.apiServer).then(x => {
            let templates = x
            this.setState({
                templates: templates,
            })
        })
    }

    finishAddTemplate = (values) => {
        this.setState({
            submitting: true
        })
        this.props.apiServer.apiMail.postMailTemplate({
            title: values.title,
            content: values.content,
            tags: values.tags
        }).then(x => {
            if (x.data.data?.success) {
                message.success(this.props.t("mails.mail-template.save-success")).then(() => { })
                this.setState({
                    showEditor: false
                })
                this.componentDidMount()
            } else {
                message.error(this.props.t("mails.mail-template.save-fail")).then(() => { })
            }
        }).finally(() => {
            this.setState({
                submitting: false
            })
        })
    }

    finishEditTemplate = (values) => {
        this.setState({
            submitting: true
        })
        this.props.apiServer.apiMail.putMailTemplate(values.id, {
            title: values.title,
            content: values.content,
            tags: values.tags
        }).then(x => {
            if (x.data.data?.success) {
                message.success(this.props.t("mails.mail-template.save-success")).then(() => { })
                this.setState({
                    showEditor: false
                })
                this.componentDidMount()
            } else {
                message.error(this.props.t("mails.mail-template.save-fail")).then(() => { })
            }
        }).finally(() => {
            this.setState({
                submitting: false
            })
            this.hideTemplateEditEditor()
        })
    }

    showEditor = () => {
        this.setState({
            showEditor: true
        })
    }

    hideEditor = () => {
        this.setState({
            showEditor: false
        })
    }

    hideTemplateEditEditor = () => {
        this.setState({
            showTemplateEditEditor: false
        })
    }

    showTemplateEditEditor = () => {
        this.setState({
            showTemplateEditEditor: true
        })
    }

    hideBinding = () => {
        this.setState({
            showBinding: false
        })
    }

    showBinding = () => {
        this.setState({
            showBinding: true
        })
    }

    deleteTemplate = (templateId: number) => {
        this.props.apiServer.apiMail.deleteMailTemplate(templateId).then(x => {
            if (x.data.data?.success) {
                message.success(this.props.t("mails.mail-template.delete-success")).then(() => { })
                this.componentDidMount()
            } else {
                message.error(this.props.t("mails.mail-template.delete-fail")).then(() => { })
            }
        })
    }

    onMailTemplateAction = (record: MailTemplateModel, action: string) => {
        if (action === "delete") {
            if (record.id) {
                this.deleteTemplate(record.id)
            }
        } else if (action === "edit") {
            this.setState({
                selectedTemplateForEditting: record
            })
            this.showTemplateEditEditor()
        } else if (action === "bind-mail-box") {
            this.setState({
                selectedTemplate: record
            })
            this.showBinding()
        }
    }

    render(): React.ReactNode {
        return (
            <PageHeader
                className="page-header"
                title={this.props.t("mails.mail-template.management")}
                subTitle={this.props.t("mails.mail-template.management-sub-title")}
            >
                <div className="mail-template-container">
                    <div className="operation-bar">
                        <Button onClick={this.showEditor} type="primary">{this.props.t("mails.mail-template.new-template")}</Button>
                    </div>
                    <MailTemplateList
                        apiServer={this.props.apiServer}
                        onMailTemplateAction={this.onMailTemplateAction}
                        templates={this.state.templates}
                        editOperationBtn
                        deleteOperationBtn
                        bindOperationBtn
                    />
                </div>

                <Modal
                    title={this.props.t("mails.mail-template.editor-title")}
                    visible={this.state.showEditor}
                    onCancel={this.hideEditor}
                    footer={null}
                    destroyOnClose
                    width={1000}
                >
                    <MailTemplateEditor onFinish={this.finishAddTemplate} apiServer={this.props.apiServer} submitting={this.state.submitting} />
                </Modal>

                <Modal
                    title={this.props.t("mails.mail-template.editor-title") + " - " + this.state.selectedTemplateForEditting?.title}
                    visible={this.state.showTemplateEditEditor}
                    onCancel={this.hideTemplateEditEditor}
                    footer={null}
                    destroyOnClose
                    width={1000}
                >
                    <MailTemplateEditor
                        onFinish={this.finishEditTemplate}
                        template={this.state.selectedTemplateForEditting}
                        apiServer={this.props.apiServer}
                        submitting={this.state.submitting}
                    />
                </Modal>

                <Modal
                    title={this.props.t("mails.mail-template.binding-title") + " - " + this.state.selectedTemplate?.title}
                    visible={this.state.showBinding}
                    onCancel={this.hideBinding}
                    width={1000}
                    destroyOnClose
                    footer={null}
                >
                    <BindMailboxView apiServer={this.props.apiServer} template={this.state.selectedTemplate} />
                </Modal>

            </PageHeader>
        )
    }
}

export default withTranslation()(MailTemplateManagerView)