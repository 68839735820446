import { Button, Input } from "antd";
import React from "react";
import {CheckOutlined,CloseOutlined,EditOutlined} from "@ant-design/icons"
import './index.less';
import { EditableColumnProps } from "../@mod";


export interface EditableTextColumnViewModel<T> extends EditableColumnProps<T> {
    changed?: ()=>void,
    type?:string
}


interface EditableTextColumnState {
    editing: boolean,
    tempValue :string
}


class EditableTextColumn<T> extends React.Component<EditableTextColumnViewModel<T>, EditableTextColumnState>{

    constructor(props: EditableTextColumnViewModel<T>) {
        super(props)
        this.state = {
            editing: false,
            tempValue:this.props.record[this.props.editingField]
        }
    }

    changed = (t)=>{

        var v = t.target.value;
        if(this.props.type && "number"===this.props.type){
            v = Number(v);
        }

        this.setState({
            tempValue:v
        })

    }
    cancleClick = ()=>{
        this.setState(
            {
                tempValue:this.props.record[this.props.editingField],
                editing: false,
            }
        )
    }

    confirmClick = () =>{
        this.props.changed?.();
        console.log("confirm clicked")
        this.props.record[this.props.editingField]= this.state.tempValue;
        this.setState(
            {
               editing:false
            }
        )
    }


    render(): React.ReactNode {
        return (
            <div>
                {!this.state.editing && 
                <div onClick={() => this.setState({ editing: true })}
                    className="editable-field"
                >
                    {this.props.record[this.props.editingField]}
                    <EditOutlined />
                </div>}
                {
                    this.state.editing &&
                    <div className="text-field-editor">
                        <Input value={this.state.tempValue}
                            onChange={this.changed}
                            style={{width:240}}
                            type={this.props.type}
                        >
                        </Input>
                        <Button icon={<CheckOutlined/> } type="primary" onClick={this.confirmClick} ></Button>
                        <Button icon={<CloseOutlined/>} type="primary" danger onClick={this.cancleClick} ></Button>
                    </div>
                }
            </div>

        )
    }

}

export default EditableTextColumn;