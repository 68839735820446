import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ChangedModel} from "../../@mod";
import {UserGroupModel, UserModel } from "../../../@mod"
import { CaretRightOutlined } from '@ant-design/icons'
import './index.less'



export interface AssigneeChangeViewMode extends WithTranslation {
	changed: ChangedModel[],
	userList: UserModel[],
	userGroupList: UserGroupModel[]
}

interface AssigneeChangeState {

}

class AssigneeChangeView extends React.Component<AssigneeChangeViewMode, AssigneeChangeState> {

	renderChanged = () => {
		return this.props.changed.map((x: ChangedModel) => {
			if (x.type === "user") {
				console.log(x)
				let origin: UserModel = this.props.userList.filter(y => y.id === x.before)[0];
				let target: UserModel = this.props.userList.filter(y => y.id === x.after)[0];

				return (
					<div key={x.type} className="assignee">
						<div className="origin-assignee">
							<div className="user-name" >{origin.name}</div>
							<div className="org-name">{origin.organization}</div>
						</div>
						<div className="pointer">
							<CaretRightOutlined />
						</div>
						<div key={x.type} className="target-assignee">
							<div className="user-name" >{target.name}</div>
							<div className="org-name">{target.organization}</div>
						</div>
					</div>
				)
			}
			if (x.type === "user-group") {
				let origin: UserGroupModel = this.props.userGroupList.filter(y => y.id === x.before)[0];
				let target: UserGroupModel = this.props.userGroupList.filter(y => y.id === x.after)[0];
				if (target && origin) {
					return (
						<div key={x.type} className="assignee" >
							<div key={x.type} className="origin-assignee">
								<div>{origin.name}</div>
							</div>
							<div className="pointer">
								<CaretRightOutlined />

							</div>
							<div key={x.type} className="target-assignee">
								<div>{target.name}</div>
							</div>
						</div>
					)
				}
			}
			return <></>
		})
	}

	render(): React.ReactNode {
		return (
			<div className="assignee-container">
				{this.renderChanged()}
			</div>)
	}
}

export default withTranslation()(AssigneeChangeView);