import {Button, Input, Tabs} from "antd";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import ApiServer from "../../../ApiServer";
import {loadActiveInstances} from "../../cluster/@mod";
import {
    loadAllMailBoxes,
    loadAllTags,
    loadUsers,
    loadVendors,
    mailBoxConfigureColumnProvider,
    MailBoxModel,
    MailVendorModel,
    RemoteInstanceModel,
    TagModel,
    UserModel
} from "../@mod";
import MailBoxList from '../box-list'
import {SearchOutlined} from "@ant-design/icons"
import MailBoxAccessEditor from './editor'

export interface AccessControlViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface AccessControlViewState {
    activeView: string,
    dataLoading: boolean,
    mailBoxes: MailBoxModel[],
    mailBoxesOnView: MailBoxModel[],
    instances: RemoteInstanceModel[],
    vendors: MailVendorModel[]
    tags: TagModel[],
    user: UserModel[],
}


class AccessControl extends React.Component<AccessControlViewModel, AccessControlViewState>{

    constructor(props: AccessControlViewModel) {
        super(props)
        this.state = {
            activeView: "default",
            dataLoading: false,
            mailBoxes: [],
            mailBoxesOnView: [],
            instances: [],
            vendors: [],
            tags: [],
            user: []
        }

    }


    loadData = async () => {
        this.setState(
            {
                mailBoxes: [],
                mailBoxesOnView: []
            }
        )
        var mailBoxes = await loadAllMailBoxes(this.props.apiServer)
        var instances = await loadActiveInstances(this.props.apiServer)
        var vendors = await loadVendors(this.props.apiServer)
        var tags = await loadAllTags(this.props.apiServer);
        var user = await loadUsers(this.props.apiServer);

        this.setState(
            {
                mailBoxes: mailBoxes,
                instances: instances,
                mailBoxesOnView: [],
                vendors: vendors,
                tags: tags,
                user: user
            }
        )
    }

    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.setState({
                        dataLoading: false
                    })
                })
        }
    }

    onNavigate = (box: MailBoxModel) => {
        let onView = this.state.mailBoxesOnView;
        if (onView.findIndex(x => x.id == box.id) >= 0) {
            this.setState({
                activeView: String(box.id)
            })
        } else {
            onView.push(box);
            this.setState({
                mailBoxesOnView: onView
            })
        }
    }

    newEmailConf = () => {
        var boxOnView = this.state.mailBoxesOnView;
        if (boxOnView.findIndex(x => x.id === 0) >= 0) {
            this.setState({
                activeView: "0"
            })
        } else {
            boxOnView.push(
                {
                    id: 0,
                    emailAddress: "example@hotmail.com",
                    cred: "",
                    description: {},
                    creator: "",
                    updateter: "",
                    created: "",
                    updated: "",
                    displayCred: false,
                    deployTo: []
                }
            )
            this.setState({
                activeView: "0",
                mailBoxesOnView: boxOnView
            })
        }
    }

    aggreateTags = ()=>{
        let tags: TagModel[] = [];
        this.state.mailBoxes.forEach(x=>{
            x.tags?.forEach(t=>{
                if(tags.findIndex(q=>q.id===t.id)<0){
                    tags.push(t);
                }
            })
        })
        return tags;
    }

    renderTabs = () => {
        let tabs: React.ReactNode[] = [];
        tabs.push(
            <Tabs.TabPane closable={false} tab={this.props.t("mails.access-control.desc")} key={"default"}>
                <div>
                    <div className="function-bar">
                        <div className="right">
                            <Input type="text" placeholder={this.props.t("mails.conf.search")} ></Input>
                            <Button type="primary" icon={<SearchOutlined />} > {this.props.t("mails.conf.search")}</Button>
                        </div>
                    </div>
                    <MailBoxList
                        dataLoading={this.state.dataLoading}
                        mailBoxes={this.state.mailBoxes} onNavigate={this.onNavigate}
                        columnTypeProvider={(x) => mailBoxConfigureColumnProvider(this.props.t, x, this.props.apiServer, this.state.instances,this.aggreateTags())}
                    />
                </div>
            </Tabs.TabPane>
        )

        this.state.mailBoxesOnView.forEach(
            mailModel => {
                tabs.push(
                    <Tabs.TabPane closable={true} tab={mailModel.emailAddress} key={mailModel.id}>
                        <div className="mail-config-editor">
                            <MailBoxAccessEditor apiServer={this.props.apiServer}
                                instances={this.state.instances}
                                tags={this.state.tags}
                                vendors={this.state.vendors}
                                mailBoxModel={mailModel}
                                user={this.state.user}
                            />
                        </div>
                    </Tabs.TabPane>

                )
            }

        )
        return tabs;
    }




    activeKey = (k: string) => {
        this.setState({
            activeView: k
        })
    }

    editTabs = (e, a) => {
        var boxOnView = this.state.mailBoxesOnView;
        if (a === "remove") {
            boxOnView = boxOnView.filter(x => x.id !== Number(e));
            var activeView = this.state.activeView;
            if (this.state.activeView === e) {
                activeView = "default"
            }
            this.setState({
                mailBoxesOnView: boxOnView,
                activeView: activeView
            })
        }
    }

    render(): React.ReactNode {
        return (
            <div className="boxes-conf-container">
                <Tabs type="editable-card"
                    hideAdd={true}
                    onTabClick={this.activeKey}
                    activeKey={this.state.activeView}
                    onEdit={this.editTabs}
                    tabPosition="top"
                    className="detail-tab">
                    {this.renderTabs()}
                </Tabs>
            </div>)
    }
}

export default withTranslation()(AccessControl);