import { Statistic } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { DataPoint } from "../../../../components/charts/@mod";
import PieChart from "../../../../components/charts/pie-chart";
import { DataModel } from "../../@mod";
import './index.less'



export interface PieChartSummaryViewModel extends WithTranslation {
    data: DataModel[]
}


interface PieChartSummaryViewState {

}

const COLOR :string[] = [
"#466AEB",
"#5CCCD5",
"#B1C3E2",
"#BEEBEE",
"#FF6270",
"#FFD143",
"#93C5FD"
];




class PieChartSummary extends React.Component<PieChartSummaryViewModel, PieChartSummaryViewState>{

    get pieChartData() {
        return this.props.data.sort((x,y)=> y.value - x.value)
        .map((x,index)=> {
            let dataPoint: DataPoint = {
                type: x.description[this.props.i18n.language],
                value: x.value,
                color:COLOR[index]
            }
            return dataPoint;
        });
    }

    renderStatistics = () => {
        return this.props.data.map(x => {
            return (
                <Statistic title={x.description[this.props.i18n.language]}
                    value={x.value} key={x.name}
                />
            )
        })

    }


    render(): React.ReactNode {
        return (

                <div className="pie-chart-summary-container">
                    <div className="pie-panel">
                        <PieChart data={this.pieChartData} />
                    </div>
                    <div className="summary-panel">
                        {this.renderStatistics()}
                    </div>
                </div>
        )
    }
}


export default withTranslation()(PieChartSummary);