import { Fraction } from "./fraction";

//解析数学表达式
function execMathExpress(formula, obj) {
	//局部变量
	const tempObj = Object.assign({
		_0: 0
	}, obj);
	//计算缓存
	const keyCache = {};
	let index = 1;

	formula = formula.replace(/ /g, '');//清理空格
	//解析数字
	formula = formula.replace(/(^|[(*+/-])(\d+\.\d+|\d+)/g, function (m, p1, p2) {
		if (keyCache[p2]) {
			return p1 + keyCache[p2];
		}
		const key = keyCache[p2] = '_' + index++;
		tempObj[key] = Fraction.create(p2);
		return p1 + key;
	})

	function getKey(p1, p2, p3) {
		const keyC = p1 + p2 + p3;
		if (keyCache[keyC]) {
			return keyCache[keyC];
		}
		const key = keyCache[keyC] = '_' + index++;
		const fA = Fraction.create(tempObj[p1])
		const fB = Fraction.create(tempObj[p3])
		if (p2 === '*') {
			tempObj[key] = fA.multiply(fB);
		} else if (p2 === '/') {
			tempObj[key] = fA.divide(fB);
		} else if (p2 === '+') {
			tempObj[key] = fA.add(fB);
		} else if (p2 === '-') {
			tempObj[key] = fA.sub(fB);
		}
		return key;
	}
	function run(s) {

		//子表达式
		if (/\(([^(]+?)\)/.test(s)) {
			s = s.replace(/\(([^(]+?)\)/g, function (m, p1, p2) {
				return run(p1);
			})
		}
		//负号
		s = s.replace(/([*/+]|^)-(\w+)/g, function (m, p1, p2) {
			return getKey('_0', '-', p2);
		})
		//返回
		if (/(^\w+$)/.test(s)) {
			return RegExp.$1;
		}
		//乘法、除法、加法、减法
		const expArr = ['*', '/', '+', '-'];
		for (let i = 0; i < expArr.length; i++) {
			const p = expArr[i];
			const reg = new RegExp('(\\w+)[' + p + '](\\w+)');
			while (reg.test(s)) {
				s = s.replace(reg, function (m, p1, p2) {
					return getKey(p1, p, p2);
				})
			}
		}
		//返回
		if (/(^\w+$)/.test(s)) {
			return RegExp.$1;
		}
		return run(s);
	}
	return tempObj[run(formula)]
}

export {execMathExpress};