import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AjvError, ArrayFieldTemplateProps, FieldTemplateProps, ObjectFieldTemplateProps, withTheme } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';
import { Button, Collapse, Input, Tooltip } from "antd";
import './index.less'
import 'antd/dist/antd.less'
import { execMathExpress } from '../../utils/calc';


const Form = withTheme(AntDTheme);

export interface DynamicEditorViewModel extends WithTranslation {
	schema: object,
	uiSchema?: any,
	content: object,
	isReadOnly?: boolean
	onSave?: (d: any) => void
	widthPercent: number,
	omitExtraData?:boolean
}


interface DynamicEditorViewState {
	isReadOnly: boolean,
	content: any

}

class DynamicEditorView extends React.Component<DynamicEditorViewModel, DynamicEditorViewState>{




	constructor(props: DynamicEditorViewModel) {
		super(props)
		this.state = {
			isReadOnly: props.isReadOnly ?? true,
			content: this.props.content
		}
	}

	submitData = (a, e) => {
		let { formData } = a
		console.log(a,e)
		this.props.onSave?.(formData);
	}

	getSumbitButton = () => {

		if (this.state.isReadOnly) {
			return (<></>)
		}
		return (
			<Button type="primary" htmlType="submit"   >{this.props.t("dynamic-editor.submit")}</Button>
		)
	}
	componentDidUpdate() {

	}

	getSnapshotBeforeUpdate(beforeVM: DynamicEditorViewModel, beforeState: DynamicEditorViewState) {
		if (beforeVM.isReadOnly !== this.props.isReadOnly) {

			this.setState({
				isReadOnly: this.props.isReadOnly ?? true
			})
			this.setState({
				content: this.props.content
			})
		}
		if (this.props.content !== beforeVM.content) {
			this.setState({
				content: this.props.content
			})
		}
		return null;
	}

	resloveSchema = () => {
		return this.props.schema;
	}

	objectFieldTemplate = (props: ObjectFieldTemplateProps, context: any) => {
		return (

			<div className="dynamic-form">
				<div className="dynamic-top-bar">
					<div className="dynamic-title">
						{props.title}
					</div>
					<div className="dynamic-description">
						{props.description}
					</div>
				</div>
				<div className={"dynamic-fields " + props.schema.type}>
					{props.properties.map(element => {
						return <div key={element.name} className={"property-wrapper width-percent-" + this.props.widthPercent}>{element.content}</div>
					})}
				</div>
			</div>
		);
	}

	arrayItems = (props: ArrayFieldTemplateProps) => {

		return props.items.map(el => {
			return (
				<Collapse.Panel key={el.index} header={props.title + " - " + el.index} className="array-pannel">
					{el.children}
				</Collapse.Panel>
			)
		})
	}


	arrayFieldTemplate = (props: ArrayFieldTemplateProps, context: any) => {
		return (
			<div>
				<div className="array-ops">
					{props.canAdd && !props.readonly && <Button type="primary" onClick={props.onAddClick}>
						{"Add " + props.title}</Button>}
				</div>
				<Collapse defaultActiveKey={['1']}>
					{this.arrayItems(props)}
				</Collapse>
			</div>
		)
	}

	transformErrors = (errors: AjvError[]): AjvError[] =>{
		return errors.map(x=>{
			if(x.name==="type"){
				if(x.params.type==="number"){
					x.message = this.props.t("dynamic-editor.should-be-number")
				}
			}
			return x;
		})
	}


	get calculationFields() {
		let fields: React.ReactNode[] = [];
		let calcField = this.props.uiSchema?.calculation;
		if (calcField) {
			for (var key in calcField) {
				let result: any = {};
				try {
					result = execMathExpress(calcField[key].value, this.state.content);
				} catch {

				}
				let value = result.num ? result.num / result.den : "N/A";
				let percentValue = result.num ? (result.num / result.den / 100) : "N/A";

				let displayValue =
					calcField[key].display === "original" ?
						value :
						percentValue + "%"
				fields.push(
					(
						<div className="calculation-field" key={key}>
							<div className="label">
								<Tooltip placement="top" title={calcField[key].description} ><Button type="link">{calcField[key].title}</Button></Tooltip>
							</div>
							<div className="value">

								<Input readOnly value={displayValue}>

								</Input>
							</div>
						</div>
					)
				)
			}
		}
		return fields;
	}

	onchanged = (e) => {
		this.setState({
			content: e.formData
		})
	}

	renderError = (label:string,rawErrors?:string[])=>{

		return rawErrors?.map(x=>{
		
			return (<div>{ label + x}</div>)})
	}

	fieldTemplate = (props: FieldTemplateProps) => {
		return (
		<div className={props.classNames}>
			<div className="dynamic-field-label">
				<Tooltip title={props.description} >
					{props.label}<span className="required-star">{props.required ? "*" : null}</span>
				</Tooltip>
			</div>
			{props.children}
			<div className="dynamic-error-field">
					{this.renderError(props.label,props.rawErrors)}
			</div>
			{props.help}
		</div>)
	}

	render(): React.ReactNode {
		return (<>
			<div className="dynamic-form-container">
				<Form schema={this.resloveSchema()}
					uiSchema={this.props.uiSchema}
					formData={this.state.content}
					onSubmit={this.submitData}
					ObjectFieldTemplate={this.objectFieldTemplate}
					readonly={this.state.isReadOnly}
					onChange={this.onchanged}
					transformErrors={this.transformErrors}
					ArrayFieldTemplate={this.arrayFieldTemplate}
					FieldTemplate={this.fieldTemplate}
					disabled={false}
					omitExtraData={this.props.omitExtraData}
					
				>
					<div className="calculation-fields">
						{this.calculationFields}
					</div>
					{this.getSumbitButton()}
				</Form>

			</div>
		</>)
	}

}

export default withTranslation()(DynamicEditorView);


