import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import {Card, message} from "antd";
import {NotificationConfigurationModel} from "../../../@mod";
import './index.less'
import NotificationSettingBox from "./setting-box";
import ApiServer from "../../../../../ApiServer";

export interface ChannelConfigurationModel extends WithTranslation {
    apiServer: ApiServer,
    title?: string,
    channelType: string
}

interface ChannelConfigurationState {
    title: string,
    systemConfigurationKey?: string,
    configurationList?: NotificationConfigurationModel[]
}

class ChannelConfiguration extends React.Component<ChannelConfigurationModel, ChannelConfigurationState> {
    constructor(props: ChannelConfigurationModel) {
        super(props)
        this.state = {
            title: this.props.title ? this.props.title : "参数配置"
        }
    }

    componentDidMount() {
        this.fetchConfigurationList()
    }

    fetchConfigurationList = () => {
        this.props.apiServer.apiSystem.getChannelConfigurationKeys(this.props.channelType)
            .then(res => {
                if (res.data.data?.success) {
                    let systemConfigurationKey = res.data.data?.dto?.systemConfigurationKey
                    let configurationList = res.data.data?.dto?.notificationConfigurationKeys?.map(key => {
                        return {
                            key: key,
                            value: "******"
                        };
                    })
                    this.setState({
                        systemConfigurationKey: systemConfigurationKey ? systemConfigurationKey : "",
                        configurationList: configurationList
                    })
                    return
                }
                console.log("fail to get configuration of channel: ", this.props.channelType)
            })
    }

    changeConfiguration = (key: string, value: string) => {
        this.props.apiServer.apiSystem.putChannelConfiguration({
            keyName: this.state.systemConfigurationKey,
            jsonKey: key,
            newValue: value
        }).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("success")).then()
            } else {
                message.error(this.props.t("fail")).then()
            }
        })
    };

    resetConfiguration = (key: string) => {
        this.props.apiServer.apiSystem.putResetChannelConfiguration({
            keyName: this.state.systemConfigurationKey,
            jsonKey: key
        }).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("success")).then()
            } else {
                message.error(this.props.t("fail")).then()
            }
        })
    }

    render(): React.ReactNode {
        return (
            <Card
                title={this.props.title} bordered={true}
                headStyle={{textAlign: "left"}}
            >
                {
                    this.state.configurationList?.map(x => (
                        <NotificationSettingBox
                            item={x}
                            changeConfiguration={this.changeConfiguration}
                            resetConfiguration={this.resetConfiguration}
                        />
                    ))
                }
            </Card>
        );
    }
}

export default withTranslation()(ChannelConfiguration);