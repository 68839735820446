import { Tag } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";
import { TagModel } from "../@mod";
import './index.less'

export interface TagEditorViewModel {
	allTags?: TagModel[]
	onTagAdd?: (tagId: number) => void
}

interface TagEditorViewState {

}

class TagEditorView extends React.Component<TagEditorViewModel, TagEditorViewState>{

	renderTagModel = (x: TagModel) => {
		return (
			<Tag className="tag-instance" color={x.color} onClick={() => this.props.onTagAdd?.(x.id)}>
				{x.name}
			</Tag>)
	}

	renderTagList = (tagModel: TagModel) => {

		let relatedTags = this.props.allTags?.filter(
			x => x.name.split("-").length > 1 && 
				x.name.indexOf(tagModel.name) >= 0
				
				
				);

		let f = () => {
			return relatedTags?.map(y => {
				return <div key={y.id}>
					<Tag className="tag-instance" color={y.color} onClick={() => {
						this.props.onTagAdd?.(y.id)
						this.props.onTagAdd?.(tagModel.id)}
						}>
						{y.name}
					</Tag></div>
			})
		}

		return (
			<div key={tagModel.id} className="tag-model">
				{this.renderTagModel(tagModel)}
				{f()}
			</div>

		)
	}


	renderTags = () => {
		let rootTags = this.props.allTags?.filter(x => x.name.split("-").length == 1);

		return rootTags?.map(x => {
			return (
				<div>
					{this.renderTagList(x)}
				</div>

			)
		}) ?? <></>;
	}

	render(): React.ReactNode {
		return (
			<div className="tag-editor-ticket">
				{this.renderTags()}
			</div>
		)
	}
}

export default withTranslation()(TagEditorView)