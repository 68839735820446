import { PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { ActionEventHandler } from "../../../../components";
import WorkbenchTable from "../../../../components/table/workbench-table";
import { PolicyModel, columnsProvider } from "./@mod";
import './index.less'


interface PolicyPageModel extends WithTranslation {

}

interface PolicyPageModel extends WithTranslation {
	apiServer: ApiServer
}

interface PolicyPageState {
	policies: PolicyModel[],
	dataLoading: boolean
}


class PolicyPage extends React.Component<PolicyPageModel, PolicyPageState>{
	constructor(props: PolicyPageModel) {
		super(props)
		this.state = {
			dataLoading: true,
			policies: []
		}
	}

	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData().finally(() => {
				this.setState({
					dataLoading: false
				})
			})
		}
	}

	async loadData() {
		await this.props.apiServer.apiPolicy.getPolicyList()
			.then(
				x => {
					this.setState({
						policies:
							x.data.data?.list?.map(y => {
								let p: PolicyModel = {
									key: (y.id ?? 0).toString(),
									name: y.name ?? "",
									description: y.description ?? "",
									createdTime: y.createdTime ?? "",
									updatedTime: y.updatedTime ?? "",
									allowed: y.allowed ?? false,
									id: y.id ?? 0
								};
								return p;
							}) ?? []
					});
				}
			);
	}


	render(): React.ReactNode {
		return (
			<div className="policy-container site-drawer-render-in-current-wrapper" id="user-container" >
				<PageHeader title={this.props.t("policy-editor.self")} extra={
					[
						<div className="user-global-button" key="global-button">
						</div>

					]}
					subTitle={this.props.t("policy-editor.editor-subtitle")}
				>
					<Content>
						<WorkbenchTable<PolicyModel>
							data={this.state.policies}
							dataLoading={this.state.dataLoading}
							columnProvider={
								(a: ActionEventHandler<PolicyModel>) => columnsProvider(this.props.t, a)
							} />
					</Content>

				</PageHeader>
			</div>
		)
	}
}


export default withTranslation()(PolicyPage);