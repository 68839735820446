import { Button, Descriptions, Popconfirm, Popover, Tag } from "antd";
import { ColumnType } from "antd/lib/table"
import moment from "moment-timezone";
import React from "react";
import { WorkbenchMailBoxContactDto, WorkbenchMailContactDto } from "../../../../api";
import { ActionEventHandler } from "../../../../components";
import ColumnTextFilter from "../../../../components/table/column-text-filter";
import ApiServer from "../../../../ApiServer";

const TIME_PATTERN = "YYYY-MM-DD HH:mm:ss"

export interface MailContactTag {
    id: number,
    name: string,
    color: string
}

export interface MailContactUsermodel {
    id: number,
    fullName: string
}

export interface MailContactModel {
    id: number,
    emailAddress: string,
    nickname: string,
    tag: MailContactTag[],
    extendsInfo: any,
    enable: boolean,
    createdTime: string,
    createdBy: MailContactUsermodel,
    updatedTime: string,
    updatedBy: MailContactUsermodel
}

export interface MailContactMailBoxModel {
    id: number
}

export interface WorkbenchMailBoxContactModel {
    id: number,
    emailContact: MailContactModel,
    mailBox: MailContactMailBoxModel,
    type: string,
    createdTime: string,
    createdBy: MailContactUsermodel,
    updatedTime: string,
    updatedBy: MailContactUsermodel
}

export const CONTACT_BINDING_TYPE = {
    TO: {
        code: 0,
        name: "to"
    },
    CC: {
        code: 1,
        name: "cc"
    },
    BCC: {
        code: 2,
        name: "bcc"
    }
}

export const covertWorkbenchMailContactDtoToModel = (x: WorkbenchMailContactDto): MailContactModel => {
    return {
        id: x.id ?? -1,
        emailAddress: x.emailAddress ?? "",
        nickname: x.nickname ?? "",
        tag: x.tag?.map(t => {
            return {
                id: t.id ?? -1,
                name: t.name ?? "",
                color: t.color ?? ""
            }
        }) ?? [],
        extendsInfo: x.extendsInfo ? JSON.parse(x.extendsInfo) : null,
        enable: x.enable ?? false,
        createdTime: x.createdTime ?? "",
        createdBy: {
            id: x.createdBy?.id ?? -1,
            fullName: x.createdBy?.fullName ?? ""
        },
        updatedTime: x.updatedTime ?? "",
        updatedBy: {
            id: x.updatedBy?.id ?? -1,
            fullName: x.updatedBy?.fullName ?? ""
        }
    }
}

export const covertWorkbenchMailBoxContactDtoToModel = (x: WorkbenchMailBoxContactDto): WorkbenchMailBoxContactModel => {
    return {
        id: x.id ?? NaN,
        emailContact: covertWorkbenchMailContactDtoToModel(x.emailContact ?? {}),
        mailBox: { id: NaN },
        type: x.type ?? "",
        createdTime: x.createdTime ?? "",
        createdBy: {
            id: x.createdBy?.id ?? NaN,
            fullName: x.createdBy?.fullName ?? ""
        },
        updatedTime: x.updatedTime ?? "",
        updatedBy: {
            id: x.updatedBy?.id ?? NaN,
            fullName: x.updatedBy?.fullName ?? ""
        },
    }
}

export const loadContactOfMailbox = async (mailboxId: number, apiServer: ApiServer): Promise<Array<WorkbenchMailBoxContactDto>> => {
    const response = await apiServer.apiMail.getContactsByMailbox(mailboxId)
    return response.data.data?.dto ?? []
}


export const mailSelectiveColumnProvider = (
    translater: ((d: string) => string)): ColumnType<MailContactModel>[] => {

    return [
        {
            title: translater("mails.contact.fields.emailAddress"),
            key: "emailAddress",
            dataIndex: "emailAddress",
            render: (value, record: MailContactModel, index) => {
                return record.emailAddress
            },
            filterSearch: true,
            onFilter: (value: number | string | boolean, record: MailContactModel) => record.emailAddress.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.emailAddress")} />)
            }
        },
        {
            title: translater("mails.contact.fields.nickname"),
            key: "nickname",
            dataIndex: "nickname",
            render: (value, record: MailContactModel, index) => {
                return record.nickname
            },
            filterSearch: true,
            onFilter: (value: number | string | boolean, record: MailContactModel) => record.nickname.includes(String(value)),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.nickname")} />)
            }
        },
        {
            title: translater("mails.contact.fields.extendsInfo"),
            key: "extendsInfo",
            dataIndex: "extendsInfo",
            render: (value, record: MailContactModel, index) => {
                let a: React.ReactNode[] = []
                for (const key in record.extendsInfo) {
                    a.push(
                        <Descriptions.Item label={key} key={key}>
                            {record.extendsInfo[key]}
                        </Descriptions.Item>
                    )
                }
                const displayer = (
                    <Descriptions title={translater("mails.contact.extends-info")}
                        layout="horizontal" bordered
                    >
                        {a}
                    </Descriptions>)
                return (
                    <Popover
                        trigger={"click"}
                        content={displayer}
                        style={{ padding: "8px" }}
                    >
                        <Button >{translater("mails.contact.click-view")}</Button>
                    </Popover>
                )
            }
        },
        {
            title: translater("mails.contact.fields.enable"),
            key: "enable",
            dataIndex: "enable",
            render: (value, record: MailContactModel, index) => {
                return record.enable ? translater("mails.contact.yes") : translater("mails.contact.no")
            },
            filterSearch: true,
            onFilter: (value: boolean | string | number, record: MailContactModel) => Boolean(record.enable) === Boolean(value),
            filters: [{ text: "是", value: 1 }, { text: "否", value: 0 }],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.enable")} />)
            }
        },
        {
            title: translater("mails.contact.fields.tag"),
            key: "tag",
            dataIndex: "tag",
            render: (value, record: MailContactModel, index) => {
                return record.tag.map(tag => {
                    return <Tag key={tag.name} color={tag.color}>{tag.name}</Tag>
                })
            }
        }
    ]

}


export const mailImportContactColumnProvider = (
    translater: ((d: string) => string),
    handler: ActionEventHandler<MailContactModel>): ColumnType<MailContactModel>[] => {

    return [
        {
            title: translater("mails.contact.fields.emailAddress"),
            key: "emailAddress",
            dataIndex: "emailAddress",
            render: (value, record: MailContactModel, index) => {
                return record.emailAddress
            },
            filterSearch: true,
            onFilter: (value: number | string | boolean, record: MailContactModel) => record.emailAddress.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.emailAddress")} />)
            }
        },
        {
            title: translater("mails.contact.fields.nickname"),
            key: "nickname",
            dataIndex: "nickname",
            render: (value, record: MailContactModel, index) => {
                return record.nickname
            },
            filterSearch: true,
            onFilter: (value: number | string | boolean, record: MailContactModel) => record.nickname.includes(String(value)),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.nickname")} />)
            }
        },
        {
            title: translater("mails.contact.fields.extendsInfo"),
            key: "extendsInfo",
            dataIndex: "extendsInfo",
            render: (value, record: MailContactModel, index) => {
                let a: React.ReactNode[] = []
                for (const key in record.extendsInfo) {
                    a.push(
                        <Descriptions.Item label={key} key={key}>
                            {record.extendsInfo[key]}
                        </Descriptions.Item>
                    )
                }
                const displayer = (
                    <Descriptions title={translater("mails.contact.extends-info")}
                        layout="horizontal" bordered
                    >
                        {a}
                    </Descriptions>)
                return (
                    <Popover
                        trigger={"click"}
                        content={displayer}
                        style={{ padding: "8px" }}
                    >
                        <Button >{translater("mails.contact.click-view")}</Button>
                    </Popover>
                )
            }
        },
        {
            title: translater("mails.contact.fields.enable"),
            key: "enable",
            dataIndex: "enable",
            render: (value, record: MailContactModel, index) => {
                return record.enable ? translater("mails.contact.yes") : translater("mails.contact.no")
            },
            filterSearch: true,
            onFilter: (value: boolean | string | number, record: MailContactModel) => Boolean(record.enable) === Boolean(value),
            filters: [{ text: "是", value: 1 }, { text: "否", value: 0 }],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.enable")} />)
            }
        },
        {
            title: translater("mails.contact.fields.tag"),
            key: "tag",
            dataIndex: "tag",
            render: (value, record: MailContactModel, index) => {
                return record.tag.map(tag => {
                    return <Tag key={tag.name} color={tag.color}>{tag.name}</Tag>
                })
            }
        },
        {
            title: translater("mails.contact.operation"),
            key: "operation",
            render: (value, record: MailContactModel, index) => {
                return (<div>
                    <Button danger type="primary"
                        onClick={
                            () => handler(record, "remove",)
                        }
                    >{translater("mails.contact.delete")}</Button>
                </div>)
            }
        }
    ]

}


export const mailContactColumnProvider = (translater: ((d: string) => string), handler: ActionEventHandler<MailContactModel>, operation?: (record: MailContactModel) => React.ReactNode): ColumnType<MailContactModel>[] => {
    return [
        {
            title: translater("mails.contact.fields.id"),
            key: "id",
            dataIndex: "id",
            render: (value, record: MailContactModel, index) => {
                return "#" + record.id
            },
            filterSearch: true,
            onFilter: (value: number | string | boolean, record: MailContactModel) => Number(record.id) === Number(value),
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.id")} />)
            }
        },
        {
            title: translater("mails.contact.fields.emailAddress"),
            key: "emailAddress",
            dataIndex: "emailAddress",
            render: (value, record: MailContactModel, index) => {
                return record.emailAddress
            },
            filterSearch: true,
            onFilter: (value: number | string | boolean, record: MailContactModel) => record.emailAddress.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.emailAddress")} />)
            }
        },
        {
            title: translater("mails.contact.fields.nickname"),
            key: "nickname",
            dataIndex: "nickname",
            render: (value, record: MailContactModel, index) => {
                return record.nickname
            },
            filterSearch: true,
            onFilter: (value: number | string | boolean, record: MailContactModel) => record.nickname.includes(String(value)),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.nickname")} />)
            }
        },
        {
            title: translater("mails.contact.fields.extendsInfo"),
            key: "extendsInfo",
            dataIndex: "extendsInfo",
            render: (value, record: MailContactModel, index) => {
                let a: React.ReactNode[] = []
                for (const key in record.extendsInfo) {
                    a.push(
                        <Descriptions.Item label={key} key={key}>
                            {record.extendsInfo[key]}
                        </Descriptions.Item>
                    )
                }
                const displayer = (<Descriptions title={translater("mails.contact.extends-info")} style={{ width: '1000px' }}>
                    {a}
                </Descriptions>)
                return (
                    <Popover
                        trigger={"click"}
                        content={displayer}
                    >
                        <Button >{translater("mails.contact.click-view")}</Button>
                    </Popover>
                )
            }
        },
        {
            title: translater("mails.contact.fields.enable"),
            key: "enable",
            dataIndex: "enable",
            render: (value, record: MailContactModel, index) => {
                return record.enable ? translater("mails.contact.yes") : translater("mails.contact.no")
            },
            filterSearch: true,
            onFilter: (value: boolean | string | number, record: MailContactModel) => Boolean(record.enable) === Boolean(value),
            filters: [{ text: "是", value: 1 }, { text: "否", value: 0 }],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.enable")} />)
            }
        },
        {
            title: translater("mails.contact.fields.tag"),
            key: "tag",
            dataIndex: "tag",
            render: (value, record: MailContactModel, index) => {
                return record.tag.map(tag => {
                    return <Tag key={tag.name} color={tag.color}>{tag.name}</Tag>
                })
            }
        },
        {
            title: translater("mails.contact.fields.createdTime"),
            key: "createdTime",
            dataIndex: "createdTime",
            render: (value, record: MailContactModel, index) => {
                return record.createdTime ? moment.parseZone(record.createdTime).format(TIME_PATTERN) : ""
            },
            sorter: (a: MailContactModel, b: MailContactModel) => { return moment(a.createdTime).unix() - moment(b.createdTime).unix() },
            showSorterTooltip: false
        },
        {
            title: translater("mails.contact.fields.createdBy"),
            key: "createdBy",
            dataIndex: "createdBy",
            render: (value, record: MailContactModel, index) => {
                return record.createdBy.fullName
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailContactModel) => record.createdBy.fullName.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.createdBy")} />)
            }
        },
        {
            title: translater("mails.contact.fields.updatedTime"),
            key: "updatedTime",
            dataIndex: "updatedTime",
            render: (value, record: MailContactModel, index) => {
                return record.updatedTime ? moment.parseZone(record.updatedTime).format(TIME_PATTERN) : ""
            },
            sorter: (a: MailContactModel, b: MailContactModel) => moment(a.updatedTime).unix() - moment(b.updatedTime).unix(),
            showSorterTooltip: false
        },
        {
            title: translater("mails.contact.fields.updatedBy"),
            key: "updatedBy",
            dataIndex: "updatedBy",
            render: (value, record: MailContactModel, index) => {
                return record.updatedBy.fullName
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: MailContactModel) => record.updatedBy.fullName.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.updatedBy")} />)
            }
        },
        {
            title: translater("mails.contact.operation"),
            key: "operation",
            render: (value, record: MailContactModel, index) => {
                return operation?.(record) || (
                    <div className="operation-bar">
                        <Button size="small" type="primary" onClick={() => handler(record, "edit")}>{translater("mails.contact.edit")}</Button>
                        <Popconfirm
                            title={translater("mails.contact.confirm-delete")}
                            onConfirm={() => handler(record, "delete")}
                        >
                            <Button size="small" danger>{translater("mails.contact.delete")}</Button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]
}

export const mailboxBindContactColumnProvider = (translater: ((d: string) => string), handler: ActionEventHandler<WorkbenchMailBoxContactModel>, operation?: (record: WorkbenchMailBoxContactModel) => React.ReactNode): ColumnType<WorkbenchMailBoxContactModel>[] => {
    return [
        {
            title: translater("mails.contact.fields.id"),
            key: "id",
            dataIndex: "id",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                return "#" + record.id
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: WorkbenchMailBoxContactModel) => Number(record.id) === Number(value),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.id")} />)
            }
        },
        {
            title: translater("mails.contact.fields.emailAddress"),
            key: "emailAddress",
            dataIndex: "emailAddress",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                return record.emailContact.emailAddress
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: WorkbenchMailBoxContactModel) =>
                record.emailContact.emailAddress.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.emailAddress")} />)
            }
        },
        {
            title: translater("mails.contact.fields.nickname"),
            key: "nickname",
            dataIndex: "nickname",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                return record.emailContact.nickname
            },

            filterSearch: true,
            onFilter: (value: string | number | boolean, record: WorkbenchMailBoxContactModel) =>
                record.emailContact.nickname.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.fields.nickname")} />)
            }
        },
        {
            title: translater("mails.contact.fields.extendsInfo"),
            key: "extendsInfo",
            dataIndex: "extendsInfo",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                let a: React.ReactNode[] = []
                for (const key in record.emailContact.extendsInfo) {
                    a.push(
                        <Descriptions.Item label={key} key={key}>
                            {record.emailContact.extendsInfo[key]}
                        </Descriptions.Item>
                    )
                }
                const displayer = (<Descriptions title={translater("mails.contact.extends-info")} style={{ width: '1000px' }}>
                    {a}
                </Descriptions>)
                return (
                    <Popover
                        trigger={"click"}
                        content={displayer}
                    >
                        <Button >{translater("mails.contact.click-view")}</Button>
                    </Popover>
                )
            }
        },
        {
            title: translater("mails.contact.fields.enable"),
            key: "enable",
            dataIndex: "enable",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                return record.emailContact.enable ? translater("mails.contact.yes") : translater("mails.contact.no")
            }
        },
        {
            title: translater("mails.contact.fields.tag"),
            key: "tag",
            dataIndex: "tag",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                return record.emailContact.tag.map(tag => {
                    return <Tag key={tag.name} color={tag.color}>{tag.name}</Tag>
                })
            }
        },
        {
            title: translater("mails.contact.binding.fields.bindingTime"),
            key: "createdTime",
            dataIndex: "createdTime",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                return record.createdTime ? moment.parseZone(record.createdTime).format(TIME_PATTERN) : ""
            },
            sorter: (a: WorkbenchMailBoxContactModel, b: WorkbenchMailBoxContactModel) => moment(a.createdTime).unix() - moment(b.createdTime).unix(),
            showSorterTooltip: false
        },
        {
            title: translater("mails.contact.binding.fields.operator"),
            key: "createdBy",
            dataIndex: "createdBy",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                return record.createdBy.fullName
            },
            filterSearch: true,
            onFilter: (value: string | boolean | number, record: WorkbenchMailBoxContactModel) =>
                record.createdBy.fullName.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("mails.contact.binding.fields.operator")} />)
            }
        },
        {
            title: translater("mails.contact.operation"),
            key: "operation",
            render: (value, record: WorkbenchMailBoxContactModel, index) => {
                return operation?.(record) || (
                    <div className="operation-bar">
                        <Popconfirm
                            title={translater("mails.contact.binding.confirm-unbinding")}
                            onConfirm={() => handler(record, "delete")}
                        >
                            <Button size="small" danger>{translater("mails.contact.binding.unbinding")}</Button>
                        </Popconfirm>
                    </div>
                )
            }
        }
    ]
}



export const loadTagList = async (apiServer: ApiServer): Promise<MailContactTag[]> => {
    return apiServer.apiProductivity.getTagList().then(x => {
        if (x.data.data?.tagDtoList) {
            const tagList = x.data.data?.tagDtoList
            return tagList.map(tag => {
                return {
                    id: tag.id ?? 0,
                    name: tag.name ?? "",
                    color: tag.color ?? ""
                }
            })
        }
        return []
    })
}