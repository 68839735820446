import { Button, notification, PageHeader } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import './index.less';
import {SyncOutlined} from "@ant-design/icons"
import WorkBenchTable from "../../../components/table/workbench-table";
import { columnsProvider, ExportJobModel } from "./@mod";

export interface ExportViewModel extends WithTranslation {
    apiServer:ApiServer
}

interface ExportState {
    data:ExportJobModel[],
    dataLoading:boolean
}

class Exports extends React.Component<ExportViewModel,ExportState> {

    constructor(props:ExportViewModel){
        super(props)
        this.state={
            data:[],
            dataLoading:false
        }
    }

    loadData = async () => {
        this.setState({
            dataLoading:true
        })
        this.props.apiServer.apiExport.getExportJobs(1)
        .then(x=>{
            if(x.data.data?.succeed){
                var jobs = x.data.data.jobs?.map(y=>{
                    let j :ExportJobModel ={
                        id:y.id??0,
                        startedAt:Number(y.startedAt??"0"),
                        completedAt:Number(y.completedAt??"0"),
                        requestedAt:Number(y.requestedAt??"0"),
                        jobId:y.jobId??"",
                        params:"",
                        status:y.status,
                        requester:y.requester,
                        attachment:"",
                        type:1
                    }
                    return j;
                }).sort((a,b)=>b.id-a.id)||[]
                this.setState({
                    data:jobs
                })
                
            }
        }).finally(()=>{
            this.setState({
                dataLoading:false
            })
        })

    }

    l:Promise<void>| undefined;
    componentDidMount(){
        if(!this.l){
            this.l = this.loadData()
            .finally(()=>{
                this.l=undefined;
                this.setState({
                    dataLoading:true
                })
            })
        }
    }

    downloadClick = (m:ExportJobModel)=>{
        this.props.apiServer.apiExport.getExportJobDownloadUrl(
            1,m.jobId??""
        ).then(y=>{
            if(y.data.data?.succeed){
                var element = document.createElement('a');
                element.target="_blank";
                element.href= y.data.data.downloadUrl??""
                document.body.appendChild(element)
                element.click()
                URL.revokeObjectURL(element.href);
                document.body.removeChild(element);
            } else{
                notification.error({ message: this.props.t("tickets.download-fail") })
            }
        });

    }


    render(): React.ReactNode {
        return (<div className="ticket-export-container">
            <PageHeader title={this.props.t("tickets.exports-page")}
					subTitle={this.props.t("tickets.export-subtitle")}
                    extra={[
                            <Button loading={this.state.dataLoading} icon={ <SyncOutlined />}>{this.props.t("tickets.exports.flush-jobs")}</Button>

                    ]}
					>
                        <WorkBenchTable<ExportJobModel> 
                            columnProvider={ (h)=> columnsProvider(this.props,h) }
                            data={this.state.data}
                            dataLoading ={this.state.dataLoading}
                            onDetail={this.downloadClick}
                        />
				</PageHeader>
        </div>)
    }

}

export default withTranslation()(Exports);