import { Select, Skeleton } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { TicketStatusModel, TicketTypeModel, TicketSourceModel, TicketPriorityModel } from "../../../@mod";
import { EscalationRuleModel } from "../@mod";
import './index.less'


export interface TicketSelectorViewModel extends WithTranslation {
    ticketTypes: TicketTypeModel[]
    ticketStatus: TicketStatusModel[]
    ticketSource: TicketSourceModel[]
    ticketPriority: TicketPriorityModel[],
    dataLoading: boolean,
    rule:EscalationRuleModel,
    onChanged ?:()=>void
}


interface TicketSelctorState {
    ticketTypeSelected: number[],
    ticketStatusSelected: number[],
    ticketSourceSelected: number[],
    ticketPrioritySelected: number[],
}


class TicketSelector extends React.Component<TicketSelectorViewModel, TicketSelctorState>{

    constructor(props: TicketSelectorViewModel) {
        super(props)

        this.state = {
            ticketTypeSelected: this.props.rule.type ?? [],
            ticketStatusSelected: this.props.rule.status ?? [],
            ticketSourceSelected: this.props.rule.source ?? [],
            ticketPrioritySelected: this.props.rule.priority ?? []
        }
    }

    renderTicketTypePreview = () => {

        return this.props.ticketTypes.map(
            x => (
                <Select.Option value={x.id} key={x.id}>
                    {x.description[this.props.i18n.language]}
                </Select.Option>)
        )
    }

    onTicketTypeChanged = (value: number[]) => {
        this.setState({
            ticketTypeSelected: value
        })
        this.props.rule.type=value;
        this.props.onChanged?.();
    }
    renderTicketPriorityPreview = () => {
        return this.props.ticketPriority.map(
            x => (
                <Select.Option value={x.id} key={x.id}>
                    {x.description[this.props.i18n.language]}
                </Select.Option>)
        )
    }

    onTicketPriorityChanged = (value: number[]) => {
        this.setState({
            ticketPrioritySelected: value
        })
        this.props.rule.priority=value;
        this.props.onChanged?.();
    }

    renderTicketSourcePreview = () => {

        return this.props.ticketSource.map(
            x => (
                <Select.Option value={x.id} key={x.id}>
                    {x.description[this.props.i18n.language]}
                </Select.Option>)
        )
    }

    onTicketSourceChange = (value: number[]) => {
        this.setState({
            ticketSourceSelected: value
        })
        this.props.rule.source = value
        this.props.onChanged?.();
    }

    renderTicketStatusPreview = () => {

        return this.props.ticketStatus.map(
            x => (
                <Select.Option value={x.id} key={x.id}>
                    {x.description[this.props.i18n.language]}
                </Select.Option>)
        )
    }

    onTicketStatusChange = (value: number[]) => {
        this.setState({
            ticketStatusSelected: value
        })
        this.props.rule.status = value;
        this.props.onChanged?.();
    }



    render(): React.ReactNode {

        return (<div>
            {
                this.props.dataLoading &&
                <div>
                    <Skeleton active />
                </div>
            }{
                !this.props.dataLoading &&
                <div  className="escalation-ticket-selector">
                    <div className="title"><h3>{this.props.t("tickets.ticket-selector")}</h3></div>
                    <div className="field">
                        <div>{this.props.t("tickets.type.self")}</div>
                        <Select mode="tags" style={{ width: '100%' }}
                            value={this.state.ticketTypeSelected}
                            placeholder={this.props.t("tickets.type.self")}
                            onChange={this.onTicketTypeChanged}>
                            {this.renderTicketTypePreview()}
                        </Select>
                    </div>
                    <div className="field">
                        <div>{this.props.t("tickets.priority.self")}</div>
                        <Select mode="tags" style={{ width: '100%' }}
                            value={this.state.ticketPrioritySelected}
                            placeholder={this.props.t("tickets.priority.self")}
                            onChange={this.onTicketPriorityChanged}>
                            {this.renderTicketPriorityPreview()}
                        </Select>
                    </div>
                    <div className="field">
                        <div>{this.props.t("tickets.source.self")}</div>
                        <Select mode="tags" style={{ width: '100%' }}
                            value={this.state.ticketSourceSelected}
                            placeholder={this.props.t("tickets.source.self")}
                            onChange={this.onTicketSourceChange}>
                            {this.renderTicketSourcePreview()}
                        </Select>
                    </div>
                    <div className="field">
                        <div>{this.props.t("tickets.status.self")}</div>
                        <Select mode="tags" style={{ width: '100%' }}
                            value={this.state.ticketStatusSelected}
                            placeholder={this.props.t("tickets.status.self")}
                            onChange={this.onTicketStatusChange}>
                            {this.renderTicketStatusPreview()}
                        </Select>
                    </div>
                </div>
            }


        </div>)
    }
}

export default withTranslation()(TicketSelector);






