import { notification } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TicketDto } from "../../../../api";
import ApiServer from "../../../../ApiServer";
import { RouteContext, withRouter } from "../../../../utils";
import { convertTo, TicketModel } from "../../@mod";

import "./index.less";
import TicketRelated from "./ticket-related";

export interface RelatedTicketsViewModel extends WithTranslation {
	apiServer: ApiServer,
	ticketId: number,
	router?:RouteContext
}

interface RelatedTicketsState {
	dataloading: boolean,
	parent: TicketModel[],
	children: TicketModel[],
	link: TicketModel[],
}

class RelatedTickets extends React.Component<RelatedTicketsViewModel, RelatedTicketsState>{

	constructor(props: RelatedTicketsViewModel) {
		super(props)
		this.state = {
			dataloading: true,
			parent: [],
			children: [],
			link: []
		}
	}

	componentDidUpdate(){

	}
	getSnapshotBeforeUpdate(preProps:RelatedTicketsViewModel){
		if(preProps.ticketId!==this.props.ticketId){
			this.componentDidMount();
		}
		return null;
	}

	convertToTicketModel(t: TicketDto[]): TicketModel[] {
		return t.map(convertTo);
	}

	loadData = async () => {

		let parentList = await this.props.apiServer.apiTickets.getRelatedTickets(
			this.props.ticketId,
			"parent"
		).then(x => {
			if (x.data.data?.success) {
				return x.data.data.tickets?.map(convertTo);
			}
		}) ?? [];

		let linkList = await this.props.apiServer.apiTickets.getRelatedTickets(
			this.props.ticketId,
			"link"
		).then(x => {
			if (x.data.data?.success) {
				return x.data.data.tickets?.map(convertTo);
			}
		}) ?? [];
		let children = await this.props.apiServer.apiTickets.getRelatedTickets(
			this.props.ticketId,
			"children"
		).then(x => {
			if (x.data.data?.success) {
				return x.data.data.tickets?.map(convertTo);
			}
		}) ?? [];
		this.setState({
			children: children,
			link: linkList,
			parent: parentList
		})

	}
	search = (name: string) => {

		return this.props.apiServer.apiTickets.searchTicketsByName(undefined, name,"").then(
			x => {
				return x.data.data?.recommendTickets?.map(convertTo) ?? [];
			}
		)
	}

	l: Promise<void> | undefined
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => {
					this.l = undefined;
					this.setState(
						{
							dataloading: false
						}
					)
				})
		}

	}

	routeToTicket = (t: TicketModel) => {
		console.log(t);
		this.props.router?.navigate("/ticket/"+t.id+"/detail",{
			replace:true
		})
	}

	notficationIfIdIsTheSame = (l, r) => {
		if (Number(l) === Number(r)) {
			notification.error({ message: this.props.t("tickets.related.could-not-link-self") })
			return false;
		}
		return true;
	}

	linkParent = (id: number) => {
		console.log(id)
		if (this.notficationIfIdIsTheSame(id, this.props.ticketId)) {
			this.props.apiServer.apiTickets.postRelatedTicket(this.props.ticketId, "parent", id).then(x => {
				if (x.data.data?.succeed) {
					this.componentDidMount()
					notification.info({ message: this.props.t("tickets.related.link-succeed") })
				} else {
					notification.warn({ message: this.props.t("tickets.related.link-fail") })
				}
			})
		}

	}

	linkChildren = (id: number) => {
		if (this.notficationIfIdIsTheSame(id, this.props.ticketId)) {
			this.props.apiServer.apiTickets.postRelatedTicket(this.props.ticketId, "children", id).then(x => {
				if (x.data.data?.succeed) {
					this.componentDidMount()
					notification.info({ message: this.props.t("tickets.related.link-succeed") })
				} else {
					notification.warn({ message: this.props.t("tickets.related.link-fail") })
				}
			})
		}
	}
	link = (id: number) => {
		if (this.notficationIfIdIsTheSame(id, this.props.ticketId)) {
			this.props.apiServer.apiTickets.postRelatedTicket(this.props.ticketId, "link", id).then(x => {
				if (x.data.data?.succeed) {
					this.componentDidMount()
					notification.info({ message: this.props.t("tickets.related.link-succeed") })
				} else {
					notification.warn({ message: this.props.t("tickets.related.link-fail") })
				}
			})
		}
	}

	unlink = (ids: number[], unlinkType: string) => {
		this.props.apiServer.apiTickets.deleteRelatedTicket(this.props.ticketId, unlinkType, {ids: ids})
			.then(x => {
				if (x.data.data?.success) {
					this.componentDidMount()
					notification.info({ message: this.props.t("tickets.related.unlink-succeed") })
				} else {
					notification.warn({ message: this.props.t("tickets.related.unlink-fail") })
				}
			})
	}


	render(): React.ReactNode {
		return (<div className="related-ticket-container">
			<div className="parent">
				<TicketRelated
					title={this.props.t("tickets.related.parent-title")}
					button={this.props.t("tickets.related.parent-button-link")}
					dataLoading={this.state.dataloading}
					tickets={this.state.parent}
					ondetail={this.routeToTicket}
					link={this.linkParent}
					search={this.search}
					unlink={(ids: number[]) => this.unlink(ids, "parent")}
				/>

			</div>
			<div className="children">
				<TicketRelated
					title={this.props.t("tickets.related.children-title")}
					button={this.props.t("tickets.related.children-button-link")}
					dataLoading={this.state.dataloading}
					tickets={this.state.children}
					ondetail={this.routeToTicket}
					link={this.linkChildren}
					search={this.search}
					unlink={(ids: number[]) => this.unlink(ids, "children")}
				/>
			</div>
			<div className="link">
				<TicketRelated
					title={this.props.t("tickets.related.link-title")}
					button={this.props.t("tickets.related.link-button-link")}
					dataLoading={this.state.dataloading}
					tickets={this.state.link}
					ondetail={this.routeToTicket}
					link={this.link}
					search={this.search}
					unlink={(ids: number[]) => this.unlink(ids, "link")}
				/>
			</div>
		</div>
		)
	}
}


export default withRouter(withTranslation()(RelatedTickets));