import React from "react";
import {
	Location,
	NavigateFunction,
	Params,
	useLocation,
	useNavigate,
	useParams,
  } from "react-router-dom";

  export interface RouteContext {
	  location: Location,
	  navigate: NavigateFunction,
	  params: Readonly<Params<string>>
  }

  export interface WithRouteContext {
	router?:RouteContext
  }


export function withRoute<C extends React.ComponentType<React.ComponentProps<any>>>(Component:C)  :
  		React.ComponentType<React.ComponentProps<any & RouteContext>>{
	function ComponentWithRouterProp(props:any) {
	 let routeContext:RouteContext ={
		location : useLocation(),
		navigate : useNavigate(),
		params : useParams()
	 }
	  return (
		<Component
		  {...props}
		  router= {routeContext}
		/>
	  );
	}

	return ComponentWithRouterProp;
  }


  
  function withRouter(Component:any) {
	function ComponentWithRouterProp(props:any) {
	 let routeContext:RouteContext ={
		location : useLocation(),
		navigate : useNavigate(),
		params : useParams()
	 }
	  return (
		<Component
		  {...props}
		  router= {routeContext}
		/>
	  );
	}
  
	return ComponentWithRouterProp;
  }

  export default withRoute;