import { ColumnType } from "antd/lib/table";
import moment from "moment";
import React from "react";
import {ActionEventHandler} from "../../../../../../components";

export interface ApiAccessKeyModel {
	key: number,
	userId: number,
	accessKey: string,
	accessSecretPub: string,
	asymmetricMethod: string,
	createdTime: string,
	updatedTime: string,
	createdBy:number,
	updatedBy:number
}



export const columnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<ApiAccessKeyModel>): ColumnType<ApiAccessKeyModel>[] => {
	return [
		{
			title: translater("api-access-key.access-key"),
			dataIndex: 'accessKey',
			key: 'accessKey',
		},
		// {
		// 	title: translater("api-access-key.access-secret-pub"),
		// 	dataIndex: 'accessSecretPub',
		// 	key: 'accessSecretPub',
		// },
		{
			title: translater("api-access-key.asymmetric-method"),
			dataIndex: 'asymmetricMethod',
			key: 'asymmetricMethod',
		},
		{
			title: translater("api-access-key.createdTime"),
			dataIndex: 'createdTime',
			key: 'createdTime',
			render:(value:any,record:ApiAccessKeyModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
		}
	]
}