import React from "react";
import './index.less';

export interface PluginLandingPageModel {

}



class PluginLandingPage extends React.Component<PluginLandingPageModel,{}> {
	render(): React.ReactNode {
		return(<div>Plugin Landing Page <span className="fi fi-gr"></span> <span className="fi fi-gr fis"></span></div>)
	}
}

export default PluginLandingPage;