import { PageHeader } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import './index.less'

interface SystemLandingPageMode extends WithTranslation {

}


class SystemLandingPage extends React.Component<SystemLandingPageMode,{}> {

	render(): React.ReactNode {
		return (<PageHeader title={this.props.t("system")}>

		</PageHeader>)
	}
}


export default withTranslation()(SystemLandingPage);