import { Button } from "antd"
import { ColumnType } from "antd/lib/table"
import moment from "moment"
import { ActionEventHandler } from "../../../../../components"
import ColumnTextFilter from "../../../../../components/table/column-text-filter"
import ActiveTag from "./active-tag"


export interface UserModel {
	key:string,
	name: string,
	fullName: string,
	source: string
	organization: string,
	phone: string,
	email: string
	active: boolean,
	created: string,
	updated: string
}




export const columnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<UserModel>): ColumnType<UserModel>[] => {
	
	return [
		{
			title: translater("user-editor.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string,record:UserModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.name")}/>)
			},
			
		},
		{
			title: translater("user-editor.fullName"),
			dataIndex: 'fullName',
			key: 'fullName',
			render: (text: string,record:UserModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.fullName.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.fullName")}/>)
			},

		},
		{
			title: translater("user-editor.source"),
			dataIndex: 'source',
			key: 'source',
			render:(value:any,record:UserModel,index:number)=>{
				return translater("from-server.userSource."+record.source)
			}
		},
		{
			title: translater("user-editor.organization"),
			dataIndex: 'organization',
			key: 'organization',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.organization.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.organization")}/>)
			},
		},
		{
			title: translater("user-editor.phone"),
			dataIndex: 'phone',
			key: 'phone',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.phone.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.phone")}/>)
			},
		},
		{
			title: translater("user-editor.email"),
			dataIndex: 'email',
			key: 'email',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserModel) => record.email.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.email")}/>)
			},
		},
		{
			title: translater("user-editor.active"),
			dataIndex: 'active',
			key: 'active',
			render: (a: any) => (<ActiveTag isActive={a}></ActiveTag>)
		},{
			title: translater("user-editor.created"),
			dataIndex: 'created',
			key: 'created',
			render:(value:any,record:UserModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
		},{
			title: translater("user-editor.updated"),
			dataIndex: 'updated',
			key: 'updated',
			render:(value:any,record:UserModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
		},
	];
}