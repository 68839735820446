import { Drawer, PageHeader } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { ActionEventHandler } from "../../../components";
import WorkBenchTable from "../../../components/table/workbench-table";
import { RouteContext, withRoute, WithRouteContext } from "../../../utils";
import { PluginModel, columnsProvider } from "./@mod";
import './index.less'
import PluginConfig from "./plugin-config";

export interface PluginListViewModel extends WithTranslation,WithRouteContext {
	apiServer: ApiServer
}

interface PluginViewModelState {
	plugins: PluginModel[],
	dataLoading: boolean,
	showConfigureView:boolean,
	pluginModel?: PluginModel

}


class PluginListView extends React.Component<PluginListViewModel, PluginViewModelState>{

	constructor(props: PluginListViewModel) {
		super(props)
		this.state = {
			plugins: [],
			dataLoading: true,
			showConfigureView:false
		}
	}

	loadData = async () => {
		this.props.apiServer.apiPlugin.getPluginList()
		.then(x=>{
			if(x.data.data?.succeed){
			 let plugins =	x.data.data.list?.map(
					y=>{
						let p: PluginModel ={
							name: y.name??"",
							description :y.description??"",
							id:y.id??0,
							schema:y.schema??"",
							version:y.version??"",
							key:y.id
						}
						return p;
					}
				)??[]
				this.setState({
					plugins:plugins
				})
			}
		})


	}
	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => { 
					this.l = undefined 
					this.setState({
						dataLoading:false
					})
				});
		}
	}

	onPluginClicked=(m:PluginModel)=>{
		this.setState({
			showConfigureView:true,
			pluginModel:m
		})
	}


	getfooter = () => {
		return (<WorkBenchTable<PluginModel>
			data={this.state.plugins}
			dataLoading={this.state.dataLoading}
			onDetail={this.onPluginClicked}
			columnProvider={(a: ActionEventHandler<PluginModel>) => columnsProvider(this.props.t, a)}

		/>)
	}


	render(): React.ReactNode {
		return (<div className="installed-plugin-container">
			<PageHeader
				title={this.props.t("plugins.installed-plugin")}
				subTitle={this.props.t("plugins.installed-plugin-description")}
				footer={this.getfooter()}
			>
			</PageHeader>
			<Drawer title={this.props.t("plugins.configure")}
				visible={this.state.showConfigureView}
				destroyOnClose
				onClose={()=>this.setState({showConfigureView:false})}
			>
				<PluginConfig
					apiServer={this.props.apiServer}
					pluginModel={this.state.pluginModel}
				></PluginConfig>
			</Drawer>


		</div>)
	}
}


export default withRoute(withTranslation()(PluginListView));