import {
	CodeSandboxOutlined,
	NotificationOutlined,
	MailOutlined,
	MessageOutlined,
	TeamOutlined,
	UserOutlined,
	ApartmentOutlined,
	SecurityScanOutlined,
	CheckCircleOutlined,
	LockOutlined,
	RiseOutlined,
	CopyOutlined,
	FileOutlined,
	SettingOutlined,
	TagOutlined,
	UnorderedListOutlined,
	ApiOutlined,
	InteractionOutlined,
	SearchOutlined,
	FolderOpenOutlined,
	ShoppingCartOutlined,
	ProjectOutlined,
	ExportOutlined,
	LineChartOutlined,
	CaretUpOutlined,
	BuildOutlined,
	TwitterOutlined,
	WechatOutlined,
	BorderlessTableOutlined,
	UserSwitchOutlined,
	CustomerServiceOutlined,
	ReadOutlined,
	FundViewOutlined,
	HeartOutlined,
	HistoryOutlined,
	LikeOutlined,
	RocketOutlined,
	ReloadOutlined,
	HourglassOutlined,
	LoginOutlined,
	LogoutOutlined,
	LinkOutlined,
	DeleteOutlined,
	StopOutlined,
	FireOutlined,
	UsergroupAddOutlined,
	BookOutlined,
	FormOutlined,
	SwapOutlined,
	CodeOutlined,
	ClearOutlined,
	PhoneOutlined,
	AlignCenterOutlined

} from '@ant-design/icons'

import { ExtendsIcons } from './ex-icon';
import React, { Children } from 'react'

export const menuEnt = (translator: (a: string) => string): any => {

	return {
		"system": [{
			key: "/system/landing",
			label: translator("landing"),
			icon: React.createElement(CodeSandboxOutlined)
		}, {
			key: "/system/notification",
			label: translator("notification"),
			icon: React.createElement(NotificationOutlined),
			children: [
				{
					key: "/system/notification/feishu",
					label: translator("feishu"),
					icon: React.createElement(TwitterOutlined)
				},
				{
					key: "/system/notification/cropwechat",
					label: translator("cropwechat"),
					icon: React.createElement(WechatOutlined)
				},

				{
					key: "/system/notification/voice",
					label: translator("voice"),
					icon: React.createElement(PhoneOutlined)
				}
			]
		}, {
			key: "/system/productivity",
			label: translator("productivity"),
			icon: React.createElement(RiseOutlined),
			children: [
				{
					key: "/system/productivity/templates",
					label: translator("templates"),
					icon: React.createElement(CopyOutlined)
				},
				{
					key: "/system/productivity/automation",
					label: translator("automation"),
					icon: React.createElement(InteractionOutlined)
				},
				{
					key: "/system/productivity/tags",
					label: translator("tags"),
					icon: React.createElement(TagOutlined)
				},
				{
					key: "/system/productivity/ticket",
					label: translator("ticket-meta"),
					icon: React.createElement(FileOutlined)
				},
				{
					key: "/system/productivity/ticket-new-template",
					label: translator("ticket-new-template"),
					icon:React.createElement(AlignCenterOutlined)

				}
			]
		}, {
			key: "/system/performance",
			label: translator("performance"),
			icon: React.createElement(LineChartOutlined),
			children: [{
				key: "/system/performance/escalation",
				label: translator("escalation"),
				icon: React.createElement(CaretUpOutlined)
			}]
		}, {
			key: "/system/settings",
			label: translator("settings"),
			icon: React.createElement(SettingOutlined),

		}, {
			key: "/system/operation",
			label: translator("operation"),
			icon: React.createElement(CodeOutlined),
			children: [{
				key: "/system/operation/reload-reflex-system",
				label: translator("reload-reflex-system"),
				icon: React.createElement(ReloadOutlined)
			},{
				key: "/system/operation/clear-abnormal-mailbox",
				label: translator("clear-abnormal-mailbox"),
				icon: React.createElement(ClearOutlined)
			}]
		}
		],
		"access-control": [{
			key: "/access-control/landing",
			label: translator("landing"),
			icon: React.createElement(CodeSandboxOutlined),
		}, {
			key: "/access-control/members",
			label: translator("members"),
			icon: React.createElement(TeamOutlined),
			children: [{
				key: "/access-control/members/users",
				label: translator("users"),
				icon: React.createElement(UserOutlined),
			}, {
				key: "/access-control/members/user-group",
				label: translator("user-group"),
				icon: React.createElement(ApartmentOutlined),
			}]
		}, {
			key: "/access-control/security",
			label: translator("security"),
			icon: React.createElement(SecurityScanOutlined),
			children: [{
				key: "/access-control/security/privilege",
				label: translator("privilege"),
				icon: React.createElement(LockOutlined),
			}, {
				key: "/access-control/security/policy",
				label: translator("policy"),
				icon: React.createElement(CheckCircleOutlined),
			}]
		}],
		"plugin": [{
			key: "/plugin/landing",
			label: translator("landing"),
			icon: React.createElement(CodeSandboxOutlined),

		},
		{
			key: "/plugin/installed",
			label: translator("plugin-installed"),
			icon: React.createElement(ApiOutlined)
		}
		],
		"ticket": [{
			key: "/ticket/landing",
			label: translator("landing"),
			icon: React.createElement(CodeSandboxOutlined),
			adminrequired: false
		}, {
			key: "/ticket/my-ticket",
			label: translator("my-ticket"),
			icon: React.createElement(UnorderedListOutlined),
			adminrequired: false
		}, {
			key: "/ticket/search",
			label: translator("ticket-search"),
			icon: React.createElement(SearchOutlined)
		}, {
			key: "/ticket/my-related-ticket",
			label: translator("my-related-ticket"),
			icon: React.createElement(FolderOpenOutlined),
			adminrequired: false
		}, {
			key: "/ticket/export",
			label: translator("export"),
			icon: React.createElement(ExportOutlined),
			adminrequired: false
		}
		],
		"service": [{
			key: "/service/landing",
			label: translator("project.menus.landing"),
			icon: React.createElement(CodeSandboxOutlined),
			adminrequired: false
		}, {
			key: "/service/market",
			label: translator("project.menus.market"),
			icon: React.createElement(ShoppingCartOutlined),
			adminrequired: false
		}, {
			key: "/service/subscribe",
			label: translator("project.menus.my-subscribed"),
			icon: React.createElement(FireOutlined),
			adminrequired: false,
			children: [{
				key: "/service/subscribed",
				label: translator("project.menus.subscribed-services"),
				icon: React.createElement(HeartOutlined),
				adminrequired: false,
			}, {
				key: "/service/accept",
				label: translator("project.menus.acceptance-services"),
				icon: React.createElement(LikeOutlined),
				adminrequired: false,
			}]

		}, {
			key: "/service/management",
			label: translator("project.menus.my-service"),
			icon: React.createElement(CustomerServiceOutlined),
			adminrequired: false,
			children: [{
				key: "/service/launched",
				label: translator("project.menus.launched-services"),
				icon: React.createElement(HistoryOutlined),
				adminrequired: false,
			}, {
				key: "/service/eng",
				label: translator("project.menus.participate-services"),
				icon: React.createElement(TeamOutlined),
				adminrequired: false,
			}]
		}, {
			key: "/project/management",
			label: translator("project.menus.my-project"),
			icon: React.createElement(ProjectOutlined),
			adminrequired: false,
			children: [
				{
					key: "/service/approve",
					label: translator("project.menus.approval-services"),
					icon: React.createElement(ReloadOutlined),
					adminrequired: false,
				}, {
					key: "/service/start",
					label: translator("project.menus.start-services"),
					icon: React.createElement(RocketOutlined),
					adminrequired: false,
				}, {
					key: "/service/processing",
					label: translator("project.menus.processing-services"),
					icon: React.createElement(HourglassOutlined),
					adminrequired: false,
				}, {
					key: "/service/statistics-project",
					label: translator("project.menus.project-statistic"),
					icon: React.createElement(FundViewOutlined),
					adminrequired: false,
				}, {
					key: "/service/templates-project",
					label: translator("project.menus.project-template"),
					icon: React.createElement(ReadOutlined),
					adminrequired: false,
				}
			]

		}],
		"mail": [
			{
				key: "/mail/landing",
				label: translator("landing"),
				icon: React.createElement(CodeSandboxOutlined)
			}, {
				key: "/mail/mail-vendor",
				label: translator("mails.mail-vendor"),
				icon: <ExtendsIcons type='icon-gongyingshang' />
			}, {
				key: "mailboxes",
				label: translator("mails.mail-box"),
				icon: <MailOutlined />,
				adminrequired: false,
				children: [
					{
						key: "/mail/mail-box/my-boxes",
						label: translator('mails.my-boxes'),
						icon: <ExtendsIcons type='icon-24gl-mailboxFull' />,
						adminrequired: false,
					},
					{
						key: "/mail/mail-box/mail-approval",
						label: translator('mails.mail-approval'),

						icon: <ExtendsIcons type='icon-approval1' />
					}
				]
			}, {
				key: "mail-management",
				label: translator("mails.mail-management"),
				icon: <ExtendsIcons type='icon-youxiangpeizhi' />,
				children: [
					{
						key: "/mail/mail-management/access-control",
						label: translator('mails.access-control.desc'),
						icon: <ExtendsIcons type='icon-yijianshouquan' />
					},
					{
						key: "/mail/mail-management/mailbox-configure",
						label: translator('mails.mailbox-configure'),
						icon: <SettingOutlined />
					}
				]
			}, {
				key: "mail-contact",
				label: translator("mails.mail-contact.desc"),
				adminrequired: true,
				icon: <UserOutlined />,
				children: [
					{
						key: "/mail/mail-contact/mail-contact-management",
						label: translator("mails.mail-contact.management"),
						adminrequired: true,
						icon: <UsergroupAddOutlined />
					},
					{
						key: "/mail/mail-contact/mailbox-binding",
						label: translator("mails.mail-contact.mailbox-binding"),
						adminrequired: true,
						icon: <UserSwitchOutlined />
					}
				]
			}, {
				key: "mail-template",
				label: translator("mails.mail-template.desc"),
				icon: <BookOutlined />,
				adminrequired: false,
				children: [
					{
						key: "/mail/mail-template/mail-template-management",
						label: translator("mails.mail-template.management"),
						adminrequired: true,
						icon: <FormOutlined />
					},
					{
						key: "/mail/mail-template/mail-template-binding",
						label: translator("mails.mail-template.binding"),
						icon: <SwapOutlined />,
						adminrequired: false,
					}
				]
			}
		],

		"cluster": [
			{
				key: "/cluster/landing",
				label: translator("landing"),
				icon: React.createElement(CodeSandboxOutlined)
			},
			{
				key: "/cluster/instances",
				label: translator("instances-management"),
				icon: <ExtendsIcons type="icon-bianyuanjisuanjiqi" />,
				children: [
					{
						key: "/cluster/enroll",
						label: translator("enrollment"),
						icon: React.createElement(LoginOutlined),
					},
					{
						key: "/cluster/enrolled",
						label: translator("enrolled-instances"),
						icon: React.createElement(LinkOutlined),
					}, {
						key: "/cluster/blocked",
						label: translator("blocked"),
						icon: React.createElement(StopOutlined),
					}, {
						key: "/cluster/recycle-bin",
						label: translator("recycle-bin"),
						icon: React.createElement(DeleteOutlined),
					}
				]
			},
			{
				key: "/cluster/tasks",
				label: translator("tasks-management"),
				icon: <ExtendsIcons type='icon-renwu1' />,

			}
		],
		"statistics": [
			{
				key: "/statistics/landing",
				label: translator("landing"),
				icon: React.createElement(CodeSandboxOutlined)
			}, {
				key: "/statistics/tickets",
				label: translator("tickets-statistics"),
				icon: React.createElement(BorderlessTableOutlined),
				children: [
					{
						key: "/statistics/tickets-statistics/overview",
						label: translator("statistics-tickets.overview-menu"),
						icon: React.createElement(ProjectOutlined),
						adminrequired: false,
					}, {
						key: "/statistics/tickets-statistics/participator",
						label: translator("statistics-tickets.participator-menu"),
						icon: React.createElement(UserSwitchOutlined),
						adminrequired: false,
					},
				]
			}
		]
	};
}
