import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import NotificationSetting from "../component/notification-setting";
import ApiServer from "../../../../ApiServer";



export interface EmailConfigurePageModel extends WithTranslation {
	apiServer: ApiServer
}
interface EmailConfigurePageState{

}

class EmailConfigurePage extends React.Component<EmailConfigurePageModel,EmailConfigurePageState>{

	render(): React.ReactNode {
		return (
			<NotificationSetting
				channelType={""}
				channelConfigurationTitle={"电子邮件参数配置"}
				messageTemplateContainerTitle={"电子邮件消息模板"}
				apiServer={this.props.apiServer}
			/>
		);
	}
}

export default withTranslation()(EmailConfigurePage);