import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import DynamicEditor from "../../../../components/dynamic-editor";
import { PluginConfigureModel, PluginModel } from "../@mod";
import './index.less'

export interface PluginConfigureViewModel extends WithTranslation {
	apiServer: ApiServer,
	pluginModel?: PluginModel
}


interface PluginConfigureViewState {
	config: PluginConfigureModel
	dataLoading: boolean
	schema: string
}

class PluginConfigureView extends React.Component<PluginConfigureViewModel, PluginConfigureViewState>{

	constructor(props: PluginConfigureViewModel) {
		super(props)
		this.state = {
			config: {
				id: 0,
				config: "{}",
				enabled: false,
				runAs: "undefined"
			},
			dataLoading: true,
			schema:"{\"type\":\"object\"}"

		}
	}

	loadData = async () => {
		if (this.props.pluginModel) {
			this.props.apiServer.apiPlugin.getPluginById(
				this.props.pluginModel.id,
				true
			).then(x => {
				let dto = x.data.data?.pluginConfigure;
				let conf: PluginConfigureModel = {
					id: dto?.id ?? 0,
					config: dto?.config ?? "{}",
					enabled: dto?.enabled ?? false,
					runAs: dto?.runAs?.name ?? ""
				}
				this.setState({
					config: conf,
					schema: x.data.data?.plugin?.schema ?? ""
				})
			})
		}
	}

	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => {
					this.l = undefined;
					this.setState({
						dataLoading: false
					})

				})
		}

	}

	getSchema = () => {
		try {
			return JSON.parse(this.state.schema);
		} catch {
			return {"type":"object"}
		}
	}

	getConfig = ()=>{
		try{
			return JSON.parse(this.state.config.config)
		}catch{
			return {}
		}
	}


	render(): React.ReactNode {
		return (
			<div className="plugin-config-view">
				<div className="plugin-info">
					<div className="item">
						<div className="label">
							{this.props.t("plugins.name")}
						</div>
						<div className="value">
							{this.props.pluginModel?.name}
						</div>
					</div>
					<div className="item">
						<div className="label">
							{this.props.t("plugins.description")}
						</div>
						<div className="value">
							{this.props.pluginModel?.description}
						</div>
					</div>
					<div className="item">
						<div className="label">
							{this.props.t("plugins.version")}
						</div>
						<div className="value">
							{this.props.pluginModel?.version}
						</div>
					</div>
					<div className="item">
						<div className="label">
							{this.props.t("plugins.runAs")}
						</div>
						<div className="value">
							{this.state.config.runAs}
						</div>
					</div>
					{
						this.state.config.id !== 0 ||
						<div className="item">
							<div className="label">
								{this.props.t("plugins.configure")}
							</div>
							<div className="value red">
								{this.props.t("plugins.no-configure")}
							</div>
						</div>

					}
				</div>
				<div className="config-info">
					{
						this.state.config.id === 0 ||
						<DynamicEditor
							widthPercent={100}
							schema={this.getSchema()}
							uiSchema={{}}
							content={this.getConfig()} 
							isReadOnly={false}
							/>
					}

				</div>

			</div>)
	}
}

export default withTranslation()(PluginConfigureView);