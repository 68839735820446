import "./layout"
import './App.less';
import Layout from "./layout";
import {HashRouter, Route, Routes} from "react-router-dom";
import ManagementBody from './layout/content'
import System from "./pages/system";
import AccessControlLandingPage from "./pages/access";
import PluginLandingPage from "./pages/plugin";
import SMSConfigurePage from "./pages/system/notification/sms";
import EmailConfigurePage from "./pages/system/notification/email";
import VoiceConfigurePage from "./pages/system/notification/voice";
import ContentSchemasPage from "./pages/system/productivity/content-schemas"
import TagsPage from "./pages/system/productivity/tags";
import TicketPage from "./pages/system/productivity/ticket";
import SettingsPage from "./pages/system/settings";
import UsersPage from "./pages/access/members/users";
import UserGroupsPage from "./pages/access/members/usergroups";
import PrivilegePage from "./pages/access/sercurity/privilege";
import PolicyPage from "./pages/access/sercurity/policy";
import TicketLandingPage from "./pages/ticket";
import MyTicket from "./pages/ticket/my-ticket";
import TeamTicket from "./pages/ticket/team-ticket";
import TicketDetail from "./pages/ticket/ticket-detail";
import ApiServer from "./ApiServer";
import UserGroupEditor from "./pages/access/members/user-group-editor";
import ContentSchemaEditor from "./pages/system/productivity/content-schema-editor";
import PluginList from "./pages/plugin/plugin-list";
import Automation from "./pages/system/productivity/automation";
import ProcessEditor from "./pages/system/productivity/automation/process-editor";
import TicketSearch from "./pages/ticket/ticket-search";
import MyRelatedTickets from "./pages/ticket/my-related-tickets";
import React from "react";
import Service from "./pages/project";
import Exports from "./pages/ticket/exports";
import Escalation from "./pages/system/performance/escalation";
import NewTicketTemplate from "./pages/system/productivity/new-ticket-template";
import FeishuConfigurePage from "./pages/system/notification/feishu";
import CropWechatConfigurePage from "./pages/system/notification/crop-weichat";
import StatisticsLandingPage from "./pages/statistics"
import TicketsStatisticsOverview from './pages/statistics/tickets/overview';
import Participated from "./pages/statistics/tickets/participated";
import ServiceSubscribedView from "./pages/project/service-subscribed";
import ServiceApproveView from "./pages/project/service-approve";
import ServiceEngView from "./pages/project/service-eng";
import ServiceLaunchedView from "./pages/project/service-launched";
import ServiceAcceptView from "./pages/project/service-accept";
import ProjectTemplateView from "./pages/project/project-template";
import ProjectStatisticsView from "./pages/project/project-statistics";
import ServiceMarketWaterfall from "./pages/project/market";
import ServiceStartView from "./pages/project/service-start";
import ServiceProcessingView from "./pages/project/service-processing";
import MailLandingView from "./pages/mail";
import ClusterLandingView from "./pages/cluster";
import EnrollView from "./pages/cluster/enroll";
import EnrolledView from "./pages/cluster/enrolled"
import BlockedView from "./pages/cluster/blocked";
import RecycleBinView from "./pages/cluster/recycle-bin";
import VendorView from "./pages/mail/vendor";
import MyMailBoxView from './pages/mail/box/myboxes';
import MailConfigureView from './pages/mail/config'
import MailAccessControl from './pages/mail/access'
import TicketCard from "./pages/ticket/ticket-card";
import MailContactManagerView from "./pages/mail/contact/manager"
import MailContactBindingView from "./pages/mail/contact/binding"
import MailTemplateManagerView from "./pages/mail/template/manager"
import MailTemplateBindingView from "./pages/mail/template/binding"
import ClearAbnormalMailbox from "./pages/system/operation/clear-abnormal-mailbox"
import ReloadReflexSystem from "./pages/system/operation/reload-reflex-system";
import MailApproval from "./pages/mail/approval";

export interface AppModel {
  apiServer: ApiServer
}

interface AppState {
  isAdmin: boolean
}


class App extends React.Component<AppModel, AppState> {

  constructor(props: AppModel) {
    super(props)
    this.state = {
      isAdmin: false
    }
    console.log("app created");
  }

  getUserPage = () => {
    return (<UsersPage apiServer={this.props.apiServer} />);
  }

  componentDidMount() {
    this.props.apiServer.apiSystem.getLoginUser()
      .then(x => {
        if (x.data.data?.admin) {
          this.setState({
            isAdmin: true
          })
        }
      })
  }

  render(): React.ReactNode {

    let apiServer = this.props.apiServer
    return (
      <div className="App">
        <HashRouter>
          <Routes>
            <Route path="/" element={<Layout apiServer={apiServer} copyright="xxx" />}>
              <Route path="system" element={<ManagementBody menuKey="system" isAdmin={this.state.isAdmin} />}>
                <Route path="landing" element={<System />}></Route>
                <Route path="notification">
                  <Route path="sms" element={<SMSConfigurePage apiServer={apiServer} />}></Route>
                  <Route path="email" element={<EmailConfigurePage apiServer={apiServer} />}></Route>
                  <Route path="cropwechat" element={<CropWechatConfigurePage apiServer={apiServer} />}></Route>
                  <Route path="feishu" element={<FeishuConfigurePage apiServer={apiServer} />}></Route>
                  <Route path="voice" element={<VoiceConfigurePage apiServer={apiServer} />}></Route>
                </Route>
                <Route path="performance">
                  <Route path="escalation" element={<Escalation apiServer={apiServer} />} ></Route>
                </Route>
                <Route path="productivity">
                  <Route path="template-editor/:id" element={<ContentSchemaEditor apiServer={apiServer} />}></Route>
                  <Route path="templates" element={<ContentSchemasPage apiServer={apiServer} />} ></Route>
                  <Route path="automation" element={<Automation apiServer={apiServer} />} ></Route>
                  <Route path=":id/process" element={<ProcessEditor apiServer={apiServer} />}></Route>
                  <Route path="tags" element={<TagsPage apiServer={apiServer} />} ></Route>
                  <Route path="ticket" element={<TicketPage apiServer={apiServer} />} ></Route>
                  <Route path="ticket-new-template" element={<NewTicketTemplate apiServer={apiServer} />}></Route>
                </Route>
                <Route path="settings" element={<SettingsPage apiServer={apiServer} />} />
                <Route path="operation">
                  <Route path="reload-reflex-system" element={<ReloadReflexSystem apiServer={apiServer} />} />
                  <Route path="clear-abnormal-mailbox" element={<ClearAbnormalMailbox apiServer={apiServer} />} />
                </Route>
              </Route>
              <Route path="access-control" element={<ManagementBody menuKey="access-control" isAdmin={this.state.isAdmin} />}  >
                <Route path="landing" element={<AccessControlLandingPage />}></Route>
                <Route path="members">
                  <Route path="users" element={this.getUserPage()} />
                  <Route path="user-group" element={<UserGroupsPage apiServer={apiServer} />}></Route>
                  <Route path="user-group/:id" element={<UserGroupEditor apiServer={apiServer} />}></Route>
                </Route>
                <Route path="security">
                  <Route path="privilege" element={<PrivilegePage apiServer={apiServer} />} ></Route>
                  <Route path="policy" element={<PolicyPage apiServer={apiServer} />}></Route>
                </Route>
              </Route>
              <Route path="plugin" element={<ManagementBody menuKey="plugin" isAdmin={this.state.isAdmin} />}>
                <Route path="landing" element={<PluginLandingPage />} ></Route>
                <Route path="installed" element={<PluginList apiServer={apiServer} />}></Route>
              </Route>
              <Route path="ticket" element={<ManagementBody menuKey="ticket" isAdmin={this.state.isAdmin} />}>
                <Route path="landing" element={<TicketLandingPage apiServer={apiServer} />} ></Route>
                <Route path="my-ticket" element={<MyTicket apiServer={apiServer}></MyTicket>}></Route>
                <Route path=":id/detail" element={<TicketDetail apiServer={apiServer} />}></Route>
                <Route path="team-ticket" element={<TeamTicket apiServer={apiServer} />}></Route>
                <Route path="search" element={<TicketSearch apiServer={apiServer} />}></Route>
                <Route path="my-related-ticket" element={<MyRelatedTickets apiServer={apiServer} />}></Route>
                <Route path="export" element={<Exports apiServer={apiServer} ></Exports>}></Route>
                <Route path="ticket-card" element={<TicketCard apiServer={apiServer} ticketNumber={1510} />} />
              </Route>
              <Route path="service" element={<ManagementBody menuKey="service" isAdmin={this.state.isAdmin} />}>
                <Route path="landing" element={<Service apiServer={apiServer} />} ></Route>
                <Route path="market" element={<ServiceMarketWaterfall apiServer={apiServer} />} />
                <Route path="subscribed" element={<ServiceSubscribedView apiServer={apiServer}/>}/>
                <Route path="approve" element={<ServiceApproveView apiServer={apiServer}/>}/>
                <Route path="eng" element={<ServiceEngView apiServer={apiServer}/>}/>
                <Route path="launched" element={<ServiceLaunchedView apiServer={apiServer}/>}/>
                <Route path="accept" element={<ServiceAcceptView apiServer={apiServer}/>}/>
                <Route path="start" element={<ServiceStartView apiServer={apiServer}/>}/>
                <Route path="processing" element={<ServiceProcessingView apiServer={apiServer}/>}/>
                <Route path="templates-project" element={<ProjectTemplateView apiServer={apiServer}/>}/>
                <Route path="statistics-project" element={<ProjectStatisticsView apiServer={apiServer}/>}/>
              </Route>
              <Route path="mail" element={<ManagementBody menuKey="mail" isAdmin={this.state.isAdmin}/>}>
                <Route path="landing" element={<MailLandingView></MailLandingView>}></Route>
                <Route path="mail-vendor" element={<VendorView apiServer={apiServer}/>}></Route>
                <Route path="mail-box/my-boxes" element={<MyMailBoxView apiServer={apiServer}></MyMailBoxView>}></Route>
                <Route path="mail-management/mailbox-configure"
                       element={<MailConfigureView apiServer={apiServer}/>}></Route>
                <Route path="mail-management/access-control"
                       element={<MailAccessControl apiServer={apiServer}/>}></Route>
                <Route path="mail-contact/mail-contact-management"
                       element={<MailContactManagerView apiServer={apiServer}/>}></Route>
                <Route path="mail-contact/mailbox-binding"
                       element={<MailContactBindingView apiServer={apiServer}/>}></Route>
                <Route path="mail-template/mail-template-management"
                       element={<MailTemplateManagerView apiServer={apiServer}/>}></Route>
                <Route path="mail-template/mail-template-binding"
                       element={<MailTemplateBindingView apiServer={apiServer}/>}></Route>
                <Route path="mail-box/mail-approval" element={<MailApproval apiServer={apiServer}/>}></Route>
              </Route>

              <Route path="cluster" element={<ManagementBody menuKey="cluster" isAdmin={this.state.isAdmin} />}>
                <Route path="landing" element={<ClusterLandingView></ClusterLandingView>} ></Route>
                <Route path="enroll" element={<EnrollView apiServer={apiServer} />} ></Route>
                <Route path="enrolled" element={<EnrolledView apiServer={apiServer} />} ></Route>
                <Route path="blocked" element={<BlockedView apiServer={apiServer} />} ></Route>
                <Route path="recycle-bin" element={<RecycleBinView apiServer={apiServer} />} ></Route>
              </Route>

              <Route path="statistics" element={<ManagementBody menuKey="statistics" isAdmin={this.state.isAdmin} />}>
                <Route path="landing" element={<StatisticsLandingPage apiServer={apiServer} />} ></Route>
                <Route path="tickets-statistics/overview" element={<TicketsStatisticsOverview apiServer={apiServer} />}></Route>
                <Route path="tickets-statistics/participator" element={<Participated apiServer={apiServer} />} ></Route>
              </Route>

            </Route>
          </Routes>
        </HashRouter>
      </div>
    );
  }

}

export default App;
