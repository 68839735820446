import { Button, Input, notification, PageHeader, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { RouteContext, withRouter } from "../../../utils";
import {
	convertTo,
	PriorityModel,
	SourceModel,
	StatusModel,
	TagModel,
	TicketModel,
	TicketTypeModel,
	UserModel
} from "../@mod";
import TicketList from "../ticket-list";
import "./index.less"
import TicketDetail from "../ticket-detail";

var { Option } = Select

export interface TicketSearchViewMode extends WithTranslation {
	apiServer: ApiServer
	router?: RouteContext
}

interface TicketSearchState {
	title: string,
	subtitle: string,
	id?: number,
	status?: number,
	priority?: number,
	type?: number,
	source?: number,
	assigned?: number,
	tag?: number,
	tickets: TicketModel[],
	loading: boolean
	ticketTypes: TicketTypeModel[],
	ticketPriority: PriorityModel[],
	ticketStatus: StatusModel[],
	ticketSource: SourceModel[],
	assignedList: UserModel[],
	tagList: TagModel[],
	showTicketDetail: boolean,
	ticketToShow?: number,
	ticketSelected: TicketModel[],
	onEval: boolean,
	isExported:boolean
}


class TicketSearch extends React.Component<TicketSearchViewMode, TicketSearchState>{

	constructor(props: TicketSearchViewMode) {
		super(props)
		this.state = {
			title: "",
			subtitle: "",
			tickets: [],
			loading: false,
			ticketTypes: [],
			ticketPriority: [],
			ticketStatus: [],
			ticketSource: [],
			assignedList: [],
			tagList: [],
			showTicketDetail: false,
			ticketSelected: [],
			onEval: false,
			isExported:true
		}
	}


	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => { this.l = undefined })
		}

	}

	loadData = async () => {
		await this.props.apiServer.apiMeta.getTicketTypeList(false)
			.then(x => {
				let ticketTypes = x.data.data?.list?.map(y => {
					let ticketType: TicketTypeModel = {
						name: y.name ?? "",
						description: y.description,
						id: y.id ?? 0
					}
					return ticketType;
				})
				this.setState({
					ticketTypes: ticketTypes ?? []
				})
			})
		await this.props.apiServer.apiMeta.getTicketPriority(false)
			.then(
				x => {
					let priority = x.data.data?.list?.map(
						y => {
							let p: PriorityModel = {
								id: y.id ?? 0,
								name: y.name ?? "",
								description: y.lang

							}
							return p;
						}
					)
					this.setState({
						ticketPriority: priority ?? []
					})
				}
			);
		await this.props.apiServer.apiMeta.getTicketStatusList(false)
			.then(x => {
				let status = x.data.data?.list?.map(x => {
					let status: StatusModel = {
						id: x.id ?? 0,
						name: x.name ?? "",
						description: x.description
					}
					return status;
				}) ?? []
				this.setState({ ticketStatus: status })
			})

		await this.props.apiServer.apiMeta.getTicketSourceList(false)
			.then(x => {
				let sources = x.data.data?.list?.map(y => {
					let source: SourceModel = {
						id: y.id ?? 0,
						name: y.name ?? "",
						description: y.lang
					}
					return source;
				}) ?? []
				this.setState({ ticketSource: sources })
			})

		await this.props.apiServer.apiUser.getUserList(true).then(x => {
			let assignedList = x.data.data?.list?.map(y => {
				let assigned: UserModel = {
					id: y.id ?? 0,
					name: y.fullName ?? "",
					organization: y.organization ?? ""
				}
				return assigned;
			}) ?? []
			this.setState({ assignedList: assignedList })
		})

		await this.props.apiServer.apiProductivity.getTagList().then(x => {
			let tagList = x.data.data?.tagDtoList?.map(y => {
				let tag: TagModel = {
					id: y.id ?? 0,
					name: y.name ?? "",
					color: y.color ?? ""
				}
				return tag;
			}) ?? []
			this.setState({ tagList: tagList })
		})
	}

	navigateToDetail = (t: TicketModel) => {
		this.setState({
			ticketToShow: t.id,
			showTicketDetail: true
		})
	}

	search = () => {
		if (this.state.subtitle !== ""
			|| this.state.title !== ""
			|| this.state.id !== undefined
			|| this.state.source !== undefined
			|| this.state.priority !== undefined
			|| this.state.status !== undefined
			|| this.state.type !== undefined
			|| this.state.assigned !== undefined
			|| this.state.tag !== undefined
		) {
			this.setState({ loading: true });
			this.props.apiServer.apiTickets.searchTicketsByName(
				this.state.id,
				this.state.title,
				this.state.subtitle,
				this.state.status,
				this.state.priority,
				this.state.type,
				this.state.source,
				this.state.assigned,
				this.state.tag
			).then(x => {
				if (x.data.data?.recommendTickets) {
					this.setState({
						tickets: x.data.data.recommendTickets.map(convertTo)
					});
				}
			}).finally(() => {
				this.setState({
					loading: false
				})
			});
		}
	}

	keyDown = (e) => {
		if (e.code === "Enter") {
			this.search()
		}
	}

	reset = () => {
		this.setState({
			id: undefined,
			subtitle: "",
			title: "",
			source: undefined,
			priority: undefined,
			status: undefined,
			type: undefined,
			assigned: undefined,
			tag: undefined,
			ticketSelected:[]
		})
	}

	detailClickBack = () => {
		this.ticketUpdate(this.state.ticketToShow??0)
		this.setState({
			showTicketDetail: false
		})
	}

	ticketUpdate = (id:number) => {

		this.setState({
			loading:true
		})
		

		this.props.apiServer.apiTickets.getTicket(id)
		.then(x=>{
			if(x.data.data?.succeed && x.data.data.ticket)
			{
				var list = this.state.tickets;
				list = list.filter(x=>x.id!=id)
				list.push( convertTo(x.data.data?.ticket));
				this.setState({
					tickets:list
				})
			}
		}).finally(
			()=>{
				this.setState({
					loading:false
				})
		})
	}

	renderTicketDetail = () => {
		if (this.state.showTicketDetail) {
			return <div>
				<TicketDetail
					apiServer={this.props.apiServer}
					ticketId={this.state.ticketToShow}
					back={this.detailClickBack}
				>
				</TicketDetail>

			</div>
		}
		return <></>
	}

	ticketSelected = (t: TicketModel[]) => {
		this.setState({
			ticketSelected: t,
			isExported:false
		})
	}
	exportSelected = () => {
		if (this.state.ticketSelected.length > 0) {
			if (!this.state.isExported) {
				this.setState({
					isExported: true
				})
				let param: any = {
					withAttachment: false,
					ticketIds: this.state.ticketSelected.map(x => x.id)
				}
				this.props.apiServer.apiExport.postExport(
					1, {
					parameter: JSON.stringify(param)
				})
					.then(x => {
						setTimeout(() => {
							this.props.apiServer.apiExport.getExportJobDownloadUrl(
								1, x.data.data?.job?.jobId ?? ""
							).then(y => {
								if (y.data.data?.succeed) {
									var element = document.createElement('a');
									element.target = "_blank";
									element.href = y.data.data.downloadUrl ?? ""
									document.body.appendChild(element)
									element.click()
									URL.revokeObjectURL(element.href);
									document.body.removeChild(element);
								} else {
									notification.warn({ message: this.props.t("tickets.can-not-completed-in-time") })
								}
							});
						}, 3000)
					});
				notification.success({ message: this.props.t("tickets.export-job-queued") })
			} else {
				notification.warn({ message: this.props.t("tickets.do-no-duplicate-export") })
			}
		} else {
			notification.warn({ message: this.props.t("tickets.no-ticket-selected") })
		}

	}

	ticketReEval = () => {

		let ids = this.state.ticketSelected.filter(x =>
			x.type.name === "AUTOMATION")
			.map(x => x.id);

		this.setState({
			onEval: true
		})

		this.props.apiServer
			.apiTickets.postAutomaticAssigneeReEval(
				{
					ticketIds: ids
				}
			).then(y => {
				if (y.data.data?.success) {
					notification.success({
						message: this.props.t("tickets.search.you-reeval-completed")
					});
				}

			}).finally(() => {
				this.setState({
					onEval: false
				})
			})

	}

	renderTicketList = () => {
		return (
			<div style={{ display: this.state.showTicketDetail ? "none" : "block" }}>
				<PageHeader title={this.props.t("tickets.search.self")}
					subTitle={this.props.t("tickets.search.subtitle")}
					footer={(
						<Content>
							<TicketList
								tickets={this.state.tickets} dataLoading={this.state.loading}
								corestatus={{
									status: this.state.ticketStatus,
									priority: this.state.ticketPriority,
									type: this.state.ticketTypes,
									source: this.state.ticketSource
								}}
								onSelected={this.ticketSelected}
								ondetail={this.navigateToDetail}
							/>
						</Content>
					)}
				>
					<div className="ticket-search">
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.id")}
							</div>
							<Input type="number" value={this.state.id}
								allowClear={true}
								placeholder={this.props.t("tickets.search.input-id")}
								onChange={event => this.setState({ id: event.target.value === "" ? undefined : Number(event.target.value) })}
								onKeyDown={this.keyDown}
							/>
						</div>
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.title")}
							</div>
							<Input type="input" value={this.state.title}
								placeholder={this.props.t("tickets.search.input-title")}
								onChange={(v) => this.setState({ title: v.target.value })}
								onKeyDown={this.keyDown}
							/>
						</div>
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.sub-title")}
							</div>
							<Input type="input" value={this.state.subtitle}
								placeholder={this.props.t("tickets.search.input-sub-title")}
								onChange={(v) => this.setState({ subtitle: v.target.value })}
								onKeyDown={this.keyDown}
							/>
						</div>
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.source")}
							</div>
							<Select
								className={"selector"}
								allowClear={true} onChange={(e) => {
									this.setState({ source: e })
								}}
								value={this.state.source}
							>
								{this.state.ticketSource?.map(d => (
									<Option key={d.id} value={d.id}>{d.description[this.props.i18n.language]}</Option>))}
							</Select>
						</div>
					</div>
					<div className="ticket-search">
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.priority")}
							</div>
							<Select
								className={"selector"}
								allowClear={true}
								onChange={(e) => this.setState({ priority: e })}
								value={this.state.priority}
							>
								{this.state.ticketPriority?.map(d => (
									<Option value={d.id} key={d.id}>{d.description[this.props.i18n.language]}</Option>))}
							</Select>
						</div>
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.status")}
							</div>
							<Select
								allowClear={true}
								className={"selector"}
								onChange={(e) => this.setState({ status: e })}
								value={this.state.status}
							>
								{this.state.ticketStatus?.map(d => (
									<Option key={d.id} value={d.id}>{d.description[this.props.i18n.language]}</Option>))}
							</Select>
						</div>
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.type")}
							</div>
							<Select
								allowClear={true}
								className={"selector"}
								onChange={(e) => this.setState({ type: e })}
								value={this.state.type}
							>
								{this.state.ticketTypes?.map(d => (
									<Option key={d.id} value={d.id}>{d.description[this.props.i18n.language]}</Option>))}
							</Select>
						</div>
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.assigned")}
							</div>
							<Select
								allowClear={true}
								className={"selector"}
								onChange={(e) => this.setState({ assigned: e })}
								value={this.state.assigned}
								filterOption={(input, op) =>
									this.state.assignedList.filter(d => d.id === Number(op?.value))[0]?.name.search(input) !== -1}
								showSearch={true}
							>
								{this.state.assignedList.map(d => (<Option key={d.id} value={d.id}>{d.name}</Option>))}
							</Select>
						</div>
						<div className="field">
							<div className="field-title">
								{this.props.t("tickets.search.tag")}
							</div>
							<Select
								allowClear={true}
								className={"selector"}
								onChange={(e) => this.setState({ tag: e })}
								value={this.state.tag}
							>
								{this.state.tagList.map(d => (<Option key={d.id} value={d.id}>{d.name}</Option>))}
							</Select>
						</div>

					</div>
					<div className="ticket-search">
						<div className="search-bar">
							{this.state.ticketSelected.length > 0 &&
								/*<Button loading={this.state.onEval} type="primary" onClick={this.ticketReEval} className={"button"}  >
									{this.props.t("tickets.search.automatic-re-eval")}
								</Button>*/
								<Button disabled={this.state.isExported} type="primary" onClick={this.exportSelected} className={"button"}  >
									{this.props.t("tickets.search.export")}
								</Button>

							}
							<Button type={"primary"} onClick={this.search} loading={this.state.loading}
								className={"button"}>
								{this.props.t("tickets.search.act")}
							</Button>
							<Button danger onClick={this.reset} className={"button"}>
								{this.props.t("tickets.search.reset")}
							</Button>

						</div>
					</div>
				</PageHeader>
			</div>
		);
	}


	render(): React.ReactNode {
		return (
			<>
				{this.renderTicketList()}
				{this.renderTicketDetail()}
			</>
		)
	}
}

export default withRouter(withTranslation()(TicketSearch));