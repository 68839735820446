import { Tabs } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { mailTemplateColumnProvider, MailTemplateModel } from "../../@mod";
import TemplateContentView from "../template-content-view";

interface MailTemplateListModel extends WithTranslation {
    apiServer: ApiServer,
    templates: MailTemplateModel[],
    onMailTemplateAction: (record: MailTemplateModel, action: string) => void,
    bindOperationBtn?: boolean
    editOperationBtn?: boolean
    deleteOperationBtn?: boolean
    unbindOperationBtn?: boolean
}

interface MailTemplateListState {
    activeKey: string,
    selectedTemplateForTabs: MailTemplateModel[]
}

class MailTemplateList extends React.Component<MailTemplateListModel, MailTemplateListState>{
    constructor(props: MailTemplateListModel) {
        super(props)
        this.state = {
            activeKey: "0",
            selectedTemplateForTabs: []
        }
    }

    activeKey = (key: string) => {
        this.setState({
            activeKey: key
        })
    }

    editTabs = (e, a) => {
        if (a === "remove") {
            let templates = this.state.selectedTemplateForTabs
            this.setState({
                selectedTemplateForTabs: templates.filter(x => String(x.id) !== e)
            })
        }
    }

    onMailTemplateAction = (record: MailTemplateModel, action: string) => {
        if (action === "detail") {
            const templates = this.state.selectedTemplateForTabs
            let filtered = templates.filter(t => t.id === record.id)
            if (filtered && filtered.length > 0) {
                this.activeKey(record.id + "")
                return
            }
            templates.push(record)
            this.setState({
                selectedTemplateForTabs: templates
            })
        } else {
            this.props.onMailTemplateAction(record, action)
        }

    }

    renderTabs = () => {
        let tabs: React.ReactNode[] = []
        tabs.push(
            <Tabs.TabPane closable={false} key="0" tab={this.props.t("mails.mail-template.all-templates")}>
                <WorkBenchTable
                    data={this.props.templates}
                    dataLoading={false}
                    columnProvider={() => mailTemplateColumnProvider(this.props.t, this.onMailTemplateAction, this.props.bindOperationBtn,
                        this.props.editOperationBtn, this.props.deleteOperationBtn, this.props.unbindOperationBtn
                    )}
                />
            </Tabs.TabPane>
        )
        tabs.push(
            this.state.selectedTemplateForTabs.map((t, index) => {
                return (
                    <Tabs.TabPane closable={true} key={String(t.id)} tab={t.title} style={{ height: "100%" }}>
                        <TemplateContentView apiServer={this.props.apiServer} templateId={t.id ?? -1} />
                    </Tabs.TabPane >
                )
            })
        )
        return tabs
    }

    render(): React.ReactNode {
        return (
            <div className="mail-template-list-container">
                <Tabs
                    type="editable-card"
                    activeKey={this.state.activeKey}
                    onTabClick={this.activeKey}
                    tabPosition={"top"}
                    onEdit={this.editTabs}
                    hideAdd
                >
                    {this.renderTabs()}
                </Tabs>
            </div>
        )
    }
}

export default withTranslation()(MailTemplateList)