import { Button, Modal, PageHeader, Tabs } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { loadUsers, UserModel } from "../../@mod";
import UserSelector from "../../user-selector";
import './index.less';
import { CloseOutlined } from '@ant-design/icons'
import ParticipatorAnalysis from "./participator-analysis";


export interface ParticipatedViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface ParticipatedViewState {
    participators: ParticipatorModel[],
    users: UserModel[],
    selectedUser: UserModel | undefined,
    openEditorAnalysis :boolean
}

interface ParticipatorModel {
    userId: number;
    name: string;
    org: string;
}



class ParticipatedView extends React.Component<ParticipatedViewModel, ParticipatedViewState>{


    constructor(props: ParticipatedViewModel) {
        super(props)
        this.state = {
            participators: [],
            users: [],
            selectedUser: undefined,
            openEditorAnalysis:false
        }
    }

    loadData = async () => {
        let users = await loadUsers(this.props.apiServer);
        this.setState({
            users: users,
            selectedUser: users[0]
        })
    }


    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.l = undefined;
                })
        }

    }


    renderParticipatorTabs = () => {

        let overViewTab: React.ReactNode[] = [];
        overViewTab.push(
            <Tabs.TabPane closable={false} tab={this.props.t("statistics-tickets.participated-overview")} key={"default"}>

                <div className="total-summary">



                </div>
            </Tabs.TabPane>
        )

        this.state.participators.forEach(x => {
            overViewTab.push(
                <Tabs.TabPane tab={x.name} closable={true} key={x.userId} closeIcon={<CloseOutlined />} >
                    <ParticipatorAnalysis userId={x.userId} apiServer={this.props.apiServer} />
                </Tabs.TabPane>
            )

        });
        return overViewTab;
    }

    onTabEdit = (key, action) => {

        if (action === 'remove') {

            var removedSelected = this.state.participators.filter(x => Number(x.userId) !== Number(key));

            this.setState({
                participators: removedSelected
            })
        }else if (action ==='add'){
            this.setState({
                openEditorAnalysis:true
            })

        }
    }


    renderFooter = () => {

        let tabs = this.renderParticipatorTabs();
        return (
            <Tabs onEdit={this.onTabEdit} type="editable-card"
                tabPosition="top"
                className="participator-anaylsis-tab">
                {tabs}
            </Tabs>
        )

    }

    addParticipatorAnalysis = () => {
        if (this.state.selectedUser &&
            this.state.participators.findIndex(x => x.userId ===
                this.state.selectedUser?.id) < 0) {
            let p = this.state.participators;
            p.push({
                userId: this.state.selectedUser.id,
                name: this.state.selectedUser.name,
                org: this.state.selectedUser.organization
            })
            this.setState({
                participators: p
            })
            this.closeAdd();
        }
    }

    
    closeAdd = ()=>{
        this.setState({
            openEditorAnalysis:false
        })
    }

    render(): React.ReactNode {
        return (
            <div className="participated-summary-view">
                {this.renderFooter()}
                <Modal
                    
                    title={this.props.t("statistics-tickets.add-participator")}
                    visible={this.state.openEditorAnalysis}
                    onOk={this.addParticipatorAnalysis}
                    onCancel={this.closeAdd}
                    okText={this.props.t("statistics-tickets.add-participator")}
                    cancelText="取消"
                    centered
                >
                    <div className="left-part">
                        <UserSelector users={this.state.users}
                            defaultSelected={this.state.users.length > 0 ? this.state.users[0] : undefined}
                            onSelected={x => this.setState({ selectedUser: x })}
                        />
                    </div>
                </Modal>
            </div>
        )
    }
}


export default withTranslation()(ParticipatedView);