import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import NotificationSetting from "../component/notification-setting";
import ApiServer from "../../../../ApiServer";



export interface FeishuConfigurePageModel extends WithTranslation {
	apiServer: ApiServer
}
interface FeishuConfigurePageState{

}

class FeishuConfigurePage extends React.Component<FeishuConfigurePageModel,FeishuConfigurePageState>{

	render(): React.ReactNode {
		return (
			<NotificationSetting
				channelType={"feishu"}
				channelConfigurationTitle={this.props.t("notification-setting.channel-configuration.config-feishu-parameters")}
				messageTemplateContainerTitle={this.props.t("notification-setting.message-template.feishu-message-template")}
				apiServer={this.props.apiServer}
			/>
		);
	}
}

export default withTranslation()(FeishuConfigurePage);