import React from "react";
import { Chart, Util } from '@antv/g2';
import { SERIAL_COLOR, TimeValueDataModel } from "../@mod";
import './index.less';
import moment from "moment-timezone";
import { withTranslation, WithTranslation } from "react-i18next";


export interface TimeChartViewModel extends WithTranslation {
    data: TimeValueDataModel[]
    types: string[],
    colors?: string[],
    height?: number
}

interface TimeChartViewState {

}

interface TimeChartRanderModel {
    range: any[],
    status: any,
    name: string
}

class TimeChart extends React.Component<TimeChartViewModel, TimeChartViewState>{

    containerId: string
    serialColor = SERIAL_COLOR;

    constructor(props: TimeChartViewModel) {
        super(props)

        this.containerId = "time-chart-container-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random();

    }
    chart: Chart | undefined;

    componentDidUpdate() {
        if (this.chart == null && this.props.data.length > 0) {
            var colors = (this.props.colors) ?? this.props.types.map((x, index) => {
                return this.serialColor[index] ?? "#dfdfdf"
            });

            this.chart = new Chart({
                container: this.containerId,
                autoFit: true,
                height: this.props.height

            });
            console.log(this.props.height)
            this.chart.data(this.getData())
            this.chart.coordinate().transpose().scale(1, -1);
            this.chart.scale('range', {
                type: 'time',
                nice: true,
            });
            this.chart.tooltip({
                showMarkers: false,

                customItems: (items) => {
                    return items.map(x => {
                        let toolTipItem = {
                            ...x,
                            name: this.props.t("time-cost"),
                            value: (moment(x.data.range[1].toString()).unix() -
                                moment(x.data.range[0].toString()).unix()) / 60 + " " + this.props.t("minutes")
                        }
                        return toolTipItem;
                    })
                }
            });
            this.chart.interaction('element-active');
            this.chart
                .interval()
                .position('name*range')
                .color('status', colors)
                .animate({
                    appear: {
                        animation: 'scale-in-x',
                    }
                });
            this.chart.render()

        } else if (this.chart) {
            this.chart.changeData(this.getData());
        }
    }


    getData(): TimeChartRanderModel[] {
        return this.props.data.map(x => {
            let model: TimeChartRanderModel = {
                range: [x.start, x.end],
                status: this.props.types[x.type],
                name: x.name
            }
            return model;
        })
    }


    render(): React.ReactNode {

        return (<div id={this.containerId} className="time-chart-container">

        </div>)
    }
}


export default withTranslation()(TimeChart);

