import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { SketchPicker, ColorResult } from "react-color"
import { Button, Input } from "antd";
import './index.less'


export interface TagEditorModel extends WithTranslation {
	name?: string
	color?: string
	done?: (name: string, color: string) => void;
	cancel?: ()=>void
}

interface TagEditorState {
	color?: string
	name?: string
}

class TagEditor extends React.Component<TagEditorModel, TagEditorState>{
	constructor(prop: TagEditorModel) {
		super(prop)
		this.state = {

		}
	}


	componentDidMount() {
		this.setState(
			{
				name: this.props.name,
				color: this.props.color
			}
		)
	}

	colorSelected = (color: ColorResult) => {
		this.setState(
			{
				color: color.hex
			}
		)
	}

	render(): React.ReactNode {
		return (
			<div className="tag-editor">
				<span>{this.props.t("tag.name")}</span>
				<Input type={"text"} placeholder={this.props.t("tag.name")} onChange={this.nameInput}></Input>
				<span>{this.props.t("tag.color")}</span>
				<SketchPicker onChange={this.colorSelected} disableAlpha={true}
					color={this.state.color}
				/>
				<div className="buttons">
					<Button type="primary" onClick={this.okClick}>{this.props.t("tag.ok")}</Button>
					<Button type="primary" onClick={this.cancelClick}>{this.props.t("tag.cancel")}</Button>
				</div>
			</div>
		)
	}

	okClick= ()=>{
		console.log("clicked")
		if(this.props.done){
			this.props.done(this.state.name??"",this.state.color??"");
		}
		//TODO: Notification
	}
	cancelClick = ()=>{
		if(this.props.cancel){
			this.props.cancel();
		}
	}


	nameInput=(a:any)=>{
		this.setState({
			name:a.currentTarget.value
		})
	}

}

export default withTranslation()(TagEditor)
