import { Button } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { UndoOutlined } from "@ant-design/icons";
import { ConnectiveStatusModel } from "../../@mod";
import ApiServer from "../../../../ApiServer";
interface ConnectiveStatusViewModel extends WithTranslation {
    state: ConnectiveStatusModel
    clusterIdentifier: string
    apiServer: ApiServer
}

interface ConnectiveStatusState {
    state: ConnectiveStatusModel
    syncStateLoading: boolean
}


class ConnectiveStatus extends React.Component<ConnectiveStatusViewModel, ConnectiveStatusState> {
    constructor(props: ConnectiveStatusViewModel) {
        super(props)
        this.state = {
            state: props.state,
            syncStateLoading: false
        }
    }

    loadClusterState = () => {
        this.setState({
            syncStateLoading: true
        })
        this.props.apiServer.apiCluster.getRemoteInstanceConnectiveStatus(this.props.clusterIdentifier).then(r => {
            if (r.data.data?.dto) {
                this.setState({
                    state: { ...r.data.data?.dto }
                })
            }
        }).finally(() => {
            this.setState({
                syncStateLoading: false
            })
        })
    }

    render(): React.ReactNode {
        return (
            <>
                {this.state.state.desc?.[this.props.i18n.language]}
                <Button
                    shape="circle"
                    icon={<UndoOutlined />}
                    size={"small"}
                    style={{ marginLeft: "4px" }}
                    onClick={this.loadClusterState}
                    loading={this.state.syncStateLoading}
                />
            </>
        )
    }
}


export default withTranslation()(ConnectiveStatus)