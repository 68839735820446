import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import './index.less';
import RichTextEditor, { EditorOperation, RichTextEditorViewModel } from "../../../../../components/text-editor";
import { dataURLtoBlob, makeString } from "../../../../../utils";
import { Axios } from "axios";
import { exec } from "child_process";

const ATTACHMENT_REGEX = /\{\{attachment:([0-9]+)\}\}/g;

//{{attachment:1313}}

export interface DefaultTextEditorViewModel extends WithTranslation {
	getUploadUrl?: (uid: string, fileName: string) => Promise<{ url: string, id: number }>,
	getDownloadUrl?: (id: number) => Promise<{ url: string, id: number }>,
	onCommit: (v: string) => void,
	onEventHappend?:(e:string) => void
	value?: string,

	isLoading?: boolean,
}

interface DefaultTextEditorViewState {
	value: string,
	enableEdit: boolean,
	renederedValue?: string
}


class DefaultTextEditor extends React.Component<DefaultTextEditorViewModel, DefaultTextEditorViewState>{

	textEditor: EditorOperation | undefined
	textEditorEnable: ((b) => void) | undefined;
	currentValue: string = "";

	constructor(props: DefaultTextEditorViewModel) {
		super(props)
		this.state = {
			value: this.currentValue = this.props.value ?? "",
			enableEdit: false
		}
	}


	async getValue(content) {
		let finalValue =content ?? this.state.value;
		let result: null | RegExpExecArray = null;
		let replaceTarget: {
			placeHolder: string,
			attachmentId: number,
			replaceText?: string
		}[] = [];

		while (result = ATTACHMENT_REGEX.exec(finalValue)) {
			replaceTarget.push({
				placeHolder: result[0],
				attachmentId: Number(result[1])
			})
		}

		for (var i = 0; i < replaceTarget.length; i++) {
			if (this.props.getDownloadUrl) {
				await this.props.getDownloadUrl(replaceTarget[i].attachmentId)
					.then(q => {
						replaceTarget[i].replaceText = "<img src=\"" + q.url + "#attachment-" + q.id + "\" />"
					});
			}
		}

		replaceTarget.forEach(x => {
			finalValue = finalValue.replace(x.placeHolder, x.replaceText ?? "");
		});

		return finalValue;
	}

	componentDidMount(): void {
		this.renderHtml(null);
	}

	renderHtml(content ){
		this.getValue(content).then(x => {
			console.log(x)
			this.setState({
				renederedValue: x
			})
		})
	}


	componentDidUpdate() {

	}

	getSnapshotBeforeUpdate(preProp: DefaultTextEditorViewModel, state: DefaultTextEditorViewState) {
		if (this.props.value) {
			if (preProp.value !== this.props.value) {
				this.setState({
					value: this.props.value ?? ""
				})
			}
		}
		return null;
	}

	onSave = () => {
		let content = this.currentValue;
		let fullImageTag: { fullTag: string, id: number }[] = [];
		let ATTACHMENT_IMG_TAG = RegExp("<img src=\"https://[a-z0-9A-Z\-\/\.&;=\?%_]*#attachment-([0-9]+)\" ?/?>", "g");
		do {
			let matchResult = ATTACHMENT_IMG_TAG.exec(content);
			if (matchResult) {
				let imageFull = matchResult[0];
				let attachmentId = matchResult[1];
				fullImageTag.push({
					fullTag: imageFull,
					id: Number(attachmentId)
				});
			} else {
				break;
			}
		} while (true)

		fullImageTag.forEach(x => {
			content = content.replace(x.fullTag, "{{attachment:" + x.id + "}}")
		})

		this.props.onCommit(content);
		this.setState({ enableEdit: false, value: content })
		this.renderHtml(content);
	}

	onChange = (
		delta: any,
		oldDelta: any,
		content: string) => {
		if (delta.ops.length >= 2) {
			var retain = delta.ops[0];
			var majorOps = delta.ops[1];
			if (majorOps.insert && majorOps.insert.image) {
				if (majorOps.insert.image.indexOf("data:image/png;base64,") === 0) {

					let blob = dataURLtoBlob(majorOps.insert.image)
					let fileName = makeString();
					let uid = makeString();
					let file = new File([blob], fileName, {
						type: blob.type,
						lastModified: Date.now(),
					});
					if (this.props.getUploadUrl) {
						this.props.getUploadUrl(uid, file.name).then(async (q) => {
							if (this.props.getDownloadUrl) {
								var a = new Axios({ baseURL: q.url })
								await a.put(q.url, file);
								this.textEditor?.delete?.(retain.retain, 1);
								let downloadUrl = await this.props.getDownloadUrl(q.id);
								this.textEditor?.delete?.(retain.retain, 1);
								this.textEditor?.addImageHtml?.(retain.retain, "attachment-" + q.id, downloadUrl.url + "#attachment-" + downloadUrl.id);
							}
						})
					}
					// {{attachment:56}}
					//<img id="attachment-56" src=""/>
					// upload the image to attachment 
				}
			}
		}
		//console.log(content)
		this.currentValue = content;
		/*this.setState({
			value: content
		})*/
	}

	setEditorEnable = (f: (b) => void) => {
		this.textEditorEnable = f
	}

	setEditorOperator = (f: EditorOperation) => {
		this.textEditor = f
	}

	onEditorDoubleClick = ()=>{
		if(this.props.onEventHappend){
			this.props.onEventHappend("editor-wakeup");

		}
		this.setState({ enableEdit: true })

	}


	render(): React.ReactNode {
		return (
			<div className="default-editor">
				<div className="defaul1t-editor-bar">
					<Button disabled={this.state.enableEdit} onClick={() => this.setState({ enableEdit: true })}  >{this.props.t("tickets.edit-default-detail")}</Button>
					<Button disabled={!this.state.enableEdit} onClick={this.onSave} type="primary"> {this.props.t("tickets.save")}</Button>
				</div>
				{this.state.enableEdit &&
					<div className="default-editor-field">
						<div className="quill-editor">
							<div className="tool-bar" style={{ display: "block" }} id={"toolbar-default-view-rich-editor"} >
								<select className="ql-header">
									<option value={1}></option>
									<option value={2}></option>
									<option value={3}></option>
									<option value={4}></option>
									<option value={5}></option>
									<option selected></option>
								</select>
								<button className="ql-bold"></button>
								<button className="ql-italic"></button>
								<button className="ql-underline"></button>
								<button className="ql-strike"></button>
								<button className="ql-blockquote"></button>
								<button className="ql-indent" value={"+1"}></button>
								<button className="ql-indent" value={"-1"}></button>
								<button className="ql-list" value={"ordered"}></button>
								<button className="ql-list" value={"bullet"}></button>
								<button className="ql-link"></button>
								<button className="ql-image"></button>
								<button className="ql-clean"></button>
							</div>
							<RichTextEditor value={this.getValue(null)}
								containerId="default-view-rich-editor"
								editorOperator={this.setEditorOperator}
								enableOperator={this.setEditorEnable}
								onChanged={this.onChange}
							/>
						</div>
						{/*<TextArea
						value={this.state.value} onChange={v => this.setState({ value: v.target.value })}
						rows={this.state.value.split("\n").length}
						readOnly={!this.state.enableEdit} />*/
						}
					</div>
				}
				{

					!this.state.enableEdit &&
					<div className="ql-container ql-snow" onDoubleClick={this.onEditorDoubleClick}>
						<div dangerouslySetInnerHTML={{ __html: this.state.renederedValue ?? "" }} className="ql-editor">

						</div>
					</div>
				}
			</div>
		)
	}
}



export default withTranslation()(DefaultTextEditor)