import { Button, Tag, Tooltip } from "antd"
import { ColumnType } from "antd/lib/table"
import { WithTranslation } from "react-i18next"
import { TicketDto } from "../../../api"
import { ActionEventHandler } from "../../../components"
import ColumnTextFilter from "../../../components/table/column-text-filter"
import moment from 'moment'
import ApiServer from "../../../ApiServer"
import Countdown from "antd/lib/statistic/Countdown"
import { LockOutlined, UnlockOutlined } from "@ant-design/icons"
import './index.less';
import ColumnDateRangeFilter from "../../../components/table/column-date-range-filter"



export interface TemplateInstance {
    title: string,
    subtitle: string,
    timelimit: number,
    type: number,
    status: number,
    priority: number,
    source: number,
    assignedGroup: number,
    assignedUser: number,
    tags: number[],
	contentSchema:number[]
    defaultContent:string
}


export interface NewTicketTemplateRecord{
    key?:number,
    id: number,
    title: string,
    template: TemplateInstance
    created: string,
    updated: string

}
export interface ContentSchemaModel {
	id: number,
	name: string
}



export const loadContentSchemaList = async (apiServer:ApiServer) :Promise<ContentSchemaModel[]> =>  {
	return await apiServer.apiMeta.getContentSchema()
			.then(x => {
				if (x.data.data?.list) {
					let data = x.data.data.list.map(u => {
						let model: ContentSchemaModel = {
							id: u.id ?? 0,
							name: u.name ?? ""
						}
						return model
					}) || [];
					return data
				}
				return []
			})
}


export async function loadTicketTemplate(apiServer: ApiServer): Promise<NewTicketTemplateRecord[]> {
    return apiServer.apiProductivity.getTicketTemplates()
        .then(x => {
            let records: NewTicketTemplateRecord[] = []
            if (x.data.data?.success) {
                x.data.data.dtos?.forEach(y => {
                    let record: NewTicketTemplateRecord = {
                        key: y.id,
                        id: y.id ?? 0,
                        title: y.title ?? "",
                        created: y.createdTime ?? "",
                        updated: y.updatedTime ?? "",
                        template: JSON.parse(y.content ?? "{}")
                    }
                    records.push(record);
                })
            }
            return records;
        });
}


export interface TicketModel {
	key?: number,
	id: number,
	priority: PriorityModel
	status: StatusModel,
	type: TicketTypeModel,
	source: SourceModel,
	subject: string,
	subtitle: string,
	sla: string,
	assigned?: UserModel,
	assignedGroup: UserGroupModel,
	createTime: string,
	updateTime: string,
	creator?: UserModel
	updator?: UserModel,
	contentId: number,
	isPrivate: boolean,
	watchList: UserModel[]
	tags?: TagModel[]

}

export interface SourceModel {
	id: number,
	name: string,
	description: any
}


export interface TicketTypeModel {
	id: number,
	name: string,
	description: any
}

export interface StatusModel {
	id: number,
	name: string,
	description: any
}

export interface PriorityModel {
	id: number,
	name: string,
	description: any
}

export interface UserModel {
	id: number,
	name: string,
	organization: string,
	displayName?: string,
	active?:boolean
}

export interface UserGroupModel {
	key?: number,
	id: number,
	name: string
	description: string
}

export interface TagModel {
	id: number,
	name: string,
	color: string
}


export async function loadUserGroupModels(apiServer: ApiServer): Promise<UserGroupModel[]> {
	return apiServer.apiGroup.getUserGroups()
		.then(
			x => x.data.data?.list?.map(
				y => {
					let ug: UserGroupModel = {
						name: y.name ?? "",
						description: y.description ?? "",
						id: y.id ?? 0
					}
					return ug;
				}
			) ?? []
		)
}


export async function loadTicketType(apiServer: ApiServer): Promise<TicketTypeModel[]> {
	return apiServer.apiMeta.getTicketTypeList(false)
		.then(x => {
			let ticketTypes = x.data.data?.list?.map(y => {
				let ticketType: TicketTypeModel = {
					name: y.name ?? "",
					description: y.description,
					id: y.id ?? 0
				}
				return ticketType;
			}) ?? []
			return ticketTypes;
		})
}

export async function loadTicketSource(apiServer: ApiServer): Promise<SourceModel[]> {

	return apiServer.apiMeta.getTicketSourceList(false)
		.then(x => {
			let ticketTypes = x.data.data?.list?.map(y => {
				let ticketType: TicketTypeModel = {
					name: y.name ?? "",
					description: y.lang,
					id: y.id ?? 0
				}
				return ticketType;
			}) ?? []
			return ticketTypes;
		})
}



export async function loadTicketPriority(apiServer: ApiServer): Promise<PriorityModel[]> {

	return apiServer.apiMeta.getTicketPriority(false)
		.then(x => {
			let ticketTypes = x.data.data?.list?.map(y => {
				let ticketType: TicketTypeModel = {
					name: y.name ?? "",
					description: y.lang,
					id: y.id ?? 0
				}
				return ticketType;
			}) ?? []
			return ticketTypes;
		})
}

export async function loadAllTags(apiServer: ApiServer): Promise<TagModel[]> {
	return apiServer.apiProductivity.getTagList()
		.then(x => {
			return x.data.data?.tagDtoList?.map((y) => {
				let t: TagModel = {
					name: y.name ?? "",
					color: y.color ?? "#000000",
					id: y.id ?? 0
				}
				return t;
			}

			) ?? []
		});
}


export async function loadUsers(apiServer: ApiServer, userGroupId: number): Promise<UserModel[]> {
	return apiServer.apiGroup.getUserGroup(userGroupId, true, false)
		.then(x =>
			x.data.data?.users?.map(y => {
				let u: UserModel = {
					name: (y.fullName ?? "") + (y.active?"(@" + y.name + ")":" ### 已离职 ###"),
					id: y.id ?? 0,
					organization: y.organization ?? "",
					active: y.active
				}
				return u
			}) ?? []
		)
}


export async function loadTicketStatus(apiServer: ApiServer): Promise<StatusModel[]> {

	return apiServer.apiMeta.getTicketStatusList(false)
		.then(x => {
			let ticketTypes = x.data.data?.list?.map(y => {
				let ticketType: TicketTypeModel = {
					name: y.name ?? "",
					description: y.description,
					id: y.id ?? 0
				}
				return ticketType;
			}) ?? []
			return ticketTypes;
		})
}


export function convertTo(ticketDto: TicketDto): TicketModel {

	let model: TicketModel = {
		key: ticketDto.id,
		id: ticketDto.id ?? 0,
		isPrivate: ticketDto.private ?? false,
		priority: {
			id: ticketDto.priority?.id ?? 0,
			name: ticketDto.priority?.name ?? "",
			description: ticketDto.priority?.lang
		},
		source: {
			id: ticketDto.source?.id ?? 0,
			name: ticketDto.source?.name ?? "",
			description: ticketDto.source?.lang
		},
		type: {
			id: ticketDto.type?.id ?? 0,
			name: ticketDto.type?.name ?? "",
			description: ticketDto.type?.description
		},
		status: {
			id: ticketDto.status?.id ?? 0,
			name: ticketDto.status?.name ?? "",
			description: ticketDto.status?.description
		},
		subject: ticketDto.subject ?? "",
		subtitle: ticketDto.subTitle ?? "",
		sla: ticketDto.sla ?? "",
		assigned: {
			id: ticketDto.assigned?.id ?? 0,
			name: (ticketDto.assigned?.fullName ?? "") + "(@" + (ticketDto.assigned?.name ?? "") + ")",
			organization: ticketDto.assigned?.organization ?? ""
		},
		assignedGroup: {
			id: ticketDto.assignedGroup?.id ?? 0,
			name: ticketDto.assignedGroup?.name ?? "",
			description: ticketDto.assignedGroup?.description ?? ""
		},
		createTime: ticketDto.createdTime ?? "",
		updateTime: ticketDto.updatedTime ?? "",
		contentId: ticketDto.contentId ?? 0,
		watchList: []

	}
	if (ticketDto.createdBy) {
		model.creator = {
			id: ticketDto.createdBy?.id ?? 0,
			name: (ticketDto.createdBy.fullName ?? "") + "(@" + (ticketDto.createdBy?.name ?? "") + ")",
			organization: ticketDto.createdBy?.organization ?? ""
		}
	}
	if (ticketDto.updatedBy) {
		model.updator = {
			id: ticketDto.updatedBy?.id ?? 0,
			name: ticketDto.updatedBy?.name ?? "",
			organization: ticketDto.updatedBy?.organization ?? ""
		}
	}
	if (ticketDto.watchList) {
		model.watchList = ticketDto.watchList.map(
			x => {
				return {
					id: x?.id ?? 0,
					name: (x?.fullName ?? "") + "(@" + (x?.name ?? "") + ")",
					organization: x?.organization ?? ""
				}
			}
		)
	}
	if(ticketDto.tags){
		model.tags = ticketDto.tags?.map(x=>{
			return {
				id: x.id??0,
				name:x.name??"",
				color:x.color??"#000000"
			}
		});
	}

	return model;
}

export interface CoreStatusMetas {
	status: StatusModel[],
	priority: PriorityModel[],
	source: SourceModel[],
	type: TicketTypeModel[]
}

export const columnsProvider = (
	trans: WithTranslation, handler: ActionEventHandler<TicketModel>, 
	tickets:TicketModel[],
	coreStatusMetas?: CoreStatusMetas,
	skippedColumn?:string[] 
	): ColumnType<TicketModel>[] => {
	let translater: ((d: string) => string) = trans.t;
	let user:UserModel[] = [];
	let createUser : UserModel[] = [];
	let columskipped = skippedColumn??[];
	let allTagged:TagModel[]  = []

	tickets.forEach(element => {
		if(!user.find(x=>x.id === element.assigned?.id??0)){
			if(element.assigned){
				user.push(element.assigned)
			}
		}
		if(!createUser.find(x=>x.id === element.creator?.id??0)){
			if(element.creator){
				createUser.push(element.creator)
			}
		}
		if(element.tags){
			element.tags.forEach(x=>{
				if(allTagged.findIndex(y=>y.id===x.id)<0){
					allTagged.push(x);
				}
			})
		}
	});
	return [
		{
			"title": translater("tickets.id"),
			dataIndex: "id",
			key: "id",
			render: (text, record) => {
				return (
					<div>
						{record.isPrivate &&
							<Tag color="#005ac8">
								<LockOutlined ></LockOutlined>
							</Tag>
						}
						{!record.isPrivate &&
							<Tag color="#00af00">
								<UnlockOutlined ></UnlockOutlined>
							</Tag>

						}
						#{record.id}
					</div>)
			},
			width:120,
			filterSearch: true,
			onFilter: (value: string | number | boolean, record: TicketModel) =>{ 
				return String(record.id)=== String(value)
			},
			filters: [],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("tickets.id")} />)
			},
			
		},
		{
			title: translater("tickets.subject"),
			dataIndex: 'subject',
			key: 'subject',
			render: (text: string, record: TicketModel) =>
			{
				return (
				<div>
					<div className="subject-container">
						<div className="link"  onClick={() => handler(record, "detail")}>
							{text}
						</div>
					</div>
					<div style={{ marginLeft: 16, color: "#666666" }} >{record.subtitle}</div>
				</div>)
			},
			filterSearch: true,
			onFilter: (value: string | number | boolean, record: TicketModel) =>{ 
				return record.subject.indexOf(value.toString()) >= 0 || record.subtitle.indexOf(value.toString())>=0
			},
			filters: [],
			width: 320,
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("tickets.subject")} />)
			},

		},
		{
			title: translater("tickets.source.self"),
			dataIndex: 'source',
			key: 'source',
			filterMultiple: true,
			filters: coreStatusMetas?.source.map(x => {
				return { text: x.description[trans.i18n.language], value: x.id }
			}) ?? [],
			onFilter(value, record) {
				return record.source.id === value;
			},
			render: (value: any, record: TicketModel, index: number) => {
				return record.source.description[trans.i18n.language];
			}
		},
		{
			title: translater("tickets.priority.self"),
			dataIndex: 'priority',
			key: 'priority',
			filterMultiple: true,
			filters: coreStatusMetas?.priority.map(x => {
				return { text: x.description[trans.i18n.language], value: x.id }
			}) ?? [],
			onFilter(value, record) {
				return record.priority.id === value;
			},
			render: (value: any, record: TicketModel, index: number) => {
				return (<span style={{ color: record.priority.description["color"] }} >{record.priority.description[trans.i18n.language]}</span>)
			}
		},
		{
			title: translater("tickets.status.self"),
			dataIndex: 'status',
			key: 'status',
			filterMultiple: true,
			filters: coreStatusMetas?.status.map(x => {
				return { text: x.description[trans.i18n.language], value: x.id }
			}) ?? [],
			onFilter(value, record) {
				return record.status.id === value;
			},
			render: (value: any, record: TicketModel, index: number) => {
				return record.status.description[trans.i18n.language]
			}
		},
		{
			title: translater("tickets.type.self"),
			dataIndex: 'type',
			key: 'type',
			onFilter(value, record) {
				return record.type.id === value;
			},
			filterMultiple: true,
			filters: coreStatusMetas?.type.map(x => {
				return { text: x.description[trans.i18n.language], value: x.id }
			}) ?? [],
			render: (value: any, record: TicketModel, index: number) => {
				return record.type.description[trans.i18n.language]
			}
		}, {
			title: translater("tickets.assigned"),
			dataIndex: 'assigned',
			key: 'assigned',
			filterMultiple: true,
			filters: user.map(y=>{
				return {
				text:y?.name??"",
				value:y?.id??0};
			})??[],
			render: (value: any, record: TicketModel, index: number) => {
				return (
					<Tooltip title={record.assigned?.organization}>
						{record.assigned?.name}
					</Tooltip>)

			},
			onFilter(value, record) {
				return record.assigned?.id===value;
			},
		}, 
		{
			title: translater("tickets.creator"),
			dataIndex: 'creator',
			key: 'creator',
			filterMultiple: true,
			filters: createUser.map(y=>{
				return {
				text:y?.name??"",
				value:y?.id??0};
			})??[],
			render: (value: any, record: TicketModel, index: number) => {
				return (
					<Tooltip title={record.creator?.organization}>
						{record.creator?.name}
					</Tooltip>)
			},
			onFilter(value, record) {
				return record.creator?.id===value;
			},
		}, 
		{
			title: translater("tickets.sla"),
			dataIndex: 'sla',
			key: 'sla',
			render: (value: any, record: TicketModel, index: number) => {
				let sla = moment.parseZone(record?.sla)
				if(record.status.name==="CLOSE"){
					let lastUpdate = moment.parseZone(record.updateTime) ;
					let dur = moment.duration(sla.diff(lastUpdate));
					if(dur.asHours() > 0){
						return translater("tickets.completed-on-time")
					} else {
						return translater("tickets.completed-over-due")
					}
				}
				let dur = moment.duration(sla.diff(moment.now()));
				if (dur.asHours() < 0) {
					return translater("tickets.time-up")
				} else {
					return <Countdown
						value={Date.now() + dur.asMilliseconds()}
						format={"D|HH:mm:ss"}
					/>
				}
			}
		},
		{
			title: translater("tickets.createTime"),
			dataIndex: 'createTime',
			key: 'createTime',
			render: (value: any, record: TicketModel, index: number) => {
				return record.createTime === "" ? "" : moment.parseZone(record.createTime).format("YYYY-MM-DD HH:mm:ss");
			},
			filterDropdown(props) {
				return (<ColumnDateRangeFilter context={props} title={translater("tickets.createTime")} />)
			},
			sorter: (a: TicketModel, b: TicketModel)=> {
				return moment(a.createTime).unix() - moment(b.createTime).unix()
			},
			showSorterTooltip:false,
			onFilter: (value: string | number | boolean, record: TicketModel) =>{
				value = value.toString();
				let startEnd = value.split("->");
				if(startEnd.length===2){
					let start  = moment(startEnd[0]);
					let end = moment(startEnd[1]);
					let created =moment(record.createTime)
					return  created.isAfter(start) && created.isBefore(end);
				}
				return true
			}
		}
		, {
			title: translater("tickets.tags"),
			dataIndex: 'tags',
			key: 'tags',
			onFilter(value, record) {
				return record.tags && record.tags.findIndex(x=>x.id ===value) >= 0;
			},
			render: (value: any, record: TicketModel, index: number) => {
				if(value){
					return value.map(element => {
						return <Tag color={element.color} key={element.id}>{element.name}</Tag>
					});
				}
				return <></>
			},
			filters: allTagged.map(y=>{
				return {
				text:y?.name??"",
				value:y?.id??0};
			})??[]
		},
		
	].filter(x=>!(columskipped.indexOf(x.dataIndex)>=0));
}



export async function loadUserList(apiServer: ApiServer): Promise<UserModel[]> {
	return apiServer.apiUser.getUserList()
		.then(x => {
			return x.data.data?.list?.map(convertUserDtoToUserMode) ?? [];
		})
}


export  function convertUserDtoToUserMode(y:any) {
	let u: UserModel = {
		id: y.id ?? 0,
		name: (y.fullName ?? "") + "(@" + (y.name ?? "") + ")",
		organization: y.organization ?? "",
		active:y.active
	}
	return u;
}
var cacheUserGroup: UserGroupModel[]=[];

export const loadUserGroups = async (apiServer: ApiServer,forceLoad :boolean): Promise<UserGroupModel[]> => {
	if(!forceLoad && cacheUserGroup.length>0){
		return cacheUserGroup;
	}
	return apiServer.apiGroup.getUserGroups()
		.then(x => {
			let dat: UserGroupModel[] = []
			x.data.data?.list?.forEach(
				dto => {
					let d: UserGroupModel = {
						key: dto.id,
						id: dto.id ?? 0,
						name: dto.name ?? "",
						description:dto.description??""
					}
					dat.push(d);
				}
			);
			cacheUserGroup = dat;
			return dat;
		});
}