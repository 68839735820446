import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Layout, Menu, Breadcrumb, MenuProps } from 'antd';
import "./index.less";
import { Outlet } from "react-router-dom";
import { withRoute, WithRouteContext } from "../../utils";
import { menuEnt } from "../../config/function-menu"



const { Content, Sider } = Layout;
export interface ManagementBodyModel extends WithTranslation, WithRouteContext {
	menuKey: string
	isAdmin?:boolean
}

export interface ManagementState {
	paths: string[],
	menuKey: string,
	menuModel: MenuProps,
	menuKeyHighline: string,

}

class ManagementBody extends React.Component<ManagementBodyModel, ManagementState>{

	constructor(props: ManagementBodyModel) {
		super(props);
		this.state = {
			paths: [],
			menuKey: props.menuKey,
			menuModel: {
				theme: "light",

			},
			menuKeyHighline: "",
		};
	}

	removeTheAdminMenu = (menu:any[])=>{
		let a = menu.filter(x=>(x.adminrequired??true)===false);
		for(let i=0;i<a.length;i++){
			if(a[i].children && a[i].children.length>0){
				a[i].children = this.removeTheAdminMenu(a[i].children);
			}
		}
		return a;
	}

	updateProp(nextProps: ManagementBodyModel) {
		let currentPath = nextProps.router?.location.pathname;
		let p = currentPath?.split("/")
			.filter(
				(v, i, a) => {
					return v != null && v !== "";
				}
			);
		let menuItems = menuEnt(this.props.t)[nextProps.menuKey];
		if(!this.props.isAdmin){
			menuItems = this.removeTheAdminMenu(menuItems);
		}

		let menuModel = this.state.menuModel;
		menuModel.items = menuItems;
		this.setState(
			{
				menuModel: menuModel,
				paths: p ?? [],
				menuKeyHighline: currentPath ?? ""
			}
		)
	}
	componentDidMount() {
		this.updateProp(this.props)
	}

	componentDidUpdate() {

	}

	getSnapshotBeforeUpdate(prevProps: Readonly<ManagementBodyModel>, prevState: Readonly<ManagementState>) {
		if (prevProps !== this.props) {
			this.updateProp(this.props);
		}
		return null;
	}

	click = (menuInfo: any) => {

		if (this.props.router?.location.pathname !== menuInfo.key) {
			this.props.router?.navigate(menuInfo.key);
		}
	}


	get breadscrumb() {

		return this.state.paths.map((v, i) => {
			v = Number.parseInt(v)>0 ? "#" + v : v;
			return (
				<Breadcrumb.Item key={i}>{this.props.t(v)}</Breadcrumb.Item>
			)
		})
	}

	render(): React.ReactNode {
		return (
			<Content className='layout-content'>
				<Breadcrumb className="breadcrumb" separator=">">
					{this.breadscrumb}
				</Breadcrumb>
				<Layout
					className="site-layout-background">
					<Sider width={200} collapsible theme='light'
						style={{}}
					>
						<Menu
							mode="inline"
							items={this.state.menuModel.items}
							onClick={this.click}
							activeKey={this.state.menuKeyHighline}
							multiple={false}
							selectedKeys={[this.state.menuKeyHighline]}

						/>
					</Sider>
					<Content className="main-content">
						<Outlet></Outlet>
					</Content>
				</Layout>
			</Content>)
	}
}

export default withRoute(withTranslation()(ManagementBody));
