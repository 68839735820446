import { Checkbox, Input, Popover, Select, Tag, Tooltip } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ContentSchemaModel, MetaDataSource, TagModel, UserGroupModel, UserModel } from "../../../@mod";
import TagSelector from "../../../tag-selector";
import { PlusOutlined } from '@ant-design/icons';
import { NewTicketTemplateRecord } from "../@mod";
import './index.less';
import { CheckboxChangeEvent } from "antd/lib/checkbox";

const { Option } = Select;

export interface TemplateEditorViewModel extends WithTranslation {
    metaSource: MetaDataSource,
    allTags: TagModel[]
    record: NewTicketTemplateRecord,
    schemas: ContentSchemaModel[],
    userGroups: UserGroupModel[],
    loadUser: (groupId: number) => Promise<UserModel[]>
}

interface TemplateEditorState {
    record: NewTicketTemplateRecord
    users: UserModel[]
}


class TemplateEditor extends React.Component<TemplateEditorViewModel, TemplateEditorState>{

    constructor(props: TemplateEditorViewModel) {
        super(props)
        this.state = {
            record: this.props.record,
            users: []
        }
    }



    async componentDidMount() {
        this.setState({
            users: await this.props.loadUser(this.state.record.template.assignedGroup)
        });

    }

    renderTicketTypePreview = () => {

        return this.props.metaSource.ticketType.map(
            x => (
                <Option value={x.id} key={x.id}>
                    {x.description[this.props.i18n.language]}
                </Option>)
        )
    }

    renderTicketStatusPreview = () => {

        return this.props.metaSource.ticketStatus.map(
            x => (
                <Option value={x.id} key={x.id}>
                    {x.description[this.props.i18n.language]}
                </Option>)
        )
    }


    renderTicketSourcePreview = () => {

        return this.props.metaSource.ticketSource.map(
            x => (
                <Option value={x.id} key={x.id}>
                    {x.description[this.props.i18n.language]}
                </Option>)
        )
    }

    renderTicketPriorityPreview = () => {

        return this.props.metaSource.ticketPriority.map(
            x => (
                <Option value={x.id} key={x.id}>
                    {x.description[this.props.i18n.language]}
                </Option>)
        )
    }

    typeChanged = (v: number) => {
        let record = this.state.record;
        record.template.type = v;
        this.setState(
            {
                record: record
            }
        )

    }
    sourceChanged = (v: number) => {
        let record = this.state.record;
        record.template.source = v;
        this.setState(
            {
                record: record
            }
        )

    }
    priorityChanged = (v: number) => {
        let record = this.state.record;
        record.template.priority = v;
        this.setState(
            {
                record: record
            }
        )

    }
    statusChanged = (v: number) => {
        let record = this.state.record;
        record.template.status = v;
        this.setState(
            {
                record: record
            }
        )
    }

    templateTitleChanged = (v: string) => {
        let record = this.state.record;
        record.title = v;
        this.setState(
            {
                record: record
            }
        )
    }

    onTemplateTicketTitleChanged = (v: string) => {
        let record = this.state.record;
        record.template.title = v;
        this.setState(
            {
                record: record
            }
        )
    }

    onTemplateTicketSubtitleChanged = (v: string) => {
        let record = this.state.record;
        record.template.subtitle = v;
        this.setState(
            {
                record: record
            }
        )
    }

    onTemplateTicketTimelimitChanged = (v: number) => {
        let record = this.state.record;
        record.template.timelimit = v;
        this.setState(
            {
                record: record
            }
        )
    }
    renderSeletedTag = () => {
        return this.state.record.template.tags.map(x => {
            let tagModel = this.props.allTags.find(y => y.id === x);
            return (

                <Tag className="tag-instance"
                    closable
                    onClose={() => { this.removeTag(x) }}
                    color={tagModel?.color} key={tagModel?.name} >{tagModel?.name}</Tag>

            )
        }
        )
    }

    removeTag = (e: number) => {
        let selected = this.state.record.template.tags;
        for (var idx = 0; selected.length > idx; idx++) {
            var shfited = this.state.record.template.tags.shift();
            if (shfited === e) {
                break;
            } else {
                this.state.record.template.tags.push(shfited ?? 0);
            }
        }
        this.setState(
            {
                record: this.state.record
            }
        )


    }

    newTag = (e: number) => {
        if (this.state.record.template.tags.findIndex(x => x === e) >= 0) {

            return
        }
        this.state.record.template.tags.push(e);
        this.setState(
            {
                record: this.state.record
            }
        )
    }

    renderContentSchemaOptions = () => {
        return this.props.schemas.map(x => {
            return (
                <Select.Option key={x.id} value={x.id}>
                    {x.name}
                </Select.Option>)

        })
    }

    contentSchemaChanged = (v) => {
        let record = this.state.record;
        record.template.contentSchema = v;
        this.setState({
            record: record
        })
    }

    defaultContentChanged = (v) => {
        let record = this.state.record;
        record.template.defaultContent = v.target.value;
        this.setState({
            record: record
        })
    }

    onGroupChanged = async (v: number) => {
        let record = this.state.record;
        record.template.assignedGroup = v;
        let users = await this.props.loadUser(v);
        record.template.assignedUser = users[0].id;
        this.setState({
            record: record,
            users: users
        })

    }

    renderUserGroupOption = () => {
        return this.props.userGroups.map(x => (
            <Select.Option value={x.id} key={x.id} >{x.name}</Select.Option>
        ))
    }

    onUserChanged = (v: number) => {
        let record = this.state.record;
        record.template.assignedUser = this.ischecked? -v:v;
        this.setState({
            record: record
        })

    }

    renderSelectedItem = () => {
        return this.state.users.map(
            x => (
                <Select.Option value={x.id} key={x.id} username={x.name} org={x.organization} >
                    <div className="user-name">{x.name}</div>
                    <div className="user-organzation">{x.organization}</div>
                </Select.Option>

            ));

    }
    filterOption = (v: any, m: any | undefined) => {
        if (m) {
            if (m.username.indexOf(v) >= 0) {
                return true;
            }
            if (m.org.indexOf(v) >= 0) {
                return true
            }
        }
        return false;
    }

    ischecked :boolean =false

    onAssigneeToSelfChanged = (e:CheckboxChangeEvent)=>{

        //console.log(e);
        this.ischecked = e.target.checked;
        let record = this.state.record;
        record.template.assignedUser = this.ischecked?-record.template.assignedUser:record.template.assignedUser;
        this.setState({
            record :record
        })
    }


    render(): React.ReactNode {

        return (
            <div className="template-new-ticket-editor">
                <div className="meta">
                    <div>
                        <span className="title">{this.props.t("new-ticket-template.title")}</span>
                        <Input type="text" value={this.state.record.title} onChange={(v) => this.templateTitleChanged(v.target.value)}></Input>
                    </div>
                </div>
                <div className="template-def">
                    <div>
                        <span className="title">{this.props.t("new-ticket-template.ticket-title")}</span>
                        <Input type="text" value={this.state.record.template.title}
                            onChange={(v) => this.onTemplateTicketTitleChanged(v.target.value)}
                        ></Input>
                    </div>
                    <div>
                        <span className="title">{this.props.t("new-ticket-template.ticket-subtitle")}</span>
                        <Input type="text" value={this.state.record.template.subtitle}
                            onChange={(v) => this.onTemplateTicketSubtitleChanged(v.target.value)}
                        ></Input>
                    </div>
                    <div>
                        <span className="title">{this.props.t("new-ticket-template.sla")}</span>
                        <Input type="number" value={this.state.record.template.timelimit}
                            onChange={(v) => this.onTemplateTicketTimelimitChanged(Number(v.target.value))}
                        ></Input>
                    </div>
                    <div>
                        <span className="title">{this.props.t("ticket-metas.ticket-type")}</span>
                        <Select value={this.state.record.template.type}
                            onChange={this.typeChanged}
                        >
                            {this.renderTicketTypePreview()}
                        </Select>
                    </div>
                    <div>
                        <span className="title">{this.props.t("ticket-metas.ticket-source")}</span>
                        <Select value={this.state.record.template.source}
                            onChange={this.sourceChanged}
                        >
                            {this.renderTicketSourcePreview()}
                        </Select>
                    </div>
                    <div>
                        <span className="title">{this.props.t("ticket-metas.ticket-priority")}</span>
                        <Select value={this.state.record.template.priority}
                            onChange={this.priorityChanged}
                        >
                            {this.renderTicketPriorityPreview()}
                        </Select>
                    </div>
                    <div>
                        <span className="title">{this.props.t("ticket-metas.ticket-status")}</span>
                        <Select value={this.state.record.template.status}
                            onChange={this.statusChanged}
                        >
                            {this.renderTicketStatusPreview()}
                        </Select>
                    </div>

                    <div>
                        <span className="title">{this.props.t("new-ticket-template.default-group")}</span>
                        <Select style={{ width: "100%" }}
                            value={this.state.record.template.assignedGroup}
                            onChange={this.onGroupChanged}>
                            {this.renderUserGroupOption()}
                        </Select>
                    </div>

                    <div>
                        <span className="title">{this.props.t("new-ticket-template.default-assignee")}</span>
                        <Select
                            style={{ width: "100%" }}
                            value={ Math.abs(this.state.record.template.assignedUser)}
                            onChange={(v) => this.onUserChanged(v)}
                            showSearch
                            filterOption={this.filterOption}
                        >
                            {this.renderSelectedItem()}
                        </Select>

                        <Checkbox onChange={this.onAssigneeToSelfChanged} checked={this.state.record.template.assignedUser < 0} >
                            <Tooltip title={this.props.t("new-ticket-template.assignToCreatorDecription")}>{this.props.t("new-ticket-template.assignToCreator")}
                            </Tooltip>
                        </Checkbox>
                    </div>

                    <div className="field">
                        <div className="field-lable">
                            <span style={{ marginRight: 16 }}>
                                {this.props.t("tickets.tags")}
                            </span>
                            <Popover trigger="click"
                                placement="top"
                                content={<TagSelector allTags={this.props.allTags} onTagAdd={this.newTag} />}>
                                <Tag className="site-tag-plus" >
                                    <PlusOutlined />
                                </Tag>
                            </Popover>
                        </div>
                        <div className="tags-view">
                            {this.renderSeletedTag()}
                        </div>
                    </div>

                    <div>
                        <span className="title">{this.props.t("new-ticket-template.default-template")}</span>
                        <Select mode="multiple"
                            showSearch
                            value={this.state.record.template.contentSchema}
                            onChange={this.contentSchemaChanged}>
                            {this.renderContentSchemaOptions()}
                        </Select>
                    </div>

                    <div>
                        <span className="title">{this.props.t("new-ticket-template.default-content")}</span>
                        <Input.TextArea autoSize onChange={this.defaultContentChanged} value={this.state.record.template.defaultContent}></Input.TextArea>
                    </div>

                </div>

            </div>)
    }
}

export default withTranslation()(TemplateEditor);