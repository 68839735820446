import { Button, Mentions } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserModel } from "../../@mod";
import './index.less'

export interface CommentViewModel extends WithTranslation {
	onSave: (dat: string) => Promise<boolean>
	userModels: UserModel[]
}

interface CommentViewState {
	text: string
}

class CommentView extends React.Component<CommentViewModel, CommentViewState>{
	constructor(props: CommentViewModel) {
		super(props)
		this.state = {
			text: ""
		}
	}

	clean = () => {
		this.setState({
			text: ""
		})
	}
	save = () => {
		if (this.state.text.trim() !== "") {

			this.props.onSave(this.state.text.trim())
				.then(x => {
					if (x) {
						this.setState({
							text: ""
						});
					}
				});
		}
	}
	renderSelects = () => {
		return this.props.userModels.map(x => {

			return <Mentions.Option value={x.name} key={x.id.toString()}>
				<div>
					<div>{x.name}</div>
					<div>{x.organization}</div>
				</div>
			</Mentions.Option>
		});
	}



	render(): React.ReactNode {
		return (<div className="comment-view-container">
			<div className="button-bar">
				<Button type="primary" onClick={this.save}>{this.props.t("tickets.add-comment")}</Button>
				<Button type="default" onClick={this.clean}>{this.props.t("tickets.clean")}</Button>
			</div>
			<div>
				<Mentions rows={4} value={this.state.text}
					onChange={(v) => this.setState({ text: v })}
					style={{textAlign:"left"}}
				>
					{this.renderSelects()}
				</Mentions>
			</div>

		</div>)
	}
}

export default withTranslation()(CommentView);