import {Button, Form, Input, Select, Tag} from "antd";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import TextEditor, {EditorOperation} from "../../../../../components/text-editor";
import {MailTemplateModel, MailTemplateTagModel} from "../../@mod";
import "./index.less"

interface MailTemplateEditorModel extends WithTranslation {
    apiServer: ApiServer
    onFinish: (emailTemplate: MailTemplateModel) => void
    template?: MailTemplateModel
    submitting: boolean
}

interface MailTemplateEditorState {
    tags: MailTemplateTagModel[],
    displayMailEditor: boolean,
    templateContent: string,
    contentLoaded: boolean,
    loadedContent?: Promise<string>
}

class MailTemplateEditor extends React.Component<MailTemplateEditorModel, MailTemplateEditorState> {
    constructor(props: MailTemplateEditorModel) {
        super(props)
        this.state = {
            tags: [],
            displayMailEditor: false,
            templateContent: props.template?.content ?? "",
            contentLoaded: false
        }
    }

    componentDidMount(): void {
        this.loadTags()
        this.setState({
            loadedContent: this.loadTemplateContent()
        })
    }

    loadTags = () => {
        if (this.state.tags.length === 0) {
            this.props.apiServer.apiProductivity.getTagList().then(x => {
                if (x.data.data?.tagDtoList) {
                    this.setState({
                        tags: x.data.data.tagDtoList.map(t => {
                            return {
                                id: t.id ?? -1,
                                name: t.name ?? "",
                                color: t.color ?? ""
                            }
                        })
                    })
                }
            })
        }
    }

    loadTemplateContent = async (): Promise<string> => {
        if (this.props.template?.id && !this.state.contentLoaded) {
            this.setState({
                contentLoaded: true
            })
            return this.props.apiServer.apiMail.getMailTemplateContent(this.props.template.id).then(x => {
                return x.data.data?.content ?? ""
            })
        } else {
            return ""
        }
    }

    onFinish = (values) => {
        this.props.onFinish({
            id: values.id,
            title: values.title,
            content: this.state.templateContent,
            tags: values.tags
        })
    }

    setEditorOperator = (f: EditorOperation) => {
        // this.textEditor = f
    }

    setEditorEnable = (f: (b) => void) => {
        // this.textEditorEnable = f
    }

    onchange = (
        delta: any,
        oldDelta: any,
        content: any) => {
        this.setState({
            templateContent: content
        })
    }

    genContentEditor = () => {
        return (
            <div className={"quill-editor"} >
                <div className="tool-bar" style={{ display: "block" }} id={"toolbar-" + String(this.props.template?.id ?? "")} >
                    <select className="ql-header">
                        <option value={1}></option>
                        <option value={2}></option>
                        <option value={3}></option>
                        <option value={4}></option>
                        <option value={5}></option>
                        <option selected></option>
                    </select>
                    <button className="ql-bold"></button>
                    <button className="ql-italic"></button>
                    <button className="ql-underline"></button>
                    <button className="ql-strike"></button>
                    <button className="ql-blockquote"></button>
                    <button className="ql-indent" value={"+1"}></button>
                    <button className="ql-indent" value={"-1"}></button>
                    <button className="ql-list" value={"ordered"}></button>
                    <button className="ql-list" value={"bullet"}></button>
                    <button className="ql-link"></button>
                    <button className="ql-image"></button>
                    <button className="ql-clean"></button>
                </div>
                <TextEditor value={this.state.loadedContent ?? Promise.resolve("")}
                            onChanged={this.onchange}
                            containerId={String(this.props.template?.id ?? "")}
                            editorOperator={this.setEditorOperator}
                            enableOperator={this.setEditorEnable}
                />
            </div>
        )
    }

    render(): React.ReactNode {
        return (
            <div className="template-editor">
                <Form
                    onFinish={this.onFinish}
                    labelCol={{ span: 3 }}
                >
                    <Form.Item
                        label={this.props.t("mails.mail-template.fields.id")}
                        name="id"
                        initialValue={this.props.template?.id}
                        hidden
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        label={this.props.t("mails.mail-template.fields.title")}
                        name="title"
                        initialValue={this.props.template?.title}
                        rules={[
                            { required: true, message: this.props.t("mails.mail-template.required-title") }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={this.props.t("mails.mail-template.fields.content")}
                    >
                        {this.genContentEditor()}
                    </Form.Item>

                    <Form.Item
                        label={this.props.t("mails.mail-template.tags")}
                        initialValue={this.props.template?.tags?.map(t => t.id)}
                        name="tags"
                    >
                        <Select
                            mode="multiple"
                            allowClear
                        >
                            {
                                this.state.tags.map(t => {
                                    return <Select.Option key={t.id} value={t.id} children={<Tag color={t.color} children={t.name} />} />
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <div className="operation-bar">
                            <Button
                                type="primary" htmlType="submit"
                                loading={this.props.submitting}
                            >
                                {
                                    this.props.submitting
                                        ? this.props.t("mails.mail-template.submitting")
                                        : this.props.t("mails.mail-template.submit")
                                }
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div >
        )
    }
}

export default withTranslation()(MailTemplateEditor)