import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import ServiceListView from "../component/service-list";
import { convertServiceDtoToModel, convertTaskDtoToModel, SERVICE_STATUS, ServiceModel, TaskModel } from "../@mod";
import { Button, message, Popconfirm } from "antd";
import { RouteContext, withRouter } from "../../../utils";

interface ServiceSubscribedViewModel extends WithTranslation {
    apiServer: ApiServer
    router?: RouteContext
}

interface ServiceSubscribedViewState {
    serviceList: ServiceModel[]
    taskList: TaskModel[]
    showServiceDetails: boolean
    selectedService?: ServiceModel
    dataLoading: boolean
    routerStateLoaded: boolean
}

class ServiceSubscribedView extends React.Component<ServiceSubscribedViewModel, ServiceSubscribedViewState> {
    constructor(props: ServiceSubscribedViewModel) {
        super(props);
        this.state = {
            serviceList: [],
            taskList: [],
            showServiceDetails: false,
            dataLoading: false,
            routerStateLoaded: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    onDetail = (model: ServiceModel) => {
        this.setState({
            showServiceDetails: true,
            selectedService: model
        })
        this.loadTaskList(model)
    }

    loadTaskList = (model: ServiceModel) => {
        if (model) {
            this.props.apiServer.apiProjectInstance.getItemInstanceListOfServiceInstance(model.id).then(r => {
                if (r.data.data?.dtos) {
                    let dtos = r.data.data.dtos;
                    let serviceItemList = dtos.map((dto) => convertTaskDtoToModel(dto))
                    this.setState({
                        taskList: serviceItemList
                    })
                }
            })
        }
    }

    loadData = () => {
        this.setState({
            dataLoading: true
        })
        this.props.apiServer.apiProjectInstance.getSubscribedProjectInstance().then(r => {
            if (r.data.data?.dtos) {
                let serviceList = r.data.data.dtos.map(model => convertServiceDtoToModel(model))
                serviceList = [
                    ...serviceList.filter(d => d.status.code === SERVICE_STATUS.APPROVAL_FAILED),
                    ...serviceList.filter(d => d.status.code === SERVICE_STATUS.DRAFT),
                    ...serviceList.filter(d => d.status.code === SERVICE_STATUS.UNDER_APPROVAL),
                    ...serviceList.filter(d => d.status.code !== SERVICE_STATUS.UNDER_APPROVAL && d.status.code !== SERVICE_STATUS.DRAFT && d.status.code !== SERVICE_STATUS.APPROVAL_FAILED)
                ]
                const state: any = this.props.router?.location?.state
                if (state && !this.state.routerStateLoaded) {
                    this.setState({
                        routerStateLoaded: true
                    })
                    let serviceIdToShow = state.id
                    let serviceToShow = serviceList.filter(d => d.id === serviceIdToShow);
                    if (serviceToShow && serviceToShow.length > 0) {
                        this.onDetail(serviceToShow[0])
                    }
                }
                this.setState({
                    serviceList: serviceList
                })
            }
        }).finally(() => {
            this.setState({
                dataLoading: false
            })
        })
    }

    genServiceOperationExtra = () => {
        const serviceStatus = this.state.selectedService?.status.code
        let cannotSubmit =
            (serviceStatus !== SERVICE_STATUS.DRAFT && serviceStatus !== SERVICE_STATUS.APPROVAL_FAILED)
            ||
            this.state.taskList.filter(d => {
                let statusCode = Number(d.status["code"])
                return Number(d.type.code) > 1000 && (statusCode === SERVICE_STATUS.PROCESSING || statusCode === SERVICE_STATUS.REDOING)
            }).length > 0;
        let isDraft = serviceStatus === SERVICE_STATUS.DRAFT
        let operationBar = (
            <Popconfirm title={this.props.t("project.service.submit-application-confirm")} onConfirm={this.submitServiceApplication} disabled={cannotSubmit}>
                <Button
                    type={"primary"}
                    disabled={cannotSubmit}
                >{this.props.t("project.service.submit-application")}</Button>
            </Popconfirm>
        )

        if (isDraft) {
            operationBar = (
                <>
                    <Popconfirm title={this.props.t("project.service.delete-draft-confirm")} onConfirm={this.deleteDraft}>
                        <Button
                            type={"primary"}
                            danger
                        >{this.props.t("project.service.delete-draft")}</Button>
                    </Popconfirm>
                    {operationBar}
                </>
            )
        }

        return (<>
            {operationBar}
        </>)
    }

    submitServiceApplication = () => {
        let serviceId = this.state.selectedService?.project.id
        if (serviceId) {
            let requestData = {
                id: this.state.selectedService?.id,
                commit: true
            }
            this.props.apiServer.apiProjectMeta.postSubscribeService(serviceId, requestData)
                .then(r => {
                    if (r.data.data?.success) {
                        this.loadData()
                        this.setState({
                            showServiceDetails: false
                        })
                        message.success(this.props.t("project.service.submit-application-success")).then()
                    } else {
                        message.error(this.props.t("project.service.submit-application-fail")).then()
                    }
                })
        }
    }

    deleteDraft = () => {
        if (this.state.selectedService) {
            this.props.apiServer.apiProjectInstance.removeDraft(this.state.selectedService.id).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("project.service.delete-draft-success")).then()
                    this.setState({
                        showServiceDetails: false
                    })
                    this.componentDidMount()
                } else {
                    message.error(this.props.t("project.service.delete-draft-fail")).then()
                }
            })
        }
    }

    render() {
        return (
            <div>
                <ServiceListView
                    serviceLoading={this.state.dataLoading}
                    apiServer={this.props.apiServer}
                    serviceList={this.state.serviceList}
                    headerSubTitle={this.props.t("project.service.subscribe-sub-title")}
                    headerTitle={this.props.t("project.service.subscribe-title")}
                    genServiceOperationExtra={this.genServiceOperationExtra}
                    taskList={this.state.taskList}
                    onDetail={this.onDetail}
                    reloadTaskList={this.loadTaskList}
                    showServiceDetails={this.state.showServiceDetails}
                    closeServiceDetailView={() => this.setState({ showServiceDetails: false })}
                    selectedService={this.state.selectedService}
                />
            </div>
        );
    }
}

export default withRouter(withTranslation()(ServiceSubscribedView))