import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import "./index.less"
import { Button, Card, Input, List, message, Modal, PageHeader } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProjectMetaDataModel, ProjectProcessModel } from "../@mod";
import ProjectSubscribeView from "../component/project-subscribe";
import ProjectDetailView from "../component/project-detail";
import { RouteContext, withRouter } from "../../../utils";
import { PostServiceInstanceSubscribeServiceResponse } from "../../../api";


interface ServiceMarketModel extends WithTranslation {
    apiServer: ApiServer
    router?: RouteContext
}

interface ServiceMarketState {
    projectList: ProjectMetaDataModel[],
    shownProjectList: ProjectMetaDataModel[],
    notShownProjectList: ProjectMetaDataModel[],
    showProjectDetail: boolean,
    showProjectApply: boolean,
    processOfShownProject: ProjectProcessModel[],
    projectToShow?: ProjectMetaDataModel,
    inputtedSearchContent: string
}

let projectProcessKey = 1;

class ServiceMarket extends React.Component<ServiceMarketModel, ServiceMarketState> {
    constructor(props: ServiceMarketModel) {
        super(props);
        this.state = {
            projectList: [],
            shownProjectList: [],
            notShownProjectList: [],
            showProjectDetail: false,
            showProjectApply: false,
            processOfShownProject: [],
            inputtedSearchContent: ""
        }
    }

    convertProjectMetaDataModelToString = (model: ProjectMetaDataModel): string => {
        return model.id + ","
            + model.createdByName + ","
            + model.createdTime + ","
            + model.description + ","
            + model.owner + ","
            + model.ownerName + ","
            + model.status + ","
            + model.template + ","
            + model.title + ","
            + model.updateBy + ","
            + model.updateByName + ","
            + model.updateByTime + ","
            + model.version
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        this.props.apiServer.apiProjectMeta.getMetaDataList(
            undefined, undefined, undefined, 1, undefined, undefined, undefined
        ).then(r => {
            if (r.data.data?.list) {
                let projectList: ProjectMetaDataModel[] = r.data.data.list?.map((metaData): ProjectMetaDataModel => {
                    return {
                        createdBy: metaData.createdBy ?? 0,
                        createdByName: metaData.createdByName ?? "",
                        createdTime: metaData.createdTime ?? "",
                        description: metaData.description ?? "",
                        id: metaData.id ?? 0,
                        owner: metaData.owner ?? 0,
                        ownerName: metaData.ownerName ?? "",
                        status: metaData.status ?? {},
                        template: metaData.template ?? "",
                        title: metaData.title ?? "",
                        updateBy: metaData.updatedBy ?? 0,
                        updateByName: metaData.updatedByName ?? "",
                        updateByTime: metaData.updatedTime ?? "",
                        version: metaData.version ?? ""
                    }
                });
                let tem = [...projectList]
                let defaultCount = 4
                let initCount = tem.length > defaultCount ? defaultCount : tem.length
                this.setState({
                    projectList: projectList,
                    notShownProjectList: tem.slice(initCount),
                    shownProjectList: tem.slice(0, initCount)
                })
            }
        })
    }

    mapToProcessModel = (toHandle): ProjectProcessModel => {
        let children: ProjectProcessModel[] = []
        if (toHandle.children) {
            for (const child in toHandle.children) {
                children.push(this.mapToProcessModel(toHandle.children[child]))
            }
        }
        return {
            children: children,
            description: toHandle.description,
            key: "" + projectProcessKey++,
            title: toHandle.title
        }
    }

    onDetail = (model: ProjectMetaDataModel) => {
        projectProcessKey = 1
        let processTemplate = JSON.parse(model.template);
        let processOfShownProject: ProjectProcessModel[] = []
        if (processTemplate && processTemplate.milestones) {
            let milestones = processTemplate.milestones
            for (const milestonesIndex in milestones) {
                let projectProcessModel = this.mapToProcessModel(milestones[milestonesIndex]);
                processOfShownProject.push(projectProcessModel)
            }
        }
        let root = {
            children: processOfShownProject,
            description: "项目服务流程",
            key: 0 + "",
            title: "项目服务流程"
        }
        this.setState({
            projectToShow: model,
            showProjectDetail: true,
            processOfShownProject: [root]
        })
    }

    genProjectCardExtra = (item: ProjectMetaDataModel) => {
        return (
            <div>
                <Button type={"primary"} onClick={() => { this.onDetail(item) }}>{this.props.t("project.service.view-details")}</Button>
            </div>
        )
    }

    renderItem = (item: ProjectMetaDataModel, index: number) => {
        return (
            <Card title={item.title} style={{ marginTop: "8px" }} extra={this.genProjectCardExtra(item)}>
                <div>
                    <ProjectDetailView
                        projectProcess={[]}
                        projectToShow={item}
                        disableEditor={true}
                    />
                </div>
            </Card>
        )
    }

    loadMore = () => {
        let loadCount = 4
        this.setState({
            shownProjectList: [...this.state.shownProjectList, ...this.state.notShownProjectList.slice(0, loadCount)],
            notShownProjectList: this.state.notShownProjectList.slice(loadCount)
        })
    }

    onProjectSubscribeSuccess = (response: PostServiceInstanceSubscribeServiceResponse) => {
        message.success(this.props.t("project.service.subscribe-success")).then()
        this.closeProjectSubscribeView()
        this.closeProjectDetailsView()
        let serviceInstanceId = response.serviceInstanceId
        this.props.router?.navigate("/service/subscribed", { state: { id: serviceInstanceId } })
    }

    onProjectSubscribeFail = () => {
        message.success(this.props.t("project.service.subscribe-fail")).then()
        this.closeProjectSubscribeView()
    }

    closeProjectSubscribeView = () => {
        this.setState({
            showProjectApply: false
        })
    }
    closeProjectDetailsView = () => {
        this.setState({
            showProjectDetail: false
        })
    }

    genProjectDetailFooter = () => {
        return (
            <div>
                <Button type={"primary"} onClick={this.navigateToApplyProject}>{this.props.t("project.service.subscribe")}</Button>
            </div>
        )
    }

    navigateToApplyProject = () => {
        this.setState({
            showProjectApply: true
        })
    }

    search = () => {
        let searchedProject = this.state.projectList.filter(
            pro => this.convertProjectMetaDataModelToString(pro).toLowerCase().includes(this.state.inputtedSearchContent.toLowerCase()));
        let defaultCount = 4
        let initCount = searchedProject.length > defaultCount ? defaultCount : searchedProject.length
        this.setState({
            notShownProjectList: searchedProject.slice(initCount),
            shownProjectList: searchedProject.slice(0, initCount)
        })
    }

    searchContentChange = (event) => {
        this.setState({
            inputtedSearchContent: event.target.value
        })
    }

    render() {
        return (
            <div>
                <PageHeader title={this.props.t("project.service.market-title")} subTitle={this.props.t("project.service.market-sub-title")}>
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <div style={{ width: "80%" }}>
                            <Input placeholder={this.props.t("project.service.input-keyword")} onChange={(e) => this.searchContentChange(e)} />
                        </div>
                        <div>
                            <Button onClick={this.search}>{this.props.t("project.service.search")}</Button>
                        </div>

                    </div>
                    <div
                        id="scrollableDiv"
                        style={{
                            height: 600,
                            overflow: 'auto',
                            padding: '0 16px',
                            marginTop: '16px'
                        }}
                    >
                        <InfiniteScroll
                            next={this.loadMore}
                            hasMore={this.state.notShownProjectList.length > 0}
                            loader={<div>loading</div>}
                            dataLength={this.state.shownProjectList.length}
                            endMessage={"no more"}
                            scrollableTarget="scrollableDiv"
                        >
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <List
                                    dataSource={this.state.shownProjectList.filter((d, i) => i % 2 === 0)}
                                    renderItem={this.renderItem}
                                    style={{ width: '50%' }}
                                />
                                <List
                                    dataSource={this.state.shownProjectList.filter((d, i) => i % 2 === 1)}
                                    renderItem={this.renderItem}
                                    style={{ marginLeft: '16px', width: '50%' }}
                                />
                            </div>

                        </InfiniteScroll>
                    </div>
                </PageHeader>

                <Modal
                    visible={this.state.showProjectApply}
                    onCancel={() => this.setState({ showProjectApply: false })}
                    destroyOnClose={true}
                    footer={null}
                >
                    <div className="padding-16px">
                        <ProjectSubscribeView
                            projectToApply={this.state.projectToShow?.id}
                            apiServer={this.props.apiServer}
                            onSuccess={this.onProjectSubscribeSuccess}
                            onFail={this.onProjectSubscribeFail}
                        />
                    </div>

                </Modal>

                <Modal
                    visible={this.state.showProjectDetail}
                    onCancel={() => this.setState({ showProjectDetail: false })}
                    footer={this.genProjectDetailFooter()}
                    destroyOnClose={true}
                >
                    <div className="padding-16px">
                        <ProjectDetailView
                            projectToShow={this.state.projectToShow}
                            projectProcess={this.state.processOfShownProject}
                            disableEditor={true}
                        />
                    </div>

                </Modal>
            </div>
        );
    }
}

export default withRouter(withTranslation()(ServiceMarket))