import {ActionEventHandler} from "../../../../components";
import {ColumnType} from "antd/lib/table";
import ColumnTextFilter from "../../../../components/table/column-text-filter";
import {Button, Popconfirm} from "antd";
import moment from "moment-timezone";


export interface MailApprovalUserModel {
    id: number,
    fullName: string
}

export interface MailApprovalModel {
    id: number,
    mailBox: string,
    mailBoxId: number,
    emailTitle: string,
    emailDesc: string,
    creator: MailApprovalUserModel,
    createTime: string,
    updater: MailApprovalUserModel,
    updateTime: string

}

export const mailApprovalColumnProvider = (translator: (r: string) => string, handler: ActionEventHandler<MailApprovalModel>): ColumnType<MailApprovalModel>[] => {
    return [
        {
            dataIndex: "id",
            key: "id",
            title: translator("mails.approval.id"),
            render: (value, record, index) => {
                return "#" + record.id
            },
            filterSearch: true,
            onFilter: (value, record) => record.id === Number(value),
            filterDropdown(props) {
                return (<ColumnTextFilter title={translator("mails.approval.id")} context={props}/>)
            }
        },
        {
            dataIndex: "mailBox",
            key: "mailBox",
            title: translator("mails.approval.mailBox"),
            filterSearch: true,
            onFilter: (value, record) => {
                return record.mailBox === value
            },
            filters: [{text: "1", value: "1"}, {text: "2", value: "2"}],
        },
        {
            dataIndex: "emailTitle",
            key: "emailTitle",
            title: translator("mails.approval.emailTitle"),
            filterSearch: true,
            onFilter: (value, record) => {
                return record.emailTitle.toLowerCase().includes(String(value).toLowerCase())
            },
            filterDropdown(props) {
                return <ColumnTextFilter title={translator("mails.approval.emailTitle")} context={props}/>
            }
        },
        {
            dataIndex: "emailDesc",
            key: "emailDesc",
            title: translator("mails.approval.emailDesc"),
            filterSearch: true,
            onFilter: (value, record) => record.emailDesc?.toLowerCase().includes(String(value).toLowerCase()),
            filterDropdown(props) {
                return <ColumnTextFilter context={props} title={translator("mails.approval.emailDesc")}/>
            }
        },
        {
            dataIndex: "creator",
            key: "creator",
            title: translator("mails.approval.creator"),
            render: (value, record) => {
                return record.creator.fullName
            },
            filterSearch: true,
            onFilter: (value, record) => record.creator.fullName.toLowerCase().includes(String(value)),
            filterDropdown(props) {
                return <ColumnTextFilter context={props} title={translator("mails.approval.creator")}/>
            }
        },
        {
            dataIndex: "createTime",
            key: "createTime",
            title: translator("mails.approval.createTime"),
            render: (value, record) => {
                return moment.parseZone(record.createTime).format("YYYY-MM-DD HH:mm:ss")
                // return record.createTime
            }
        },
        {
            dataIndex: "updater",
            key: "updater",
            title: translator("mails.approval.updater"),
            render: (value, record) => {
                return record.updater.fullName
            },
            filterSearch: true,
            onFilter: (value, record) => record.updater.fullName.toLowerCase().includes(String(value)),
            filterDropdown(props) {
                return <ColumnTextFilter context={props} title={translator("mails.approval.updater")}/>
            }
        },
        {
            dataIndex: "updateTime",
            key: "updateTime",
            title: translator("mails.approval.updateTime"),
            render: (value, record) => {
                return moment.parseZone(record.updateTime).format("YYYY-MM-DD HH:mm:ss")
                // return record.updateTime
            }
        },
        {
            dataIndex: "operation",
            key: "operation",
            title: translator("mails.approval.operation"),
            render: (value, record, index) => {
                return (
                    <div className={"operation-bar"}>
                        <Button
                            type={"primary"}
                            size={"small"}
                            onClick={() => handler(record, "detail")}
                            children={translator("mails.approval.view-detail")}
                        />

                        <Popconfirm
                            title={translator("mails.approval.confirm-approve")}
                            onConfirm={() => handler(record, "approve")}
                        >
                            <Button
                                children={translator("mails.approval.approve")}
                                size={"small"}
                            />
                        </Popconfirm>

                        <Popconfirm
                            title={translator("mails.approval.confirm-reject")}
                            onConfirm={() => handler(record, "reject")}
                        >
                            <Button
                                danger={true}
                                children={translator("mails.approval.reject")}
                                size={"small"}
                            />
                        </Popconfirm>

                    </div>
                )
            }
        }

    ]
}