import {Button, Input, message, Popconfirm} from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { SettingItem } from "../@mod";
import './index.less'
import ApiServer from "../../../../ApiServer";

export interface SettingBoxViewModel extends WithTranslation {
	item: SettingItem
	onChange: (before: string, after: string) => void
	operator ?:(o:SettingBoxOperator) =>void
	apiServer: ApiServer
}

export interface SettingBoxOperator {
	reset : ()=>void
	setValue: (string) =>void
}

interface SettingBoxState {
	value: string,
	preValue: string
}


class SettingBox extends React.Component<SettingBoxViewModel, SettingBoxState>{

	constructor(props: SettingBoxViewModel) {
		super(props)
		this.state = {
			value: props.item.value,
			preValue: props.item.value
		}
	}

	componentDidMount() {
		this.props.operator?.(
			{
				setValue:this.onValueChange,
				reset: this.reset
			}
		)
	}

	onValueChange = (v: string) => {
		this.props.onChange(this.state.value, v);
		this.setState({
			value: v,
		})
	}

	saveSystemConfig = () => {
		this.props.apiServer.apiSystem.putSystemSettings({
			changedKey: this.props.item.key,
			changedValue: this.state.value
		}).then(r => {
			if (r.data.data?.success) {
				this.setState({
					preValue: this.state.value
				})
				message.success(this.props.t("system-setting.save-success")).then(() => {
				})
				return
			}
			message.error(this.props.t("system-setting.save-failed")).then(() => {});
		});
	};

	resetSystemConfig = () => {
		this.props.apiServer.apiSystem.putResetSystemSettings(this.props.item.key).then(r => {
			if (r.data.data?.success) {
				let defaultVal: string = r.data.data?.defaultValue??""
				this.setState({
					value: defaultVal,
					preValue: defaultVal
				})
				message.success(this.props.t("system-setting.reset-success")).then(() => {})
				return
			}
			message.error((this.props.t("system-setting.reset-failed"))).then(() => {})
		})
	}

	reset = () => {
		this.props.onChange(this.state.value, this.props.item.value);
		this.setState({
			value: this.props.item.value
		})
	}

	render(): React.ReactNode {
		return (<div className="setting-box-container">
			<div className="title">
				{this.props.item.attribute[this.props.i18n.language]}
			</div>
			<div className="box">
				<Input value={this.state.value} onChange={(t)=>this.onValueChange(t.target.value)}/>
				<Button type="primary"
						className="button"
						disabled={this.state.preValue === this.state.value}
						onClick={this.saveSystemConfig}
				>
					{(this.props.t("system-setting.save"))}
				</Button>
				<Popconfirm
					title={this.props.t("system-setting.confirm-reset-title")}
					onConfirm={this.resetSystemConfig}
				>
					<Button type="ghost" className="button" >{(this.props.t("system-setting.reset"))}</Button>
				</Popconfirm>
			</div>
			<div className="key">
				{this.props.item.key}
			</div>
		</div>
		)
	}
}

export default withTranslation()(SettingBox);