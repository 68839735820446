import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { WithTranslation } from "react-i18next";
import { TicketThreadDto, UserDto } from "../../../../api";
import ApiServer from "../../../../ApiServer";
import { ActionEventHandler } from "../../../../components";
import ColumnTextFilter from "../../../../components/table/column-text-filter";
import { UserModel } from "../../@mod";



export interface ChangedModel {
	type: string,
	before: number,
	after: number
}


export interface SourceModel {
	id: number,
	name: string,
	description: any
}


export interface TicketTypeModel {
	id: number,
	name: string,
	description: any
}

export interface StatusModel {
	id: number,
	name: string,
	description: any
}

export interface PriorityModel {
	id: number,
	name: string,
	description: any
}


export interface FileModel {
	key?: number,
	id: number,
	fileName: string,
	uploadTime: string,
	creatorName:string,
	driver: {
		[key: string]: string
	}
}

export interface PlaybookItemModel {
	subject: string,
	detail: string,
	sequence: number
}

export interface PlaybookModel {
	name: string,
	description: string,
	playbookItems: PlaybookItemModel[]
}

export interface TicketContentModel {
	id: number
	schema: any,
	uiSchema: any,
	content: any
}

export interface ThreadModel {
	id: number,
	ticketId: number,
	type: string,
	message: string,
	createdBy: UserModel
	createdAt: string
	bookmarked: boolean
}


export const converThreadDtoToModel = (dto: TicketThreadDto): ThreadModel => {
	let m: ThreadModel = {
		id: dto.id ?? 0,
		ticketId: dto.ticketId ?? 0,
		type: dto.threadType ?? "",
		message: dto.message ?? "",
		createdBy: {
			id: dto.createdBy?.id ?? 0,
			name: (dto.createdBy?.fullName??"") + "(@" +(dto.createdBy?.name ?? "0") + ")",
			organization: dto.createdBy?.organization ?? ""
		},
		createdAt: dto.createdTime ?? "",
		bookmarked: dto.bookMarked ?? false
	}

	return m;
}





export const loadTicketSource = async (apiServer: ApiServer): Promise<SourceModel[]> => {
	return apiServer.apiMeta.getTicketSourceList(false)
		.then(
			x => {
				return x.data.data?.list?.map(y => {
					let i: SourceModel = {
						id: y.id ?? 0,
						name: y.name ?? "",
						description: y.lang
					};
					return i;

				}) || []
			}
		);
}

export const loadTicketPriority = async (apiServer: ApiServer): Promise<PriorityModel[]> => {
	return apiServer.apiMeta.getTicketPriority(false)
		.then(
			x => {
				return x.data.data?.list?.map(y => {
					let i: PriorityModel = {
						id: y.id ?? 0,
						name: y.name ?? "",
						description: y.lang
					};
					return i;

				}) || []
			}
		);
}

export const loadTicketType = async (apiServer: ApiServer): Promise<TicketTypeModel[]> => {
	return apiServer.apiMeta.getTicketTypeList(false)
		.then(
			x => {
				return x.data.data?.list?.map(y => {
					let i: TicketTypeModel = {
						id: y.id ?? 0,
						name: y.name ?? "",
						description: y.description
					};
					return i;

				}) || []
			}
		);
}

export const loadTicketStatus = async (apiServer: ApiServer,closePermissionFilter:boolean): Promise<StatusModel[]> => {
	return apiServer.apiMeta.getTicketStatusList(false,!closePermissionFilter)
		.then(
			x => {
				return x.data.data?.list?.map(y => {
					let i: StatusModel = {
						id: y.id ?? 0,
						name: y.name ?? "",
						description: y.description
					};
					return i;

				}) || []
			}
		);
}



export const columnsProviderUser = (i18n: WithTranslation, handler: ActionEventHandler<UserModel>): ColumnType<UserModel>[] => {
	let translater = i18n.t;
	return[
		{
			"title":translater("tickets.user-name"),
			"dataIndex":"name",
			"key":"name"
		},{
			"title":translater("tickets.org"),
			"dataIndex":"organization",
			"key":"organization"
		}
	]
}



export const columnsProvider = (i18n: WithTranslation, handler: ActionEventHandler<FileModel>): ColumnType<FileModel>[] => {

	let translater = i18n.t;
	return [
		{

			title: translater("tickets.attachment.file-name"),
			dataIndex: 'fileName',
			key: 'fileName',
			render: (text: string, record: FileModel) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value: string | number | boolean, record: FileModel) => record.fileName.indexOf(value.toString()) >= 0,
			filters: [],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("tickets.attachment.file-name")} />)
			}
		},
		{

			title: translater("tickets.attachment.storage-type"),
			dataIndex: 'driver',
			key: 'driver',
			render: (d: any, record: FileModel) => {
				console.log(d);
				return (<div>
					{d[i18n.i18n.language]}
				</div>)
			}
		},
		{

			title: translater("tickets.attachment.upload-time"),
			dataIndex: 'uploadTime',
			key: 'uploadTime',
			render: (d: string, record: FileModel) => {
				return (<div>
					{moment(d).format(i18n.t("tickets.time-pattern-long"))}
				</div>)
			}
		},
		{

			title: translater("tickets.attachment.creator"),
			dataIndex: 'creatorName',
			key: 'creatorName'
		},
		
		{
			title: translater("tickets.attachment.action"),
			key: 'action',
			render: (text: string, record: FileModel) =>
				<Button onClick={() => handler(record, "delete")} danger >{translater("tickets.attachment.delete")}</Button>
		}
	]
}