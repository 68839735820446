import { Button, Card, Select } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import UserSelector from "../../user-selector";
import { loadUserGroups, UserGroupModel, UserModel } from "../../@mod";
import './index.less';


interface AssignToViewModel extends WithTranslation {
	apiServer: ApiServer
	user?: UserModel,
	userGroup?: UserGroupModel
	onAssigned?: (groupId:number,userId:number) => void
	cancel? :()=>void
}

interface AssignToViewState {
	selectedUser: number,
	selectedUserGroup: number
	usersInGroup: UserModel[]
	userGroups: UserGroupModel[]
}


class AssignToView extends React.Component<AssignToViewModel, AssignToViewState>{

	constructor(props: AssignToViewModel) {
		super(props)

		this.state = {
			selectedUser: this.props.user?.id??0,
			selectedUserGroup: this.props.userGroup?.id??0,
			usersInGroup: [],
			userGroups: []
		}

	}

	loadData = async () => {

		let admin = await this.props.apiServer.apiSystem.getLoginUser()
		.then(
			x => {
				return x.data.data?.admin ?? false;

			}
		)
		await loadUserGroups(this.props.apiServer,true)
		.then(
			x=>{
				if(!admin){
					x = x.filter(x=>x.name==="Default")
				}	
				this.setState({userGroups:x})
			})

			
		await this.userGroupChange(this.props.userGroup?.id??0)


		
	}
	l: Promise<void> | undefined
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(() => {
					this.l = undefined
				})
		}
	}

	getUserGroupOptions = () => {
		
		return this.state.userGroups.map(
			x => {
				return (<Select.Option value={x.id} key={x.id}>
					{x.name}
				</Select.Option>)
			}

		)
	}

	 userGroupChange= async (val:number)=>{
		this.props.apiServer.apiGroup.getUserGroup(val,true,false,false,true)
		.then(x=>{
			let users :UserModel[]=[];
			x.data.data?.users?.forEach(x=>{
				users.push({
					id:x.id??0,
					name: (x.fullName ?? "") + (x.active?"(@" + x.name + ")":" ### 已离职 ###"),
					organization:x.organization??"",
					active:x.active
				})
			})
			this.setState({
				usersInGroup:users,
				selectedUserGroup:val,
				selectedUser:users.length>0?users[0].id:0
			})
		})
	}
	userChanged=(val:number,option:UserModel|UserModel[])=>{
		this.setState({
			selectedUser:val
		})
	}

	getSnapshotBeforeUpdate(preProps:AssignToViewModel,preState:AssignToViewState){
		if(this.state.selectedUser===0){
			if(this.props.user){
				this.setState({selectedUser:this.props.user.id})
			}
		}
	}


	saveClicked =()=>{
		this.props.onAssigned?.(this.state.selectedUserGroup,this.state.selectedUser);
	}

	filterOption (v:string,u:UserModel|undefined){
		if(u){
			return u.name.indexOf(v)>=0
		}
		return false;
	}

	

	render(): React.ReactNode {
		return (
			<Card title={this.props.t("tickets.assign-to")}
			extra={
				[
					<Button type="default" danger key={1} onClick={this.props.cancel}> {this.props.t("tickets.details.cancel")}</Button>,
					<Button type="primary" key={2} onClick={this.saveClicked}> {this.props.t("tickets.details.save")}</Button>
				]
			}
			>
				<div className="assign-to-view">
					<div className="field" >
						<div className="title">{this.props.t("tickets.assigned-group")}</div>
						<Select value={this.state.selectedUserGroup} className="selector" onChange={this.userGroupChange}
						style={ {width:450}} >
							{this.getUserGroupOptions()}
						</Select>
					</div>
					<div className="field" >
						<div className="title">{this.props.t("tickets.assigned")}</div>
						<div	style={ {width:450}} >
						<UserSelector users={this.state.usersInGroup} 
							defaultSelected={this.state.usersInGroup.filter(x=>x.id===this.state.selectedUser)[0]} 
							onSelected={(v)=>this.setState({selectedUser:v.id})}
							></UserSelector>
						</div>
					</div>
				</div>
			</Card>
		)
	}
}

export default withTranslation()(AssignToView);