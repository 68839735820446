

/*
export function arrayBufferToString(buffer:ArrayBuffer) {
    //return String.fromCharCode.apply(null, new Uint8Array(buffer))
}*/


export function ab2str( buffer ) {
	var binary = '';
	var bytes = new Uint8Array( buffer );
	var len = bytes.byteLength;
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode( bytes[ i ] );
	}
	return window.btoa( binary );
}



export function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

export function base64ToArrayBuffer(str) {

    var binaryString = window.atob(str);
    return str2ab(binaryString);
}

export function baseDecode(str){
    return window.atob(str)
}
