import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import ServiceListView from "../component/service-list";
import { convertServiceDtoToModel, convertTaskDtoToModel, ServiceModel, SERVICE_STATUS, TaskModel } from "../@mod";
import { RouteContext, withRouter } from "../../../utils";

interface ServiceLaunchedViewModel extends WithTranslation {
    apiServer: ApiServer
    router?: RouteContext
}

interface ServiceLaunchedViewState {
    serviceList: ServiceModel[]
    taskList: TaskModel[]
    showServiceDetails: boolean
    selectedService?: ServiceModel
    dataLoading: boolean
    routerStateLoaded: boolean
}

class ServiceLaunchedView extends React.Component<ServiceLaunchedViewModel, ServiceLaunchedViewState> {
    constructor(props: ServiceLaunchedViewModel) {
        super(props);
        this.state = {
            serviceList: [],
            taskList: [],
            showServiceDetails: false,
            dataLoading: false,
            routerStateLoaded: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        this.setState({
            dataLoading: true
        })
        this.props.apiServer.apiProjectInstance.getProjectInstanceByParticipantAndParticipantType(1).then(r => {
            if (r.data.data?.dtos) {
                let serviceList = r.data.data.dtos.map(model => convertServiceDtoToModel(model))
                const state: any = this.props.router?.location?.state
                if (state && !this.state.routerStateLoaded) {
                    this.setState({
                        routerStateLoaded: true
                    })
                    let serviceIdToShow = state.id
                    let serviceToShow = serviceList.filter(d => d.id === serviceIdToShow);
                    if (serviceToShow && serviceToShow.length > 0) {
                        this.onDetail(serviceToShow[0])
                    }
                }
                this.setState({
                    serviceList: this.unFinishedFirst(serviceList)
                })
            }
        }).finally(() => {
            this.setState({
                dataLoading: false
            })
        })
    }

    unFinishedFirst = (list: ServiceModel[]): ServiceModel[] => {
        return [
            ...list.filter(t => SERVICE_STATUS.isUnfinished(t.status.code)),
            ...list.filter(t => !SERVICE_STATUS.isUnfinished(t.status.code))
        ]
    }

    onDetail = (model: ServiceModel) => {
        this.setState({
            showServiceDetails: true,
            selectedService: model
        })
        this.loadTaskList(model)
    }

    loadTaskList = (model: ServiceModel) => {
        if (model) {
            this.props.apiServer.apiProjectInstance.getItemInstanceListOfServiceInstance(model.id).then(r => {
                if (r.data.data?.dtos) {
                    let dtos = r.data.data.dtos;
                    let serviceItemList = dtos.map((dto) => convertTaskDtoToModel(dto))
                    this.setState({
                        taskList: serviceItemList
                    })
                }
            })
        }
    }

    genServiceOperationExtra = () => {
        return (<></>)
    }

    render() {
        return (
            <div>
                <ServiceListView
                    serviceLoading={this.state.dataLoading}
                    apiServer={this.props.apiServer}
                    serviceList={this.state.serviceList}
                    headerSubTitle={this.props.t("project.service.launched-sub-title")}
                    headerTitle={this.props.t("project.service.launched-title")}
                    genServiceOperationExtra={this.genServiceOperationExtra}
                    taskList={this.state.taskList}
                    onDetail={this.onDetail}
                    reloadTaskList={this.loadTaskList}
                    showServiceDetails={this.state.showServiceDetails}
                    closeServiceDetailView={() => this.setState({ showServiceDetails: false })}
                    selectedService={this.state.selectedService}
                />
            </div>
        );
    }
}

export default withRouter(withTranslation()(ServiceLaunchedView))