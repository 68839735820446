import { message, PageHeader, Tabs } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { loadMyMailBoxes, MailBoxModel, myMailBoxColumnProvider } from "../../@mod";
import MailBoxList from '../../box-list'
import { loadEmailTemplate, MailTemplateModel } from "../@mod";
import "./index.less"
import MailTemplateList from "../component/template-list"
import BindMailTemplateView from "../component/bind-template"

interface MailTemplateBindingViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface MailTemplateBindingViewState {
    activeView: string,
    dataLoading: boolean,
    mailBoxes: MailBoxModel[],
    mailBoxesOnView: MailBoxModel[],
    templates: MailTemplateModel[]

}

class MailTemplateBindingView extends React.Component<MailTemplateBindingViewModel, MailTemplateBindingViewState> {

    constructor(props: MailTemplateBindingViewModel) {
        super(props)
        this.state = {
            activeView: "default",
            dataLoading: false,
            mailBoxes: [],
            mailBoxesOnView: [],
            templates: []
        }
    }
    loadData = async () => {
        this.setState(
            {
                mailBoxes: [],
                mailBoxesOnView: []
            }
        )
        var mailBoxes = await loadMyMailBoxes(this.props.apiServer)
        this.setState(
            {
                mailBoxes: mailBoxes,
                mailBoxesOnView: []
            }
        )
    }

    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.setState({
                        dataLoading: false
                    })
                })
        }
        this.loadTemplates()
    }

    loadTemplates = () => {
        loadEmailTemplate(false, this.props.apiServer).then(x => {
            let templates = x
            this.setState({
                templates: templates
            })
        })

    }

    onNavigate = (box: MailBoxModel) => {
        let onView = this.state.mailBoxesOnView
        if (onView.findIndex(x => x.id === box.id) >= 0) {
            this.activeKey(String(box.id))
        } else {
            onView.push(box);
            this.setState({
                mailBoxesOnView: onView
            })
        }
    }

    afterEdit = () => { }
    afterDelete = () => { }
    afterBind = () => { }


    unbind = (mailboxId?: number, templateId?: number) => {
        if (mailboxId && templateId) {
            this.props.apiServer.apiMail.deleteMailTemplateBinding(templateId, mailboxId).then(x => {
                if (x.data.data?.success) {
                    this.loadTemplates()
                    message.success(this.props.t("mails.mail-template.unbind-success")).then(() => { })
                } else {
                    message.error(this.props.t("mails.mail-template.unbind-fail")).then(() => { })
                }
            })
        }
    }

    onMailTemplateAction = (record: MailTemplateModel, action: string) => {

    }

    renderTabs = () => {
        let tabs: React.ReactNode[] = [];
        tabs.push(
            <Tabs.TabPane closable={false} tab={this.props.t("mails.my-boxes")} key={"default"}>
                <div>
                    <div className="function-bar">
                        <div className="left">

                        </div>
                        <div className="right">

                        </div>
                    </div>
                    <MailBoxList mailBoxes={this.state.mailBoxes}
                        dataLoading={this.state.dataLoading}
                        onNavigate={this.onNavigate}
                        columnTypeProvider={(x) => myMailBoxColumnProvider(this.props.t, x, this.props.apiServer)}
                    />
                </div>
            </Tabs.TabPane>
        )

        this.state.mailBoxesOnView.forEach(x => {
            tabs.push(
                <Tabs.TabPane closable={true} tab={x.emailAddress} key={String(x.id)} style={{ height: "100%" }} >
                    {
                        <div className="template-container">
                            <BindMailTemplateView
                                apiServer={this.props.apiServer}
                                mailBox={x}
                            />
                        </div>
                    }
                </Tabs.TabPane>
            );

        });
        return tabs;
    }

    activeKey = (k: string) => {
        this.setState({
            activeView: k
        })
    }

    editTabs = (e, a) => {
        if (a === "remove") {
            let onView = this.state.mailBoxesOnView;
            onView = onView.filter(x => String(x.id) !== e);
            this.setState({
                mailBoxesOnView: onView
            })
        }
    }

    render(): React.ReactNode {
        return (
            <PageHeader
                title={this.props.t("mails.mail-template.binding-header-title")}
                subTitle={this.props.t("mails.mail-template.binding-header-sub-title")}>
                <Tabs type="editable-card"
                    hideAdd={true}
                    onTabClick={this.activeKey}
                    activeKey={this.state.activeView}
                    onEdit={this.editTabs}
                    tabPosition="top"
                    className="detail-tab"
                >
                    {this.renderTabs()}
                </Tabs>
            </PageHeader>


        )
    }
}

export default withTranslation()(MailTemplateBindingView)