import { notification } from "antd";
import ApiServer from "../../../../ApiServer"




export interface ContentModel {

    [key: string]: ContentItemModel
}

export interface ContentItemModel {
    type: string,
    schema?: number,
    filter?: any,
    value: string,
    canEdit?: boolean
}


export async function loadContent(apiServer: ApiServer, ticketId: number, contentId: number):Promise<ContentModel> {

   return await apiServer.apiTickets.getTicketContent(ticketId, contentId)
        .then(x => {
            try {
                let contentModel: ContentModel | undefined = JSON.parse(x.data.data?.contentDto?.content ?? "");

                if (contentModel) {
                    return contentModel;
                }
                return {
                    "default": {
                        "type": "text",
                        "value": ""
                    }
                }
            } catch {

                return {
                    "default": {
                        "type": "text",
                        "value": ""
                    }
                }
            }
        })
}