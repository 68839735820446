import React from "react";
import { Chart, Util } from '@antv/g2';


import "./index.less"
import { DataPoint, SERIAL_COLOR } from "../@mod";

export interface PieChartViewModel {
    data: DataPoint[],
    width?: number,
    height?: number,
    valueFormat?: (value: any) => any,
    nameFormat?: (name: any) => any


}



interface PieChartViewState {

}



class PieChart extends React.Component<PieChartViewModel, PieChartViewState>{

    serialColor = SERIAL_COLOR;
    containerId: string

    constructor(props: PieChartViewModel) {
        super(props)

        this.containerId = "pie-chart-container-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random();

    }

    chart: Chart | undefined;

    componentDidUpdate() {
        if (this.chart == null && this.props.data.length > 0) {
            var colors = this.props.data.map((x, index) => {
                return x.color ?? this.serialColor[index] ?? ""
            });
            let nameFormat = this.props.nameFormat ?? function (any) {
                return any.name;
            }
            let valueFormat = this.props.valueFormat ?? function (any) {
                return any.value
            }
            this.chart = new Chart(
                {
                    container: this.containerId,
                    autoFit: true,
                    //height: this.props.height,

                    width: this.props.width,
                    height: this.props.height

                }
            );
            this.chart.interaction("element-single-selected")
            this.chart.data(this.props.data);
            this.chart.coordinate('theta', {
                innerRadius: 0.5 / 0.8,
                radius: 0.5
            });
            this.chart.tooltip({
                showMarkers: false,
                customItems: (items) => {
                    return items.map(x => {
                        let toolTipItem = {
                            ...x,
                            name: nameFormat(x),
                            value: valueFormat(x)
                        }
                        return toolTipItem;
                    }
                    )
                }
            });

            const interval = this.chart
                .interval()
                .adjust('stack')
                .position('value')
                .color('type', colors)
                //.style({ opacity: 0.4 })
                .state({
                    active: {
                        style: (element) => {
                            const shape = element.shape;
                            return {
                                matrix: Util.zoom(shape, 1.1),
                            }
                        }
                    }
                });
            this.chart.legend({
                position: "right"
            })
            this.chart.render();

        } else if (this.chart) {
            this.chart.changeData(this.props.data);
        }

    }

    render(): React.ReactNode {
        return (
            <div id={this.containerId} className="pie-chart-container" >

            </div>
        )
    }
}


export default PieChart;