import { Descriptions } from "antd";
import DescriptionsItem from "antd/lib/descriptions/Item";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";

export interface DataDisplayViewModel extends WithTranslation{
	type:string,
	anchor:string
	apiServer:ApiServer

}

interface DataDisplayState {
	rows :Row[]
}

interface Row {
	fieldDataModel:FieldsModel[]
}



interface FieldsModel {
	name:string,
	description :any
	value:string
}


class DataDisplay extends React.Component<DataDisplayViewModel,DataDisplayState> {
	constructor(props:DataDisplayViewModel){
		super(props)
		this.state = {
			rows:[]
		}
	}
	
	loadData = async ()=>{
		this.props.apiServer.apiDataProxy.getData(
			this.props.type,this.props.anchor
			)
		.then(x=>{
			if(x.data.data){
				let rs:any = x.data.data;
				let rows = rs.map(r=>{
					let dataField:any = r.data;
					let fieldModels:FieldsModel[] = [];
					dataField.forEach(
						x=>{
							let f :FieldsModel ={
								name:x.field.name,
								description :x.field.description,
								value:x.value
							}
							fieldModels.push(f)
						}
					)
					return {
						fieldDataModel: fieldModels
					}
				});
				this.setState({
					rows:rows
				})
				
			}
		});
	}

	l:Promise<void>|undefined
	componentDidMount(){
		if(!this.l){
			this.l = this.loadData().finally(()=>this.l=undefined)
		}
	}

	renderRows = ()=>{
		return this.state.rows.map((x,index)=>{
				return <Descriptions key={index} title={this.props.t("tickets.data-ref-"+this.props.type) + ":"+  this.props.anchor} 
				bordered
				size="small"
				column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
			>
				{this.getDescriptions(x)}
			</Descriptions>
		})
	}


	getDescriptions = (row)=>{
		return row.fieldDataModel.map(x=>{
				return (<DescriptionsItem key={x.name} label={x.description[this.props.i18n.language]}> {x.value}
				</DescriptionsItem>)
		})
	}


	render(): React.ReactNode {
		return (<div>
			{this.renderRows()}
		</div>);
	}
}

export default withTranslation()(DataDisplay);