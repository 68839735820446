import {ColumnType} from "antd/lib/table";
import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {ActionEventHandler} from "../../../components";
import WorkBenchTable from "../../../components/table/workbench-table";
import {MailBoxModel} from "../@mod";


import './index.less';


export interface BoxListViewModel extends WithTranslation {
    mailBoxes: MailBoxModel[]
    // instances?: RemoteInstanceModel[],
    dataLoading: boolean
    onNavigate?: (model: MailBoxModel) => void,
    onVerify?: (model: MailBoxModel) => void,
    // apiServer: ApiServer,
    columnTypeProvider: (handler: ActionEventHandler<any>) => ColumnType<any>[]
    onSelected?: (models: MailBoxModel[]) => void
}

interface BoxListViewState {

}


class BoxListView extends React.Component<BoxListViewModel, BoxListViewState>{


    componentDidMount() {

    }

    ondetail = (model: MailBoxModel) => {
        if (this.props.onNavigate) {
            this.props.onNavigate(model);
        }
    }
    onEvent = (d: MailBoxModel, event: string) => {
        if (event === "verify") {
            this.props.onVerify?.(d);
        }

    }

    onSelected = (models: MailBoxModel[]) => {
        this.props.onSelected?.(models)
    }

    render(): React.ReactNode {
        return (
            <div className="box-list-view">
                <WorkBenchTable
                    data={this.props.mailBoxes}
                    dataLoading={this.props.dataLoading}
                    columnProvider={this.props.columnTypeProvider}
                    onDetail={this.ondetail}
                    onEvent={this.onEvent}
                    onSelected={this.onSelected}
                />
            </div>
        )
    }
}



export default withTranslation()(BoxListView);
