import React from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // 主题样式
import 'quill/dist/quill.bubble.css'; // 主题样式


export interface RichTextEditorViewModel {
  value: Promise<string>,
  containerId: string,
  onChanged?: (delta, oldDelta, text) => void,
  editorOperator?: (f: EditorOperation) => void,
  enableOperator?: (f: (b) => void) => void
}

interface RichTextEditorViewState {

}

export interface EditorOperation {
  setRawHtml?: (str: string) => void
  delete?: (start, length) => void,
  add?: (start, type, str) => void
  addDelta?: (delta) => void
  addImage?: (start, url) => void,
  addImageHtml?: (start,id,url)=> void
}


class RichTextEditor extends React.Component<RichTextEditorViewModel, {}> implements EditorOperation {

  constructor(props: RichTextEditorViewModel) {
    super(props)

  }

  quillEditor: any;

  selectedRange: any;


  setRawHtml = (str) => {
    this.quillEditor.root.innerHTML = str;
  }

  delete = (start, length) => {
    var delta = {
      ops: [
        {
          retain: start
        }, {
          delete: length
        }
      ]
    }
    this.quillEditor.updateContents(delta);
  }

  add = (start, type, str) => {
    var delta = {
      ops: [
        {
          retain: start
        }, {
          [type]: str
        }
      ]
    }
    this.quillEditor.updateContents(delta);
  }
  addImage = (start, url) => {

    this.quillEditor.insertEmbed(start, 'image', url, "user");
  }

  addImageHtml =(start,id, url) =>{
    let imgHtml = "<img id=\""+ id + "\" src=\""+ url + "\" />";
    //console.log(imgHtml);
    this.quillEditor.pasteHTML(start, imgHtml,"user");
  }

  addDelta = (delta) => {
    this.quillEditor.updateContents(delta);
  }

  async componentDidMount() {
    if (!this.quillEditor) {
      this.quillEditor = new Quill("#editor-" + this.props.containerId, {
        theme: 'snow', // 设置主题
        modules: {
          toolbar: {
            container: '#toolbar-' + this.props.containerId
          }
        },
        scrollingContainer: "ql-editor"
      })
    }
    this.quillEditor.root.innerHTML = await this.props.value;
    this.quillEditor.on('text-change',
      (delta, oldDelta, source) => {
        if (source === 'user') {
          if (this.props.onChanged) {
            this.props.onChanged(delta, oldDelta,
              this.quillEditor.root.innerHTML);
          }
        }
      }
    )

    this.quillEditor.on('selection-change', (range, oldRange, source) => {
      this.selectedRange = range
    }

    );


    if (this.props.editorOperator) {
      this.props.editorOperator(this)
    }

    if (this.props.enableOperator) {
      this.props.enableOperator((t) => {
        this.quillEditor.enable(t);
      })
    }
  }

  componentDidUpdate() {

  }

  updateContent = (content) => {
    if (this.quillEditor) {
      this.quillEditor.root.innerHTML = content
    }
  }


  getSnapshotBeforeUpdate(prevProps: Readonly<RichTextEditorViewModel>, prevState: Readonly<{}>): any {
    if (prevProps.value !== this.props.value) {
      this.props.value.then(content => {
        this.updateContent(content);
      })
    }
    return null;
  }


  render(): React.ReactNode {
    return (
      <div id={"editor-" + this.props.containerId}>

      </div>)
  }

}




export default RichTextEditor;