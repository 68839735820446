import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button, Form, Input, Select } from "antd";
import { milestoneSelections } from "../../../@mod";
import Question from "../../../../../components/question";


interface MilestoneEditorModel extends WithTranslation {
    onFinish: (values) => void
    onCancel: () => void
}

interface MilestoneEditorState {

}

class MilestoneEditor extends React.Component<MilestoneEditorModel, MilestoneEditorState> {
    constructor(props: MilestoneEditorModel) {
        super(props);
    }


    render() {
        return (
            <div className={"milestone-editor"}>
                <Form
                    labelCol={{ span: 6 }}
                    onFinish={this.props.onFinish}
                >
                    <Form.Item
                        label={this.props.t("project.template.milestone-name")}
                        name={"title"}
                        rules={[{ required: true, message: this.props.t("project.template.input-milestone-name") }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={<span>
                            <Question answer={this.props.t("project.template.editor-comment.milestone-type")} />&nbsp;
                            {this.props.t("project.template.milestone-type")}
                        </span>}
                        name={"type"}
                        rules={[{ required: true, message: this.props.t("project.template.select-milestone-type") }]}
                    >
                        <Select>
                            {
                                milestoneSelections(this.props.t).map(d => {
                                    return (
                                        <Select.Option value={d.value} key={d.value}>{d.text}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={this.props.t("project.template.description")}
                        name={"description"}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <div style={{ float: "right" }}>
                            <Button
                                style={{ marginRight: "8px" }}
                                onClick={this.props.onCancel}
                            >{this.props.t("project.template.cancel")}</Button>
                            <Button type={"primary"} htmlType={"submit"}>{this.props.t("project.template.submit")}</Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}


export default withTranslation()(MilestoneEditor)