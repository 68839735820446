import { Chart } from "@antv/g2";
import { Bullet } from "@antv/g2plot"
import React from "react";


export interface BulletChartViewModel {
    ranges: RangeConfigure[]
    values: RangeConfigure[]
    target:number

}

interface BulletChartViewState {

}

export interface RangeConfigure {
    value: number,
    color?: string
}


class BulletChart extends React.Component<BulletChartViewModel, BulletChartViewState>{

    containerId: string
    bulletPlot: Bullet | undefined

    constructor(props: BulletChartViewModel) {
        super(props)
        this.containerId = "bullet-chart-container-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random();
    }

    getData = () => {
        return [
            {
                title: "",
                values: this.props.values.map(x => x.value),
                ranges: this.props.ranges.map(x => x.value),
                target: this.props.target
            }
        ];

    }

    getColor = () => {
        return {
            range: this.props.ranges.map(x => x.color ?? ""),
            measure: this.props.values.map(x => x.color ?? ""),
            target: "#dfdfdf"
        }
    }

    componentDidMount(){
        this.componentDidUpdate();
    }



    componentDidUpdate() {
    
        if (this.bulletPlot == null && this.props.values && this.props.values.length > 0 ) {
            console.log(this.props);
            this.bulletPlot = new Bullet(this.containerId,
                {
                    height:50,
                    bulletStyle:{
                        range:{
                            fillOpacity:1,
                            lineWidth:0
                        },
                        measure:{
                            fillOpacity:1,
                            lineWidth:0
                        },
                        target:{
                            fillOpacity:1,
                            lineWidth:0
                        }
                    },
                    size:{
                        measure: 5
                    },

                    data: this.getData(),
                    measureField:"values",
                    rangeField: 'ranges',
                    targetField: 'target',
                    xField: 'title',
                    color: this.getColor(),
                    label: {
                        measure: false
                    },
                    xAxis:{
                        line:null
                    },
                    yAxis: false,
                    tooltip: {
                        showMarkers: false,
                        shared: true,
                      }
                });
                this.bulletPlot.render();
        }else if(this.bulletPlot){
            this.bulletPlot.update({
                color : this.getColor()
            })
            this.bulletPlot.changeData(this.getData());
        }
    }


    render(): React.ReactNode {
        return (
            <div id={this.containerId} className="bullet-chart-container">

            </div>
        )
    }

}

export default BulletChart;