import { Button, notification, Select, Tag } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import WorkBenchTable from "../../../../components/table/workbench-table";
import { columnsProviderUser } from "../@mod";
import { UserModel } from "../../@mod"
import './index.less'

export interface WatchListViewModel extends WithTranslation {
	ticketId: number
	currentUser: UserModel[],
	apiServer: ApiServer,
	allUsers: UserModel[],
	loading?: boolean
}

interface WatchListViewState {
	tempUserModel: number[],
	loading: boolean,
	changed: boolean
}


class WatchList extends React.Component<WatchListViewModel, WatchListViewState>{

	constructor(props: WatchListViewModel) {
		super(props)
		this.state = {
			tempUserModel: this.props.currentUser.map(x => x.id),
			loading: false,
			changed: false
		}
	}

	get loading() {
		return this.props.loading || this.state.loading
	}


	deleteClick = (u: UserModel, a: string) => {


	}

	getDisplayUser = () => {
		return this.props.allUsers.filter(x => this.state.tempUserModel.findIndex(y => y === x.id) >= 0);
	}

	get allUserOptions() {
		return this.props.allUsers
			.filter(x => x.active).map(x => {
				return {
					id: x.id,
					label: x.name,
					refModel: x,
					value: x.id
				}
			})
	}
	selectChanged = (v) => {
		this.setState({
			tempUserModel: v
		})
		this.setState({
			changed: true
		})
	}

	addObserver = () => {
		this.setState({
			loading: true
		})

		this.props.apiServer.apiTickets.postTicketWatchList(
			this.props.ticketId,
			{
				userIds: this.state.tempUserModel
			}
		).then(x => {
			if (x.data.data?.succeed) {
				notification.success({ message: this.props.t("tickets.watch-list-set-succee") })
			}
			this.setState({
				loading: false,
				changed: false
			})
		})

	}

	render(): React.ReactNode {
		return (
			<div className="watch-list-panel">
				<div className="add-bar">
					<div className="add-button">
						<Button disabled={!this.state.changed} type="primary" onClick={this.addObserver}>{this.props.t("tickets.add-observer")}</Button>
						<Select<any>
							value={this.state.tempUserModel}
							style={{ width: "100%" }}
							options={this.allUserOptions}
							showSearch
							mode="tags"
							onChange={this.selectChanged}
							filterOption={(text, u: any) => {
								return u.label.indexOf(text) >= 0;
							}}
							placeholder={this.props.t("tickets.select-observer")}
						>
						</Select>

					</div>
				</div>
				<div className="existed-user">
					<WorkBenchTable<UserModel>
						disableCheckBox={true}
						pagesize={100}
						data={this.getDisplayUser()}
						dataLoading={false}
						columnProvider={() => columnsProviderUser(this.props, this.deleteClick)}
					/>
				</div>

			</div>)
	}
}

export default withTranslation()(WatchList);
