import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import ServiceListView from "../component/service-list";
import { convertServiceDtoToModel, convertTaskDtoToModel, SERVICE_STATUS, ServiceModel, TaskModel } from "../@mod";
import { Button, Form, message, Popover } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./index.less"

interface ServiceProcessingViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface ServiceProcessingViewState {
    serviceList: ServiceModel[]
    taskList: TaskModel[]
    showServiceDetails: boolean,
    selectedService?: ServiceModel
    dataLoading: boolean
    showTerminationView: boolean
}

class ServiceProcessingView extends React.Component<ServiceProcessingViewModel, ServiceProcessingViewState> {
    constructor(props: ServiceProcessingViewModel) {
        super(props);
        this.state = {
            serviceList: [],
            taskList: [],
            showServiceDetails: false,
            dataLoading: false,
            showTerminationView: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    unfinishedFirst = (list: ServiceModel[]): ServiceModel[] => {
        return [
            ...list.filter(t => SERVICE_STATUS.isUnfinished(t.status.code)),
            ...list.filter(t => !SERVICE_STATUS.isUnfinished(t.status.code))
        ]
    }

    loadData = () => {
        this.setState({
            dataLoading: true
        })
        this.props.apiServer.apiProjectInstance.getProjectInstanceOfOwnerByStatus([SERVICE_STATUS.PROCESSING, SERVICE_STATUS.REDOING]).then(r => {
            if (r.data.data?.dtos) {
                let serviceList = r.data.data.dtos.map(model => convertServiceDtoToModel(model))
                this.setState({
                    serviceList: this.unfinishedFirst(serviceList)
                })
            }
        }).finally(() => {
            this.setState({
                dataLoading: false
            })
        })
    }

    onDetail = (model: ServiceModel) => {
        this.setState({
            showServiceDetails: true,
            selectedService: model
        })
        this.loadTaskList(model)
    }

    loadTaskList = (model: ServiceModel) => {
        if (model) {
            this.props.apiServer.apiProjectInstance.getItemInstanceListOfServiceInstance(model.id).then(r => {
                if (r.data.data?.dtos) {
                    let dtos = r.data.data.dtos;
                    let serviceItemList = dtos.map((dto) => convertTaskDtoToModel(dto))
                    this.setState({
                        taskList: serviceItemList
                    })
                }
            })
        }
    }

    terminateService = (values) => {
        if (this.state.selectedService) {
            this.props.apiServer.apiProjectInstance.changeServiceInstanceStatus(this.state.selectedService.id, {
                newStatus: SERVICE_STATUS.TERMINATED,
                comment: values["reason"]
            }).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("project.service.teminated-success"))
                    this.setState({
                        showServiceDetails: false,
                        showTerminationView: false
                    })
                    this.componentDidMount()
                    return
                }
                message.error(this.props.t("project.service.teminated-failed"))
            })

        }
    }


    genTerminationView = () => {
        return (
            <>
                <Form layout="vertical"
                    onFinish={this.terminateService}
                >
                    <Form.Item
                        name={"reason"}
                        label={this.props.t("project.service.terminate-reason")}
                        rules={[{ required: true, message: this.props.t("project.service.please-input-reason-for-termination") }]}
                    >
                        <TextArea placeholder={this.props.t("project.service.please-input-reason-for-termination")} />
                    </Form.Item>
                    <Form.Item>
                        <div className="terminate-operation-container">
                            <Button
                                htmlType="submit"
                                danger
                                className="btn-left"
                            >{this.props.t("project.service.confirm")}</Button>
                            <Button type="primary" onClick={() => this.setState({ showTerminationView: false })}>{this.props.t("project.service.cancel")}</Button>
                        </div>

                    </Form.Item>
                </Form>
            </>
        )
    }

    genServiceOperationExtra = () => {
        return (<>
            <Popover content={this.genTerminationView} trigger="click" visible={this.state.showTerminationView}>
                <Button
                    danger
                    onClick={() => this.setState({ showTerminationView: true })}
                >{this.props.t("project.service.terminate-service")}</Button>
            </Popover>
        </>)
    }

    render() {
        return (
            <div className="processing-service-container">
                <ServiceListView
                    serviceLoading={this.state.dataLoading}
                    apiServer={this.props.apiServer}
                    serviceList={this.state.serviceList}
                    headerSubTitle={this.props.t("project.service.processing-sub-title")}
                    headerTitle={this.props.t("project.service.processing-title")}
                    genServiceOperationExtra={this.genServiceOperationExtra}
                    taskList={this.state.taskList}
                    onDetail={this.onDetail}
                    reloadTaskList={this.loadTaskList}
                    showServiceDetails={this.state.showServiceDetails}
                    closeServiceDetailView={() => this.setState({ showServiceDetails: false })}
                    selectedService={this.state.selectedService}
                />
            </div>
        );
    }
}

export default withTranslation()(ServiceProcessingView)