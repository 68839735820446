import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import NotificationSetting from "../component/notification-setting";
import ApiServer from "../../../../ApiServer";


export interface SMSConfigurePageModel extends WithTranslation {
	apiServer: ApiServer
}


class SMSConfigurePage extends React.Component<SMSConfigurePageModel,{}>{
	constructor(props: SMSConfigurePageModel) {
		super(props);
	}

	render(): React.ReactNode {
		return (
			<NotificationSetting
				channelType={""}
				channelConfigurationTitle={"短信参数配置"}
				messageTemplateContainerTitle={"短信消息模板"}
				apiServer={this.props.apiServer}
			/>
		);
	}
}


export default withTranslation()(SMSConfigurePage)
