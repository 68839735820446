import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import {Button, Form, Input, Select, Switch} from "antd";
import {judgeParentTask, TASK_TYPES, taskSelections} from "../../../@mod";
import {UserDto} from "../../../../../api";
import Question from "../../../../../components/question";
import {TicketPriorityModel} from "../@mod";

interface TaskEditorViewModel extends WithTranslation {
    onFinish: (values) => void
    userList: UserDto[]
    cancelEdit: () => void
    taskInfo?: any
    taskSelections?: any[],
    ticketPriorityList: TicketPriorityModel[]
}

interface TaskEditorViewState {
    isParentTaskSelected: boolean,
    isTicketSelected: boolean
}

let translator: (s: string) => string

class TaskEditorView extends React.Component<TaskEditorViewModel, TaskEditorViewState> {
    extraInfoPrefix: string = "extends."
    constructor(props: TaskEditorViewModel) {
        super(props);
        translator = props.t
        this.state = {
            isParentTaskSelected: false,
            isTicketSelected: false,
        }
    }


    componentDidMount(): void {
        const isParentTask = judgeParentTask(this.props.taskInfo?.type ?? "")
        this.setState({
            isParentTaskSelected: isParentTask,
            isTicketSelected: this.props.taskInfo?.type?.toLowerCase() === TASK_TYPES["1004"].value
        })
    }

    finishTask = (values) => {
        let realValue = {
            parameters: {}
        }
        for (let valuesKey in values) {
            if (valuesKey.startsWith(this.extraInfoPrefix)) {
                realValue.parameters[valuesKey.replace(this.extraInfoPrefix, "")] = values[valuesKey]
            } else {
                realValue[valuesKey] = values[valuesKey]
            }
        }
        this.props.onFinish(realValue)
    }

    renderTicketExtInfo = () => {
        return <div>
            <Form.Item
                name={this.extraInfoPrefix + "ticket-subject"}
                label={this.props.t("project.template.ticket.subject")}
                initialValue={this.props.taskInfo.parameters?.["ticket-subject"] ?? ""}
            >
                <Input placeholder={this.props.t("project.template.ticket.subject-placeholder")}/>
            </Form.Item>

            <Form.Item
                name={this.extraInfoPrefix + "ticket-subtitle"}
                label={this.props.t("project.template.ticket.subtitle")}
                initialValue={this.props.taskInfo.parameters?.["ticket-subtitle"] ?? ""}
            >
                <Input placeholder={this.props.t("project.template.ticket.subtitle-placeholder")}/>
            </Form.Item>
            <Form.Item
                name={this.extraInfoPrefix + "ticket-priority"}
                label={this.props.t("project.template.ticket.priority")}
                initialValue={this.props.taskInfo.parameters?.["ticket-priority"]}
            >
                <Select allowClear={true} placeholder={this.props.t("project.template.ticket.priority-placeholder")}>
                    {
                        this.props.ticketPriorityList.map(p => {
                            return <Select.Option key={p.id}
                                                  value={p.id}>{p.desc[this.props.i18n.language]}</Select.Option>
                        })
                    }
                </Select>
            </Form.Item>
        </div>
    }

    render() {
        return (
            <>
                <Form
                    labelCol={{span: 8}}
                    onFinish={this.finishTask}
                >
                    <Form.Item label={this.props.t("project.template.task.fields.name")} name={"title"}
                               rules={[{required: true, message: this.props.t("project.template.input-task-name")}]}
                               initialValue={this.props.taskInfo?.title ?? ""}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={this.props.t("project.template.task.fields.description")} name={"description"}
                               initialValue={this.props.taskInfo?.description ?? ""}>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                <Question answer={this.props.t("project.template.editor-comment.task-type")}/>&nbsp;
                                {this.props.t("project.template.task.fields.type")}
                            </span>
                        }
                        name={"type"}
                        rules={[{ required: true, message: this.props.t("project.template.select-task-type") }]} initialValue={this.props.taskInfo?.type ?? ""}>
                        <Select
                            onChange={(value) => {
                                const isParentTask = judgeParentTask(value)
                                this.setState({
                                    isParentTaskSelected: isParentTask,
                                    isTicketSelected: value.toLowerCase() === TASK_TYPES[1004].value
                                })
                            }}>
                            {

                                (this.props.taskSelections ? this.props.taskSelections : taskSelections(this.props.t)).map(d => {
                                    return (
                                        <Select.Option value={d.value} key={d.value}>{d.text}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>

                    {this.state.isTicketSelected && this.renderTicketExtInfo()}

                    <Form.Item
                        label={
                            <span>
                                <Question answer={this.props.t("project.template.editor-comment.task-executor")}/>&nbsp;
                                {this.props.t("project.template.task.fields.executor")}
                            </span>
                        }
                        name={"defaultExecutor"}
                        rules={[{
                            required: !this.state.isParentTaskSelected,
                            message: this.props.t("project.template.select-task-executor")
                        }]} initialValue={this.props.taskInfo?.defaultExecutor}>
                        <Select
                            defaultValue={this.props.taskInfo?.defaultExecutor}
                            disabled={this.state.isParentTaskSelected}
                            filterOption={(input, option) => String(option!.children).toLowerCase().includes(input.toLowerCase())}
                            showSearch
                            allowClear
                        >
                            {
                                this.props.userList?.map(d => {
                                    return (
                                        <Select.Option value={d.id} key={d.id}>{d.fullName}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item label={this.props.t("project.template.task.fields.required")} name={"required"} initialValue={this.props.taskInfo?.required ?? false}>
                        <Switch
                            defaultChecked={this.props.taskInfo?.required ?? false}
                            checkedChildren={this.props.t("project.template.required")}
                            unCheckedChildren={this.props.t("project.template.optional")}
                        />
                    </Form.Item>

                    <Form.Item
                        label={this.props.t("project.template.task.fields.lower-limit")}
                        name={"timeEstimationLowerLimit"}
                        initialValue={this.props.taskInfo?.timeEstimationLowerLimit ?? ""}
                        rules={[
                            { required: !this.state.isParentTaskSelected, message: this.props.t("project.template.input-lower-limit") },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const isParentTask = judgeParentTask(getFieldValue('type'))
                                    if (isParentTask) {
                                        return Promise.resolve();
                                    }
                                    let lowerLimit = Number(value)
                                    if (lowerLimit < 0) {
                                        return Promise.reject(new Error(translator("project.template.greater-than-or-equal-zero")));
                                    }
                                    return Promise.resolve();
                                },
                            })
                        ]}
                    >
                        <Input type={"number"} placeholder={this.props.t("project.template.time-placeholder")} disabled={this.state.isParentTaskSelected} />
                    </Form.Item>

                    <Form.Item
                        label={this.props.t("project.template.task.fields.higher-limit")}
                        name={"timeEstimationHigherLimit"}
                        initialValue={this.props.taskInfo?.timeEstimationHigherLimit ?? ""}
                        rules={[
                            { required: !this.state.isParentTaskSelected, message: this.props.t("project.template.input-higher-limit") },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const isParentTask = judgeParentTask(getFieldValue('type'))
                                    if (isParentTask) {
                                        return Promise.resolve();
                                    }
                                    let higherLimit = Number(value)
                                    if (higherLimit <= 0) {
                                        return Promise.reject(new Error(translator("project.template.greater-than-zero")));
                                    }
                                    if (Number(getFieldValue('timeEstimationLowerLimit')) <= higherLimit) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(translator("project.template.greater-than-lower-time")));
                                },
                            })
                        ]}
                    >
                        <Input type={"number"} placeholder={this.props.t("project.template.time-placeholder")} disabled={this.state.isParentTaskSelected} />
                    </Form.Item>

                    <Form.Item>
                        <div style={{ float: "right" }}>
                            <Button
                                style={{ marginRight: "8px" }}
                                onClick={this.props.cancelEdit}
                            >{this.props.t("project.template.cancel")}</Button>
                            <Button type={"primary"} htmlType={"submit"}>{this.props.t("project.template.submit")}</Button>
                        </div>
                    </Form.Item>
                </Form>
            </>
        )
    }
}
export default withTranslation()(TaskEditorView)