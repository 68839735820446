import { Button, Descriptions, Input, notification, PageHeader, Tabs } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { RouteContext, withRouter } from "../../../../utils";
import MonacoEditor from 'react-monaco-editor';
import './index.less';
import DynamicEditor from "../../../../components/dynamic-editor";
import FriendlySchemaEditor from "./friendly-schema-editor";

const options = {
	selectOnLineNumbers: true
};

export interface ContentSchemasEditorModel extends WithTranslation {
	router?: RouteContext,
	apiServer: ApiServer
}


interface ContentSchemaEditorState {
	//schema?: ContentSchemaModel,
	dataLoading: boolean,
	onEditSchema: any,
	onEditUiSchema: any
	name: string,
	description: string
}

const defaultSchema = {
	"type": "object",
	"properties": {
		"helpText": {
			"title": "Initilizing",
			"description": "Initilizing",
			"type": "null"
		}
	}
}


class ContentSchemaEditor extends React.Component<ContentSchemasEditorModel, ContentSchemaEditorState>{
	id = 0
	schema: string = ""
	uiSchema: string = ""
	constructor(props: ContentSchemasEditorModel) {
		super(props)
		this.id = Number(props.router?.params["id"]);
		this.state = {
			onEditSchema: defaultSchema,
			onEditUiSchema: "{}",
			dataLoading: true,
			name: "",
			description: ""

		}

	}

	loadData = async () => {

		await this.props.apiServer.apiMeta
			.getContentSchemaById(this.id)
			.then(x => {
				this.schema = x.data.data?.contentSchema?.schema ?? "";
				this.uiSchema = x.data.data?.contentSchema?.uiSchema ?? ""
				this.setState({
					name: x.data.data?.contentSchema?.name ?? "",
					description: x.data.data?.contentSchema?.description ?? ""
				});
				this.load();
			});

	}

	l: Promise<void> | undefined
	componentDidMount() {
		if (!this.l && this.id !== 0) {
			this.l = this.loadData().finally(
				() => {
					this.l = undefined
				}
			)
		}
	}

	editorSchema: any;
	editorSchemaDidMount = (editor: any, monaco) => {
		this.editorSchema = editor;
	}
	editorUserSchema: any;
	editorUiSchemaDidMount = (editor, monaco) => {
		this.editorUserSchema = editor;
	}

	onUischemaChange = (val: string) => {
		this.uiSchema = val;
	}

	onSchemaChange = (val: string) => {
		this.schema = val;

	}

	load = async () => {
		console.log("loaded")
		try {
			console.log(this.uiSchema);
			if (this.uiSchema != null && this.uiSchema !== "") {
				this.setState(
					{
						onEditUiSchema: JSON.parse(this.uiSchema)
					}
				);
			}

			let testSchema = await this.props.apiServer.resolve(JSON.parse(this.schema))
			console.log(testSchema);
			this.setState(
				{
					onEditSchema: testSchema
				}
			);
		} catch {

		}
	}
	/*
	<Tabs.TabPane key={1} tab={this.props.t("content-schemas.edit")}>
	<FriendlySchemaEditor
		jsonSchema={this.state.onEditSchema}
		uiSchema={this.state.onEditUiSchema}
	/>
</Tabs.TabPane>*/


	getFooter = () => {
		return (
			<Tabs >

				<Tabs.TabPane key={2} tab={this.props.t("content-schemas.edit")}>
					<div className="schema-editor">
						<MonacoEditor
							height={400}
							language="javascript"
							theme="vs-dark"
							value={this.schema}
							options={options}
							onChange={this.onSchemaChange}
							editorDidMount={this.editorSchemaDidMount}
						/>

						<MonacoEditor
							height={400}
							language="javascript"
							theme="vs-dark"
							value={this.uiSchema}
							options={options}
							onChange={this.onUischemaChange}
							editorDidMount={this.editorUiSchemaDidMount}
						/>

					</div>



				</Tabs.TabPane>
				<Tabs.TabPane key={3} tab={this.props.t("content-schemas.preview")}
				>
					<div className="bar">
						<Button onClick={this.load}>加载</Button>
					</div>

					<div>
						<DynamicEditor
							widthPercent={45}
							schema={this.state.onEditSchema}
							uiSchema={this.state.onEditUiSchema}
							content={{}}
						/>
					</div>
				</Tabs.TabPane>
			</Tabs>
		)
	}
	getBasicInputs = () => {

		return (<></>)
	}

	saveTemplate = async () => {

		try {
			if (this.id !== 0) {
				await this.props.apiServer.apiMeta
					.putContentSchema(this.id, {
						schema: this.schema,
						uiSchema: this.uiSchema,
						name: this.state.name,
						description: this.state.description

					}).then(
						x => {
							if (x.data.data?.succeed) {
								notification.info({ message: this.props.t("content-schemas.save-succeed") });
							} else {
								notification.info({ message: this.props.t("content-schemas.save-fail-server") })
							}
						}
					)
			}
			else {
				await this.props.apiServer.apiMeta
					.postContentSchema({
						name: this.state.name,
						description: this.state.description,
						uiSchema: this.uiSchema,
						schema: this.schema
					}).then(
						x => {
							if (x.data.data?.succeed) {
								notification.info({ message: this.props.t("content-schemas.new-succeed") });
								this.props.router?.navigate("/system/productivity/template-editor/" + x.data.data.id)
							} else {
								notification.info({ message: this.props.t("content-schemas.new-fail-server") })
							}
						}
					)
			}
		} catch {
			notification.error({ message: this.props.t("content-schemas.fail-save-format") });
		}
	}



	render(): React.ReactNode {
		return (
			<div className="content-schema-container site-drawer-render-in-current-wrapper" >
				<PageHeader title={this.props.t("content-schemas.editor")}
					footer={this.getFooter()}
					onBack={() => window.history.back()}
					extra={
						[
							<Button type="primary" onClick={this.saveTemplate}>{this.props.t("content-schemas.save")}</Button>
						]
					}
				>
					<Descriptions>
						<Descriptions.Item key={1} label={this.props.t("content-schemas.name")} labelStyle={{ padding: 6 }}>
							<Input onChange={(v) => this.setState({ name: v.target.value })} value={this.state.name}></Input>
						</Descriptions.Item>
						<Descriptions.Item key={2} label={this.props.t("content-schemas.description")} labelStyle={{ padding: 6 }}>
							<Input onChange={(v) => this.setState({ description: v.target.value })} value={this.state.description}></Input>
						</Descriptions.Item>
					</Descriptions>

				</PageHeader>
			</div>
		)
	}
}


export default withRouter(withTranslation()(ContentSchemaEditor))

