import { Button, Divider, Form, message, Modal, Radio, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { MailBoxModel } from "../../../@mod";
import { mailSelectiveColumnProvider } from "../../@mod"
import {
    CONTACT_BINDING_TYPE,
    covertWorkbenchMailBoxContactDtoToModel,
    covertWorkbenchMailContactDtoToModel,
    loadContactOfMailbox,
    MailContactModel,
    WorkbenchMailBoxContactModel
} from "../../@mod";
import MailContactBindingList from "../../component/mail-contact-binding-list";
import "./index.less"

import { LinkOutlined } from "@ant-design/icons"

interface MailboxBindingsViewModel extends WithTranslation {
    mailBox: MailBoxModel
    apiServer: ApiServer
}

interface MailboxBindingsViewState {
    to: WorkbenchMailBoxContactModel[],
    cc: WorkbenchMailBoxContactModel[],
    bcc: WorkbenchMailBoxContactModel[],
    showBindingEditor: boolean,
    mailContacts: MailContactModel[],
    selecteBindCandidate: MailContactModel[],
    bindTo: boolean,
    bindCc: boolean,
    bindBcc: boolean
}

class MailboxBindingsView extends React.Component<MailboxBindingsViewModel, MailboxBindingsViewState> {

    constructor(props: MailboxBindingsViewModel) {
        super(props)
        this.state = {
            to: [],
            cc: [],
            bcc: [],
            showBindingEditor: false,
            mailContacts: [],
            selecteBindCandidate: [],
            bindTo: true,
            bindCc: false,
            bindBcc: false
        }
    }


    componentDidMount(): void {
        this.loadBinding()
    }

    loadBinding = () => {
        loadContactOfMailbox(this.props.mailBox.id, this.props.apiServer).then(res => {
            this.setState({
                to: res.filter(x => String(x.type) === String(CONTACT_BINDING_TYPE.TO.code)).map(x => covertWorkbenchMailBoxContactDtoToModel(x)) ?? [],
                cc: res.filter(x => String(x.type) === String(CONTACT_BINDING_TYPE.CC.code)).map(x => covertWorkbenchMailBoxContactDtoToModel(x)) ?? [],
                bcc: res.filter(x => String(x.type) === String(CONTACT_BINDING_TYPE.BCC.code)).map(x => covertWorkbenchMailBoxContactDtoToModel(x)) ?? []
            })
        })
    }

    unbindGivenType = (type: number) => {
        return (contactId: number, mailboxId: number) => {
            this.props.apiServer.apiMail.deleteMailboxContactBinding(mailboxId, contactId, type).then(x => {
                if (x.data.data?.success) {
                    message.success(this.props.t("mails.contact.binding.unbinding-success")).then(() => { })
                    this.componentDidMount()
                } else {
                    message.error(this.props.t("mails.contact.binding.unbinding-fail")).then(() => { })
                }
            })
        }
    }

    loadAllMailContacts = () => {
        this.props.apiServer.apiMail.getMailContacts().then(x => {
            if (x.data.data?.dtos) {
                this.setState({
                    mailContacts: x.data.data?.dtos.map(d => covertWorkbenchMailContactDtoToModel(d))
                })
            }
        })
    }

    showAddBindingEditor = () => {
        if (this.state.mailContacts.length === 0) {
            this.loadAllMailContacts()
        }
        this.setState({
            showBindingEditor: true
        })
    }

    onFinishAddBinding = async (values) => {
        await this.props.apiServer.apiMail.postMailboxContactBinding(
            this.props.mailBox.id,
            values.contactId,
            values.bindingType
        ).then(x => {
            if (x.data.data?.success) {
                message.success(this.props.t("mails.contact.binding.binding-success")).then()
                
            } else {
                message.error(this.props.t("mails.contact.binding.binding-fail")).then()
            }
        })
    }

    onSelectedContract = (data: MailContactModel[]) => {
        console.log(data)
        this.setState({ selecteBindCandidate: data });
    }

    canBind = () => {
        return (this.state.bindBcc || this.state.bindTo || this.state.bindCc) && this.state.selecteBindCandidate.length > 0
    }


    doBind = async () => {
        for (let idx = 0; idx < this.state.selecteBindCandidate.length; idx++) {
            var contract = this.state.selecteBindCandidate[idx];
            console.log(contract,this.state.selecteBindCandidate,idx)
            if (this.state.bindTo && this.state.to.findIndex(q=>q.emailContact.emailAddress===contract.emailAddress)<0) {
                await this.onFinishAddBinding({
                    contactId :contract.id,
                    bindingType: CONTACT_BINDING_TYPE.TO.code
                })

            }
            if (this.state.bindCc && this.state.cc.findIndex(q=>q.emailContact.emailAddress===contract.emailAddress)<0) {
                await this.onFinishAddBinding({
                    contactId :contract.id,
                    bindingType: CONTACT_BINDING_TYPE.CC.code
                })
            }
            if (this.state.bindBcc && this.state.bcc.findIndex(q=>q.emailContact.emailAddress===contract.emailAddress)<0) {
                await this.onFinishAddBinding({
                    contactId :contract.id,
                    bindingType: CONTACT_BINDING_TYPE.BCC.code
                })
            }
        }
        this.setState({
            showBindingEditor: false,
            selecteBindCandidate:[],
        })
        this.loadBinding();
    }

    genNewBingdingEditor = (): React.ReactNode => {
        return (
            <>
                <Modal
                    title={this.props.t("mails.contact.binding.binding-editor-title") + " - " + this.props.mailBox.emailAddress}
                    visible={this.state.showBindingEditor}
                    onCancel={() => this.setState({
                        showBindingEditor: false
                    })}
                    width="640px"
                    footer={null}
                    className="mailbox-contact-binding-editor"
                >

                    <WorkBenchTable<MailContactModel>
                        data={this.state.mailContacts}
                        dataLoading={false}
                        onSelected={this.onSelectedContract}
                        columnProvider={() => mailSelectiveColumnProvider(this.props.t)}
                        rowKey={(record: MailContactModel) => { return record.id }}
                    />
                    <div className="bind-type">
                        <Checkbox checked={this.state.bindTo} onClick={() => { this.setState({ bindTo: !this.state.bindTo }) }}>{this.props.t("mails.contact.to")}</Checkbox>
                        <Checkbox checked={this.state.bindCc} onClick={() => { this.setState({ bindCc: !this.state.bindCc }) }}>{this.props.t("mails.contact.cc")}</Checkbox>
                        <Checkbox checked={this.state.bindBcc} onClick={() => { this.setState({ bindBcc: !this.state.bindBcc }) }}>{this.props.t("mails.contact.bcc")}</Checkbox>
                    </div>
                    <div>
                        <Button icon={<LinkOutlined />} type="primary"
                            disabled={!this.canBind()}
                            onClick={()=>this.doBind()}
                        >{this.props.t("mails.mail-contact.bind")}</Button>
                    </div>

                </Modal>
            </>
        )
    }

    render(): React.ReactNode {
        return (
            <div className="mailbox-contact-binding">
                <div className="operation-bar">
                    <Button type="primary" onClick={this.showAddBindingEditor}>{this.props.t("mails.contact.binding.new-binding")}</Button>
                </div>

                <Divider orientation="center">{this.props.t("mails.contact.to")}</Divider>
                <MailContactBindingList
                    apiServer={this.props.apiServer}
                    contacts={this.state.to}
                    contactHandler={(record, actionType) => this.unbindGivenType(CONTACT_BINDING_TYPE.TO.code)(record.emailContact.id, this.props.mailBox.id)}
                />

                <Divider orientation="center">{this.props.t("mails.contact.cc")}</Divider>
                <MailContactBindingList
                    apiServer={this.props.apiServer}
                    contacts={this.state.cc}
                    contactHandler={(record, actionType) => this.unbindGivenType(CONTACT_BINDING_TYPE.CC.code)(record.emailContact.id, this.props.mailBox.id)}
                />

                <Divider orientation="center">{this.props.t("mails.contact.bcc")}</Divider>
                <MailContactBindingList
                    apiServer={this.props.apiServer}
                    contacts={this.state.bcc}
                    contactHandler={(record, actionType) => this.unbindGivenType(CONTACT_BINDING_TYPE.BCC.code)(record.emailContact.id, this.props.mailBox.id)}
                />
                {this.genNewBingdingEditor()}
            </div>
        )
    }
}

export default withTranslation()(MailboxBindingsView)
