import moment from "moment-timezone"
import { ProjectServiceItemInstanceStatusDto, ProjectServiceStatusChangeLogDto } from "../../../../../api"

export interface StatusLogInfoModel {
    id: number,
    operatorName: string,
    date: string,
    preStatus: ProjectServiceItemInstanceStatusDto,
    afterStatus: ProjectServiceItemInstanceStatusDto,
    comment: string
}

export const convertProjectServiceStatusChangeLogDtoToModel = (dto: ProjectServiceStatusChangeLogDto): StatusLogInfoModel => {
    return {
        id: dto.id ?? -1,
        operatorName: dto.operator?.fullName ?? "",
        date: dto.operateTime ? moment.parseZone(dto.operateTime).format("YYYY-MM-DD HH:mm:ss") : "",
        preStatus: dto.previousStatus ?? {},
        afterStatus: dto.currentStatus ?? {},
        comment: dto.comment ?? ""
    }
}