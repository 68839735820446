import React from "react";
import { Chart, Util } from '@antv/g2';
import './index.less'
import { DataPoint } from "../@mod";


export interface AreaChartViewModel {
    data: DataPoint[],
    width?: number,
    height?: number
}

interface AreaChartViewState {

}


class AreaChart extends React.Component<AreaChartViewModel, AreaChartViewState>{

    containerId: string
    chart: Chart | undefined;

    constructor(props: AreaChartViewModel) {
        super(props)

        this.containerId = "area-chart-container-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random();
    }

    componentDidUpdate() {
        if (this.chart == null && this.props.data.length > 0) {
            
            var chart = new Chart({
                container: this.containerId,
                autoFit: true,
                width: this.props.width,
                height: this.props.height
            });

            chart.data(this.props.data);
            chart.scale({
                value: {
                    nice: true,
                    min:50
                },
                type: {
                    range: [0, 1]
                },
            });
            chart.tooltip({
                showCrosshairs: true,
                shared: true,
            });

            /*
            chart.axis('value', {
                label: {
                    formatter: (val) => {
                        return (+val / 10000).toFixed(1) + 'k';
                    },
                },
            });
            */
           chart.axis('value',{

           })

            chart.area().position('type*value');
            chart.line().position('type*value');
            chart.render();
            this.chart = chart;
        } else if (this.chart) {
            this.chart.changeData(this.props.data);
        }
    }



    render(): React.ReactNode {
        return (
            <div id={this.containerId} className="area-chart-container">
                
            </div>
        )
    }
}

export default AreaChart;