import {withTranslation, WithTranslation} from "react-i18next";
import {UserGroupModel} from "../@mod";
import React from "react";
import {Button, Form, FormInstance, Input} from "antd";
import './index.less'

interface GroupEditorModel extends WithTranslation {
    userGroup: UserGroupModel,
    save: (userGroup: UserGroupModel) => void,
    cancel: () => void
}

interface GroupEditorState {
    userGroupOnEdit: UserGroupModel
}

class GroupEditor extends React.Component<GroupEditorModel, GroupEditorState> {
    formRef: React.RefObject<FormInstance<UserGroupModel>>
    constructor(props) {
        super(props);
        this.formRef = React.createRef()
        this.state = {
            userGroupOnEdit: this.props.userGroup
        }
    }

    componentDidMount() {
        this.formRef?.current!.setFieldsValue(this.props.userGroup)
    }

    componentDidUpdate(prevProps: Readonly<GroupEditorModel>, prevState: Readonly<GroupEditorState>, snapshot?: any) {
    }

    getSnapshotBeforeUpdate(prevProps: Readonly<GroupEditorModel>, prevState: Readonly<GroupEditorState>): any {
        if (prevProps.userGroup !== this.props.userGroup) {
            this.formRef?.current!.setFieldsValue(this.props.userGroup)
            this.setState({userGroupOnEdit: this.props.userGroup})
            this.forceUpdate()
        }
        return null
    }

    onFinished = (val: UserGroupModel) => {
        this.props.save(val);
    }

    onCancel = () => {
        this.props.cancel();
    }

    render(): React.ReactNode {
        return (
            <div>
                <Form
                    layout="horizontal"
                    labelCol={{span: 5}}
                    wrapperCol={{span: 16}}
                    onFinish={this.onFinished}
                    name="control-ref"
                    ref={this.formRef}
                >
                    <Form.Item label={this.props.t("user-group-editor.name")}
                               name="name"
                               rules={[{required: true, message: this.props.t("user-group-editor.name-placeholder")}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label={this.props.t("user-group-editor.desc")}
                        name="description"
                        rules={[{required: true, message: this.props.t("user-group-editor.desc-placeholder")}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item className="editor-button-bar">
                        <Button type="primary"  htmlType="submit" className="button">
                            {this.props.t("user-group-editor.save")}
                        </Button>
                        <Button type="primary" className="button" onClick={this.onCancel}>
                            {this.props.t("user-group-editor.cancel")}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

export default withTranslation()(GroupEditor)