import { Button } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
//import { TicketPriorityModel } from "../../system/productivity/ticket/@mod";
import { convertTo, TicketModel } from "../@mod";
import AssignedTickets from "../assigned-tickets";



export interface MyTicketPageModel extends WithTranslation {
	apiServer: ApiServer

}
interface MyTicketPageState {
	title: string;
	subtitle: string;
	tickets: TicketModel[],
	loading: boolean,
	loginId: number
}

class MyTicketPage extends React.Component<MyTicketPageModel, MyTicketPageState>{

	constructor(props: MyTicketPageModel) {
		super(props)
		this.state = {
			title: "my-ticket",
			subtitle: "tickets.assigned-to-me-desc",
			tickets: [],
			loading: true,
			loginId:0
		}
	}

	async loadData() {
		await this.props.apiServer.apiTickets.getTicketList()
			.then(x => {
				let tickets = x.data.data?.tickets?.map(convertTo)
				this.setState({
					tickets: tickets ?? []
				})
			});

		await this.props.apiServer.apiSystem.getLoginUser().then(r=>{
				if(r.data.data?.user){
					this.setState({
						loginId:r.data.data.user.id??0
					})
				}

		});
	}

	l: Promise<void> | undefined;


 	reload = ()=>{
		this.setState({
			loading:true
		});
		this.loadData().finally(()=>{
			this.setState({
				loading:false
			})
		})
	}


	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(
					() => {
						this.setState({
							loading: false
						});
					})
		}
	}

	ticketUpdate = (id:number) => {

		this.setState({
			loading:true
		})
		

		this.props.apiServer.apiTickets.getTicket(id)
		.then(x=>{
			if(x.data.data?.succeed && x.data.data.ticket)
			{
				var list = this.state.tickets;
				list = list.filter(x=>x.id!=id)
				list.push( convertTo(x.data.data?.ticket));
				this.setState({
					tickets:list
				})
			}
		}).finally(
			()=>{
				this.setState({
					loading:false
				})
		})
	}


	render(): React.ReactNode {
		return (<>
			<AssignedTickets
				title={this.state.title}
				subtitle={this.state.subtitle}
				tickets={this.state.tickets.filter(x=>x.assigned?.id===this.state.loginId)}
				loading={this.state.loading}
				apiServer={this.props.apiServer}
				withCreateNewTicketButton
				skippedColumn={["assigned","tags"]}
				enableGroupAssign={true}
				reload={this.reload}
				ticketUpdate={this.ticketUpdate}
			></AssignedTickets>
		</>)
	}
}

export default withTranslation()(MyTicketPage)