import { Button, Divider, Form, message, Select, Tag } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { loadMyMailBoxes, MailBoxModel, convertMailBoxDtoToModel } from "../../../@mod";
import { mailBoxColumnProvider, MailTemplateModel } from "../../@mod";
import "./index.less"
interface BindMailboxViewModel extends WithTranslation {
    apiServer: ApiServer,
    template?: MailTemplateModel
}

interface BindMailboxViewState {
    boundMailboxes: MailBoxModel[],
    myMailboxes: MailBoxModel[],
    loadingMailboxes: boolean
}

class BindMailboxView extends React.Component<BindMailboxViewModel, BindMailboxViewState> {
    constructor(props: BindMailboxViewModel) {
        super(props)
        this.state = {
            boundMailboxes: [],
            loadingMailboxes: false,
            myMailboxes: []
        }
    }

    componentDidMount(): void {
        this.loadBoundMailboxes()
        this.loadMyMailboxexs()
    }

    loadMyMailboxexs = () => {
        loadMyMailBoxes(this.props.apiServer).then(r => {
            this.setState({
                myMailboxes: r
            })
        })
    }

    loadBoundMailboxes = () => {
        if (this.props.template?.id) {
            this.setState({
                loadingMailboxes: true
            })
            this.props.apiServer.apiMail.getBoundMailboxes(this.props.template.id).then(x => {
                if (x.data.data?.success) {
                    let models = x.data.data.dtos?.map(d => convertMailBoxDtoToModel(d)) ?? []
                    this.setState({
                        boundMailboxes: models
                    })
                }
            }).finally(() => {
                this.setState({
                    loadingMailboxes: false
                })
            })
        }
    }

    onMailTemplateAction = (record: MailBoxModel, action: string) => {
        if (action === "unbind" && this.props.template?.id) {
            this.props.apiServer.apiMail.deleteMailTemplateBinding(this.props.template?.id, record.id).then(x => {
                if (x.data.data?.success) {
                    message.success(this.props.t("mails.mail-template.unbind-success"))
                    this.loadBoundMailboxes()
                } else {
                    message.error(this.props.t("mails.mail-template.unbind-fail"))
                }
            })
        }
    }

    bindMailBox = (values) => {
        if (this.props.template?.id) {
            this.props.apiServer.apiMail.postMailTemplateBinding(this.props.template.id, values.mailboxId).then(x => {
                if (x.data.data?.success) {
                    this.loadBoundMailboxes()
                    message.success(this.props.t("mails.mail-template.bind-success"))
                } else {
                    message.error(this.props.t("mails.mail-template.bind-fail"))
                }
            })
        }
    }

    render(): React.ReactNode {
        return (
            <div className="bind-mail-box-container">
                <div className="mailbox-selector">
                    <Form
                        layout="inline"
                        onFinish={this.bindMailBox}
                    >
                        <Form.Item
                            label={this.props.t("mails.mail-template.mailbox")}
                            name={"mailboxId"}
                            rules={[{ required: true, message: this.props.t("mails.mail-template.select-mailbox") }]}
                        >
                            <Select style={{ width: 400 }}>
                                {
                                    this.state.myMailboxes.map(m => {
                                        return <Select.Option children={(
                                            <div>
                                                <span style={{ marginRight: '8px' }}>{m.emailAddress}</span>
                                                {m.tags?.map(t => <Tag color={t.color} children={t.name} />)}
                                            </div>
                                        )} key={m.id} value={m.id} />
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">{this.props.t("mails.mail-template.binding")}</Button>
                        </Form.Item>
                    </Form>
                </div>


                <Divider children={this.props.t("mails.mail-template.bound-mailboxes")} />

                <div className="mailboxes">
                    <WorkBenchTable
                        data={this.state.boundMailboxes}
                        dataLoading={this.state.loadingMailboxes}
                        columnProvider={() => mailBoxColumnProvider(this.props.t, this.onMailTemplateAction, this.props.apiServer)}
                    />
                </div>
            </div>
        )
    }
}

export default withTranslation()(BindMailboxView)