import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { removeDuplicateUser, serviceColumnProvider, ServiceModel, TaskModel } from "../../@mod";
import { Modal, PageHeader } from "antd";
import WorkBenchTable from "../../../../components/table/workbench-table";
import { ActionEventHandler } from "../../../../components";
import ServiceDetailView from "./service-detail";
import ApiServer from "../../../../ApiServer";
import { ProjectServiceInstanceStatusDto, UserModel } from "../../../../api";

interface ServiceListViewModel extends WithTranslation {
    apiServer: ApiServer
    serviceList: ServiceModel[]
    headerTitle: string
    headerSubTitle: string
    genServiceOperationExtra: () => React.ReactNode
    onDetail: (model: ServiceModel) => void
    taskList?: TaskModel[]
    reloadTaskList: (selectedService: ServiceModel) => void
    showServiceDetails: boolean
    selectedService?: ServiceModel
    closeServiceDetailView: () => void
    serviceLoading?: boolean
}

interface ServiceListViewState {
    statusList: ProjectServiceInstanceStatusDto[],
    subscriberList: UserModel[],
    acceptor: UserModel[],
}

class ServiceListView extends React.Component<ServiceListViewModel, ServiceListViewState> {
    constructor(props: ServiceListViewModel) {
        super(props);
        this.state = {
            statusList: [],
            subscriberList: [],
            acceptor: []
        }
    }

    componentDidMount() {
        this.loadStatus()
        // TODO 组件再次加载时无法获取到props中的数据
        this.setState({
            subscriberList: removeDuplicateUser(this.props.serviceList?.map(t => t.subscriber)),
            acceptor: removeDuplicateUser(this.props.serviceList?.map(t => t.acceptor))
        })
    }

    loadStatus = () => {
        this.props.apiServer.apiProjectTask.getItemInstanceStatusMetaData().then(r => {
            if (r.data.data?.dtos) {
                this.setState({
                    statusList: r.data.data?.dtos
                })
            }
        })
    }

    render() {
        return (
            <div>
                <PageHeader title={this.props.headerTitle} subTitle={this.props.headerSubTitle}>
                    <WorkBenchTable<ServiceModel>
                        data={this.props.serviceList}
                        dataLoading={this.props.serviceLoading ?? false}
                        rowKey={(record => record.id)}
                        columnProvider={(a: ActionEventHandler<ServiceModel>) => serviceColumnProvider(this.props, this.state.statusList, this.state.subscriberList, this.state.acceptor, a)}
                        onDetail={this.props.onDetail}
                    />
                </PageHeader>

                <Modal
                    visible={this.props.showServiceDetails}
                    destroyOnClose={true}
                    // closable={false}
                    onCancel={() => { this.props.closeServiceDetailView() }}
                    footer={null}
                    style={{ top: 20 }}
                    width={1600}
                >
                    <ServiceDetailView
                        service={this.props.selectedService}
                        taskList={this.props.taskList}
                        genServiceOperationExtra={this.props.genServiceOperationExtra}
                        apiServer={this.props.apiServer}
                        reloadTaskList={this.props.reloadTaskList}
                    />
                </Modal>
            </div>
        )
    }
}

export default withTranslation()(ServiceListView)