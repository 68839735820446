import ApiServer from "../../../ApiServer"

export interface DataModel {
    name: string,
    value: number,
    description: any
}


export interface UserModel {
	id: number,
	name: string,
	organization: string
}

export async function loadUsers(apiServer: ApiServer): Promise<UserModel[]> {
	return apiServer.apiUser.getUserList(true)
		.then(x =>
			x.data.data?.list?.map(y => {
				let u: UserModel = {
					name: (y.fullName ?? "") + "(@" + y.name + ")",
					id: y.id ?? 0,
					organization: y.organization ?? ""
				}
				return u
			}) ?? []
		)
}