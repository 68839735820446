import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import './index.less';

import { Avatar, Button, Input, List, Select, Skeleton, Tooltip } from 'antd';
import { MailModel } from "../../../../@mod";
import moment from "moment-timezone";
import { SearchOutlined } from "@ant-design/icons"



export interface MailsListViewModel extends WithTranslation {
    load?: (start, end) => Promise<MailModel[]>,
    itemOnView?: (mail: MailModel) => void,
    setManupulator?: (operator:ListViewManupulator)=>void

}
export interface ListViewManupulator {
    reload : ()=>void
}

interface MailsListViewState {
    start: string, // use for display

    current: string,
    mailList: MailModel[],
    loading: boolean,
    searchOption?: string,
    searchText: string,
    filter?: (mailList: MailModel) => boolean,

}


class MailsListView extends React.Component<MailsListViewModel, MailsListViewState> implements ListViewManupulator {

    reload = ()=>{
        this.loadState();
    }

    constructor(props: MailsListViewModel) {
        super(props)
        this.state = {
            start: moment().add(-30, "days").format("yyyy-MM-DD 23:59:59"),
            current: moment().format("yyyy-MM-DD 23:59:59"),
            mailList: [],
            loading: false,
            searchOption: "subject",
            searchText: ""
        }
        if(props.setManupulator){
            props.setManupulator(this);
        }
    }

    loadState = async (): Promise<void> => {
    
        if (this.props.load) {
            await this.props.load(this.state.start, this.state.current)
                .then(x => {
                    this.setState({
                        mailList: x.sort((x, y) => Number(y.mailDate) - Number(x.mailDate))
                    })
                })
        }
    }

    doloadMore = async (): Promise<void> => {
        if (this.props.load) {
            var eariler = moment(this.state.start).add(-30, "days").format("yyyy-MM-DD 23:59:59");
            await this.props.load(eariler, this.state.start)
                .then(x => {
                    let exists = this.state.mailList;
                    x.sort((x, y) => Number(y.mailDate) - Number(x.mailDate)).forEach(n => {
                        exists.push(n);
                    })
                    this.setState({
                        mailList: exists,
                        start: eariler
                    })
                })
        }
    }


    componentDidMount() {
        this.loadState();
        this.applyFilter(undefined);
    }

    click = (model: MailModel) => {
        if (this.props.itemOnView) {
            this.props.itemOnView(model);
        }
    }

    loadMore = () => {
        this.setState({
            loading: true
        })

        this.doloadMore().finally(() => {
            this.setState({
                loading: false
            });
        })

    }

    getLoadMode = () => {
        return (
            <Button onClick={this.loadMore} loading={this.state.loading}>{this.props.t("mails.load-more")}</Button>
        )
    }
    changeSearchOption = (s) => {
        this.setState({
            searchOption: s
        })
        this.setState({
            filter: undefined,
            searchText: ""
        })
        this.applyFilter(s);
    }

    getMailListWithFilter = () => {
        if (this.state.filter) {
            return this.state.mailList.filter(this.state.filter)
        }
        return this.state.mailList;
    }
    applyFilter = (s) => {
        s = s??this.state.searchOption

        if (s) {
            switch (s) {
                case "subject":
                    this.setState({
                        filter: (m) => {
                            return m.subject.toLocaleLowerCase().indexOf(this.state.searchText.toLocaleLowerCase()) >= 0
                        }
                    })
                    break;
                case "address":
                    this.setState({
                        filter: (m) => {
                            return m.externInfo.send.indexOf(this.state.searchText) >= 0
                        }
                    })
                break;
                case "folder":
                    this.setState({
                        filter: (m) => {
                            return m.folder.indexOf(this.state.searchText) >= 0
                        }
                    })
                
                
            }
        }
    }

    getFolderWithCounts = ()=>{

        let folder:any = {};
        this.state.mailList.forEach(m=>{
            if(!folder[m.folder]){
                folder[m.folder] = 1;
            }else{
                folder[m.folder] +=1
            }
        })
        return folder;
    }

    renderSelectOptions = () =>{
        let folder = this.getFolderWithCounts();
        var options: React.ReactNode[] = [];
        for(let i in folder){
            options.push(
                <Select.Option key={i} value={i}>{i}-{folder[i]} </Select.Option>
            )
        }

        return options;

    }


    render(): React.ReactNode {

        return (
            <div className="mail-list-view">
                <div className="search-field">
                    <div className="search-input">
                        <Input.Group compact>
                            <Select style={{ width: '30%' }} defaultValue="subject" value={this.state.searchOption}
                                onChange={this.changeSearchOption}>
                                <Select.Option value="address" >{this.props.t("mails.mail-address")}</Select.Option>
                                <Select.Option value="subject" >{this.props.t("mails.mail-subject")}</Select.Option>
                                <Select.Option value="folder" >{this.props.t("mails.mail-folder")}</Select.Option>
                            </Select>
                            {this.state.searchOption !== "folder" &&
                                <Input value={this.state.searchText} style={{ width: '70%', textAlign: "left" }} type={"text"}
                                    onChange={(t) => this.setState({ searchText: t.target.value })} />
                            } 
                            {this.state.searchOption === "folder" &&
                                <Select value={this.state.searchText} style={{ width: '70%', textAlign: "left" }} 
                                    onChange={(t) => this.setState({ searchText: t})}>           
                                    {this.renderSelectOptions()}</Select>
                            } 

                        </Input.Group>
                    </div>
                
                </div>


                <div className="mail-list-container">
                    <List<MailModel> loading={this.state.loading}
                        loadMore={this.getLoadMode()}
                        dataSource={this.getMailListWithFilter()}
                        renderItem={(item) => {
                            return (
                                <div className="mail-item" onClick={() => this.click(item)}>
                                    <div className="title-field">
                                        <Tooltip placement="top" title={item.subject === "" ? this.props.t("mails.no-subject") : item.subject}>
                                            {item.subject === "" ? this.props.t("mails.no-subject") : item.subject}
                                        </Tooltip>
                                    </div>
                                    <div className="info">
                                        <div className="sender">
                                            <Tooltip placement="bottom" title={item.externInfo.send}>
                                                {item.externInfo.send}
                                            </Tooltip>
                                        </div>
                                        <div className="date">
                                            {moment(item.mailDate).format("MM-DD/HH:mm")}
                                        </div>
                                    </div>
                                </div>
                            )
                        }}
                    >
                    </List>
                </div>
            </div>
        )
    }

}

export default withTranslation()(MailsListView);