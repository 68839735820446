import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'flag-icons/less/flag-icons.less'
import ApiServer from './ApiServer';
import { Configuration } from './api';
import lang from './i18n/language'
import { BASE_USRL } from './configure.js';
import { Provider } from 'react-redux';
import store from './redux'
import './index.css';
import App from './App';



function configureApiServer(): ApiServer {
  let config = new Configuration({
    basePath: BASE_USRL
  });

  return new ApiServer(
    config
  );
}

const apiServer = configureApiServer();
lang(apiServer).then(
  x => {
    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
    /*root.render(
      <React.StrictMode>
        <Provider store={store}>
          <App apiServer={apiServer} />
        </Provider>
      </React.StrictMode>
    );*/
    
    root.render(
      <Provider store={store}>
          <App apiServer={apiServer} />
      </Provider>
    );
    reportWebVitals();
  }
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


