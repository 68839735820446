import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { WithTranslation } from "react-i18next";
import { ActionEventHandler } from "../../../../../components";
import ColumnTextFilter from "../../../../../components/table/column-text-filter";
import { ContentSchemaModel } from "../../../@mod";






export const columnsProvider = (
	trans: WithTranslation, handler: ActionEventHandler<ContentSchemaModel>): ColumnType<ContentSchemaModel>[] => {
	let translater: ((d: string) => string) = trans.t;
	return [
		{
			title: translater("content-schemas.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: ContentSchemaModel) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value: string | number | boolean, record: ContentSchemaModel) => record.name.indexOf(value.toString()) >= 0,
			filters: [],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.name")} />)
			},

		},
		{
			title: translater("content-schemas.description"),
			dataIndex: 'description',
			key: 'description'
		}, {
			title: translater("content-schemas.updateTime"),
			dataIndex: 'updateTime',
			key: 'updateTime',
			render: (value: any, record: ContentSchemaModel, index: number) => {
				return record.updateTime === "" ? "" : moment.parseZone(record.updateTime).format("YYYY-MM-DD HH:mm:ss");
			}
		},
		{
			title: translater("content-schemas.createTime"),
			dataIndex: 'createTime',
			key: 'createTime',
			render: (value: any, record: ContentSchemaModel, index: number) => {
				return record.createTime === "" ? "" : moment.parseZone(record.createTime).format("YYYY-MM-DD HH:mm:ss");
			}
		}
	];
}
