import { PageHeader } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { DataPoint } from "../../../../components/charts/@mod";
import PieChart from "../../../../components/charts/pie-chart";
import { DataModel } from "../../@mod";
import HistorySummary from "../../components/history-summary";
import PieChartSummary from '../../components/pie-summary'
import './index.less'


export interface TicketsStatisticsOverviewViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface TicketsStatisticsOverviewViewState {
    data: DataModel[],
    dataLoading: boolean
}



class TicketsStatisticsOverview extends React.Component<TicketsStatisticsOverviewViewModel, TicketsStatisticsOverviewViewState>{


    constructor(props: TicketsStatisticsOverviewViewModel) {
        super(props)
        this.state = {
            data: [],
            dataLoading: false
        }

    }

    loadData = async () => {
        this.setState({
            dataLoading: true
        })

        this.props.apiServer.apiStatistic.getAtMomentStatisticsData()
            .then(x => {
                var data = x.data.data?.dataValues?.map(x => {
                    let data: DataModel = {
                        name: x.dataPoint ?? "",
                        value: x.value ?? 0,
                        description: x.description
                    }
                    return data;
                }) ?? [];
                this.setState({
                    data: data
                })
            }).finally(()=>{
                this.setState({
                    dataLoading:false
                })
            });
    }


    l: Promise<void> | undefined;

    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData().finally(() => {
                this.l = undefined;
            });
        }
    }

    get ticketTypeData() {
        return this.state.data.filter(x => x.name.indexOf("TICKET-STATUS") == 0).map(x => {
            let dataPoint: DataPoint = {
                type: x.description[this.props.i18n.language],
                value: x.value
            }
            return dataPoint;
        });
    }

    renderStatusPart = () => {
        return (<PieChartSummary
            //title = {this.props.t("statistics-tickets.agg-tickets-status")}
            data={this.state.data.filter(x => x.name.indexOf("TICKET-STATUS") === 0)}

        />)
    }


    renderTicketPriorityTotalPart = () => {
        return (<PieChartSummary
            //title = {this.props.t("statistics-tickets.agg-tickets-priority-include-closed")}
            data={this.state.data.filter(x =>
                x.name.indexOf("TICKET-PRIORITY") === 0 &&
                x.name.indexOf("TICKET-PRIORITY-NON") < 0
            )
            } />)
    }

    renderTicketPriorityNonClosedPart = () => {
        return (<PieChartSummary
            //title = {this.props.t("statistics-tickets.agg-tickets-priority")}
            data={this.state.data.filter(x =>
                x.name.indexOf("TICKET-PRIORITY-NON") === 0
            )
            } />)
    }



    renderTicketTypeTotalPart = () => {
        return (<PieChartSummary
            //title = {this.props.t("statistics-tickets.agg-tickets-type-include-closed")}
            data={this.state.data.filter(x =>
                x.name.indexOf("TICKET-TYPE") === 0 &&
                x.name.indexOf("TICKET-TYPE-NON") < 0
            )
            } />)
    }

    renderTicketTypeNonClosedPart = () => {
        return (<PieChartSummary

            //title = {this.props.t("statistics-tickets.agg-tickets-type")}
            data={this.state.data.filter(x =>
                x.name.indexOf("TICKET-TYPE-NON") === 0)
            } />)
    }

    renderTicketSourceTotalPart = () => {
        return (<PieChartSummary
            //title = {this.props.t("statistics-tickets.agg-tickets-source-include-closed")}

            data={this.state.data.filter(x =>
                x.name.indexOf("TICKET-SOURCE") === 0 &&
                x.name.indexOf("TICKET-SOURCE-NON") < 0
            )
            } />)
    }

    renderTicketSourceNonClosedPart = () => {
        return (<PieChartSummary
            //title = {this.props.t("statistics-tickets.agg-tickets-source")}
            data={this.state.data.filter(x =>
                x.name.indexOf("TICKET-SOURCE-NON") == 0)
            } />)
    }
    getDataModel = (dpName:string):DataModel =>{
        let updateToday = this.state.data.filter(x => x.name.indexOf(dpName) === 0)[0];
        return updateToday;
    }


  


    renderSummaryHistoryPart = (dpName:string) => {
        let updateToday = this.getDataModel(dpName);
        if (updateToday) {
            let dataPoint: DataPoint = {
                type: updateToday.name,
                value: updateToday.value
            }
            return (<HistorySummary
                summaryDataPoint={
                    dataPoint
                }
                name = {updateToday.description[this.props.i18n.language]}
                apiServer={this.props.apiServer}
            />)
        } 
        return <></>
    }



    render(): React.ReactNode {
        return <div className="statistics-container">
            <PageHeader title={this.props.t("statistics-tickets.overview")}>


                    <div className="statistics-overview-container">
                        <div className="statistics-column-100">
                            <div className="statistics-row">
                                <div className="row-item-25 row-item">
                                    <div className="statistics-title" >{this.props.t("statistics-tickets.updated")}</div>
                                    {this.renderSummaryHistoryPart("TICKET-UPDATED-TODAY")}
                                </div>
                                <div className="row-item-25 row-item">
                                    <div className="statistics-title" >{this.props.t("statistics-tickets.created")}</div>
                                    {this.renderSummaryHistoryPart("TICKET-CREATED-TODAY")}
                                </div>
                                <div className="row-item-25 row-item">
                                    <div className="statistics-title" >{this.props.t("statistics-tickets.time-up")}</div>
                                    {this.renderSummaryHistoryPart("TICKET-NEEDS-CLOSED-TODAY")}
                                </div>
                                <div className="row-item-25 row-item">
                                    <div className="statistics-title" >{this.props.t("statistics-tickets.close")}</div>
                                    {this.renderSummaryHistoryPart("TICKET-CLOSED-TODAY")}
                                </div>
                            </div>
                            <div className="statistics-row">
                                <div className="row-item-25 row-item">
                                    <div className="statistics-title" >{this.props.t("statistics-tickets.agg-tickets-priority")}</div>
                                    {this.renderTicketPriorityNonClosedPart()}
                                </div>
                                <div className="row-item-25 row-item">
                                    <div className="statistics-title">{this.props.t("statistics-tickets.agg-tickets-type")}</div>
                                    {this.renderTicketTypeNonClosedPart()}
                                </div>

                                <div className="row-item-25 row-item">
                                    <div className="statistics-title">{this.props.t("statistics-tickets.agg-tickets-source")}</div>
                                    {this.renderTicketSourceNonClosedPart()}
                                </div>

                                <div className="row-item-25 row-item">
                                    <div className="statistics-title">{this.props.t("statistics-tickets.agg-tickets-status")}</div>
                                    {this.renderStatusPart()}
                                </div>
                            </div>

                            <div className="statistics-row">
                                <div className="row-item-33 row-item">
                                    <div className="statistics-title">{this.props.t("statistics-tickets.agg-tickets-priority-include-closed")}</div>
                                    {this.renderTicketPriorityTotalPart()}
                                </div>
                                <div className="row-item-33 row-item">
                                    <div className="statistics-title">{this.props.t("statistics-tickets.agg-tickets-type-include-closed")}</div>
                                    {this.renderTicketTypeTotalPart()}
                                </div>
                                <div className="row-item-33 row-item">
                                    <div className="statistics-title">{this.props.t("statistics-tickets.agg-tickets-source-include-closed")}</div>
                                    {this.renderTicketSourceTotalPart()}
                                </div>
                            </div>
                        </div>
                    </div>

            </PageHeader >



        </div >
    }
}




export default withTranslation()(TicketsStatisticsOverview);