import { Button, Input, Tag } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import './index.less';

interface ColumnTextFilterModel extends WithTranslation {
	context: FilterDropdownProps,
	title?: string
}

interface ColumnTextFilterState {
	appliedSearch:string,
	searchText:string
}


class ColumnTextFilter extends React.Component<ColumnTextFilterModel, ColumnTextFilterState>{

	constructor(props: ColumnTextFilterModel) {
		super(props)
		this.state = {
			searchText:"",
			appliedSearch:""
		}
	}

	getAlliedSearchTag(){
		if(this.state.appliedSearch && this.state.appliedSearch!=="" ){
			return (
				<Tag color="blue"> {this.state.appliedSearch} </Tag>
			)
		}
		else{
			return (<></>)
		}
	}

	get getHeader() {
		if (this.props.title) {
			return (
				<div className="filter-header" >
					{this.props.title} {this.getAlliedSearchTag()}
				</div>
			)
		}
		return (<></>)
	}

	applySearch = () => {

		console.log(this.props.context);
		this.setState({
			appliedSearch:this.state.searchText
		})
		this.props.context.setSelectedKeys([this.state.searchText]);
		this.props.context.confirm({
			"closeDropdown":true
		})
		
	}

	resetSearch = () => {
		this.setState({
			appliedSearch:"",
			searchText:""
		})
		this.props.context.setSelectedKeys([]);
		if(this.props.context.clearFilters){
			this.props.context.clearFilters();
		}
		this.props.context.confirm({
			"closeDropdown":false
		});
		
		
	}
	keyUp = (e)=>{
		if(e.code ==="Enter"){
			this.applySearch()
		}
	}

	render(): React.ReactNode {
		return (
			<div className="filter-container">
				{this.getHeader} 
				<div className="filter-body" >
					<Input type="text" placeholder={this.props.t("search")} value={this.state.searchText}
						onChange={(v)=>{this.setState({searchText:v.target.value})}}
						onKeyUp= {this.keyUp}
					></Input>
				</div>
				<div className="filter-footer" >
					<Button type="primary" onClick={this.applySearch}>{this.props.t("confirm")} </Button>
					<Button type="primary" onClick={this.resetSearch}>{this.props.t("reset")} </Button>
				</div>
			</div>
		)
	}
}




export default withTranslation()(ColumnTextFilter);


