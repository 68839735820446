import { Button, Popconfirm, Select } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { UserDto } from "../../../../api";
import ApiServer from "../../../../ApiServer";


interface UserSelectorModel extends WithTranslation {
    userList?: UserDto[]
    selectedUser: (user: any) => void
    cancelSelect: () => void
    style?: any
    apiServer: ApiServer
}

interface UserSelectorState {
    selectedUser?: any
    userList: UserDto[]
}

class UserSelector extends React.Component<UserSelectorModel, UserSelectorState> {
    constructor(props: UserSelectorModel) {
        super(props)
        this.state = {
            userList: props.userList ?? []
        }
    }

    componentDidMount(): void {
        if (this.state.userList.length === 0) {
            this.loadUserList()
        }
    }


    loadUserList = () => {
        this.props.apiServer.apiUser.getUserList().then(r => {
            this.setState({
                userList: r.data.data?.list ?? []
            })
        })
    }

    onUserChange = (user) => {
        this.setState({
            selectedUser: {
                id: user.value,
                name: user.children
            }
        })

    }

    render(): React.ReactNode {
        return (
            <div>
                <Select
                    style={this.props.style ?? { width: 200 }}
                    onChange={(v, a) => this.onUserChange(a)}
                    showSearch
                    filterOption={(input, option) => String(option?.children).toLowerCase().includes(input.toLowerCase())}
                >
                    {this.state.userList?.map(u => <Select.Option key={u.id} value={u.id}>{u.fullName}</Select.Option>)}
                </Select>

                <Popconfirm
                    title={this.props.t("project.confirm-select-user")}
                    onConfirm={() => this.props.selectedUser(this.state.selectedUser)}
                >
                    <Button type={"primary"}>
                        {this.props.t("project.template.confirm")}
                    </Button>
                </Popconfirm>
                <Button onClick={this.props.cancelSelect}>{this.props.t("project.template.cancel")}</Button>
            </div>

        )
    }
}

export default withTranslation()(UserSelector)


