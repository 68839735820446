import { Button, Card, notification } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { TicketModel } from "../../@mod";
import { loadUserGroups, UserGroupModel, UserModel } from "../../@mod";
import UserSelector from "../../user-selector";
import './index.less'


export interface CopyNewTicketViewModel extends WithTranslation {
    userGroup?: UserGroupModel
    apiServer: ApiServer,
    ticketModel?: TicketModel,
    cancel?: () => void
}

interface CopyNewTicketViewState {
    usersInGroup: UserModel[],
    copyOption: CopyOptions,
}

export interface CopyOptions {
    users: UserModel[],
    options: any
}

class CopyNewTicketView extends React.Component<CopyNewTicketViewModel, CopyNewTicketViewState>{

    constructor(props: CopyNewTicketViewModel) {
        super(props)
        this.state = {
            usersInGroup: [],
            copyOption: {
                users: [],
                options: {}
            }
        }
    }

    loadData = async () => {
        await this.userGroupChange(this.props.userGroup?.id ?? 0)
    }

    userGroupChange = async (val: number) => {
        this.props.apiServer.apiGroup.getUserGroup(val, true, false, false, true)
            .then(x => {
                let users: UserModel[] = [];
                x.data.data?.users?.forEach(x => {
                    users.push({
                        id: x.id ?? 0,
                        name: (x.fullName ?? "") + "(@" + x.name + ")",
                        organization: x.organization ?? "",
                        active:x.active
                    })
                })
                if(users.length>0){
                    this.state.copyOption.users.push(users[0]);
                }
                this.setState({
                    usersInGroup: users,
                    copyOption:this.state.copyOption
                })
            })
    }

    l: Promise<void> | undefined
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.l = undefined
                })
        }
    }


    saveClicked = () => {
        this.props.apiServer.apiTickets.patchCopyTicket(
            this.props.ticketModel?.id??0,{
            assignees: this.state.copyOption.users.map(x=>x.id),
            options: {}
        }).then(x=>{
            if(x.data.data?.success &&  this.props.cancel){
                this.props.cancel();
                notification.success({message:this.props.t("tickets.success-copied")+ x.data.data.tickets?.length})
            }else{
                notification.error({message:this.props.t("tickets.details.copy-tickets-fail")})
            }
        })


       
    }

    getSelector = () => {
        
        //console.log("get selector")
        return this.state.copyOption.users.map((x, index) => {
            return (
                <div key={index} className="assignee-selector">
                    <UserSelector users={this.state.usersInGroup}
                        defaultSelected={x}
                        onSelected={(v) => { this.state.copyOption.users[index] = v }}
                    ></UserSelector>
                </div>
            )
        })
    }

    newAssignee = () => {
        this.state.copyOption.users.push(
            this.state.usersInGroup[0]
        );
        this.setState({
            copyOption: this.state.copyOption
        })
    }

    render(): React.ReactNode {
        return (
            <Card title={this.props.t("tickets.copy-new-ticket")}
                extra={
                    [
                        <Button type="default" danger key={1} onClick={this.props.cancel}> {this.props.t("tickets.details.cancel")}</Button>,
                        <Button type="default" key={2} onClick={this.newAssignee}> {this.props.t("tickets.details.new-assignee")}</Button>,
                        <Button type="primary" key={3} onClick={this.saveClicked}> {this.props.t("tickets.details.save")}</Button>
                    ]
                }
            >
                <div className="assign-to-view">
                    <div className="field" >
                        <div className="title">{this.props.t("tickets.assigned-group")}</div>
                        <div className="title">{this.props.userGroup?.name}</div>
                    </div>
                    <div className="field" >
                        <div className="title">{this.props.t("tickets.assigned")}</div>
                        <div style={{ width: 450 }} className="assignees" >
                            {this.getSelector()}
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

}


export default withTranslation()(CopyNewTicketView);