import { Avatar } from "antd";
import React from "react";


const color = [
	"#a5e2fc",
	"#132dbd",
	"#3543a3",
	"#0c09b0",
	"#b44c11",
	"#238d89",
	"#1196d1",
	"#61f728",
	"#857b51",
	"#8e8c4b",
	"#7794ee",
	"#a41d76",
	"#37ef51",
	"#f4f5ef",
	"#7ec89a",
	"#aaa681",
	"#002fb3",
	"#6fe366",
	"#4f3df9",
	"#b942e3",
	"#1bdb19",
	"#d01845",
	"#2a364e",
	"#2d7be9",
	"#d46be7",
	"#ee003b"
]

export interface UserAvatarViewModel {
	userName:string
}
interface UserAvatarState{

}

class UserAvatar extends React.Component<UserAvatarViewModel,UserAvatarState> {

	color =()=>{
		return color[this.props.userName.length %color.length-1];
	}

	shortName = ()=>{
		return this.props.userName.slice(0,2).toUpperCase();
	}

	render(): React.ReactNode {
		return (
			<Avatar style={{ backgroundColor: this.color(), verticalAlign: 'middle' }} size="large" >
				{this.shortName()}
			</Avatar>
		)
	}
}

export default UserAvatar