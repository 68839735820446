import { PageHeader } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import WorkBenchTable from "../../components/table/workbench-table";



export interface ClusterLandingViewModel extends WithTranslation {

}

interface ClusterLandingViewState {

}

class ClusterLanding extends React.Component<ClusterLandingViewModel,ClusterLandingViewState>{


    constructor(props:ClusterLandingViewModel){
        super(props)
    }


    componentDidMount(){
        

    }


    render(): React.ReactNode {
        return (
        <div className="cluster-management">
            <PageHeader title={this.props.t("enroll-management")}>
 
            </PageHeader>
        </div>)
    }
}

export default withTranslation()(ClusterLanding);