import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ProjectMetaDataModel, ProjectProcessModel } from "../../@mod";
import { Form, Input } from "antd";
import ProjectProcessView from "../project-process";
import moment from "moment";

export interface ProjectDetailViewModel extends WithTranslation {
    projectToShow?: ProjectMetaDataModel,
    projectProcess: ProjectProcessModel[],
    disableEditor: boolean
}

interface ProjectDetailViewState {

}


class ProjectDetailView extends React.Component<ProjectDetailViewModel, ProjectDetailViewState> {
    constructor(props: ProjectDetailViewModel) {
        super(props);
        this.state = {
        }
    }

    render() {
        let status = ""
        if (this.props.projectToShow?.status?.desc) {
            status = this.props.projectToShow?.status?.desc[this.props.i18n.language]
        }
        return (
            <div style={{ display: this.props.projectToShow ? "block" : "none" }}>
                <Form disabled={this.props.disableEditor} labelCol={{ span: 5 }}>
                    <Form.Item label={this.props.t("project.template.fields.title")}>
                        <Input value={this.props.projectToShow?.title} />
                    </Form.Item>
                    <Form.Item label={this.props.t("project.template.fields.version")}>
                        <Input value={this.props.projectToShow?.version} />
                    </Form.Item>
                    <Form.Item label={this.props.t("project.template.fields.description")}>
                        <Input value={this.props.projectToShow?.description} />
                    </Form.Item>
                    <Form.Item label={this.props.t("project.template.fields.owner")}>
                        <Input value={this.props.projectToShow?.ownerName} />
                    </Form.Item>
                    <Form.Item label={this.props.t("project.template.fields.status")}>
                        <Input value={status} />
                    </Form.Item>
                    <Form.Item label={this.props.t("project.template.fields.create-time")}>
                        <Input value={moment.parseZone(this.props.projectToShow?.createdTime).format("YYYY-MM-DD HH:mm:ss")} />
                    </Form.Item>
                </Form>

                <ProjectProcessView
                    processList={this.props.projectProcess}
                    expandedKeys={["0"]}
                />
            </div>
        );
    }
}

export default withTranslation()(ProjectDetailView)