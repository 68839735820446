import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import withRouter, { WithRouteContext } from "../../utils/route-utils";


import './index.less';



export interface StatisticsLandingViewModel extends WithTranslation,WithRouteContext {

}
interface StatisticsViewState {

}

class StatisticsLandingPage extends React.Component<StatisticsLandingViewModel,StatisticsViewState>{

        render(): React.ReactNode {
            return <div>
                    TT 
            </div>
        }
}


export default withRouter(withTranslation()(StatisticsLandingPage));