import { Tag, Tree } from "antd";
import React, { Children } from "react";
import { withTranslation } from "react-i18next";
import { TagModel } from "../@mod";



export interface TagTreeEditorViewModel {
    allTags?: TagModel[]
    onTagAdd?: (tagId: number) => void
}

interface TagTreeEditorViewState {
    tagTree: TagNode[]
    selectedKeys :string[]
}

interface TagNode {
    key: string
    title: string,
    refTag?: TagModel,
    children: TagNode[],
    parentNode?: TagNode,
    icon?:any,
    checked :boolean
}


interface MiddleStageTree {
    splited: string[],
    refName: string,
    refTag: TagModel,
    

}

class TagTreeEditor extends React.Component<TagTreeEditorViewModel, TagTreeEditorViewState>{

    constructor(props: TagTreeEditorViewModel) {
        super(props)
        this.state = {
            tagTree: [],
            selectedKeys:[]
        }
    }
    renderIcon = (props: any) =>{
        //console.log(props)
        if(props.refTag){
            return <div style={{marginTop:"4px", width:"16px",height:"16px",backgroundColor:props.refTag.color}}></div>
        }
        
        return <div></div>
    }


    buildTagNode = (flated: MiddleStageTree[], deep: number): TagNode[] => {



        var firstBuildNeeds: string[] = [];
        flated.forEach(x => {
            if (firstBuildNeeds.findIndex(q => q === x.splited[0]) < 0) {
                firstBuildNeeds.push(x.splited[0])
            }
        });
        let tagNodes: TagNode[] = [];


        firstBuildNeeds.forEach(x => {
            var allInOne = flated.filter(f => f.splited[0] === x);
            let a = allInOne.find(x => x.splited.length == 1)?.refTag;
            let tagNode: TagNode = {
                key: deep + "-" + x,
                title: x,
                refTag: a,
                checked:false,
                children: this.buildTagNode(allInOne.filter(a => a.splited.length > 1)
                    .map(a => {
                        a.refName = a.refName.substring(x.length + 1, a.refName.length);
                        a.splited = a.refName.split("-") ?? [];
                        return a;
                    }) ?? [], deep + 1)
            }
            tagNode.children.forEach(q => {
                q.parentNode = tagNode
                if(q.refTag){
                    q.icon = this.renderIcon
                }
            });
            tagNodes.push(tagNode);
        })
        return tagNodes;
    }

    buildTagTreeSet = () => {
        var flated = this.props.allTags?.map(x => {
            let tr: MiddleStageTree = {
                splited: x.name.split("-"),
                refName: x.name,
                refTag: x
            };
            return tr
        });
        let builtNode = this.buildTagNode(flated ?? [], 0);
        builtNode.forEach(b=>b.icon=this.renderIcon);
        this.setState({
            tagTree: builtNode
        })
    }
    componentDidMount(): void {
        this.buildTagTreeSet();
    }

    checkNode = (refNode)=>{
        //console.log(refNode)
        if(refNode && refNode.refTag && this.props.onTagAdd) {
            this.props.onTagAdd(refNode.refTag.id)
            this.checkNode(refNode.parentNode)
        }
    }

    onSelect = (a,checkInfo)=>{
        this.checkNode(checkInfo.node);
    }

    render(): React.ReactNode {
        return (
            <div className="tag-editor-tree">
                <Tree<TagNode> treeData={this.state.tagTree}
                    checkable={false}
                    showIcon={true}
                    onSelect={this.onSelect}
                    selectedKeys={this.state.selectedKeys}
                >

                </Tree>
            </div>
        )
    }
}

export default withTranslation()(TagTreeEditor)