import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { mailboxBindContactColumnProvider, WorkbenchMailBoxContactModel } from "../../@mod";

interface MailContactBindingListModel extends WithTranslation {
    apiServer: ApiServer
    contacts: WorkbenchMailBoxContactModel[]
    contactHandler: (record: WorkbenchMailBoxContactModel, action: string) => void
}


interface MailContactBindingListState {

}


class MailContactBindingList extends React.Component<MailContactBindingListModel, MailContactBindingListState> {

    contactHandler = (record: WorkbenchMailBoxContactModel, action: string) => {
        this.props.contactHandler(record, action)
    }

    render(): React.ReactNode {
        return (
            <div>

                <WorkBenchTable<WorkbenchMailBoxContactModel>
                    data={this.props.contacts}
                    dataLoading={false}
                    columnProvider={() => mailboxBindContactColumnProvider(this.props.t, this.contactHandler)}
                />
            </div>
        )
    }
}


export default withTranslation()(MailContactBindingList)