/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessKeyDto
 */
export interface AccessKeyDto {
    /**
     * 
     * @type {number}
     * @memberof AccessKeyDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessKeyDto
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessKeyDto
     */
    'accessKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessKeyDto
     */
    'accessSecretPub'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessKeyDto
     */
    'asymmetricMethod'?: string;
    /**
     * 
     * @type {number}
     * @memberof AccessKeyDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof AccessKeyDto
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof AccessKeyDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessKeyDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface ActionStatusDto
 */
export interface ActionStatusDto {
    /**
     * 
     * @type {string}
     * @memberof ActionStatusDto
     */
    'result'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActionStatusDto
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActionStatusDto
     */
    'instanceId'?: number;
}
/**
 * 
 * @export
 * @interface ApiResponseAtMomentStatisticsDataResponse
 */
export interface ApiResponseAtMomentStatisticsDataResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseAtMomentStatisticsDataResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseAtMomentStatisticsDataResponse
     */
    'message'?: string;
    /**
     * 
     * @type {AtMomentStatisticsDataResponse}
     * @memberof ApiResponseAtMomentStatisticsDataResponse
     */
    'data'?: AtMomentStatisticsDataResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseBoolean
 */
export interface ApiResponseBoolean {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseBoolean
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseBoolean
     */
    'message'?: string;
    /**
     * 响应请求的数据
     * @type {boolean}
     * @memberof ApiResponseBoolean
     */
    'data'?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseCreateNewTagResponse
 */
export interface ApiResponseCreateNewTagResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseCreateNewTagResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseCreateNewTagResponse
     */
    'message'?: string;
    /**
     * 
     * @type {CreateNewTagResponse}
     * @memberof ApiResponseCreateNewTagResponse
     */
    'data'?: CreateNewTagResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDelMailBoxAccessResponse
 */
export interface ApiResponseDelMailBoxAccessResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDelMailBoxAccessResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDelMailBoxAccessResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DelMailBoxAccessResponse}
     * @memberof ApiResponseDelMailBoxAccessResponse
     */
    'data'?: DelMailBoxAccessResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteApiAccessKeyResponse
 */
export interface ApiResponseDeleteApiAccessKeyResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteApiAccessKeyResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteApiAccessKeyResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteApiAccessKeyResponse}
     * @memberof ApiResponseDeleteApiAccessKeyResponse
     */
    'data'?: DeleteApiAccessKeyResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteAttachmentFromTicketResponse
 */
export interface ApiResponseDeleteAttachmentFromTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteAttachmentFromTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteAttachmentFromTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteAttachmentFromTicketResponse}
     * @memberof ApiResponseDeleteAttachmentFromTicketResponse
     */
    'data'?: DeleteAttachmentFromTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteDraftMailResponse
 */
export interface ApiResponseDeleteDraftMailResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteDraftMailResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteDraftMailResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteDraftMailResponse}
     * @memberof ApiResponseDeleteDraftMailResponse
     */
    'data'?: DeleteDraftMailResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteMailContactsResponse
 */
export interface ApiResponseDeleteMailContactsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteMailContactsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteMailContactsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteMailContactsResponse}
     * @memberof ApiResponseDeleteMailContactsResponse
     */
    'data'?: DeleteMailContactsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteMailTemplateBindingResponse
 */
export interface ApiResponseDeleteMailTemplateBindingResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteMailTemplateBindingResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteMailTemplateBindingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteMailTemplateBindingResponse}
     * @memberof ApiResponseDeleteMailTemplateBindingResponse
     */
    'data'?: DeleteMailTemplateBindingResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteMailTemplateResponse
 */
export interface ApiResponseDeleteMailTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteMailTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteMailTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteMailTemplateResponse}
     * @memberof ApiResponseDeleteMailTemplateResponse
     */
    'data'?: DeleteMailTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteMailboxContactBindingResponse
 */
export interface ApiResponseDeleteMailboxContactBindingResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteMailboxContactBindingResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteMailboxContactBindingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteMailboxContactBindingResponse}
     * @memberof ApiResponseDeleteMailboxContactBindingResponse
     */
    'data'?: DeleteMailboxContactBindingResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteNotificationEventResponse
 */
export interface ApiResponseDeleteNotificationEventResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteNotificationEventResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteNotificationEventResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteNotificationEventResponse}
     * @memberof ApiResponseDeleteNotificationEventResponse
     */
    'data'?: DeleteNotificationEventResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteProjectDraftResponse
 */
export interface ApiResponseDeleteProjectDraftResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteProjectDraftResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteProjectDraftResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteProjectDraftResponse}
     * @memberof ApiResponseDeleteProjectDraftResponse
     */
    'data'?: DeleteProjectDraftResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteRelatedTicketResponse
 */
export interface ApiResponseDeleteRelatedTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteRelatedTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteRelatedTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteRelatedTicketResponse}
     * @memberof ApiResponseDeleteRelatedTicketResponse
     */
    'data'?: DeleteRelatedTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteTagFromTicketResponse
 */
export interface ApiResponseDeleteTagFromTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteTagFromTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteTagFromTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteTagFromTicketResponse}
     * @memberof ApiResponseDeleteTagFromTicketResponse
     */
    'data'?: DeleteTagFromTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteTaskKnowledgeResponse
 */
export interface ApiResponseDeleteTaskKnowledgeResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteTaskKnowledgeResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteTaskKnowledgeResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteTaskKnowledgeResponse}
     * @memberof ApiResponseDeleteTaskKnowledgeResponse
     */
    'data'?: DeleteTaskKnowledgeResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteTemplateResponse
 */
export interface ApiResponseDeleteTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteTemplateResponse}
     * @memberof ApiResponseDeleteTemplateResponse
     */
    'data'?: DeleteTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteUserFromUserGroupResponse
 */
export interface ApiResponseDeleteUserFromUserGroupResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteUserFromUserGroupResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteUserFromUserGroupResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteUserFromUserGroupResponse}
     * @memberof ApiResponseDeleteUserFromUserGroupResponse
     */
    'data'?: DeleteUserFromUserGroupResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseDeleteUserGroupResponse
 */
export interface ApiResponseDeleteUserGroupResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseDeleteUserGroupResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseDeleteUserGroupResponse
     */
    'message'?: string;
    /**
     * 
     * @type {DeleteUserGroupResponse}
     * @memberof ApiResponseDeleteUserGroupResponse
     */
    'data'?: DeleteUserGroupResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetAcceptProjectInstanceResponse
 */
export interface ApiResponseGetAcceptProjectInstanceResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetAcceptProjectInstanceResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetAcceptProjectInstanceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetAcceptProjectInstanceResponse}
     * @memberof ApiResponseGetAcceptProjectInstanceResponse
     */
    'data'?: GetAcceptProjectInstanceResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetAggregatedContactsByMailboxResponse
 */
export interface ApiResponseGetAggregatedContactsByMailboxResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetAggregatedContactsByMailboxResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetAggregatedContactsByMailboxResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetAggregatedContactsByMailboxResponse}
     * @memberof ApiResponseGetAggregatedContactsByMailboxResponse
     */
    'data'?: GetAggregatedContactsByMailboxResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetAllNotificationEventResponse
 */
export interface ApiResponseGetAllNotificationEventResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetAllNotificationEventResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetAllNotificationEventResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetAllNotificationEventResponse}
     * @memberof ApiResponseGetAllNotificationEventResponse
     */
    'data'?: GetAllNotificationEventResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetAllTasksResponse
 */
export interface ApiResponseGetAllTasksResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetAllTasksResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetAllTasksResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetAllTasksResponse}
     * @memberof ApiResponseGetAllTasksResponse
     */
    'data'?: GetAllTasksResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetApiAccessKeyOfUserResponse
 */
export interface ApiResponseGetApiAccessKeyOfUserResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetApiAccessKeyOfUserResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetApiAccessKeyOfUserResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetApiAccessKeyOfUserResponse}
     * @memberof ApiResponseGetApiAccessKeyOfUserResponse
     */
    'data'?: GetApiAccessKeyOfUserResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetAutomaticProcessTicketContentResponse
 */
export interface ApiResponseGetAutomaticProcessTicketContentResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetAutomaticProcessTicketContentResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetAutomaticProcessTicketContentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetAutomaticProcessTicketContentResponse}
     * @memberof ApiResponseGetAutomaticProcessTicketContentResponse
     */
    'data'?: GetAutomaticProcessTicketContentResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetBoundMailboxesResponse
 */
export interface ApiResponseGetBoundMailboxesResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetBoundMailboxesResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetBoundMailboxesResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetBoundMailboxesResponse}
     * @memberof ApiResponseGetBoundMailboxesResponse
     */
    'data'?: GetBoundMailboxesResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetBoundTemplatesResponse
 */
export interface ApiResponseGetBoundTemplatesResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetBoundTemplatesResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetBoundTemplatesResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetBoundTemplatesResponse}
     * @memberof ApiResponseGetBoundTemplatesResponse
     */
    'data'?: GetBoundTemplatesResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetChannelConfigurationKeysResponse
 */
export interface ApiResponseGetChannelConfigurationKeysResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetChannelConfigurationKeysResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetChannelConfigurationKeysResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetChannelConfigurationKeysResponse}
     * @memberof ApiResponseGetChannelConfigurationKeysResponse
     */
    'data'?: GetChannelConfigurationKeysResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetChannelNameListResponse
 */
export interface ApiResponseGetChannelNameListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetChannelNameListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetChannelNameListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetChannelNameListResponse}
     * @memberof ApiResponseGetChannelNameListResponse
     */
    'data'?: GetChannelNameListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetContactsByMailboxResponse
 */
export interface ApiResponseGetContactsByMailboxResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetContactsByMailboxResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetContactsByMailboxResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetContactsByMailboxResponse}
     * @memberof ApiResponseGetContactsByMailboxResponse
     */
    'data'?: GetContactsByMailboxResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetContentSchemaByIdResponse
 */
export interface ApiResponseGetContentSchemaByIdResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetContentSchemaByIdResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetContentSchemaByIdResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetContentSchemaByIdResponse}
     * @memberof ApiResponseGetContentSchemaByIdResponse
     */
    'data'?: GetContentSchemaByIdResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetContentSchemaListResponse
 */
export interface ApiResponseGetContentSchemaListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetContentSchemaListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetContentSchemaListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetContentSchemaListResponse}
     * @memberof ApiResponseGetContentSchemaListResponse
     */
    'data'?: GetContentSchemaListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetDownloadAttachmentResponse
 */
export interface ApiResponseGetDownloadAttachmentResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetDownloadAttachmentResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetDownloadAttachmentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetDownloadAttachmentResponse}
     * @memberof ApiResponseGetDownloadAttachmentResponse
     */
    'data'?: GetDownloadAttachmentResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetEmailBoxMailsResponse
 */
export interface ApiResponseGetEmailBoxMailsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetEmailBoxMailsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetEmailBoxMailsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetEmailBoxMailsResponse}
     * @memberof ApiResponseGetEmailBoxMailsResponse
     */
    'data'?: GetEmailBoxMailsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetEscalationActionResponse
 */
export interface ApiResponseGetEscalationActionResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetEscalationActionResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetEscalationActionResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetEscalationActionResponse}
     * @memberof ApiResponseGetEscalationActionResponse
     */
    'data'?: GetEscalationActionResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetEscalationRulesResponseEscalationRuleDtoV1
 */
export interface ApiResponseGetEscalationRulesResponseEscalationRuleDtoV1 {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetEscalationRulesResponseEscalationRuleDtoV1
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetEscalationRulesResponseEscalationRuleDtoV1
     */
    'message'?: string;
    /**
     * 
     * @type {GetEscalationRulesResponseEscalationRuleDtoV1}
     * @memberof ApiResponseGetEscalationRulesResponseEscalationRuleDtoV1
     */
    'data'?: GetEscalationRulesResponseEscalationRuleDtoV1;
}
/**
 * 
 * @export
 * @interface ApiResponseGetEventNameListResponse
 */
export interface ApiResponseGetEventNameListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetEventNameListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetEventNameListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetEventNameListResponse}
     * @memberof ApiResponseGetEventNameListResponse
     */
    'data'?: GetEventNameListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetExportJobDownloadUrlResponse
 */
export interface ApiResponseGetExportJobDownloadUrlResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetExportJobDownloadUrlResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetExportJobDownloadUrlResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetExportJobDownloadUrlResponse}
     * @memberof ApiResponseGetExportJobDownloadUrlResponse
     */
    'data'?: GetExportJobDownloadUrlResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetExportJobListResponse
 */
export interface ApiResponseGetExportJobListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetExportJobListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetExportJobListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetExportJobListResponse}
     * @memberof ApiResponseGetExportJobListResponse
     */
    'data'?: GetExportJobListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetInstancesTaskResponse
 */
export interface ApiResponseGetInstancesTaskResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetInstancesTaskResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetInstancesTaskResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetInstancesTaskResponse}
     * @memberof ApiResponseGetInstancesTaskResponse
     */
    'data'?: GetInstancesTaskResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetItemInstanceListOfServiceInstanceResponse
 */
export interface ApiResponseGetItemInstanceListOfServiceInstanceResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetItemInstanceListOfServiceInstanceResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetItemInstanceListOfServiceInstanceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetItemInstanceListOfServiceInstanceResponse}
     * @memberof ApiResponseGetItemInstanceListOfServiceInstanceResponse
     */
    'data'?: GetItemInstanceListOfServiceInstanceResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetItemInstanceStatusChangeLogResponse
 */
export interface ApiResponseGetItemInstanceStatusChangeLogResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetItemInstanceStatusChangeLogResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetItemInstanceStatusChangeLogResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetItemInstanceStatusChangeLogResponse}
     * @memberof ApiResponseGetItemInstanceStatusChangeLogResponse
     */
    'data'?: GetItemInstanceStatusChangeLogResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetItemInstanceStatusMetaDataResponse
 */
export interface ApiResponseGetItemInstanceStatusMetaDataResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetItemInstanceStatusMetaDataResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetItemInstanceStatusMetaDataResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetItemInstanceStatusMetaDataResponse}
     * @memberof ApiResponseGetItemInstanceStatusMetaDataResponse
     */
    'data'?: GetItemInstanceStatusMetaDataResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetItemKnowledgeResponse
 */
export interface ApiResponseGetItemKnowledgeResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetItemKnowledgeResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetItemKnowledgeResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetItemKnowledgeResponse}
     * @memberof ApiResponseGetItemKnowledgeResponse
     */
    'data'?: GetItemKnowledgeResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetItemsResponse
 */
export interface ApiResponseGetItemsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetItemsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetItemsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetItemsResponse}
     * @memberof ApiResponseGetItemsResponse
     */
    'data'?: GetItemsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetKnowledgeBaseCategoriesResponse
 */
export interface ApiResponseGetKnowledgeBaseCategoriesResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetKnowledgeBaseCategoriesResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetKnowledgeBaseCategoriesResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetKnowledgeBaseCategoriesResponse}
     * @memberof ApiResponseGetKnowledgeBaseCategoriesResponse
     */
    'data'?: GetKnowledgeBaseCategoriesResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetKnowledgeBaseCategoryTreeResponse
 */
export interface ApiResponseGetKnowledgeBaseCategoryTreeResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetKnowledgeBaseCategoryTreeResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetKnowledgeBaseCategoryTreeResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetKnowledgeBaseCategoryTreeResponse}
     * @memberof ApiResponseGetKnowledgeBaseCategoryTreeResponse
     */
    'data'?: GetKnowledgeBaseCategoryTreeResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetLoginUserResponse
 */
export interface ApiResponseGetLoginUserResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetLoginUserResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetLoginUserResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetLoginUserResponse}
     * @memberof ApiResponseGetLoginUserResponse
     */
    'data'?: GetLoginUserResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailActionStatusResponse
 */
export interface ApiResponseGetMailActionStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailActionStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailActionStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailActionStatusResponse}
     * @memberof ApiResponseGetMailActionStatusResponse
     */
    'data'?: GetMailActionStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailBoxAccessResponse
 */
export interface ApiResponseGetMailBoxAccessResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailBoxAccessResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailBoxAccessResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailBoxAccessResponse}
     * @memberof ApiResponseGetMailBoxAccessResponse
     */
    'data'?: GetMailBoxAccessResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailBoxStateResponse
 */
export interface ApiResponseGetMailBoxStateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailBoxStateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailBoxStateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailBoxStateResponse}
     * @memberof ApiResponseGetMailBoxStateResponse
     */
    'data'?: GetMailBoxStateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailContactsResponse
 */
export interface ApiResponseGetMailContactsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailContactsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailContactsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailContactsResponse}
     * @memberof ApiResponseGetMailContactsResponse
     */
    'data'?: GetMailContactsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailContentDownloadUrlResponse
 */
export interface ApiResponseGetMailContentDownloadUrlResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailContentDownloadUrlResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailContentDownloadUrlResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailContentDownloadUrlResponse}
     * @memberof ApiResponseGetMailContentDownloadUrlResponse
     */
    'data'?: GetMailContentDownloadUrlResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailContentNamesResponse
 */
export interface ApiResponseGetMailContentNamesResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailContentNamesResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailContentNamesResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailContentNamesResponse}
     * @memberof ApiResponseGetMailContentNamesResponse
     */
    'data'?: GetMailContentNamesResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailForApprovingResponse
 */
export interface ApiResponseGetMailForApprovingResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailForApprovingResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailForApprovingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailForApprovingResponse}
     * @memberof ApiResponseGetMailForApprovingResponse
     */
    'data'?: GetMailForApprovingResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailListResponse
 */
export interface ApiResponseGetMailListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailListResponse}
     * @memberof ApiResponseGetMailListResponse
     */
    'data'?: GetMailListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailTemplateByIdResponse
 */
export interface ApiResponseGetMailTemplateByIdResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailTemplateByIdResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailTemplateByIdResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailTemplateByIdResponse}
     * @memberof ApiResponseGetMailTemplateByIdResponse
     */
    'data'?: GetMailTemplateByIdResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailTemplateContentResponse
 */
export interface ApiResponseGetMailTemplateContentResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailTemplateContentResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailTemplateContentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailTemplateContentResponse}
     * @memberof ApiResponseGetMailTemplateContentResponse
     */
    'data'?: GetMailTemplateContentResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailTemplateListResponse
 */
export interface ApiResponseGetMailTemplateListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailTemplateListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailTemplateListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailTemplateListResponse}
     * @memberof ApiResponseGetMailTemplateListResponse
     */
    'data'?: GetMailTemplateListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailValidationMessageResponse
 */
export interface ApiResponseGetMailValidationMessageResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailValidationMessageResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailValidationMessageResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailValidationMessageResponse}
     * @memberof ApiResponseGetMailValidationMessageResponse
     */
    'data'?: GetMailValidationMessageResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetMailVendorListResponse
 */
export interface ApiResponseGetMailVendorListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetMailVendorListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetMailVendorListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetMailVendorListResponse}
     * @memberof ApiResponseGetMailVendorListResponse
     */
    'data'?: GetMailVendorListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetNavigationResponse
 */
export interface ApiResponseGetNavigationResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetNavigationResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetNavigationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetNavigationResponse}
     * @memberof ApiResponseGetNavigationResponse
     */
    'data'?: GetNavigationResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetNotificationEventByIdResponse
 */
export interface ApiResponseGetNotificationEventByIdResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetNotificationEventByIdResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetNotificationEventByIdResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetNotificationEventByIdResponse}
     * @memberof ApiResponseGetNotificationEventByIdResponse
     */
    'data'?: GetNotificationEventByIdResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetNotificationEventByTopicResponse
 */
export interface ApiResponseGetNotificationEventByTopicResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetNotificationEventByTopicResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetNotificationEventByTopicResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetNotificationEventByTopicResponse}
     * @memberof ApiResponseGetNotificationEventByTopicResponse
     */
    'data'?: GetNotificationEventByTopicResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetNotificationTemplateListResponse
 */
export interface ApiResponseGetNotificationTemplateListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetNotificationTemplateListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetNotificationTemplateListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetNotificationTemplateListResponse}
     * @memberof ApiResponseGetNotificationTemplateListResponse
     */
    'data'?: GetNotificationTemplateListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetPluginByIdResponse
 */
export interface ApiResponseGetPluginByIdResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetPluginByIdResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetPluginByIdResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetPluginByIdResponse}
     * @memberof ApiResponseGetPluginByIdResponse
     */
    'data'?: GetPluginByIdResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetPluginListResponse
 */
export interface ApiResponseGetPluginListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetPluginListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetPluginListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetPluginListResponse}
     * @memberof ApiResponseGetPluginListResponse
     */
    'data'?: GetPluginListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetPolicyListResponse
 */
export interface ApiResponseGetPolicyListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetPolicyListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetPolicyListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetPolicyListResponse}
     * @memberof ApiResponseGetPolicyListResponse
     */
    'data'?: GetPolicyListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetPolicyResponse
 */
export interface ApiResponseGetPolicyResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetPolicyResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetPolicyResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetPolicyResponse}
     * @memberof ApiResponseGetPolicyResponse
     */
    'data'?: GetPolicyResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetPrivilegeListResponse
 */
export interface ApiResponseGetPrivilegeListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetPrivilegeListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetPrivilegeListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetPrivilegeListResponse}
     * @memberof ApiResponseGetPrivilegeListResponse
     */
    'data'?: GetPrivilegeListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProcessListResponse
 */
export interface ApiResponseGetProcessListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProcessListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProcessListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProcessListResponse}
     * @memberof ApiResponseGetProcessListResponse
     */
    'data'?: GetProcessListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProcessResponse
 */
export interface ApiResponseGetProcessResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProcessResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProcessResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProcessResponse}
     * @memberof ApiResponseGetProcessResponse
     */
    'data'?: GetProcessResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProcessTemplateResponse
 */
export interface ApiResponseGetProcessTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProcessTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProcessTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProcessTemplateResponse}
     * @memberof ApiResponseGetProcessTemplateResponse
     */
    'data'?: GetProcessTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProjectInstanceByParticipantAndParticipantTypeResponse
 */
export interface ApiResponseGetProjectInstanceByParticipantAndParticipantTypeResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProjectInstanceByParticipantAndParticipantTypeResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProjectInstanceByParticipantAndParticipantTypeResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProjectInstanceByParticipantAndParticipantTypeResponse}
     * @memberof ApiResponseGetProjectInstanceByParticipantAndParticipantTypeResponse
     */
    'data'?: GetProjectInstanceByParticipantAndParticipantTypeResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProjectInstanceListResponse
 */
export interface ApiResponseGetProjectInstanceListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProjectInstanceListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProjectInstanceListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProjectInstanceListResponse}
     * @memberof ApiResponseGetProjectInstanceListResponse
     */
    'data'?: GetProjectInstanceListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProjectInstanceOfOwnerByStatusResponse
 */
export interface ApiResponseGetProjectInstanceOfOwnerByStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProjectInstanceOfOwnerByStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProjectInstanceOfOwnerByStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProjectInstanceOfOwnerByStatusResponse}
     * @memberof ApiResponseGetProjectInstanceOfOwnerByStatusResponse
     */
    'data'?: GetProjectInstanceOfOwnerByStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProjectMetaDataListResponse
 */
export interface ApiResponseGetProjectMetaDataListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProjectMetaDataListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProjectMetaDataListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProjectMetaDataListResponse}
     * @memberof ApiResponseGetProjectMetaDataListResponse
     */
    'data'?: GetProjectMetaDataListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProjectStatusMetaDataResponse
 */
export interface ApiResponseGetProjectStatusMetaDataResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProjectStatusMetaDataResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProjectStatusMetaDataResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProjectStatusMetaDataResponse}
     * @memberof ApiResponseGetProjectStatusMetaDataResponse
     */
    'data'?: GetProjectStatusMetaDataResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetProjectWorkbenchDataResponse
 */
export interface ApiResponseGetProjectWorkbenchDataResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetProjectWorkbenchDataResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetProjectWorkbenchDataResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetProjectWorkbenchDataResponse}
     * @memberof ApiResponseGetProjectWorkbenchDataResponse
     */
    'data'?: GetProjectWorkbenchDataResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetPublicInDomainResponse
 */
export interface ApiResponseGetPublicInDomainResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetPublicInDomainResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetPublicInDomainResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetPublicInDomainResponse}
     * @memberof ApiResponseGetPublicInDomainResponse
     */
    'data'?: GetPublicInDomainResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetReflexStrategyResponse
 */
export interface ApiResponseGetReflexStrategyResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetReflexStrategyResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetReflexStrategyResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetReflexStrategyResponse}
     * @memberof ApiResponseGetReflexStrategyResponse
     */
    'data'?: GetReflexStrategyResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetRelatedTicketListResponse
 */
export interface ApiResponseGetRelatedTicketListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetRelatedTicketListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetRelatedTicketListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetRelatedTicketListResponse}
     * @memberof ApiResponseGetRelatedTicketListResponse
     */
    'data'?: GetRelatedTicketListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetRelatedTicketsResponse
 */
export interface ApiResponseGetRelatedTicketsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetRelatedTicketsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetRelatedTicketsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetRelatedTicketsResponse}
     * @memberof ApiResponseGetRelatedTicketsResponse
     */
    'data'?: GetRelatedTicketsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetRemoteInstanceConnectiveStatusResponse
 */
export interface ApiResponseGetRemoteInstanceConnectiveStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetRemoteInstanceConnectiveStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetRemoteInstanceConnectiveStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetRemoteInstanceConnectiveStatusResponse}
     * @memberof ApiResponseGetRemoteInstanceConnectiveStatusResponse
     */
    'data'?: GetRemoteInstanceConnectiveStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetRemoteInstancesResponse
 */
export interface ApiResponseGetRemoteInstancesResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetRemoteInstancesResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetRemoteInstancesResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetRemoteInstancesResponse}
     * @memberof ApiResponseGetRemoteInstancesResponse
     */
    'data'?: GetRemoteInstancesResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetSendingEmailAttachmentTargetResponse
 */
export interface ApiResponseGetSendingEmailAttachmentTargetResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetSendingEmailAttachmentTargetResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetSendingEmailAttachmentTargetResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetSendingEmailAttachmentTargetResponse}
     * @memberof ApiResponseGetSendingEmailAttachmentTargetResponse
     */
    'data'?: GetSendingEmailAttachmentTargetResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetSendingEmailDetailResponse
 */
export interface ApiResponseGetSendingEmailDetailResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetSendingEmailDetailResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetSendingEmailDetailResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetSendingEmailDetailResponse}
     * @memberof ApiResponseGetSendingEmailDetailResponse
     */
    'data'?: GetSendingEmailDetailResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetSendingEmailResponse
 */
export interface ApiResponseGetSendingEmailResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetSendingEmailResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetSendingEmailResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetSendingEmailResponse}
     * @memberof ApiResponseGetSendingEmailResponse
     */
    'data'?: GetSendingEmailResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetServiceInstanceDeliveryMaterialResponse
 */
export interface ApiResponseGetServiceInstanceDeliveryMaterialResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetServiceInstanceDeliveryMaterialResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetServiceInstanceDeliveryMaterialResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetServiceInstanceDeliveryMaterialResponse}
     * @memberof ApiResponseGetServiceInstanceDeliveryMaterialResponse
     */
    'data'?: GetServiceInstanceDeliveryMaterialResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetServiceInstanceStatusChangeLogResponse
 */
export interface ApiResponseGetServiceInstanceStatusChangeLogResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetServiceInstanceStatusChangeLogResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetServiceInstanceStatusChangeLogResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetServiceInstanceStatusChangeLogResponse}
     * @memberof ApiResponseGetServiceInstanceStatusChangeLogResponse
     */
    'data'?: GetServiceInstanceStatusChangeLogResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetStatusOfTicketResponse
 */
export interface ApiResponseGetStatusOfTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetStatusOfTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetStatusOfTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetStatusOfTicketResponse}
     * @memberof ApiResponseGetStatusOfTicketResponse
     */
    'data'?: GetStatusOfTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetSubscribeProjectInstanceResponse
 */
export interface ApiResponseGetSubscribeProjectInstanceResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetSubscribeProjectInstanceResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetSubscribeProjectInstanceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetSubscribeProjectInstanceResponse}
     * @memberof ApiResponseGetSubscribeProjectInstanceResponse
     */
    'data'?: GetSubscribeProjectInstanceResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetSystemSettingItemResponse
 */
export interface ApiResponseGetSystemSettingItemResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetSystemSettingItemResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetSystemSettingItemResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetSystemSettingItemResponse}
     * @memberof ApiResponseGetSystemSettingItemResponse
     */
    'data'?: GetSystemSettingItemResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetSystemSettingResponse
 */
export interface ApiResponseGetSystemSettingResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetSystemSettingResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetSystemSettingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetSystemSettingResponse}
     * @memberof ApiResponseGetSystemSettingResponse
     */
    'data'?: GetSystemSettingResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTagByIdResponse
 */
export interface ApiResponseGetTagByIdResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTagByIdResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTagByIdResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTagByIdResponse}
     * @memberof ApiResponseGetTagByIdResponse
     */
    'data'?: GetTagByIdResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTagListResponse
 */
export interface ApiResponseGetTagListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTagListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTagListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTagListResponse}
     * @memberof ApiResponseGetTagListResponse
     */
    'data'?: GetTagListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTaskProgressInfoResponse
 */
export interface ApiResponseGetTaskProgressInfoResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTaskProgressInfoResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTaskProgressInfoResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTaskProgressInfoResponse}
     * @memberof ApiResponseGetTaskProgressInfoResponse
     */
    'data'?: GetTaskProgressInfoResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTaskResponse
 */
export interface ApiResponseGetTaskResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTaskResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTaskResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTaskResponse}
     * @memberof ApiResponseGetTaskResponse
     */
    'data'?: GetTaskResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTemplatesOfMailboxResponse
 */
export interface ApiResponseGetTemplatesOfMailboxResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTemplatesOfMailboxResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTemplatesOfMailboxResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTemplatesOfMailboxResponse}
     * @memberof ApiResponseGetTemplatesOfMailboxResponse
     */
    'data'?: GetTemplatesOfMailboxResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketAssigneeChangeThreadsResponse
 */
export interface ApiResponseGetTicketAssigneeChangeThreadsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketAssigneeChangeThreadsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketAssigneeChangeThreadsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketAssigneeChangeThreadsResponse}
     * @memberof ApiResponseGetTicketAssigneeChangeThreadsResponse
     */
    'data'?: GetTicketAssigneeChangeThreadsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketAttachmentMetaResponse
 */
export interface ApiResponseGetTicketAttachmentMetaResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketAttachmentMetaResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketAttachmentMetaResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketAttachmentMetaResponse}
     * @memberof ApiResponseGetTicketAttachmentMetaResponse
     */
    'data'?: GetTicketAttachmentMetaResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketAttachmentResponse
 */
export interface ApiResponseGetTicketAttachmentResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketAttachmentResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketAttachmentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketAttachmentResponse}
     * @memberof ApiResponseGetTicketAttachmentResponse
     */
    'data'?: GetTicketAttachmentResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketContentResponse
 */
export interface ApiResponseGetTicketContentResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketContentResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketContentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketContentResponse}
     * @memberof ApiResponseGetTicketContentResponse
     */
    'data'?: GetTicketContentResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketListResponse
 */
export interface ApiResponseGetTicketListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketListResponse}
     * @memberof ApiResponseGetTicketListResponse
     */
    'data'?: GetTicketListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketPriorityListResponse
 */
export interface ApiResponseGetTicketPriorityListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketPriorityListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketPriorityListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketPriorityListResponse}
     * @memberof ApiResponseGetTicketPriorityListResponse
     */
    'data'?: GetTicketPriorityListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketResponse
 */
export interface ApiResponseGetTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketResponse}
     * @memberof ApiResponseGetTicketResponse
     */
    'data'?: GetTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketSourceListResponse
 */
export interface ApiResponseGetTicketSourceListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketSourceListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketSourceListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketSourceListResponse}
     * @memberof ApiResponseGetTicketSourceListResponse
     */
    'data'?: GetTicketSourceListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketStatusListResponse
 */
export interface ApiResponseGetTicketStatusListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketStatusListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketStatusListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketStatusListResponse}
     * @memberof ApiResponseGetTicketStatusListResponse
     */
    'data'?: GetTicketStatusListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketStatusResponse
 */
export interface ApiResponseGetTicketStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketStatusResponse}
     * @memberof ApiResponseGetTicketStatusResponse
     */
    'data'?: GetTicketStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketTagsResponse
 */
export interface ApiResponseGetTicketTagsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketTagsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketTagsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketTagsResponse}
     * @memberof ApiResponseGetTicketTagsResponse
     */
    'data'?: GetTicketTagsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketTemplateResponse
 */
export interface ApiResponseGetTicketTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketTemplateResponse}
     * @memberof ApiResponseGetTicketTemplateResponse
     */
    'data'?: GetTicketTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketThreadsResponse
 */
export interface ApiResponseGetTicketThreadsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketThreadsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketThreadsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketThreadsResponse}
     * @memberof ApiResponseGetTicketThreadsResponse
     */
    'data'?: GetTicketThreadsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketTypeListResponse
 */
export interface ApiResponseGetTicketTypeListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketTypeListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketTypeListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketTypeListResponse}
     * @memberof ApiResponseGetTicketTypeListResponse
     */
    'data'?: GetTicketTypeListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetTicketTypeResponse
 */
export interface ApiResponseGetTicketTypeResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetTicketTypeResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetTicketTypeResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetTicketTypeResponse}
     * @memberof ApiResponseGetTicketTypeResponse
     */
    'data'?: GetTicketTypeResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetUserGroupListResponse
 */
export interface ApiResponseGetUserGroupListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetUserGroupListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetUserGroupListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetUserGroupListResponse}
     * @memberof ApiResponseGetUserGroupListResponse
     */
    'data'?: GetUserGroupListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetUserGroupResponse
 */
export interface ApiResponseGetUserGroupResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetUserGroupResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetUserGroupResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetUserGroupResponse}
     * @memberof ApiResponseGetUserGroupResponse
     */
    'data'?: GetUserGroupResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetUserListResponse
 */
export interface ApiResponseGetUserListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetUserListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetUserListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetUserListResponse}
     * @memberof ApiResponseGetUserListResponse
     */
    'data'?: GetUserListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetUserParticipatingResponse
 */
export interface ApiResponseGetUserParticipatingResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetUserParticipatingResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetUserParticipatingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetUserParticipatingResponse}
     * @memberof ApiResponseGetUserParticipatingResponse
     */
    'data'?: GetUserParticipatingResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetUserResponse
 */
export interface ApiResponseGetUserResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetUserResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetUserResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetUserResponse}
     * @memberof ApiResponseGetUserResponse
     */
    'data'?: GetUserResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetUserSourceListResponse
 */
export interface ApiResponseGetUserSourceListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetUserSourceListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetUserSourceListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetUserSourceListResponse}
     * @memberof ApiResponseGetUserSourceListResponse
     */
    'data'?: GetUserSourceListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetUserSourceResponse
 */
export interface ApiResponseGetUserSourceResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetUserSourceResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetUserSourceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetUserSourceResponse}
     * @memberof ApiResponseGetUserSourceResponse
     */
    'data'?: GetUserSourceResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseGetVendorMailListResponse
 */
export interface ApiResponseGetVendorMailListResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseGetVendorMailListResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseGetVendorMailListResponse
     */
    'message'?: string;
    /**
     * 
     * @type {GetVendorMailListResponse}
     * @memberof ApiResponseGetVendorMailListResponse
     */
    'data'?: GetVendorMailListResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseHistoryStatisticsDataResponse
 */
export interface ApiResponseHistoryStatisticsDataResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseHistoryStatisticsDataResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseHistoryStatisticsDataResponse
     */
    'message'?: string;
    /**
     * 
     * @type {HistoryStatisticsDataResponse}
     * @memberof ApiResponseHistoryStatisticsDataResponse
     */
    'data'?: HistoryStatisticsDataResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseObject
 */
export interface ApiResponseObject {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseObject
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseObject
     */
    'message'?: string;
    /**
     * 响应请求的数据
     * @type {object}
     * @memberof ApiResponseObject
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface ApiResponseParticipatingTicketsResponse
 */
export interface ApiResponseParticipatingTicketsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseParticipatingTicketsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseParticipatingTicketsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ParticipatingTicketsResponse}
     * @memberof ApiResponseParticipatingTicketsResponse
     */
    'data'?: ParticipatingTicketsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseParticipatorStatisticsHistoryResponse
 */
export interface ApiResponseParticipatorStatisticsHistoryResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseParticipatorStatisticsHistoryResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseParticipatorStatisticsHistoryResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ParticipatorStatisticsHistoryResponse}
     * @memberof ApiResponseParticipatorStatisticsHistoryResponse
     */
    'data'?: ParticipatorStatisticsHistoryResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePatchCopyTicketResponse
 */
export interface ApiResponsePatchCopyTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePatchCopyTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePatchCopyTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PatchCopyTicketResponse}
     * @memberof ApiResponsePatchCopyTicketResponse
     */
    'data'?: PatchCopyTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePatchMailBoxStateResponse
 */
export interface ApiResponsePatchMailBoxStateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePatchMailBoxStateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePatchMailBoxStateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PatchMailBoxStateResponse}
     * @memberof ApiResponsePatchMailBoxStateResponse
     */
    'data'?: PatchMailBoxStateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePatchManualEvaluateResponse
 */
export interface ApiResponsePatchManualEvaluateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePatchManualEvaluateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePatchManualEvaluateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PatchManualEvaluateResponse}
     * @memberof ApiResponsePatchManualEvaluateResponse
     */
    'data'?: PatchManualEvaluateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePatchSendMailResponse
 */
export interface ApiResponsePatchSendMailResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePatchSendMailResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePatchSendMailResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PatchSendMailResponse}
     * @memberof ApiResponsePatchSendMailResponse
     */
    'data'?: PatchSendMailResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostAllocateTaskResponse
 */
export interface ApiResponsePostAllocateTaskResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostAllocateTaskResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostAllocateTaskResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostAllocateTaskResponse}
     * @memberof ApiResponsePostAllocateTaskResponse
     */
    'data'?: PostAllocateTaskResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostApproveMailSendApplicationResponse
 */
export interface ApiResponsePostApproveMailSendApplicationResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostApproveMailSendApplicationResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostApproveMailSendApplicationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostApproveMailSendApplicationResponse}
     * @memberof ApiResponsePostApproveMailSendApplicationResponse
     */
    'data'?: PostApproveMailSendApplicationResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostChangeExecutorResponse
 */
export interface ApiResponsePostChangeExecutorResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostChangeExecutorResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostChangeExecutorResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostChangeExecutorResponse}
     * @memberof ApiResponsePostChangeExecutorResponse
     */
    'data'?: PostChangeExecutorResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostCommitDeliveryMaterialResponse
 */
export interface ApiResponsePostCommitDeliveryMaterialResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostCommitDeliveryMaterialResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostCommitDeliveryMaterialResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostCommitDeliveryMaterialResponse}
     * @memberof ApiResponsePostCommitDeliveryMaterialResponse
     */
    'data'?: PostCommitDeliveryMaterialResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostCommitItemKnowledgeResponse
 */
export interface ApiResponsePostCommitItemKnowledgeResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostCommitItemKnowledgeResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostCommitItemKnowledgeResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostCommitItemKnowledgeResponse}
     * @memberof ApiResponsePostCommitItemKnowledgeResponse
     */
    'data'?: PostCommitItemKnowledgeResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostContentSchemaResponse
 */
export interface ApiResponsePostContentSchemaResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostContentSchemaResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostContentSchemaResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostContentSchemaResponse}
     * @memberof ApiResponsePostContentSchemaResponse
     */
    'data'?: PostContentSchemaResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostEscalationRuleResponseEscalationRuleDtoV1
 */
export interface ApiResponsePostEscalationRuleResponseEscalationRuleDtoV1 {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostEscalationRuleResponseEscalationRuleDtoV1
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostEscalationRuleResponseEscalationRuleDtoV1
     */
    'message'?: string;
    /**
     * 
     * @type {PostEscalationRuleResponseEscalationRuleDtoV1}
     * @memberof ApiResponsePostEscalationRuleResponseEscalationRuleDtoV1
     */
    'data'?: PostEscalationRuleResponseEscalationRuleDtoV1;
}
/**
 * 
 * @export
 * @interface ApiResponsePostExamineAndApproveServiceResponse
 */
export interface ApiResponsePostExamineAndApproveServiceResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostExamineAndApproveServiceResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostExamineAndApproveServiceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostExamineAndApproveServiceResponse}
     * @memberof ApiResponsePostExamineAndApproveServiceResponse
     */
    'data'?: PostExamineAndApproveServiceResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostExportResponse
 */
export interface ApiResponsePostExportResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostExportResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostExportResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostExportResponse}
     * @memberof ApiResponsePostExportResponse
     */
    'data'?: PostExportResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostKnowledgeCategoryResponse
 */
export interface ApiResponsePostKnowledgeCategoryResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostKnowledgeCategoryResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostKnowledgeCategoryResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostKnowledgeCategoryResponse}
     * @memberof ApiResponsePostKnowledgeCategoryResponse
     */
    'data'?: PostKnowledgeCategoryResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostKnowledgeItemResponse
 */
export interface ApiResponsePostKnowledgeItemResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostKnowledgeItemResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostKnowledgeItemResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostKnowledgeItemResponse}
     * @memberof ApiResponsePostKnowledgeItemResponse
     */
    'data'?: PostKnowledgeItemResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailBoxAccessResponse
 */
export interface ApiResponsePostMailBoxAccessResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailBoxAccessResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailBoxAccessResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailBoxAccessResponse}
     * @memberof ApiResponsePostMailBoxAccessResponse
     */
    'data'?: PostMailBoxAccessResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailBoxesResponse
 */
export interface ApiResponsePostMailBoxesResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailBoxesResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailBoxesResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailBoxesResponse}
     * @memberof ApiResponsePostMailBoxesResponse
     */
    'data'?: PostMailBoxesResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailContactsResponse
 */
export interface ApiResponsePostMailContactsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailContactsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailContactsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailContactsResponse}
     * @memberof ApiResponsePostMailContactsResponse
     */
    'data'?: PostMailContactsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailSendApplicationResponse
 */
export interface ApiResponsePostMailSendApplicationResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailSendApplicationResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailSendApplicationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailSendApplicationResponse}
     * @memberof ApiResponsePostMailSendApplicationResponse
     */
    'data'?: PostMailSendApplicationResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailTagsResponse
 */
export interface ApiResponsePostMailTagsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailTagsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailTagsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailTagsResponse}
     * @memberof ApiResponsePostMailTagsResponse
     */
    'data'?: PostMailTagsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailTemplateBindingResponse
 */
export interface ApiResponsePostMailTemplateBindingResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailTemplateBindingResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailTemplateBindingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailTemplateBindingResponse}
     * @memberof ApiResponsePostMailTemplateBindingResponse
     */
    'data'?: PostMailTemplateBindingResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailTemplateResponse
 */
export interface ApiResponsePostMailTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailTemplateResponse}
     * @memberof ApiResponsePostMailTemplateResponse
     */
    'data'?: PostMailTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailVendorResponse
 */
export interface ApiResponsePostMailVendorResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailVendorResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailVendorResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailVendorResponse}
     * @memberof ApiResponsePostMailVendorResponse
     */
    'data'?: PostMailVendorResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostMailboxContactBindingResponse
 */
export interface ApiResponsePostMailboxContactBindingResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostMailboxContactBindingResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostMailboxContactBindingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostMailboxContactBindingResponse}
     * @memberof ApiResponsePostMailboxContactBindingResponse
     */
    'data'?: PostMailboxContactBindingResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostNotificationEventResponse
 */
export interface ApiResponsePostNotificationEventResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostNotificationEventResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostNotificationEventResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostNotificationEventResponse}
     * @memberof ApiResponsePostNotificationEventResponse
     */
    'data'?: PostNotificationEventResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostNotificationResponse
 */
export interface ApiResponsePostNotificationResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostNotificationResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostNotificationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostNotificationResponse}
     * @memberof ApiResponsePostNotificationResponse
     */
    'data'?: PostNotificationResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostPluginConfigureResponse
 */
export interface ApiResponsePostPluginConfigureResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostPluginConfigureResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostPluginConfigureResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostPluginConfigureResponse}
     * @memberof ApiResponsePostPluginConfigureResponse
     */
    'data'?: PostPluginConfigureResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostPluginResponse
 */
export interface ApiResponsePostPluginResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostPluginResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostPluginResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostPluginResponse}
     * @memberof ApiResponsePostPluginResponse
     */
    'data'?: PostPluginResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostProcessResponse
 */
export interface ApiResponsePostProcessResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostProcessResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostProcessResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostProcessResponse}
     * @memberof ApiResponsePostProcessResponse
     */
    'data'?: PostProcessResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostProjectServiceDraftResponse
 */
export interface ApiResponsePostProjectServiceDraftResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostProjectServiceDraftResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostProjectServiceDraftResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostProjectServiceDraftResponse}
     * @memberof ApiResponsePostProjectServiceDraftResponse
     */
    'data'?: PostProjectServiceDraftResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostProjectServiceMetaResponse
 */
export interface ApiResponsePostProjectServiceMetaResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostProjectServiceMetaResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostProjectServiceMetaResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostProjectServiceMetaResponse}
     * @memberof ApiResponsePostProjectServiceMetaResponse
     */
    'data'?: PostProjectServiceMetaResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostProjectServiceStatusResponse
 */
export interface ApiResponsePostProjectServiceStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostProjectServiceStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostProjectServiceStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostProjectServiceStatusResponse}
     * @memberof ApiResponsePostProjectServiceStatusResponse
     */
    'data'?: PostProjectServiceStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostReEvalCurrentStageAssigneeResponse
 */
export interface ApiResponsePostReEvalCurrentStageAssigneeResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostReEvalCurrentStageAssigneeResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostReEvalCurrentStageAssigneeResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostReEvalCurrentStageAssigneeResponse}
     * @memberof ApiResponsePostReEvalCurrentStageAssigneeResponse
     */
    'data'?: PostReEvalCurrentStageAssigneeResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostRelatedTicketResponse
 */
export interface ApiResponsePostRelatedTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostRelatedTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostRelatedTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostRelatedTicketResponse}
     * @memberof ApiResponsePostRelatedTicketResponse
     */
    'data'?: PostRelatedTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostSendMailResponse
 */
export interface ApiResponsePostSendMailResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostSendMailResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostSendMailResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostSendMailResponse}
     * @memberof ApiResponsePostSendMailResponse
     */
    'data'?: PostSendMailResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostServiceInstanceSubscribeServiceResponse
 */
export interface ApiResponsePostServiceInstanceSubscribeServiceResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostServiceInstanceSubscribeServiceResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostServiceInstanceSubscribeServiceResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostServiceInstanceSubscribeServiceResponse}
     * @memberof ApiResponsePostServiceInstanceSubscribeServiceResponse
     */
    'data'?: PostServiceInstanceSubscribeServiceResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostServiceItemChangeStatusResponse
 */
export interface ApiResponsePostServiceItemChangeStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostServiceItemChangeStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostServiceItemChangeStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostServiceItemChangeStatusResponse}
     * @memberof ApiResponsePostServiceItemChangeStatusResponse
     */
    'data'?: PostServiceItemChangeStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostSetTicketVisibilityResponse
 */
export interface ApiResponsePostSetTicketVisibilityResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostSetTicketVisibilityResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostSetTicketVisibilityResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostSetTicketVisibilityResponse}
     * @memberof ApiResponsePostSetTicketVisibilityResponse
     */
    'data'?: PostSetTicketVisibilityResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostStartFollowupItemResponse
 */
export interface ApiResponsePostStartFollowupItemResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostStartFollowupItemResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostStartFollowupItemResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostStartFollowupItemResponse}
     * @memberof ApiResponsePostStartFollowupItemResponse
     */
    'data'?: PostStartFollowupItemResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostTaskResponse
 */
export interface ApiResponsePostTaskResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostTaskResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostTaskResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostTaskResponse}
     * @memberof ApiResponsePostTaskResponse
     */
    'data'?: PostTaskResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostTemplateResponse
 */
export interface ApiResponsePostTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostTemplateResponse}
     * @memberof ApiResponsePostTemplateResponse
     */
    'data'?: PostTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostTicketAttachmentResponse
 */
export interface ApiResponsePostTicketAttachmentResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostTicketAttachmentResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostTicketAttachmentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostTicketAttachmentResponse}
     * @memberof ApiResponsePostTicketAttachmentResponse
     */
    'data'?: PostTicketAttachmentResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostTicketResponse
 */
export interface ApiResponsePostTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostTicketResponse}
     * @memberof ApiResponsePostTicketResponse
     */
    'data'?: PostTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostTicketTemplateResponse
 */
export interface ApiResponsePostTicketTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostTicketTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostTicketTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostTicketTemplateResponse}
     * @memberof ApiResponsePostTicketTemplateResponse
     */
    'data'?: PostTicketTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostTicketThreadResponse
 */
export interface ApiResponsePostTicketThreadResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostTicketThreadResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostTicketThreadResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostTicketThreadResponse}
     * @memberof ApiResponsePostTicketThreadResponse
     */
    'data'?: PostTicketThreadResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostTriggerEscalationRuleResponse
 */
export interface ApiResponsePostTriggerEscalationRuleResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostTriggerEscalationRuleResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostTriggerEscalationRuleResponse
     */
    'message'?: string;
    /**
     * 响应请求的数据
     * @type {object}
     * @memberof ApiResponsePostTriggerEscalationRuleResponse
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @interface ApiResponsePostUpdateExportJobResponse
 */
export interface ApiResponsePostUpdateExportJobResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostUpdateExportJobResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostUpdateExportJobResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostUpdateExportJobResponse}
     * @memberof ApiResponsePostUpdateExportJobResponse
     */
    'data'?: PostUpdateExportJobResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostUploadCompletedResponse
 */
export interface ApiResponsePostUploadCompletedResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostUploadCompletedResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostUploadCompletedResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostUploadCompletedResponse}
     * @memberof ApiResponsePostUploadCompletedResponse
     */
    'data'?: PostUploadCompletedResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostUserGroupResponse
 */
export interface ApiResponsePostUserGroupResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostUserGroupResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostUserGroupResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostUserGroupResponse}
     * @memberof ApiResponsePostUserGroupResponse
     */
    'data'?: PostUserGroupResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostUserResponse
 */
export interface ApiResponsePostUserResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostUserResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostUserResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostUserResponse}
     * @memberof ApiResponsePostUserResponse
     */
    'data'?: PostUserResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostVerifyProcessResponse
 */
export interface ApiResponsePostVerifyProcessResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostVerifyProcessResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostVerifyProcessResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostVerifyProcessResponse}
     * @memberof ApiResponsePostVerifyProcessResponse
     */
    'data'?: PostVerifyProcessResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePostWatchTicketResponse
 */
export interface ApiResponsePostWatchTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePostWatchTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePostWatchTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PostWatchTicketResponse}
     * @memberof ApiResponsePostWatchTicketResponse
     */
    'data'?: PostWatchTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseProjectGetServiceItemTagResponse
 */
export interface ApiResponseProjectGetServiceItemTagResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseProjectGetServiceItemTagResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseProjectGetServiceItemTagResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ProjectGetServiceItemTagResponse}
     * @memberof ApiResponseProjectGetServiceItemTagResponse
     */
    'data'?: ProjectGetServiceItemTagResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseProjectGetServiceListTagResponse
 */
export interface ApiResponseProjectGetServiceListTagResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseProjectGetServiceListTagResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseProjectGetServiceListTagResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ProjectGetServiceListTagResponse}
     * @memberof ApiResponseProjectGetServiceListTagResponse
     */
    'data'?: ProjectGetServiceListTagResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseProjectServiceInstanceCheckFinishedModel
 */
export interface ApiResponseProjectServiceInstanceCheckFinishedModel {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseProjectServiceInstanceCheckFinishedModel
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseProjectServiceInstanceCheckFinishedModel
     */
    'message'?: string;
    /**
     * 
     * @type {ProjectServiceInstanceCheckFinishedModel}
     * @memberof ApiResponseProjectServiceInstanceCheckFinishedModel
     */
    'data'?: ProjectServiceInstanceCheckFinishedModel;
}
/**
 * 
 * @export
 * @interface ApiResponseProjectServiceTagModel
 */
export interface ApiResponseProjectServiceTagModel {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseProjectServiceTagModel
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseProjectServiceTagModel
     */
    'message'?: string;
    /**
     * 
     * @type {ProjectServiceTagModel}
     * @memberof ApiResponseProjectServiceTagModel
     */
    'data'?: ProjectServiceTagModel;
}
/**
 * 
 * @export
 * @interface ApiResponsePutAssignTicketResponse
 */
export interface ApiResponsePutAssignTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutAssignTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutAssignTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutAssignTicketResponse}
     * @memberof ApiResponsePutAssignTicketResponse
     */
    'data'?: PutAssignTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutAssignTicketsResponse
 */
export interface ApiResponsePutAssignTicketsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutAssignTicketsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutAssignTicketsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutAssignTicketsResponse}
     * @memberof ApiResponsePutAssignTicketsResponse
     */
    'data'?: PutAssignTicketsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutAutomaticProcessTicketContentResponse
 */
export interface ApiResponsePutAutomaticProcessTicketContentResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutAutomaticProcessTicketContentResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutAutomaticProcessTicketContentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutAutomaticProcessTicketContentResponse}
     * @memberof ApiResponsePutAutomaticProcessTicketContentResponse
     */
    'data'?: PutAutomaticProcessTicketContentResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutAutomaticProcessTicketResponse
 */
export interface ApiResponsePutAutomaticProcessTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutAutomaticProcessTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutAutomaticProcessTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutAutomaticProcessTicketResponse}
     * @memberof ApiResponsePutAutomaticProcessTicketResponse
     */
    'data'?: PutAutomaticProcessTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutChannelConfigurationResponse
 */
export interface ApiResponsePutChannelConfigurationResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutChannelConfigurationResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutChannelConfigurationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutChannelConfigurationResponse}
     * @memberof ApiResponsePutChannelConfigurationResponse
     */
    'data'?: PutChannelConfigurationResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutContentSchemaResponse
 */
export interface ApiResponsePutContentSchemaResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutContentSchemaResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutContentSchemaResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutContentSchemaResponse}
     * @memberof ApiResponsePutContentSchemaResponse
     */
    'data'?: PutContentSchemaResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutDraftMailResponse
 */
export interface ApiResponsePutDraftMailResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutDraftMailResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutDraftMailResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutDraftMailResponse}
     * @memberof ApiResponsePutDraftMailResponse
     */
    'data'?: PutDraftMailResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutEscalationActionResponse
 */
export interface ApiResponsePutEscalationActionResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutEscalationActionResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutEscalationActionResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutEscalationActionResponse}
     * @memberof ApiResponsePutEscalationActionResponse
     */
    'data'?: PutEscalationActionResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutEscalationBasicInfoResponse
 */
export interface ApiResponsePutEscalationBasicInfoResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutEscalationBasicInfoResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutEscalationBasicInfoResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutEscalationBasicInfoResponse}
     * @memberof ApiResponsePutEscalationBasicInfoResponse
     */
    'data'?: PutEscalationBasicInfoResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutEscalationEnabledResponse
 */
export interface ApiResponsePutEscalationEnabledResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutEscalationEnabledResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutEscalationEnabledResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutEscalationEnabledResponse}
     * @memberof ApiResponsePutEscalationEnabledResponse
     */
    'data'?: PutEscalationEnabledResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutEscalationFrequencyResponse
 */
export interface ApiResponsePutEscalationFrequencyResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutEscalationFrequencyResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutEscalationFrequencyResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutEscalationFrequencyResponse}
     * @memberof ApiResponsePutEscalationFrequencyResponse
     */
    'data'?: PutEscalationFrequencyResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutEscalationTicketFilterResponse
 */
export interface ApiResponsePutEscalationTicketFilterResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutEscalationTicketFilterResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutEscalationTicketFilterResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutEscalationTicketFilterResponse}
     * @memberof ApiResponsePutEscalationTicketFilterResponse
     */
    'data'?: PutEscalationTicketFilterResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutEscalationTimeSheetResponse
 */
export interface ApiResponsePutEscalationTimeSheetResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutEscalationTimeSheetResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutEscalationTimeSheetResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutEscalationTimeSheetResponse}
     * @memberof ApiResponsePutEscalationTimeSheetResponse
     */
    'data'?: PutEscalationTimeSheetResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutItemsResponse
 */
export interface ApiResponsePutItemsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutItemsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutItemsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutItemsResponse}
     * @memberof ApiResponsePutItemsResponse
     */
    'data'?: PutItemsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutMailBoxesResponse
 */
export interface ApiResponsePutMailBoxesResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutMailBoxesResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutMailBoxesResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutMailBoxesResponse}
     * @memberof ApiResponsePutMailBoxesResponse
     */
    'data'?: PutMailBoxesResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutMailContactsResponse
 */
export interface ApiResponsePutMailContactsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutMailContactsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutMailContactsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutMailContactsResponse}
     * @memberof ApiResponsePutMailContactsResponse
     */
    'data'?: PutMailContactsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutMailTemplateResponse
 */
export interface ApiResponsePutMailTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutMailTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutMailTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutMailTemplateResponse}
     * @memberof ApiResponsePutMailTemplateResponse
     */
    'data'?: PutMailTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutMailVendorResponse
 */
export interface ApiResponsePutMailVendorResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutMailVendorResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutMailVendorResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutMailVendorResponse}
     * @memberof ApiResponsePutMailVendorResponse
     */
    'data'?: PutMailVendorResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutNewProcessResponse
 */
export interface ApiResponsePutNewProcessResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutNewProcessResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutNewProcessResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutNewProcessResponse}
     * @memberof ApiResponsePutNewProcessResponse
     */
    'data'?: PutNewProcessResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutNewUploadResponse
 */
export interface ApiResponsePutNewUploadResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutNewUploadResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutNewUploadResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutNewUploadResponse}
     * @memberof ApiResponsePutNewUploadResponse
     */
    'data'?: PutNewUploadResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutNotificationEventResponse
 */
export interface ApiResponsePutNotificationEventResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutNotificationEventResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutNotificationEventResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutNotificationEventResponse}
     * @memberof ApiResponsePutNotificationEventResponse
     */
    'data'?: PutNotificationEventResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutNotificationTemplateResponse
 */
export interface ApiResponsePutNotificationTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutNotificationTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutNotificationTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutNotificationTemplateResponse}
     * @memberof ApiResponsePutNotificationTemplateResponse
     */
    'data'?: PutNotificationTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutProjectServiceMetaResponse
 */
export interface ApiResponsePutProjectServiceMetaResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutProjectServiceMetaResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutProjectServiceMetaResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutProjectServiceMetaResponse}
     * @memberof ApiResponsePutProjectServiceMetaResponse
     */
    'data'?: PutProjectServiceMetaResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutRemoteInstanceEnrollResponse
 */
export interface ApiResponsePutRemoteInstanceEnrollResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutRemoteInstanceEnrollResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutRemoteInstanceEnrollResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutRemoteInstanceEnrollResponse}
     * @memberof ApiResponsePutRemoteInstanceEnrollResponse
     */
    'data'?: PutRemoteInstanceEnrollResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutRemoteInstanceEnrollmentStatusResponse
 */
export interface ApiResponsePutRemoteInstanceEnrollmentStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutRemoteInstanceEnrollmentStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutRemoteInstanceEnrollmentStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutRemoteInstanceEnrollmentStatusResponse}
     * @memberof ApiResponsePutRemoteInstanceEnrollmentStatusResponse
     */
    'data'?: PutRemoteInstanceEnrollmentStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutResetChannelConfigurationResponse
 */
export interface ApiResponsePutResetChannelConfigurationResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutResetChannelConfigurationResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutResetChannelConfigurationResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutResetChannelConfigurationResponse}
     * @memberof ApiResponsePutResetChannelConfigurationResponse
     */
    'data'?: PutResetChannelConfigurationResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutResetSystemSettingsResponse
 */
export interface ApiResponsePutResetSystemSettingsResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutResetSystemSettingsResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutResetSystemSettingsResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutResetSystemSettingsResponse}
     * @memberof ApiResponsePutResetSystemSettingsResponse
     */
    'data'?: PutResetSystemSettingsResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutSystemSettingResponse
 */
export interface ApiResponsePutSystemSettingResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutSystemSettingResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutSystemSettingResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutSystemSettingResponse}
     * @memberof ApiResponsePutSystemSettingResponse
     */
    'data'?: PutSystemSettingResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTagToTicketResponse
 */
export interface ApiResponsePutTagToTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTagToTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTagToTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTagToTicketResponse}
     * @memberof ApiResponsePutTagToTicketResponse
     */
    'data'?: PutTagToTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTaskConfigureResponse
 */
export interface ApiResponsePutTaskConfigureResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTaskConfigureResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTaskConfigureResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTaskConfigureResponse}
     * @memberof ApiResponsePutTaskConfigureResponse
     */
    'data'?: PutTaskConfigureResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTaskStatusResponse
 */
export interface ApiResponsePutTaskStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTaskStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTaskStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTaskStatusResponse}
     * @memberof ApiResponsePutTaskStatusResponse
     */
    'data'?: PutTaskStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTemplateUsingStateResponse
 */
export interface ApiResponsePutTemplateUsingStateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTemplateUsingStateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTemplateUsingStateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTemplateUsingStateResponse}
     * @memberof ApiResponsePutTemplateUsingStateResponse
     */
    'data'?: PutTemplateUsingStateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTicketBasicInfoResponse
 */
export interface ApiResponsePutTicketBasicInfoResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTicketBasicInfoResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTicketBasicInfoResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTicketBasicInfoResponse}
     * @memberof ApiResponsePutTicketBasicInfoResponse
     */
    'data'?: PutTicketBasicInfoResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTicketContentResponse
 */
export interface ApiResponsePutTicketContentResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTicketContentResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTicketContentResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTicketContentResponse}
     * @memberof ApiResponsePutTicketContentResponse
     */
    'data'?: PutTicketContentResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTicketCoreStateResponse
 */
export interface ApiResponsePutTicketCoreStateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTicketCoreStateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTicketCoreStateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTicketCoreStateResponse}
     * @memberof ApiResponsePutTicketCoreStateResponse
     */
    'data'?: PutTicketCoreStateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTicketTemplateDisabledResponse
 */
export interface ApiResponsePutTicketTemplateDisabledResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTicketTemplateDisabledResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTicketTemplateDisabledResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTicketTemplateDisabledResponse}
     * @memberof ApiResponsePutTicketTemplateDisabledResponse
     */
    'data'?: PutTicketTemplateDisabledResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTicketTemplateEnabledResponse
 */
export interface ApiResponsePutTicketTemplateEnabledResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTicketTemplateEnabledResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTicketTemplateEnabledResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTicketTemplateEnabledResponse}
     * @memberof ApiResponsePutTicketTemplateEnabledResponse
     */
    'data'?: PutTicketTemplateEnabledResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutTicketTemplateResponse
 */
export interface ApiResponsePutTicketTemplateResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutTicketTemplateResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutTicketTemplateResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutTicketTemplateResponse}
     * @memberof ApiResponsePutTicketTemplateResponse
     */
    'data'?: PutTicketTemplateResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutUserApiAccessResponse
 */
export interface ApiResponsePutUserApiAccessResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutUserApiAccessResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutUserApiAccessResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutUserApiAccessResponse}
     * @memberof ApiResponsePutUserApiAccessResponse
     */
    'data'?: PutUserApiAccessResponse;
}
/**
 * 
 * @export
 * @interface ApiResponsePutUserToUserGroupResponse
 */
export interface ApiResponsePutUserToUserGroupResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponsePutUserToUserGroupResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponsePutUserToUserGroupResponse
     */
    'message'?: string;
    /**
     * 
     * @type {PutUserToUserGroupResponse}
     * @memberof ApiResponsePutUserToUserGroupResponse
     */
    'data'?: PutUserToUserGroupResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseRemoveDeliveryMaterialResponse
 */
export interface ApiResponseRemoveDeliveryMaterialResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseRemoveDeliveryMaterialResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseRemoveDeliveryMaterialResponse
     */
    'message'?: string;
    /**
     * 
     * @type {RemoveDeliveryMaterialResponse}
     * @memberof ApiResponseRemoveDeliveryMaterialResponse
     */
    'data'?: RemoveDeliveryMaterialResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseRemoveProjectServiceDraftResponse
 */
export interface ApiResponseRemoveProjectServiceDraftResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseRemoveProjectServiceDraftResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseRemoveProjectServiceDraftResponse
     */
    'message'?: string;
    /**
     * 
     * @type {RemoveProjectServiceDraftResponse}
     * @memberof ApiResponseRemoveProjectServiceDraftResponse
     */
    'data'?: RemoveProjectServiceDraftResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseSearchTicketByNameResponse
 */
export interface ApiResponseSearchTicketByNameResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseSearchTicketByNameResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseSearchTicketByNameResponse
     */
    'message'?: string;
    /**
     * 
     * @type {SearchTicketByNameResponse}
     * @memberof ApiResponseSearchTicketByNameResponse
     */
    'data'?: SearchTicketByNameResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseServiceInstanceChangeStatusResponse
 */
export interface ApiResponseServiceInstanceChangeStatusResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseServiceInstanceChangeStatusResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseServiceInstanceChangeStatusResponse
     */
    'message'?: string;
    /**
     * 
     * @type {ServiceInstanceChangeStatusResponse}
     * @memberof ApiResponseServiceInstanceChangeStatusResponse
     */
    'data'?: ServiceInstanceChangeStatusResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseStatisticCloseTicketResponse
 */
export interface ApiResponseStatisticCloseTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseStatisticCloseTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseStatisticCloseTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {StatisticCloseTicketResponse}
     * @memberof ApiResponseStatisticCloseTicketResponse
     */
    'data'?: StatisticCloseTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseStatisticSingleCloseTicketResponse
 */
export interface ApiResponseStatisticSingleCloseTicketResponse {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseStatisticSingleCloseTicketResponse
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseStatisticSingleCloseTicketResponse
     */
    'message'?: string;
    /**
     * 
     * @type {StatisticSingleCloseTicketResponse}
     * @memberof ApiResponseStatisticSingleCloseTicketResponse
     */
    'data'?: StatisticSingleCloseTicketResponse;
}
/**
 * 
 * @export
 * @interface ApiResponseString
 */
export interface ApiResponseString {
    /**
     * HTTP响应状态码
     * @type {number}
     * @memberof ApiResponseString
     */
    'status'?: number;
    /**
     * 描述响应的消息
     * @type {string}
     * @memberof ApiResponseString
     */
    'message'?: string;
    /**
     * 响应请求的数据
     * @type {string}
     * @memberof ApiResponseString
     */
    'data'?: string;
}
/**
 * 本次请求的内容
 * @export
 * @interface AtMomentStatisticsDataRequest
 */
export interface AtMomentStatisticsDataRequest {
    /**
     * 
     * @type {string}
     * @memberof AtMomentStatisticsDataRequest
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof AtMomentStatisticsDataRequest
     */
    'end'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface AtMomentStatisticsDataResponse
 */
export interface AtMomentStatisticsDataResponse {
    /**
     * 
     * @type {AtMomentStatisticsDataRequest}
     * @memberof AtMomentStatisticsDataResponse
     */
    'request'?: AtMomentStatisticsDataRequest;
    /**
     * 
     * @type {boolean}
     * @memberof AtMomentStatisticsDataResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<StatisticsDataDto>}
     * @memberof AtMomentStatisticsDataResponse
     */
    'dataValues'?: Array<StatisticsDataDto>;
}
/**
 * 
 * @export
 * @interface AttachmentDownloadDto
 */
export interface AttachmentDownloadDto {
    /**
     * 
     * @type {string}
     * @memberof AttachmentDownloadDto
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDownloadDto
     */
    'downloadUrl'?: string;
}
/**
 * 
 * @export
 * @interface AttachmentDto
 */
export interface AttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof AttachmentDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AttachmentDto
     */
    'driver'?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof AttachmentDto
     */
    'storeCompleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'uploadUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttachmentDto
     */
    'downloadUrl'?: string;
}
/**
 * 
 * @export
 * @interface AuthorizationResultDto
 */
export interface AuthorizationResultDto {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationResultDto
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationResultDto
     */
    'authorizationDocument'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationResultDto
     */
    'expired'?: string;
}
/**
 * 
 * @export
 * @interface ChallengeCodeDto
 */
export interface ChallengeCodeDto {
    /**
     * 
     * @type {string}
     * @memberof ChallengeCodeDto
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeCodeDto
     */
    'signatureMethod'?: string;
}
/**
 * 
 * @export
 * @interface ChallengeProfileDto
 */
export interface ChallengeProfileDto {
    /**
     * 
     * @type {string}
     * @memberof ChallengeProfileDto
     */
    'plainText'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeProfileDto
     */
    'randomStr'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeProfileDto
     */
    'signature'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChallengeProfileDto
     */
    'scope'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChallengeProfileDto
     */
    'duration'?: number;
}
/**
 * 
 * @export
 * @interface CloseTicketsStatisticsResultDto
 */
export interface CloseTicketsStatisticsResultDto {
    /**
     * 
     * @type {number}
     * @memberof CloseTicketsStatisticsResultDto
     */
    'successStatisticsCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CloseTicketsStatisticsResultDto
     */
    'reStatisticsTicketCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CloseTicketsStatisticsResultDto
     */
    'newClosedTicketCount'?: number;
}
/**
 * 
 * @export
 * @interface ConfigureItemDto
 */
export interface ConfigureItemDto {
    /**
     * 
     * @type {string}
     * @memberof ConfigureItemDto
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigureItemDto
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigureItemDto
     */
    'defaultValue'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConfigureItemDto
     */
    'attribute'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ContentSchemaDto
 */
export interface ContentSchemaDto {
    /**
     * 
     * @type {number}
     * @memberof ContentSchemaDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ContentSchemaDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSchemaDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSchemaDto
     */
    'schema'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSchemaDto
     */
    'uiSchema'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof ContentSchemaDto
     */
    'created'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof ContentSchemaDto
     */
    'updated'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof ContentSchemaDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentSchemaDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CreateNewTagRequest
 */
export interface CreateNewTagRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNewTagRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewTagRequest
     */
    'color'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface CreateNewTagResponse
 */
export interface CreateNewTagResponse {
    /**
     * 
     * @type {TagDto}
     * @memberof CreateNewTagResponse
     */
    'tag'?: TagDto;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNewTagResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface DataProxyConfigure
 */
export interface DataProxyConfigure {
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'scheme'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'host'?: string;
    /**
     * 
     * @type {number}
     * @memberof DataProxyConfigure
     */
    'port'?: number;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'path'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'cred'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'credentialInjector'?: string;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'dataMapping'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataProxyConfigure
     */
    'parseAsArray'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DataProxyConfigure
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @interface DelMailBoxAccessRequest
 */
export interface DelMailBoxAccessRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DelMailBoxAccessRequest
     */
    'userIds'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface DelMailBoxAccessResponse
 */
export interface DelMailBoxAccessResponse {
    /**
     * 
     * @type {DelMailBoxAccessRequest}
     * @memberof DelMailBoxAccessResponse
     */
    'request'?: DelMailBoxAccessRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DelMailBoxAccessResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DelMailBoxAccessResponse
     */
    'requestedBoxId'?: number;
}
/**
 * 
 * @export
 * @interface DeleteApiAccessKeyRequest
 */
export interface DeleteApiAccessKeyRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteApiAccessKeyRequest
     */
    'ids'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteApiAccessKeyResponse
 */
export interface DeleteApiAccessKeyResponse {
    /**
     * 
     * @type {DeleteApiAccessKeyRequest}
     * @memberof DeleteApiAccessKeyResponse
     */
    'request'?: DeleteApiAccessKeyRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteApiAccessKeyResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteAttachmentFromTicketResponse
 */
export interface DeleteAttachmentFromTicketResponse {
    /**
     * 
     * @type {number}
     * @memberof DeleteAttachmentFromTicketResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteAttachmentFromTicketResponse
     */
    'fileId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteAttachmentFromTicketResponse
     */
    'succeed'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface DeleteDraftMailRequest
 */
export interface DeleteDraftMailRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteDraftMailRequest
     */
    'mailBoxId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteDraftMailRequest
     */
    'mailId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteDraftMailResponse
 */
export interface DeleteDraftMailResponse {
    /**
     * 
     * @type {DeleteDraftMailRequest}
     * @memberof DeleteDraftMailResponse
     */
    'request'?: DeleteDraftMailRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteDraftMailResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface DeleteMailContactsRequest
 */
export interface DeleteMailContactsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteMailContactsRequest
     */
    'contactIds'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteMailContactsResponse
 */
export interface DeleteMailContactsResponse {
    /**
     * 
     * @type {DeleteMailContactsRequest}
     * @memberof DeleteMailContactsResponse
     */
    'request'?: DeleteMailContactsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteMailContactsResponse
     */
    'success'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface DeleteMailTemplateBindingRequest
 */
export interface DeleteMailTemplateBindingRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteMailTemplateBindingRequest
     */
    'templateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteMailTemplateBindingRequest
     */
    'mailboxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteMailTemplateBindingResponse
 */
export interface DeleteMailTemplateBindingResponse {
    /**
     * 
     * @type {DeleteMailTemplateBindingRequest}
     * @memberof DeleteMailTemplateBindingResponse
     */
    'request'?: DeleteMailTemplateBindingRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteMailTemplateBindingResponse
     */
    'success'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface DeleteMailTemplateRequest
 */
export interface DeleteMailTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteMailTemplateRequest
     */
    'templateId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteMailTemplateResponse
 */
export interface DeleteMailTemplateResponse {
    /**
     * 
     * @type {DeleteMailTemplateRequest}
     * @memberof DeleteMailTemplateResponse
     */
    'request'?: DeleteMailTemplateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteMailTemplateResponse
     */
    'success'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface DeleteMailboxContactBindingRequest
 */
export interface DeleteMailboxContactBindingRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteMailboxContactBindingRequest
     */
    'mailboxId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteMailboxContactBindingRequest
     */
    'contactId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteMailboxContactBindingRequest
     */
    'type'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteMailboxContactBindingResponse
 */
export interface DeleteMailboxContactBindingResponse {
    /**
     * 
     * @type {DeleteMailboxContactBindingRequest}
     * @memberof DeleteMailboxContactBindingResponse
     */
    'request'?: DeleteMailboxContactBindingRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteMailboxContactBindingResponse
     */
    'success'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface DeleteNotificationEventRequest
 */
export interface DeleteNotificationEventRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteNotificationEventRequest
     */
    'id'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteNotificationEventResponse
 */
export interface DeleteNotificationEventResponse {
    /**
     * 
     * @type {DeleteNotificationEventRequest}
     * @memberof DeleteNotificationEventResponse
     */
    'request'?: DeleteNotificationEventRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteNotificationEventResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteProjectDraftResponse
 */
export interface DeleteProjectDraftResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof DeleteProjectDraftResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteProjectDraftResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeleteProjectDraftResponse
     */
    'draftId'?: number;
}
/**
 * 
 * @export
 * @interface DeleteRelatedTicketRequest
 */
export interface DeleteRelatedTicketRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteRelatedTicketRequest
     */
    'ids'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteRelatedTicketResponse
 */
export interface DeleteRelatedTicketResponse {
    /**
     * 
     * @type {DeleteRelatedTicketRequest}
     * @memberof DeleteRelatedTicketResponse
     */
    'request'?: DeleteRelatedTicketRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteRelatedTicketResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeleteRelatedTicketResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeleteRelatedTicketResponse
     */
    'relateType'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteTagFromTicketResponse
 */
export interface DeleteTagFromTicketResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteTagFromTicketResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeleteTagFromTicketResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteTagFromTicketResponse
     */
    'tagId'?: number;
}
/**
 * 
 * @export
 * @interface DeleteTaskKnowledgeRequest
 */
export interface DeleteTaskKnowledgeRequest {
    /**
     * 
     * @type {number}
     * @memberof DeleteTaskKnowledgeRequest
     */
    'attachmentId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteTaskKnowledgeResponse
 */
export interface DeleteTaskKnowledgeResponse {
    /**
     * 
     * @type {DeleteTaskKnowledgeRequest}
     * @memberof DeleteTaskKnowledgeResponse
     */
    'request'?: DeleteTaskKnowledgeRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteTaskKnowledgeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeleteTaskKnowledgeResponse
     */
    'itemId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteTemplateResponse
 */
export interface DeleteTemplateResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof DeleteTemplateResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteTemplateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeleteTemplateResponse
     */
    'templateName'?: string;
}
/**
 * 
 * @export
 * @interface DeleteUserFromUserGroupRequest
 */
export interface DeleteUserFromUserGroupRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteUserFromUserGroupRequest
     */
    'userToDelete'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteUserFromUserGroupResponse
 */
export interface DeleteUserFromUserGroupResponse {
    /**
     * 
     * @type {number}
     * @memberof DeleteUserFromUserGroupResponse
     */
    'deleted'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteUserFromUserGroupResponse
     */
    'userIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof DeleteUserFromUserGroupResponse
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUserFromUserGroupResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface DeleteUserGroupRequest
 */
export interface DeleteUserGroupRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteUserGroupRequest
     */
    'ids'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface DeleteUserGroupResponse
 */
export interface DeleteUserGroupResponse {
    /**
     * 
     * @type {DeleteUserGroupRequest}
     * @memberof DeleteUserGroupResponse
     */
    'request'?: DeleteUserGroupRequest;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteUserGroupResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface EscalationActionDto
 */
export interface EscalationActionDto {
    /**
     * 
     * @type {string}
     * @memberof EscalationActionDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EscalationActionDto
     */
    'display'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EscalationActionDto
     */
    'parameters'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface EscalationRuleDtoV1
 */
export interface EscalationRuleDtoV1 {
    /**
     * 
     * @type {number}
     * @memberof EscalationRuleDtoV1
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof EscalationRuleDtoV1
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof EscalationRuleDtoV1
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EscalationRuleDtoV1
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EscalationRuleDtoV1
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof EscalationRuleDtoV1
     */
    'type'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EscalationRuleDtoV1
     */
    'status'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EscalationRuleDtoV1
     */
    'priority'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EscalationRuleDtoV1
     */
    'source'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof EscalationRuleDtoV1
     */
    'timeAxis'?: string;
    /**
     * 
     * @type {number}
     * @memberof EscalationRuleDtoV1
     */
    'timeConstraint'?: number;
    /**
     * 
     * @type {string}
     * @memberof EscalationRuleDtoV1
     */
    'timeDirection'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof EscalationRuleDtoV1
     */
    'frequency'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof EscalationRuleDtoV1
     */
    'actions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ExportJobDto
 */
export interface ExportJobDto {
    /**
     * 
     * @type {number}
     * @memberof ExportJobDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExportJobDto
     */
    'jobId'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof ExportJobDto
     */
    'requester'?: UserDto;
    /**
     * 
     * @type {number}
     * @memberof ExportJobDto
     */
    'type'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ExportJobDto
     */
    'status'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ExportJobDto
     */
    'params'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportJobDto
     */
    'requestedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportJobDto
     */
    'startedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExportJobDto
     */
    'completedAt'?: string;
    /**
     * 
     * @type {AttachmentDto}
     * @memberof ExportJobDto
     */
    'attachment'?: AttachmentDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetAcceptProjectInstanceResponse
 */
export interface GetAcceptProjectInstanceResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetAcceptProjectInstanceResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetAcceptProjectInstanceResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {UserModel}
     * @memberof GetAcceptProjectInstanceResponse
     */
    'acceptor'?: UserModel;
    /**
     * 
     * @type {Array<ProjectServiceInstanceDto>}
     * @memberof GetAcceptProjectInstanceResponse
     */
    'dtos'?: Array<ProjectServiceInstanceDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetAggregatedContactsByMailboxRequest
 */
export interface GetAggregatedContactsByMailboxRequest {
    /**
     * 
     * @type {number}
     * @memberof GetAggregatedContactsByMailboxRequest
     */
    'mailboxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetAggregatedContactsByMailboxResponse
 */
export interface GetAggregatedContactsByMailboxResponse {
    /**
     * 
     * @type {GetAggregatedContactsByMailboxRequest}
     * @memberof GetAggregatedContactsByMailboxResponse
     */
    'request'?: GetAggregatedContactsByMailboxRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetAggregatedContactsByMailboxResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailBoxContactAggregationDto}
     * @memberof GetAggregatedContactsByMailboxResponse
     */
    'dto'?: MailBoxContactAggregationDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetAllNotificationEventResponse
 */
export interface GetAllNotificationEventResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetAllNotificationEventResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllNotificationEventResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<NotificationEventTypeDto>}
     * @memberof GetAllNotificationEventResponse
     */
    'dtos'?: Array<NotificationEventTypeDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetAllTasksResponse
 */
export interface GetAllTasksResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetAllTasksResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetAllTasksResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<RemoteTaskDto>}
     * @memberof GetAllTasksResponse
     */
    'tasks'?: Array<RemoteTaskDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetApiAccessKeyOfUserRequest
 */
export interface GetApiAccessKeyOfUserRequest {
    /**
     * 
     * @type {number}
     * @memberof GetApiAccessKeyOfUserRequest
     */
    'userId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetApiAccessKeyOfUserResponse
 */
export interface GetApiAccessKeyOfUserResponse {
    /**
     * 
     * @type {GetApiAccessKeyOfUserRequest}
     * @memberof GetApiAccessKeyOfUserResponse
     */
    'request'?: GetApiAccessKeyOfUserRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetApiAccessKeyOfUserResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<AccessKeyDto>}
     * @memberof GetApiAccessKeyOfUserResponse
     */
    'dtos'?: Array<AccessKeyDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetAutomaticProcessTicketContentResponse
 */
export interface GetAutomaticProcessTicketContentResponse {
    /**
     * 
     * @type {TicketContentDto}
     * @memberof GetAutomaticProcessTicketContentResponse
     */
    'contentDto'?: TicketContentDto;
    /**
     * 
     * @type {number}
     * @memberof GetAutomaticProcessTicketContentResponse
     */
    'contentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetAutomaticProcessTicketContentResponse
     */
    'ticketId'?: number;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetBoundMailboxesRequest
 */
export interface GetBoundMailboxesRequest {
    /**
     * 
     * @type {number}
     * @memberof GetBoundMailboxesRequest
     */
    'templateId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetBoundMailboxesResponse
 */
export interface GetBoundMailboxesResponse {
    /**
     * 
     * @type {GetBoundMailboxesRequest}
     * @memberof GetBoundMailboxesResponse
     */
    'request'?: GetBoundMailboxesRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetBoundMailboxesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailBoxDto>}
     * @memberof GetBoundMailboxesResponse
     */
    'dtos'?: Array<MailBoxDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetBoundTemplatesRequest
 */
export interface GetBoundTemplatesRequest {
    /**
     * 
     * @type {number}
     * @memberof GetBoundTemplatesRequest
     */
    'mailboxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetBoundTemplatesResponse
 */
export interface GetBoundTemplatesResponse {
    /**
     * 
     * @type {GetBoundTemplatesRequest}
     * @memberof GetBoundTemplatesResponse
     */
    'request'?: GetBoundTemplatesRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetBoundTemplatesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailTemplateDto>}
     * @memberof GetBoundTemplatesResponse
     */
    'dtos'?: Array<MailTemplateDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetChannelConfigurationKeysResponse
 */
export interface GetChannelConfigurationKeysResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetChannelConfigurationKeysResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelConfigurationKeysResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetChannelConfigurationKeysResponse
     */
    'channelType'?: string;
    /**
     * 
     * @type {NotificationConfigurationKeyDto}
     * @memberof GetChannelConfigurationKeysResponse
     */
    'dto'?: NotificationConfigurationKeyDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetChannelNameListResponse
 */
export interface GetChannelNameListResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetChannelNameListResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelNameListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetChannelNameListResponse
     */
    'channelNameList'?: Array<string>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetContactsByMailboxRequest
 */
export interface GetContactsByMailboxRequest {
    /**
     * 
     * @type {number}
     * @memberof GetContactsByMailboxRequest
     */
    'mailboxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetContactsByMailboxResponse
 */
export interface GetContactsByMailboxResponse {
    /**
     * 
     * @type {GetContactsByMailboxRequest}
     * @memberof GetContactsByMailboxResponse
     */
    'request'?: GetContactsByMailboxRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetContactsByMailboxResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<WorkbenchMailBoxContactDto>}
     * @memberof GetContactsByMailboxResponse
     */
    'dto'?: Array<WorkbenchMailBoxContactDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetContentSchemaByIdResponse
 */
export interface GetContentSchemaByIdResponse {
    /**
     * 
     * @type {number}
     * @memberof GetContentSchemaByIdResponse
     */
    'id'?: number;
    /**
     * 
     * @type {ContentSchemaDto}
     * @memberof GetContentSchemaByIdResponse
     */
    'contentSchema'?: ContentSchemaDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetContentSchemaByIdResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetContentSchemaListResponse
 */
export interface GetContentSchemaListResponse {
    /**
     * 
     * @type {Array<ContentSchemaDto>}
     * @memberof GetContentSchemaListResponse
     */
    'list'?: Array<ContentSchemaDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetDownloadAttachmentResponse
 */
export interface GetDownloadAttachmentResponse {
    /**
     * 
     * @type {number}
     * @memberof GetDownloadAttachmentResponse
     */
    'attachmentId'?: number;
    /**
     * 
     * @type {AttachmentDto}
     * @memberof GetDownloadAttachmentResponse
     */
    'attachmentInfo'?: AttachmentDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetDownloadAttachmentResponse
     */
    'succeed'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetEmailBoxMailsRequest
 */
export interface GetEmailBoxMailsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetEmailBoxMailsRequest
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailBoxMailsRequest
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetEmailBoxMailsRequest
     */
    'mailBoxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetEmailBoxMailsResponse
 */
export interface GetEmailBoxMailsResponse {
    /**
     * 
     * @type {GetEmailBoxMailsRequest}
     * @memberof GetEmailBoxMailsResponse
     */
    'request'?: GetEmailBoxMailsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmailBoxMailsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailDto>}
     * @memberof GetEmailBoxMailsResponse
     */
    'mails'?: Array<MailDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetEscalationActionResponse
 */
export interface GetEscalationActionResponse {
    /**
     * 
     * @type {Array<EscalationActionDto>}
     * @memberof GetEscalationActionResponse
     */
    'actions'?: Array<EscalationActionDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetEscalationRulesResponseEscalationRuleDtoV1
 */
export interface GetEscalationRulesResponseEscalationRuleDtoV1 {
    /**
     * 
     * @type {Array<EscalationRuleDtoV1>}
     * @memberof GetEscalationRulesResponseEscalationRuleDtoV1
     */
    'rules'?: Array<EscalationRuleDtoV1>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetEventNameListResponse
 */
export interface GetEventNameListResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetEventNameListResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetEventNameListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetEventNameListResponse
     */
    'eventNameList'?: Array<string>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetExportJobDownloadUrlResponse
 */
export interface GetExportJobDownloadUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GetExportJobDownloadUrlResponse
     */
    'jobId'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetExportJobDownloadUrlResponse
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetExportJobDownloadUrlResponse
     */
    'downloadUrl'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetExportJobDownloadUrlResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetExportJobListResponse
 */
export interface GetExportJobListResponse {
    /**
     * 
     * @type {Array<ExportJobDto>}
     * @memberof GetExportJobListResponse
     */
    'jobs'?: Array<ExportJobDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetExportJobListResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetInstancesTaskResponse
 */
export interface GetInstancesTaskResponse {
    /**
     * 
     * @type {GetInstancesTasksRequest}
     * @memberof GetInstancesTaskResponse
     */
    'request'?: GetInstancesTasksRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetInstancesTaskResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<RemoteTaskDto>}
     * @memberof GetInstancesTaskResponse
     */
    'tasks'?: Array<RemoteTaskDto>;
    /**
     * 
     * @type {RemoteInstanceDto}
     * @memberof GetInstancesTaskResponse
     */
    'instance'?: RemoteInstanceDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetInstancesTasksRequest
 */
export interface GetInstancesTasksRequest {
    /**
     * 
     * @type {number}
     * @memberof GetInstancesTasksRequest
     */
    'remoteInstanceId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetItemInstanceListOfServiceInstanceResponse
 */
export interface GetItemInstanceListOfServiceInstanceResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetItemInstanceListOfServiceInstanceResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetItemInstanceListOfServiceInstanceResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetItemInstanceListOfServiceInstanceResponse
     */
    'serviceInstanceId'?: number;
    /**
     * 
     * @type {Array<ProjectServiceItemInstanceDto>}
     * @memberof GetItemInstanceListOfServiceInstanceResponse
     */
    'dtos'?: Array<ProjectServiceItemInstanceDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetItemInstanceStatusChangeLogResponse
 */
export interface GetItemInstanceStatusChangeLogResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetItemInstanceStatusChangeLogResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetItemInstanceStatusChangeLogResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetItemInstanceStatusChangeLogResponse
     */
    'itemInstanceId'?: number;
    /**
     * 
     * @type {Array<ProjectServiceStatusChangeLogDto>}
     * @memberof GetItemInstanceStatusChangeLogResponse
     */
    'dtos'?: Array<ProjectServiceStatusChangeLogDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetItemInstanceStatusMetaDataResponse
 */
export interface GetItemInstanceStatusMetaDataResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetItemInstanceStatusMetaDataResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetItemInstanceStatusMetaDataResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProjectServiceItemInstanceStatusDto>}
     * @memberof GetItemInstanceStatusMetaDataResponse
     */
    'dtos'?: Array<ProjectServiceItemInstanceStatusDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetItemKnowledgeResponse
 */
export interface GetItemKnowledgeResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetItemKnowledgeResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetItemKnowledgeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetItemKnowledgeResponse
     */
    'itemId'?: number;
    /**
     * 
     * @type {Array<ProjectAttachmentDto>}
     * @memberof GetItemKnowledgeResponse
     */
    'knowledgeList'?: Array<ProjectAttachmentDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetItemsResponse
 */
export interface GetItemsResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetItemsResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetItemsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetItemsResponse
     */
    'serviceId'?: number;
    /**
     * 
     * @type {Array<ProjectServiceItemMetaDataDto>}
     * @memberof GetItemsResponse
     */
    'dtos'?: Array<ProjectServiceItemMetaDataDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetKnowledgeBaseCategoriesResponse
 */
export interface GetKnowledgeBaseCategoriesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetKnowledgeBaseCategoriesResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {Array<KnowledgeBaseCategoryDto>}
     * @memberof GetKnowledgeBaseCategoriesResponse
     */
    'categories'?: Array<KnowledgeBaseCategoryDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetKnowledgeBaseCategoryTreeResponse
 */
export interface GetKnowledgeBaseCategoryTreeResponse {
    /**
     * 
     * @type {KnowledgeBaseCategoryDto}
     * @memberof GetKnowledgeBaseCategoryTreeResponse
     */
    'tree'?: KnowledgeBaseCategoryDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetLoginUserResponse
 */
export interface GetLoginUserResponse {
    /**
     * 
     * @type {UserDto}
     * @memberof GetLoginUserResponse
     */
    'user'?: UserDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetLoginUserResponse
     */
    'admin'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetMailActionStatusRequest
 */
export interface GetMailActionStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof GetMailActionStatusRequest
     */
    'query'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailActionStatusResponse
 */
export interface GetMailActionStatusResponse {
    /**
     * 
     * @type {GetMailActionStatusRequest}
     * @memberof GetMailActionStatusResponse
     */
    'request'?: GetMailActionStatusRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailActionStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ActionStatusDto>}
     * @memberof GetMailActionStatusResponse
     */
    'actionStatusDtos'?: Array<ActionStatusDto>;
    /**
     * 
     * @type {string}
     * @memberof GetMailActionStatusResponse
     */
    'latestUpdate'?: string;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetMailBoxAccessRequest
 */
export interface GetMailBoxAccessRequest {
    /**
     * 
     * @type {number}
     * @memberof GetMailBoxAccessRequest
     */
    'mailBoxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailBoxAccessResponse
 */
export interface GetMailBoxAccessResponse {
    /**
     * 
     * @type {GetMailBoxAccessRequest}
     * @memberof GetMailBoxAccessResponse
     */
    'request'?: GetMailBoxAccessRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailBoxAccessResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailAccessDto>}
     * @memberof GetMailBoxAccessResponse
     */
    'access'?: Array<MailAccessDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetMailBoxStateRequest
 */
export interface GetMailBoxStateRequest {
    /**
     * 
     * @type {number}
     * @memberof GetMailBoxStateRequest
     */
    'mailBoxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailBoxStateResponse
 */
export interface GetMailBoxStateResponse {
    /**
     * 
     * @type {GetMailBoxStateRequest}
     * @memberof GetMailBoxStateResponse
     */
    'request'?: GetMailBoxStateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailBoxStateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailBoxStateDto}
     * @memberof GetMailBoxStateResponse
     */
    'state'?: MailBoxStateDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetMailContactsRequest
 */
export interface GetMailContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof GetMailContactsRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMailContactsRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMailContactsRequest
     */
    'nickname'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailContactsRequest
     */
    'enable'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailContactsResponse
 */
export interface GetMailContactsResponse {
    /**
     * 
     * @type {GetMailContactsRequest}
     * @memberof GetMailContactsResponse
     */
    'request'?: GetMailContactsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailContactsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<WorkbenchMailContactDto>}
     * @memberof GetMailContactsResponse
     */
    'dtos'?: Array<WorkbenchMailContactDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailContentDownloadUrlResponse
 */
export interface GetMailContentDownloadUrlResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetMailContentDownloadUrlResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailContentDownloadUrlResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMailContentDownloadUrlResponse
     */
    'mailboxId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMailContentDownloadUrlResponse
     */
    'mailId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMailContentDownloadUrlResponse
     */
    'contentNames'?: Array<string>;
    /**
     * 
     * @type {Array<AttachmentDownloadDto>}
     * @memberof GetMailContentDownloadUrlResponse
     */
    'downloadUrls'?: Array<AttachmentDownloadDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailContentNamesResponse
 */
export interface GetMailContentNamesResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetMailContentNamesResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailContentNamesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMailContentNamesResponse
     */
    'mailboxId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMailContentNamesResponse
     */
    'mailId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMailContentNamesResponse
     */
    'mailContentNames'?: Array<string>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailForApprovingResponse
 */
export interface GetMailForApprovingResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetMailForApprovingResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailForApprovingResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetMailForApprovingResponse
     */
    'userId'?: number;
    /**
     * 
     * @type {Array<MailSendingDto>}
     * @memberof GetMailForApprovingResponse
     */
    'mailSendingDtos'?: Array<MailSendingDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetMailListRequest
 */
export interface GetMailListRequest {
    /**
     * 
     * @type {number}
     * @memberof GetMailListRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailListRequest
     */
    'loadAdminView'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMailListRequest
     */
    'publicKey'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailListResponse
 */
export interface GetMailListResponse {
    /**
     * 
     * @type {GetMailListRequest}
     * @memberof GetMailListResponse
     */
    'request'?: GetMailListRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailBoxDto>}
     * @memberof GetMailListResponse
     */
    'availableMailBoxes'?: Array<MailBoxDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetMailTemplateByIdRequest
 */
export interface GetMailTemplateByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof GetMailTemplateByIdRequest
     */
    'mailTemplateId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailTemplateByIdResponse
 */
export interface GetMailTemplateByIdResponse {
    /**
     * 
     * @type {GetMailTemplateByIdRequest}
     * @memberof GetMailTemplateByIdResponse
     */
    'request'?: GetMailTemplateByIdRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailTemplateByIdResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailTemplateDto}
     * @memberof GetMailTemplateByIdResponse
     */
    'dto'?: MailTemplateDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetMailTemplateContentRequest
 */
export interface GetMailTemplateContentRequest {
    /**
     * 
     * @type {number}
     * @memberof GetMailTemplateContentRequest
     */
    'templateId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailTemplateContentResponse
 */
export interface GetMailTemplateContentResponse {
    /**
     * 
     * @type {GetMailTemplateContentRequest}
     * @memberof GetMailTemplateContentResponse
     */
    'request'?: GetMailTemplateContentRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailTemplateContentResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMailTemplateContentResponse
     */
    'content'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailTemplateListResponse
 */
export interface GetMailTemplateListResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetMailTemplateListResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailTemplateListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailTemplateDto>}
     * @memberof GetMailTemplateListResponse
     */
    'dtos'?: Array<MailTemplateDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetMailValidationMessageRequest
 */
export interface GetMailValidationMessageRequest {
    /**
     * 
     * @type {number}
     * @memberof GetMailValidationMessageRequest
     */
    'mailBoxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailValidationMessageResponse
 */
export interface GetMailValidationMessageResponse {
    /**
     * 
     * @type {GetMailValidationMessageRequest}
     * @memberof GetMailValidationMessageResponse
     */
    'request'?: GetMailValidationMessageRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailValidationMessageResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMailValidationMessageResponse
     */
    'queryKey'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetMailVendorListResponse
 */
export interface GetMailVendorListResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetMailVendorListResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetMailVendorListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailVendorDto>}
     * @memberof GetMailVendorListResponse
     */
    'vendors'?: Array<MailVendorDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetNavigationResponse
 */
export interface GetNavigationResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetNavigationResponse
     */
    'allowedModules'?: Array<string>;
    /**
     * 
     * @type {UserDto}
     * @memberof GetNavigationResponse
     */
    'userDto'?: UserDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetNotificationEventByIdRequest
 */
export interface GetNotificationEventByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof GetNotificationEventByIdRequest
     */
    'id'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetNotificationEventByIdResponse
 */
export interface GetNotificationEventByIdResponse {
    /**
     * 
     * @type {GetNotificationEventByIdRequest}
     * @memberof GetNotificationEventByIdResponse
     */
    'request'?: GetNotificationEventByIdRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationEventByIdResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {NotificationEventTypeDto}
     * @memberof GetNotificationEventByIdResponse
     */
    'dto'?: NotificationEventTypeDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetNotificationEventByTopicRequest
 */
export interface GetNotificationEventByTopicRequest {
    /**
     * 
     * @type {string}
     * @memberof GetNotificationEventByTopicRequest
     */
    'topic'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetNotificationEventByTopicResponse
 */
export interface GetNotificationEventByTopicResponse {
    /**
     * 
     * @type {GetNotificationEventByTopicRequest}
     * @memberof GetNotificationEventByTopicResponse
     */
    'request'?: GetNotificationEventByTopicRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationEventByTopicResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {NotificationEventTypeDto}
     * @memberof GetNotificationEventByTopicResponse
     */
    'dto'?: NotificationEventTypeDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetNotificationTemplateListResponse
 */
export interface GetNotificationTemplateListResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetNotificationTemplateListResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationTemplateListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<WorkbenchVelocityTemplateDto>}
     * @memberof GetNotificationTemplateListResponse
     */
    'dtos'?: Array<WorkbenchVelocityTemplateDto>;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationTemplateListResponse
     */
    'eventType'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNotificationTemplateListResponse
     */
    'channelType'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetPluginByIdResponse
 */
export interface GetPluginByIdResponse {
    /**
     * 
     * @type {PluginDto}
     * @memberof GetPluginByIdResponse
     */
    'plugin'?: PluginDto;
    /**
     * 
     * @type {PluginConfigureDto}
     * @memberof GetPluginByIdResponse
     */
    'pluginConfigure'?: PluginConfigureDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetPluginByIdResponse
     */
    'includeConfigure'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetPluginByIdResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetPluginListResponse
 */
export interface GetPluginListResponse {
    /**
     * 
     * @type {Array<PluginDto>}
     * @memberof GetPluginListResponse
     */
    'list'?: Array<PluginDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetPluginListResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetPolicyListResponse
 */
export interface GetPolicyListResponse {
    /**
     * 
     * @type {Array<PolicyDto>}
     * @memberof GetPolicyListResponse
     */
    'list'?: Array<PolicyDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetPolicyResponse
 */
export interface GetPolicyResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetPolicyResponse
     */
    'withPrivilege'?: boolean;
    /**
     * 
     * @type {PolicyDto}
     * @memberof GetPolicyResponse
     */
    'policy'?: PolicyDto;
    /**
     * 
     * @type {Array<PrivilegeDto>}
     * @memberof GetPolicyResponse
     */
    'privileges'?: Array<PrivilegeDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetPolicyResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetPrivilegeListResponse
 */
export interface GetPrivilegeListResponse {
    /**
     * 
     * @type {Array<PrivilegeDto>}
     * @memberof GetPrivilegeListResponse
     */
    'list'?: Array<PrivilegeDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProcessListResponse
 */
export interface GetProcessListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetProcessListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProcessDto>}
     * @memberof GetProcessListResponse
     */
    'processes'?: Array<ProcessDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProcessResponse
 */
export interface GetProcessResponse {
    /**
     * 
     * @type {ProcessDto}
     * @memberof GetProcessResponse
     */
    'dto'?: ProcessDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetProcessResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProcessResponse
     */
    'id'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProcessTemplateResponse
 */
export interface GetProcessTemplateResponse {
    /**
     * 
     * @type {MetaGetProcessTemplateRequest}
     * @memberof GetProcessTemplateResponse
     */
    'request'?: MetaGetProcessTemplateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetProcessTemplateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetProcessTemplateResponse
     */
    'template'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProjectInstanceByParticipantAndParticipantTypeResponse
 */
export interface GetProjectInstanceByParticipantAndParticipantTypeResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetProjectInstanceByParticipantAndParticipantTypeResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectInstanceByParticipantAndParticipantTypeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {UserModel}
     * @memberof GetProjectInstanceByParticipantAndParticipantTypeResponse
     */
    'participant'?: UserModel;
    /**
     * 
     * @type {Array<ProjectServiceInstanceDto>}
     * @memberof GetProjectInstanceByParticipantAndParticipantTypeResponse
     */
    'dtos'?: Array<ProjectServiceInstanceDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetProjectInstanceListRequest
 */
export interface GetProjectInstanceListRequest {
    /**
     * 
     * @type {number}
     * @memberof GetProjectInstanceListRequest
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectInstanceListRequest
     */
    'serviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectInstanceListRequest
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProjectInstanceListRequest
     */
    'subscriber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectInstanceListRequest
     */
    'acceptor'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectInstanceListRequest
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectInstanceListRequest
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectInstanceListRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProjectInstanceListRequest
     */
    'status'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProjectInstanceListResponse
 */
export interface GetProjectInstanceListResponse {
    /**
     * 
     * @type {GetProjectInstanceListRequest}
     * @memberof GetProjectInstanceListResponse
     */
    'request'?: GetProjectInstanceListRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectInstanceListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProjectServiceInstanceDto>}
     * @memberof GetProjectInstanceListResponse
     */
    'dtos'?: Array<ProjectServiceInstanceDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProjectInstanceOfOwnerByStatusResponse
 */
export interface GetProjectInstanceOfOwnerByStatusResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetProjectInstanceOfOwnerByStatusResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectInstanceOfOwnerByStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {UserModel}
     * @memberof GetProjectInstanceOfOwnerByStatusResponse
     */
    'owner'?: UserModel;
    /**
     * 
     * @type {Array<ProjectServiceInstanceDto>}
     * @memberof GetProjectInstanceOfOwnerByStatusResponse
     */
    'dtos'?: Array<ProjectServiceInstanceDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetProjectMetaDataListRequest
 */
export interface GetProjectMetaDataListRequest {
    /**
     * 
     * @type {number}
     * @memberof GetProjectMetaDataListRequest
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectMetaDataListRequest
     */
    'owner'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMetaDataListRequest
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProjectMetaDataListRequest
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMetaDataListRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetProjectMetaDataListRequest
     */
    'createdBy'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectMetaDataListRequest
     */
    'updatedBy'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProjectMetaDataListResponse
 */
export interface GetProjectMetaDataListResponse {
    /**
     * 
     * @type {GetProjectMetaDataListRequest}
     * @memberof GetProjectMetaDataListResponse
     */
    'request'?: GetProjectMetaDataListRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectMetaDataListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProjectServiceMetaDataDto>}
     * @memberof GetProjectMetaDataListResponse
     */
    'list'?: Array<ProjectServiceMetaDataDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProjectStatusMetaDataResponse
 */
export interface GetProjectStatusMetaDataResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetProjectStatusMetaDataResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectStatusMetaDataResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProjectStatusDto>}
     * @memberof GetProjectStatusMetaDataResponse
     */
    'dtoList'?: Array<ProjectStatusDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetProjectWorkbenchDataResponse
 */
export interface GetProjectWorkbenchDataResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetProjectWorkbenchDataResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectWorkbenchDataResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetProjectWorkbenchDataResponse
     */
    'userId'?: number;
    /**
     * 
     * @type {ProjectWorkbenchDataDto}
     * @memberof GetProjectWorkbenchDataResponse
     */
    'dto'?: ProjectWorkbenchDataDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetPublicInDomainResponse
 */
export interface GetPublicInDomainResponse {
    /**
     * 
     * @type {string}
     * @memberof GetPublicInDomainResponse
     */
    'publicKey'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof GetPublicInDomainResponse
     */
    'requestUser'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof GetPublicInDomainResponse
     */
    'requestDomain'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetReflexStrategyResponse
 */
export interface GetReflexStrategyResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetReflexStrategyResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetReflexStrategyResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ReflexStrategyDto>}
     * @memberof GetReflexStrategyResponse
     */
    'strategies'?: Array<ReflexStrategyDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetRelatedTicketListResponse
 */
export interface GetRelatedTicketListResponse {
    /**
     * 
     * @type {string}
     * @memberof GetRelatedTicketListResponse
     */
    'relatedType'?: string;
    /**
     * 
     * @type {Array<TicketDto>}
     * @memberof GetRelatedTicketListResponse
     */
    'tickets'?: Array<TicketDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetRelatedTicketListResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetRelatedTicketsResponse
 */
export interface GetRelatedTicketsResponse {
    /**
     * 
     * @type {Array<TicketDto>}
     * @memberof GetRelatedTicketsResponse
     */
    'tickets'?: Array<TicketDto>;
    /**
     * 
     * @type {UserModel}
     * @memberof GetRelatedTicketsResponse
     */
    'principal'?: UserModel;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetRemoteInstanceConnectiveStatusRequest
 */
export interface GetRemoteInstanceConnectiveStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof GetRemoteInstanceConnectiveStatusRequest
     */
    'identifier'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetRemoteInstanceConnectiveStatusResponse
 */
export interface GetRemoteInstanceConnectiveStatusResponse {
    /**
     * 
     * @type {GetRemoteInstanceConnectiveStatusRequest}
     * @memberof GetRemoteInstanceConnectiveStatusResponse
     */
    'request'?: GetRemoteInstanceConnectiveStatusRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetRemoteInstanceConnectiveStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetRemoteInstanceConnectiveStatusResponse
     */
    'instanceIdentifier'?: string;
    /**
     * 
     * @type {NetworkStatusDto}
     * @memberof GetRemoteInstanceConnectiveStatusResponse
     */
    'dto'?: NetworkStatusDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetRemoteInstancesResponse
 */
export interface GetRemoteInstancesResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetRemoteInstancesResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetRemoteInstancesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<RemoteInstanceDto>}
     * @memberof GetRemoteInstancesResponse
     */
    'remoteInstances'?: Array<RemoteInstanceDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetSendingEmailAttachmentTargetRequest
 */
export interface GetSendingEmailAttachmentTargetRequest {
    /**
     * 
     * @type {string}
     * @memberof GetSendingEmailAttachmentTargetRequest
     */
    'frontEndUid'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSendingEmailAttachmentTargetRequest
     */
    'fileName'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSendingEmailAttachmentTargetRequest
     */
    'mailBoxId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSendingEmailAttachmentTargetRequest
     */
    'mailId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSendingEmailAttachmentTargetRequest
     */
    'action'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetSendingEmailAttachmentTargetResponse
 */
export interface GetSendingEmailAttachmentTargetResponse {
    /**
     * 
     * @type {GetSendingEmailAttachmentTargetRequest}
     * @memberof GetSendingEmailAttachmentTargetResponse
     */
    'request'?: GetSendingEmailAttachmentTargetRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetSendingEmailAttachmentTargetResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSendingEmailAttachmentTargetResponse
     */
    'url'?: string;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetSendingEmailDetailRequest
 */
export interface GetSendingEmailDetailRequest {
    /**
     * 
     * @type {number}
     * @memberof GetSendingEmailDetailRequest
     */
    'mailId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSendingEmailDetailRequest
     */
    'mailBoxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetSendingEmailDetailResponse
 */
export interface GetSendingEmailDetailResponse {
    /**
     * 
     * @type {GetSendingEmailDetailRequest}
     * @memberof GetSendingEmailDetailResponse
     */
    'request'?: GetSendingEmailDetailRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetSendingEmailDetailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailSendingDto}
     * @memberof GetSendingEmailDetailResponse
     */
    'sendingMail'?: MailSendingDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetSendingEmailRequest
 */
export interface GetSendingEmailRequest {
    /**
     * 
     * @type {number}
     * @memberof GetSendingEmailRequest
     */
    'mailBoxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetSendingEmailResponse
 */
export interface GetSendingEmailResponse {
    /**
     * 
     * @type {GetSendingEmailRequest}
     * @memberof GetSendingEmailResponse
     */
    'request'?: GetSendingEmailRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetSendingEmailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailSendingDto>}
     * @memberof GetSendingEmailResponse
     */
    'sendingMails'?: Array<MailSendingDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetServiceInstanceDeliveryMaterialResponse
 */
export interface GetServiceInstanceDeliveryMaterialResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetServiceInstanceDeliveryMaterialResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetServiceInstanceDeliveryMaterialResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProjectAttachmentDto>}
     * @memberof GetServiceInstanceDeliveryMaterialResponse
     */
    'dtos'?: Array<ProjectAttachmentDto>;
    /**
     * 
     * @type {number}
     * @memberof GetServiceInstanceDeliveryMaterialResponse
     */
    'itemInstanceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetServiceInstanceDeliveryMaterialResponse
     */
    'redoCount'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetServiceInstanceStatusChangeLogResponse
 */
export interface GetServiceInstanceStatusChangeLogResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetServiceInstanceStatusChangeLogResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetServiceInstanceStatusChangeLogResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetServiceInstanceStatusChangeLogResponse
     */
    'serviceInstanceId'?: number;
    /**
     * 
     * @type {Array<ProjectServiceStatusChangeLogDto>}
     * @memberof GetServiceInstanceStatusChangeLogResponse
     */
    'dtos'?: Array<ProjectServiceStatusChangeLogDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetServiceInstanceUserParticipatingRequest
 */
export interface GetServiceInstanceUserParticipatingRequest {
    /**
     * 
     * @type {number}
     * @memberof GetServiceInstanceUserParticipatingRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetServiceInstanceUserParticipatingRequest
     */
    'statusList'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetStatusOfTicketResponse
 */
export interface GetStatusOfTicketResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetStatusOfTicketResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetStatusOfTicketResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {TicketStatusDto}
     * @memberof GetStatusOfTicketResponse
     */
    'dto'?: TicketStatusDto;
    /**
     * 
     * @type {number}
     * @memberof GetStatusOfTicketResponse
     */
    'ticketId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetSubscribeProjectInstanceResponse
 */
export interface GetSubscribeProjectInstanceResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetSubscribeProjectInstanceResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetSubscribeProjectInstanceResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {UserModel}
     * @memberof GetSubscribeProjectInstanceResponse
     */
    'subscriber'?: UserModel;
    /**
     * 
     * @type {Array<ProjectServiceInstanceDto>}
     * @memberof GetSubscribeProjectInstanceResponse
     */
    'dtos'?: Array<ProjectServiceInstanceDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetSystemSettingItemResponse
 */
export interface GetSystemSettingItemResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSystemSettingItemResponse
     */
    'settingKey'?: string;
    /**
     * 
     * @type {ConfigureItemDto}
     * @memberof GetSystemSettingItemResponse
     */
    'settingItem'?: ConfigureItemDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetSystemSettingResponse
 */
export interface GetSystemSettingResponse {
    /**
     * 
     * @type {Array<ConfigureItemDto>}
     * @memberof GetSystemSettingResponse
     */
    'configures'?: Array<ConfigureItemDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetSystemSettingResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTagByIdResponse
 */
export interface GetTagByIdResponse {
    /**
     * 
     * @type {TagDto}
     * @memberof GetTagByIdResponse
     */
    'tag'?: TagDto;
    /**
     * 
     * @type {number}
     * @memberof GetTagByIdResponse
     */
    'id'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTagListResponse
 */
export interface GetTagListResponse {
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof GetTagListResponse
     */
    'tagDtoList'?: Array<TagDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTaskProgressInfoResponse
 */
export interface GetTaskProgressInfoResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetTaskProgressInfoResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetTaskProgressInfoResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetTaskProgressInfoResponse
     */
    'serviceInstanceId'?: number;
    /**
     * 
     * @type {Array<ProjectServiceInstanceTaskTreeNodeDto>}
     * @memberof GetTaskProgressInfoResponse
     */
    'dtos'?: Array<ProjectServiceInstanceTaskTreeNodeDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetTaskRequest
 */
export interface GetTaskRequest {
    /**
     * 
     * @type {boolean}
     * @memberof GetTaskRequest
     */
    'includeAllocatedInstance'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetTaskRequest
     */
    'taskId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTaskResponse
 */
export interface GetTaskResponse {
    /**
     * 
     * @type {GetTaskRequest}
     * @memberof GetTaskResponse
     */
    'request'?: GetTaskRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetTaskResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {RemoteTaskDto}
     * @memberof GetTaskResponse
     */
    'task'?: RemoteTaskDto;
    /**
     * 
     * @type {Array<RemoteInstanceDto>}
     * @memberof GetTaskResponse
     */
    'allocatedInstance'?: Array<RemoteInstanceDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetTemplatesOfMailboxRequest
 */
export interface GetTemplatesOfMailboxRequest {
    /**
     * 
     * @type {number}
     * @memberof GetTemplatesOfMailboxRequest
     */
    'mailboxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTemplatesOfMailboxResponse
 */
export interface GetTemplatesOfMailboxResponse {
    /**
     * 
     * @type {GetTemplatesOfMailboxRequest}
     * @memberof GetTemplatesOfMailboxResponse
     */
    'request'?: GetTemplatesOfMailboxRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetTemplatesOfMailboxResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailSimpleTemplateDto>}
     * @memberof GetTemplatesOfMailboxResponse
     */
    'dtos'?: Array<MailSimpleTemplateDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketAssigneeChangeThreadsResponse
 */
export interface GetTicketAssigneeChangeThreadsResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetTicketAssigneeChangeThreadsResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketAssigneeChangeThreadsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetTicketAssigneeChangeThreadsResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {Array<TicketThreadTimeDto>}
     * @memberof GetTicketAssigneeChangeThreadsResponse
     */
    'dtos'?: Array<TicketThreadTimeDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketAttachmentMetaResponse
 */
export interface GetTicketAttachmentMetaResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTicketAttachmentMetaResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketAttachmentMetaResponse
     */
    'attachmentId'?: number;
    /**
     * 
     * @type {AttachmentDto}
     * @memberof GetTicketAttachmentMetaResponse
     */
    'attachmentDto'?: AttachmentDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketAttachmentResponse
 */
export interface GetTicketAttachmentResponse {
    /**
     * 
     * @type {Array<TicketAttachmentDto>}
     * @memberof GetTicketAttachmentResponse
     */
    'list'?: Array<TicketAttachmentDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketAttachmentResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketContentResponse
 */
export interface GetTicketContentResponse {
    /**
     * 
     * @type {TicketContentDto}
     * @memberof GetTicketContentResponse
     */
    'contentDto'?: TicketContentDto;
    /**
     * 
     * @type {number}
     * @memberof GetTicketContentResponse
     */
    'contentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketContentResponse
     */
    'ticketId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketListResponse
 */
export interface GetTicketListResponse {
    /**
     * 
     * @type {Array<TicketDto>}
     * @memberof GetTicketListResponse
     */
    'tickets'?: Array<TicketDto>;
    /**
     * 
     * @type {number}
     * @memberof GetTicketListResponse
     */
    'assigned'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTicketListResponse
     */
    'assignedGroup'?: number;
    /**
     * 
     * @type {Array<TicketStatusDto>}
     * @memberof GetTicketListResponse
     */
    'ticketStatus'?: Array<TicketStatusDto>;
    /**
     * 
     * @type {Array<TicketTypeDto>}
     * @memberof GetTicketListResponse
     */
    'ticketType'?: Array<TicketTypeDto>;
    /**
     * 
     * @type {Array<TicketSourceDto>}
     * @memberof GetTicketListResponse
     */
    'ticketSource'?: Array<TicketSourceDto>;
    /**
     * 
     * @type {Array<TicketPriorityDto>}
     * @memberof GetTicketListResponse
     */
    'ticketPriority'?: Array<TicketPriorityDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketPriorityListResponse
 */
export interface GetTicketPriorityListResponse {
    /**
     * 
     * @type {Array<TicketPriorityDto>}
     * @memberof GetTicketPriorityListResponse
     */
    'list'?: Array<TicketPriorityDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketPriorityListResponse
     */
    'force'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketResponse
 */
export interface GetTicketResponse {
    /**
     * 
     * @type {TicketDto}
     * @memberof GetTicketResponse
     */
    'ticket'?: TicketDto;
    /**
     * 
     * @type {number}
     * @memberof GetTicketResponse
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketSourceListResponse
 */
export interface GetTicketSourceListResponse {
    /**
     * 
     * @type {Array<TicketSourceDto>}
     * @memberof GetTicketSourceListResponse
     */
    'list'?: Array<TicketSourceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketSourceListResponse
     */
    'force'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketStatusListResponse
 */
export interface GetTicketStatusListResponse {
    /**
     * 
     * @type {Array<TicketStatusDto>}
     * @memberof GetTicketStatusListResponse
     */
    'list'?: Array<TicketStatusDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketStatusListResponse
     */
    'force'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketStatusResponse
 */
export interface GetTicketStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTicketStatusResponse
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketStatusResponse
     */
    'force'?: boolean;
    /**
     * 
     * @type {TicketStatusDto}
     * @memberof GetTicketStatusResponse
     */
    'ticketStatus'?: TicketStatusDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketTagsResponse
 */
export interface GetTicketTagsResponse {
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof GetTicketTagsResponse
     */
    'tags'?: Array<TagDto>;
    /**
     * 
     * @type {number}
     * @memberof GetTicketTagsResponse
     */
    'ticketId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketTemplateResponse
 */
export interface GetTicketTemplateResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof GetTicketTemplateResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketTemplateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<TicketTemplateDto>}
     * @memberof GetTicketTemplateResponse
     */
    'dtos'?: Array<TicketTemplateDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketThreadsResponse
 */
export interface GetTicketThreadsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketThreadsResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {Array<TicketThreadDto>}
     * @memberof GetTicketThreadsResponse
     */
    'threads'?: Array<TicketThreadDto>;
    /**
     * 
     * @type {number}
     * @memberof GetTicketThreadsResponse
     */
    'ticketId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketTypeListResponse
 */
export interface GetTicketTypeListResponse {
    /**
     * 
     * @type {Array<TicketTypeDto>}
     * @memberof GetTicketTypeListResponse
     */
    'list'?: Array<TicketTypeDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketTypeListResponse
     */
    'force'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetTicketTypeResponse
 */
export interface GetTicketTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTicketTypeResponse
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketTypeResponse
     */
    'force'?: boolean;
    /**
     * 
     * @type {TicketTypeDto}
     * @memberof GetTicketTypeResponse
     */
    'ticketType'?: TicketTypeDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetUserGroupListResponse
 */
export interface GetUserGroupListResponse {
    /**
     * 
     * @type {Array<UserGroupDto>}
     * @memberof GetUserGroupListResponse
     */
    'list'?: Array<UserGroupDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetUserGroupResponse
 */
export interface GetUserGroupResponse {
    /**
     * 
     * @type {UserGroupDto}
     * @memberof GetUserGroupResponse
     */
    'userGroup'?: UserGroupDto;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof GetUserGroupResponse
     */
    'users'?: Array<UserDto>;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof GetUserGroupResponse
     */
    'usersNoIn'?: Array<UserDto>;
    /**
     * 
     * @type {Array<PolicyDto>}
     * @memberof GetUserGroupResponse
     */
    'policies'?: Array<PolicyDto>;
    /**
     * 
     * @type {Array<PolicyDto>}
     * @memberof GetUserGroupResponse
     */
    'policiesNotIn'?: Array<PolicyDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserGroupResponse
     */
    'includeUsers'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetUserListResponse
 */
export interface GetUserListResponse {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof GetUserListResponse
     */
    'list'?: Array<UserDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetUserParticipatingResponse
 */
export interface GetUserParticipatingResponse {
    /**
     * 
     * @type {GetServiceInstanceUserParticipatingRequest}
     * @memberof GetUserParticipatingResponse
     */
    'request'?: GetServiceInstanceUserParticipatingRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserParticipatingResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProjectServiceUserParticipatingServiceDto>}
     * @memberof GetUserParticipatingResponse
     */
    'dtos'?: Array<ProjectServiceUserParticipatingServiceDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetUserResponse
 */
export interface GetUserResponse {
    /**
     * 
     * @type {UserDto}
     * @memberof GetUserResponse
     */
    'user'?: UserDto;
    /**
     * 
     * @type {UserProfileDto}
     * @memberof GetUserResponse
     */
    'userProfileDto'?: UserProfileDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetUserSourceListResponse
 */
export interface GetUserSourceListResponse {
    /**
     * 
     * @type {Array<UserSourceDto>}
     * @memberof GetUserSourceListResponse
     */
    'list'?: Array<UserSourceDto>;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserSourceListResponse
     */
    'force'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetUserSourceResponse
 */
export interface GetUserSourceResponse {
    /**
     * 
     * @type {UserSourceDto}
     * @memberof GetUserSourceResponse
     */
    'userSource'?: UserSourceDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface GetVendorMailListRequest
 */
export interface GetVendorMailListRequest {
    /**
     * 
     * @type {number}
     * @memberof GetVendorMailListRequest
     */
    'vendorId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetVendorMailListRequest
     */
    'publicKey'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface GetVendorMailListResponse
 */
export interface GetVendorMailListResponse {
    /**
     * 
     * @type {GetVendorMailListRequest}
     * @memberof GetVendorMailListResponse
     */
    'request'?: GetVendorMailListRequest;
    /**
     * 
     * @type {boolean}
     * @memberof GetVendorMailListResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailBoxDto>}
     * @memberof GetVendorMailListResponse
     */
    'mailBox'?: Array<MailBoxDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface HistoryStatisticsDataRequest
 */
export interface HistoryStatisticsDataRequest {
    /**
     * 
     * @type {string}
     * @memberof HistoryStatisticsDataRequest
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryStatisticsDataRequest
     */
    'end'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryStatisticsDataRequest
     */
    'type'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface HistoryStatisticsDataResponse
 */
export interface HistoryStatisticsDataResponse {
    /**
     * 
     * @type {HistoryStatisticsDataRequest}
     * @memberof HistoryStatisticsDataResponse
     */
    'request'?: HistoryStatisticsDataRequest;
    /**
     * 
     * @type {boolean}
     * @memberof HistoryStatisticsDataResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<StatisticsDataDto>}
     * @memberof HistoryStatisticsDataResponse
     */
    'dataValues'?: Array<StatisticsDataDto>;
}
/**
 * 
 * @export
 * @interface KnowledgeBaseCategoryDto
 */
export interface KnowledgeBaseCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof KnowledgeBaseCategoryDto
     */
    'id'?: number;
    /**
     * 
     * @type {KnowledgeBaseCategoryDto}
     * @memberof KnowledgeBaseCategoryDto
     */
    'parent'?: KnowledgeBaseCategoryDto;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseCategoryDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseCategoryDto
     */
    'description'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof KnowledgeBaseCategoryDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseCategoryDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof KnowledgeBaseCategoryDto
     */
    'updatedBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseCategoryDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface KnowledgeBaseItemDto
 */
export interface KnowledgeBaseItemDto {
    /**
     * 
     * @type {number}
     * @memberof KnowledgeBaseItemDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseItemDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseItemDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof KnowledgeBaseItemDto
     */
    'categoryId'?: number;
    /**
     * 
     * @type {KnowledgeItemTypeDto}
     * @memberof KnowledgeBaseItemDto
     */
    'type'?: KnowledgeItemTypeDto;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseItemDto
     */
    'message'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof KnowledgeBaseItemDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseItemDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof KnowledgeBaseItemDto
     */
    'updatedBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof KnowledgeBaseItemDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface KnowledgeItemTypeDto
 */
export interface KnowledgeItemTypeDto {
    /**
     * 
     * @type {number}
     * @memberof KnowledgeItemTypeDto
     */
    'id'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof KnowledgeItemTypeDto
     */
    'description'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface MailAccessDto
 */
export interface MailAccessDto {
    /**
     * 
     * @type {boolean}
     * @memberof MailAccessDto
     */
    'canRead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailAccessDto
     */
    'canSend'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailAccessDto
     */
    'canApprove'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MailAccessDto
     */
    'readLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailAccessDto
     */
    'sendLimit'?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof MailAccessDto
     */
    'user'?: UserDto;
}
/**
 * 
 * @export
 * @interface MailAccessReqDto
 */
export interface MailAccessReqDto {
    /**
     * 
     * @type {boolean}
     * @memberof MailAccessReqDto
     */
    'canRead'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailAccessReqDto
     */
    'canSend'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailAccessReqDto
     */
    'canApprove'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MailAccessReqDto
     */
    'readLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailAccessReqDto
     */
    'sendLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailAccessReqDto
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface MailBoxContactAggregationDto
 */
export interface MailBoxContactAggregationDto {
    /**
     * 
     * @type {number}
     * @memberof MailBoxContactAggregationDto
     */
    'mailBoxId'?: number;
    /**
     * 
     * @type {{ [key: string]: Array<WorkbenchMailBoxContactDto>; }}
     * @memberof MailBoxContactAggregationDto
     */
    'contacts'?: { [key: string]: Array<WorkbenchMailBoxContactDto>; };
}
/**
 * 
 * @export
 * @interface MailBoxDto
 */
export interface MailBoxDto {
    /**
     * 
     * @type {number}
     * @memberof MailBoxDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailBoxDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailBoxDto
     */
    'credential'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MailBoxDto
     */
    'description'?: { [key: string]: string; };
    /**
     * 
     * @type {MailVendorDto}
     * @memberof MailBoxDto
     */
    'vendor'?: MailVendorDto;
    /**
     * 
     * @type {UserDto}
     * @memberof MailBoxDto
     */
    'creator'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof MailBoxDto
     */
    'updater'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof MailBoxDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailBoxDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailBoxDto
     */
    'combineTo'?: Array<string>;
    /**
     * 
     * @type {MailAccessDto}
     * @memberof MailBoxDto
     */
    'accessDto'?: MailAccessDto;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof MailBoxDto
     */
    'tags'?: Array<TagDto>;
}
/**
 * 
 * @export
 * @interface MailBoxStateDto
 */
export interface MailBoxStateDto {
    /**
     * 
     * @type {string}
     * @memberof MailBoxStateDto
     */
    'stateName'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MailBoxStateDto
     */
    'stateDescription'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof MailBoxStateDto
     */
    'latestUpdate'?: string;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'runningInstance'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'mailBoxId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailBoxStateDto
     */
    'occupiedId'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof MailBoxStateDto
     */
    'failedInstance'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'completed'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'failed'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'received'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'sent'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailBoxStateDto
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'scheduledFailTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'linkFailCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailBoxStateDto
     */
    'nextCycle'?: string;
    /**
     * 
     * @type {number}
     * @memberof MailBoxStateDto
     */
    'syncFailTime'?: number;
}
/**
 * 
 * @export
 * @interface MailDetailDto
 */
export interface MailDetailDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof MailDetailDto
     */
    'to'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailDetailDto
     */
    'cc'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailDetailDto
     */
    'bcc'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MailDetailDto
     */
    'split'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MailDetailDto
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailDetailDto
     */
    'body'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailDetailDto
     */
    'cid'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailDetailDto
     */
    'attachments'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MailDto
 */
export interface MailDto {
    /**
     * 
     * @type {number}
     * @memberof MailDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailDto
     */
    'syncDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailDto
     */
    'mailDate'?: string;
    /**
     * 
     * @type {MailBoxDto}
     * @memberof MailDto
     */
    'mailBox'?: MailBoxDto;
    /**
     * 
     * @type {string}
     * @memberof MailDto
     */
    'mailId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailDto
     */
    'subject'?: string;
    /**
     * 
     * @type {number}
     * @memberof MailDto
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof MailDto
     */
    'recvCost'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailDto
     */
    'folder'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MailDto
     */
    'externInfo'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface MailSendingDto
 */
export interface MailSendingDto {
    /**
     * 
     * @type {number}
     * @memberof MailSendingDto
     */
    'id'?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof MailSendingDto
     */
    'reviewer'?: UserDto;
    /**
     * 
     * @type {MailBoxDto}
     * @memberof MailSendingDto
     */
    'mailBox'?: MailBoxDto;
    /**
     * 
     * @type {MailDetailDto}
     * @memberof MailSendingDto
     */
    'detail'?: MailDetailDto;
    /**
     * 
     * @type {string}
     * @memberof MailSendingDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailSendingDto
     */
    'sendStatus'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MailSendingDto
     */
    'sendStatusDescription'?: { [key: string]: string; };
    /**
     * 
     * @type {UserDto}
     * @memberof MailSendingDto
     */
    'creator'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof MailSendingDto
     */
    'updater'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof MailSendingDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailSendingDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailSendingDto
     */
    'subject'?: string;
}
/**
 * 
 * @export
 * @interface MailSimpleTemplateDto
 */
export interface MailSimpleTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof MailSimpleTemplateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailSimpleTemplateDto
     */
    'no'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailSimpleTemplateDto
     */
    'title'?: string;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof MailSimpleTemplateDto
     */
    'tags'?: Array<TagDto>;
    /**
     * 
     * @type {string}
     * @memberof MailSimpleTemplateDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof MailSimpleTemplateDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof MailSimpleTemplateDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof MailSimpleTemplateDto
     */
    'updatedBy'?: UserDto;
}
/**
 * 
 * @export
 * @interface MailTemplateDto
 */
export interface MailTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof MailTemplateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateDto
     */
    'no'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateDto
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateDto
     */
    'content'?: string;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof MailTemplateDto
     */
    'tags'?: Array<TagDto>;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof MailTemplateDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof MailTemplateDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof MailTemplateDto
     */
    'updatedBy'?: UserDto;
}
/**
 * 
 * @export
 * @interface MailVendorConfigureDto
 */
export interface MailVendorConfigureDto {
    /**
     * 
     * @type {string}
     * @memberof MailVendorConfigureDto
     */
    'receiveHost'?: string;
    /**
     * 
     * @type {number}
     * @memberof MailVendorConfigureDto
     */
    'receivePort'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MailVendorConfigureDto
     */
    'receiveAuth'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailVendorConfigureDto
     */
    'ssl'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MailVendorConfigureDto
     */
    'receiveProtocol'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailVendorConfigureDto
     */
    'sendProtocol'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailVendorConfigureDto
     */
    'sendAuth'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MailVendorConfigureDto
     */
    'sendPort'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailVendorConfigureDto
     */
    'sendHost'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MailVendorConfigureDto
     */
    'startSsl'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MailVendorConfigureDto
     */
    'sendSsl'?: boolean;
}
/**
 * 
 * @export
 * @interface MailVendorDto
 */
export interface MailVendorDto {
    /**
     * 
     * @type {number}
     * @memberof MailVendorDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof MailVendorDto
     */
    'vendorName'?: string;
    /**
     * 
     * @type {MailVendorConfigureDto}
     * @memberof MailVendorDto
     */
    'mailConfigure'?: MailVendorConfigureDto;
    /**
     * 
     * @type {UserDto}
     * @memberof MailVendorDto
     */
    'creator'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof MailVendorDto
     */
    'updater'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof MailVendorDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MailVendorDto
     */
    'updatedAt'?: string;
}
/**
 * 本次请求的内容
 * @export
 * @interface MetaGetProcessTemplateRequest
 */
export interface MetaGetProcessTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof MetaGetProcessTemplateRequest
     */
    'serviceId'?: number;
}
/**
 * 
 * @export
 * @interface NetworkStatusDto
 */
export interface NetworkStatusDto {
    /**
     * 
     * @type {string}
     * @memberof NetworkStatusDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NetworkStatusDto
     */
    'desc'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface NotificationConfigurationKeyDto
 */
export interface NotificationConfigurationKeyDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationConfigurationKeyDto
     */
    'systemConfigurationKey'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationConfigurationKeyDto
     */
    'notificationConfigurationKeys'?: Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationEventTypeDto
 */
export interface NotificationEventTypeDto {
    /**
     * 
     * @type {number}
     * @memberof NotificationEventTypeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationEventTypeDto
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEventTypeDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationEventTypeDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof NotificationEventTypeDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof NotificationEventTypeDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof NotificationEventTypeDto
     */
    'updatedBy'?: UserDto;
}
/**
 * 
 * @export
 * @interface ParticipatedRecordsDto
 */
export interface ParticipatedRecordsDto {
    /**
     * 
     * @type {UserDto}
     * @memberof ParticipatedRecordsDto
     */
    'user'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof ParticipatedRecordsDto
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipatedRecordsDto
     */
    'end'?: string;
}
/**
 * 
 * @export
 * @interface ParticipatedTicketsDto
 */
export interface ParticipatedTicketsDto {
    /**
     * 
     * @type {number}
     * @memberof ParticipatedTicketsDto
     */
    'ticketId'?: number;
    /**
     * 
     * @type {UserDto}
     * @memberof ParticipatedTicketsDto
     */
    'creator'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof ParticipatedTicketsDto
     */
    'expectCloseAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipatedTicketsDto
     */
    'createAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipatedTicketsDto
     */
    'closeAt'?: string;
    /**
     * 
     * @type {Array<ParticipatedRecordsDto>}
     * @memberof ParticipatedTicketsDto
     */
    'records'?: Array<ParticipatedRecordsDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface ParticipatingTicketsRequest
 */
export interface ParticipatingTicketsRequest {
    /**
     * 
     * @type {string}
     * @memberof ParticipatingTicketsRequest
     */
    'start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipatingTicketsRequest
     */
    'end'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipatingTicketsRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipatingTicketsRequest
     */
    'actAsCreator'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface ParticipatingTicketsResponse
 */
export interface ParticipatingTicketsResponse {
    /**
     * 
     * @type {ParticipatingTicketsRequest}
     * @memberof ParticipatingTicketsResponse
     */
    'request'?: ParticipatingTicketsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipatingTicketsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ParticipatedTicketsDto>}
     * @memberof ParticipatingTicketsResponse
     */
    'participatedTickets'?: Array<ParticipatedTicketsDto>;
}
/**
 * 本次请求的内容
 * @export
 * @interface ParticipatorStatisticsHistoryRequest
 */
export interface ParticipatorStatisticsHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof ParticipatorStatisticsHistoryRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipatorStatisticsHistoryRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipatorStatisticsHistoryRequest
     */
    'endDate'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface ParticipatorStatisticsHistoryResponse
 */
export interface ParticipatorStatisticsHistoryResponse {
    /**
     * 
     * @type {ParticipatorStatisticsHistoryRequest}
     * @memberof ParticipatorStatisticsHistoryResponse
     */
    'request'?: ParticipatorStatisticsHistoryRequest;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipatorStatisticsHistoryResponse
     */
    'success'?: boolean;
    /**
     * 统计数据
     * @type {Array<StatisticsDataDto>}
     * @memberof ParticipatorStatisticsHistoryResponse
     */
    'data'?: Array<StatisticsDataDto>;
}
/**
 * 
 * @export
 * @interface PatchCopyTicketRequest
 */
export interface PatchCopyTicketRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchCopyTicketRequest
     */
    'assignees'?: Array<number>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PatchCopyTicketRequest
     */
    'options'?: { [key: string]: object; };
}
/**
 * 响应请求的数据
 * @export
 * @interface PatchCopyTicketResponse
 */
export interface PatchCopyTicketResponse {
    /**
     * 
     * @type {PatchCopyTicketRequest}
     * @memberof PatchCopyTicketResponse
     */
    'request'?: PatchCopyTicketRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PatchCopyTicketResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<TicketDto>}
     * @memberof PatchCopyTicketResponse
     */
    'tickets'?: Array<TicketDto>;
    /**
     * 
     * @type {number}
     * @memberof PatchCopyTicketResponse
     */
    'copyFrom'?: number;
}
/**
 * 
 * @export
 * @interface PatchMailBoxStateRequest
 */
export interface PatchMailBoxStateRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchMailBoxStateRequest
     */
    'type'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PatchMailBoxStateResponse
 */
export interface PatchMailBoxStateResponse {
    /**
     * 
     * @type {PatchMailBoxStateRequest}
     * @memberof PatchMailBoxStateResponse
     */
    'request'?: PatchMailBoxStateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PatchMailBoxStateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailBoxDto}
     * @memberof PatchMailBoxStateResponse
     */
    'mailBoxDto'?: MailBoxDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface PatchManualEvaluateResponse
 */
export interface PatchManualEvaluateResponse {
    /**
     * 
     * @type {object}
     * @memberof PatchManualEvaluateResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof PatchManualEvaluateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<MailBoxStateDto>}
     * @memberof PatchManualEvaluateResponse
     */
    'mailBoxStates'?: Array<MailBoxStateDto>;
}
/**
 * 
 * @export
 * @interface PatchSendMailRequest
 */
export interface PatchSendMailRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchSendMailRequest
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchSendMailRequest
     */
    'scheduledDate'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PatchSendMailResponse
 */
export interface PatchSendMailResponse {
    /**
     * 
     * @type {PatchSendMailRequest}
     * @memberof PatchSendMailResponse
     */
    'request'?: PatchSendMailRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PatchSendMailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailSendingDto}
     * @memberof PatchSendMailResponse
     */
    'sendingDto'?: MailSendingDto;
}
/**
 * 
 * @export
 * @interface PluginConfigureDto
 */
export interface PluginConfigureDto {
    /**
     * 
     * @type {number}
     * @memberof PluginConfigureDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PluginConfigureDto
     */
    'pluginId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PluginConfigureDto
     */
    'config'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PluginConfigureDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {UserDto}
     * @memberof PluginConfigureDto
     */
    'runAs'?: UserDto;
}
/**
 * 
 * @export
 * @interface PluginDto
 */
export interface PluginDto {
    /**
     * 
     * @type {number}
     * @memberof PluginDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PluginDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PluginDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PluginDto
     */
    'schema'?: string;
    /**
     * 
     * @type {string}
     * @memberof PluginDto
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface PolicyDto
 */
export interface PolicyDto {
    /**
     * 
     * @type {number}
     * @memberof PolicyDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PolicyDto
     */
    'allowed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof PolicyDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface PostAllocateTaskRequest
 */
export interface PostAllocateTaskRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PostAllocateTaskRequest
     */
    'tasks'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostAllocateTaskResponse
 */
export interface PostAllocateTaskResponse {
    /**
     * 
     * @type {PostAllocateTaskRequest}
     * @memberof PostAllocateTaskResponse
     */
    'request'?: PostAllocateTaskRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostAllocateTaskResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<RemoteTaskDto>}
     * @memberof PostAllocateTaskResponse
     */
    'tasks'?: Array<RemoteTaskDto>;
    /**
     * 
     * @type {RemoteInstanceDto}
     * @memberof PostAllocateTaskResponse
     */
    'instance'?: RemoteInstanceDto;
}
/**
 * 
 * @export
 * @interface PostApproveMailSendApplicationRequest
 */
export interface PostApproveMailSendApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof PostApproveMailSendApplicationRequest
     */
    'action'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostApproveMailSendApplicationResponse
 */
export interface PostApproveMailSendApplicationResponse {
    /**
     * 
     * @type {PostApproveMailSendApplicationRequest}
     * @memberof PostApproveMailSendApplicationResponse
     */
    'request'?: PostApproveMailSendApplicationRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostApproveMailSendApplicationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostApproveMailSendApplicationResponse
     */
    'mailId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostApproveMailSendApplicationResponse
     */
    'mailboxId'?: number;
    /**
     * 
     * @type {MailSendingDto}
     * @memberof PostApproveMailSendApplicationResponse
     */
    'sendingDto'?: MailSendingDto;
}
/**
 * 
 * @export
 * @interface PostChangeExecutorRequest
 */
export interface PostChangeExecutorRequest {
    /**
     * 
     * @type {number}
     * @memberof PostChangeExecutorRequest
     */
    'newExecutor'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostChangeExecutorResponse
 */
export interface PostChangeExecutorResponse {
    /**
     * 
     * @type {PostChangeExecutorRequest}
     * @memberof PostChangeExecutorResponse
     */
    'request'?: PostChangeExecutorRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostChangeExecutorResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostChangeExecutorResponse
     */
    'itemInstanceId'?: number;
}
/**
 * 
 * @export
 * @interface PostCommitDeliveryMaterialRequest
 */
export interface PostCommitDeliveryMaterialRequest {
    /**
     * 
     * @type {number}
     * @memberof PostCommitDeliveryMaterialRequest
     */
    'attachmentId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostCommitDeliveryMaterialResponse
 */
export interface PostCommitDeliveryMaterialResponse {
    /**
     * 
     * @type {PostCommitDeliveryMaterialRequest}
     * @memberof PostCommitDeliveryMaterialResponse
     */
    'request'?: PostCommitDeliveryMaterialRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostCommitDeliveryMaterialResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PostCommitItemKnowledgeRequest
 */
export interface PostCommitItemKnowledgeRequest {
    /**
     * 
     * @type {number}
     * @memberof PostCommitItemKnowledgeRequest
     */
    'attachmentId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostCommitItemKnowledgeResponse
 */
export interface PostCommitItemKnowledgeResponse {
    /**
     * 
     * @type {PostCommitItemKnowledgeRequest}
     * @memberof PostCommitItemKnowledgeResponse
     */
    'request'?: PostCommitItemKnowledgeRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostCommitItemKnowledgeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostCommitItemKnowledgeResponse
     */
    'itemId'?: number;
}
/**
 * 
 * @export
 * @interface PostContentSchemaRequest
 */
export interface PostContentSchemaRequest {
    /**
     * 
     * @type {string}
     * @memberof PostContentSchemaRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostContentSchemaRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostContentSchemaRequest
     */
    'schema'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostContentSchemaRequest
     */
    'uiSchema'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostContentSchemaResponse
 */
export interface PostContentSchemaResponse {
    /**
     * 
     * @type {PostContentSchemaRequest}
     * @memberof PostContentSchemaResponse
     */
    'request'?: PostContentSchemaRequest;
    /**
     * 
     * @type {number}
     * @memberof PostContentSchemaResponse
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostContentSchemaResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PostEscalationRuleRequest
 */
export interface PostEscalationRuleRequest {
    /**
     * 
     * @type {number}
     * @memberof PostEscalationRuleRequest
     */
    'order'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostEscalationRuleRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostEscalationRuleRequest
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostEscalationRuleRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostEscalationRuleRequest
     */
    'type'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostEscalationRuleRequest
     */
    'status'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostEscalationRuleRequest
     */
    'priority'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostEscalationRuleRequest
     */
    'source'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PostEscalationRuleRequest
     */
    'timeAxis'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostEscalationRuleRequest
     */
    'timeConstraint'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostEscalationRuleRequest
     */
    'timeDirection'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PostEscalationRuleRequest
     */
    'frequency'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof PostEscalationRuleRequest
     */
    'actions'?: Array<string>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostEscalationRuleResponseEscalationRuleDtoV1
 */
export interface PostEscalationRuleResponseEscalationRuleDtoV1 {
    /**
     * 
     * @type {EscalationRuleDtoV1}
     * @memberof PostEscalationRuleResponseEscalationRuleDtoV1
     */
    'rule'?: EscalationRuleDtoV1;
    /**
     * 
     * @type {boolean}
     * @memberof PostEscalationRuleResponseEscalationRuleDtoV1
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {PostEscalationRuleRequest}
     * @memberof PostEscalationRuleResponseEscalationRuleDtoV1
     */
    'request'?: PostEscalationRuleRequest;
}
/**
 * 
 * @export
 * @interface PostExamineAndApproveServiceRequest
 */
export interface PostExamineAndApproveServiceRequest {
    /**
     * 
     * @type {number}
     * @memberof PostExamineAndApproveServiceRequest
     */
    'approve'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostExamineAndApproveServiceRequest
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostExamineAndApproveServiceRequest
     */
    'start'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostExamineAndApproveServiceResponse
 */
export interface PostExamineAndApproveServiceResponse {
    /**
     * 
     * @type {PostExamineAndApproveServiceRequest}
     * @memberof PostExamineAndApproveServiceResponse
     */
    'request'?: PostExamineAndApproveServiceRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostExamineAndApproveServiceResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostExamineAndApproveServiceResponse
     */
    'serviceInstanceId'?: number;
}
/**
 * 
 * @export
 * @interface PostExportRequest
 */
export interface PostExportRequest {
    /**
     * 
     * @type {string}
     * @memberof PostExportRequest
     */
    'parameter'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostExportResponse
 */
export interface PostExportResponse {
    /**
     * 
     * @type {PostExportRequest}
     * @memberof PostExportResponse
     */
    'request'?: PostExportRequest;
    /**
     * 
     * @type {number}
     * @memberof PostExportResponse
     */
    'type'?: number;
    /**
     * 
     * @type {ExportJobDto}
     * @memberof PostExportResponse
     */
    'job'?: ExportJobDto;
    /**
     * 
     * @type {boolean}
     * @memberof PostExportResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PostKnowledgeCategoryRequest
 */
export interface PostKnowledgeCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof PostKnowledgeCategoryRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostKnowledgeCategoryRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostKnowledgeCategoryRequest
     */
    'parent'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostKnowledgeCategoryResponse
 */
export interface PostKnowledgeCategoryResponse {
    /**
     * 
     * @type {PostKnowledgeCategoryRequest}
     * @memberof PostKnowledgeCategoryResponse
     */
    'request'?: PostKnowledgeCategoryRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostKnowledgeCategoryResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PostKnowledgeItemRequest
 */
export interface PostKnowledgeItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PostKnowledgeItemRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostKnowledgeItemRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostKnowledgeItemRequest
     */
    'categoryId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostKnowledgeItemRequest
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostKnowledgeItemRequest
     */
    'message'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostKnowledgeItemResponse
 */
export interface PostKnowledgeItemResponse {
    /**
     * 
     * @type {PostKnowledgeItemRequest}
     * @memberof PostKnowledgeItemResponse
     */
    'request'?: PostKnowledgeItemRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostKnowledgeItemResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {KnowledgeBaseItemDto}
     * @memberof PostKnowledgeItemResponse
     */
    'item'?: KnowledgeBaseItemDto;
}
/**
 * 
 * @export
 * @interface PostMailBoxAccessRequest
 */
export interface PostMailBoxAccessRequest {
    /**
     * 
     * @type {Array<MailAccessReqDto>}
     * @memberof PostMailBoxAccessRequest
     */
    'access'?: Array<MailAccessReqDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailBoxAccessResponse
 */
export interface PostMailBoxAccessResponse {
    /**
     * 
     * @type {PostMailBoxAccessRequest}
     * @memberof PostMailBoxAccessResponse
     */
    'request'?: PostMailBoxAccessRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailBoxAccessResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostMailBoxAccessResponse
     */
    'requestMailBoxId'?: number;
}
/**
 * 
 * @export
 * @interface PostMailBoxesRequest
 */
export interface PostMailBoxesRequest {
    /**
     * 
     * @type {string}
     * @memberof PostMailBoxesRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostMailBoxesRequest
     */
    'credential'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PostMailBoxesRequest
     */
    'description'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof PostMailBoxesRequest
     */
    'vendorId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostMailBoxesRequest
     */
    'deployTo'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailBoxesResponse
 */
export interface PostMailBoxesResponse {
    /**
     * 
     * @type {PostMailBoxesRequest}
     * @memberof PostMailBoxesResponse
     */
    'request'?: PostMailBoxesRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailBoxesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailBoxDto}
     * @memberof PostMailBoxesResponse
     */
    'mailBox'?: MailBoxDto;
}
/**
 * 
 * @export
 * @interface PostMailContactsRequest
 */
export interface PostMailContactsRequest {
    /**
     * 
     * @type {string}
     * @memberof PostMailContactsRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostMailContactsRequest
     */
    'nickname'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostMailContactsRequest
     */
    'tags'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailContactsRequest
     */
    'enable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostMailContactsRequest
     */
    'extendsInfo'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailContactsResponse
 */
export interface PostMailContactsResponse {
    /**
     * 
     * @type {PostMailContactsRequest}
     * @memberof PostMailContactsResponse
     */
    'request'?: PostMailContactsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailContactsResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailSendApplicationResponse
 */
export interface PostMailSendApplicationResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof PostMailSendApplicationResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailSendApplicationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostMailSendApplicationResponse
     */
    'mailboxId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostMailSendApplicationResponse
     */
    'mailId'?: number;
    /**
     * 
     * @type {MailSendingDto}
     * @memberof PostMailSendApplicationResponse
     */
    'sendingDto'?: MailSendingDto;
}
/**
 * 
 * @export
 * @interface PostMailTagsRequest
 */
export interface PostMailTagsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PostMailTagsRequest
     */
    'tagIds'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailTagsResponse
 */
export interface PostMailTagsResponse {
    /**
     * 
     * @type {PostMailTagsRequest}
     * @memberof PostMailTagsResponse
     */
    'request'?: PostMailTagsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailTagsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailBoxDto}
     * @memberof PostMailTagsResponse
     */
    'mailBoxDto'?: MailBoxDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface PostMailTemplateBindingRequest
 */
export interface PostMailTemplateBindingRequest {
    /**
     * 
     * @type {number}
     * @memberof PostMailTemplateBindingRequest
     */
    'templateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostMailTemplateBindingRequest
     */
    'mailboxId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailTemplateBindingResponse
 */
export interface PostMailTemplateBindingResponse {
    /**
     * 
     * @type {PostMailTemplateBindingRequest}
     * @memberof PostMailTemplateBindingResponse
     */
    'request'?: PostMailTemplateBindingRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailTemplateBindingResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PostMailTemplateRequest
 */
export interface PostMailTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof PostMailTemplateRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostMailTemplateRequest
     */
    'content'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostMailTemplateRequest
     */
    'tags'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailTemplateResponse
 */
export interface PostMailTemplateResponse {
    /**
     * 
     * @type {PostMailTemplateRequest}
     * @memberof PostMailTemplateResponse
     */
    'request'?: PostMailTemplateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailTemplateResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PostMailVendorRequest
 */
export interface PostMailVendorRequest {
    /**
     * 
     * @type {string}
     * @memberof PostMailVendorRequest
     */
    'name'?: string;
    /**
     * 
     * @type {MailVendorConfigureDto}
     * @memberof PostMailVendorRequest
     */
    'configure'?: MailVendorConfigureDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailVendorResponse
 */
export interface PostMailVendorResponse {
    /**
     * 
     * @type {PostMailVendorRequest}
     * @memberof PostMailVendorResponse
     */
    'request'?: PostMailVendorRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailVendorResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailVendorDto}
     * @memberof PostMailVendorResponse
     */
    'vendor'?: MailVendorDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface PostMailboxContactBindingRequest
 */
export interface PostMailboxContactBindingRequest {
    /**
     * 
     * @type {number}
     * @memberof PostMailboxContactBindingRequest
     */
    'mailboxId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostMailboxContactBindingRequest
     */
    'contactId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostMailboxContactBindingRequest
     */
    'type'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostMailboxContactBindingResponse
 */
export interface PostMailboxContactBindingResponse {
    /**
     * 
     * @type {PostMailboxContactBindingRequest}
     * @memberof PostMailboxContactBindingResponse
     */
    'request'?: PostMailboxContactBindingRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostMailboxContactBindingResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PostNotificationEventRequest
 */
export interface PostNotificationEventRequest {
    /**
     * 
     * @type {string}
     * @memberof PostNotificationEventRequest
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostNotificationEventRequest
     */
    'descJson'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostNotificationEventResponse
 */
export interface PostNotificationEventResponse {
    /**
     * 
     * @type {PostNotificationEventRequest}
     * @memberof PostNotificationEventResponse
     */
    'request'?: PostNotificationEventRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostNotificationEventResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PostNotificationRequest
 */
export interface PostNotificationRequest {
    /**
     * 
     * @type {string}
     * @memberof PostNotificationRequest
     */
    'loginId'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PostNotificationRequest
     */
    'context'?: { [key: string]: string; };
}
/**
 * 响应请求的数据
 * @export
 * @interface PostNotificationResponse
 */
export interface PostNotificationResponse {
    /**
     * 
     * @type {PostNotificationRequest}
     * @memberof PostNotificationResponse
     */
    'request'?: PostNotificationRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostNotificationResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostNotificationResponse
     */
    'topic'?: string;
}
/**
 * 
 * @export
 * @interface PostPluginConfigureRequest
 */
export interface PostPluginConfigureRequest {
    /**
     * 
     * @type {string}
     * @memberof PostPluginConfigureRequest
     */
    'configure'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostPluginConfigureRequest
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostPluginConfigureRequest
     */
    'runAs'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostPluginConfigureResponse
 */
export interface PostPluginConfigureResponse {
    /**
     * 
     * @type {PostPluginConfigureRequest}
     * @memberof PostPluginConfigureResponse
     */
    'request'?: PostPluginConfigureRequest;
    /**
     * 
     * @type {number}
     * @memberof PostPluginConfigureResponse
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostPluginConfigureResponse
     */
    'pluginId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostPluginConfigureResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PostPluginRequest
 */
export interface PostPluginRequest {
    /**
     * 
     * @type {string}
     * @memberof PostPluginRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPluginRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPluginRequest
     */
    'parameterSchema'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPluginRequest
     */
    'version'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostPluginResponse
 */
export interface PostPluginResponse {
    /**
     * 
     * @type {PostPluginRequest}
     * @memberof PostPluginResponse
     */
    'request'?: PostPluginRequest;
    /**
     * 
     * @type {number}
     * @memberof PostPluginResponse
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostPluginResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PostProcessRequest
 */
export interface PostProcessRequest {
    /**
     * 
     * @type {string}
     * @memberof PostProcessRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostProcessRequest
     */
    'desc'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostProcessRequest
     */
    'processTemplate'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostProcessResponse
 */
export interface PostProcessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PostProcessResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {PostProcessRequest}
     * @memberof PostProcessResponse
     */
    'request'?: PostProcessRequest;
}
/**
 * 
 * @export
 * @interface PostProjectServiceDraftRequest
 */
export interface PostProjectServiceDraftRequest {
    /**
     * 
     * @type {number}
     * @memberof PostProjectServiceDraftRequest
     */
    'draftId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostProjectServiceDraftRequest
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostProjectServiceDraftRequest
     */
    'owner'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostProjectServiceDraftRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostProjectServiceDraftRequest
     */
    'template'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostProjectServiceDraftRequest
     */
    'version'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostProjectServiceDraftResponse
 */
export interface PostProjectServiceDraftResponse {
    /**
     * 
     * @type {PostProjectServiceDraftRequest}
     * @memberof PostProjectServiceDraftResponse
     */
    'request'?: PostProjectServiceDraftRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostProjectServiceDraftResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostProjectServiceDraftResponse
     */
    'draftId'?: number;
}
/**
 * 
 * @export
 * @interface PostProjectServiceMetaRequest
 */
export interface PostProjectServiceMetaRequest {
    /**
     * 
     * @type {number}
     * @memberof PostProjectServiceMetaRequest
     */
    'projectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostProjectServiceMetaRequest
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostProjectServiceMetaRequest
     */
    'owner'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostProjectServiceMetaRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostProjectServiceMetaRequest
     */
    'template'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostProjectServiceMetaRequest
     */
    'version'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostProjectServiceMetaRequest
     */
    'active'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostProjectServiceMetaResponse
 */
export interface PostProjectServiceMetaResponse {
    /**
     * 
     * @type {PostProjectServiceMetaRequest}
     * @memberof PostProjectServiceMetaResponse
     */
    'request'?: PostProjectServiceMetaRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostProjectServiceMetaResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PostProjectServiceStatusRequest
 */
export interface PostProjectServiceStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof PostProjectServiceStatusRequest
     */
    'newStatus'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostProjectServiceStatusResponse
 */
export interface PostProjectServiceStatusResponse {
    /**
     * 
     * @type {PostProjectServiceStatusRequest}
     * @memberof PostProjectServiceStatusResponse
     */
    'request'?: PostProjectServiceStatusRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostProjectServiceStatusResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PostReEvalCurrentStageAssigneeRequest
 */
export interface PostReEvalCurrentStageAssigneeRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PostReEvalCurrentStageAssigneeRequest
     */
    'ticketIds'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostReEvalCurrentStageAssigneeResponse
 */
export interface PostReEvalCurrentStageAssigneeResponse {
    /**
     * 
     * @type {PostReEvalCurrentStageAssigneeRequest}
     * @memberof PostReEvalCurrentStageAssigneeResponse
     */
    'request'?: PostReEvalCurrentStageAssigneeRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostReEvalCurrentStageAssigneeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PostReEvalCurrentStageAssigneeResponse
     */
    'evalResult'?: { [key: string]: boolean; };
}
/**
 * 响应请求的数据
 * @export
 * @interface PostRelatedTicketResponse
 */
export interface PostRelatedTicketResponse {
    /**
     * 
     * @type {string}
     * @memberof PostRelatedTicketResponse
     */
    'relatedType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostRelatedTicketResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostRelatedTicketResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostRelatedTicketResponse
     */
    'ticketRelated'?: number;
}
/**
 * 
 * @export
 * @interface PostSendMailRequest
 */
export interface PostSendMailRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PostSendMailRequest
     */
    'sendSplit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostSendMailRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSendMailRequest
     */
    'message'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostSendMailRequest
     */
    'to'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PostSendMailRequest
     */
    'body'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostSendMailRequest
     */
    'cc'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostSendMailRequest
     */
    'bcc'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostSendMailRequest
     */
    'attachment'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostSendMailRequest
     */
    'cid'?: Array<string>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostSendMailResponse
 */
export interface PostSendMailResponse {
    /**
     * 
     * @type {PostSendMailRequest}
     * @memberof PostSendMailResponse
     */
    'request'?: PostSendMailRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostSendMailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostSendMailResponse
     */
    'mailId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostSendMailResponse
     */
    'mailBoxId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostSendMailResponse
     */
    'notifierId'?: number;
    /**
     * 
     * @type {MailSendingDto}
     * @memberof PostSendMailResponse
     */
    'mailSendingDto'?: MailSendingDto;
}
/**
 * 
 * @export
 * @interface PostServiceInstanceSubscribeServiceRequest
 */
export interface PostServiceInstanceSubscribeServiceRequest {
    /**
     * 
     * @type {number}
     * @memberof PostServiceInstanceSubscribeServiceRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostServiceInstanceSubscribeServiceRequest
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostServiceInstanceSubscribeServiceRequest
     */
    'acceptor'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostServiceInstanceSubscribeServiceRequest
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostServiceInstanceSubscribeServiceRequest
     */
    'commit'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostServiceInstanceSubscribeServiceResponse
 */
export interface PostServiceInstanceSubscribeServiceResponse {
    /**
     * 
     * @type {PostServiceInstanceSubscribeServiceRequest}
     * @memberof PostServiceInstanceSubscribeServiceResponse
     */
    'request'?: PostServiceInstanceSubscribeServiceRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostServiceInstanceSubscribeServiceResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostServiceInstanceSubscribeServiceResponse
     */
    'serviceInstanceId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostServiceItemChangeStatusResponse
 */
export interface PostServiceItemChangeStatusResponse {
    /**
     * 
     * @type {ServiceItemChangeStatusRequest}
     * @memberof PostServiceItemChangeStatusResponse
     */
    'request'?: ServiceItemChangeStatusRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostServiceItemChangeStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostServiceItemChangeStatusResponse
     */
    'itemInstanceId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostSetTicketVisibilityResponse
 */
export interface PostSetTicketVisibilityResponse {
    /**
     * 
     * @type {number}
     * @memberof PostSetTicketVisibilityResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostSetTicketVisibilityResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostSetTicketVisibilityResponse
     */
    'public'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostStartFollowupItemResponse
 */
export interface PostStartFollowupItemResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof PostStartFollowupItemResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof PostStartFollowupItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostStartFollowupItemResponse
     */
    'preItemId'?: number;
}
/**
 * 
 * @export
 * @interface PostTaskRequest
 */
export interface PostTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof PostTaskRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTaskRequest
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostTaskRequest
     */
    'identifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostTaskRequest
     */
    'configure'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostTaskResponse
 */
export interface PostTaskResponse {
    /**
     * 
     * @type {PostTaskRequest}
     * @memberof PostTaskResponse
     */
    'request'?: PostTaskRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostTaskResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {RemoteTaskDto}
     * @memberof PostTaskResponse
     */
    'remoteTask'?: RemoteTaskDto;
}
/**
 * 
 * @export
 * @interface PostTemplateRequest
 */
export interface PostTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRequest
     */
    'templateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRequest
     */
    'template'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRequest
     */
    'event'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTemplateRequest
     */
    'channel'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostTemplateResponse
 */
export interface PostTemplateResponse {
    /**
     * 
     * @type {PostTemplateRequest}
     * @memberof PostTemplateResponse
     */
    'request'?: PostTemplateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostTemplateResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PostThreadRequest
 */
export interface PostThreadRequest {
    /**
     * 
     * @type {string}
     * @memberof PostThreadRequest
     */
    'message'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostThreadRequest
     */
    'bookMarked'?: boolean;
}
/**
 * 
 * @export
 * @interface PostTicketAttachmentRequest
 */
export interface PostTicketAttachmentRequest {
    /**
     * 
     * @type {number}
     * @memberof PostTicketAttachmentRequest
     */
    'attachmentId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostTicketAttachmentResponse
 */
export interface PostTicketAttachmentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PostTicketAttachmentResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {PostTicketAttachmentRequest}
     * @memberof PostTicketAttachmentResponse
     */
    'request'?: PostTicketAttachmentRequest;
}
/**
 * 
 * @export
 * @interface PostTicketRequest
 */
export interface PostTicketRequest {
    /**
     * 
     * @type {number}
     * @memberof PostTicketRequest
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostTicketRequest
     */
    'priority'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostTicketRequest
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostTicketRequest
     */
    'source'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostTicketRequest
     */
    'subTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTicketRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTicketRequest
     */
    'sla'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostTicketRequest
     */
    'assigned'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostTicketRequest
     */
    'assignedGroup'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostTicketResponse
 */
export interface PostTicketResponse {
    /**
     * 
     * @type {TicketDto}
     * @memberof PostTicketResponse
     */
    'ticket'?: TicketDto;
    /**
     * 
     * @type {boolean}
     * @memberof PostTicketResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PostTicketTemplateRequest
 */
export interface PostTicketTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof PostTicketTemplateRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostTicketTemplateRequest
     */
    'content'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostTicketTemplateResponse
 */
export interface PostTicketTemplateResponse {
    /**
     * 
     * @type {PostTicketTemplateRequest}
     * @memberof PostTicketTemplateResponse
     */
    'request'?: PostTicketTemplateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostTicketTemplateResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostTicketThreadResponse
 */
export interface PostTicketThreadResponse {
    /**
     * 
     * @type {TicketThreadDto}
     * @memberof PostTicketThreadResponse
     */
    'threadDto'?: TicketThreadDto;
    /**
     * 
     * @type {boolean}
     * @memberof PostTicketThreadResponse
     */
    'succeed'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostUpdateExportJobResponse
 */
export interface PostUpdateExportJobResponse {
    /**
     * 
     * @type {PostUpdateExportJobsRequest}
     * @memberof PostUpdateExportJobResponse
     */
    'request'?: PostUpdateExportJobsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostUpdateExportJobResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {ExportJobDto}
     * @memberof PostUpdateExportJobResponse
     */
    'job'?: ExportJobDto;
}
/**
 * 
 * @export
 * @interface PostUpdateExportJobsRequest
 */
export interface PostUpdateExportJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof PostUpdateExportJobsRequest
     */
    'action'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostUploadCompletedResponse
 */
export interface PostUploadCompletedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PostUploadCompletedResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PostUserGroupRequest
 */
export interface PostUserGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof PostUserGroupRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserGroupRequest
     */
    'description'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostUserGroupResponse
 */
export interface PostUserGroupResponse {
    /**
     * 
     * @type {PostUserGroupRequest}
     * @memberof PostUserGroupResponse
     */
    'request'?: PostUserGroupRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostUserGroupResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {UserGroupDto}
     * @memberof PostUserGroupResponse
     */
    'dto'?: UserGroupDto;
}
/**
 * 
 * @export
 * @interface PostUserRequest
 */
export interface PostUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PostUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserRequest
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserRequest
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserRequest
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostUserRequest
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostUserRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostUserRequest
     */
    'password'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostUserResponse
 */
export interface PostUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PostUserResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {UserDto}
     * @memberof PostUserResponse
     */
    'created'?: UserDto;
}
/**
 * 
 * @export
 * @interface PostVerifyProcessRequest
 */
export interface PostVerifyProcessRequest {
    /**
     * 
     * @type {string}
     * @memberof PostVerifyProcessRequest
     */
    'processData'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostVerifyProcessResponse
 */
export interface PostVerifyProcessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PostVerifyProcessResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostVerifyProcessResponse
     */
    'errorMessage'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PostVerifyProcessResponse
     */
    'inputSchema'?: string;
}
/**
 * 
 * @export
 * @interface PostWatchTicketRequest
 */
export interface PostWatchTicketRequest {
    /**
     * 将被加入到工单的关注列表
     * @type {Array<number>}
     * @memberof PostWatchTicketRequest
     */
    'userIds'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PostWatchTicketResponse
 */
export interface PostWatchTicketResponse {
    /**
     * 请求的工单号
     * @type {number}
     * @memberof PostWatchTicketResponse
     */
    'ticketId'?: number;
    /**
     * 加入到工单关注列表的用户
     * @type {Array<UserDto>}
     * @memberof PostWatchTicketResponse
     */
    'watchList'?: Array<UserDto>;
    /**
     * true表示请求成功，false表示请求失败
     * @type {boolean}
     * @memberof PostWatchTicketResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PrivilegeDto
 */
export interface PrivilegeDto {
    /**
     * 
     * @type {number}
     * @memberof PrivilegeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PrivilegeDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivilegeDto
     */
    'verb'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivilegeDto
     */
    'path'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrivilegeDto
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface ProcessDto
 */
export interface ProcessDto {
    /**
     * 
     * @type {string}
     * @memberof ProcessDto
     */
    'processTemplate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDto
     */
    'desc'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessDto
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDto
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @interface ProjectAttachmentDto
 */
export interface ProjectAttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectAttachmentDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectAttachmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectAttachmentDto
     */
    'storageType'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProjectAttachmentDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof ProjectAttachmentDto
     */
    'creator'?: UserDto;
}
/**
 * 本次请求的内容
 * @export
 * @interface ProjectGetServiceItemTagRequest
 */
export interface ProjectGetServiceItemTagRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectGetServiceItemTagRequest
     */
    'serviceItemId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface ProjectGetServiceItemTagResponse
 */
export interface ProjectGetServiceItemTagResponse {
    /**
     * 
     * @type {ProjectGetServiceItemTagRequest}
     * @memberof ProjectGetServiceItemTagResponse
     */
    'request'?: ProjectGetServiceItemTagRequest;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectGetServiceItemTagResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProjectServiceTagDto>}
     * @memberof ProjectGetServiceItemTagResponse
     */
    'dtos'?: Array<ProjectServiceTagDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface ProjectGetServiceListTagResponse
 */
export interface ProjectGetServiceListTagResponse {
    /**
     * 
     * @type {ProjectServiceTagRequest}
     * @memberof ProjectGetServiceListTagResponse
     */
    'request'?: ProjectServiceTagRequest;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectGetServiceListTagResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {Array<ProjectServiceTagDto>}
     * @memberof ProjectGetServiceListTagResponse
     */
    'dtos'?: Array<ProjectServiceTagDto>;
}
/**
 * 响应请求的数据
 * @export
 * @interface ProjectServiceInstanceCheckFinishedModel
 */
export interface ProjectServiceInstanceCheckFinishedModel {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceInstanceCheckFinishedModel
     */
    'completed'?: boolean;
    /**
     * 
     * @type {Array<ProjectServiceItemMetaDataNodeModel>}
     * @memberof ProjectServiceInstanceCheckFinishedModel
     */
    'uncompletedNodeList'?: Array<ProjectServiceItemMetaDataNodeModel>;
}
/**
 * 
 * @export
 * @interface ProjectServiceInstanceDto
 */
export interface ProjectServiceInstanceDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceInstanceDto
     */
    'id'?: number;
    /**
     * 
     * @type {ProjectServiceMetaDataModel}
     * @memberof ProjectServiceInstanceDto
     */
    'project'?: ProjectServiceMetaDataModel;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceDto
     */
    'title'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceInstanceDto
     */
    'subscriber'?: UserModel;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceInstanceDto
     */
    'acceptor'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceDto
     */
    'description'?: string;
    /**
     * 
     * @type {ProjectServiceInstanceStatusDto}
     * @memberof ProjectServiceInstanceDto
     */
    'status'?: ProjectServiceInstanceStatusDto;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceInstanceDto
     */
    'createdBy'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceInstanceDto
     */
    'updatedBy'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface ProjectServiceInstanceStatusDto
 */
export interface ProjectServiceInstanceStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceInstanceStatusDto
     */
    'code'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectServiceInstanceStatusDto
     */
    'desc'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ProjectServiceInstanceTaskTreeNodeDto
 */
export interface ProjectServiceInstanceTaskTreeNodeDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'milestoneId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'parentNodeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'preNodeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {ProjectServiceItemInstanceStatusDto}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'status'?: ProjectServiceItemInstanceStatusDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceInstanceTaskTreeNodeDto
     */
    'fastestEndDate'?: string;
}
/**
 * 
 * @export
 * @interface ProjectServiceItemInstanceDto
 */
export interface ProjectServiceItemInstanceDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemInstanceDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemInstanceDto
     */
    'itemId'?: number;
    /**
     * 
     * @type {ProjectServiceInstanceDto}
     * @memberof ProjectServiceItemInstanceDto
     */
    'serviceInstance'?: ProjectServiceInstanceDto;
    /**
     * 
     * @type {ProjectServiceItemInstanceStatusDto}
     * @memberof ProjectServiceItemInstanceDto
     */
    'status'?: ProjectServiceItemInstanceStatusDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'estimatedStartTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'estimatedHigherEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'estimatedLowerEndTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemInstanceDto
     */
    'redoCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceItemInstanceDto
     */
    'executor'?: UserModel;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceItemInstanceDto
     */
    'createdBy'?: UserModel;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceItemInstanceDto
     */
    'updatedBy'?: UserModel;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProjectServiceItemInstanceDto
     */
    'parameters'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemInstanceDto
     */
    'serviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'title'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectServiceItemInstanceDto
     */
    'type'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemInstanceDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemInstanceDto
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceItemInstanceDto
     */
    'leaf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceItemInstanceDto
     */
    'serial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceItemInstanceDto
     */
    'subItem'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemInstanceDto
     */
    'orderCode'?: number;
}
/**
 * 
 * @export
 * @interface ProjectServiceItemInstanceStatusDto
 */
export interface ProjectServiceItemInstanceStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemInstanceStatusDto
     */
    'code'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectServiceItemInstanceStatusDto
     */
    'desc'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ProjectServiceItemMetaDataDto
 */
export interface ProjectServiceItemMetaDataDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'serviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'title'?: string;
    /**
     * 
     * @type {ServiceItemTypeDto}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'type'?: ServiceItemTypeDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'priority'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'orderCode'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'required'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'defaultOwner'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'defaultOwnerName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'defaultExecutor'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'defaultExecutorName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'defaultHelper'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'defaultHelperName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'timeEstimationLowerLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'timeEstimationHigherLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataDto
     */
    'createdTime'?: string;
}
/**
 * 
 * @export
 * @interface ProjectServiceItemMetaDataNodeModel
 */
export interface ProjectServiceItemMetaDataNodeModel {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'serviceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'type'?: ProjectServiceItemMetaDataNodeModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'required'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'parameters'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'defaultOwner'?: number;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'defaultOwnerUserModel'?: UserModel;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'defaultExecutor'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'defaultExecutorName'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'defaultExecutorUserModel'?: UserModel;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'defaultHelper'?: number;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'defaultHelperUserModel'?: UserModel;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'priority'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'orderCode'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'timeEstimationLowerLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'timeEstimationHigherLimit'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'leaf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'serial'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectServiceItemMetaDataNodeModel
     */
    'subItem'?: boolean;
}

export const ProjectServiceItemMetaDataNodeModelTypeEnum = {
    ServiceInstance: 'SERVICE_INSTANCE',
    MinMilestone: 'MIN_MILESTONE',
    PreRequired: 'PRE_REQUIRED',
    SerialMilestone: 'serial_milestone',
    ParallelMilestone: 'parallel_milestone',
    MaxMilestone: 'MAX_MILESTONE',
    MinTask: 'MIN_TASK',
    SerialTask: 'serial_task',
    ParallelTask: 'parallel_task',
    File: 'file',
    Ticket: 'ticket',
    Meeting: 'meeting',
    Form: 'form',
    Process: 'process',
    MaxTask: 'MAX_TASK',
    Unknown: 'UNKNOWN'
} as const;

export type ProjectServiceItemMetaDataNodeModelTypeEnum = typeof ProjectServiceItemMetaDataNodeModelTypeEnum[keyof typeof ProjectServiceItemMetaDataNodeModelTypeEnum];

/**
 * 
 * @export
 * @interface ProjectServiceItemTagForItemRequest
 */
export interface ProjectServiceItemTagForItemRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceItemTagForItemRequest
     */
    'tagId'?: number;
}
/**
 * 
 * @export
 * @interface ProjectServiceMetaDataDto
 */
export interface ProjectServiceMetaDataDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceMetaDataDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceMetaDataDto
     */
    'owner'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'ownerName'?: string;
    /**
     * 
     * @type {ProjectStatusDto}
     * @memberof ProjectServiceMetaDataDto
     */
    'status'?: ProjectStatusDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'template'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceMetaDataDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'createdByName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceMetaDataDto
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'updatedByName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface ProjectServiceMetaDataModel
 */
export interface ProjectServiceMetaDataModel {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceMetaDataModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceMetaDataModel
     */
    'owner'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'ownerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'status'?: ProjectServiceMetaDataModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'template'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceMetaDataModel
     */
    'createdBy'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'createdByName'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof ProjectServiceMetaDataModel
     */
    'updatedBy'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'updatedByName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceMetaDataModel
     */
    'updatedTime'?: string;
}

export const ProjectServiceMetaDataModelStatusEnum = {
    Draft: 'DRAFT',
    Release: 'RELEASE',
    Disable: 'DISABLE',
    Unknown: 'UNKNOWN'
} as const;

export type ProjectServiceMetaDataModelStatusEnum = typeof ProjectServiceMetaDataModelStatusEnum[keyof typeof ProjectServiceMetaDataModelStatusEnum];

/**
 * 
 * @export
 * @interface ProjectServiceStatusChangeLogDto
 */
export interface ProjectServiceStatusChangeLogDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceStatusChangeLogDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceStatusChangeLogDto
     */
    'serviceInstanceId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceStatusChangeLogDto
     */
    'itemId'?: number;
    /**
     * 
     * @type {ProjectServiceItemInstanceStatusDto}
     * @memberof ProjectServiceStatusChangeLogDto
     */
    'currentStatus'?: ProjectServiceItemInstanceStatusDto;
    /**
     * 
     * @type {ProjectServiceItemInstanceStatusDto}
     * @memberof ProjectServiceStatusChangeLogDto
     */
    'previousStatus'?: ProjectServiceItemInstanceStatusDto;
    /**
     * 
     * @type {UserDto}
     * @memberof ProjectServiceStatusChangeLogDto
     */
    'operator'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceStatusChangeLogDto
     */
    'operateTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceStatusChangeLogDto
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface ProjectServiceTagDto
 */
export interface ProjectServiceTagDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceTagDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceTagDto
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceTagDto
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProjectServiceTagModel
 */
export interface ProjectServiceTagModel {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceTagModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceTagModel
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceTagModel
     */
    'name'?: string;
}
/**
 * 本次请求的内容
 * @export
 * @interface ProjectServiceTagRequest
 */
export interface ProjectServiceTagRequest {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceTagRequest
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceTagRequest
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceTagRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProjectServiceUserParticipatingServiceDto
 */
export interface ProjectServiceUserParticipatingServiceDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectServiceUserParticipatingServiceDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectServiceUserParticipatingServiceDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface ProjectStatusDto
 */
export interface ProjectStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectStatusDto
     */
    'code'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectStatusDto
     */
    'desc'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ProjectWorkbenchDataDto
 */
export interface ProjectWorkbenchDataDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectWorkbenchDataDto
     */
    'countOfSubscribes'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectWorkbenchDataDto
     */
    'countOfApproving'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectWorkbenchDataDto
     */
    'countOfStart'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectWorkbenchDataDto
     */
    'countOfAcceptance'?: number;
    /**
     * 
     * @type {Array<ProjectServiceItemInstanceDto>}
     * @memberof ProjectWorkbenchDataDto
     */
    'taskList'?: Array<ProjectServiceItemInstanceDto>;
}
/**
 * 
 * @export
 * @interface PuTicketTemplateRequest
 */
export interface PuTicketTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof PuTicketTemplateRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PuTicketTemplateRequest
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface PutAssignTicketRequest
 */
export interface PutAssignTicketRequest {
    /**
     * 
     * @type {number}
     * @memberof PutAssignTicketRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutAssignTicketRequest
     */
    'userGroupId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutAssignTicketResponse
 */
export interface PutAssignTicketResponse {
    /**
     * 
     * @type {number}
     * @memberof PutAssignTicketResponse
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutAssignTicketResponse
     */
    'userGroupId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PutAssignTicketResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PutAssignTicketsRequest
 */
export interface PutAssignTicketsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PutAssignTicketsRequest
     */
    'ticketIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PutAssignTicketsRequest
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutAssignTicketsRequest
     */
    'userGroupId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutAssignTicketsResponse
 */
export interface PutAssignTicketsResponse {
    /**
     * 
     * @type {PutAssignTicketsRequest}
     * @memberof PutAssignTicketsResponse
     */
    'request'?: PutAssignTicketsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutAssignTicketsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutAssignTicketsResponse
     */
    'successCount'?: number;
}
/**
 * 
 * @export
 * @interface PutAutomaticProcessTicketContentRequest
 */
export interface PutAutomaticProcessTicketContentRequest {
    /**
     * 
     * @type {string}
     * @memberof PutAutomaticProcessTicketContentRequest
     */
    'data'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutAutomaticProcessTicketContentResponse
 */
export interface PutAutomaticProcessTicketContentResponse {
    /**
     * 
     * @type {PutAutomaticProcessTicketContentRequest}
     * @memberof PutAutomaticProcessTicketContentResponse
     */
    'request'?: PutAutomaticProcessTicketContentRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutAutomaticProcessTicketContentResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutAutomaticProcessTicketContentResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutAutomaticProcessTicketContentResponse
     */
    'contentId'?: number;
}
/**
 * 
 * @export
 * @interface PutAutomaticProcessTicketRequest
 */
export interface PutAutomaticProcessTicketRequest {
    /**
     * 流程ID联系管理员进行获取
     * @type {number}
     * @memberof PutAutomaticProcessTicketRequest
     */
    'processId'?: number;
    /**
     * 该文档指定流程中应该有哪些人进行处理
     * @type {string}
     * @memberof PutAutomaticProcessTicketRequest
     */
    'processInput'?: string;
    /**
     * 显示在工单中的标题，如未提供，则会使用时间戳+流程名称作为标题
     * @type {string}
     * @memberof PutAutomaticProcessTicketRequest
     */
    'subject'?: string;
    /**
     * 显示在工单中对子标题，如未提供，则会使用时间戳+流程名称作为标题
     * @type {string}
     * @memberof PutAutomaticProcessTicketRequest
     */
    'subTitle'?: string;
    /**
     * 如未指定，默认为手工创建
     * @type {string}
     * @memberof PutAutomaticProcessTicketRequest
     */
    'requestSource'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutAutomaticProcessTicketResponse
 */
export interface PutAutomaticProcessTicketResponse {
    /**
     * 
     * @type {PutAutomaticProcessTicketRequest}
     * @memberof PutAutomaticProcessTicketResponse
     */
    'request'?: PutAutomaticProcessTicketRequest;
    /**
     * 是否创建成功标志着该请求是否被工单系统接受
     * @type {boolean}
     * @memberof PutAutomaticProcessTicketResponse
     */
    'success'?: boolean;
    /**
     * 工单对唯一主键
     * @type {number}
     * @memberof PutAutomaticProcessTicketResponse
     */
    'id'?: number;
    /**
     * 主要用于前端渲染，如果对其数据不感兴趣，可以丢弃
     * @type {number}
     * @memberof PutAutomaticProcessTicketResponse
     */
    'contentId'?: number;
}
/**
 * 
 * @export
 * @interface PutChannelConfigurationRequest
 */
export interface PutChannelConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof PutChannelConfigurationRequest
     */
    'keyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutChannelConfigurationRequest
     */
    'jsonKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutChannelConfigurationRequest
     */
    'newValue'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutChannelConfigurationResponse
 */
export interface PutChannelConfigurationResponse {
    /**
     * 
     * @type {PutChannelConfigurationRequest}
     * @memberof PutChannelConfigurationResponse
     */
    'request'?: PutChannelConfigurationRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutChannelConfigurationResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PutContentSchemaRequest
 */
export interface PutContentSchemaRequest {
    /**
     * 
     * @type {string}
     * @memberof PutContentSchemaRequest
     */
    'schema'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContentSchemaRequest
     */
    'uiSchema'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContentSchemaRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContentSchemaRequest
     */
    'description'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutContentSchemaResponse
 */
export interface PutContentSchemaResponse {
    /**
     * 
     * @type {PutContentSchemaRequest}
     * @memberof PutContentSchemaResponse
     */
    'request'?: PutContentSchemaRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutContentSchemaResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PutDraftMailRequest
 */
export interface PutDraftMailRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PutDraftMailRequest
     */
    'sendSplit'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PutDraftMailRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutDraftMailRequest
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutDraftMailRequest
     */
    'body'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutDraftMailResponse
 */
export interface PutDraftMailResponse {
    /**
     * 
     * @type {PutDraftMailRequest}
     * @memberof PutDraftMailResponse
     */
    'request'?: PutDraftMailRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutDraftMailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailSendingDto}
     * @memberof PutDraftMailResponse
     */
    'sendingDto'?: MailSendingDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutEscalationActionResponse
 */
export interface PutEscalationActionResponse {
    /**
     * 
     * @type {Array<EscalationActionDto>}
     * @memberof PutEscalationActionResponse
     */
    'actions'?: Array<EscalationActionDto>;
    /**
     * 
     * @type {PutEscalationActionsRequest}
     * @memberof PutEscalationActionResponse
     */
    'request'?: PutEscalationActionsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutEscalationActionResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutEscalationActionResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface PutEscalationActionsRequest
 */
export interface PutEscalationActionsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutEscalationActionsRequest
     */
    'actions'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PutEscalationBasicInfoRequest
 */
export interface PutEscalationBasicInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof PutEscalationBasicInfoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutEscalationBasicInfoRequest
     */
    'description'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutEscalationBasicInfoResponse
 */
export interface PutEscalationBasicInfoResponse {
    /**
     * 
     * @type {PutEscalationBasicInfoRequest}
     * @memberof PutEscalationBasicInfoResponse
     */
    'request'?: PutEscalationBasicInfoRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutEscalationBasicInfoResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {EscalationRuleDtoV1}
     * @memberof PutEscalationBasicInfoResponse
     */
    'rule'?: EscalationRuleDtoV1;
    /**
     * 
     * @type {number}
     * @memberof PutEscalationBasicInfoResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface PutEscalationEnabledRequest
 */
export interface PutEscalationEnabledRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PutEscalationEnabledRequest
     */
    'enabled'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutEscalationEnabledResponse
 */
export interface PutEscalationEnabledResponse {
    /**
     * 
     * @type {PutEscalationEnabledRequest}
     * @memberof PutEscalationEnabledResponse
     */
    'request'?: PutEscalationEnabledRequest;
    /**
     * 
     * @type {number}
     * @memberof PutEscalationEnabledResponse
     */
    'id'?: number;
    /**
     * 
     * @type {EscalationRuleDtoV1}
     * @memberof PutEscalationEnabledResponse
     */
    'rule'?: EscalationRuleDtoV1;
    /**
     * 
     * @type {boolean}
     * @memberof PutEscalationEnabledResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PutEscalationFrequencyRequest
 */
export interface PutEscalationFrequencyRequest {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PutEscalationFrequencyRequest
     */
    'frequency'?: { [key: string]: object; };
}
/**
 * 响应请求的数据
 * @export
 * @interface PutEscalationFrequencyResponse
 */
export interface PutEscalationFrequencyResponse {
    /**
     * 
     * @type {PutEscalationFrequencyRequest}
     * @memberof PutEscalationFrequencyResponse
     */
    'request'?: PutEscalationFrequencyRequest;
    /**
     * 
     * @type {number}
     * @memberof PutEscalationFrequencyResponse
     */
    'id'?: number;
    /**
     * 
     * @type {EscalationRuleDtoV1}
     * @memberof PutEscalationFrequencyResponse
     */
    'rule'?: EscalationRuleDtoV1;
    /**
     * 
     * @type {boolean}
     * @memberof PutEscalationFrequencyResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PutEscalationTicketFilterRequest
 */
export interface PutEscalationTicketFilterRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PutEscalationTicketFilterRequest
     */
    'type'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PutEscalationTicketFilterRequest
     */
    'source'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PutEscalationTicketFilterRequest
     */
    'status'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PutEscalationTicketFilterRequest
     */
    'priority'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutEscalationTicketFilterResponse
 */
export interface PutEscalationTicketFilterResponse {
    /**
     * 
     * @type {EscalationRuleDtoV1}
     * @memberof PutEscalationTicketFilterResponse
     */
    'rule'?: EscalationRuleDtoV1;
    /**
     * 
     * @type {PutEscalationTicketFilterRequest}
     * @memberof PutEscalationTicketFilterResponse
     */
    'request'?: PutEscalationTicketFilterRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutEscalationTicketFilterResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutEscalationTicketFilterResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface PutEscalationTimeSheetRequest
 */
export interface PutEscalationTimeSheetRequest {
    /**
     * 
     * @type {string}
     * @memberof PutEscalationTimeSheetRequest
     */
    'timeAxis'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutEscalationTimeSheetRequest
     */
    'timeConstraint'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutEscalationTimeSheetRequest
     */
    'timeDirection'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutEscalationTimeSheetResponse
 */
export interface PutEscalationTimeSheetResponse {
    /**
     * 
     * @type {PutEscalationTimeSheetRequest}
     * @memberof PutEscalationTimeSheetResponse
     */
    'request'?: PutEscalationTimeSheetRequest;
    /**
     * 
     * @type {number}
     * @memberof PutEscalationTimeSheetResponse
     */
    'id'?: number;
    /**
     * 
     * @type {EscalationRuleDtoV1}
     * @memberof PutEscalationTimeSheetResponse
     */
    'rule'?: EscalationRuleDtoV1;
    /**
     * 
     * @type {boolean}
     * @memberof PutEscalationTimeSheetResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PutItemsRequest
 */
export interface PutItemsRequest {
    /**
     * 
     * @type {string}
     * @memberof PutItemsRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutItemsRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutItemsRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutItemsRequest
     */
    'defaultExecutor'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PutItemsRequest
     */
    'required'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutItemsRequest
     */
    'timeEstimationHigherLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutItemsRequest
     */
    'timeEstimationLowerLimit'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutItemsResponse
 */
export interface PutItemsResponse {
    /**
     * 
     * @type {PutItemsRequest}
     * @memberof PutItemsResponse
     */
    'request'?: PutItemsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutItemsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutItemsResponse
     */
    'itemId'?: number;
}
/**
 * 
 * @export
 * @interface PutMailBoxesRequest
 */
export interface PutMailBoxesRequest {
    /**
     * 
     * @type {string}
     * @memberof PutMailBoxesRequest
     */
    'credential'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PutMailBoxesRequest
     */
    'description'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof PutMailBoxesRequest
     */
    'vendorId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutMailBoxesRequest
     */
    'deployTo'?: Array<string>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutMailBoxesResponse
 */
export interface PutMailBoxesResponse {
    /**
     * 
     * @type {PutMailBoxesRequest}
     * @memberof PutMailBoxesResponse
     */
    'request'?: PutMailBoxesRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutMailBoxesResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutMailBoxesResponse
     */
    'requestMailBoxId'?: number;
    /**
     * 
     * @type {MailBoxDto}
     * @memberof PutMailBoxesResponse
     */
    'mailbox'?: MailBoxDto;
}
/**
 * 
 * @export
 * @interface PutMailContactsRequest
 */
export interface PutMailContactsRequest {
    /**
     * 
     * @type {number}
     * @memberof PutMailContactsRequest
     */
    'contactId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutMailContactsRequest
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutMailContactsRequest
     */
    'nickname'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PutMailContactsRequest
     */
    'tags'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PutMailContactsRequest
     */
    'enable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PutMailContactsRequest
     */
    'extendsInfo'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutMailContactsResponse
 */
export interface PutMailContactsResponse {
    /**
     * 
     * @type {PutMailContactsRequest}
     * @memberof PutMailContactsResponse
     */
    'request'?: PutMailContactsRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutMailContactsResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PutMailTemplateRequest
 */
export interface PutMailTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof PutMailTemplateRequest
     */
    'templateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutMailTemplateRequest
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutMailTemplateRequest
     */
    'content'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PutMailTemplateRequest
     */
    'tags'?: Array<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutMailTemplateResponse
 */
export interface PutMailTemplateResponse {
    /**
     * 
     * @type {PutMailTemplateRequest}
     * @memberof PutMailTemplateResponse
     */
    'request'?: PutMailTemplateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutMailTemplateResponse
     */
    'success'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface PutMailVendorRequest
 */
export interface PutMailVendorRequest {
    /**
     * 
     * @type {number}
     * @memberof PutMailVendorRequest
     */
    'vendorId'?: number;
    /**
     * 
     * @type {MailVendorConfigureDto}
     * @memberof PutMailVendorRequest
     */
    'configure'?: MailVendorConfigureDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutMailVendorResponse
 */
export interface PutMailVendorResponse {
    /**
     * 
     * @type {PutMailVendorRequest}
     * @memberof PutMailVendorResponse
     */
    'request'?: PutMailVendorRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutMailVendorResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {MailVendorDto}
     * @memberof PutMailVendorResponse
     */
    'vendor'?: MailVendorDto;
}
/**
 * 
 * @export
 * @interface PutNewProcessRequest
 */
export interface PutNewProcessRequest {
    /**
     * 
     * @type {string}
     * @memberof PutNewProcessRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewProcessRequest
     */
    'desc'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutNewProcessRequest
     */
    'processTemplate'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutNewProcessResponse
 */
export interface PutNewProcessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PutNewProcessResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutNewProcessResponse
     */
    'id'?: number;
    /**
     * 
     * @type {PutNewProcessRequest}
     * @memberof PutNewProcessResponse
     */
    'request'?: PutNewProcessRequest;
}
/**
 * 
 * @export
 * @interface PutNewUploadRequest
 */
export interface PutNewUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof PutNewUploadRequest
     */
    'fileName'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutNewUploadResponse
 */
export interface PutNewUploadResponse {
    /**
     * 
     * @type {PutNewUploadRequest}
     * @memberof PutNewUploadResponse
     */
    'request'?: PutNewUploadRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutNewUploadResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {AttachmentDto}
     * @memberof PutNewUploadResponse
     */
    'attachmentDto'?: AttachmentDto;
}
/**
 * 
 * @export
 * @interface PutNotificationEventRequest
 */
export interface PutNotificationEventRequest {
    /**
     * 
     * @type {string}
     * @memberof PutNotificationEventRequest
     */
    'descJson'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutNotificationEventResponse
 */
export interface PutNotificationEventResponse {
    /**
     * 
     * @type {PutNotificationEventRequest}
     * @memberof PutNotificationEventResponse
     */
    'request'?: PutNotificationEventRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutNotificationEventResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PutNotificationTemplateRequest
 */
export interface PutNotificationTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof PutNotificationTemplateRequest
     */
    'template'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutNotificationTemplateResponse
 */
export interface PutNotificationTemplateResponse {
    /**
     * 
     * @type {PutNotificationTemplateRequest}
     * @memberof PutNotificationTemplateResponse
     */
    'request'?: PutNotificationTemplateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutNotificationTemplateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PutNotificationTemplateResponse
     */
    'templateName'?: string;
}
/**
 * 
 * @export
 * @interface PutProjectServiceMetaRequest
 */
export interface PutProjectServiceMetaRequest {
    /**
     * 
     * @type {string}
     * @memberof PutProjectServiceMetaRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutProjectServiceMetaRequest
     */
    'owner'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutProjectServiceMetaResponse
 */
export interface PutProjectServiceMetaResponse {
    /**
     * 
     * @type {PutProjectServiceMetaRequest}
     * @memberof PutProjectServiceMetaResponse
     */
    'request'?: PutProjectServiceMetaRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutProjectServiceMetaResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PutReflexResolversRequest
 */
export interface PutReflexResolversRequest {
    /**
     * 
     * @type {string}
     * @memberof PutReflexResolversRequest
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface PutReflexStrategiesRequest
 */
export interface PutReflexStrategiesRequest {
    /**
     * 
     * @type {string}
     * @memberof PutReflexStrategiesRequest
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @interface PutRemoteInstanceEnrollRequest
 */
export interface PutRemoteInstanceEnrollRequest {
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollRequest
     */
    'publicIp'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PutRemoteInstanceEnrollRequest
     */
    'tags'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollRequest
     */
    'clientPublicKey'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutRemoteInstanceEnrollResponse
 */
export interface PutRemoteInstanceEnrollResponse {
    /**
     * 
     * @type {PutRemoteInstanceEnrollRequest}
     * @memberof PutRemoteInstanceEnrollResponse
     */
    'request'?: PutRemoteInstanceEnrollRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutRemoteInstanceEnrollResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {RemoteInstanceDto}
     * @memberof PutRemoteInstanceEnrollResponse
     */
    'enrollInstance'?: RemoteInstanceDto;
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollResponse
     */
    'requestIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollResponse
     */
    'connectorType'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollResponse
     */
    'configure'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollResponse
     */
    'signature'?: string;
}
/**
 * 本次请求的内容
 * @export
 * @interface PutRemoteInstanceEnrollmentStatusRequest
 */
export interface PutRemoteInstanceEnrollmentStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof PutRemoteInstanceEnrollmentStatusRequest
     */
    'requestInstanceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutRemoteInstanceEnrollmentStatusRequest
     */
    'requestStatus'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutRemoteInstanceEnrollmentStatusResponse
 */
export interface PutRemoteInstanceEnrollmentStatusResponse {
    /**
     * 
     * @type {PutRemoteInstanceEnrollmentStatusRequest}
     * @memberof PutRemoteInstanceEnrollmentStatusResponse
     */
    'request'?: PutRemoteInstanceEnrollmentStatusRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutRemoteInstanceEnrollmentStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {RemoteInstanceDto}
     * @memberof PutRemoteInstanceEnrollmentStatusResponse
     */
    'updatedInstance'?: RemoteInstanceDto;
}
/**
 * 
 * @export
 * @interface PutResetChannelConfigurationRequest
 */
export interface PutResetChannelConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof PutResetChannelConfigurationRequest
     */
    'keyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutResetChannelConfigurationRequest
     */
    'jsonKey'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutResetChannelConfigurationResponse
 */
export interface PutResetChannelConfigurationResponse {
    /**
     * 
     * @type {PutResetChannelConfigurationRequest}
     * @memberof PutResetChannelConfigurationResponse
     */
    'request'?: PutResetChannelConfigurationRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutResetChannelConfigurationResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutResetSystemSettingsResponse
 */
export interface PutResetSystemSettingsResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof PutResetSystemSettingsResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof PutResetSystemSettingsResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PutResetSystemSettingsResponse
     */
    'settingKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutResetSystemSettingsResponse
     */
    'defaultValue'?: string;
}
/**
 * 
 * @export
 * @interface PutSystemSettingRequest
 */
export interface PutSystemSettingRequest {
    /**
     * 
     * @type {string}
     * @memberof PutSystemSettingRequest
     */
    'changedKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutSystemSettingRequest
     */
    'changedValue'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutSystemSettingResponse
 */
export interface PutSystemSettingResponse {
    /**
     * 
     * @type {PutSystemSettingRequest}
     * @memberof PutSystemSettingResponse
     */
    'request'?: PutSystemSettingRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutSystemSettingResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface PutTagToTicketRequest
 */
export interface PutTagToTicketRequest {
    /**
     * 
     * @type {number}
     * @memberof PutTagToTicketRequest
     */
    'tagId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTagToTicketResponse
 */
export interface PutTagToTicketResponse {
    /**
     * 
     * @type {number}
     * @memberof PutTagToTicketResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutTagToTicketResponse
     */
    'tagId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PutTagToTicketResponse
     */
    'succeed'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface PutTaskConfigureRequest
 */
export interface PutTaskConfigureRequest {
    /**
     * 
     * @type {number}
     * @memberof PutTaskConfigureRequest
     */
    'taskId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutTaskConfigureRequest
     */
    'configure'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTaskConfigureResponse
 */
export interface PutTaskConfigureResponse {
    /**
     * 
     * @type {PutTaskConfigureRequest}
     * @memberof PutTaskConfigureResponse
     */
    'request'?: PutTaskConfigureRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutTaskConfigureResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {RemoteTaskDto}
     * @memberof PutTaskConfigureResponse
     */
    'task'?: RemoteTaskDto;
}
/**
 * 
 * @export
 * @interface PutTaskStatusRequest
 */
export interface PutTaskStatusRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PutTaskStatusRequest
     */
    'enabled'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTaskStatusResponse
 */
export interface PutTaskStatusResponse {
    /**
     * 
     * @type {PutTaskStatusRequest}
     * @memberof PutTaskStatusResponse
     */
    'request'?: PutTaskStatusRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutTaskStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {RemoteTaskDto}
     * @memberof PutTaskStatusResponse
     */
    'task'?: RemoteTaskDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTemplateUsingStateResponse
 */
export interface PutTemplateUsingStateResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof PutTemplateUsingStateResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof PutTemplateUsingStateResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PutTemplateUsingStateResponse
     */
    'templateName'?: string;
}
/**
 * 
 * @export
 * @interface PutTicketBasicInfoRequest
 */
export interface PutTicketBasicInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof PutTicketBasicInfoRequest
     */
    'subject'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutTicketBasicInfoRequest
     */
    'subTitle'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTicketBasicInfoResponse
 */
export interface PutTicketBasicInfoResponse {
    /**
     * 
     * @type {PutTicketBasicInfoRequest}
     * @memberof PutTicketBasicInfoResponse
     */
    'request'?: PutTicketBasicInfoRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutTicketBasicInfoResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutTicketBasicInfoResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface PutTicketContentRequest
 */
export interface PutTicketContentRequest {
    /**
     * 
     * @type {string}
     * @memberof PutTicketContentRequest
     */
    'data'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTicketContentResponse
 */
export interface PutTicketContentResponse {
    /**
     * 
     * @type {number}
     * @memberof PutTicketContentResponse
     */
    'ticketId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutTicketContentResponse
     */
    'contentId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PutTicketContentResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface PutTicketCoreStateRequest
 */
export interface PutTicketCoreStateRequest {
    /**
     * 
     * @type {number}
     * @memberof PutTicketCoreStateRequest
     */
    'priorityId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutTicketCoreStateRequest
     */
    'statusId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutTicketCoreStateRequest
     */
    'source'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutTicketCoreStateRequest
     */
    'type'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTicketCoreStateResponse
 */
export interface PutTicketCoreStateResponse {
    /**
     * 
     * @type {PutTicketCoreStateRequest}
     * @memberof PutTicketCoreStateResponse
     */
    'request'?: PutTicketCoreStateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutTicketCoreStateResponse
     */
    'succeed'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface PutTicketTemplateDisabledRequest
 */
export interface PutTicketTemplateDisabledRequest {
    /**
     * 
     * @type {number}
     * @memberof PutTicketTemplateDisabledRequest
     */
    'templateId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTicketTemplateDisabledResponse
 */
export interface PutTicketTemplateDisabledResponse {
    /**
     * 
     * @type {PutTicketTemplateDisabledRequest}
     * @memberof PutTicketTemplateDisabledResponse
     */
    'request'?: PutTicketTemplateDisabledRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutTicketTemplateDisabledResponse
     */
    'success'?: boolean;
}
/**
 * 本次请求的内容
 * @export
 * @interface PutTicketTemplateEnabledRequest
 */
export interface PutTicketTemplateEnabledRequest {
    /**
     * 
     * @type {number}
     * @memberof PutTicketTemplateEnabledRequest
     */
    'templateId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTicketTemplateEnabledResponse
 */
export interface PutTicketTemplateEnabledResponse {
    /**
     * 
     * @type {PutTicketTemplateEnabledRequest}
     * @memberof PutTicketTemplateEnabledResponse
     */
    'request'?: PutTicketTemplateEnabledRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutTicketTemplateEnabledResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutTicketTemplateResponse
 */
export interface PutTicketTemplateResponse {
    /**
     * 
     * @type {PuTicketTemplateRequest}
     * @memberof PutTicketTemplateResponse
     */
    'request'?: PuTicketTemplateRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PutTicketTemplateResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutUserApiAccessResponse
 */
export interface PutUserApiAccessResponse {
    /**
     * 
     * @type {string}
     * @memberof PutUserApiAccessResponse
     */
    'accessKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserApiAccessResponse
     */
    'accessSecret'?: string;
}
/**
 * 
 * @export
 * @interface PutUserToUserGroupRequest
 */
export interface PutUserToUserGroupRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PutUserToUserGroupRequest
     */
    'userToAdd'?: Array<number>;
    /**
     * 
     * @type {Set<number>}
     * @memberof PutUserToUserGroupRequest
     */
    'userToInSet'?: Set<number>;
}
/**
 * 响应请求的数据
 * @export
 * @interface PutUserToUserGroupResponse
 */
export interface PutUserToUserGroupResponse {
    /**
     * 
     * @type {number}
     * @memberof PutUserToUserGroupResponse
     */
    'id'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PutUserToUserGroupResponse
     */
    'userIds'?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PutUserToUserGroupResponse
     */
    'added'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserToUserGroupResponse
     */
    'succeed'?: boolean;
}
/**
 * 
 * @export
 * @interface ReflexStrategyDto
 */
export interface ReflexStrategyDto {
    /**
     * 
     * @type {string}
     * @memberof ReflexStrategyDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReflexStrategyDto
     */
    'description'?: string;
    /**
     * 
     * @type {object}
     * @memberof ReflexStrategyDto
     */
    'strategy'?: object;
}
/**
 * 
 * @export
 * @interface RemoteInstanceDto
 */
export interface RemoteInstanceDto {
    /**
     * 
     * @type {number}
     * @memberof RemoteInstanceDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RemoteInstanceDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteInstanceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteInstanceDto
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteInstanceDto
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteInstanceDto
     */
    'publicIp'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteInstanceDto
     */
    'accessIp'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RemoteInstanceDto
     */
    'tags'?: { [key: string]: string; };
    /**
     * 
     * @type {NetworkStatusDto}
     * @memberof RemoteInstanceDto
     */
    'networkStatusDto'?: NetworkStatusDto;
}
/**
 * 
 * @export
 * @interface RemoteTaskDto
 */
export interface RemoteTaskDto {
    /**
     * 
     * @type {number}
     * @memberof RemoteTaskDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RemoteTaskDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteTaskDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof RemoteTaskDto
     */
    'identifier'?: number;
    /**
     * 
     * @type {string}
     * @memberof RemoteTaskDto
     */
    'configure'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RemoteTaskDto
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {UserDto}
     * @memberof RemoteTaskDto
     */
    'creator'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof RemoteTaskDto
     */
    'updater'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof RemoteTaskDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof RemoteTaskDto
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface RemoveDeliveryMaterialRequest
 */
export interface RemoveDeliveryMaterialRequest {
    /**
     * 
     * @type {number}
     * @memberof RemoveDeliveryMaterialRequest
     */
    'attachmentId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface RemoveDeliveryMaterialResponse
 */
export interface RemoveDeliveryMaterialResponse {
    /**
     * 
     * @type {RemoveDeliveryMaterialRequest}
     * @memberof RemoveDeliveryMaterialResponse
     */
    'request'?: RemoveDeliveryMaterialRequest;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveDeliveryMaterialResponse
     */
    'success'?: boolean;
}
/**
 * 响应请求的数据
 * @export
 * @interface RemoveProjectServiceDraftResponse
 */
export interface RemoveProjectServiceDraftResponse {
    /**
     * 本次请求的内容
     * @type {object}
     * @memberof RemoveProjectServiceDraftResponse
     */
    'request'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof RemoveProjectServiceDraftResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RemoveProjectServiceDraftResponse
     */
    'serviceInstanceId'?: number;
}
/**
 * 响应请求的数据
 * @export
 * @interface SearchTicketByNameResponse
 */
export interface SearchTicketByNameResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchTicketByNameResponse
     */
    'ticketName'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchTicketByNameResponse
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchTicketByNameResponse
     */
    'subtitle'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchTicketByNameResponse
     */
    'status'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTicketByNameResponse
     */
    'priority'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTicketByNameResponse
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTicketByNameResponse
     */
    'source'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTicketByNameResponse
     */
    'assigned'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchTicketByNameResponse
     */
    'tag'?: number;
    /**
     * 
     * @type {Array<TicketDto>}
     * @memberof SearchTicketByNameResponse
     */
    'recommendTickets'?: Array<TicketDto>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTicketByNameResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceInstanceChangeStatusRequest
 */
export interface ServiceInstanceChangeStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof ServiceInstanceChangeStatusRequest
     */
    'newStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceInstanceChangeStatusRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceInstanceChangeStatusRequest
     */
    'extendsParameters'?: { [key: string]: string; };
}
/**
 * 响应请求的数据
 * @export
 * @interface ServiceInstanceChangeStatusResponse
 */
export interface ServiceInstanceChangeStatusResponse {
    /**
     * 
     * @type {ServiceInstanceChangeStatusRequest}
     * @memberof ServiceInstanceChangeStatusResponse
     */
    'request'?: ServiceInstanceChangeStatusRequest;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceInstanceChangeStatusResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface ServiceItemChangeStatusRequest
 */
export interface ServiceItemChangeStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof ServiceItemChangeStatusRequest
     */
    'newStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceItemChangeStatusRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceItemChangeStatusRequest
     */
    'extendsParameters'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface ServiceItemTypeDto
 */
export interface ServiceItemTypeDto {
    /**
     * 
     * @type {number}
     * @memberof ServiceItemTypeDto
     */
    'code'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ServiceItemTypeDto
     */
    'desc'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface StatisticCloseTicketRequest
 */
export interface StatisticCloseTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof StatisticCloseTicketRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof StatisticCloseTicketRequest
     */
    'endDate'?: string;
}
/**
 * 响应请求的数据
 * @export
 * @interface StatisticCloseTicketResponse
 */
export interface StatisticCloseTicketResponse {
    /**
     * 
     * @type {StatisticCloseTicketRequest}
     * @memberof StatisticCloseTicketResponse
     */
    'request'?: StatisticCloseTicketRequest;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticCloseTicketResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {CloseTicketsStatisticsResultDto}
     * @memberof StatisticCloseTicketResponse
     */
    'result'?: CloseTicketsStatisticsResultDto;
}
/**
 * 响应请求的数据
 * @export
 * @interface StatisticSingleCloseTicketResponse
 */
export interface StatisticSingleCloseTicketResponse {
    /**
     * 
     * @type {TicketDto}
     * @memberof StatisticSingleCloseTicketResponse
     */
    'ticketOnStatistics'?: TicketDto;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticSingleCloseTicketResponse
     */
    'succeed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StatisticSingleCloseTicketResponse
     */
    'updated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StatisticSingleCloseTicketResponse
     */
    'finalCloseTime'?: string;
}
/**
 * 
 * @export
 * @interface StatisticsDataDto
 */
export interface StatisticsDataDto {
    /**
     * 
     * @type {string}
     * @memberof StatisticsDataDto
     */
    'dataPoint'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StatisticsDataDto
     */
    'description'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof StatisticsDataDto
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatisticsDataDto
     */
    'dataDate'?: string;
}
/**
 * 
 * @export
 * @interface TagDto
 */
export interface TagDto {
    /**
     * 
     * @type {number}
     * @memberof TagDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface TicketAttachmentDto
 */
export interface TicketAttachmentDto {
    /**
     * 
     * @type {number}
     * @memberof TicketAttachmentDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TicketAttachmentDto
     */
    'storageType'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof TicketAttachmentDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketAttachmentDto
     */
    'creator'?: UserDto;
}
/**
 * 
 * @export
 * @interface TicketContentDto
 */
export interface TicketContentDto {
    /**
     * 
     * @type {number}
     * @memberof TicketContentDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketContentDto
     */
    'content'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketContentDto
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketContentDto
     */
    'created'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketContentDto
     */
    'updatedBy'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketContentDto
     */
    'createdBy'?: UserDto;
}
/**
 * 
 * @export
 * @interface TicketDto
 */
export interface TicketDto {
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    'id'?: number;
    /**
     * 
     * @type {TicketStatusDto}
     * @memberof TicketDto
     */
    'status'?: TicketStatusDto;
    /**
     * 
     * @type {TicketPriorityDto}
     * @memberof TicketDto
     */
    'priority'?: TicketPriorityDto;
    /**
     * 
     * @type {TicketTypeDto}
     * @memberof TicketDto
     */
    'type'?: TicketTypeDto;
    /**
     * 
     * @type {TicketSourceDto}
     * @memberof TicketDto
     */
    'source'?: TicketSourceDto;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'subTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'subject'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketDto
     */
    'contentId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'sla'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketDto
     */
    'assigned'?: UserDto;
    /**
     * 
     * @type {UserGroupDto}
     * @memberof TicketDto
     */
    'assignedGroup'?: UserGroupDto;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketDto
     */
    'updatedBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof TicketDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof TicketDto
     */
    'watchList'?: Array<UserDto>;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof TicketDto
     */
    'tags'?: Array<TagDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TicketDto
     */
    'private'?: boolean;
}
/**
 * 
 * @export
 * @interface TicketPriorityDto
 */
export interface TicketPriorityDto {
    /**
     * 
     * @type {string}
     * @memberof TicketPriorityDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TicketPriorityDto
     */
    'lang'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof TicketPriorityDto
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TicketSourceDto
 */
export interface TicketSourceDto {
    /**
     * 
     * @type {string}
     * @memberof TicketSourceDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TicketSourceDto
     */
    'lang'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof TicketSourceDto
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TicketStatusDto
 */
export interface TicketStatusDto {
    /**
     * 
     * @type {string}
     * @memberof TicketStatusDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TicketStatusDto
     */
    'description'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof TicketStatusDto
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface TicketTemplateDto
 */
export interface TicketTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof TicketTemplateDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketTemplateDto
     */
    'title'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketTemplateDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketTemplateDto
     */
    'updatedBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof TicketTemplateDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketTemplateDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketTemplateDto
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TicketTemplateDto
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface TicketThreadDto
 */
export interface TicketThreadDto {
    /**
     * 
     * @type {number}
     * @memberof TicketThreadDto
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketThreadDto
     */
    'ticketId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TicketThreadDto
     */
    'threadType'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketThreadDto
     */
    'message'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketThreadDto
     */
    'updatedBy'?: UserDto;
    /**
     * 
     * @type {UserDto}
     * @memberof TicketThreadDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof TicketThreadDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketThreadDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TicketThreadDto
     */
    'bookMarked'?: boolean;
}
/**
 * 
 * @export
 * @interface TicketThreadTimeDto
 */
export interface TicketThreadTimeDto {
    /**
     * 
     * @type {number}
     * @memberof TicketThreadTimeDto
     */
    'ticketId'?: number;
    /**
     * 
     * @type {UserModel}
     * @memberof TicketThreadTimeDto
     */
    'user'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof TicketThreadTimeDto
     */
    'startedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketThreadTimeDto
     */
    'endAt'?: string;
}
/**
 * 
 * @export
 * @interface TicketTypeDto
 */
export interface TicketTypeDto {
    /**
     * 
     * @type {string}
     * @memberof TicketTypeDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TicketTypeDto
     */
    'description'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof TicketTypeDto
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 用户id
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 用户名
     * @type {string}
     * @memberof UserDto
     */
    'name'?: string;
    /**
     * 用户来源
     * @type {string}
     * @memberof UserDto
     */
    'source'?: string;
    /**
     * 用户全名
     * @type {string}
     * @memberof UserDto
     */
    'fullName'?: string;
    /**
     * 用户所属组织
     * @type {string}
     * @memberof UserDto
     */
    'organization'?: string;
    /**
     * 用户手机号
     * @type {string}
     * @memberof UserDto
     */
    'phone'?: string;
    /**
     * 用户邮箱
     * @type {string}
     * @memberof UserDto
     */
    'email'?: string;
    /**
     * 用户是否激活
     * @type {boolean}
     * @memberof UserDto
     */
    'active'?: boolean;
    /**
     * 用户创建时间
     * @type {string}
     * @memberof UserDto
     */
    'createdTime'?: string;
    /**
     * 用户更新时间
     * @type {string}
     * @memberof UserDto
     */
    'updatedTime'?: string;
}
/**
 * 
 * @export
 * @interface UserGroupDto
 */
export interface UserGroupDto {
    /**
     * 
     * @type {number}
     * @memberof UserGroupDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserGroupDto
     */
    'userCount'?: number;
}
/**
 * 
 * @export
 * @interface UserGroupModel
 */
export interface UserGroupModel {
    /**
     * 
     * @type {number}
     * @memberof UserGroupModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserGroupModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupModel
     */
    'description'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof UserGroupModel
     */
    'createdBy'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof UserGroupModel
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof UserGroupModel
     */
    'updatedBy'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof UserGroupModel
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserGroupModel
     */
    'usersCount'?: number;
    /**
     * 
     * @type {Array<UserModel>}
     * @memberof UserGroupModel
     */
    'users'?: Array<UserModel>;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {number}
     * @memberof UserModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'fullName'?: string;
    /**
     * 
     * @type {UserProfileModel}
     * @memberof UserModel
     */
    'userProfile'?: UserProfileModel;
    /**
     * 
     * @type {UserSourceModel}
     * @memberof UserModel
     */
    'source'?: UserSourceModel;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'organization'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'email'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    'active'?: boolean;
    /**
     * 
     * @type {UserModel}
     * @memberof UserModel
     */
    'createdBy'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserModel}
     * @memberof UserModel
     */
    'updatedBy'?: UserModel;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {Array<UserGroupModel>}
     * @memberof UserModel
     */
    'groups'?: Array<UserGroupModel>;
}
/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {number}
     * @memberof UserProfileDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserProfileDto
     */
    'connector'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserProfileDto
     */
    'other'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface UserProfileModel
 */
export interface UserProfileModel {
    /**
     * 
     * @type {number}
     * @memberof UserProfileModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    'avatar'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserProfileModel
     */
    'connector'?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserProfileModel
     */
    'other'?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    'createdTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileModel
     */
    'configuration'?: string;
}
/**
 * 
 * @export
 * @interface UserSourceDto
 */
export interface UserSourceDto {
    /**
     * 
     * @type {string}
     * @memberof UserSourceDto
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserSourceDto
     */
    'lang'?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof UserSourceDto
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface UserSourceModel
 */
export interface UserSourceModel {
    /**
     * 
     * @type {number}
     * @memberof UserSourceModel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSourceModel
     */
    'name'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserSourceModel
     */
    'description'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface WorkbenchMailBoxContactDto
 */
export interface WorkbenchMailBoxContactDto {
    /**
     * 
     * @type {number}
     * @memberof WorkbenchMailBoxContactDto
     */
    'id'?: number;
    /**
     * 
     * @type {WorkbenchMailContactDto}
     * @memberof WorkbenchMailBoxContactDto
     */
    'emailContact'?: WorkbenchMailContactDto;
    /**
     * 
     * @type {MailBoxDto}
     * @memberof WorkbenchMailBoxContactDto
     */
    'mailBox'?: MailBoxDto;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchMailBoxContactDto
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchMailBoxContactDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof WorkbenchMailBoxContactDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchMailBoxContactDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof WorkbenchMailBoxContactDto
     */
    'updatedBy'?: UserDto;
}
/**
 * 
 * @export
 * @interface WorkbenchMailContactDto
 */
export interface WorkbenchMailContactDto {
    /**
     * 
     * @type {number}
     * @memberof WorkbenchMailContactDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchMailContactDto
     */
    'emailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchMailContactDto
     */
    'nickname'?: string;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof WorkbenchMailContactDto
     */
    'tag'?: Array<TagDto>;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchMailContactDto
     */
    'extendsInfo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkbenchMailContactDto
     */
    'enable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchMailContactDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof WorkbenchMailContactDto
     */
    'createdBy'?: UserDto;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchMailContactDto
     */
    'updatedTime'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof WorkbenchMailContactDto
     */
    'updatedBy'?: UserDto;
}
/**
 * 
 * @export
 * @interface WorkbenchVelocityTemplateDto
 */
export interface WorkbenchVelocityTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'event'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'channel'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'inUse'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'templateContent'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'createdBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'createdTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'updatedBy'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkbenchVelocityTemplateDto
     */
    'updatedTime'?: string;
}

/**
 * AttachmentApiApi - axios parameter creator
 * @export
 */
export const AttachmentApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAttachment', 'id', id)
            const localVarPath = `/api/v1/attachments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteUpload: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postCompleteUpload', 'id', id)
            const localVarPath = `/api/v1/attachments/{id}/completed`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutNewUploadRequest} putNewUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNewUploadRequest: async (putNewUploadRequest: PutNewUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putNewUploadRequest' is not null or undefined
            assertParamExists('putNewUploadRequest', 'putNewUploadRequest', putNewUploadRequest)
            const localVarPath = `/api/v1/attachments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putNewUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttachmentApiApi - functional programming interface
 * @export
 */
export const AttachmentApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttachmentApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachment(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetDownloadAttachmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachment(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCompleteUpload(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostUploadCompletedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCompleteUpload(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutNewUploadRequest} putNewUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNewUploadRequest(putNewUploadRequest: PutNewUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutNewUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNewUploadRequest(putNewUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttachmentApiApi - factory interface
 * @export
 */
export const AttachmentApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttachmentApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachment(id: number, options?: any): AxiosPromise<ApiResponseGetDownloadAttachmentResponse> {
            return localVarFp.getAttachment(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCompleteUpload(id: number, options?: any): AxiosPromise<ApiResponsePostUploadCompletedResponse> {
            return localVarFp.postCompleteUpload(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutNewUploadRequest} putNewUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNewUploadRequest(putNewUploadRequest: PutNewUploadRequest, options?: any): AxiosPromise<ApiResponsePutNewUploadResponse> {
            return localVarFp.putNewUploadRequest(putNewUploadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttachmentApiApi - object-oriented interface
 * @export
 * @class AttachmentApiApi
 * @extends {BaseAPI}
 */
export class AttachmentApiApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApiApi
     */
    public getAttachment(id: number, options?: AxiosRequestConfig) {
        return AttachmentApiApiFp(this.configuration).getAttachment(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApiApi
     */
    public postCompleteUpload(id: number, options?: AxiosRequestConfig) {
        return AttachmentApiApiFp(this.configuration).postCompleteUpload(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutNewUploadRequest} putNewUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttachmentApiApi
     */
    public putNewUploadRequest(putNewUploadRequest: PutNewUploadRequest, options?: AxiosRequestConfig) {
        return AttachmentApiApiFp(this.configuration).putNewUploadRequest(putNewUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} accessKey 
         * @param {ChallengeProfileDto} challengeProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorization: async (accessKey: string, challengeProfileDto: ChallengeProfileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
            assertParamExists('authorization', 'accessKey', accessKey)
            // verify required parameter 'challengeProfileDto' is not null or undefined
            assertParamExists('authorization', 'challengeProfileDto', challengeProfileDto)
            const localVarPath = `/auth/authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(challengeProfileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challenge: async (accessKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
            assertParamExists('challenge', 'accessKey', accessKey)
            const localVarPath = `/auth/challenge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accessKey !== undefined) {
                localVarQueryParameter['accessKey'] = accessKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} accessKey 
         * @param {ChallengeProfileDto} challengeProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorization(accessKey: string, challengeProfileDto: ChallengeProfileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizationResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authorization(accessKey, challengeProfileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async challenge(accessKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeCodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.challenge(accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} accessKey 
         * @param {ChallengeProfileDto} challengeProfileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorization(accessKey: string, challengeProfileDto: ChallengeProfileDto, options?: any): AxiosPromise<AuthorizationResultDto> {
            return localVarFp.authorization(accessKey, challengeProfileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        challenge(accessKey: string, options?: any): AxiosPromise<ChallengeCodeDto> {
            return localVarFp.challenge(accessKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} accessKey 
     * @param {ChallengeProfileDto} challengeProfileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorization(accessKey: string, challengeProfileDto: ChallengeProfileDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authorization(accessKey, challengeProfileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} accessKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public challenge(accessKey: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).challenge(accessKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClusterApiApi - axios parameter creator
 * @export
 */
export const ClusterApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTasks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cluster/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceCombinedTasks: async (id: number, type: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInstanceCombinedTasks', 'id', id)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getInstanceCombinedTasks', 'type', type)
            const localVarPath = `/api/v1/cluster/instances/{id}/tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteInstanceConnectiveStatus: async (instanceIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceIdentifier' is not null or undefined
            assertParamExists('getRemoteInstanceConnectiveStatus', 'instanceIdentifier', instanceIdentifier)
            const localVarPath = `/api/v1/cluster/instances/{instance-identifier}/connectiveStatus`
                .replace(`{${"instance-identifier"}}`, encodeURIComponent(String(instanceIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteInstances: async (status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/cluster/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {boolean} [includedAllocatedInstance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask: async (taskId: number, includedAllocatedInstance?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getTask', 'taskId', taskId)
            const localVarPath = `/api/v1/cluster/tasks/{task-id}`
                .replace(`{${"task-id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includedAllocatedInstance !== undefined) {
                localVarQueryParameter['included-allocated-instance'] = includedAllocatedInstance;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PostAllocateTaskRequest} postAllocateTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateTask: async (id: number, postAllocateTaskRequest: PostAllocateTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postAllocateTask', 'id', id)
            // verify required parameter 'postAllocateTaskRequest' is not null or undefined
            assertParamExists('postAllocateTask', 'postAllocateTaskRequest', postAllocateTaskRequest)
            const localVarPath = `/api/v1/cluster/instances/{id}/tasks`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAllocateTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostTaskRequest} postTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTasks: async (postTaskRequest: PostTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTaskRequest' is not null or undefined
            assertParamExists('postTasks', 'postTaskRequest', postTaskRequest)
            const localVarPath = `/api/v1/cluster/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} identifier 
         * @param {PutRemoteInstanceEnrollRequest} putRemoteInstanceEnrollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRemoteInstanceEnroll: async (identifier: string, putRemoteInstanceEnrollRequest: PutRemoteInstanceEnrollRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('putRemoteInstanceEnroll', 'identifier', identifier)
            // verify required parameter 'putRemoteInstanceEnrollRequest' is not null or undefined
            assertParamExists('putRemoteInstanceEnroll', 'putRemoteInstanceEnrollRequest', putRemoteInstanceEnrollRequest)
            const localVarPath = `/api/v1/cluster/instances/{identifier}`
                .replace(`{${"identifier"}}`, encodeURIComponent(String(identifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putRemoteInstanceEnrollRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRemoteInstanceEnrollmentStatus: async (id: number, status: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putRemoteInstanceEnrollmentStatus', 'id', id)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('putRemoteInstanceEnrollmentStatus', 'status', status)
            const localVarPath = `/api/v1/cluster/instances/{id}/status/{status}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaskConfigure: async (taskId: number, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('putTaskConfigure', 'taskId', taskId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putTaskConfigure', 'body', body)
            const localVarPath = `/api/v1/cluster/tasks/{task-id}`
                .replace(`{${"task-id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {PutTaskStatusRequest} putTaskStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaskStatus: async (taskId: number, putTaskStatusRequest: PutTaskStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('putTaskStatus', 'taskId', taskId)
            // verify required parameter 'putTaskStatusRequest' is not null or undefined
            assertParamExists('putTaskStatus', 'putTaskStatusRequest', putTaskStatusRequest)
            const localVarPath = `/api/v1/cluster/tasks/{task-id}/status`
                .replace(`{${"task-id"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putTaskStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClusterApiApi - functional programming interface
 * @export
 */
export const ClusterApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClusterApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTasks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetAllTasksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTasks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstanceCombinedTasks(id: number, type: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetInstancesTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstanceCombinedTasks(id, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemoteInstanceConnectiveStatus(instanceIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetRemoteInstanceConnectiveStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRemoteInstanceConnectiveStatus(instanceIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRemoteInstances(status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetRemoteInstancesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRemoteInstances(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {boolean} [includedAllocatedInstance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTask(taskId: number, includedAllocatedInstance?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTask(taskId, includedAllocatedInstance, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PostAllocateTaskRequest} postAllocateTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAllocateTask(id: number, postAllocateTaskRequest: PostAllocateTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostAllocateTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAllocateTask(id, postAllocateTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostTaskRequest} postTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTasks(postTaskRequest: PostTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostTaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTasks(postTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} identifier 
         * @param {PutRemoteInstanceEnrollRequest} putRemoteInstanceEnrollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRemoteInstanceEnroll(identifier: string, putRemoteInstanceEnrollRequest: PutRemoteInstanceEnrollRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutRemoteInstanceEnrollResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRemoteInstanceEnroll(identifier, putRemoteInstanceEnrollRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRemoteInstanceEnrollmentStatus(id: number, status: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutRemoteInstanceEnrollmentStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRemoteInstanceEnrollmentStatus(id, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTaskConfigure(taskId: number, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTaskConfigureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTaskConfigure(taskId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {PutTaskStatusRequest} putTaskStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTaskStatus(taskId: number, putTaskStatusRequest: PutTaskStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTaskStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTaskStatus(taskId, putTaskStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClusterApiApi - factory interface
 * @export
 */
export const ClusterApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClusterApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTasks(options?: any): AxiosPromise<ApiResponseGetAllTasksResponse> {
            return localVarFp.getAllTasks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceCombinedTasks(id: number, type: number, options?: any): AxiosPromise<ApiResponseGetInstancesTaskResponse> {
            return localVarFp.getInstanceCombinedTasks(id, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteInstanceConnectiveStatus(instanceIdentifier: string, options?: any): AxiosPromise<ApiResponseGetRemoteInstanceConnectiveStatusResponse> {
            return localVarFp.getRemoteInstanceConnectiveStatus(instanceIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRemoteInstances(status?: string, options?: any): AxiosPromise<ApiResponseGetRemoteInstancesResponse> {
            return localVarFp.getRemoteInstances(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId 
         * @param {boolean} [includedAllocatedInstance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTask(taskId: number, includedAllocatedInstance?: boolean, options?: any): AxiosPromise<ApiResponseGetTaskResponse> {
            return localVarFp.getTask(taskId, includedAllocatedInstance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PostAllocateTaskRequest} postAllocateTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAllocateTask(id: number, postAllocateTaskRequest: PostAllocateTaskRequest, options?: any): AxiosPromise<ApiResponsePostAllocateTaskResponse> {
            return localVarFp.postAllocateTask(id, postAllocateTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostTaskRequest} postTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTasks(postTaskRequest: PostTaskRequest, options?: any): AxiosPromise<ApiResponsePostTaskResponse> {
            return localVarFp.postTasks(postTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} identifier 
         * @param {PutRemoteInstanceEnrollRequest} putRemoteInstanceEnrollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRemoteInstanceEnroll(identifier: string, putRemoteInstanceEnrollRequest: PutRemoteInstanceEnrollRequest, options?: any): AxiosPromise<ApiResponsePutRemoteInstanceEnrollResponse> {
            return localVarFp.putRemoteInstanceEnroll(identifier, putRemoteInstanceEnrollRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRemoteInstanceEnrollmentStatus(id: number, status: string, options?: any): AxiosPromise<ApiResponsePutRemoteInstanceEnrollmentStatusResponse> {
            return localVarFp.putRemoteInstanceEnrollmentStatus(id, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaskConfigure(taskId: number, body: string, options?: any): AxiosPromise<ApiResponsePutTaskConfigureResponse> {
            return localVarFp.putTaskConfigure(taskId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} taskId 
         * @param {PutTaskStatusRequest} putTaskStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaskStatus(taskId: number, putTaskStatusRequest: PutTaskStatusRequest, options?: any): AxiosPromise<ApiResponsePutTaskStatusResponse> {
            return localVarFp.putTaskStatus(taskId, putTaskStatusRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClusterApiApi - object-oriented interface
 * @export
 * @class ClusterApiApi
 * @extends {BaseAPI}
 */
export class ClusterApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public getAllTasks(options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).getAllTasks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public getInstanceCombinedTasks(id: number, type: number, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).getInstanceCombinedTasks(id, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public getRemoteInstanceConnectiveStatus(instanceIdentifier: string, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).getRemoteInstanceConnectiveStatus(instanceIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public getRemoteInstances(status?: string, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).getRemoteInstances(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} taskId 
     * @param {boolean} [includedAllocatedInstance] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public getTask(taskId: number, includedAllocatedInstance?: boolean, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).getTask(taskId, includedAllocatedInstance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PostAllocateTaskRequest} postAllocateTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public postAllocateTask(id: number, postAllocateTaskRequest: PostAllocateTaskRequest, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).postAllocateTask(id, postAllocateTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostTaskRequest} postTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public postTasks(postTaskRequest: PostTaskRequest, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).postTasks(postTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} identifier 
     * @param {PutRemoteInstanceEnrollRequest} putRemoteInstanceEnrollRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public putRemoteInstanceEnroll(identifier: string, putRemoteInstanceEnrollRequest: PutRemoteInstanceEnrollRequest, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).putRemoteInstanceEnroll(identifier, putRemoteInstanceEnrollRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public putRemoteInstanceEnrollmentStatus(id: number, status: string, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).putRemoteInstanceEnrollmentStatus(id, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} taskId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public putTaskConfigure(taskId: number, body: string, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).putTaskConfigure(taskId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} taskId 
     * @param {PutTaskStatusRequest} putTaskStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClusterApiApi
     */
    public putTaskStatus(taskId: number, putTaskStatusRequest: PutTaskStatusRequest, options?: AxiosRequestConfig) {
        return ClusterApiApiFp(this.configuration).putTaskStatus(taskId, putTaskStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataProxyApi - axios parameter creator
 * @export
 */
export const DataProxyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} expression 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expression: async (expression: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'expression' is not null or undefined
            assertParamExists('expression', 'expression', expression)
            const localVarPath = `/api/v1/proxy/stakeholder-expressions/{expression}`
                .replace(`{${"expression"}}`, encodeURIComponent(String(expression)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigure: async (type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getConfigure', 'type', type)
            const localVarPath = `/api/v1/proxy/configure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} type 
         * @param {string} anchor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData: async (type: string, anchor: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getData', 'type', type)
            // verify required parameter 'anchor' is not null or undefined
            assertParamExists('getData', 'anchor', anchor)
            const localVarPath = `/api/v1/proxy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (anchor !== undefined) {
                localVarQueryParameter['anchor'] = anchor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DataProxyConfigure} dataProxyConfigure 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigure: async (dataProxyConfigure: DataProxyConfigure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataProxyConfigure' is not null or undefined
            assertParamExists('updateConfigure', 'dataProxyConfigure', dataProxyConfigure)
            const localVarPath = `/api/v1/proxy/configure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataProxyConfigure, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataProxyApi - functional programming interface
 * @export
 */
export const DataProxyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataProxyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} expression 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async expression(expression: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.expression(expression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigure(type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigure(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} type 
         * @param {string} anchor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getData(type: string, anchor: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getData(type, anchor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DataProxyConfigure} dataProxyConfigure 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfigure(dataProxyConfigure: DataProxyConfigure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfigure(dataProxyConfigure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataProxyApi - factory interface
 * @export
 */
export const DataProxyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataProxyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} expression 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expression(expression: string, options?: any): AxiosPromise<object> {
            return localVarFp.expression(expression, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigure(type: string, options?: any): AxiosPromise<ApiResponseObject> {
            return localVarFp.getConfigure(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} type 
         * @param {string} anchor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getData(type: string, anchor: string, options?: any): AxiosPromise<ApiResponseObject> {
            return localVarFp.getData(type, anchor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DataProxyConfigure} dataProxyConfigure 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigure(dataProxyConfigure: DataProxyConfigure, options?: any): AxiosPromise<ApiResponseObject> {
            return localVarFp.updateConfigure(dataProxyConfigure, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataProxyApi - object-oriented interface
 * @export
 * @class DataProxyApi
 * @extends {BaseAPI}
 */
export class DataProxyApi extends BaseAPI {
    /**
     * 
     * @param {string} expression 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataProxyApi
     */
    public expression(expression: string, options?: AxiosRequestConfig) {
        return DataProxyApiFp(this.configuration).expression(expression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataProxyApi
     */
    public getConfigure(type: string, options?: AxiosRequestConfig) {
        return DataProxyApiFp(this.configuration).getConfigure(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} type 
     * @param {string} anchor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataProxyApi
     */
    public getData(type: string, anchor: string, options?: AxiosRequestConfig) {
        return DataProxyApiFp(this.configuration).getData(type, anchor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DataProxyConfigure} dataProxyConfigure 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataProxyApi
     */
    public updateConfigure(dataProxyConfigure: DataProxyConfigure, options?: AxiosRequestConfig) {
        return DataProxyApiFp(this.configuration).updateConfigure(dataProxyConfigure, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExportApiApi - axios parameter creator
 * @export
 */
export const ExportApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} exportType 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportJobDownloadUrl: async (exportType: number, jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportType' is not null or undefined
            assertParamExists('getExportJobDownloadUrl', 'exportType', exportType)
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('getExportJobDownloadUrl', 'jobId', jobId)
            const localVarPath = `/api/v1/exports/{export-type}/jobs/{job-id}/download`
                .replace(`{${"export-type"}}`, encodeURIComponent(String(exportType)))
                .replace(`{${"job-id"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportJobs: async (exportType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportType' is not null or undefined
            assertParamExists('getExportJobs', 'exportType', exportType)
            const localVarPath = `/api/v1/exports/{export-type}/jobs`
                .replace(`{${"export-type"}}`, encodeURIComponent(String(exportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exportType 
         * @param {PostExportRequest} postExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExport: async (exportType: number, postExportRequest: PostExportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportType' is not null or undefined
            assertParamExists('postExport', 'exportType', exportType)
            // verify required parameter 'postExportRequest' is not null or undefined
            assertParamExists('postExport', 'postExportRequest', postExportRequest)
            const localVarPath = `/api/v1/exports/{export-type}`
                .replace(`{${"export-type"}}`, encodeURIComponent(String(exportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postExportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRunExportJob: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/exports/next`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} exportType 
         * @param {string} jobId 
         * @param {PostUpdateExportJobsRequest} postUpdateExportJobsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJob: async (exportType: number, jobId: string, postUpdateExportJobsRequest: PostUpdateExportJobsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'exportType' is not null or undefined
            assertParamExists('updateJob', 'exportType', exportType)
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('updateJob', 'jobId', jobId)
            // verify required parameter 'postUpdateExportJobsRequest' is not null or undefined
            assertParamExists('updateJob', 'postUpdateExportJobsRequest', postUpdateExportJobsRequest)
            const localVarPath = `/api/v1/exports/{export-type}/jobs/{job-id}`
                .replace(`{${"export-type"}}`, encodeURIComponent(String(exportType)))
                .replace(`{${"job-id"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUpdateExportJobsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApiApi - functional programming interface
 * @export
 */
export const ExportApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExportApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} exportType 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportJobDownloadUrl(exportType: number, jobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetExportJobDownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportJobDownloadUrl(exportType, jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportJobs(exportType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetExportJobListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportJobs(exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exportType 
         * @param {PostExportRequest} postExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExport(exportType: number, postExportRequest: PostExportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExport(exportType, postExportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRunExportJob(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRunExportJob(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} exportType 
         * @param {string} jobId 
         * @param {PostUpdateExportJobsRequest} postUpdateExportJobsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateJob(exportType: number, jobId: string, postUpdateExportJobsRequest: PostUpdateExportJobsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostUpdateExportJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateJob(exportType, jobId, postUpdateExportJobsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExportApiApi - factory interface
 * @export
 */
export const ExportApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExportApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} exportType 
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportJobDownloadUrl(exportType: number, jobId: string, options?: any): AxiosPromise<ApiResponseGetExportJobDownloadUrlResponse> {
            return localVarFp.getExportJobDownloadUrl(exportType, jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportJobs(exportType: number, options?: any): AxiosPromise<ApiResponseGetExportJobListResponse> {
            return localVarFp.getExportJobs(exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exportType 
         * @param {PostExportRequest} postExportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExport(exportType: number, postExportRequest: PostExportRequest, options?: any): AxiosPromise<ApiResponsePostExportResponse> {
            return localVarFp.postExport(exportType, postExportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRunExportJob(options?: any): AxiosPromise<string> {
            return localVarFp.postRunExportJob(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} exportType 
         * @param {string} jobId 
         * @param {PostUpdateExportJobsRequest} postUpdateExportJobsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJob(exportType: number, jobId: string, postUpdateExportJobsRequest: PostUpdateExportJobsRequest, options?: any): AxiosPromise<ApiResponsePostUpdateExportJobResponse> {
            return localVarFp.updateJob(exportType, jobId, postUpdateExportJobsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExportApiApi - object-oriented interface
 * @export
 * @class ExportApiApi
 * @extends {BaseAPI}
 */
export class ExportApiApi extends BaseAPI {
    /**
     * 
     * @param {number} exportType 
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApiApi
     */
    public getExportJobDownloadUrl(exportType: number, jobId: string, options?: AxiosRequestConfig) {
        return ExportApiApiFp(this.configuration).getExportJobDownloadUrl(exportType, jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exportType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApiApi
     */
    public getExportJobs(exportType: number, options?: AxiosRequestConfig) {
        return ExportApiApiFp(this.configuration).getExportJobs(exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exportType 
     * @param {PostExportRequest} postExportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApiApi
     */
    public postExport(exportType: number, postExportRequest: PostExportRequest, options?: AxiosRequestConfig) {
        return ExportApiApiFp(this.configuration).postExport(exportType, postExportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApiApi
     */
    public postRunExportJob(options?: AxiosRequestConfig) {
        return ExportApiApiFp(this.configuration).postRunExportJob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} exportType 
     * @param {string} jobId 
     * @param {PostUpdateExportJobsRequest} postUpdateExportJobsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApiApi
     */
    public updateJob(exportType: number, jobId: string, postUpdateExportJobsRequest: PostUpdateExportJobsRequest, options?: AxiosRequestConfig) {
        return ExportApiApiFp(this.configuration).updateJob(exportType, jobId, postUpdateExportJobsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KnowledgeBaseApiApi - axios parameter creator
 * @export
 */
export const KnowledgeBaseApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PostKnowledgeCategoryRequest} postKnowledgeCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory: async (postKnowledgeCategoryRequest: PostKnowledgeCategoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postKnowledgeCategoryRequest' is not null or undefined
            assertParamExists('createCategory', 'postKnowledgeCategoryRequest', postKnowledgeCategoryRequest)
            const localVarPath = `/api/v1/knowledge-base/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postKnowledgeCategoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryList: async (categoryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategoryList', 'categoryId', categoryId)
            const localVarPath = `/api/v1/knowledge-base/categories/{category-id}`
                .replace(`{${"category-id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTree: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/knowledge-base/categories/tree`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {PostKnowledgeItemRequest} postKnowledgeItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryItem: async (categoryId: number, postKnowledgeItemRequest: PostKnowledgeItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('postCategoryItem', 'categoryId', categoryId)
            // verify required parameter 'postKnowledgeItemRequest' is not null or undefined
            assertParamExists('postCategoryItem', 'postKnowledgeItemRequest', postKnowledgeItemRequest)
            const localVarPath = `/api/v1/knowledge-base/categories/{category-id}/items`
                .replace(`{${"category-id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postKnowledgeItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KnowledgeBaseApiApi - functional programming interface
 * @export
 */
export const KnowledgeBaseApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KnowledgeBaseApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PostKnowledgeCategoryRequest} postKnowledgeCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCategory(postKnowledgeCategoryRequest: PostKnowledgeCategoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostKnowledgeCategoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCategory(postKnowledgeCategoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryList(categoryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetKnowledgeBaseCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryList(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTree(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetKnowledgeBaseCategoryTreeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTree(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {PostKnowledgeItemRequest} postKnowledgeItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCategoryItem(categoryId: number, postKnowledgeItemRequest: PostKnowledgeItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostKnowledgeItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCategoryItem(categoryId, postKnowledgeItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KnowledgeBaseApiApi - factory interface
 * @export
 */
export const KnowledgeBaseApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KnowledgeBaseApiApiFp(configuration)
    return {
        /**
         * 
         * @param {PostKnowledgeCategoryRequest} postKnowledgeCategoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategory(postKnowledgeCategoryRequest: PostKnowledgeCategoryRequest, options?: any): AxiosPromise<ApiResponsePostKnowledgeCategoryResponse> {
            return localVarFp.createCategory(postKnowledgeCategoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryList(categoryId: number, options?: any): AxiosPromise<ApiResponseGetKnowledgeBaseCategoriesResponse> {
            return localVarFp.getCategoryList(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTree(options?: any): AxiosPromise<ApiResponseGetKnowledgeBaseCategoryTreeResponse> {
            return localVarFp.getTree(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {PostKnowledgeItemRequest} postKnowledgeItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCategoryItem(categoryId: number, postKnowledgeItemRequest: PostKnowledgeItemRequest, options?: any): AxiosPromise<ApiResponsePostKnowledgeItemResponse> {
            return localVarFp.postCategoryItem(categoryId, postKnowledgeItemRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KnowledgeBaseApiApi - object-oriented interface
 * @export
 * @class KnowledgeBaseApiApi
 * @extends {BaseAPI}
 */
export class KnowledgeBaseApiApi extends BaseAPI {
    /**
     * 
     * @param {PostKnowledgeCategoryRequest} postKnowledgeCategoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseApiApi
     */
    public createCategory(postKnowledgeCategoryRequest: PostKnowledgeCategoryRequest, options?: AxiosRequestConfig) {
        return KnowledgeBaseApiApiFp(this.configuration).createCategory(postKnowledgeCategoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseApiApi
     */
    public getCategoryList(categoryId: number, options?: AxiosRequestConfig) {
        return KnowledgeBaseApiApiFp(this.configuration).getCategoryList(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseApiApi
     */
    public getTree(options?: AxiosRequestConfig) {
        return KnowledgeBaseApiApiFp(this.configuration).getTree(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} categoryId 
     * @param {PostKnowledgeItemRequest} postKnowledgeItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KnowledgeBaseApiApi
     */
    public postCategoryItem(categoryId: number, postKnowledgeItemRequest: PostKnowledgeItemRequest, options?: AxiosRequestConfig) {
        return KnowledgeBaseApiApiFp(this.configuration).postCategoryItem(categoryId, postKnowledgeItemRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailApiApi - axios parameter creator
 * @export
 */
export const MailApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} mailBoxId 
         * @param {DelMailBoxAccessRequest} delMailBoxAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMailBoxAccess: async (mailBoxId: number, delMailBoxAccessRequest: DelMailBoxAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('delMailBoxAccess', 'mailBoxId', mailBoxId)
            // verify required parameter 'delMailBoxAccessRequest' is not null or undefined
            assertParamExists('delMailBoxAccess', 'delMailBoxAccessRequest', delMailBoxAccessRequest)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/access`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(delMailBoxAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraftMail: async (mailBoxId: number, mailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('deleteDraftMail', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('deleteDraftMail', 'mailId', mailId)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/send/{mail-id}`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteMailContactsRequest} deleteMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailContacts: async (deleteMailContactsRequest: DeleteMailContactsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteMailContactsRequest' is not null or undefined
            assertParamExists('deleteMailContacts', 'deleteMailContactsRequest', deleteMailContactsRequest)
            const localVarPath = `/api/v1/mail/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteMailContactsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplate: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteMailTemplate', 'templateId', templateId)
            const localVarPath = `/api/v1/mail/templates/{template-id}`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplateBinding: async (templateId: number, mailboxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteMailTemplateBinding', 'templateId', templateId)
            // verify required parameter 'mailboxId' is not null or undefined
            assertParamExists('deleteMailTemplateBinding', 'mailboxId', mailboxId)
            const localVarPath = `/api/v1/mail/templates/{template-id}/unbind/{mailbox-id}`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"mailbox-id"}}`, encodeURIComponent(String(mailboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {number} contactId 
         * @param {number} bindType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailboxContactBinding: async (mailboxId: number, contactId: number, bindType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailboxId' is not null or undefined
            assertParamExists('deleteMailboxContactBinding', 'mailboxId', mailboxId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('deleteMailboxContactBinding', 'contactId', contactId)
            // verify required parameter 'bindType' is not null or undefined
            assertParamExists('deleteMailboxContactBinding', 'bindType', bindType)
            const localVarPath = `/api/v1/mail/contacts/{mailbox-id}/{contact-id}/{bind-type}/bind`
                .replace(`{${"mailbox-id"}}`, encodeURIComponent(String(mailboxId)))
                .replace(`{${"contact-id"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"bind-type"}}`, encodeURIComponent(String(bindType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedContactsByMailbox: async (mailboxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailboxId' is not null or undefined
            assertParamExists('getAggregatedContactsByMailbox', 'mailboxId', mailboxId)
            const localVarPath = `/api/v1/mail/contacts/{mailbox-id}/aggregateMailbox`
                .replace(`{${"mailbox-id"}}`, encodeURIComponent(String(mailboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundMailboxes: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getBoundMailboxes', 'templateId', templateId)
            const localVarPath = `/api/v1/mail/templates/{template-id}/boundMailboxes`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundTemplates: async (mailboxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailboxId' is not null or undefined
            assertParamExists('getBoundTemplates', 'mailboxId', mailboxId)
            const localVarPath = `/api/v1/mail/templates/{mailbox-id}/boundTemplates`
                .replace(`{${"mailbox-id"}}`, encodeURIComponent(String(mailboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsByMailbox: async (mailboxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailboxId' is not null or undefined
            assertParamExists('getContactsByMailbox', 'mailboxId', mailboxId)
            const localVarPath = `/api/v1/mail/contacts/{mailbox-id}/mailbox`
                .replace(`{${"mailbox-id"}}`, encodeURIComponent(String(mailboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailAction: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getMailAction', 'query', query)
            const localVarPath = `/api/v1/mail/mailboxes/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailBoxState: async (mailBoxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getMailBoxState', 'mailBoxId', mailBoxId)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/state`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [emailAddress] 
         * @param {string} [nickname] 
         * @param {boolean} [enable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailContacts: async (id?: number, emailAddress?: string, nickname?: string, enable?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mail/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (nickname !== undefined) {
                localVarQueryParameter['nickname'] = nickname;
            }

            if (enable !== undefined) {
                localVarQueryParameter['enable'] = enable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {string} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailContent: async (mailBoxId: number, mailId: number, content: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getMailContent', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('getMailContent', 'mailId', mailId)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('getMailContent', 'content', content)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/mails/{mail-id}/contents`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {Array<string>} contentNames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailContentDownloadUrl: async (mailBoxId: number, mailId: number, contentNames: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getMailContentDownloadUrl', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('getMailContentDownloadUrl', 'mailId', mailId)
            // verify required parameter 'contentNames' is not null or undefined
            assertParamExists('getMailContentDownloadUrl', 'contentNames', contentNames)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/mails/{mail-id}/downloadUrl`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentNames) {
                localVarQueryParameter['contentNames'] = contentNames;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailContentNames: async (mailBoxId: number, mailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getMailContentNames', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('getMailContentNames', 'mailId', mailId)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/mails/{mail-id}/contentNames`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailForApproving: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mail/mails/forApproving`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailGrantedAccess: async (mailBoxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getMailGrantedAccess', 'mailBoxId', mailBoxId)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/access`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplateById: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getMailTemplateById', 'templateId', templateId)
            const localVarPath = `/api/v1/mail/templates/{template-id}`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplateContent: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getMailTemplateContent', 'templateId', templateId)
            const localVarPath = `/api/v1/mail/templates/{template-id}/content`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} withContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplateList: async (withContent: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withContent' is not null or undefined
            assertParamExists('getMailTemplateList', 'withContent', withContent)
            const localVarPath = `/api/v1/mail/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (withContent !== undefined) {
                localVarQueryParameter['withContent'] = withContent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplatesOfMailbox: async (mailboxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailboxId' is not null or undefined
            assertParamExists('getMailTemplatesOfMailbox', 'mailboxId', mailboxId)
            const localVarPath = `/api/v1/mail/templates/of/{mailbox-id}`
                .replace(`{${"mailbox-id"}}`, encodeURIComponent(String(mailboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailValidationMessage: async (mailBoxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getMailValidationMessage', 'mailBoxId', mailBoxId)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/validation`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} encryptKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailVendorMails: async (vendorId: number, encryptKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('getMailVendorMails', 'vendorId', vendorId)
            // verify required parameter 'encryptKey' is not null or undefined
            assertParamExists('getMailVendorMails', 'encryptKey', encryptKey)
            const localVarPath = `/api/v1/mail/vendors/{vendor-id}/mailboxes`
                .replace(`{${"vendor-id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (encryptKey !== undefined) {
                localVarQueryParameter['encrypt-key'] = encryptKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailVendors: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/mail/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMails: async (mailBoxId: number, start: string, end: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getMails', 'mailBoxId', mailBoxId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getMails', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getMails', 'end', end)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/mails`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} encryptKey 
         * @param {boolean} [loadAdminView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMallBoxes: async (encryptKey: string, loadAdminView?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'encryptKey' is not null or undefined
            assertParamExists('getMallBoxes', 'encryptKey', encryptKey)
            const localVarPath = `/api/v1/mail/mailboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loadAdminView !== undefined) {
                localVarQueryParameter['load-admin-view'] = loadAdminView;
            }

            if (encryptKey !== undefined) {
                localVarQueryParameter['encrypt-key'] = encryptKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {string} fileName 
         * @param {string} frontEndUid 
         * @param {string} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendingEmailAttachmentTarget: async (mailBoxId: number, mailId: number, fileName: string, frontEndUid: string, action?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getSendingEmailAttachmentTarget', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('getSendingEmailAttachmentTarget', 'mailId', mailId)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('getSendingEmailAttachmentTarget', 'fileName', fileName)
            // verify required parameter 'frontEndUid' is not null or undefined
            assertParamExists('getSendingEmailAttachmentTarget', 'frontEndUid', frontEndUid)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/send/{mail-id}/attachment`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['file-name'] = fileName;
            }

            if (frontEndUid !== undefined) {
                localVarQueryParameter['front-end-uid'] = frontEndUid;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendingMail: async (mailBoxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getSendingMail', 'mailBoxId', mailBoxId)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/send`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendingMailDetail: async (mailBoxId: number, mailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('getSendingMailDetail', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('getSendingMailDetail', 'mailId', mailId)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/send/{mail-id}`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PatchMailBoxStateRequest} patchMailBoxStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailBox: async (mailBoxId: number, patchMailBoxStateRequest: PatchMailBoxStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('patchMailBox', 'mailBoxId', mailBoxId)
            // verify required parameter 'patchMailBoxStateRequest' is not null or undefined
            assertParamExists('patchMailBox', 'patchMailBoxStateRequest', patchMailBoxStateRequest)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchMailBoxStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailBoxes: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('patchMailBoxes', 'body', body)
            const localVarPath = `/api/v1/mail/mailboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PatchSendMailRequest} patchSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSendMail: async (mailBoxId: number, mailId: number, patchSendMailRequest: PatchSendMailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('patchSendMail', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('patchSendMail', 'mailId', mailId)
            // verify required parameter 'patchSendMailRequest' is not null or undefined
            assertParamExists('patchSendMail', 'patchSendMailRequest', patchSendMailRequest)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/send/{mail-id}`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchSendMailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PostApproveMailSendApplicationRequest} postApproveMailSendApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApproveMailSendApplication: async (mailBoxId: number, mailId: number, postApproveMailSendApplicationRequest: PostApproveMailSendApplicationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('postApproveMailSendApplication', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('postApproveMailSendApplication', 'mailId', mailId)
            // verify required parameter 'postApproveMailSendApplicationRequest' is not null or undefined
            assertParamExists('postApproveMailSendApplication', 'postApproveMailSendApplicationRequest', postApproveMailSendApplicationRequest)
            const localVarPath = `/api/v1/mail/mailSendApplication/approve/mailboxes/{mail-box-id}/mails/{mail-id}`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postApproveMailSendApplicationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PostMailBoxAccessRequest} postMailBoxAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailBoxAccess: async (mailBoxId: number, postMailBoxAccessRequest: PostMailBoxAccessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('postMailBoxAccess', 'mailBoxId', mailBoxId)
            // verify required parameter 'postMailBoxAccessRequest' is not null or undefined
            assertParamExists('postMailBoxAccess', 'postMailBoxAccessRequest', postMailBoxAccessRequest)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/access`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMailBoxAccessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostMailBoxesRequest} postMailBoxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailBoxes: async (postMailBoxesRequest: PostMailBoxesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postMailBoxesRequest' is not null or undefined
            assertParamExists('postMailBoxes', 'postMailBoxesRequest', postMailBoxesRequest)
            const localVarPath = `/api/v1/mail/mailboxes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMailBoxesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostMailContactsRequest} postMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailContacts: async (postMailContactsRequest: PostMailContactsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postMailContactsRequest' is not null or undefined
            assertParamExists('postMailContacts', 'postMailContactsRequest', postMailContactsRequest)
            const localVarPath = `/api/v1/mail/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMailContactsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailSendApplication: async (mailBoxId: number, mailId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('postMailSendApplication', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('postMailSendApplication', 'mailId', mailId)
            const localVarPath = `/api/v1/mail/mailSendApplication/mailboxes/{mail-box-id}/mails/{mail-id}`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PostMailTagsRequest} postMailTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTags: async (mailBoxId: number, postMailTagsRequest: PostMailTagsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('postMailTags', 'mailBoxId', mailBoxId)
            // verify required parameter 'postMailTagsRequest' is not null or undefined
            assertParamExists('postMailTags', 'postMailTagsRequest', postMailTagsRequest)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/tags`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMailTagsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostMailTemplateRequest} postMailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTemplate: async (postMailTemplateRequest: PostMailTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postMailTemplateRequest' is not null or undefined
            assertParamExists('postMailTemplate', 'postMailTemplateRequest', postMailTemplateRequest)
            const localVarPath = `/api/v1/mail/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMailTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTemplateBinding: async (templateId: number, mailboxId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('postMailTemplateBinding', 'templateId', templateId)
            // verify required parameter 'mailboxId' is not null or undefined
            assertParamExists('postMailTemplateBinding', 'mailboxId', mailboxId)
            const localVarPath = `/api/v1/mail/templates/{template-id}/bindTo/{mailbox-id}`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)))
                .replace(`{${"mailbox-id"}}`, encodeURIComponent(String(mailboxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostMailVendorRequest} postMailVendorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailVendors: async (postMailVendorRequest: PostMailVendorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postMailVendorRequest' is not null or undefined
            assertParamExists('postMailVendors', 'postMailVendorRequest', postMailVendorRequest)
            const localVarPath = `/api/v1/mail/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMailVendorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {number} contactId 
         * @param {number} bindType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailboxContactBinding: async (mailboxId: number, contactId: number, bindType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailboxId' is not null or undefined
            assertParamExists('postMailboxContactBinding', 'mailboxId', mailboxId)
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('postMailboxContactBinding', 'contactId', contactId)
            // verify required parameter 'bindType' is not null or undefined
            assertParamExists('postMailboxContactBinding', 'bindType', bindType)
            const localVarPath = `/api/v1/mail/contacts/{mailbox-id}/{contact-id}/{bind-type}/bind`
                .replace(`{${"mailbox-id"}}`, encodeURIComponent(String(mailboxId)))
                .replace(`{${"contact-id"}}`, encodeURIComponent(String(contactId)))
                .replace(`{${"bind-type"}}`, encodeURIComponent(String(bindType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PostSendMailRequest} postSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSendMail: async (mailBoxId: number, mailId: number, postSendMailRequest: PostSendMailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('postSendMail', 'mailBoxId', mailBoxId)
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('postSendMail', 'mailId', mailId)
            // verify required parameter 'postSendMailRequest' is not null or undefined
            assertParamExists('postSendMail', 'postSendMailRequest', postSendMailRequest)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/send/{mail-id}`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)))
                .replace(`{${"mail-id"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSendMailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PutDraftMailRequest} putDraftMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDraftMail: async (mailBoxId: number, putDraftMailRequest: PutDraftMailRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('putDraftMail', 'mailBoxId', mailBoxId)
            // verify required parameter 'putDraftMailRequest' is not null or undefined
            assertParamExists('putDraftMail', 'putDraftMailRequest', putDraftMailRequest)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}/send`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putDraftMailRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PutMailBoxesRequest} putMailBoxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailBoxes: async (mailBoxId: number, putMailBoxesRequest: PutMailBoxesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBoxId' is not null or undefined
            assertParamExists('putMailBoxes', 'mailBoxId', mailBoxId)
            // verify required parameter 'putMailBoxesRequest' is not null or undefined
            assertParamExists('putMailBoxes', 'putMailBoxesRequest', putMailBoxesRequest)
            const localVarPath = `/api/v1/mail/mailboxes/{mail-box-id}`
                .replace(`{${"mail-box-id"}}`, encodeURIComponent(String(mailBoxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMailBoxesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} contactId 
         * @param {PutMailContactsRequest} putMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailContacts: async (contactId: number, putMailContactsRequest: PutMailContactsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactId' is not null or undefined
            assertParamExists('putMailContacts', 'contactId', contactId)
            // verify required parameter 'putMailContactsRequest' is not null or undefined
            assertParamExists('putMailContacts', 'putMailContactsRequest', putMailContactsRequest)
            const localVarPath = `/api/v1/mail/contacts/{contact-id}`
                .replace(`{${"contact-id"}}`, encodeURIComponent(String(contactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMailContactsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {PutMailTemplateRequest} putMailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailTemplate: async (templateId: number, putMailTemplateRequest: PutMailTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('putMailTemplate', 'templateId', templateId)
            // verify required parameter 'putMailTemplateRequest' is not null or undefined
            assertParamExists('putMailTemplate', 'putMailTemplateRequest', putMailTemplateRequest)
            const localVarPath = `/api/v1/mail/templates/{template-id}`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMailTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {MailVendorConfigureDto} mailVendorConfigureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailVendors: async (vendorId: number, mailVendorConfigureDto: MailVendorConfigureDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('putMailVendors', 'vendorId', vendorId)
            // verify required parameter 'mailVendorConfigureDto' is not null or undefined
            assertParamExists('putMailVendors', 'mailVendorConfigureDto', mailVendorConfigureDto)
            const localVarPath = `/api/v1/mail/vendors/{vendor-id}`
                .replace(`{${"vendor-id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailVendorConfigureDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNewEmailBelongToVendor: async (vendorId: number, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('putNewEmailBelongToVendor', 'vendorId', vendorId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('putNewEmailBelongToVendor', 'body', body)
            const localVarPath = `/api/v1/mail/vendors/{vendor-id}/mailboxes`
                .replace(`{${"vendor-id"}}`, encodeURIComponent(String(vendorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailApiApi - functional programming interface
 * @export
 */
export const MailApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} mailBoxId 
         * @param {DelMailBoxAccessRequest} delMailBoxAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delMailBoxAccess(mailBoxId: number, delMailBoxAccessRequest: DelMailBoxAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDelMailBoxAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delMailBoxAccess(mailBoxId, delMailBoxAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDraftMail(mailBoxId: number, mailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteDraftMailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDraftMail(mailBoxId, mailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteMailContactsRequest} deleteMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailContacts(deleteMailContactsRequest: DeleteMailContactsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteMailContactsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailContacts(deleteMailContactsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailTemplate(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteMailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailTemplate(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailTemplateBinding(templateId: number, mailboxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteMailTemplateBindingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailTemplateBinding(templateId, mailboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {number} contactId 
         * @param {number} bindType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMailboxContactBinding(mailboxId: number, contactId: number, bindType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteMailboxContactBindingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMailboxContactBinding(mailboxId, contactId, bindType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAggregatedContactsByMailbox(mailboxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetAggregatedContactsByMailboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAggregatedContactsByMailbox(mailboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoundMailboxes(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetBoundMailboxesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoundMailboxes(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoundTemplates(mailboxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetBoundTemplatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoundTemplates(mailboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactsByMailbox(mailboxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetContactsByMailboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactsByMailbox(mailboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailAction(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailActionStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailAction(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailBoxState(mailBoxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailBoxStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailBoxState(mailBoxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [emailAddress] 
         * @param {string} [nickname] 
         * @param {boolean} [enable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailContacts(id?: number, emailAddress?: string, nickname?: string, enable?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailContactsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailContacts(id, emailAddress, nickname, enable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {string} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailContent(mailBoxId: number, mailId: number, content: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailContent(mailBoxId, mailId, content, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {Array<string>} contentNames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailContentDownloadUrl(mailBoxId: number, mailId: number, contentNames: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailContentDownloadUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailContentDownloadUrl(mailBoxId, mailId, contentNames, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailContentNames(mailBoxId: number, mailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailContentNamesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailContentNames(mailBoxId, mailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailForApproving(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailForApprovingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailForApproving(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailGrantedAccess(mailBoxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailBoxAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailGrantedAccess(mailBoxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailTemplateById(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailTemplateByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailTemplateById(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailTemplateContent(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailTemplateContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailTemplateContent(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} withContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailTemplateList(withContent: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailTemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailTemplateList(withContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailTemplatesOfMailbox(mailboxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTemplatesOfMailboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailTemplatesOfMailbox(mailboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailValidationMessage(mailBoxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailValidationMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailValidationMessage(mailBoxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} encryptKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailVendorMails(vendorId: number, encryptKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetVendorMailListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailVendorMails(vendorId, encryptKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMailVendors(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailVendorListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMailVendors(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMails(mailBoxId: number, start: string, end: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetEmailBoxMailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMails(mailBoxId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} encryptKey 
         * @param {boolean} [loadAdminView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMallBoxes(encryptKey: string, loadAdminView?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetMailListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMallBoxes(encryptKey, loadAdminView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {string} fileName 
         * @param {string} frontEndUid 
         * @param {string} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSendingEmailAttachmentTarget(mailBoxId: number, mailId: number, fileName: string, frontEndUid: string, action?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetSendingEmailAttachmentTargetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSendingEmailAttachmentTarget(mailBoxId, mailId, fileName, frontEndUid, action, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSendingMail(mailBoxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetSendingEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSendingMail(mailBoxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSendingMailDetail(mailBoxId: number, mailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetSendingEmailDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSendingMailDetail(mailBoxId, mailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PatchMailBoxStateRequest} patchMailBoxStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMailBox(mailBoxId: number, patchMailBoxStateRequest: PatchMailBoxStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePatchMailBoxStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMailBox(mailBoxId, patchMailBoxStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMailBoxes(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePatchManualEvaluateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMailBoxes(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PatchSendMailRequest} patchSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchSendMail(mailBoxId: number, mailId: number, patchSendMailRequest: PatchSendMailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePatchSendMailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchSendMail(mailBoxId, mailId, patchSendMailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PostApproveMailSendApplicationRequest} postApproveMailSendApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postApproveMailSendApplication(mailBoxId: number, mailId: number, postApproveMailSendApplicationRequest: PostApproveMailSendApplicationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostApproveMailSendApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postApproveMailSendApplication(mailBoxId, mailId, postApproveMailSendApplicationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PostMailBoxAccessRequest} postMailBoxAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailBoxAccess(mailBoxId: number, postMailBoxAccessRequest: PostMailBoxAccessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailBoxAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailBoxAccess(mailBoxId, postMailBoxAccessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostMailBoxesRequest} postMailBoxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailBoxes(postMailBoxesRequest: PostMailBoxesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailBoxesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailBoxes(postMailBoxesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostMailContactsRequest} postMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailContacts(postMailContactsRequest: PostMailContactsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailContactsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailContacts(postMailContactsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailSendApplication(mailBoxId: number, mailId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailSendApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailSendApplication(mailBoxId, mailId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PostMailTagsRequest} postMailTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailTags(mailBoxId: number, postMailTagsRequest: PostMailTagsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailTags(mailBoxId, postMailTagsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostMailTemplateRequest} postMailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailTemplate(postMailTemplateRequest: PostMailTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailTemplate(postMailTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailTemplateBinding(templateId: number, mailboxId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailTemplateBindingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailTemplateBinding(templateId, mailboxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostMailVendorRequest} postMailVendorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailVendors(postMailVendorRequest: PostMailVendorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailVendors(postMailVendorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {number} contactId 
         * @param {number} bindType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMailboxContactBinding(mailboxId: number, contactId: number, bindType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostMailboxContactBindingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMailboxContactBinding(mailboxId, contactId, bindType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PostSendMailRequest} postSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSendMail(mailBoxId: number, mailId: number, postSendMailRequest: PostSendMailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostSendMailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSendMail(mailBoxId, mailId, postSendMailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PutDraftMailRequest} putDraftMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDraftMail(mailBoxId: number, putDraftMailRequest: PutDraftMailRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutDraftMailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDraftMail(mailBoxId, putDraftMailRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PutMailBoxesRequest} putMailBoxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailBoxes(mailBoxId: number, putMailBoxesRequest: PutMailBoxesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutMailBoxesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailBoxes(mailBoxId, putMailBoxesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} contactId 
         * @param {PutMailContactsRequest} putMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailContacts(contactId: number, putMailContactsRequest: PutMailContactsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutMailContactsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailContacts(contactId, putMailContactsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {PutMailTemplateRequest} putMailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailTemplate(templateId: number, putMailTemplateRequest: PutMailTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutMailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailTemplate(templateId, putMailTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {MailVendorConfigureDto} mailVendorConfigureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailVendors(vendorId: number, mailVendorConfigureDto: MailVendorConfigureDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutMailVendorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailVendors(vendorId, mailVendorConfigureDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNewEmailBelongToVendor(vendorId: number, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNewEmailBelongToVendor(vendorId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailApiApi - factory interface
 * @export
 */
export const MailApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} mailBoxId 
         * @param {DelMailBoxAccessRequest} delMailBoxAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delMailBoxAccess(mailBoxId: number, delMailBoxAccessRequest: DelMailBoxAccessRequest, options?: any): AxiosPromise<ApiResponseDelMailBoxAccessResponse> {
            return localVarFp.delMailBoxAccess(mailBoxId, delMailBoxAccessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDraftMail(mailBoxId: number, mailId: number, options?: any): AxiosPromise<ApiResponseDeleteDraftMailResponse> {
            return localVarFp.deleteDraftMail(mailBoxId, mailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteMailContactsRequest} deleteMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailContacts(deleteMailContactsRequest: DeleteMailContactsRequest, options?: any): AxiosPromise<ApiResponseDeleteMailContactsResponse> {
            return localVarFp.deleteMailContacts(deleteMailContactsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplate(templateId: number, options?: any): AxiosPromise<ApiResponseDeleteMailTemplateResponse> {
            return localVarFp.deleteMailTemplate(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailTemplateBinding(templateId: number, mailboxId: number, options?: any): AxiosPromise<ApiResponseDeleteMailTemplateBindingResponse> {
            return localVarFp.deleteMailTemplateBinding(templateId, mailboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {number} contactId 
         * @param {number} bindType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMailboxContactBinding(mailboxId: number, contactId: number, bindType: number, options?: any): AxiosPromise<ApiResponseDeleteMailboxContactBindingResponse> {
            return localVarFp.deleteMailboxContactBinding(mailboxId, contactId, bindType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAggregatedContactsByMailbox(mailboxId: number, options?: any): AxiosPromise<ApiResponseGetAggregatedContactsByMailboxResponse> {
            return localVarFp.getAggregatedContactsByMailbox(mailboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundMailboxes(templateId: number, options?: any): AxiosPromise<ApiResponseGetBoundMailboxesResponse> {
            return localVarFp.getBoundMailboxes(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoundTemplates(mailboxId: number, options?: any): AxiosPromise<ApiResponseGetBoundTemplatesResponse> {
            return localVarFp.getBoundTemplates(mailboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsByMailbox(mailboxId: number, options?: any): AxiosPromise<ApiResponseGetContactsByMailboxResponse> {
            return localVarFp.getContactsByMailbox(mailboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailAction(query: string, options?: any): AxiosPromise<ApiResponseGetMailActionStatusResponse> {
            return localVarFp.getMailAction(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailBoxState(mailBoxId: number, options?: any): AxiosPromise<ApiResponseGetMailBoxStateResponse> {
            return localVarFp.getMailBoxState(mailBoxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [emailAddress] 
         * @param {string} [nickname] 
         * @param {boolean} [enable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailContacts(id?: number, emailAddress?: string, nickname?: string, enable?: boolean, options?: any): AxiosPromise<ApiResponseGetMailContactsResponse> {
            return localVarFp.getMailContacts(id, emailAddress, nickname, enable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {string} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailContent(mailBoxId: number, mailId: number, content: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getMailContent(mailBoxId, mailId, content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {Array<string>} contentNames 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailContentDownloadUrl(mailBoxId: number, mailId: number, contentNames: Array<string>, options?: any): AxiosPromise<ApiResponseGetMailContentDownloadUrlResponse> {
            return localVarFp.getMailContentDownloadUrl(mailBoxId, mailId, contentNames, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailContentNames(mailBoxId: number, mailId: number, options?: any): AxiosPromise<ApiResponseGetMailContentNamesResponse> {
            return localVarFp.getMailContentNames(mailBoxId, mailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailForApproving(options?: any): AxiosPromise<ApiResponseGetMailForApprovingResponse> {
            return localVarFp.getMailForApproving(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailGrantedAccess(mailBoxId: number, options?: any): AxiosPromise<ApiResponseGetMailBoxAccessResponse> {
            return localVarFp.getMailGrantedAccess(mailBoxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplateById(templateId: number, options?: any): AxiosPromise<ApiResponseGetMailTemplateByIdResponse> {
            return localVarFp.getMailTemplateById(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplateContent(templateId: number, options?: any): AxiosPromise<ApiResponseGetMailTemplateContentResponse> {
            return localVarFp.getMailTemplateContent(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} withContent 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplateList(withContent: boolean, options?: any): AxiosPromise<ApiResponseGetMailTemplateListResponse> {
            return localVarFp.getMailTemplateList(withContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailTemplatesOfMailbox(mailboxId: number, options?: any): AxiosPromise<ApiResponseGetTemplatesOfMailboxResponse> {
            return localVarFp.getMailTemplatesOfMailbox(mailboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailValidationMessage(mailBoxId: number, options?: any): AxiosPromise<ApiResponseGetMailValidationMessageResponse> {
            return localVarFp.getMailValidationMessage(mailBoxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} encryptKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailVendorMails(vendorId: number, encryptKey: string, options?: any): AxiosPromise<ApiResponseGetVendorMailListResponse> {
            return localVarFp.getMailVendorMails(vendorId, encryptKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMailVendors(options?: any): AxiosPromise<ApiResponseGetMailVendorListResponse> {
            return localVarFp.getMailVendors(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMails(mailBoxId: number, start: string, end: string, options?: any): AxiosPromise<ApiResponseGetEmailBoxMailsResponse> {
            return localVarFp.getMails(mailBoxId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} encryptKey 
         * @param {boolean} [loadAdminView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMallBoxes(encryptKey: string, loadAdminView?: boolean, options?: any): AxiosPromise<ApiResponseGetMailListResponse> {
            return localVarFp.getMallBoxes(encryptKey, loadAdminView, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {string} fileName 
         * @param {string} frontEndUid 
         * @param {string} [action] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendingEmailAttachmentTarget(mailBoxId: number, mailId: number, fileName: string, frontEndUid: string, action?: string, options?: any): AxiosPromise<ApiResponseGetSendingEmailAttachmentTargetResponse> {
            return localVarFp.getSendingEmailAttachmentTarget(mailBoxId, mailId, fileName, frontEndUid, action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendingMail(mailBoxId: number, options?: any): AxiosPromise<ApiResponseGetSendingEmailResponse> {
            return localVarFp.getSendingMail(mailBoxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSendingMailDetail(mailBoxId: number, mailId: number, options?: any): AxiosPromise<ApiResponseGetSendingEmailDetailResponse> {
            return localVarFp.getSendingMailDetail(mailBoxId, mailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PatchMailBoxStateRequest} patchMailBoxStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailBox(mailBoxId: number, patchMailBoxStateRequest: PatchMailBoxStateRequest, options?: any): AxiosPromise<ApiResponsePatchMailBoxStateResponse> {
            return localVarFp.patchMailBox(mailBoxId, patchMailBoxStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMailBoxes(body: object, options?: any): AxiosPromise<ApiResponsePatchManualEvaluateResponse> {
            return localVarFp.patchMailBoxes(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PatchSendMailRequest} patchSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchSendMail(mailBoxId: number, mailId: number, patchSendMailRequest: PatchSendMailRequest, options?: any): AxiosPromise<ApiResponsePatchSendMailResponse> {
            return localVarFp.patchSendMail(mailBoxId, mailId, patchSendMailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PostApproveMailSendApplicationRequest} postApproveMailSendApplicationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postApproveMailSendApplication(mailBoxId: number, mailId: number, postApproveMailSendApplicationRequest: PostApproveMailSendApplicationRequest, options?: any): AxiosPromise<ApiResponsePostApproveMailSendApplicationResponse> {
            return localVarFp.postApproveMailSendApplication(mailBoxId, mailId, postApproveMailSendApplicationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PostMailBoxAccessRequest} postMailBoxAccessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailBoxAccess(mailBoxId: number, postMailBoxAccessRequest: PostMailBoxAccessRequest, options?: any): AxiosPromise<ApiResponsePostMailBoxAccessResponse> {
            return localVarFp.postMailBoxAccess(mailBoxId, postMailBoxAccessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostMailBoxesRequest} postMailBoxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailBoxes(postMailBoxesRequest: PostMailBoxesRequest, options?: any): AxiosPromise<ApiResponsePostMailBoxesResponse> {
            return localVarFp.postMailBoxes(postMailBoxesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostMailContactsRequest} postMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailContacts(postMailContactsRequest: PostMailContactsRequest, options?: any): AxiosPromise<ApiResponsePostMailContactsResponse> {
            return localVarFp.postMailContacts(postMailContactsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailSendApplication(mailBoxId: number, mailId: number, options?: any): AxiosPromise<ApiResponsePostMailSendApplicationResponse> {
            return localVarFp.postMailSendApplication(mailBoxId, mailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PostMailTagsRequest} postMailTagsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTags(mailBoxId: number, postMailTagsRequest: PostMailTagsRequest, options?: any): AxiosPromise<ApiResponsePostMailTagsResponse> {
            return localVarFp.postMailTags(mailBoxId, postMailTagsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostMailTemplateRequest} postMailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTemplate(postMailTemplateRequest: PostMailTemplateRequest, options?: any): AxiosPromise<ApiResponsePostMailTemplateResponse> {
            return localVarFp.postMailTemplate(postMailTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {number} mailboxId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailTemplateBinding(templateId: number, mailboxId: number, options?: any): AxiosPromise<ApiResponsePostMailTemplateBindingResponse> {
            return localVarFp.postMailTemplateBinding(templateId, mailboxId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostMailVendorRequest} postMailVendorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailVendors(postMailVendorRequest: PostMailVendorRequest, options?: any): AxiosPromise<ApiResponsePostMailVendorResponse> {
            return localVarFp.postMailVendors(postMailVendorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailboxId 
         * @param {number} contactId 
         * @param {number} bindType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMailboxContactBinding(mailboxId: number, contactId: number, bindType: number, options?: any): AxiosPromise<ApiResponsePostMailboxContactBindingResponse> {
            return localVarFp.postMailboxContactBinding(mailboxId, contactId, bindType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {number} mailId 
         * @param {PostSendMailRequest} postSendMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSendMail(mailBoxId: number, mailId: number, postSendMailRequest: PostSendMailRequest, options?: any): AxiosPromise<ApiResponsePostSendMailResponse> {
            return localVarFp.postSendMail(mailBoxId, mailId, postSendMailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PutDraftMailRequest} putDraftMailRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDraftMail(mailBoxId: number, putDraftMailRequest: PutDraftMailRequest, options?: any): AxiosPromise<ApiResponsePutDraftMailResponse> {
            return localVarFp.putDraftMail(mailBoxId, putDraftMailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mailBoxId 
         * @param {PutMailBoxesRequest} putMailBoxesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailBoxes(mailBoxId: number, putMailBoxesRequest: PutMailBoxesRequest, options?: any): AxiosPromise<ApiResponsePutMailBoxesResponse> {
            return localVarFp.putMailBoxes(mailBoxId, putMailBoxesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} contactId 
         * @param {PutMailContactsRequest} putMailContactsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailContacts(contactId: number, putMailContactsRequest: PutMailContactsRequest, options?: any): AxiosPromise<ApiResponsePutMailContactsResponse> {
            return localVarFp.putMailContacts(contactId, putMailContactsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {PutMailTemplateRequest} putMailTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailTemplate(templateId: number, putMailTemplateRequest: PutMailTemplateRequest, options?: any): AxiosPromise<ApiResponsePutMailTemplateResponse> {
            return localVarFp.putMailTemplate(templateId, putMailTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {MailVendorConfigureDto} mailVendorConfigureDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailVendors(vendorId: number, mailVendorConfigureDto: MailVendorConfigureDto, options?: any): AxiosPromise<ApiResponsePutMailVendorResponse> {
            return localVarFp.putMailVendors(vendorId, mailVendorConfigureDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNewEmailBelongToVendor(vendorId: number, body: object, options?: any): AxiosPromise<ApiResponseObject> {
            return localVarFp.putNewEmailBelongToVendor(vendorId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailApiApi - object-oriented interface
 * @export
 * @class MailApiApi
 * @extends {BaseAPI}
 */
export class MailApiApi extends BaseAPI {
    /**
     * 
     * @param {number} mailBoxId 
     * @param {DelMailBoxAccessRequest} delMailBoxAccessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public delMailBoxAccess(mailBoxId: number, delMailBoxAccessRequest: DelMailBoxAccessRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).delMailBoxAccess(mailBoxId, delMailBoxAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public deleteDraftMail(mailBoxId: number, mailId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).deleteDraftMail(mailBoxId, mailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteMailContactsRequest} deleteMailContactsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public deleteMailContacts(deleteMailContactsRequest: DeleteMailContactsRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).deleteMailContacts(deleteMailContactsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public deleteMailTemplate(templateId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).deleteMailTemplate(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {number} mailboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public deleteMailTemplateBinding(templateId: number, mailboxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).deleteMailTemplateBinding(templateId, mailboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailboxId 
     * @param {number} contactId 
     * @param {number} bindType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public deleteMailboxContactBinding(mailboxId: number, contactId: number, bindType: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).deleteMailboxContactBinding(mailboxId, contactId, bindType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getAggregatedContactsByMailbox(mailboxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getAggregatedContactsByMailbox(mailboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getBoundMailboxes(templateId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getBoundMailboxes(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getBoundTemplates(mailboxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getBoundTemplates(mailboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getContactsByMailbox(mailboxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getContactsByMailbox(mailboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailAction(query: string, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailAction(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailBoxState(mailBoxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailBoxState(mailBoxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [emailAddress] 
     * @param {string} [nickname] 
     * @param {boolean} [enable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailContacts(id?: number, emailAddress?: string, nickname?: string, enable?: boolean, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailContacts(id, emailAddress, nickname, enable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {string} content 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailContent(mailBoxId: number, mailId: number, content: string, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailContent(mailBoxId, mailId, content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {Array<string>} contentNames 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailContentDownloadUrl(mailBoxId: number, mailId: number, contentNames: Array<string>, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailContentDownloadUrl(mailBoxId, mailId, contentNames, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailContentNames(mailBoxId: number, mailId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailContentNames(mailBoxId, mailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailForApproving(options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailForApproving(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailGrantedAccess(mailBoxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailGrantedAccess(mailBoxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailTemplateById(templateId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailTemplateById(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailTemplateContent(templateId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailTemplateContent(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} withContent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailTemplateList(withContent: boolean, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailTemplateList(withContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailTemplatesOfMailbox(mailboxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailTemplatesOfMailbox(mailboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailValidationMessage(mailBoxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailValidationMessage(mailBoxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} vendorId 
     * @param {string} encryptKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailVendorMails(vendorId: number, encryptKey: string, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailVendorMails(vendorId, encryptKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMailVendors(options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMailVendors(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {string} start 
     * @param {string} end 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMails(mailBoxId: number, start: string, end: string, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMails(mailBoxId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} encryptKey 
     * @param {boolean} [loadAdminView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getMallBoxes(encryptKey: string, loadAdminView?: boolean, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getMallBoxes(encryptKey, loadAdminView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {string} fileName 
     * @param {string} frontEndUid 
     * @param {string} [action] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getSendingEmailAttachmentTarget(mailBoxId: number, mailId: number, fileName: string, frontEndUid: string, action?: string, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getSendingEmailAttachmentTarget(mailBoxId, mailId, fileName, frontEndUid, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getSendingMail(mailBoxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getSendingMail(mailBoxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public getSendingMailDetail(mailBoxId: number, mailId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).getSendingMailDetail(mailBoxId, mailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {PatchMailBoxStateRequest} patchMailBoxStateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public patchMailBox(mailBoxId: number, patchMailBoxStateRequest: PatchMailBoxStateRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).patchMailBox(mailBoxId, patchMailBoxStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public patchMailBoxes(body: object, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).patchMailBoxes(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {PatchSendMailRequest} patchSendMailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public patchSendMail(mailBoxId: number, mailId: number, patchSendMailRequest: PatchSendMailRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).patchSendMail(mailBoxId, mailId, patchSendMailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {PostApproveMailSendApplicationRequest} postApproveMailSendApplicationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postApproveMailSendApplication(mailBoxId: number, mailId: number, postApproveMailSendApplicationRequest: PostApproveMailSendApplicationRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postApproveMailSendApplication(mailBoxId, mailId, postApproveMailSendApplicationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {PostMailBoxAccessRequest} postMailBoxAccessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailBoxAccess(mailBoxId: number, postMailBoxAccessRequest: PostMailBoxAccessRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailBoxAccess(mailBoxId, postMailBoxAccessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostMailBoxesRequest} postMailBoxesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailBoxes(postMailBoxesRequest: PostMailBoxesRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailBoxes(postMailBoxesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostMailContactsRequest} postMailContactsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailContacts(postMailContactsRequest: PostMailContactsRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailContacts(postMailContactsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailSendApplication(mailBoxId: number, mailId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailSendApplication(mailBoxId, mailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {PostMailTagsRequest} postMailTagsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailTags(mailBoxId: number, postMailTagsRequest: PostMailTagsRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailTags(mailBoxId, postMailTagsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostMailTemplateRequest} postMailTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailTemplate(postMailTemplateRequest: PostMailTemplateRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailTemplate(postMailTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {number} mailboxId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailTemplateBinding(templateId: number, mailboxId: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailTemplateBinding(templateId, mailboxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostMailVendorRequest} postMailVendorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailVendors(postMailVendorRequest: PostMailVendorRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailVendors(postMailVendorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailboxId 
     * @param {number} contactId 
     * @param {number} bindType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postMailboxContactBinding(mailboxId: number, contactId: number, bindType: number, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postMailboxContactBinding(mailboxId, contactId, bindType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {number} mailId 
     * @param {PostSendMailRequest} postSendMailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public postSendMail(mailBoxId: number, mailId: number, postSendMailRequest: PostSendMailRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).postSendMail(mailBoxId, mailId, postSendMailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {PutDraftMailRequest} putDraftMailRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public putDraftMail(mailBoxId: number, putDraftMailRequest: PutDraftMailRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).putDraftMail(mailBoxId, putDraftMailRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mailBoxId 
     * @param {PutMailBoxesRequest} putMailBoxesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public putMailBoxes(mailBoxId: number, putMailBoxesRequest: PutMailBoxesRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).putMailBoxes(mailBoxId, putMailBoxesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} contactId 
     * @param {PutMailContactsRequest} putMailContactsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public putMailContacts(contactId: number, putMailContactsRequest: PutMailContactsRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).putMailContacts(contactId, putMailContactsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {PutMailTemplateRequest} putMailTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public putMailTemplate(templateId: number, putMailTemplateRequest: PutMailTemplateRequest, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).putMailTemplate(templateId, putMailTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} vendorId 
     * @param {MailVendorConfigureDto} mailVendorConfigureDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public putMailVendors(vendorId: number, mailVendorConfigureDto: MailVendorConfigureDto, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).putMailVendors(vendorId, mailVendorConfigureDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} vendorId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailApiApi
     */
    public putNewEmailBelongToVendor(vendorId: number, body: object, options?: AxiosRequestConfig) {
        return MailApiApiFp(this.configuration).putNewEmailBelongToVendor(vendorId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MetasApiApi - axios parameter creator
 * @export
 */
export const MetasApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentSchema: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metas/content-schemas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentSchemaById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContentSchemaById', 'id', id)
            const localVarPath = `/api/v1/metas/content-schemas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketPriority: async (force: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'force' is not null or undefined
            assertParamExists('getTicketPriority', 'force', force)
            const localVarPath = `/api/v1/metas/ticket-priority`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketSourceList: async (force: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'force' is not null or undefined
            assertParamExists('getTicketSourceList', 'force', force)
            const localVarPath = `/api/v1/metas/ticket-source`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketStatus: async (id: number, force: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketStatus', 'id', id)
            // verify required parameter 'force' is not null or undefined
            assertParamExists('getTicketStatus', 'force', force)
            const localVarPath = `/api/v1/metas/ticket-status/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} force 
         * @param {boolean} [needsFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketStatusList: async (force: boolean, needsFilter?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'force' is not null or undefined
            assertParamExists('getTicketStatusList', 'force', force)
            const localVarPath = `/api/v1/metas/ticket-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }

            if (needsFilter !== undefined) {
                localVarQueryParameter['needsFilter'] = needsFilter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketType: async (id: number, force: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketType', 'id', id)
            // verify required parameter 'force' is not null or undefined
            assertParamExists('getTicketType', 'force', force)
            const localVarPath = `/api/v1/metas/ticket-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketTypeList: async (force: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'force' is not null or undefined
            assertParamExists('getTicketTypeList', 'force', force)
            const localVarPath = `/api/v1/metas/ticket-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketTypeSchema: async (isForce?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metas/ticket-types/schema`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isForce !== undefined) {
                localVarQueryParameter['isForce'] = isForce;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSource: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserSource', 'id', id)
            const localVarPath = `/api/v1/metas/user-sources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSourceList: async (force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/metas/user-sources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostContentSchemaRequest} postContentSchemaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContentSchema: async (postContentSchemaRequest: PostContentSchemaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postContentSchemaRequest' is not null or undefined
            assertParamExists('postContentSchema', 'postContentSchemaRequest', postContentSchemaRequest)
            const localVarPath = `/api/v1/metas/content-schemas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postContentSchemaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutContentSchemaRequest} putContentSchemaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContentSchema: async (id: number, putContentSchemaRequest: PutContentSchemaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putContentSchema', 'id', id)
            // verify required parameter 'putContentSchemaRequest' is not null or undefined
            assertParamExists('putContentSchema', 'putContentSchemaRequest', putContentSchemaRequest)
            const localVarPath = `/api/v1/metas/content-schemas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putContentSchemaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetasApiApi - functional programming interface
 * @export
 */
export const MetasApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetasApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentSchema(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetContentSchemaListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentSchema(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentSchemaById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetContentSchemaByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentSchemaById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketPriority(force: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketPriorityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketPriority(force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketSourceList(force: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketSourceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketSourceList(force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketStatus(id: number, force: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketStatus(id, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} force 
         * @param {boolean} [needsFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketStatusList(force: boolean, needsFilter?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketStatusListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketStatusList(force, needsFilter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketType(id: number, force: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketType(id, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketTypeList(force: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketTypeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketTypeList(force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketTypeSchema(isForce?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketTypeSchema(isForce, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSource(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetUserSourceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSource(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSourceList(force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetUserSourceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSourceList(force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostContentSchemaRequest} postContentSchemaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContentSchema(postContentSchemaRequest: PostContentSchemaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostContentSchemaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContentSchema(postContentSchemaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutContentSchemaRequest} putContentSchemaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContentSchema(id: number, putContentSchemaRequest: PutContentSchemaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutContentSchemaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContentSchema(id, putContentSchemaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MetasApiApi - factory interface
 * @export
 */
export const MetasApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetasApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentSchema(options?: any): AxiosPromise<ApiResponseGetContentSchemaListResponse> {
            return localVarFp.getContentSchema(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentSchemaById(id: number, options?: any): AxiosPromise<ApiResponseGetContentSchemaByIdResponse> {
            return localVarFp.getContentSchemaById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketPriority(force: boolean, options?: any): AxiosPromise<ApiResponseGetTicketPriorityListResponse> {
            return localVarFp.getTicketPriority(force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketSourceList(force: boolean, options?: any): AxiosPromise<ApiResponseGetTicketSourceListResponse> {
            return localVarFp.getTicketSourceList(force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketStatus(id: number, force: boolean, options?: any): AxiosPromise<ApiResponseGetTicketStatusResponse> {
            return localVarFp.getTicketStatus(id, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} force 
         * @param {boolean} [needsFilter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketStatusList(force: boolean, needsFilter?: boolean, options?: any): AxiosPromise<ApiResponseGetTicketStatusListResponse> {
            return localVarFp.getTicketStatusList(force, needsFilter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketType(id: number, force: boolean, options?: any): AxiosPromise<ApiResponseGetTicketTypeResponse> {
            return localVarFp.getTicketType(id, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} force 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketTypeList(force: boolean, options?: any): AxiosPromise<ApiResponseGetTicketTypeListResponse> {
            return localVarFp.getTicketTypeList(force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketTypeSchema(isForce?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.getTicketTypeSchema(isForce, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSource(id: number, options?: any): AxiosPromise<ApiResponseGetUserSourceResponse> {
            return localVarFp.getUserSource(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [force] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSourceList(force?: boolean, options?: any): AxiosPromise<ApiResponseGetUserSourceListResponse> {
            return localVarFp.getUserSourceList(force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostContentSchemaRequest} postContentSchemaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContentSchema(postContentSchemaRequest: PostContentSchemaRequest, options?: any): AxiosPromise<ApiResponsePostContentSchemaResponse> {
            return localVarFp.postContentSchema(postContentSchemaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutContentSchemaRequest} putContentSchemaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContentSchema(id: number, putContentSchemaRequest: PutContentSchemaRequest, options?: any): AxiosPromise<ApiResponsePutContentSchemaResponse> {
            return localVarFp.putContentSchema(id, putContentSchemaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetasApiApi - object-oriented interface
 * @export
 * @class MetasApiApi
 * @extends {BaseAPI}
 */
export class MetasApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getContentSchema(options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getContentSchema(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getContentSchemaById(id: number, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getContentSchemaById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getTicketPriority(force: boolean, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getTicketPriority(force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getTicketSourceList(force: boolean, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getTicketSourceList(force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getTicketStatus(id: number, force: boolean, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getTicketStatus(id, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} force 
     * @param {boolean} [needsFilter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getTicketStatusList(force: boolean, needsFilter?: boolean, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getTicketStatusList(force, needsFilter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getTicketType(id: number, force: boolean, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getTicketType(id, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} force 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getTicketTypeList(force: boolean, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getTicketTypeList(force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isForce] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getTicketTypeSchema(isForce?: boolean, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getTicketTypeSchema(isForce, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getUserSource(id: number, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getUserSource(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [force] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public getUserSourceList(force?: boolean, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).getUserSourceList(force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostContentSchemaRequest} postContentSchemaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public postContentSchema(postContentSchemaRequest: PostContentSchemaRequest, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).postContentSchema(postContentSchemaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutContentSchemaRequest} putContentSchemaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetasApiApi
     */
    public putContentSchema(id: number, putContentSchemaRequest: PutContentSchemaRequest, options?: AxiosRequestConfig) {
        return MetasApiApiFp(this.configuration).putContentSchema(id, putContentSchemaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApiApi - axios parameter creator
 * @export
 */
export const NotificationApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationEvent: async (eventId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('deleteNotificationEvent', 'eventId', eventId)
            const localVarPath = `/api/v1/notification/events/{event-id}`
                .replace(`{${"event-id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (templateName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateName' is not null or undefined
            assertParamExists('deleteTemplate', 'templateName', templateName)
            const localVarPath = `/api/v1/notificationTemplates/{template-name}`
                .replace(`{${"template-name"}}`, encodeURIComponent(String(templateName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotificationEvent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notification/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationEventById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getNotificationEventById', 'id', id)
            const localVarPath = `/api/v1/notification/events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationEventByTopic: async (topic: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('getNotificationEventByTopic', 'topic', topic)
            const localVarPath = `/api/v1/notification/events/topics/{topic}`
                .replace(`{${"topic"}}`, encodeURIComponent(String(topic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} channelType 
         * @param {string} [eventType] 
         * @param {boolean} [inUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationTemplateList: async (channelType: string, eventType?: string, inUse?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelType' is not null or undefined
            assertParamExists('getNotificationTemplateList', 'channelType', channelType)
            const localVarPath = `/api/v1/notificationTemplates/{channel-type}`
                .replace(`{${"channel-type"}}`, encodeURIComponent(String(channelType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (inUse !== undefined) {
                localVarQueryParameter['inUse'] = inUse;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} topic 
         * @param {PostNotificationRequest} postNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotification: async (topic: string, postNotificationRequest: PostNotificationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'topic' is not null or undefined
            assertParamExists('postNotification', 'topic', topic)
            // verify required parameter 'postNotificationRequest' is not null or undefined
            assertParamExists('postNotification', 'postNotificationRequest', postNotificationRequest)
            const localVarPath = `/api/v1/notification/events/topics/{topic}`
                .replace(`{${"topic"}}`, encodeURIComponent(String(topic)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postNotificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostNotificationEventRequest} postNotificationEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotificationEvent: async (postNotificationEventRequest: PostNotificationEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postNotificationEventRequest' is not null or undefined
            assertParamExists('postNotificationEvent', 'postNotificationEventRequest', postNotificationEventRequest)
            const localVarPath = `/api/v1/notification/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postNotificationEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostTemplateRequest} postTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTemplate: async (postTemplateRequest: PostTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTemplateRequest' is not null or undefined
            assertParamExists('postTemplate', 'postTemplateRequest', postTemplateRequest)
            const localVarPath = `/api/v1/notificationTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} eventId 
         * @param {PutNotificationEventRequest} putNotificationEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationEvent: async (eventId: number, putNotificationEventRequest: PutNotificationEventRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('putNotificationEvent', 'eventId', eventId)
            // verify required parameter 'putNotificationEventRequest' is not null or undefined
            assertParamExists('putNotificationEvent', 'putNotificationEventRequest', putNotificationEventRequest)
            const localVarPath = `/api/v1/notification/events/{event-id}`
                .replace(`{${"event-id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putNotificationEventRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateName 
         * @param {PutNotificationTemplateRequest} putNotificationTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationTemplate: async (templateName: string, putNotificationTemplateRequest: PutNotificationTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateName' is not null or undefined
            assertParamExists('putNotificationTemplate', 'templateName', templateName)
            // verify required parameter 'putNotificationTemplateRequest' is not null or undefined
            assertParamExists('putNotificationTemplate', 'putNotificationTemplateRequest', putNotificationTemplateRequest)
            const localVarPath = `/api/v1/notificationTemplates/{template-name}`
                .replace(`{${"template-name"}}`, encodeURIComponent(String(templateName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putNotificationTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTemplateUsingState: async (templateName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateName' is not null or undefined
            assertParamExists('putTemplateUsingState', 'templateName', templateName)
            const localVarPath = `/api/v1/notificationTemplates/{template-name}/active`
                .replace(`{${"template-name"}}`, encodeURIComponent(String(templateName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApiApi - functional programming interface
 * @export
 */
export const NotificationApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotificationEvent(eventId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteNotificationEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotificationEvent(eventId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(templateName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplate(templateName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNotificationEvent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetAllNotificationEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNotificationEvent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationEventById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetNotificationEventByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationEventById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationEventByTopic(topic: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetNotificationEventByTopicResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationEventByTopic(topic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} channelType 
         * @param {string} [eventType] 
         * @param {boolean} [inUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationTemplateList(channelType: string, eventType?: string, inUse?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetNotificationTemplateListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationTemplateList(channelType, eventType, inUse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} topic 
         * @param {PostNotificationRequest} postNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNotification(topic: string, postNotificationRequest: PostNotificationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostNotificationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNotification(topic, postNotificationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostNotificationEventRequest} postNotificationEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postNotificationEvent(postNotificationEventRequest: PostNotificationEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostNotificationEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postNotificationEvent(postNotificationEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostTemplateRequest} postTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTemplate(postTemplateRequest: PostTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTemplate(postTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} eventId 
         * @param {PutNotificationEventRequest} putNotificationEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotificationEvent(eventId: number, putNotificationEventRequest: PutNotificationEventRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutNotificationEventResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotificationEvent(eventId, putNotificationEventRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateName 
         * @param {PutNotificationTemplateRequest} putNotificationTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotificationTemplate(templateName: string, putNotificationTemplateRequest: PutNotificationTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutNotificationTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotificationTemplate(templateName, putNotificationTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTemplateUsingState(templateName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTemplateUsingStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTemplateUsingState(templateName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApiApi - factory interface
 * @export
 */
export const NotificationApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationEvent(eventId: number, options?: any): AxiosPromise<ApiResponseDeleteNotificationEventResponse> {
            return localVarFp.deleteNotificationEvent(eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(templateName: string, options?: any): AxiosPromise<ApiResponseDeleteTemplateResponse> {
            return localVarFp.deleteTemplate(templateName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNotificationEvent(options?: any): AxiosPromise<ApiResponseGetAllNotificationEventResponse> {
            return localVarFp.getAllNotificationEvent(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationEventById(id: number, options?: any): AxiosPromise<ApiResponseGetNotificationEventByIdResponse> {
            return localVarFp.getNotificationEventById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topic 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationEventByTopic(topic: string, options?: any): AxiosPromise<ApiResponseGetNotificationEventByTopicResponse> {
            return localVarFp.getNotificationEventByTopic(topic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} channelType 
         * @param {string} [eventType] 
         * @param {boolean} [inUse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationTemplateList(channelType: string, eventType?: string, inUse?: boolean, options?: any): AxiosPromise<ApiResponseGetNotificationTemplateListResponse> {
            return localVarFp.getNotificationTemplateList(channelType, eventType, inUse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} topic 
         * @param {PostNotificationRequest} postNotificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotification(topic: string, postNotificationRequest: PostNotificationRequest, options?: any): AxiosPromise<ApiResponsePostNotificationResponse> {
            return localVarFp.postNotification(topic, postNotificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostNotificationEventRequest} postNotificationEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postNotificationEvent(postNotificationEventRequest: PostNotificationEventRequest, options?: any): AxiosPromise<ApiResponsePostNotificationEventResponse> {
            return localVarFp.postNotificationEvent(postNotificationEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostTemplateRequest} postTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTemplate(postTemplateRequest: PostTemplateRequest, options?: any): AxiosPromise<ApiResponsePostTemplateResponse> {
            return localVarFp.postTemplate(postTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} eventId 
         * @param {PutNotificationEventRequest} putNotificationEventRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationEvent(eventId: number, putNotificationEventRequest: PutNotificationEventRequest, options?: any): AxiosPromise<ApiResponsePutNotificationEventResponse> {
            return localVarFp.putNotificationEvent(eventId, putNotificationEventRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateName 
         * @param {PutNotificationTemplateRequest} putNotificationTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationTemplate(templateName: string, putNotificationTemplateRequest: PutNotificationTemplateRequest, options?: any): AxiosPromise<ApiResponsePutNotificationTemplateResponse> {
            return localVarFp.putNotificationTemplate(templateName, putNotificationTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} templateName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTemplateUsingState(templateName: string, options?: any): AxiosPromise<ApiResponsePutTemplateUsingStateResponse> {
            return localVarFp.putTemplateUsingState(templateName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApiApi - object-oriented interface
 * @export
 * @class NotificationApiApi
 * @extends {BaseAPI}
 */
export class NotificationApiApi extends BaseAPI {
    /**
     * 
     * @param {number} eventId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public deleteNotificationEvent(eventId: number, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).deleteNotificationEvent(eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public deleteTemplate(templateName: string, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).deleteTemplate(templateName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public getAllNotificationEvent(options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).getAllNotificationEvent(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public getNotificationEventById(id: number, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).getNotificationEventById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} topic 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public getNotificationEventByTopic(topic: string, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).getNotificationEventByTopic(topic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} channelType 
     * @param {string} [eventType] 
     * @param {boolean} [inUse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public getNotificationTemplateList(channelType: string, eventType?: string, inUse?: boolean, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).getNotificationTemplateList(channelType, eventType, inUse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} topic 
     * @param {PostNotificationRequest} postNotificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public postNotification(topic: string, postNotificationRequest: PostNotificationRequest, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).postNotification(topic, postNotificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostNotificationEventRequest} postNotificationEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public postNotificationEvent(postNotificationEventRequest: PostNotificationEventRequest, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).postNotificationEvent(postNotificationEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostTemplateRequest} postTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public postTemplate(postTemplateRequest: PostTemplateRequest, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).postTemplate(postTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} eventId 
     * @param {PutNotificationEventRequest} putNotificationEventRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public putNotificationEvent(eventId: number, putNotificationEventRequest: PutNotificationEventRequest, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).putNotificationEvent(eventId, putNotificationEventRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateName 
     * @param {PutNotificationTemplateRequest} putNotificationTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public putNotificationTemplate(templateName: string, putNotificationTemplateRequest: PutNotificationTemplateRequest, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).putNotificationTemplate(templateName, putNotificationTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} templateName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApiApi
     */
    public putTemplateUsingState(templateName: string, options?: AxiosRequestConfig) {
        return NotificationApiApiFp(this.configuration).putTemplateUsingState(templateName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PluginApiApi - axios parameter creator
 * @export
 */
export const PluginApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} includeConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPluginById: async (id: number, includeConfig: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPluginById', 'id', id)
            // verify required parameter 'includeConfig' is not null or undefined
            assertParamExists('getPluginById', 'includeConfig', includeConfig)
            const localVarPath = `/api/v1/plugins/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeConfig !== undefined) {
                localVarQueryParameter['include-config'] = includeConfig;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPluginList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/plugins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostPluginRequest} postPluginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPlugin: async (postPluginRequest: PostPluginRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postPluginRequest' is not null or undefined
            assertParamExists('postPlugin', 'postPluginRequest', postPluginRequest)
            const localVarPath = `/api/v1/plugins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPluginRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PostPluginConfigureRequest} postPluginConfigureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPluginConfigure: async (id: number, postPluginConfigureRequest: PostPluginConfigureRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postPluginConfigure', 'id', id)
            // verify required parameter 'postPluginConfigureRequest' is not null or undefined
            assertParamExists('postPluginConfigure', 'postPluginConfigureRequest', postPluginConfigureRequest)
            const localVarPath = `/api/v1/plugins/{id}/configure`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPluginConfigureRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PluginApiApi - functional programming interface
 * @export
 */
export const PluginApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PluginApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} includeConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPluginById(id: number, includeConfig: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetPluginByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPluginById(id, includeConfig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPluginList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetPluginListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPluginList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostPluginRequest} postPluginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPlugin(postPluginRequest: PostPluginRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostPluginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPlugin(postPluginRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PostPluginConfigureRequest} postPluginConfigureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPluginConfigure(id: number, postPluginConfigureRequest: PostPluginConfigureRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostPluginConfigureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPluginConfigure(id, postPluginConfigureRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PluginApiApi - factory interface
 * @export
 */
export const PluginApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PluginApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} includeConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPluginById(id: number, includeConfig: boolean, options?: any): AxiosPromise<ApiResponseGetPluginByIdResponse> {
            return localVarFp.getPluginById(id, includeConfig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPluginList(options?: any): AxiosPromise<ApiResponseGetPluginListResponse> {
            return localVarFp.getPluginList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostPluginRequest} postPluginRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPlugin(postPluginRequest: PostPluginRequest, options?: any): AxiosPromise<ApiResponsePostPluginResponse> {
            return localVarFp.postPlugin(postPluginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PostPluginConfigureRequest} postPluginConfigureRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPluginConfigure(id: number, postPluginConfigureRequest: PostPluginConfigureRequest, options?: any): AxiosPromise<ApiResponsePostPluginConfigureResponse> {
            return localVarFp.postPluginConfigure(id, postPluginConfigureRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PluginApiApi - object-oriented interface
 * @export
 * @class PluginApiApi
 * @extends {BaseAPI}
 */
export class PluginApiApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {boolean} includeConfig 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginApiApi
     */
    public getPluginById(id: number, includeConfig: boolean, options?: AxiosRequestConfig) {
        return PluginApiApiFp(this.configuration).getPluginById(id, includeConfig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginApiApi
     */
    public getPluginList(options?: AxiosRequestConfig) {
        return PluginApiApiFp(this.configuration).getPluginList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostPluginRequest} postPluginRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginApiApi
     */
    public postPlugin(postPluginRequest: PostPluginRequest, options?: AxiosRequestConfig) {
        return PluginApiApiFp(this.configuration).postPlugin(postPluginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PostPluginConfigureRequest} postPluginConfigureRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PluginApiApi
     */
    public postPluginConfigure(id: number, postPluginConfigureRequest: PostPluginConfigureRequest, options?: AxiosRequestConfig) {
        return PluginApiApiFp(this.configuration).postPluginConfigure(id, postPluginConfigureRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PolicyApiApi - axios parameter creator
 * @export
 */
export const PolicyApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [withPrivilege] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicy: async (id: number, withPrivilege?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPolicy', 'id', id)
            const localVarPath = `/api/v1/policies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (withPrivilege !== undefined) {
                localVarQueryParameter['withPrivilege'] = withPrivilege;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PolicyApiApi - functional programming interface
 * @export
 */
export const PolicyApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PolicyApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [withPrivilege] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicy(id: number, withPrivilege?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetPolicyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicy(id, withPrivilege, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicyList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetPolicyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicyList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PolicyApiApi - factory interface
 * @export
 */
export const PolicyApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PolicyApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [withPrivilege] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicy(id: number, withPrivilege?: boolean, options?: any): AxiosPromise<ApiResponseGetPolicyResponse> {
            return localVarFp.getPolicy(id, withPrivilege, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyList(options?: any): AxiosPromise<ApiResponseGetPolicyListResponse> {
            return localVarFp.getPolicyList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PolicyApiApi - object-oriented interface
 * @export
 * @class PolicyApiApi
 * @extends {BaseAPI}
 */
export class PolicyApiApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {boolean} [withPrivilege] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiApi
     */
    public getPolicy(id: number, withPrivilege?: boolean, options?: AxiosRequestConfig) {
        return PolicyApiApiFp(this.configuration).getPolicy(id, withPrivilege, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PolicyApiApi
     */
    public getPolicyList(options?: AxiosRequestConfig) {
        return PolicyApiApiFp(this.configuration).getPolicyList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivilegesApiApi - axios parameter creator
 * @export
 */
export const PrivilegesApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivilegeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/privileges`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivilegesApiApi - functional programming interface
 * @export
 */
export const PrivilegesApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivilegesApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrivilegeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetPrivilegeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrivilegeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivilegesApiApi - factory interface
 * @export
 */
export const PrivilegesApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivilegesApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrivilegeList(options?: any): AxiosPromise<ApiResponseGetPrivilegeListResponse> {
            return localVarFp.getPrivilegeList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrivilegesApiApi - object-oriented interface
 * @export
 * @class PrivilegesApiApi
 * @extends {BaseAPI}
 */
export class PrivilegesApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivilegesApiApi
     */
    public getPrivilegeList(options?: AxiosRequestConfig) {
        return PrivilegesApiApiFp(this.configuration).getPrivilegeList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductivityApiApi - axios parameter creator
 * @export
 */
export const ProductivityApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateNewTagRequest} createNewTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewTag: async (createNewTagRequest: CreateNewTagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNewTagRequest' is not null or undefined
            assertParamExists('createNewTag', 'createNewTagRequest', createNewTagRequest)
            const localVarPath = `/api/v1/productivity/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNewTagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcess: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProcess', 'id', id)
            const localVarPath = `/api/v1/productivity/processes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/productivity/processes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagById', 'id', id)
            const localVarPath = `/api/v1/productivity/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/productivity/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketTemplates: async (includeDisabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/productivity/ticket-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeDisabled !== undefined) {
                localVarQueryParameter['include-disabled'] = includeDisabled;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PostProcessRequest} postProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProcess: async (id: number, postProcessRequest: PostProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postProcess', 'id', id)
            // verify required parameter 'postProcessRequest' is not null or undefined
            assertParamExists('postProcess', 'postProcessRequest', postProcessRequest)
            const localVarPath = `/api/v1/productivity/processes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostTicketTemplateRequest} postTicketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicketTemplate: async (postTicketTemplateRequest: PostTicketTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTicketTemplateRequest' is not null or undefined
            assertParamExists('postTicketTemplate', 'postTicketTemplateRequest', postTicketTemplateRequest)
            const localVarPath = `/api/v1/productivity/ticket-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTicketTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutNewProcessRequest} putNewProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNewProcess: async (putNewProcessRequest: PutNewProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putNewProcessRequest' is not null or undefined
            assertParamExists('putNewProcess', 'putNewProcessRequest', putNewProcessRequest)
            const localVarPath = `/api/v1/productivity/processes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putNewProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {PuTicketTemplateRequest} puTicketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketTemplate: async (templateId: number, puTicketTemplateRequest: PuTicketTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('putTicketTemplate', 'templateId', templateId)
            // verify required parameter 'puTicketTemplateRequest' is not null or undefined
            assertParamExists('putTicketTemplate', 'puTicketTemplateRequest', puTicketTemplateRequest)
            const localVarPath = `/api/v1/productivity/ticket-templates/{template-id}`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(puTicketTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketTemplateDisabled: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('putTicketTemplateDisabled', 'templateId', templateId)
            const localVarPath = `/api/v1/productivity/ticket-templates/{template-id}/disabled`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketTemplateEnabled: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('putTicketTemplateEnabled', 'templateId', templateId)
            const localVarPath = `/api/v1/productivity/ticket-templates/{template-id}/enabled`
                .replace(`{${"template-id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostVerifyProcessRequest} postVerifyProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProcessRequest: async (postVerifyProcessRequest: PostVerifyProcessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postVerifyProcessRequest' is not null or undefined
            assertParamExists('verifyProcessRequest', 'postVerifyProcessRequest', postVerifyProcessRequest)
            const localVarPath = `/api/v1/productivity/processes/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postVerifyProcessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductivityApiApi - functional programming interface
 * @export
 */
export const ProductivityApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductivityApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateNewTagRequest} createNewTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNewTag(createNewTagRequest: CreateNewTagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseCreateNewTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNewTag(createNewTagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcess(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcess(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProcessListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTagByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTagListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketTemplates(includeDisabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketTemplates(includeDisabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PostProcessRequest} postProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProcess(id: number, postProcessRequest: PostProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProcess(id, postProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostTicketTemplateRequest} postTicketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTicketTemplate(postTicketTemplateRequest: PostTicketTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostTicketTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTicketTemplate(postTicketTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutNewProcessRequest} putNewProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNewProcess(putNewProcessRequest: PutNewProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutNewProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNewProcess(putNewProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {PuTicketTemplateRequest} puTicketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTicketTemplate(templateId: number, puTicketTemplateRequest: PuTicketTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTicketTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTicketTemplate(templateId, puTicketTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTicketTemplateDisabled(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTicketTemplateDisabledResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTicketTemplateDisabled(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTicketTemplateEnabled(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTicketTemplateEnabledResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTicketTemplateEnabled(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostVerifyProcessRequest} postVerifyProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyProcessRequest(postVerifyProcessRequest: PostVerifyProcessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostVerifyProcessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyProcessRequest(postVerifyProcessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductivityApiApi - factory interface
 * @export
 */
export const ProductivityApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductivityApiApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateNewTagRequest} createNewTagRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewTag(createNewTagRequest: CreateNewTagRequest, options?: any): AxiosPromise<ApiResponseCreateNewTagResponse> {
            return localVarFp.createNewTag(createNewTagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcess(id: number, options?: any): AxiosPromise<ApiResponseGetProcessResponse> {
            return localVarFp.getProcess(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessList(options?: any): AxiosPromise<ApiResponseGetProcessListResponse> {
            return localVarFp.getProcessList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagById(id: number, options?: any): AxiosPromise<ApiResponseGetTagByIdResponse> {
            return localVarFp.getTagById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagList(options?: any): AxiosPromise<ApiResponseGetTagListResponse> {
            return localVarFp.getTagList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [includeDisabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketTemplates(includeDisabled?: boolean, options?: any): AxiosPromise<ApiResponseGetTicketTemplateResponse> {
            return localVarFp.getTicketTemplates(includeDisabled, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PostProcessRequest} postProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProcess(id: number, postProcessRequest: PostProcessRequest, options?: any): AxiosPromise<ApiResponsePostProcessResponse> {
            return localVarFp.postProcess(id, postProcessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostTicketTemplateRequest} postTicketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicketTemplate(postTicketTemplateRequest: PostTicketTemplateRequest, options?: any): AxiosPromise<ApiResponsePostTicketTemplateResponse> {
            return localVarFp.postTicketTemplate(postTicketTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutNewProcessRequest} putNewProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNewProcess(putNewProcessRequest: PutNewProcessRequest, options?: any): AxiosPromise<ApiResponsePutNewProcessResponse> {
            return localVarFp.putNewProcess(putNewProcessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {PuTicketTemplateRequest} puTicketTemplateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketTemplate(templateId: number, puTicketTemplateRequest: PuTicketTemplateRequest, options?: any): AxiosPromise<ApiResponsePutTicketTemplateResponse> {
            return localVarFp.putTicketTemplate(templateId, puTicketTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketTemplateDisabled(templateId: number, options?: any): AxiosPromise<ApiResponsePutTicketTemplateDisabledResponse> {
            return localVarFp.putTicketTemplateDisabled(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketTemplateEnabled(templateId: number, options?: any): AxiosPromise<ApiResponsePutTicketTemplateEnabledResponse> {
            return localVarFp.putTicketTemplateEnabled(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostVerifyProcessRequest} postVerifyProcessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyProcessRequest(postVerifyProcessRequest: PostVerifyProcessRequest, options?: any): AxiosPromise<ApiResponsePostVerifyProcessResponse> {
            return localVarFp.verifyProcessRequest(postVerifyProcessRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductivityApiApi - object-oriented interface
 * @export
 * @class ProductivityApiApi
 * @extends {BaseAPI}
 */
export class ProductivityApiApi extends BaseAPI {
    /**
     * 
     * @param {CreateNewTagRequest} createNewTagRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public createNewTag(createNewTagRequest: CreateNewTagRequest, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).createNewTag(createNewTagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public getProcess(id: number, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).getProcess(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public getProcessList(options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).getProcessList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public getTagById(id: number, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).getTagById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public getTagList(options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).getTagList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [includeDisabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public getTicketTemplates(includeDisabled?: boolean, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).getTicketTemplates(includeDisabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PostProcessRequest} postProcessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public postProcess(id: number, postProcessRequest: PostProcessRequest, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).postProcess(id, postProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostTicketTemplateRequest} postTicketTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public postTicketTemplate(postTicketTemplateRequest: PostTicketTemplateRequest, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).postTicketTemplate(postTicketTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutNewProcessRequest} putNewProcessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public putNewProcess(putNewProcessRequest: PutNewProcessRequest, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).putNewProcess(putNewProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {PuTicketTemplateRequest} puTicketTemplateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public putTicketTemplate(templateId: number, puTicketTemplateRequest: PuTicketTemplateRequest, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).putTicketTemplate(templateId, puTicketTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public putTicketTemplateDisabled(templateId: number, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).putTicketTemplateDisabled(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} templateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public putTicketTemplateEnabled(templateId: number, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).putTicketTemplateEnabled(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostVerifyProcessRequest} postVerifyProcessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductivityApiApi
     */
    public verifyProcessRequest(postVerifyProcessRequest: PostVerifyProcessRequest, options?: AxiosRequestConfig) {
        return ProductivityApiApiFp(this.configuration).verifyProcessRequest(postVerifyProcessRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectServiceInstanceApiApi - axios parameter creator
 * @export
 */
export const ProjectServiceInstanceApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} instanceId 
         * @param {ServiceInstanceChangeStatusRequest} serviceInstanceChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeServiceInstanceStatus: async (instanceId: number, serviceInstanceChangeStatusRequest: ServiceInstanceChangeStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('changeServiceInstanceStatus', 'instanceId', instanceId)
            // verify required parameter 'serviceInstanceChangeStatusRequest' is not null or undefined
            assertParamExists('changeServiceInstanceStatus', 'serviceInstanceChangeStatusRequest', serviceInstanceChangeStatusRequest)
            const localVarPath = `/api/v1/projectInstances/{instance-id}/changeStatus`
                .replace(`{${"instance-id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceInstanceChangeStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceWhetherFinished: async (instanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('checkServiceWhetherFinished', 'instanceId', instanceId)
            const localVarPath = `/api/v1/projectInstances/{instance-id}/checkFinished`
                .replace(`{${"instance-id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptProjectInstance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projectInstances/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemInstanceListOfServiceInstance: async (instanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getItemInstanceListOfServiceInstance', 'instanceId', instanceId)
            const localVarPath = `/api/v1/projectInstances/{instance-id}/items`
                .replace(`{${"instance-id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} participantType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectInstanceByParticipantAndParticipantType: async (participantType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'participantType' is not null or undefined
            assertParamExists('getProjectInstanceByParticipantAndParticipantType', 'participantType', participantType)
            const localVarPath = `/api/v1/projectInstances/participant/{participant-type}`
                .replace(`{${"participant-type"}}`, encodeURIComponent(String(participantType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [serviceId] 
         * @param {string} [title] 
         * @param {number} [subscriber] 
         * @param {number} [acceptor] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [description] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectInstanceList: async (id?: number, serviceId?: number, title?: string, subscriber?: number, acceptor?: number, startTime?: string, endTime?: string, description?: string, status?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projectInstances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (serviceId !== undefined) {
                localVarQueryParameter['serviceId'] = serviceId;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (subscriber !== undefined) {
                localVarQueryParameter['subscriber'] = subscriber;
            }

            if (acceptor !== undefined) {
                localVarQueryParameter['acceptor'] = acceptor;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectInstanceOfOwnerByStatus: async (status: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'status' is not null or undefined
            assertParamExists('getProjectInstanceOfOwnerByStatus', 'status', status)
            const localVarPath = `/api/v1/projectInstances/ofMyProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusChangeLog1: async (serviceInstanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceInstanceId' is not null or undefined
            assertParamExists('getStatusChangeLog1', 'serviceInstanceId', serviceInstanceId)
            const localVarPath = `/api/v1/projectInstances/{service-instance-id}/statusLogs`
                .replace(`{${"service-instance-id"}}`, encodeURIComponent(String(serviceInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscribedProjectInstance: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projectInstances/subscribed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskProgressInfo: async (serviceInstanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceInstanceId' is not null or undefined
            assertParamExists('getTaskProgressInfo', 'serviceInstanceId', serviceInstanceId)
            const localVarPath = `/api/v1/projectInstances/{service-instance-id}/taskProgress`
                .replace(`{${"service-instance-id"}}`, encodeURIComponent(String(serviceInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<number>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserParticipatingService: async (userId: number, statusList?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserParticipatingService', 'userId', userId)
            const localVarPath = `/api/v1/projectInstances/participants/{user-id}`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (statusList) {
                localVarQueryParameter['statusList'] = statusList;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkbenchData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projectInstances/workbench`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {PostExamineAndApproveServiceRequest} postExamineAndApproveServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExamineAndApproveService: async (instanceId: number, postExamineAndApproveServiceRequest: PostExamineAndApproveServiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('postExamineAndApproveService', 'instanceId', instanceId)
            // verify required parameter 'postExamineAndApproveServiceRequest' is not null or undefined
            assertParamExists('postExamineAndApproveService', 'postExamineAndApproveServiceRequest', postExamineAndApproveServiceRequest)
            const localVarPath = `/api/v1/projectInstances/{instance-id}/approve`
                .replace(`{${"instance-id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postExamineAndApproveServiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDraft: async (instanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('removeDraft', 'instanceId', instanceId)
            const localVarPath = `/api/v1/projectInstances/{instance-id}`
                .replace(`{${"instance-id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectServiceInstanceApiApi - functional programming interface
 * @export
 */
export const ProjectServiceInstanceApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectServiceInstanceApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} instanceId 
         * @param {ServiceInstanceChangeStatusRequest} serviceInstanceChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeServiceInstanceStatus(instanceId: number, serviceInstanceChangeStatusRequest: ServiceInstanceChangeStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseServiceInstanceChangeStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeServiceInstanceStatus(instanceId, serviceInstanceChangeStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkServiceWhetherFinished(instanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseProjectServiceInstanceCheckFinishedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkServiceWhetherFinished(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAcceptProjectInstance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetAcceptProjectInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAcceptProjectInstance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemInstanceListOfServiceInstance(instanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetItemInstanceListOfServiceInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemInstanceListOfServiceInstance(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} participantType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectInstanceByParticipantAndParticipantType(participantType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProjectInstanceByParticipantAndParticipantTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectInstanceByParticipantAndParticipantType(participantType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [serviceId] 
         * @param {string} [title] 
         * @param {number} [subscriber] 
         * @param {number} [acceptor] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [description] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectInstanceList(id?: number, serviceId?: number, title?: string, subscriber?: number, acceptor?: number, startTime?: string, endTime?: string, description?: string, status?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProjectInstanceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectInstanceList(id, serviceId, title, subscriber, acceptor, startTime, endTime, description, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectInstanceOfOwnerByStatus(status: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProjectInstanceOfOwnerByStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectInstanceOfOwnerByStatus(status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusChangeLog1(serviceInstanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetServiceInstanceStatusChangeLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusChangeLog1(serviceInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscribedProjectInstance(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetSubscribeProjectInstanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscribedProjectInstance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskProgressInfo(serviceInstanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTaskProgressInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskProgressInfo(serviceInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<number>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserParticipatingService(userId: number, statusList?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetUserParticipatingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserParticipatingService(userId, statusList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWorkbenchData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProjectWorkbenchDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkbenchData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {PostExamineAndApproveServiceRequest} postExamineAndApproveServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postExamineAndApproveService(instanceId: number, postExamineAndApproveServiceRequest: PostExamineAndApproveServiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostExamineAndApproveServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postExamineAndApproveService(instanceId, postExamineAndApproveServiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDraft(instanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseRemoveProjectServiceDraftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDraft(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectServiceInstanceApiApi - factory interface
 * @export
 */
export const ProjectServiceInstanceApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectServiceInstanceApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} instanceId 
         * @param {ServiceInstanceChangeStatusRequest} serviceInstanceChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeServiceInstanceStatus(instanceId: number, serviceInstanceChangeStatusRequest: ServiceInstanceChangeStatusRequest, options?: any): AxiosPromise<ApiResponseServiceInstanceChangeStatusResponse> {
            return localVarFp.changeServiceInstanceStatus(instanceId, serviceInstanceChangeStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceWhetherFinished(instanceId: number, options?: any): AxiosPromise<ApiResponseProjectServiceInstanceCheckFinishedModel> {
            return localVarFp.checkServiceWhetherFinished(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAcceptProjectInstance(options?: any): AxiosPromise<ApiResponseGetAcceptProjectInstanceResponse> {
            return localVarFp.getAcceptProjectInstance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemInstanceListOfServiceInstance(instanceId: number, options?: any): AxiosPromise<ApiResponseGetItemInstanceListOfServiceInstanceResponse> {
            return localVarFp.getItemInstanceListOfServiceInstance(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} participantType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectInstanceByParticipantAndParticipantType(participantType: number, options?: any): AxiosPromise<ApiResponseGetProjectInstanceByParticipantAndParticipantTypeResponse> {
            return localVarFp.getProjectInstanceByParticipantAndParticipantType(participantType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [serviceId] 
         * @param {string} [title] 
         * @param {number} [subscriber] 
         * @param {number} [acceptor] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {string} [description] 
         * @param {number} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectInstanceList(id?: number, serviceId?: number, title?: string, subscriber?: number, acceptor?: number, startTime?: string, endTime?: string, description?: string, status?: number, options?: any): AxiosPromise<ApiResponseGetProjectInstanceListResponse> {
            return localVarFp.getProjectInstanceList(id, serviceId, title, subscriber, acceptor, startTime, endTime, description, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectInstanceOfOwnerByStatus(status: Array<number>, options?: any): AxiosPromise<ApiResponseGetProjectInstanceOfOwnerByStatusResponse> {
            return localVarFp.getProjectInstanceOfOwnerByStatus(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusChangeLog1(serviceInstanceId: number, options?: any): AxiosPromise<ApiResponseGetServiceInstanceStatusChangeLogResponse> {
            return localVarFp.getStatusChangeLog1(serviceInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscribedProjectInstance(options?: any): AxiosPromise<ApiResponseGetSubscribeProjectInstanceResponse> {
            return localVarFp.getSubscribedProjectInstance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskProgressInfo(serviceInstanceId: number, options?: any): AxiosPromise<ApiResponseGetTaskProgressInfoResponse> {
            return localVarFp.getTaskProgressInfo(serviceInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {Array<number>} [statusList] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserParticipatingService(userId: number, statusList?: Array<number>, options?: any): AxiosPromise<ApiResponseGetUserParticipatingResponse> {
            return localVarFp.getUserParticipatingService(userId, statusList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWorkbenchData(options?: any): AxiosPromise<ApiResponseGetProjectWorkbenchDataResponse> {
            return localVarFp.getWorkbenchData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {PostExamineAndApproveServiceRequest} postExamineAndApproveServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postExamineAndApproveService(instanceId: number, postExamineAndApproveServiceRequest: PostExamineAndApproveServiceRequest, options?: any): AxiosPromise<ApiResponsePostExamineAndApproveServiceResponse> {
            return localVarFp.postExamineAndApproveService(instanceId, postExamineAndApproveServiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDraft(instanceId: number, options?: any): AxiosPromise<ApiResponseRemoveProjectServiceDraftResponse> {
            return localVarFp.removeDraft(instanceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectServiceInstanceApiApi - object-oriented interface
 * @export
 * @class ProjectServiceInstanceApiApi
 * @extends {BaseAPI}
 */
export class ProjectServiceInstanceApiApi extends BaseAPI {
    /**
     * 
     * @param {number} instanceId 
     * @param {ServiceInstanceChangeStatusRequest} serviceInstanceChangeStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public changeServiceInstanceStatus(instanceId: number, serviceInstanceChangeStatusRequest: ServiceInstanceChangeStatusRequest, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).changeServiceInstanceStatus(instanceId, serviceInstanceChangeStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} instanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public checkServiceWhetherFinished(instanceId: number, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).checkServiceWhetherFinished(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getAcceptProjectInstance(options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getAcceptProjectInstance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} instanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getItemInstanceListOfServiceInstance(instanceId: number, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getItemInstanceListOfServiceInstance(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} participantType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getProjectInstanceByParticipantAndParticipantType(participantType: number, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getProjectInstanceByParticipantAndParticipantType(participantType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {number} [serviceId] 
     * @param {string} [title] 
     * @param {number} [subscriber] 
     * @param {number} [acceptor] 
     * @param {string} [startTime] 
     * @param {string} [endTime] 
     * @param {string} [description] 
     * @param {number} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getProjectInstanceList(id?: number, serviceId?: number, title?: string, subscriber?: number, acceptor?: number, startTime?: string, endTime?: string, description?: string, status?: number, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getProjectInstanceList(id, serviceId, title, subscriber, acceptor, startTime, endTime, description, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} status 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getProjectInstanceOfOwnerByStatus(status: Array<number>, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getProjectInstanceOfOwnerByStatus(status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getStatusChangeLog1(serviceInstanceId: number, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getStatusChangeLog1(serviceInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getSubscribedProjectInstance(options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getSubscribedProjectInstance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getTaskProgressInfo(serviceInstanceId: number, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getTaskProgressInfo(serviceInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {Array<number>} [statusList] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getUserParticipatingService(userId: number, statusList?: Array<number>, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getUserParticipatingService(userId, statusList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public getWorkbenchData(options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).getWorkbenchData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} instanceId 
     * @param {PostExamineAndApproveServiceRequest} postExamineAndApproveServiceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public postExamineAndApproveService(instanceId: number, postExamineAndApproveServiceRequest: PostExamineAndApproveServiceRequest, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).postExamineAndApproveService(instanceId, postExamineAndApproveServiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} instanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceInstanceApiApi
     */
    public removeDraft(instanceId: number, options?: AxiosRequestConfig) {
        return ProjectServiceInstanceApiApiFp(this.configuration).removeDraft(instanceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectServiceItemInstanceApiApi - axios parameter creator
 * @export
 */
export const ProjectServiceItemInstanceApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {number} redoCount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryMaterial: async (itemInstanceId: number, redoCount: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemInstanceId' is not null or undefined
            assertParamExists('getDeliveryMaterial', 'itemInstanceId', itemInstanceId)
            // verify required parameter 'redoCount' is not null or undefined
            assertParamExists('getDeliveryMaterial', 'redoCount', redoCount)
            const localVarPath = `/api/v1/projectServiceInstances/{item-instance-id}/deliveryMaterials/{redo-count}`
                .replace(`{${"item-instance-id"}}`, encodeURIComponent(String(itemInstanceId)))
                .replace(`{${"redo-count"}}`, encodeURIComponent(String(redoCount)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemInstanceStatusMetaData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projectServiceInstances/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusChangeLog: async (itemInstanceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemInstanceId' is not null or undefined
            assertParamExists('getStatusChangeLog', 'itemInstanceId', itemInstanceId)
            const localVarPath = `/api/v1/projectServiceInstances/{item-instance-id}/statusLogs`
                .replace(`{${"item-instance-id"}}`, encodeURIComponent(String(itemInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemInstance 
         * @param {PostChangeExecutorRequest} postChangeExecutorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postChangeExecutor: async (itemInstance: number, postChangeExecutorRequest: PostChangeExecutorRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemInstance' is not null or undefined
            assertParamExists('postChangeExecutor', 'itemInstance', itemInstance)
            // verify required parameter 'postChangeExecutorRequest' is not null or undefined
            assertParamExists('postChangeExecutor', 'postChangeExecutorRequest', postChangeExecutorRequest)
            const localVarPath = `/api/v1/projectServiceInstances/{item-instance}/changeExecutor`
                .replace(`{${"item-instance"}}`, encodeURIComponent(String(itemInstance)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postChangeExecutorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {ServiceItemChangeStatusRequest} serviceItemChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postChangeServiceItemStatus: async (itemInstanceId: number, serviceItemChangeStatusRequest: ServiceItemChangeStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemInstanceId' is not null or undefined
            assertParamExists('postChangeServiceItemStatus', 'itemInstanceId', itemInstanceId)
            // verify required parameter 'serviceItemChangeStatusRequest' is not null or undefined
            assertParamExists('postChangeServiceItemStatus', 'serviceItemChangeStatusRequest', serviceItemChangeStatusRequest)
            const localVarPath = `/api/v1/projectServiceInstances/{item-instance-id}/changeStatus`
                .replace(`{${"item-instance-id"}}`, encodeURIComponent(String(itemInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(serviceItemChangeStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {PostCommitDeliveryMaterialRequest} postCommitDeliveryMaterialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommitDeliveryMaterial: async (itemInstanceId: number, postCommitDeliveryMaterialRequest: PostCommitDeliveryMaterialRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemInstanceId' is not null or undefined
            assertParamExists('postCommitDeliveryMaterial', 'itemInstanceId', itemInstanceId)
            // verify required parameter 'postCommitDeliveryMaterialRequest' is not null or undefined
            assertParamExists('postCommitDeliveryMaterial', 'postCommitDeliveryMaterialRequest', postCommitDeliveryMaterialRequest)
            const localVarPath = `/api/v1/projectServiceInstances/{item-instance-id}/deliveryMaterials`
                .replace(`{${"item-instance-id"}}`, encodeURIComponent(String(itemInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCommitDeliveryMaterialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} preItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStartFollowupItem: async (preItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preItemId' is not null or undefined
            assertParamExists('postStartFollowupItem', 'preItemId', preItemId)
            const localVarPath = `/api/v1/projectServiceInstances/{pre-item-id}/startFollowup`
                .replace(`{${"pre-item-id"}}`, encodeURIComponent(String(preItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {RemoveDeliveryMaterialRequest} removeDeliveryMaterialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeliveryMaterial: async (itemInstanceId: number, removeDeliveryMaterialRequest: RemoveDeliveryMaterialRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemInstanceId' is not null or undefined
            assertParamExists('removeDeliveryMaterial', 'itemInstanceId', itemInstanceId)
            // verify required parameter 'removeDeliveryMaterialRequest' is not null or undefined
            assertParamExists('removeDeliveryMaterial', 'removeDeliveryMaterialRequest', removeDeliveryMaterialRequest)
            const localVarPath = `/api/v1/projectServiceInstances/{item-instance-id}/deliveryMaterials`
                .replace(`{${"item-instance-id"}}`, encodeURIComponent(String(itemInstanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(removeDeliveryMaterialRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectServiceItemInstanceApiApi - functional programming interface
 * @export
 */
export const ProjectServiceItemInstanceApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectServiceItemInstanceApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {number} redoCount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeliveryMaterial(itemInstanceId: number, redoCount: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetServiceInstanceDeliveryMaterialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeliveryMaterial(itemInstanceId, redoCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemInstanceStatusMetaData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetItemInstanceStatusMetaDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemInstanceStatusMetaData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatusChangeLog(itemInstanceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetItemInstanceStatusChangeLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatusChangeLog(itemInstanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemInstance 
         * @param {PostChangeExecutorRequest} postChangeExecutorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postChangeExecutor(itemInstance: number, postChangeExecutorRequest: PostChangeExecutorRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostChangeExecutorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postChangeExecutor(itemInstance, postChangeExecutorRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {ServiceItemChangeStatusRequest} serviceItemChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postChangeServiceItemStatus(itemInstanceId: number, serviceItemChangeStatusRequest: ServiceItemChangeStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostServiceItemChangeStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postChangeServiceItemStatus(itemInstanceId, serviceItemChangeStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {PostCommitDeliveryMaterialRequest} postCommitDeliveryMaterialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCommitDeliveryMaterial(itemInstanceId: number, postCommitDeliveryMaterialRequest: PostCommitDeliveryMaterialRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostCommitDeliveryMaterialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCommitDeliveryMaterial(itemInstanceId, postCommitDeliveryMaterialRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} preItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStartFollowupItem(preItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostStartFollowupItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStartFollowupItem(preItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {RemoveDeliveryMaterialRequest} removeDeliveryMaterialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDeliveryMaterial(itemInstanceId: number, removeDeliveryMaterialRequest: RemoveDeliveryMaterialRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseRemoveDeliveryMaterialResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDeliveryMaterial(itemInstanceId, removeDeliveryMaterialRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectServiceItemInstanceApiApi - factory interface
 * @export
 */
export const ProjectServiceItemInstanceApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectServiceItemInstanceApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {number} redoCount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryMaterial(itemInstanceId: number, redoCount: number, options?: any): AxiosPromise<ApiResponseGetServiceInstanceDeliveryMaterialResponse> {
            return localVarFp.getDeliveryMaterial(itemInstanceId, redoCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemInstanceStatusMetaData(options?: any): AxiosPromise<ApiResponseGetItemInstanceStatusMetaDataResponse> {
            return localVarFp.getItemInstanceStatusMetaData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusChangeLog(itemInstanceId: number, options?: any): AxiosPromise<ApiResponseGetItemInstanceStatusChangeLogResponse> {
            return localVarFp.getStatusChangeLog(itemInstanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemInstance 
         * @param {PostChangeExecutorRequest} postChangeExecutorRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postChangeExecutor(itemInstance: number, postChangeExecutorRequest: PostChangeExecutorRequest, options?: any): AxiosPromise<ApiResponsePostChangeExecutorResponse> {
            return localVarFp.postChangeExecutor(itemInstance, postChangeExecutorRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {ServiceItemChangeStatusRequest} serviceItemChangeStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postChangeServiceItemStatus(itemInstanceId: number, serviceItemChangeStatusRequest: ServiceItemChangeStatusRequest, options?: any): AxiosPromise<ApiResponsePostServiceItemChangeStatusResponse> {
            return localVarFp.postChangeServiceItemStatus(itemInstanceId, serviceItemChangeStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {PostCommitDeliveryMaterialRequest} postCommitDeliveryMaterialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommitDeliveryMaterial(itemInstanceId: number, postCommitDeliveryMaterialRequest: PostCommitDeliveryMaterialRequest, options?: any): AxiosPromise<ApiResponsePostCommitDeliveryMaterialResponse> {
            return localVarFp.postCommitDeliveryMaterial(itemInstanceId, postCommitDeliveryMaterialRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} preItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStartFollowupItem(preItemId: number, options?: any): AxiosPromise<ApiResponsePostStartFollowupItemResponse> {
            return localVarFp.postStartFollowupItem(preItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemInstanceId 
         * @param {RemoveDeliveryMaterialRequest} removeDeliveryMaterialRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDeliveryMaterial(itemInstanceId: number, removeDeliveryMaterialRequest: RemoveDeliveryMaterialRequest, options?: any): AxiosPromise<ApiResponseRemoveDeliveryMaterialResponse> {
            return localVarFp.removeDeliveryMaterial(itemInstanceId, removeDeliveryMaterialRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectServiceItemInstanceApiApi - object-oriented interface
 * @export
 * @class ProjectServiceItemInstanceApiApi
 * @extends {BaseAPI}
 */
export class ProjectServiceItemInstanceApiApi extends BaseAPI {
    /**
     * 
     * @param {number} itemInstanceId 
     * @param {number} redoCount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemInstanceApiApi
     */
    public getDeliveryMaterial(itemInstanceId: number, redoCount: number, options?: AxiosRequestConfig) {
        return ProjectServiceItemInstanceApiApiFp(this.configuration).getDeliveryMaterial(itemInstanceId, redoCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemInstanceApiApi
     */
    public getItemInstanceStatusMetaData(options?: AxiosRequestConfig) {
        return ProjectServiceItemInstanceApiApiFp(this.configuration).getItemInstanceStatusMetaData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemInstanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemInstanceApiApi
     */
    public getStatusChangeLog(itemInstanceId: number, options?: AxiosRequestConfig) {
        return ProjectServiceItemInstanceApiApiFp(this.configuration).getStatusChangeLog(itemInstanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemInstance 
     * @param {PostChangeExecutorRequest} postChangeExecutorRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemInstanceApiApi
     */
    public postChangeExecutor(itemInstance: number, postChangeExecutorRequest: PostChangeExecutorRequest, options?: AxiosRequestConfig) {
        return ProjectServiceItemInstanceApiApiFp(this.configuration).postChangeExecutor(itemInstance, postChangeExecutorRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemInstanceId 
     * @param {ServiceItemChangeStatusRequest} serviceItemChangeStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemInstanceApiApi
     */
    public postChangeServiceItemStatus(itemInstanceId: number, serviceItemChangeStatusRequest: ServiceItemChangeStatusRequest, options?: AxiosRequestConfig) {
        return ProjectServiceItemInstanceApiApiFp(this.configuration).postChangeServiceItemStatus(itemInstanceId, serviceItemChangeStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemInstanceId 
     * @param {PostCommitDeliveryMaterialRequest} postCommitDeliveryMaterialRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemInstanceApiApi
     */
    public postCommitDeliveryMaterial(itemInstanceId: number, postCommitDeliveryMaterialRequest: PostCommitDeliveryMaterialRequest, options?: AxiosRequestConfig) {
        return ProjectServiceItemInstanceApiApiFp(this.configuration).postCommitDeliveryMaterial(itemInstanceId, postCommitDeliveryMaterialRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} preItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemInstanceApiApi
     */
    public postStartFollowupItem(preItemId: number, options?: AxiosRequestConfig) {
        return ProjectServiceItemInstanceApiApiFp(this.configuration).postStartFollowupItem(preItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemInstanceId 
     * @param {RemoveDeliveryMaterialRequest} removeDeliveryMaterialRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemInstanceApiApi
     */
    public removeDeliveryMaterial(itemInstanceId: number, removeDeliveryMaterialRequest: RemoveDeliveryMaterialRequest, options?: AxiosRequestConfig) {
        return ProjectServiceItemInstanceApiApiFp(this.configuration).removeDeliveryMaterial(itemInstanceId, removeDeliveryMaterialRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectServiceItemTagApiApi - axios parameter creator
 * @export
 */
export const ProjectServiceItemTagApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProjectServiceTagModel} projectServiceTagModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTag: async (projectServiceTagModel: ProjectServiceTagModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectServiceTagModel' is not null or undefined
            assertParamExists('addTag', 'projectServiceTagModel', projectServiceTagModel)
            const localVarPath = `/api/v1/projectServiceTags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectServiceTagModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {ProjectServiceItemTagForItemRequest} projectServiceItemTagForItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagForServiceItemInstance: async (itemId: number, projectServiceItemTagForItemRequest: ProjectServiceItemTagForItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('addTagForServiceItemInstance', 'itemId', itemId)
            // verify required parameter 'projectServiceItemTagForItemRequest' is not null or undefined
            assertParamExists('addTagForServiceItemInstance', 'projectServiceItemTagForItemRequest', projectServiceItemTagForItemRequest)
            const localVarPath = `/api/v1/projectServiceTags/itemInstanceTags/{item-id}`
                .replace(`{${"item-id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectServiceItemTagForItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectServiceItemInstanceTags: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('listProjectServiceItemInstanceTags', 'itemId', itemId)
            const localVarPath = `/api/v1/projectServiceTags/itemTags/{item-id}`
                .replace(`{${"item-id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [color] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTag: async (id?: number, color?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projectServiceTags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (color !== undefined) {
                localVarQueryParameter['color'] = color;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag: async (tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('removeTag', 'tagId', tagId)
            const localVarPath = `/api/v1/projectServiceTags/{tag-id}`
                .replace(`{${"tag-id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {ProjectServiceItemTagForItemRequest} projectServiceItemTagForItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTagForServiceItem: async (itemId: number, projectServiceItemTagForItemRequest: ProjectServiceItemTagForItemRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('removeTagForServiceItem', 'itemId', itemId)
            // verify required parameter 'projectServiceItemTagForItemRequest' is not null or undefined
            assertParamExists('removeTagForServiceItem', 'projectServiceItemTagForItemRequest', projectServiceItemTagForItemRequest)
            const localVarPath = `/api/v1/projectServiceTags/itemInstanceTags/{item-id}`
                .replace(`{${"item-id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectServiceItemTagForItemRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId 
         * @param {ProjectServiceTagModel} projectServiceTagModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag: async (tagId: number, projectServiceTagModel: ProjectServiceTagModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('updateTag', 'tagId', tagId)
            // verify required parameter 'projectServiceTagModel' is not null or undefined
            assertParamExists('updateTag', 'projectServiceTagModel', projectServiceTagModel)
            const localVarPath = `/api/v1/projectServiceTags/{tag-id}`
                .replace(`{${"tag-id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectServiceTagModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectServiceItemTagApiApi - functional programming interface
 * @export
 */
export const ProjectServiceItemTagApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectServiceItemTagApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProjectServiceTagModel} projectServiceTagModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTag(projectServiceTagModel: ProjectServiceTagModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseProjectServiceTagModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTag(projectServiceTagModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {ProjectServiceItemTagForItemRequest} projectServiceItemTagForItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTagForServiceItemInstance(itemId: number, projectServiceItemTagForItemRequest: ProjectServiceItemTagForItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTagForServiceItemInstance(itemId, projectServiceItemTagForItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProjectServiceItemInstanceTags(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseProjectGetServiceItemTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProjectServiceItemInstanceTags(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [color] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTag(id?: number, color?: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseProjectGetServiceListTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTag(id, color, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTag(tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTag(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {ProjectServiceItemTagForItemRequest} projectServiceItemTagForItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTagForServiceItem(itemId: number, projectServiceItemTagForItemRequest: ProjectServiceItemTagForItemRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTagForServiceItem(itemId, projectServiceItemTagForItemRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId 
         * @param {ProjectServiceTagModel} projectServiceTagModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTag(tagId: number, projectServiceTagModel: ProjectServiceTagModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTag(tagId, projectServiceTagModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectServiceItemTagApiApi - factory interface
 * @export
 */
export const ProjectServiceItemTagApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectServiceItemTagApiApiFp(configuration)
    return {
        /**
         * 
         * @param {ProjectServiceTagModel} projectServiceTagModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTag(projectServiceTagModel: ProjectServiceTagModel, options?: any): AxiosPromise<ApiResponseProjectServiceTagModel> {
            return localVarFp.addTag(projectServiceTagModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {ProjectServiceItemTagForItemRequest} projectServiceItemTagForItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagForServiceItemInstance(itemId: number, projectServiceItemTagForItemRequest: ProjectServiceItemTagForItemRequest, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.addTagForServiceItemInstance(itemId, projectServiceItemTagForItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjectServiceItemInstanceTags(itemId: number, options?: any): AxiosPromise<ApiResponseProjectGetServiceItemTagResponse> {
            return localVarFp.listProjectServiceItemInstanceTags(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [color] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTag(id?: number, color?: string, name?: string, options?: any): AxiosPromise<ApiResponseProjectGetServiceListTagResponse> {
            return localVarFp.listTag(id, color, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTag(tagId: number, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.removeTag(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {ProjectServiceItemTagForItemRequest} projectServiceItemTagForItemRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTagForServiceItem(itemId: number, projectServiceItemTagForItemRequest: ProjectServiceItemTagForItemRequest, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.removeTagForServiceItem(itemId, projectServiceItemTagForItemRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tagId 
         * @param {ProjectServiceTagModel} projectServiceTagModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTag(tagId: number, projectServiceTagModel: ProjectServiceTagModel, options?: any): AxiosPromise<ApiResponseBoolean> {
            return localVarFp.updateTag(tagId, projectServiceTagModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectServiceItemTagApiApi - object-oriented interface
 * @export
 * @class ProjectServiceItemTagApiApi
 * @extends {BaseAPI}
 */
export class ProjectServiceItemTagApiApi extends BaseAPI {
    /**
     * 
     * @param {ProjectServiceTagModel} projectServiceTagModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemTagApiApi
     */
    public addTag(projectServiceTagModel: ProjectServiceTagModel, options?: AxiosRequestConfig) {
        return ProjectServiceItemTagApiApiFp(this.configuration).addTag(projectServiceTagModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {ProjectServiceItemTagForItemRequest} projectServiceItemTagForItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemTagApiApi
     */
    public addTagForServiceItemInstance(itemId: number, projectServiceItemTagForItemRequest: ProjectServiceItemTagForItemRequest, options?: AxiosRequestConfig) {
        return ProjectServiceItemTagApiApiFp(this.configuration).addTagForServiceItemInstance(itemId, projectServiceItemTagForItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemTagApiApi
     */
    public listProjectServiceItemInstanceTags(itemId: number, options?: AxiosRequestConfig) {
        return ProjectServiceItemTagApiApiFp(this.configuration).listProjectServiceItemInstanceTags(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [color] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemTagApiApi
     */
    public listTag(id?: number, color?: string, name?: string, options?: AxiosRequestConfig) {
        return ProjectServiceItemTagApiApiFp(this.configuration).listTag(id, color, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemTagApiApi
     */
    public removeTag(tagId: number, options?: AxiosRequestConfig) {
        return ProjectServiceItemTagApiApiFp(this.configuration).removeTag(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {ProjectServiceItemTagForItemRequest} projectServiceItemTagForItemRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemTagApiApi
     */
    public removeTagForServiceItem(itemId: number, projectServiceItemTagForItemRequest: ProjectServiceItemTagForItemRequest, options?: AxiosRequestConfig) {
        return ProjectServiceItemTagApiApiFp(this.configuration).removeTagForServiceItem(itemId, projectServiceItemTagForItemRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} tagId 
     * @param {ProjectServiceTagModel} projectServiceTagModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceItemTagApiApi
     */
    public updateTag(tagId: number, projectServiceTagModel: ProjectServiceTagModel, options?: AxiosRequestConfig) {
        return ProjectServiceItemTagApiApiFp(this.configuration).updateTag(tagId, projectServiceTagModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectServiceMetaDataApiApi - axios parameter creator
 * @export
 */
export const ProjectServiceMetaDataApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectDraft: async (draftId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'draftId' is not null or undefined
            assertParamExists('deleteProjectDraft', 'draftId', draftId)
            const localVarPath = `/api/v1/projectServices/{draft-id}/delDraft`
                .replace(`{${"draft-id"}}`, encodeURIComponent(String(draftId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {DeleteTaskKnowledgeRequest} deleteTaskKnowledgeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskKnowledge: async (itemId: number, deleteTaskKnowledgeRequest: DeleteTaskKnowledgeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteTaskKnowledge', 'itemId', itemId)
            // verify required parameter 'deleteTaskKnowledgeRequest' is not null or undefined
            assertParamExists('deleteTaskKnowledge', 'deleteTaskKnowledgeRequest', deleteTaskKnowledgeRequest)
            const localVarPath = `/api/v1/projectServices/items/{item-id}/knowledge`
                .replace(`{${"item-id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTaskKnowledgeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemKnowledge: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('getItemKnowledge', 'itemId', itemId)
            const localVarPath = `/api/v1/projectServices/items/{item-id}/knowledge`
                .replace(`{${"item-id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getItems', 'serviceId', serviceId)
            const localVarPath = `/api/v1/projectServices/{service-id}/items`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [owner] 
         * @param {string} [title] 
         * @param {number} [status] 
         * @param {string} [description] 
         * @param {number} [createdBy] 
         * @param {number} [updatedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetaDataList: async (id?: number, owner?: number, title?: string, status?: number, description?: string, createdBy?: number, updatedBy?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projectServices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['createdBy'] = createdBy;
            }

            if (updatedBy !== undefined) {
                localVarQueryParameter['updatedBy'] = updatedBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessTemplate: async (serviceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getProcessTemplate', 'serviceId', serviceId)
            const localVarPath = `/api/v1/projectServices/{service-id}/template`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectStatusMetaData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/projectServices/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {PostCommitItemKnowledgeRequest} postCommitItemKnowledgeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommitItemKnowledge: async (itemId: number, postCommitItemKnowledgeRequest: PostCommitItemKnowledgeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('postCommitItemKnowledge', 'itemId', itemId)
            // verify required parameter 'postCommitItemKnowledgeRequest' is not null or undefined
            assertParamExists('postCommitItemKnowledge', 'postCommitItemKnowledgeRequest', postCommitItemKnowledgeRequest)
            const localVarPath = `/api/v1/projectServices/items/{item-id}/knowledge`
                .replace(`{${"item-id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCommitItemKnowledgeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostProjectServiceMetaRequest} postProjectServiceMetaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectService: async (postProjectServiceMetaRequest: PostProjectServiceMetaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postProjectServiceMetaRequest' is not null or undefined
            assertParamExists('postProjectService', 'postProjectServiceMetaRequest', postProjectServiceMetaRequest)
            const localVarPath = `/api/v1/projectServices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProjectServiceMetaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostProjectServiceDraftRequest} postProjectServiceDraftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectServiceDraft: async (postProjectServiceDraftRequest: PostProjectServiceDraftRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postProjectServiceDraftRequest' is not null or undefined
            assertParamExists('postProjectServiceDraft', 'postProjectServiceDraftRequest', postProjectServiceDraftRequest)
            const localVarPath = `/api/v1/projectServices/drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProjectServiceDraftRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PostProjectServiceStatusRequest} postProjectServiceStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectServiceStatus: async (serviceId: number, postProjectServiceStatusRequest: PostProjectServiceStatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postProjectServiceStatus', 'serviceId', serviceId)
            // verify required parameter 'postProjectServiceStatusRequest' is not null or undefined
            assertParamExists('postProjectServiceStatus', 'postProjectServiceStatusRequest', postProjectServiceStatusRequest)
            const localVarPath = `/api/v1/projectServices/{service-id}/changeStatus`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProjectServiceStatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PostServiceInstanceSubscribeServiceRequest} postServiceInstanceSubscribeServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubscribeService: async (serviceId: number, postServiceInstanceSubscribeServiceRequest: PostServiceInstanceSubscribeServiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('postSubscribeService', 'serviceId', serviceId)
            // verify required parameter 'postServiceInstanceSubscribeServiceRequest' is not null or undefined
            assertParamExists('postSubscribeService', 'postServiceInstanceSubscribeServiceRequest', postServiceInstanceSubscribeServiceRequest)
            const localVarPath = `/api/v1/projectServices/{service-id}/subscribe`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postServiceInstanceSubscribeServiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {PutItemsRequest} putItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putItem: async (itemId: number, putItemsRequest: PutItemsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('putItem', 'itemId', itemId)
            // verify required parameter 'putItemsRequest' is not null or undefined
            assertParamExists('putItem', 'putItemsRequest', putItemsRequest)
            const localVarPath = `/api/v1/projectServices/items/{item-id}`
                .replace(`{${"item-id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putItemsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PutProjectServiceMetaRequest} putProjectServiceMetaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectServiceMetaData: async (serviceId: number, putProjectServiceMetaRequest: PutProjectServiceMetaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('putProjectServiceMetaData', 'serviceId', serviceId)
            // verify required parameter 'putProjectServiceMetaRequest' is not null or undefined
            assertParamExists('putProjectServiceMetaData', 'putProjectServiceMetaRequest', putProjectServiceMetaRequest)
            const localVarPath = `/api/v1/projectServices/{service-id}`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putProjectServiceMetaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectServiceMetaDataApiApi - functional programming interface
 * @export
 */
export const ProjectServiceMetaDataApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectServiceMetaDataApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectDraft(draftId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteProjectDraftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectDraft(draftId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {DeleteTaskKnowledgeRequest} deleteTaskKnowledgeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskKnowledge(itemId: number, deleteTaskKnowledgeRequest: DeleteTaskKnowledgeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteTaskKnowledgeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskKnowledge(itemId, deleteTaskKnowledgeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemKnowledge(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetItemKnowledgeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemKnowledge(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItems(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItems(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [owner] 
         * @param {string} [title] 
         * @param {number} [status] 
         * @param {string} [description] 
         * @param {number} [createdBy] 
         * @param {number} [updatedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetaDataList(id?: number, owner?: number, title?: string, status?: number, description?: string, createdBy?: number, updatedBy?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProjectMetaDataListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetaDataList(id, owner, title, status, description, createdBy, updatedBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessTemplate(serviceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProcessTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessTemplate(serviceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectStatusMetaData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetProjectStatusMetaDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectStatusMetaData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {PostCommitItemKnowledgeRequest} postCommitItemKnowledgeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCommitItemKnowledge(itemId: number, postCommitItemKnowledgeRequest: PostCommitItemKnowledgeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostCommitItemKnowledgeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCommitItemKnowledge(itemId, postCommitItemKnowledgeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostProjectServiceMetaRequest} postProjectServiceMetaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectService(postProjectServiceMetaRequest: PostProjectServiceMetaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostProjectServiceMetaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectService(postProjectServiceMetaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostProjectServiceDraftRequest} postProjectServiceDraftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectServiceDraft(postProjectServiceDraftRequest: PostProjectServiceDraftRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostProjectServiceDraftResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectServiceDraft(postProjectServiceDraftRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PostProjectServiceStatusRequest} postProjectServiceStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectServiceStatus(serviceId: number, postProjectServiceStatusRequest: PostProjectServiceStatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostProjectServiceStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectServiceStatus(serviceId, postProjectServiceStatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PostServiceInstanceSubscribeServiceRequest} postServiceInstanceSubscribeServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSubscribeService(serviceId: number, postServiceInstanceSubscribeServiceRequest: PostServiceInstanceSubscribeServiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostServiceInstanceSubscribeServiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSubscribeService(serviceId, postServiceInstanceSubscribeServiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {PutItemsRequest} putItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putItem(itemId: number, putItemsRequest: PutItemsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putItem(itemId, putItemsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PutProjectServiceMetaRequest} putProjectServiceMetaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProjectServiceMetaData(serviceId: number, putProjectServiceMetaRequest: PutProjectServiceMetaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutProjectServiceMetaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProjectServiceMetaData(serviceId, putProjectServiceMetaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectServiceMetaDataApiApi - factory interface
 * @export
 */
export const ProjectServiceMetaDataApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectServiceMetaDataApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} draftId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectDraft(draftId: number, options?: any): AxiosPromise<ApiResponseDeleteProjectDraftResponse> {
            return localVarFp.deleteProjectDraft(draftId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {DeleteTaskKnowledgeRequest} deleteTaskKnowledgeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskKnowledge(itemId: number, deleteTaskKnowledgeRequest: DeleteTaskKnowledgeRequest, options?: any): AxiosPromise<ApiResponseDeleteTaskKnowledgeResponse> {
            return localVarFp.deleteTaskKnowledge(itemId, deleteTaskKnowledgeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemKnowledge(itemId: number, options?: any): AxiosPromise<ApiResponseGetItemKnowledgeResponse> {
            return localVarFp.getItemKnowledge(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItems(serviceId: number, options?: any): AxiosPromise<ApiResponseGetItemsResponse> {
            return localVarFp.getItems(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {number} [owner] 
         * @param {string} [title] 
         * @param {number} [status] 
         * @param {string} [description] 
         * @param {number} [createdBy] 
         * @param {number} [updatedBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetaDataList(id?: number, owner?: number, title?: string, status?: number, description?: string, createdBy?: number, updatedBy?: number, options?: any): AxiosPromise<ApiResponseGetProjectMetaDataListResponse> {
            return localVarFp.getMetaDataList(id, owner, title, status, description, createdBy, updatedBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessTemplate(serviceId: number, options?: any): AxiosPromise<ApiResponseGetProcessTemplateResponse> {
            return localVarFp.getProcessTemplate(serviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectStatusMetaData(options?: any): AxiosPromise<ApiResponseGetProjectStatusMetaDataResponse> {
            return localVarFp.getProjectStatusMetaData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {PostCommitItemKnowledgeRequest} postCommitItemKnowledgeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCommitItemKnowledge(itemId: number, postCommitItemKnowledgeRequest: PostCommitItemKnowledgeRequest, options?: any): AxiosPromise<ApiResponsePostCommitItemKnowledgeResponse> {
            return localVarFp.postCommitItemKnowledge(itemId, postCommitItemKnowledgeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostProjectServiceMetaRequest} postProjectServiceMetaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectService(postProjectServiceMetaRequest: PostProjectServiceMetaRequest, options?: any): AxiosPromise<ApiResponsePostProjectServiceMetaResponse> {
            return localVarFp.postProjectService(postProjectServiceMetaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostProjectServiceDraftRequest} postProjectServiceDraftRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectServiceDraft(postProjectServiceDraftRequest: PostProjectServiceDraftRequest, options?: any): AxiosPromise<ApiResponsePostProjectServiceDraftResponse> {
            return localVarFp.postProjectServiceDraft(postProjectServiceDraftRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PostProjectServiceStatusRequest} postProjectServiceStatusRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectServiceStatus(serviceId: number, postProjectServiceStatusRequest: PostProjectServiceStatusRequest, options?: any): AxiosPromise<ApiResponsePostProjectServiceStatusResponse> {
            return localVarFp.postProjectServiceStatus(serviceId, postProjectServiceStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PostServiceInstanceSubscribeServiceRequest} postServiceInstanceSubscribeServiceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSubscribeService(serviceId: number, postServiceInstanceSubscribeServiceRequest: PostServiceInstanceSubscribeServiceRequest, options?: any): AxiosPromise<ApiResponsePostServiceInstanceSubscribeServiceResponse> {
            return localVarFp.postSubscribeService(serviceId, postServiceInstanceSubscribeServiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {PutItemsRequest} putItemsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putItem(itemId: number, putItemsRequest: PutItemsRequest, options?: any): AxiosPromise<ApiResponsePutItemsResponse> {
            return localVarFp.putItem(itemId, putItemsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} serviceId 
         * @param {PutProjectServiceMetaRequest} putProjectServiceMetaRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectServiceMetaData(serviceId: number, putProjectServiceMetaRequest: PutProjectServiceMetaRequest, options?: any): AxiosPromise<ApiResponsePutProjectServiceMetaResponse> {
            return localVarFp.putProjectServiceMetaData(serviceId, putProjectServiceMetaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectServiceMetaDataApiApi - object-oriented interface
 * @export
 * @class ProjectServiceMetaDataApiApi
 * @extends {BaseAPI}
 */
export class ProjectServiceMetaDataApiApi extends BaseAPI {
    /**
     * 
     * @param {number} draftId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public deleteProjectDraft(draftId: number, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).deleteProjectDraft(draftId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {DeleteTaskKnowledgeRequest} deleteTaskKnowledgeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public deleteTaskKnowledge(itemId: number, deleteTaskKnowledgeRequest: DeleteTaskKnowledgeRequest, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).deleteTaskKnowledge(itemId, deleteTaskKnowledgeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public getItemKnowledge(itemId: number, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).getItemKnowledge(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public getItems(serviceId: number, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).getItems(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {number} [owner] 
     * @param {string} [title] 
     * @param {number} [status] 
     * @param {string} [description] 
     * @param {number} [createdBy] 
     * @param {number} [updatedBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public getMetaDataList(id?: number, owner?: number, title?: string, status?: number, description?: string, createdBy?: number, updatedBy?: number, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).getMetaDataList(id, owner, title, status, description, createdBy, updatedBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public getProcessTemplate(serviceId: number, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).getProcessTemplate(serviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public getProjectStatusMetaData(options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).getProjectStatusMetaData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {PostCommitItemKnowledgeRequest} postCommitItemKnowledgeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public postCommitItemKnowledge(itemId: number, postCommitItemKnowledgeRequest: PostCommitItemKnowledgeRequest, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).postCommitItemKnowledge(itemId, postCommitItemKnowledgeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostProjectServiceMetaRequest} postProjectServiceMetaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public postProjectService(postProjectServiceMetaRequest: PostProjectServiceMetaRequest, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).postProjectService(postProjectServiceMetaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostProjectServiceDraftRequest} postProjectServiceDraftRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public postProjectServiceDraft(postProjectServiceDraftRequest: PostProjectServiceDraftRequest, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).postProjectServiceDraft(postProjectServiceDraftRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {PostProjectServiceStatusRequest} postProjectServiceStatusRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public postProjectServiceStatus(serviceId: number, postProjectServiceStatusRequest: PostProjectServiceStatusRequest, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).postProjectServiceStatus(serviceId, postProjectServiceStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {PostServiceInstanceSubscribeServiceRequest} postServiceInstanceSubscribeServiceRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public postSubscribeService(serviceId: number, postServiceInstanceSubscribeServiceRequest: PostServiceInstanceSubscribeServiceRequest, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).postSubscribeService(serviceId, postServiceInstanceSubscribeServiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {PutItemsRequest} putItemsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public putItem(itemId: number, putItemsRequest: PutItemsRequest, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).putItem(itemId, putItemsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} serviceId 
     * @param {PutProjectServiceMetaRequest} putProjectServiceMetaRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectServiceMetaDataApiApi
     */
    public putProjectServiceMetaData(serviceId: number, putProjectServiceMetaRequest: PutProjectServiceMetaRequest, options?: AxiosRequestConfig) {
        return ProjectServiceMetaDataApiApiFp(this.configuration).putProjectServiceMetaData(serviceId, putProjectServiceMetaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SchemaApiApi - axios parameter creator
 * @export
 */
export const SchemaApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} namespace 
         * @param {string} schemaName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema: async (namespace: string, schemaName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('getSchema', 'namespace', namespace)
            // verify required parameter 'schemaName' is not null or undefined
            assertParamExists('getSchema', 'schemaName', schemaName)
            const localVarPath = `/api/v1/schema/{namespace}/{schema-name}`
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"schema-name"}}`, encodeURIComponent(String(schemaName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchemaApiApi - functional programming interface
 * @export
 */
export const SchemaApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchemaApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} namespace 
         * @param {string} schemaName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchema(namespace: string, schemaName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchema(namespace, schemaName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SchemaApiApi - factory interface
 * @export
 */
export const SchemaApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchemaApiApiFp(configuration)
    return {
        /**
         * 
         * @param {string} namespace 
         * @param {string} schemaName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchema(namespace: string, schemaName: string, options?: any): AxiosPromise<string> {
            return localVarFp.getSchema(namespace, schemaName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchemaApiApi - object-oriented interface
 * @export
 * @class SchemaApiApi
 * @extends {BaseAPI}
 */
export class SchemaApiApi extends BaseAPI {
    /**
     * 
     * @param {string} namespace 
     * @param {string} schemaName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApiApi
     */
    public getSchema(namespace: string, schemaName: string, options?: AxiosRequestConfig) {
        return SchemaApiApiFp(this.configuration).getSchema(namespace, schemaName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecurityApiApi - axios parameter creator
 * @export
 */
export const SecurityApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicInDomain: async (domain: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'domain' is not null or undefined
            assertParamExists('getPublicInDomain', 'domain', domain)
            const localVarPath = `/api/v1/security/public`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (domain !== undefined) {
                localVarQueryParameter['domain'] = domain;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityApiApi - functional programming interface
 * @export
 */
export const SecurityApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicInDomain(domain: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetPublicInDomainResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicInDomain(domain, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecurityApiApi - factory interface
 * @export
 */
export const SecurityApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityApiApiFp(configuration)
    return {
        /**
         * 
         * @param {string} domain 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicInDomain(domain: string, options?: any): AxiosPromise<ApiResponseGetPublicInDomainResponse> {
            return localVarFp.getPublicInDomain(domain, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecurityApiApi - object-oriented interface
 * @export
 * @class SecurityApiApi
 * @extends {BaseAPI}
 */
export class SecurityApiApi extends BaseAPI {
    /**
     * 
     * @param {string} domain 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApiApi
     */
    public getPublicInDomain(domain: string, options?: AxiosRequestConfig) {
        return SecurityApiApiFp(this.configuration).getPublicInDomain(domain, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatisticApiApi - axios parameter creator
 * @export
 */
export const StatisticApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAtMomentStatisticsData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/statistic/tickets/at-moment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} dataPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryStatisticsData: async (start: string, end: string, dataPoint: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getHistoryStatisticsData', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getHistoryStatisticsData', 'end', end)
            // verify required parameter 'dataPoint' is not null or undefined
            assertParamExists('getHistoryStatisticsData', 'dataPoint', dataPoint)
            const localVarPath = `/api/v1/statistic/tickets/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (dataPoint !== undefined) {
                localVarQueryParameter['data-point'] = dataPoint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [actAsCreator] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipatingTickets: async (userId: number, actAsCreator?: boolean, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getParticipatingTickets', 'userId', userId)
            const localVarPath = `/api/v1/statistic/participators/{user-id}/tickets`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (actAsCreator !== undefined) {
                localVarQueryParameter['act-as-creator'] = actAsCreator;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start-date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end-date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipatorStatisticsData: async (userId: number, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getParticipatorStatisticsData', 'userId', userId)
            const localVarPath = `/api/v1/statistic/participators/{user-id}/history`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start-date'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end-date'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRun: async (start: string, end: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getRun', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getRun', 'end', end)
            const localVarPath = `/api/v1/statistic/tickets/overviews/rerun`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketParticipateRecord: async (ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getTicketParticipateRecord', 'ticketId', ticketId)
            const localVarPath = `/api/v1/statistic/tickets/{ticket-id}/participates`
                .replace(`{${"ticket-id"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRerun: async (userId: number, start: string, end: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserRerun', 'userId', userId)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getUserRerun', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getUserRerun', 'end', end)
            const localVarPath = `/api/v1/statistic/participators/{user-id}/overview/rerun`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StatisticCloseTicketRequest} statisticCloseTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticCloseTicket: async (statisticCloseTicketRequest: StatisticCloseTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'statisticCloseTicketRequest' is not null or undefined
            assertParamExists('statisticCloseTicket', 'statisticCloseTicketRequest', statisticCloseTicketRequest)
            const localVarPath = `/api/v1/statistic/tickets/closed-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statisticCloseTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticSingleCloseTicket: async (ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('statisticSingleCloseTicket', 'ticketId', ticketId)
            const localVarPath = `/api/v1/statistic/tickets/{ticket-id}/refresh`
                .replace(`{${"ticket-id"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticApiApi - functional programming interface
 * @export
 */
export const StatisticApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAtMomentStatisticsData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseAtMomentStatisticsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAtMomentStatisticsData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} dataPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistoryStatisticsData(start: string, end: string, dataPoint: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseHistoryStatisticsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoryStatisticsData(start, end, dataPoint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [actAsCreator] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipatingTickets(userId: number, actAsCreator?: boolean, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseParticipatingTicketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipatingTickets(userId, actAsCreator, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipatorStatisticsData(userId: number, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseParticipatorStatisticsHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipatorStatisticsData(userId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRun(start: string, end: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRun(start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketParticipateRecord(ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketAssigneeChangeThreadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketParticipateRecord(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserRerun(userId: number, start: string, end: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserRerun(userId, start, end, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StatisticCloseTicketRequest} statisticCloseTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticCloseTicket(statisticCloseTicketRequest: StatisticCloseTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseStatisticCloseTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticCloseTicket(statisticCloseTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticSingleCloseTicket(ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseStatisticSingleCloseTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticSingleCloseTicket(ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatisticApiApi - factory interface
 * @export
 */
export const StatisticApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAtMomentStatisticsData(options?: any): AxiosPromise<ApiResponseAtMomentStatisticsDataResponse> {
            return localVarFp.getAtMomentStatisticsData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} dataPoint 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryStatisticsData(start: string, end: string, dataPoint: string, options?: any): AxiosPromise<ApiResponseHistoryStatisticsDataResponse> {
            return localVarFp.getHistoryStatisticsData(start, end, dataPoint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} [actAsCreator] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipatingTickets(userId: number, actAsCreator?: boolean, startDate?: string, endDate?: string, options?: any): AxiosPromise<ApiResponseParticipatingTicketsResponse> {
            return localVarFp.getParticipatingTickets(userId, actAsCreator, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipatorStatisticsData(userId: number, startDate?: string, endDate?: string, options?: any): AxiosPromise<ApiResponseParticipatorStatisticsHistoryResponse> {
            return localVarFp.getParticipatorStatisticsData(userId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRun(start: string, end: string, options?: any): AxiosPromise<object> {
            return localVarFp.getRun(start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketParticipateRecord(ticketId: number, options?: any): AxiosPromise<ApiResponseGetTicketAssigneeChangeThreadsResponse> {
            return localVarFp.getTicketParticipateRecord(ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserRerun(userId: number, start: string, end: string, options?: any): AxiosPromise<object> {
            return localVarFp.getUserRerun(userId, start, end, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StatisticCloseTicketRequest} statisticCloseTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticCloseTicket(statisticCloseTicketRequest: StatisticCloseTicketRequest, options?: any): AxiosPromise<ApiResponseStatisticCloseTicketResponse> {
            return localVarFp.statisticCloseTicket(statisticCloseTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticSingleCloseTicket(ticketId: number, options?: any): AxiosPromise<ApiResponseStatisticSingleCloseTicketResponse> {
            return localVarFp.statisticSingleCloseTicket(ticketId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticApiApi - object-oriented interface
 * @export
 * @class StatisticApiApi
 * @extends {BaseAPI}
 */
export class StatisticApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public getAtMomentStatisticsData(options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).getAtMomentStatisticsData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} start 
     * @param {string} end 
     * @param {string} dataPoint 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public getHistoryStatisticsData(start: string, end: string, dataPoint: string, options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).getHistoryStatisticsData(start, end, dataPoint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {boolean} [actAsCreator] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public getParticipatingTickets(userId: number, actAsCreator?: boolean, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).getParticipatingTickets(userId, actAsCreator, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public getParticipatorStatisticsData(userId: number, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).getParticipatorStatisticsData(userId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} start 
     * @param {string} end 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public getRun(start: string, end: string, options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).getRun(start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public getTicketParticipateRecord(ticketId: number, options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).getTicketParticipateRecord(ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} start 
     * @param {string} end 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public getUserRerun(userId: number, start: string, end: string, options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).getUserRerun(userId, start, end, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StatisticCloseTicketRequest} statisticCloseTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public statisticCloseTicket(statisticCloseTicketRequest: StatisticCloseTicketRequest, options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).statisticCloseTicket(statisticCloseTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticApiApi
     */
    public statisticSingleCloseTicket(ticketId: number, options?: AxiosRequestConfig) {
        return StatisticApiApiFp(this.configuration).statisticSingleCloseTicket(ticketId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApiApi - axios parameter creator
 * @export
 */
export const SystemApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datafix: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/datafix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} channelType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelConfigurationKeys: async (channelType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'channelType' is not null or undefined
            assertParamExists('getChannelConfigurationKeys', 'channelType', channelType)
            const localVarPath = `/api/v1/system/channelConfigurations/{channel-type}`
                .replace(`{${"channel-type"}}`, encodeURIComponent(String(channelType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelNameList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/getChannelNameList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscalationActions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/escalation/actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscalationRules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/escalation/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventNameList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/getEventNameList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/navigate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReflexStrategies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/reflex/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemSettingItem: async (settingItem: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingItem' is not null or undefined
            assertParamExists('getSystemSettingItem', 'settingItem', settingItem)
            const localVarPath = `/api/v1/system/settings/{setting-item}`
                .replace(`{${"setting-item"}}`, encodeURIComponent(String(settingItem)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostEscalationRuleRequest} postEscalationRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEscalationRule: async (postEscalationRuleRequest: PostEscalationRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postEscalationRuleRequest' is not null or undefined
            assertParamExists('postEscalationRule', 'postEscalationRuleRequest', postEscalationRuleRequest)
            const localVarPath = `/api/v1/system/escalation/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postEscalationRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReflexSystemReload: async (instanceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('postReflexSystemReload', 'instanceId', instanceId)
            const localVarPath = `/api/v1/system/reflex/instances/{instance-id}`
                .replace(`{${"instance-id"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTriggerRuleRequest: async (ruleId: number, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('postTriggerRuleRequest', 'ruleId', ruleId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('postTriggerRuleRequest', 'body', body)
            const localVarPath = `/api/v1/system/escalation/rules/{rule-id}/trigger`
                .replace(`{${"rule-id"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutChannelConfigurationRequest} putChannelConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelConfiguration: async (putChannelConfigurationRequest: PutChannelConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putChannelConfigurationRequest' is not null or undefined
            assertParamExists('putChannelConfiguration', 'putChannelConfigurationRequest', putChannelConfigurationRequest)
            const localVarPath = `/api/v1/system/channelConfigurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putChannelConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationActionsRequest} putEscalationActionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationActions: async (ruleId: number, putEscalationActionsRequest: PutEscalationActionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('putEscalationActions', 'ruleId', ruleId)
            // verify required parameter 'putEscalationActionsRequest' is not null or undefined
            assertParamExists('putEscalationActions', 'putEscalationActionsRequest', putEscalationActionsRequest)
            const localVarPath = `/api/v1/system/escalation/rules/{rule-id}/actions`
                .replace(`{${"rule-id"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putEscalationActionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationBasicInfoRequest} putEscalationBasicInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationBasicInfo: async (ruleId: number, putEscalationBasicInfoRequest: PutEscalationBasicInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('putEscalationBasicInfo', 'ruleId', ruleId)
            // verify required parameter 'putEscalationBasicInfoRequest' is not null or undefined
            assertParamExists('putEscalationBasicInfo', 'putEscalationBasicInfoRequest', putEscalationBasicInfoRequest)
            const localVarPath = `/api/v1/system/escalation/rules/{rule-id}/basic-info`
                .replace(`{${"rule-id"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putEscalationBasicInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationEnabledRequest} putEscalationEnabledRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationEnabled: async (ruleId: number, putEscalationEnabledRequest: PutEscalationEnabledRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('putEscalationEnabled', 'ruleId', ruleId)
            // verify required parameter 'putEscalationEnabledRequest' is not null or undefined
            assertParamExists('putEscalationEnabled', 'putEscalationEnabledRequest', putEscalationEnabledRequest)
            const localVarPath = `/api/v1/system/escalation/rules/{rule-id}/enable`
                .replace(`{${"rule-id"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putEscalationEnabledRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationTicketFilterRequest} putEscalationTicketFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationTicketFilter: async (ruleId: number, putEscalationTicketFilterRequest: PutEscalationTicketFilterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('putEscalationTicketFilter', 'ruleId', ruleId)
            // verify required parameter 'putEscalationTicketFilterRequest' is not null or undefined
            assertParamExists('putEscalationTicketFilter', 'putEscalationTicketFilterRequest', putEscalationTicketFilterRequest)
            const localVarPath = `/api/v1/system/escalation/rules/{rule-id}/ticket-filter`
                .replace(`{${"rule-id"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putEscalationTicketFilterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationFrequencyRequest} putEscalationFrequencyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationTimeFrequency: async (ruleId: number, putEscalationFrequencyRequest: PutEscalationFrequencyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('putEscalationTimeFrequency', 'ruleId', ruleId)
            // verify required parameter 'putEscalationFrequencyRequest' is not null or undefined
            assertParamExists('putEscalationTimeFrequency', 'putEscalationFrequencyRequest', putEscalationFrequencyRequest)
            const localVarPath = `/api/v1/system/escalation/rules/{rule-id}/frequency`
                .replace(`{${"rule-id"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putEscalationFrequencyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationTimeSheetRequest} putEscalationTimeSheetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationTimeSheet: async (ruleId: number, putEscalationTimeSheetRequest: PutEscalationTimeSheetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('putEscalationTimeSheet', 'ruleId', ruleId)
            // verify required parameter 'putEscalationTimeSheetRequest' is not null or undefined
            assertParamExists('putEscalationTimeSheet', 'putEscalationTimeSheetRequest', putEscalationTimeSheetRequest)
            const localVarPath = `/api/v1/system/escalation/rules/{rule-id}/time-sheet`
                .replace(`{${"rule-id"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putEscalationTimeSheetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutReflexResolversRequest} putReflexResolversRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReflexResolvers: async (putReflexResolversRequest: PutReflexResolversRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putReflexResolversRequest' is not null or undefined
            assertParamExists('putReflexResolvers', 'putReflexResolversRequest', putReflexResolversRequest)
            const localVarPath = `/api/v1/system/reflex/resolvers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putReflexResolversRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutReflexStrategiesRequest} putReflexStrategiesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReflexStrategies: async (putReflexStrategiesRequest: PutReflexStrategiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putReflexStrategiesRequest' is not null or undefined
            assertParamExists('putReflexStrategies', 'putReflexStrategiesRequest', putReflexStrategiesRequest)
            const localVarPath = `/api/v1/system/reflex/strategies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putReflexStrategiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutResetChannelConfigurationRequest} putResetChannelConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putResetChannelConfiguration: async (putResetChannelConfigurationRequest: PutResetChannelConfigurationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putResetChannelConfigurationRequest' is not null or undefined
            assertParamExists('putResetChannelConfiguration', 'putResetChannelConfigurationRequest', putResetChannelConfigurationRequest)
            const localVarPath = `/api/v1/system/channelConfigurations/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putResetChannelConfigurationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putResetSystemSettings: async (settingKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingKey' is not null or undefined
            assertParamExists('putResetSystemSettings', 'settingKey', settingKey)
            const localVarPath = `/api/v1/system/settings/{setting-key}`
                .replace(`{${"setting-key"}}`, encodeURIComponent(String(settingKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutSystemSettingRequest} putSystemSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSystemSettings: async (putSystemSettingRequest: PutSystemSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putSystemSettingRequest' is not null or undefined
            assertParamExists('putSystemSettings', 'putSystemSettingRequest', putSystemSettingRequest)
            const localVarPath = `/api/v1/system/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putSystemSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApiApi - functional programming interface
 * @export
 */
export const SystemApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datafix(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datafix(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} channelType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelConfigurationKeys(channelType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetChannelConfigurationKeysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannelConfigurationKeys(channelType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannelNameList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetChannelNameListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannelNameList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEscalationActions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetEscalationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEscalationActions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEscalationRules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetEscalationRulesResponseEscalationRuleDtoV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEscalationRules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventNameList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetEventNameListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventNameList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetLoginUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNavigation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetNavigationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNavigation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReflexStrategies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetReflexStrategyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReflexStrategies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetSystemSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSystemSettingItem(settingItem: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetSystemSettingItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSystemSettingItem(settingItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostEscalationRuleRequest} postEscalationRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEscalationRule(postEscalationRuleRequest: PostEscalationRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostEscalationRuleResponseEscalationRuleDtoV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEscalationRule(postEscalationRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReflexSystemReload(instanceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReflexSystemReload(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTriggerRuleRequest(ruleId: number, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostTriggerEscalationRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTriggerRuleRequest(ruleId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutChannelConfigurationRequest} putChannelConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putChannelConfiguration(putChannelConfigurationRequest: PutChannelConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutChannelConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putChannelConfiguration(putChannelConfigurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationActionsRequest} putEscalationActionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEscalationActions(ruleId: number, putEscalationActionsRequest: PutEscalationActionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutEscalationActionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEscalationActions(ruleId, putEscalationActionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationBasicInfoRequest} putEscalationBasicInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEscalationBasicInfo(ruleId: number, putEscalationBasicInfoRequest: PutEscalationBasicInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutEscalationBasicInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEscalationBasicInfo(ruleId, putEscalationBasicInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationEnabledRequest} putEscalationEnabledRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEscalationEnabled(ruleId: number, putEscalationEnabledRequest: PutEscalationEnabledRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutEscalationEnabledResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEscalationEnabled(ruleId, putEscalationEnabledRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationTicketFilterRequest} putEscalationTicketFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEscalationTicketFilter(ruleId: number, putEscalationTicketFilterRequest: PutEscalationTicketFilterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutEscalationTicketFilterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEscalationTicketFilter(ruleId, putEscalationTicketFilterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationFrequencyRequest} putEscalationFrequencyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEscalationTimeFrequency(ruleId: number, putEscalationFrequencyRequest: PutEscalationFrequencyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutEscalationFrequencyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEscalationTimeFrequency(ruleId, putEscalationFrequencyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationTimeSheetRequest} putEscalationTimeSheetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEscalationTimeSheet(ruleId: number, putEscalationTimeSheetRequest: PutEscalationTimeSheetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutEscalationTimeSheetResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEscalationTimeSheet(ruleId, putEscalationTimeSheetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutReflexResolversRequest} putReflexResolversRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReflexResolvers(putReflexResolversRequest: PutReflexResolversRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReflexResolvers(putReflexResolversRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutReflexStrategiesRequest} putReflexStrategiesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putReflexStrategies(putReflexStrategiesRequest: PutReflexStrategiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putReflexStrategies(putReflexStrategiesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutResetChannelConfigurationRequest} putResetChannelConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putResetChannelConfiguration(putResetChannelConfigurationRequest: PutResetChannelConfigurationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutResetChannelConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putResetChannelConfiguration(putResetChannelConfigurationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putResetSystemSettings(settingKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutResetSystemSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putResetSystemSettings(settingKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutSystemSettingRequest} putSystemSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSystemSettings(putSystemSettingRequest: PutSystemSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutSystemSettingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSystemSettings(putSystemSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApiApi - factory interface
 * @export
 */
export const SystemApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datafix(options?: any): AxiosPromise<string> {
            return localVarFp.datafix(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} channelType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelConfigurationKeys(channelType: string, options?: any): AxiosPromise<ApiResponseGetChannelConfigurationKeysResponse> {
            return localVarFp.getChannelConfigurationKeys(channelType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannelNameList(options?: any): AxiosPromise<ApiResponseGetChannelNameListResponse> {
            return localVarFp.getChannelNameList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscalationActions(options?: any): AxiosPromise<ApiResponseGetEscalationActionResponse> {
            return localVarFp.getEscalationActions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscalationRules(options?: any): AxiosPromise<ApiResponseGetEscalationRulesResponseEscalationRuleDtoV1> {
            return localVarFp.getEscalationRules(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventNameList(options?: any): AxiosPromise<ApiResponseGetEventNameListResponse> {
            return localVarFp.getEventNameList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginUser(options?: any): AxiosPromise<ApiResponseGetLoginUserResponse> {
            return localVarFp.getLoginUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNavigation(options?: any): AxiosPromise<ApiResponseGetNavigationResponse> {
            return localVarFp.getNavigation(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReflexStrategies(options?: any): AxiosPromise<ApiResponseGetReflexStrategyResponse> {
            return localVarFp.getReflexStrategies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemSetting(options?: any): AxiosPromise<ApiResponseGetSystemSettingResponse> {
            return localVarFp.getSystemSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingItem 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemSettingItem(settingItem: string, options?: any): AxiosPromise<ApiResponseGetSystemSettingItemResponse> {
            return localVarFp.getSystemSettingItem(settingItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostEscalationRuleRequest} postEscalationRuleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEscalationRule(postEscalationRuleRequest: PostEscalationRuleRequest, options?: any): AxiosPromise<ApiResponsePostEscalationRuleResponseEscalationRuleDtoV1> {
            return localVarFp.postEscalationRule(postEscalationRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReflexSystemReload(instanceId: string, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.postReflexSystemReload(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTriggerRuleRequest(ruleId: number, body: object, options?: any): AxiosPromise<ApiResponsePostTriggerEscalationRuleResponse> {
            return localVarFp.postTriggerRuleRequest(ruleId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutChannelConfigurationRequest} putChannelConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putChannelConfiguration(putChannelConfigurationRequest: PutChannelConfigurationRequest, options?: any): AxiosPromise<ApiResponsePutChannelConfigurationResponse> {
            return localVarFp.putChannelConfiguration(putChannelConfigurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationActionsRequest} putEscalationActionsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationActions(ruleId: number, putEscalationActionsRequest: PutEscalationActionsRequest, options?: any): AxiosPromise<ApiResponsePutEscalationActionResponse> {
            return localVarFp.putEscalationActions(ruleId, putEscalationActionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationBasicInfoRequest} putEscalationBasicInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationBasicInfo(ruleId: number, putEscalationBasicInfoRequest: PutEscalationBasicInfoRequest, options?: any): AxiosPromise<ApiResponsePutEscalationBasicInfoResponse> {
            return localVarFp.putEscalationBasicInfo(ruleId, putEscalationBasicInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationEnabledRequest} putEscalationEnabledRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationEnabled(ruleId: number, putEscalationEnabledRequest: PutEscalationEnabledRequest, options?: any): AxiosPromise<ApiResponsePutEscalationEnabledResponse> {
            return localVarFp.putEscalationEnabled(ruleId, putEscalationEnabledRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationTicketFilterRequest} putEscalationTicketFilterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationTicketFilter(ruleId: number, putEscalationTicketFilterRequest: PutEscalationTicketFilterRequest, options?: any): AxiosPromise<ApiResponsePutEscalationTicketFilterResponse> {
            return localVarFp.putEscalationTicketFilter(ruleId, putEscalationTicketFilterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationFrequencyRequest} putEscalationFrequencyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationTimeFrequency(ruleId: number, putEscalationFrequencyRequest: PutEscalationFrequencyRequest, options?: any): AxiosPromise<ApiResponsePutEscalationFrequencyResponse> {
            return localVarFp.putEscalationTimeFrequency(ruleId, putEscalationFrequencyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {PutEscalationTimeSheetRequest} putEscalationTimeSheetRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEscalationTimeSheet(ruleId: number, putEscalationTimeSheetRequest: PutEscalationTimeSheetRequest, options?: any): AxiosPromise<ApiResponsePutEscalationTimeSheetResponse> {
            return localVarFp.putEscalationTimeSheet(ruleId, putEscalationTimeSheetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutReflexResolversRequest} putReflexResolversRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReflexResolvers(putReflexResolversRequest: PutReflexResolversRequest, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.putReflexResolvers(putReflexResolversRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutReflexStrategiesRequest} putReflexStrategiesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putReflexStrategies(putReflexStrategiesRequest: PutReflexStrategiesRequest, options?: any): AxiosPromise<ApiResponseString> {
            return localVarFp.putReflexStrategies(putReflexStrategiesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutResetChannelConfigurationRequest} putResetChannelConfigurationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putResetChannelConfiguration(putResetChannelConfigurationRequest: PutResetChannelConfigurationRequest, options?: any): AxiosPromise<ApiResponsePutResetChannelConfigurationResponse> {
            return localVarFp.putResetChannelConfiguration(putResetChannelConfigurationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putResetSystemSettings(settingKey: string, options?: any): AxiosPromise<ApiResponsePutResetSystemSettingsResponse> {
            return localVarFp.putResetSystemSettings(settingKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutSystemSettingRequest} putSystemSettingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSystemSettings(putSystemSettingRequest: PutSystemSettingRequest, options?: any): AxiosPromise<ApiResponsePutSystemSettingResponse> {
            return localVarFp.putSystemSettings(putSystemSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApiApi - object-oriented interface
 * @export
 * @class SystemApiApi
 * @extends {BaseAPI}
 */
export class SystemApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public datafix(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).datafix(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} channelType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getChannelConfigurationKeys(channelType: string, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getChannelConfigurationKeys(channelType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getChannelNameList(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getChannelNameList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getEscalationActions(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getEscalationActions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getEscalationRules(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getEscalationRules(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getEventNameList(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getEventNameList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getLoginUser(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getLoginUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getNavigation(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getNavigation(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getReflexStrategies(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getReflexStrategies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getSystemSetting(options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getSystemSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingItem 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public getSystemSettingItem(settingItem: string, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).getSystemSettingItem(settingItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostEscalationRuleRequest} postEscalationRuleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public postEscalationRule(postEscalationRuleRequest: PostEscalationRuleRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).postEscalationRule(postEscalationRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public postReflexSystemReload(instanceId: string, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).postReflexSystemReload(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ruleId 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public postTriggerRuleRequest(ruleId: number, body: object, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).postTriggerRuleRequest(ruleId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutChannelConfigurationRequest} putChannelConfigurationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putChannelConfiguration(putChannelConfigurationRequest: PutChannelConfigurationRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putChannelConfiguration(putChannelConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ruleId 
     * @param {PutEscalationActionsRequest} putEscalationActionsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putEscalationActions(ruleId: number, putEscalationActionsRequest: PutEscalationActionsRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putEscalationActions(ruleId, putEscalationActionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ruleId 
     * @param {PutEscalationBasicInfoRequest} putEscalationBasicInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putEscalationBasicInfo(ruleId: number, putEscalationBasicInfoRequest: PutEscalationBasicInfoRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putEscalationBasicInfo(ruleId, putEscalationBasicInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ruleId 
     * @param {PutEscalationEnabledRequest} putEscalationEnabledRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putEscalationEnabled(ruleId: number, putEscalationEnabledRequest: PutEscalationEnabledRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putEscalationEnabled(ruleId, putEscalationEnabledRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ruleId 
     * @param {PutEscalationTicketFilterRequest} putEscalationTicketFilterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putEscalationTicketFilter(ruleId: number, putEscalationTicketFilterRequest: PutEscalationTicketFilterRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putEscalationTicketFilter(ruleId, putEscalationTicketFilterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ruleId 
     * @param {PutEscalationFrequencyRequest} putEscalationFrequencyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putEscalationTimeFrequency(ruleId: number, putEscalationFrequencyRequest: PutEscalationFrequencyRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putEscalationTimeFrequency(ruleId, putEscalationFrequencyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ruleId 
     * @param {PutEscalationTimeSheetRequest} putEscalationTimeSheetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putEscalationTimeSheet(ruleId: number, putEscalationTimeSheetRequest: PutEscalationTimeSheetRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putEscalationTimeSheet(ruleId, putEscalationTimeSheetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutReflexResolversRequest} putReflexResolversRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putReflexResolvers(putReflexResolversRequest: PutReflexResolversRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putReflexResolvers(putReflexResolversRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutReflexStrategiesRequest} putReflexStrategiesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putReflexStrategies(putReflexStrategiesRequest: PutReflexStrategiesRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putReflexStrategies(putReflexStrategiesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutResetChannelConfigurationRequest} putResetChannelConfigurationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putResetChannelConfiguration(putResetChannelConfigurationRequest: PutResetChannelConfigurationRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putResetChannelConfiguration(putResetChannelConfigurationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putResetSystemSettings(settingKey: string, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putResetSystemSettings(settingKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutSystemSettingRequest} putSystemSettingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiApi
     */
    public putSystemSettings(putSystemSettingRequest: PutSystemSettingRequest, options?: AxiosRequestConfig) {
        return SystemApiApiFp(this.configuration).putSystemSettings(putSystemSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TicketApiApi - axios parameter creator
 * @export
 */
export const TicketApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {DeleteRelatedTicketRequest} deleteRelatedTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRelatedTicket: async (id: number, relateType: string, deleteRelatedTicketRequest: DeleteRelatedTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRelatedTicket', 'id', id)
            // verify required parameter 'relateType' is not null or undefined
            assertParamExists('deleteRelatedTicket', 'relateType', relateType)
            // verify required parameter 'deleteRelatedTicketRequest' is not null or undefined
            assertParamExists('deleteRelatedTicket', 'deleteRelatedTicketRequest', deleteRelatedTicketRequest)
            const localVarPath = `/api/v1/tickets/{id}/related/{relate-type}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"relate-type"}}`, encodeURIComponent(String(relateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRelatedTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketAttachment: async (id: number, fileId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTicketAttachment', 'id', id)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteTicketAttachment', 'fileId', fileId)
            const localVarPath = `/api/v1/tickets/{id}/attachments/{file-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"file-id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentMeta: async (ticketId: number, attachmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getAttachmentMeta', 'ticketId', ticketId)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('getAttachmentMeta', 'attachmentId', attachmentId)
            const localVarPath = `/api/v1/tickets/{ticket-id}/attachments/{attachment-id}`
                .replace(`{${"ticket-id"}}`, encodeURIComponent(String(ticketId)))
                .replace(`{${"attachment-id"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAutomaticContent: async (id: number, contentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAutomaticContent', 'id', id)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getAutomaticContent', 'contentId', contentId)
            const localVarPath = `/api/v1/tickets/{id}/automatic/content/{content-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedList: async (includeClosed?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tickets/related`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeClosed !== undefined) {
                localVarQueryParameter['include-closed'] = includeClosed;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedTickets: async (id: number, relateType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRelatedTickets', 'id', id)
            // verify required parameter 'relateType' is not null or undefined
            assertParamExists('getRelatedTickets', 'relateType', relateType)
            const localVarPath = `/api/v1/tickets/{id}/related/{relate-type}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"relate-type"}}`, encodeURIComponent(String(relateType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicket', 'id', id)
            const localVarPath = `/api/v1/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAttachments: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketAttachments', 'id', id)
            const localVarPath = `/api/v1/tickets/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketContent: async (id: number, contentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketContent', 'id', id)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getTicketContent', 'contentId', contentId)
            const localVarPath = `/api/v1/tickets/{id}/content/{content-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [onlyAssignToMe] 
         * @param {number} [assigned] 
         * @param {number} [assignedGroup] 
         * @param {Array<number>} [status] 
         * @param {Array<number>} [priority] 
         * @param {Array<number>} [type] 
         * @param {Array<number>} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketList: async (onlyAssignToMe?: boolean, assigned?: number, assignedGroup?: number, status?: Array<number>, priority?: Array<number>, type?: Array<number>, source?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (onlyAssignToMe !== undefined) {
                localVarQueryParameter['only-assign-to-me'] = onlyAssignToMe;
            }

            if (assigned !== undefined) {
                localVarQueryParameter['assigned'] = assigned;
            }

            if (assignedGroup !== undefined) {
                localVarQueryParameter['assigned-group'] = assignedGroup;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (priority) {
                localVarQueryParameter['priority'] = priority;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (source) {
                localVarQueryParameter['source'] = source;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketTags: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketTags', 'id', id)
            const localVarPath = `/api/v1/tickets/{id}/tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketThreads: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketThreads', 'id', id)
            const localVarPath = `/api/v1/tickets/{id}/threads`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 工单号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getsStatusOfTicket: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getsStatusOfTicket', 'id', id)
            const localVarPath = `/api/v1/tickets/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {PatchCopyTicketRequest} patchCopyTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCopyTicket: async (ticketId: number, patchCopyTicketRequest: PatchCopyTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('patchCopyTicket', 'ticketId', ticketId)
            // verify required parameter 'patchCopyTicketRequest' is not null or undefined
            assertParamExists('patchCopyTicket', 'patchCopyTicketRequest', patchCopyTicketRequest)
            const localVarPath = `/api/v1/tickets/{ticket-id}`
                .replace(`{${"ticket-id"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCopyTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostReEvalCurrentStageAssigneeRequest} postReEvalCurrentStageAssigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAutomaticAssigneeReEval: async (postReEvalCurrentStageAssigneeRequest: PostReEvalCurrentStageAssigneeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postReEvalCurrentStageAssigneeRequest' is not null or undefined
            assertParamExists('postAutomaticAssigneeReEval', 'postReEvalCurrentStageAssigneeRequest', postReEvalCurrentStageAssigneeRequest)
            const localVarPath = `/api/v1/tickets/automatic/re-eval`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postReEvalCurrentStageAssigneeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRelatedTicket: async (id: number, relateType: string, ticketId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postRelatedTicket', 'id', id)
            // verify required parameter 'relateType' is not null or undefined
            assertParamExists('postRelatedTicket', 'relateType', relateType)
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('postRelatedTicket', 'ticketId', ticketId)
            const localVarPath = `/api/v1/tickets/{id}/related/{relate-type}/{ticket-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"relate-type"}}`, encodeURIComponent(String(relateType)))
                .replace(`{${"ticket-id"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [_private] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSetTicketVisibility: async (id: number, _private?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postSetTicketVisibility', 'id', id)
            const localVarPath = `/api/v1/tickets/{id}/visibility`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_private !== undefined) {
                localVarQueryParameter['private'] = _private;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostTicketRequest} postTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicket: async (postTicketRequest: PostTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postTicketRequest' is not null or undefined
            assertParamExists('postTicket', 'postTicketRequest', postTicketRequest)
            const localVarPath = `/api/v1/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PostTicketAttachmentRequest} postTicketAttachmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicketAttachment: async (id: number, postTicketAttachmentRequest: PostTicketAttachmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postTicketAttachment', 'id', id)
            // verify required parameter 'postTicketAttachmentRequest' is not null or undefined
            assertParamExists('postTicketAttachment', 'postTicketAttachmentRequest', postTicketAttachmentRequest)
            const localVarPath = `/api/v1/tickets/{id}/attachments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTicketAttachmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PostThreadRequest} postThreadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicketThread: async (id: number, postThreadRequest: PostThreadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postTicketThread', 'id', id)
            // verify required parameter 'postThreadRequest' is not null or undefined
            assertParamExists('postTicketThread', 'postThreadRequest', postThreadRequest)
            const localVarPath = `/api/v1/tickets/{id}/threads`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postThreadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 请求的工单号
         * @param {PostWatchTicketRequest} postWatchTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicketWatchList: async (id: number, postWatchTicketRequest: PostWatchTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postTicketWatchList', 'id', id)
            // verify required parameter 'postWatchTicketRequest' is not null or undefined
            assertParamExists('postTicketWatchList', 'postWatchTicketRequest', postWatchTicketRequest)
            const localVarPath = `/api/v1/tickets/{id}/observers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postWatchTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutAssignTicketRequest} putAssignTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssignTicket: async (id: number, putAssignTicketRequest: PutAssignTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAssignTicket', 'id', id)
            // verify required parameter 'putAssignTicketRequest' is not null or undefined
            assertParamExists('putAssignTicket', 'putAssignTicketRequest', putAssignTicketRequest)
            const localVarPath = `/api/v1/tickets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAssignTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutAssignTicketsRequest} putAssignTicketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssignTickets: async (putAssignTicketsRequest: PutAssignTicketsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putAssignTicketsRequest' is not null or undefined
            assertParamExists('putAssignTickets', 'putAssignTicketsRequest', putAssignTicketsRequest)
            const localVarPath = `/api/v1/tickets/assignment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAssignTicketsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 发起自动化工单
         * @param {PutAutomaticProcessTicketRequest} putAutomaticProcessTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAutomaticProcess: async (putAutomaticProcessTicketRequest: PutAutomaticProcessTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putAutomaticProcessTicketRequest' is not null or undefined
            assertParamExists('putAutomaticProcess', 'putAutomaticProcessTicketRequest', putAutomaticProcessTicketRequest)
            const localVarPath = `/api/v1/tickets/automatic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAutomaticProcessTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {PutAutomaticProcessTicketContentRequest} putAutomaticProcessTicketContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAutomaticProcessContent: async (id: number, contentId: number, putAutomaticProcessTicketContentRequest: PutAutomaticProcessTicketContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAutomaticProcessContent', 'id', id)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('putAutomaticProcessContent', 'contentId', contentId)
            // verify required parameter 'putAutomaticProcessTicketContentRequest' is not null or undefined
            assertParamExists('putAutomaticProcessContent', 'putAutomaticProcessTicketContentRequest', putAutomaticProcessTicketContentRequest)
            const localVarPath = `/api/v1/tickets/{id}/automatic/content/{content-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putAutomaticProcessTicketContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTicketBasicInfoRequest} putTicketBasicInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketBasic: async (id: number, putTicketBasicInfoRequest: PutTicketBasicInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTicketBasic', 'id', id)
            // verify required parameter 'putTicketBasicInfoRequest' is not null or undefined
            assertParamExists('putTicketBasic', 'putTicketBasicInfoRequest', putTicketBasicInfoRequest)
            const localVarPath = `/api/v1/tickets/{id}/basic`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putTicketBasicInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {PutTicketContentRequest} putTicketContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketContent: async (id: number, contentId: number, putTicketContentRequest: PutTicketContentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTicketContent', 'id', id)
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('putTicketContent', 'contentId', contentId)
            // verify required parameter 'putTicketContentRequest' is not null or undefined
            assertParamExists('putTicketContent', 'putTicketContentRequest', putTicketContentRequest)
            const localVarPath = `/api/v1/tickets/{id}/contents/{content-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putTicketContentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTicketCoreStateRequest} putTicketCoreStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketCoreState: async (id: number, putTicketCoreStateRequest: PutTicketCoreStateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTicketCoreState', 'id', id)
            // verify required parameter 'putTicketCoreStateRequest' is not null or undefined
            assertParamExists('putTicketCoreState', 'putTicketCoreStateRequest', putTicketCoreStateRequest)
            const localVarPath = `/api/v1/tickets/{id}/core`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putTicketCoreStateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTagToTicketRequest} putTagToTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketTags: async (id: number, putTagToTicketRequest: PutTagToTicketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTicketTags', 'id', id)
            // verify required parameter 'putTagToTicketRequest' is not null or undefined
            assertParamExists('putTicketTags', 'putTagToTicketRequest', putTagToTicketRequest)
            const localVarPath = `/api/v1/tickets/{id}/tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putTagToTicketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketTags: async (id: number, tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeTicketTags', 'id', id)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('removeTicketTags', 'tagId', tagId)
            const localVarPath = `/api/v1/tickets/{id}/tags/{tag-id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag-id"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [subtitle] 
         * @param {number} [status] 
         * @param {number} [priority] 
         * @param {number} [type] 
         * @param {number} [source] 
         * @param {number} [assigned] 
         * @param {number} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTicketsByName: async (id?: number, name?: string, subtitle?: string, status?: number, priority?: number, type?: number, source?: number, assigned?: number, tag?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tickets/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (subtitle !== undefined) {
                localVarQueryParameter['subtitle'] = subtitle;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (priority !== undefined) {
                localVarQueryParameter['priority'] = priority;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (assigned !== undefined) {
                localVarQueryParameter['assigned'] = assigned;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketApiApi - functional programming interface
 * @export
 */
export const TicketApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {DeleteRelatedTicketRequest} deleteRelatedTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRelatedTicket(id: number, relateType: string, deleteRelatedTicketRequest: DeleteRelatedTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteRelatedTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRelatedTicket(id, relateType, deleteRelatedTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicketAttachment(id: number, fileId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteAttachmentFromTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketAttachment(id, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttachmentMeta(ticketId: number, attachmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketAttachmentMetaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttachmentMeta(ticketId, attachmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAutomaticContent(id: number, contentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetAutomaticProcessTicketContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAutomaticContent(id, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedList(includeClosed?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetRelatedTicketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedList(includeClosed, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatedTickets(id: number, relateType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetRelatedTicketListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatedTickets(id, relateType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicket(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketAttachments(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketAttachmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketAttachments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketContent(id: number, contentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketContent(id, contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [onlyAssignToMe] 
         * @param {number} [assigned] 
         * @param {number} [assignedGroup] 
         * @param {Array<number>} [status] 
         * @param {Array<number>} [priority] 
         * @param {Array<number>} [type] 
         * @param {Array<number>} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketList(onlyAssignToMe?: boolean, assigned?: number, assignedGroup?: number, status?: Array<number>, priority?: Array<number>, type?: Array<number>, source?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketList(onlyAssignToMe, assigned, assignedGroup, status, priority, type, source, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketTags(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketTags(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketThreads(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetTicketThreadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketThreads(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 工单号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getsStatusOfTicket(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetStatusOfTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getsStatusOfTicket(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {PatchCopyTicketRequest} patchCopyTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCopyTicket(ticketId: number, patchCopyTicketRequest: PatchCopyTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePatchCopyTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCopyTicket(ticketId, patchCopyTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostReEvalCurrentStageAssigneeRequest} postReEvalCurrentStageAssigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAutomaticAssigneeReEval(postReEvalCurrentStageAssigneeRequest: PostReEvalCurrentStageAssigneeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostReEvalCurrentStageAssigneeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAutomaticAssigneeReEval(postReEvalCurrentStageAssigneeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRelatedTicket(id: number, relateType: string, ticketId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostRelatedTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRelatedTicket(id, relateType, ticketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [_private] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSetTicketVisibility(id: number, _private?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostSetTicketVisibilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSetTicketVisibility(id, _private, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostTicketRequest} postTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTicket(postTicketRequest: PostTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTicket(postTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PostTicketAttachmentRequest} postTicketAttachmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTicketAttachment(id: number, postTicketAttachmentRequest: PostTicketAttachmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostTicketAttachmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTicketAttachment(id, postTicketAttachmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PostThreadRequest} postThreadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTicketThread(id: number, postThreadRequest: PostThreadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostTicketThreadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTicketThread(id, postThreadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 请求的工单号
         * @param {PostWatchTicketRequest} postWatchTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTicketWatchList(id: number, postWatchTicketRequest: PostWatchTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostWatchTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTicketWatchList(id, postWatchTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutAssignTicketRequest} putAssignTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAssignTicket(id: number, putAssignTicketRequest: PutAssignTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutAssignTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAssignTicket(id, putAssignTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PutAssignTicketsRequest} putAssignTicketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAssignTickets(putAssignTicketsRequest: PutAssignTicketsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutAssignTicketsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAssignTickets(putAssignTicketsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 发起自动化工单
         * @param {PutAutomaticProcessTicketRequest} putAutomaticProcessTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAutomaticProcess(putAutomaticProcessTicketRequest: PutAutomaticProcessTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutAutomaticProcessTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAutomaticProcess(putAutomaticProcessTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {PutAutomaticProcessTicketContentRequest} putAutomaticProcessTicketContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAutomaticProcessContent(id: number, contentId: number, putAutomaticProcessTicketContentRequest: PutAutomaticProcessTicketContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutAutomaticProcessTicketContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAutomaticProcessContent(id, contentId, putAutomaticProcessTicketContentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTicketBasicInfoRequest} putTicketBasicInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTicketBasic(id: number, putTicketBasicInfoRequest: PutTicketBasicInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTicketBasicInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTicketBasic(id, putTicketBasicInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {PutTicketContentRequest} putTicketContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTicketContent(id: number, contentId: number, putTicketContentRequest: PutTicketContentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTicketContentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTicketContent(id, contentId, putTicketContentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTicketCoreStateRequest} putTicketCoreStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTicketCoreState(id: number, putTicketCoreStateRequest: PutTicketCoreStateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTicketCoreStateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTicketCoreState(id, putTicketCoreStateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTagToTicketRequest} putTagToTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTicketTags(id: number, putTagToTicketRequest: PutTagToTicketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutTagToTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTicketTags(id, putTagToTicketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeTicketTags(id: number, tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteTagFromTicketResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeTicketTags(id, tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [subtitle] 
         * @param {number} [status] 
         * @param {number} [priority] 
         * @param {number} [type] 
         * @param {number} [source] 
         * @param {number} [assigned] 
         * @param {number} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchTicketsByName(id?: number, name?: string, subtitle?: string, status?: number, priority?: number, type?: number, source?: number, assigned?: number, tag?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseSearchTicketByNameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchTicketsByName(id, name, subtitle, status, priority, type, source, assigned, tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketApiApi - factory interface
 * @export
 */
export const TicketApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {DeleteRelatedTicketRequest} deleteRelatedTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRelatedTicket(id: number, relateType: string, deleteRelatedTicketRequest: DeleteRelatedTicketRequest, options?: any): AxiosPromise<ApiResponseDeleteRelatedTicketResponse> {
            return localVarFp.deleteRelatedTicket(id, relateType, deleteRelatedTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketAttachment(id: number, fileId: number, options?: any): AxiosPromise<ApiResponseDeleteAttachmentFromTicketResponse> {
            return localVarFp.deleteTicketAttachment(id, fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {number} attachmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttachmentMeta(ticketId: number, attachmentId: number, options?: any): AxiosPromise<ApiResponseGetTicketAttachmentMetaResponse> {
            return localVarFp.getAttachmentMeta(ticketId, attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAutomaticContent(id: number, contentId: number, options?: any): AxiosPromise<ApiResponseGetAutomaticProcessTicketContentResponse> {
            return localVarFp.getAutomaticContent(id, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [includeClosed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedList(includeClosed?: boolean, options?: any): AxiosPromise<ApiResponseGetRelatedTicketsResponse> {
            return localVarFp.getRelatedList(includeClosed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatedTickets(id: number, relateType: string, options?: any): AxiosPromise<ApiResponseGetRelatedTicketListResponse> {
            return localVarFp.getRelatedTickets(id, relateType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket(id: number, options?: any): AxiosPromise<ApiResponseGetTicketResponse> {
            return localVarFp.getTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketAttachments(id: number, options?: any): AxiosPromise<ApiResponseGetTicketAttachmentResponse> {
            return localVarFp.getTicketAttachments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketContent(id: number, contentId: number, options?: any): AxiosPromise<ApiResponseGetTicketContentResponse> {
            return localVarFp.getTicketContent(id, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [onlyAssignToMe] 
         * @param {number} [assigned] 
         * @param {number} [assignedGroup] 
         * @param {Array<number>} [status] 
         * @param {Array<number>} [priority] 
         * @param {Array<number>} [type] 
         * @param {Array<number>} [source] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketList(onlyAssignToMe?: boolean, assigned?: number, assignedGroup?: number, status?: Array<number>, priority?: Array<number>, type?: Array<number>, source?: Array<number>, options?: any): AxiosPromise<ApiResponseGetTicketListResponse> {
            return localVarFp.getTicketList(onlyAssignToMe, assigned, assignedGroup, status, priority, type, source, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketTags(id: number, options?: any): AxiosPromise<ApiResponseGetTicketTagsResponse> {
            return localVarFp.getTicketTags(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketThreads(id: number, options?: any): AxiosPromise<ApiResponseGetTicketThreadsResponse> {
            return localVarFp.getTicketThreads(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 工单号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getsStatusOfTicket(id: number, options?: any): AxiosPromise<ApiResponseGetStatusOfTicketResponse> {
            return localVarFp.getsStatusOfTicket(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ticketId 
         * @param {PatchCopyTicketRequest} patchCopyTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCopyTicket(ticketId: number, patchCopyTicketRequest: PatchCopyTicketRequest, options?: any): AxiosPromise<ApiResponsePatchCopyTicketResponse> {
            return localVarFp.patchCopyTicket(ticketId, patchCopyTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostReEvalCurrentStageAssigneeRequest} postReEvalCurrentStageAssigneeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAutomaticAssigneeReEval(postReEvalCurrentStageAssigneeRequest: PostReEvalCurrentStageAssigneeRequest, options?: any): AxiosPromise<ApiResponsePostReEvalCurrentStageAssigneeResponse> {
            return localVarFp.postAutomaticAssigneeReEval(postReEvalCurrentStageAssigneeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} relateType 
         * @param {number} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRelatedTicket(id: number, relateType: string, ticketId: number, options?: any): AxiosPromise<ApiResponsePostRelatedTicketResponse> {
            return localVarFp.postRelatedTicket(id, relateType, ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [_private] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSetTicketVisibility(id: number, _private?: boolean, options?: any): AxiosPromise<ApiResponsePostSetTicketVisibilityResponse> {
            return localVarFp.postSetTicketVisibility(id, _private, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostTicketRequest} postTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicket(postTicketRequest: PostTicketRequest, options?: any): AxiosPromise<ApiResponsePostTicketResponse> {
            return localVarFp.postTicket(postTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PostTicketAttachmentRequest} postTicketAttachmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicketAttachment(id: number, postTicketAttachmentRequest: PostTicketAttachmentRequest, options?: any): AxiosPromise<ApiResponsePostTicketAttachmentResponse> {
            return localVarFp.postTicketAttachment(id, postTicketAttachmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PostThreadRequest} postThreadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicketThread(id: number, postThreadRequest: PostThreadRequest, options?: any): AxiosPromise<ApiResponsePostTicketThreadResponse> {
            return localVarFp.postTicketThread(id, postThreadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 请求的工单号
         * @param {PostWatchTicketRequest} postWatchTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTicketWatchList(id: number, postWatchTicketRequest: PostWatchTicketRequest, options?: any): AxiosPromise<ApiResponsePostWatchTicketResponse> {
            return localVarFp.postTicketWatchList(id, postWatchTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutAssignTicketRequest} putAssignTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssignTicket(id: number, putAssignTicketRequest: PutAssignTicketRequest, options?: any): AxiosPromise<ApiResponsePutAssignTicketResponse> {
            return localVarFp.putAssignTicket(id, putAssignTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PutAssignTicketsRequest} putAssignTicketsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAssignTickets(putAssignTicketsRequest: PutAssignTicketsRequest, options?: any): AxiosPromise<ApiResponsePutAssignTicketsResponse> {
            return localVarFp.putAssignTickets(putAssignTicketsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 发起自动化工单
         * @param {PutAutomaticProcessTicketRequest} putAutomaticProcessTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAutomaticProcess(putAutomaticProcessTicketRequest: PutAutomaticProcessTicketRequest, options?: any): AxiosPromise<ApiResponsePutAutomaticProcessTicketResponse> {
            return localVarFp.putAutomaticProcess(putAutomaticProcessTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {PutAutomaticProcessTicketContentRequest} putAutomaticProcessTicketContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAutomaticProcessContent(id: number, contentId: number, putAutomaticProcessTicketContentRequest: PutAutomaticProcessTicketContentRequest, options?: any): AxiosPromise<ApiResponsePutAutomaticProcessTicketContentResponse> {
            return localVarFp.putAutomaticProcessContent(id, contentId, putAutomaticProcessTicketContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTicketBasicInfoRequest} putTicketBasicInfoRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketBasic(id: number, putTicketBasicInfoRequest: PutTicketBasicInfoRequest, options?: any): AxiosPromise<ApiResponsePutTicketBasicInfoResponse> {
            return localVarFp.putTicketBasic(id, putTicketBasicInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} contentId 
         * @param {PutTicketContentRequest} putTicketContentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketContent(id: number, contentId: number, putTicketContentRequest: PutTicketContentRequest, options?: any): AxiosPromise<ApiResponsePutTicketContentResponse> {
            return localVarFp.putTicketContent(id, contentId, putTicketContentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTicketCoreStateRequest} putTicketCoreStateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketCoreState(id: number, putTicketCoreStateRequest: PutTicketCoreStateRequest, options?: any): AxiosPromise<ApiResponsePutTicketCoreStateResponse> {
            return localVarFp.putTicketCoreState(id, putTicketCoreStateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutTagToTicketRequest} putTagToTicketRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTicketTags(id: number, putTagToTicketRequest: PutTagToTicketRequest, options?: any): AxiosPromise<ApiResponsePutTagToTicketResponse> {
            return localVarFp.putTicketTags(id, putTagToTicketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeTicketTags(id: number, tagId: number, options?: any): AxiosPromise<ApiResponseDeleteTagFromTicketResponse> {
            return localVarFp.removeTicketTags(id, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [subtitle] 
         * @param {number} [status] 
         * @param {number} [priority] 
         * @param {number} [type] 
         * @param {number} [source] 
         * @param {number} [assigned] 
         * @param {number} [tag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTicketsByName(id?: number, name?: string, subtitle?: string, status?: number, priority?: number, type?: number, source?: number, assigned?: number, tag?: number, options?: any): AxiosPromise<ApiResponseSearchTicketByNameResponse> {
            return localVarFp.searchTicketsByName(id, name, subtitle, status, priority, type, source, assigned, tag, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketApiApi - object-oriented interface
 * @export
 * @class TicketApiApi
 * @extends {BaseAPI}
 */
export class TicketApiApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {string} relateType 
     * @param {DeleteRelatedTicketRequest} deleteRelatedTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public deleteRelatedTicket(id: number, relateType: string, deleteRelatedTicketRequest: DeleteRelatedTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).deleteRelatedTicket(id, relateType, deleteRelatedTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public deleteTicketAttachment(id: number, fileId: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).deleteTicketAttachment(id, fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {number} attachmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getAttachmentMeta(ticketId: number, attachmentId: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getAttachmentMeta(ticketId, attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getAutomaticContent(id: number, contentId: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getAutomaticContent(id, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [includeClosed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getRelatedList(includeClosed?: boolean, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getRelatedList(includeClosed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} relateType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getRelatedTickets(id: number, relateType: string, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getRelatedTickets(id, relateType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getTicket(id: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getTicketAttachments(id: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getTicketAttachments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getTicketContent(id: number, contentId: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getTicketContent(id, contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [onlyAssignToMe] 
     * @param {number} [assigned] 
     * @param {number} [assignedGroup] 
     * @param {Array<number>} [status] 
     * @param {Array<number>} [priority] 
     * @param {Array<number>} [type] 
     * @param {Array<number>} [source] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getTicketList(onlyAssignToMe?: boolean, assigned?: number, assignedGroup?: number, status?: Array<number>, priority?: Array<number>, type?: Array<number>, source?: Array<number>, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getTicketList(onlyAssignToMe, assigned, assignedGroup, status, priority, type, source, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getTicketTags(id: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getTicketTags(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getTicketThreads(id: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getTicketThreads(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 工单号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public getsStatusOfTicket(id: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).getsStatusOfTicket(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ticketId 
     * @param {PatchCopyTicketRequest} patchCopyTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public patchCopyTicket(ticketId: number, patchCopyTicketRequest: PatchCopyTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).patchCopyTicket(ticketId, patchCopyTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostReEvalCurrentStageAssigneeRequest} postReEvalCurrentStageAssigneeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public postAutomaticAssigneeReEval(postReEvalCurrentStageAssigneeRequest: PostReEvalCurrentStageAssigneeRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).postAutomaticAssigneeReEval(postReEvalCurrentStageAssigneeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} relateType 
     * @param {number} ticketId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public postRelatedTicket(id: number, relateType: string, ticketId: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).postRelatedTicket(id, relateType, ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [_private] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public postSetTicketVisibility(id: number, _private?: boolean, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).postSetTicketVisibility(id, _private, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostTicketRequest} postTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public postTicket(postTicketRequest: PostTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).postTicket(postTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PostTicketAttachmentRequest} postTicketAttachmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public postTicketAttachment(id: number, postTicketAttachmentRequest: PostTicketAttachmentRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).postTicketAttachment(id, postTicketAttachmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PostThreadRequest} postThreadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public postTicketThread(id: number, postThreadRequest: PostThreadRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).postTicketThread(id, postThreadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 请求的工单号
     * @param {PostWatchTicketRequest} postWatchTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public postTicketWatchList(id: number, postWatchTicketRequest: PostWatchTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).postTicketWatchList(id, postWatchTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutAssignTicketRequest} putAssignTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public putAssignTicket(id: number, putAssignTicketRequest: PutAssignTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).putAssignTicket(id, putAssignTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PutAssignTicketsRequest} putAssignTicketsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public putAssignTickets(putAssignTicketsRequest: PutAssignTicketsRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).putAssignTickets(putAssignTicketsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 发起自动化工单
     * @param {PutAutomaticProcessTicketRequest} putAutomaticProcessTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public putAutomaticProcess(putAutomaticProcessTicketRequest: PutAutomaticProcessTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).putAutomaticProcess(putAutomaticProcessTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} contentId 
     * @param {PutAutomaticProcessTicketContentRequest} putAutomaticProcessTicketContentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public putAutomaticProcessContent(id: number, contentId: number, putAutomaticProcessTicketContentRequest: PutAutomaticProcessTicketContentRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).putAutomaticProcessContent(id, contentId, putAutomaticProcessTicketContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutTicketBasicInfoRequest} putTicketBasicInfoRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public putTicketBasic(id: number, putTicketBasicInfoRequest: PutTicketBasicInfoRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).putTicketBasic(id, putTicketBasicInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} contentId 
     * @param {PutTicketContentRequest} putTicketContentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public putTicketContent(id: number, contentId: number, putTicketContentRequest: PutTicketContentRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).putTicketContent(id, contentId, putTicketContentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutTicketCoreStateRequest} putTicketCoreStateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public putTicketCoreState(id: number, putTicketCoreStateRequest: PutTicketCoreStateRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).putTicketCoreState(id, putTicketCoreStateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutTagToTicketRequest} putTagToTicketRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public putTicketTags(id: number, putTagToTicketRequest: PutTagToTicketRequest, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).putTicketTags(id, putTagToTicketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public removeTicketTags(id: number, tagId: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).removeTicketTags(id, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {string} [name] 
     * @param {string} [subtitle] 
     * @param {number} [status] 
     * @param {number} [priority] 
     * @param {number} [type] 
     * @param {number} [source] 
     * @param {number} [assigned] 
     * @param {number} [tag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketApiApi
     */
    public searchTicketsByName(id?: number, name?: string, subtitle?: string, status?: number, priority?: number, type?: number, source?: number, assigned?: number, tag?: number, options?: AxiosRequestConfig) {
        return TicketApiApiFp(this.configuration).searchTicketsByName(id, name, subtitle, status, priority, type, source, assigned, tag, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApiApi - axios parameter creator
 * @export
 */
export const UserApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteApiAccessKeyRequest} deleteApiAccessKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiAccessKey: async (deleteApiAccessKeyRequest: DeleteApiAccessKeyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteApiAccessKeyRequest' is not null or undefined
            assertParamExists('deleteApiAccessKey', 'deleteApiAccessKeyRequest', deleteApiAccessKeyRequest)
            const localVarPath = `/api/v1/users/api-access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteApiAccessKeyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiAccessKeyOfUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getApiAccessKeyOfUser', 'userId', userId)
            const localVarPath = `/api/v1/users/{user-id}/api-access`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} withProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: number, withProfile: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            // verify required parameter 'withProfile' is not null or undefined
            assertParamExists('getUser', 'withProfile', withProfile)
            const localVarPath = `/api/v1/users/{user-id}`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (withProfile !== undefined) {
                localVarQueryParameter['withProfile'] = withProfile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserList: async (includeInactive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeInactive !== undefined) {
                localVarQueryParameter['include-inactive'] = includeInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostUserRequest} postUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUser: async (postUserRequest: PostUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postUserRequest' is not null or undefined
            assertParamExists('newUser', 'postUserRequest', postUserRequest)
            const localVarPath = `/api/v1/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiAccessKey: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('putApiAccessKey', 'userId', userId)
            const localVarPath = `/api/v1/users/{user-id}/api-access`
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApiApi - functional programming interface
 * @export
 */
export const UserApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteApiAccessKeyRequest} deleteApiAccessKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApiAccessKey(deleteApiAccessKeyRequest: DeleteApiAccessKeyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteApiAccessKeyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApiAccessKey(deleteApiAccessKeyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApiAccessKeyOfUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetApiAccessKeyOfUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApiAccessKeyOfUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} withProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: number, withProfile: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, withProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserList(includeInactive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserList(includeInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostUserRequest} postUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newUser(postUserRequest: PostUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newUser(postUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putApiAccessKey(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutUserApiAccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putApiAccessKey(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApiApi - factory interface
 * @export
 */
export const UserApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteApiAccessKeyRequest} deleteApiAccessKeyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApiAccessKey(deleteApiAccessKeyRequest: DeleteApiAccessKeyRequest, options?: any): AxiosPromise<ApiResponseDeleteApiAccessKeyResponse> {
            return localVarFp.deleteApiAccessKey(deleteApiAccessKeyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApiAccessKeyOfUser(userId: number, options?: any): AxiosPromise<ApiResponseGetApiAccessKeyOfUserResponse> {
            return localVarFp.getApiAccessKeyOfUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} withProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, withProfile: boolean, options?: any): AxiosPromise<ApiResponseGetUserResponse> {
            return localVarFp.getUser(userId, withProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [includeInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserList(includeInactive?: boolean, options?: any): AxiosPromise<ApiResponseGetUserListResponse> {
            return localVarFp.getUserList(includeInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostUserRequest} postUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newUser(postUserRequest: PostUserRequest, options?: any): AxiosPromise<ApiResponsePostUserResponse> {
            return localVarFp.newUser(postUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putApiAccessKey(userId: number, options?: any): AxiosPromise<ApiResponsePutUserApiAccessResponse> {
            return localVarFp.putApiAccessKey(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApiApi - object-oriented interface
 * @export
 * @class UserApiApi
 * @extends {BaseAPI}
 */
export class UserApiApi extends BaseAPI {
    /**
     * 
     * @param {DeleteApiAccessKeyRequest} deleteApiAccessKeyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public deleteApiAccessKey(deleteApiAccessKeyRequest: DeleteApiAccessKeyRequest, options?: AxiosRequestConfig) {
        return UserApiApiFp(this.configuration).deleteApiAccessKey(deleteApiAccessKeyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public getApiAccessKeyOfUser(userId: number, options?: AxiosRequestConfig) {
        return UserApiApiFp(this.configuration).getApiAccessKeyOfUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {boolean} withProfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public getUser(userId: number, withProfile: boolean, options?: AxiosRequestConfig) {
        return UserApiApiFp(this.configuration).getUser(userId, withProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [includeInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public getUserList(includeInactive?: boolean, options?: AxiosRequestConfig) {
        return UserApiApiFp(this.configuration).getUserList(includeInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostUserRequest} postUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public newUser(postUserRequest: PostUserRequest, options?: AxiosRequestConfig) {
        return UserApiApiFp(this.configuration).newUser(postUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiApi
     */
    public putApiAccessKey(userId: number, options?: AxiosRequestConfig) {
        return UserApiApiFp(this.configuration).putApiAccessKey(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserGroupApiApi - axios parameter creator
 * @export
 */
export const UserGroupApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {DeleteUserFromUserGroupRequest} deleteUserFromUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromUserGroup: async (id: number, deleteUserFromUserGroupRequest: DeleteUserFromUserGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserFromUserGroup', 'id', id)
            // verify required parameter 'deleteUserFromUserGroupRequest' is not null or undefined
            assertParamExists('deleteUserFromUserGroup', 'deleteUserFromUserGroupRequest', deleteUserFromUserGroupRequest)
            const localVarPath = `/api/v1/user-groups/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUserFromUserGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeleteUserGroupRequest} deleteUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroup: async (deleteUserGroupRequest: DeleteUserGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteUserGroupRequest' is not null or undefined
            assertParamExists('deleteUserGroup', 'deleteUserGroupRequest', deleteUserGroupRequest)
            const localVarPath = `/api/v1/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteUserGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [includeUser] 
         * @param {boolean} [notIncludeUser] 
         * @param {boolean} [includePolicy] 
         * @param {boolean} [useCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroup: async (id: number, includeUser?: boolean, notIncludeUser?: boolean, includePolicy?: boolean, useCache?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserGroup', 'id', id)
            const localVarPath = `/api/v1/user-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeUser !== undefined) {
                localVarQueryParameter['includeUser'] = includeUser;
            }

            if (notIncludeUser !== undefined) {
                localVarQueryParameter['notIncludeUser'] = notIncludeUser;
            }

            if (includePolicy !== undefined) {
                localVarQueryParameter['includePolicy'] = includePolicy;
            }

            if (useCache !== undefined) {
                localVarQueryParameter['useCache'] = useCache;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostUserGroupRequest} postUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserGroup: async (postUserGroupRequest: PostUserGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postUserGroupRequest' is not null or undefined
            assertParamExists('postUserGroup', 'postUserGroupRequest', postUserGroupRequest)
            const localVarPath = `/api/v1/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutUserToUserGroupRequest} putUserToUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserToUserGroup: async (id: number, putUserToUserGroupRequest: PutUserToUserGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putUserToUserGroup', 'id', id)
            // verify required parameter 'putUserToUserGroupRequest' is not null or undefined
            assertParamExists('putUserToUserGroup', 'putUserToUserGroupRequest', putUserToUserGroupRequest)
            const localVarPath = `/api/v1/user-groups/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putUserToUserGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserGroupApiApi - functional programming interface
 * @export
 */
export const UserGroupApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserGroupApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {DeleteUserFromUserGroupRequest} deleteUserFromUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserFromUserGroup(id: number, deleteUserFromUserGroupRequest: DeleteUserFromUserGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteUserFromUserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserFromUserGroup(id, deleteUserFromUserGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeleteUserGroupRequest} deleteUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserGroup(deleteUserGroupRequest: DeleteUserGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseDeleteUserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserGroup(deleteUserGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [includeUser] 
         * @param {boolean} [notIncludeUser] 
         * @param {boolean} [includePolicy] 
         * @param {boolean} [useCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroup(id: number, includeUser?: boolean, notIncludeUser?: boolean, includePolicy?: boolean, useCache?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetUserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroup(id, includeUser, notIncludeUser, includePolicy, useCache, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseGetUserGroupListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostUserGroupRequest} postUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserGroup(postUserGroupRequest: PostUserGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePostUserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserGroup(postUserGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutUserToUserGroupRequest} putUserToUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserToUserGroup(id: number, putUserToUserGroupRequest: PutUserToUserGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePutUserToUserGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserToUserGroup(id, putUserToUserGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserGroupApiApi - factory interface
 * @export
 */
export const UserGroupApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserGroupApiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {DeleteUserFromUserGroupRequest} deleteUserFromUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFromUserGroup(id: number, deleteUserFromUserGroupRequest: DeleteUserFromUserGroupRequest, options?: any): AxiosPromise<ApiResponseDeleteUserFromUserGroupResponse> {
            return localVarFp.deleteUserFromUserGroup(id, deleteUserFromUserGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeleteUserGroupRequest} deleteUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserGroup(deleteUserGroupRequest: DeleteUserGroupRequest, options?: any): AxiosPromise<ApiResponseDeleteUserGroupResponse> {
            return localVarFp.deleteUserGroup(deleteUserGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {boolean} [includeUser] 
         * @param {boolean} [notIncludeUser] 
         * @param {boolean} [includePolicy] 
         * @param {boolean} [useCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroup(id: number, includeUser?: boolean, notIncludeUser?: boolean, includePolicy?: boolean, useCache?: boolean, options?: any): AxiosPromise<ApiResponseGetUserGroupResponse> {
            return localVarFp.getUserGroup(id, includeUser, notIncludeUser, includePolicy, useCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserGroups(options?: any): AxiosPromise<ApiResponseGetUserGroupListResponse> {
            return localVarFp.getUserGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostUserGroupRequest} postUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserGroup(postUserGroupRequest: PostUserGroupRequest, options?: any): AxiosPromise<ApiResponsePostUserGroupResponse> {
            return localVarFp.postUserGroup(postUserGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutUserToUserGroupRequest} putUserToUserGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserToUserGroup(id: number, putUserToUserGroupRequest: PutUserToUserGroupRequest, options?: any): AxiosPromise<ApiResponsePutUserToUserGroupResponse> {
            return localVarFp.putUserToUserGroup(id, putUserToUserGroupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserGroupApiApi - object-oriented interface
 * @export
 * @class UserGroupApiApi
 * @extends {BaseAPI}
 */
export class UserGroupApiApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {DeleteUserFromUserGroupRequest} deleteUserFromUserGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApiApi
     */
    public deleteUserFromUserGroup(id: number, deleteUserFromUserGroupRequest: DeleteUserFromUserGroupRequest, options?: AxiosRequestConfig) {
        return UserGroupApiApiFp(this.configuration).deleteUserFromUserGroup(id, deleteUserFromUserGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeleteUserGroupRequest} deleteUserGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApiApi
     */
    public deleteUserGroup(deleteUserGroupRequest: DeleteUserGroupRequest, options?: AxiosRequestConfig) {
        return UserGroupApiApiFp(this.configuration).deleteUserGroup(deleteUserGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {boolean} [includeUser] 
     * @param {boolean} [notIncludeUser] 
     * @param {boolean} [includePolicy] 
     * @param {boolean} [useCache] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApiApi
     */
    public getUserGroup(id: number, includeUser?: boolean, notIncludeUser?: boolean, includePolicy?: boolean, useCache?: boolean, options?: AxiosRequestConfig) {
        return UserGroupApiApiFp(this.configuration).getUserGroup(id, includeUser, notIncludeUser, includePolicy, useCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApiApi
     */
    public getUserGroups(options?: AxiosRequestConfig) {
        return UserGroupApiApiFp(this.configuration).getUserGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostUserGroupRequest} postUserGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApiApi
     */
    public postUserGroup(postUserGroupRequest: PostUserGroupRequest, options?: AxiosRequestConfig) {
        return UserGroupApiApiFp(this.configuration).postUserGroup(postUserGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutUserToUserGroupRequest} putUserToUserGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserGroupApiApi
     */
    public putUserToUserGroup(id: number, putUserToUserGroupRequest: PutUserToUserGroupRequest, options?: AxiosRequestConfig) {
        return UserGroupApiApiFp(this.configuration).putUserToUserGroup(id, putUserToUserGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


