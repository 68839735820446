import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import ApiServer from "../../../ApiServer";
import {Button, message, Modal, PageHeader, Popconfirm} from "antd";
import WorkBenchTable from "../../../components/table/workbench-table";
import {mailApprovalColumnProvider, MailApprovalModel} from "./@mod";
import "./index.less"
import EmailDetail from "./e-mail-detail";

interface ApprovalMailModel extends WithTranslation {
    apiServer: ApiServer
}

interface ApprovalMailState {
    mails: MailApprovalModel[]
    loadingMails: boolean
    showEmailDetails: boolean,
    selectedEmail?: MailApprovalModel
}

class MailApproval extends React.Component<ApprovalMailModel, ApprovalMailState> {
    constructor(props: ApprovalMailModel) {
        super(props);
        this.state = {
            mails: [],
            loadingMails: false,
            showEmailDetails: false
        }

    }

    componentDidMount() {
        this.loadMails()
    }

    loadMails = () => {
        this.props.apiServer.apiMail.getMailForApproving().then(x => {
            this.setState({
                mails: x.data.data?.mailSendingDtos?.map(m => {
                    return {
                        id: m.id ?? -1,
                        mailBox: m.mailBox?.emailAddress ?? "",
                        mailBoxId: m.mailBox?.id ?? -1,
                        emailTitle: m.subject ?? "",
                        emailDesc: m.description ?? "",
                        creator: {
                            id: m.creator?.id ?? -1,
                            fullName: m.creator?.fullName ?? ""
                        },
                        createTime: m.createdAt ?? "",
                        updater: {
                            id: m.updater?.id ?? -1,
                            fullName: m.updater?.fullName ?? ""
                        },
                        updateTime: m.updatedAt ?? "",
                    }
                }) ?? []
            })
        })
    }

    notShowEmailDetails = () => {
        this.setState({showEmailDetails: false})
    }

    showEmailDetails = (model: MailApprovalModel) => {
        this.setState({showEmailDetails: true})
    }
    reject = (model: MailApprovalModel) => {
        this.props.apiServer.apiMail.postApproveMailSendApplication(
            model.mailBoxId,
            model.id,
            {
                action: "discard"
            }
        ).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("mails.approval.approve-success")).then(() => {
                })
                this.loadMails()
            } else {
                message.error(this.props.t("mails.approval.approve-fail")).then(() => {
                })
            }

        })
    }

    approve = (model: MailApprovalModel) => {
        this.props.apiServer.apiMail.postApproveMailSendApplication(
            model.mailBoxId,
            model.id,
            {
                action: "approved"
            }
        ).then(r => {
            if (r.data.data?.success) {
                message.success(this.props.t("mails.approval.approve-success")).then(() => {
                })
                this.loadMails()
            } else {
                message.error(this.props.t("mails.approval.approve-fail")).then(() => {
                })
            }

        })
    }
    rejectAndNotShow = () => {
        if (this.state.selectedEmail) {
            this.reject(this.state.selectedEmail)
        }
        this.notShowEmailDetails()
    }

    approveAndNotShow = () => {
        if (this.state.selectedEmail) {
            this.approve(this.state.selectedEmail)
        }
        this.notShowEmailDetails()
    }

    onAction = (model: MailApprovalModel, action: string) => {
        this.setState({
            selectedEmail: model
        })
        if (action === "detail") {
            this.showEmailDetails(model)
        } else if (action === "approve") {
            this.approve(model)
        } else if (action === "reject") {
            this.reject(model)
        }
    }

    renderDetailModalFooter = (): React.ReactNode => {
        return (
            <div>

                <Popconfirm
                    title={this.props.t("mails.approval.confirm-approve")}
                    onConfirm={() => this.approveAndNotShow()}
                >
                    <Button
                        children={this.props.t("mails.approval.approve")}
                    />
                </Popconfirm>


                <Popconfirm
                    title={this.props.t("mails.approval.confirm-reject")}
                    onConfirm={() => this.rejectAndNotShow()}
                >
                    <Button
                        danger={true}
                        children={this.props.t("mails.approval.reject")}
                    />
                </Popconfirm>

            </div>
        )
    }


    render() {
        return (
            <div className={"approval-mail-container"}>
                <PageHeader
                    title={this.props.t("mails.approval.page-title")}
                    subTitle={this.props.t("mails.approval.page-sub-title")}
                >

                    <WorkBenchTable<MailApprovalModel>
                        data={this.state.mails}
                        dataLoading={this.state.loadingMails}
                        columnProvider={() => mailApprovalColumnProvider(this.props.t, this.onAction)}
                    />
                </PageHeader>

                <Modal
                    style={{top: "20px"}}
                    title={this.props.t("mails.approval.email-details") + " - " + this.state.selectedEmail?.mailBox}
                    width={1600}
                    visible={this.state.showEmailDetails}
                    onCancel={() => this.setState({showEmailDetails: false})}
                    footer={this.renderDetailModalFooter()}
                    destroyOnClose={true}
                >
                    <EmailDetail email={this.state.selectedEmail} apiServer={this.props.apiServer}/>
                </Modal>
            </div>
        )
    }

}

export default withTranslation()(MailApproval)