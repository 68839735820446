import { Button, Input } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";

export interface NewAssigneeEditorViewModel extends WithTranslation  {
	onNewAssigneeRefActed?: (name: string) => void
	cancle?: () => void
}

interface NewAssigneeEditorState {
	name: string
}

class NewAssigneeEditor extends React.Component<NewAssigneeEditorViewModel,NewAssigneeEditorState>{

    constructor(props:NewAssigneeEditorViewModel){
        super(props)
        this.state = {
			"name": ""
		}
    }

    render(): React.ReactNode {
        return (<div>
            <div>
                <Input value={this.state.name}
                    placeholder={this.props.t("auto.ref-assignee-name")}
                    onChange={(t) => {
                        this.setState({
                            name: t.target.value
                        })
                    }}></Input>
            </div>
            <div>
                <Button type="primary" onClick={()=>this.props.onNewAssigneeRefActed?.(this.state.name)}>{this.props.t("auto.add-assignee")}</Button>
                <Button onClick={() => this.props.cancle?.()} >{this.props.t("auto.cancel")}</Button>
            </div>

        </div>)
    }
}


export default withTranslation()(NewAssigneeEditor);