import { Button, Card, Select } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import { StatusModel, TicketTypeModel, PriorityModel, SourceModel } from "../@mod";
import './index.less'

export interface ChangeStatusViewModel extends WithTranslation {
	priority?: number,
	type?: number,
	status?: number,
	source?: number,
	cancel?: () => void
	prioritySelection: PriorityModel[],
	typeSelection: TicketTypeModel[],
	statusSelection: StatusModel[],
	sourceSelection: SourceModel[],
	saveStatus?: (
		priority?: number,
		type?: number,
		status?: number,
		source?: number
	) => void
}


interface ChangeStatusViewState {
	priority: number,
	type: number,
	status: number,
	source: number
}




class ChangeStatusView extends React.Component<ChangeStatusViewModel, ChangeStatusViewState>{

	l: Promise<void> | undefined;

	constructor(props:ChangeStatusViewModel){
		super(props)
		this.state ={
			status:props.status??0,
			priority:props.priority??0,
			type:props.type??0,
			source:props.source??0
		}
	}

	componentDidMount() {
		
	}
	componentDidUpdate(){

	}
	
	getSnapshotBeforeUpdate(prop:ChangeStatusViewModel,state:ChangeStatusViewState){
		if(this.props!==prop){
		
			this.setState(
				{
					status:this.props.status??0,
					priority:this.props.priority??0,
					type:this.props.type??0,
					source:this.props.source??0
				}
			)
		}
		return null;
	}




	saveClicked = () => {

		console.log("before click",this.props)
		this.props.saveStatus?.(
			this.state.priority,
			this.state.type,
			this.state.status,
			this.state.source
		);
	}

	getSelectOption = (datArray: any[]) => {
		return datArray.map(dat => (
			<Select.Option key={dat.id} value={dat.id}>
				{dat.description[this.props.i18n.language]}
			</Select.Option>
		))
	}

	

	render(): React.ReactNode {
		return (
			<div className="change-status-view">
				<Card title={this.props.t("tickets.ticket-status")}
					extra={
						[
							<Button type="default" danger key={1} onClick={this.props.cancel}> {this.props.t("tickets.details.cancel")}</Button>,
							<Button type="primary" key={2} onClick={this.saveClicked}> {this.props.t("tickets.details.save")}</Button>
						]
					}>
					<div className="status-view">
						<div className="field">
							<div className="title">
								{this.props.t("tickets.status.self")}

							</div>
							<Select defaultValue={this.props.status} onChange={(v:number)=>this.setState({status:v})}>
								{this.getSelectOption(this.props.statusSelection)}
							</Select>
						</div>
						<div className="field">
							<div className="title">
								{this.props.t("tickets.priority.self")}

							</div>
							<Select defaultValue={this.props.priority} onChange={(v:number)=>this.setState({priority:v})}>
								{this.getSelectOption(this.props.prioritySelection)}
							</Select>
						</div>
						<div className="field">
							<div className="title">
								{this.props.t("tickets.source.self")}

							</div>
							<Select defaultValue={this.props.source} onChange={(v:number)=>this.setState({source:v})}>
								{this.getSelectOption(this.props.sourceSelection)}
							</Select>
						</div>
						<div className="field">
							<div className="title">
								{this.props.t("tickets.type.self")}

							</div>
							<Select defaultValue={this.props.type} onChange={(v:number)=>this.setState({type:v})}>
								{this.getSelectOption(this.props.typeSelection)}
							</Select>
						</div>
					</div>

				</Card>

			</div>
		)
	}

}

export default withTranslation()(ChangeStatusView);

