import { Button, DatePicker, Tag } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import moment, { Moment } from "moment";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";

const { RangePicker } = DatePicker;


export interface ColumnDateRangeFilterViewModel extends WithTranslation {
    context: FilterDropdownProps,
    title?: string
}

interface ColumnDateRangeFilterState {
    appliedSearch: string,
    searchText: string,
    dates:[moment.Moment|null,moment.Moment|null]
}


class ColumnDateRangeFilter extends React.Component<ColumnDateRangeFilterViewModel, ColumnDateRangeFilterState>{

    constructor(props: ColumnDateRangeFilterViewModel) {
        super(props)
        this.state = {
            appliedSearch: "",
            searchText: "",
            dates:[null,null]
        }
    }
    onChange = (dates, dateStrings) => {
        if (dates) {
            this.setState({
                searchText:dateStrings.join('->'),
                dates:dates
            })
        } else {
            this.setState({
                searchText:"",
                dates:[null,null]
            })
        }
    };

    get getHeader() {
        if (this.props.title) {
            return (
                <div className="filter-header" >
                    {this.props.title} {this.getAlliedSearchTag()}
                </div>
            )
        }
        return (<></>)
    }

    getAlliedSearchTag() {
        if (this.state.appliedSearch && this.state.appliedSearch !== "") {
            return (
                <Tag color="blue"> {this.state.appliedSearch} </Tag>
            )
        }
        else {
            return (<></>)
        }
    }

    applySearch = () => {

		console.log(this.props.context);
		this.setState({
			appliedSearch:this.state.searchText
		})
		this.props.context.setSelectedKeys([this.state.searchText]);
		this.props.context.confirm({
			"closeDropdown":true
		})
		
	}

	resetSearch = () => {
		this.setState({
			appliedSearch:"",
			searchText:"",
            dates:[null,null]
		})
		this.props.context.setSelectedKeys([]);
		if(this.props.context.clearFilters){
			this.props.context.clearFilters();
		}
		this.props.context.confirm({
			"closeDropdown":true
		});
	}

    render(): React.ReactNode {
        return (
            <div className="filter-container">

                {this.getHeader}
                <div className="filter-body" >
                    <RangePicker
                        ranges={{
                            "今天": [moment().hour(0).minute(0).second(0), moment().hour(23).minute(59).second(59)],
                            "上周": [moment().week(moment().week()-1).startOf('week').hour(0).minute(0).second(0), moment().week(moment().week()-1).endOf('week').hour(23).minute(59).second(59)],
                            "本周": [moment().startOf('week').hour(0).minute(0).second(0), moment().endOf('week').hour(23).minute(59).second(59)],
                            '上月': [moment().month(moment().month()-1).startOf('month').hour(0).minute(0).second(0), moment().month(moment().month()-1).endOf('month').hour(23).minute(59).second(59)],
                            '本月': [moment().startOf('month').hour(0).minute(0).second(0), moment().endOf('month').hour(23).minute(59).second(59)],
                        }}
                        showTime
                        onChange={this.onChange}
                        placeholder={[this.props.t("start-date-time"),this.props.t("end-date-time")]}
                        value={this.state.dates}
                    />
                </div>
                <div className="filter-footer" >
                    <Button type="primary" onClick={this.applySearch}>{this.props.t("confirm")} </Button>
                    <Button type="primary" onClick={this.resetSearch}>{this.props.t("reset")} </Button>
                </div>


            </div>)
    }
}


export default withTranslation()(ColumnDateRangeFilter)



