import {withTranslation, WithTranslation} from "react-i18next";
import React from "react";
import {Button, message, PageHeader} from "antd";
import ApiServer from "../../../../ApiServer";
import {loadAllMailBoxes, mailBoxConfigureColumnProvider, MailBoxModel} from "../../../mail/@mod";
import MailBoxList from "../../../mail/box-list";
import "./index.less"

interface ClearAbnormalMailboxModel extends WithTranslation {
    apiServer: ApiServer
}

interface ClearAbnormalMailboxState {
    dataLoading: boolean,
    mailBoxes: MailBoxModel[],
    clearBtnLoading: boolean,
    selectedMailboxes: MailBoxModel[]
}

class ClearAbnormalMailbox extends React.Component<ClearAbnormalMailboxModel, ClearAbnormalMailboxState> {
    constructor(props: ClearAbnormalMailboxModel) {
        super(props);
        this.state = {
            dataLoading: false,
            mailBoxes: [],
            clearBtnLoading: false,
            selectedMailboxes: []
        }
    }

    componentDidMount() {
        this.loadMailboxes()
    }

    loadMailboxes = () => {
        this.setState({
            dataLoading: true
        })
        loadAllMailBoxes(this.props.apiServer).then(x => {
            this.setState({
                mailBoxes: x
            })
        }).finally(() => {
            this.setState({
                dataLoading: false
            })
        })
    }

    clearAbnormal = () => {
        if (this.state.selectedMailboxes.length > 0) {
            this.setState({
                clearBtnLoading: true
            })
            this.state.selectedMailboxes.forEach((mailbox) => {
                this.props.apiServer.apiMail.patchMailBox(mailbox.id, {
                    type: "erasure-abnormal"
                }).then((x) => {
                })
            })
            this.setState({
                clearBtnLoading: false,
                selectedMailboxes: []
            })
            message.success(this.props.t("_clear-abnormal-mailbox.request-success"))
            this.loadMailboxes()
        }
    }

    onSelected = (models: MailBoxModel[]) => {
        this.setState({
            selectedMailboxes: models
        })
    }

    render() {
        return (
            <PageHeader
                title={this.props.t("clear-abnormal-mailbox")}
                subTitle={this.props.t("_clear-abnormal-mailbox.sub-title")}
            >
                <div className={"clear-abnormal-mailboxes-container"}>
                    <div className={"operation-bar"}>
                        <Button type={"primary"}
                                loading={this.state.clearBtnLoading}
                                onClick={this.clearAbnormal}
                                disabled={this.state.selectedMailboxes.length === 0}>{this.props.t("_clear-abnormal-mailbox.clear")}
                        </Button>
                    </div>

                    <div className={"mailboxes"}>
                        <MailBoxList
                            dataLoading={this.state.dataLoading}
                            mailBoxes={this.state.mailBoxes}
                            columnTypeProvider={(x) =>
                                mailBoxConfigureColumnProvider(this.props.t, x, this.props.apiServer)}
                            onSelected={this.onSelected}
                        />
                    </div>
                </div>
            </PageHeader>
        )
    }
}

export default withTranslation()(ClearAbnormalMailbox)

