/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteContext, withRouter } from "../../../utils";
import { PageHeader, Button, Descriptions, Tag, Tabs, Statistic, Timeline, Popover, notification, Tooltip, Switch, Dropdown, MenuProps } from "antd";
import {
	PlaybookModel, StatusModel,
	loadTicketPriority,
	loadTicketSource,
	loadTicketStatus,
	loadTicketType,
	PriorityModel,
	SourceModel,
	TicketTypeModel,
	ThreadModel,
	converThreadDtoToModel,

} from "./@mod";
import {
	UserModel,
	loadUserList,
	loadUserGroups,
	UserGroupModel
} from "../@mod"

import {
	ToolOutlined,
	EditOutlined,
	PlusOutlined,
	UnlockOutlined,
	LockOutlined,
	CheckOutlined,
	CaretDownOutlined
} from '@ant-design/icons'

import AssignToView from './assign-to-view'

import './index.less'
import ThreadView from "./thread-view";
import AttachmentView from "./attachment-view";
import ApiServer from "../../../ApiServer";
import {
	convertTo, TagModel, TicketModel
} from "../@mod";
import moment from "moment";
import Countdown from "antd/lib/statistic/Countdown";
import 'antd/lib/style/css'
import ChangeStatusView from "./change-status-view";
import EditableText from "../../../components/editable-text";
import DefaultView from "./description-view/default-view";
import TagEditor from "../tag-editor";
import CommentView from "./comment-view";
import RelatedTickets from "./related-tickets";
import AutomaticView from "./description-view/automatic-view";
import WatchList from "./watch-list";
import modal from "antd/lib/modal";

import CopyNewTicketView from "./copy-new-ticket-view";
import { TicketDto } from "../../../api";
import AgeingView from "./ageing-view";
import TagTreeEditor from "../tag-tree-editor";



export interface TicketDetailPageModel extends WithTranslation {
	router?: RouteContext,
	apiServer: ApiServer,
	ticketId?: number,
	back?: () => void
}



export interface TicketDetailPageState {
	ticketModel?: TicketModel,
	loading: boolean,
	isContentReadOnly: boolean,
	playbook: PlaybookModel,
	assignEditorVisable: boolean,
	changeStateViewVisable: boolean,
	popoverState: number,
	tags: TagModel[],
	allTags: TagModel[]
	id: string,
	activeKey: string
	threads: ThreadModel[],
	userModels: UserModel[],
	userGroupModels: UserGroupModel[],
	isAdmin: boolean
}


const SHOW_ASSIGN_TO: number = 0x01;
const SHOW_STATUS: number = 0x01 << 1;
const SHOW_COPY_NEW: number = 0x01 << 2;


class TicketDetailPage extends React.Component<TicketDetailPageModel, TicketDetailPageState>{
	ticketStatusList: StatusModel[] = [];
	ticketPriorityList: PriorityModel[] = [];
	ticketSourceList: SourceModel[] = [];
	ticketTypeList: TicketTypeModel[] = [];

	constructor(props: TicketDetailPageModel) {
		super(props);
		this.state = {
			isAdmin: false,
			threads: [],
			tags: [],
			loading: true,
			isContentReadOnly: true,
			playbook:
			{
				name: "listing 故障处理流程",
				description: "通用Listing故障处理流程",
				playbookItems: [
					{
						"subject": "登陆Ops",
						"sequence": 1,
						"detail": "登陆到Ops控制台"
					},
					{
						"subject": "登陆到OPS后台",
						"sequence": 2,
						"detail": "点击XXX -> XXX -> xxxx"
					}
				]
			},
			assignEditorVisable: false,
			changeStateViewVisable: false,
			popoverState: 0,
			allTags: [],
			id: props.router?.params["id"] ?? (this.props.ticketId?.toString() ?? "1"),
			activeKey: "1",
			userModels: [],
			userGroupModels: []
		}
	}

	get automatic() {
		return this.state.ticketModel?.type.name === "AUTOMATION";
	}

	l: Promise<void> | undefined;

	componentDidMount() {
		if (!this.l) {
			this.l = this.loadTicketData().finally(() => {
				this.setState({
					loading: false
				})
				this.l = undefined;
			});
		}
	}

	componentDidUpdate() {

	}

	getSnapshotBeforeUpdate(preProp: TicketDetailPageModel, state: TicketDetailPageState) {

		if ((this.props.router?.params["id"] && this.state.id !== this.props.router?.params["id"])) {
			this.setState({ id: this.props.router?.params["id"], activeKey: "1" })
			setTimeout(() => {
				this.componentDidMount();
			}, 10);
		}

		if (this.props.ticketId && this.props.ticketId !== preProp.ticketId) {
			this.setState({ id: this.props.ticketId.toString(), activeKey: "1" })
			setTimeout(() => {
				this.componentDidMount();
			}, 10);
		}

		return null;
	}

	vistableCheck = (t: TicketDto, l: number) => {
		if (t.watchList) {
			if (t.watchList.length > 0 && t.watchList.findIndex(i => i.id === l) >= 0) {
				return true;
			}
		}

		if (t.assigned?.id === l || t.createdBy?.id === l) {
			return true;
		}
		return false;
	}

	async loadTicketData() {
		this.setState({
			loading: true
		})

		var isAdmin = false;
		let loginUser = await this.props.apiServer.apiSystem.getLoginUser()
			.then(
				x => {
					isAdmin = x.data.data?.admin ?? false;
					return x.data.data?.user?.id;

				}
			)
		let ticketOwner = "";
		if (loginUser) {
			let ticketModel: any;
			let r = await this.props.apiServer.apiTickets.getTicket(Number(this.state.id))
				.then(
					x => {

						if (x.data.data?.ticket) {
							ticketOwner = (x.data.data.ticket.createdBy?.fullName) + "(@" + (x.data.data.ticket.createdBy?.name ?? "") + ")"
							if (!x.data.data.ticket.private || isAdmin || this.vistableCheck(x.data.data.ticket, loginUser ?? 0)) {
								let model = convertTo(x.data.data?.ticket);
								ticketModel = model;
								this.setState(
									{
										isAdmin: isAdmin,
										ticketModel: model
									}
								)
								return true
							}
						}
						return false;
					}
				)
			if (r) {
				this.ticketStatusList = await loadTicketStatus(this.props.apiServer, ticketModel?.creator.id === loginUser);
				this.ticketPriorityList = await loadTicketPriority(this.props.apiServer);
				this.ticketSourceList = await loadTicketSource(this.props.apiServer);
				this.ticketTypeList = await loadTicketType(this.props.apiServer);
				await this.props.apiServer.apiTickets.getTicketTags(Number(this.state.id))
					.then(x => {
						this.setState({
							tags: x.data.data?.tags?.map(y => {
								let d: TagModel = {
									id: y.id ?? 0,
									name: y.name ?? "",
									color: y.color ?? "#000000",
								}
								return d

							}) ?? []
						});
					})
				await this.props.apiServer.apiProductivity.getTagList()
					.then(x => {
						let tags = x.data.data?.tagDtoList?.map(y => {
							let t: TagModel = {
								id: y.id ?? 0,
								name: y.name ?? "",
								color: y.color ?? ""
							}
							return t
						})
						this.setState({
							allTags: tags ?? []
						})
					})
				await this.props.apiServer.apiTickets.getTicketThreads(Number(this.state.id))
					.then(x => {
						if (x.data.data?.succeed) {
							let threads = x.data.data.threads?.map(x => {
								return converThreadDtoToModel(x)
							}) ?? [];
							this.setState({
								threads: threads
							})
						}
					})

				await loadUserList(this.props.apiServer).then(x => {
					this.setState({
						userModels: x
					})
				})
				let userGroupModel = await loadUserGroups(this.props.apiServer, false);
				this.setState({
					userGroupModels: userGroupModel,
					loading: false
				})
			}
			else {
				notification.warn({ message: this.props.t("tickets.no-permission-to-access-ticket") + ticketOwner })
				this.goback();
			}
		}




	}

	goback = () => {
		if (this.props.ticketId) {
			this.props.back?.();
		} else {
			window.history.back();
		}

	}

	newTag = (tagId: number) => {
		this.props.apiServer.apiTickets.putTicketTags(
			Number(this.state.id), {
			tagId: tagId
		}
		).then(x => {
			if (x.data.data?.succeed) {
				notification.success({ message: this.props.t("tickets.add-tag-succeed") })
				let tagOwned = this.state.tags;
				this.state.allTags.filter(y => y.id === tagId).forEach(z => tagOwned.push(z));
				this.setState({
					tags: tagOwned
				})
			}
		})
	}

	getTags() {
		return (
			<div>
				<Tag >
					{this.state.ticketModel?.status.description[this.props.i18n.language]}
				</Tag>
				{this.getTicketTags()}
				<Popover trigger="click"
					placement="bottom"
					content={<TagTreeEditor allTags={this.state.allTags} onTagAdd={this.newTag} />}>
					<Tag className="site-tag-plus" >
						<PlusOutlined />
					</Tag>
				</Popover>
			</div>
		)
	}

	tagClose = (e: React.MouseEvent<HTMLElement>, id: number) => {
		e.preventDefault();
		this.props.apiServer.apiTickets.removeTicketTags(
			Number(this.state.id),
			id
		).then(x => {
			if (x.data.data?.succeed) {
				notification.success({ message: this.props.t("tickets.remove-tag-succeed") })
				this.setState(
					{
						tags: this.state.tags.filter(y => y.id !== id)
					}
				)
			}
		})
	}

	getTicketTags = () => {
		return this.state.tags.map(
			x => (
				<Tag color={x.color} closable key={x.id} onClose={(e) => this.tagClose(e, x.id)}>{x.name}</Tag>
			)
		)
	}

	getThreads = () => {
		return this.state.threads.sort((a, b) => {
			return Number(a.createdAt) < Number(b.createdAt) ? 1 : -1;
		}).map(x => {
			return (
				<Timeline.Item key={x.id}>
					<ThreadView
						apiServer={this.props.apiServer}
						ticketTypeList={this.ticketTypeList}
						ticketPriorityList={this.ticketPriorityList}
						ticketSourceList={this.ticketSourceList}
						ticketStatusList={this.ticketStatusList}
						userGroup={this.state.userGroupModels} userList={this.state.userModels} threadModel={x}></ThreadView>
				</Timeline.Item>
			)
		})
	}

	onSaveComment = async (d: string) => {
		return this.props.apiServer.apiTickets.postTicketThread(
			Number(this.state.id),
			{
				message: d,
				bookMarked: false
			}
		)
			.then(x => {
				if (x.data.data?.threadDto) {
					let m = converThreadDtoToModel(x.data.data.threadDto)
					let threads = this.state.threads;
					threads.push(m);
					this.setState({
						threads: threads
					})

					return true;
				}
				return false;
			})
	}

	onTabActive = (k: string) => {
		this.setState({ activeKey: k })
	}

	getFooter = () => {

		return (

			<Tabs defaultActiveKey="1" activeKey={this.state.activeKey} onTabClick={this.onTabActive} >
				<Tabs.TabPane tab={this.props.t("tickets.detail")} key="1" animated={true} >
					{!this.automatic &&
						<DefaultView
							ticketId={this.state.ticketModel?.id ?? 0}
							contentId={this.state.ticketModel?.contentId ?? 0}
							apiServer={this.props.apiServer} />
					}{
						this.automatic &&
						<AutomaticView
							onback={this.goback}
							userModels={this.state.userModels}
							ticketModel={this.state.ticketModel}
							onflush={() => this.loadTicketData()}
							ticketId={this.state.ticketModel?.id ?? 0}
							contentId={this.state.ticketModel?.contentId ?? 0}
							apiServer={this.props.apiServer} />
					}
				</Tabs.TabPane>
				<Tabs.TabPane tab={this.props.t("tickets.thread")} key="2" >
					<div className="tab-comment">
						<CommentView userModels={this.state.userModels} onSave={this.onSaveComment}></CommentView>
					</div>
					<div className="tab-thread" >
						<Timeline mode="left">
							{this.getThreads()}
						</Timeline>
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab={this.props.t("tickets.attachment.self")} key="3" >
					<AttachmentView ticketId={Number(this.state.id)} apiServer={this.props.apiServer} ></AttachmentView>
				</Tabs.TabPane>
				<Tabs.TabPane key={"6"} tab={this.props.t("tickets.watch-list")}>
					<WatchList
						ticketId={this.state.ticketModel?.id ?? 0}
						allUsers={this.state.userModels}
						apiServer={this.props.apiServer}
						currentUser={this.state.ticketModel?.watchList ?? []}
					></WatchList>
				</Tabs.TabPane>
				<Tabs.TabPane key="5" tab={this.props.t("tickets.related.self")}  >
					<RelatedTickets apiServer={this.props.apiServer} ticketId={Number(this.state.id)} />
				</Tabs.TabPane>
				{this.state.ticketModel?.status.name === "CLOSE" &&

					<Tabs.TabPane key={"7"} tab={this.props.t("tickets.efficiency.self")} destroyInactiveTabPane disabled={this.state.ticketModel?.status.name !== "CLOSE"}>
						<AgeingView userModels={this.state.userModels} ticketModel={this.state.ticketModel} apiServer={this.props.apiServer} />
					</Tabs.TabPane>
				}

			</Tabs>
		)
	}

	renderTicketPanel = () => {
		return (
			<div className="meta-field-container">
				<div className="sub-title">
					<div>{this.props.t("tickets.sub-title")}</div>
					<div>
						<EditableText className="grey-text" text={this.state.ticketModel?.subtitle ?? ""} onSave={this.saveBasicSubTitle} />
					</div>
				</div>
				<div className="content">
					<div className="detail">
						<Descriptions size="small" column={3} >
							<Descriptions.Item label={this.props.t("tickets.creator")} key="1" >
								<Tooltip trigger="click" title={this.state.ticketModel?.creator?.organization}>
									{this.state.ticketModel?.creator?.name ?? ""}
								</Tooltip>
							</Descriptions.Item>
							<Descriptions.Item label={this.props.t("tickets.assigned-group")} key="2" >
								<Tooltip trigger="click" title={this.state.ticketModel?.assignedGroup.description}>
									<a>{this.state.ticketModel?.assignedGroup.name}</a>
								</Tooltip>
							</Descriptions.Item>
							<Descriptions.Item label={this.props.t("tickets.assigned")} key="3" >
								<Tooltip trigger="click" title={this.state.ticketModel?.assigned?.organization}>
									<a>{this.state.ticketModel?.assigned?.name}</a>
								</Tooltip>
							</Descriptions.Item>
							<Descriptions.Item label={this.props.t("tickets.created-at")} key="4">
								<Tooltip trigger="click" title={this.state.ticketModel?.createTime ? moment.parseZone(this.state.ticketModel?.createTime).format(this.props.t("tickets.time-pattern-long")) : ""}>
									{this.state.ticketModel?.createTime ? moment.parseZone(this.state.ticketModel?.createTime).format(this.props.t("tickets.time-pattern-short")) : ""}
								</Tooltip >
							</Descriptions.Item>
							<Descriptions.Item label={this.props.t("tickets.latest-time")} key="5">
								<Tooltip trigger="click" title={this.state.ticketModel?.updateTime ? moment.parseZone(this.state.ticketModel?.updateTime).format(this.props.t("tickets.time-pattern-long")) : ""}>
									{this.state.ticketModel?.updateTime ? moment.parseZone(this.state.ticketModel?.updateTime).format(this.props.t("tickets.time-pattern-short")) : ""}
								</Tooltip>
							</Descriptions.Item>
							<Descriptions.Item label={this.props.t("tickets.effected-time")} key="6">
								<Tooltip trigger="click" title={this.state.ticketModel?.sla ? moment.parseZone(this.state.ticketModel?.sla).format(this.props.t("tickets.time-pattern-long")) : ""}>
									{this.state.ticketModel?.sla ? moment.parseZone(this.state.ticketModel?.sla).format(this.props.t("tickets.time-pattern-short")) : ""}
								</Tooltip>
							</Descriptions.Item>
						</Descriptions>
					</div>
					<div className="extra">
						<div className="highline-bar">
							<Statistic
								title={this.props.t("tickets.source.self")}
								value={this.state.ticketModel?.source.description[this.props.i18n.language]}
							/>
							<Statistic title={this.props.t("tickets.type.self")}
								value={this.state.ticketModel?.type.description[this.props.i18n.language]}
							/>
							<Statistic
								title={this.props.t("tickets.priority.self")}
								value={this.state.ticketModel?.priority.description[this.props.i18n.language]}
								valueStyle={{
									color: this.state.ticketModel?.priority.description["color"]
								}}
							/>
							{this.getDeadLine()}
						</div>
					</div>
				</div>
			</div>

		)
	}

	getDeadLine = () => {
		if (this.state.ticketModel?.sla) {
			var sla = moment.parseZone(this.state.ticketModel?.sla)

			if (this.state.ticketModel?.status.name) {
				if (this.state.ticketModel.status.name === "CLOSE") {
					let lastUpdate = moment.parseZone(this.state.ticketModel.updateTime);
					let dur = moment.duration(sla.diff(lastUpdate));
					if (dur.asHours() > 0) {
						return (<Statistic title={this.props.t("tickets.time-left")}
							valueStyle={{ color: "green" }}
							value={this.props.t("tickets.completed-on-time")}></Statistic>)
					} else {
						return (
							<Statistic title={this.props.t("tickets.time-left")}
								valueStyle={{ color: "red" }}
								value={this.props.t("tickets.completed-over-due")}></Statistic>)
					}
				}
			}

			let dur = moment.duration(sla.diff(moment.now()));
			if (dur.asHours() < 0) {

				return (
					<Statistic title={this.props.t("tickets.time-left")}
						valueStyle={{ color: "red" }}
						value={this.props.t("tickets.time-up")}></Statistic>
				)

			} else {


				return (
					<Countdown title={this.props.t("tickets.time-left")}
						value={Date.now() + dur.asMilliseconds()}
						format={"D|HH:mm:ss"}
					/>
				)
			}
		}

	}

	saveState = async (
		priority?: number,
		type?: number,
		status?: number,
		source?: number) => {
		await this.props.apiServer.apiTickets.putTicketCoreState(
			Number(this.state.id),
			{
				priorityId: priority,
				statusId: status,
				type: type,
				source: source
			}
		).then(x => {
			if (x.data.data?.succeed) {
				notification.info({
					message: this.props.t("tickets.update-status-succeed")
				})
				this.setState(
					{
						popoverState: 0
					}
				)
				this.componentDidMount();
			}
		})

	}

	assignTicketTo = (userGroupId: number, userId: number) => {

		this.setState({
			popoverState: 0
		})
		this.props.apiServer.apiTickets.putAssignTicket(Number(this.state.id), {
			userGroupId: userGroupId,
			userId: userId
		}).then(x => {
			if (x.data.data?.succeed) {
				notification.info({ message: this.props.t("tickets.update-succeed") });
				this.loadTicketData();
			} else {
				notification.error({ message: this.props.t("tickets.update-fail") });
			}
		})

	}
	saveBasic = async (title: string, subtitle: string) => {
		await this.props.apiServer
			.apiTickets.putTicketBasic(Number(this.state.id), {
				subject: title,
				subTitle: subtitle
			}).then(x => {
				if (x.data.data?.succeed) {
					notification.info({ message: this.props.t("tickets.update-basic-info-succeed") })
				}
			})
	}

	saveBasicTitle = async (v: string) => {
		if (v !== this.state.ticketModel?.subject) {
			await this.saveBasic(v, this.state.ticketModel?.subtitle ?? "");
			await this.loadTicketData()
		}
	}

	saveBasicSubTitle = async (v: string) => {
		if (v !== this.state.ticketModel?.subtitle) {
			await this.saveBasic(this.state.ticketModel?.subject ?? "", v);
			await this.loadTicketData()
		}
	}

	getCopyButton = () => {
		if (!this.automatic) {
			return (
				<Popover title="" trigger={"click"} key={1}
					visible={(this.state.popoverState & SHOW_COPY_NEW) === SHOW_COPY_NEW}
					content={<CopyNewTicketView
						apiServer={this.props.apiServer}
						userGroup={this.state.ticketModel?.assignedGroup}
						cancel={() => { this.setState({ popoverState: 0 }) }}
						ticketModel={this.state.ticketModel}
					></CopyNewTicketView>

					}
					destroyTooltipOnHide
					placement="leftTop">
					<Button key="2" type="dashed" onClick={() => this.setState({ popoverState: this.state.popoverState === SHOW_COPY_NEW ? 0 : SHOW_COPY_NEW })}>{this.props.t("tickets.copy-new-ticket")}</Button>
				</Popover>
			);
		}
		return <></>
	}

	getAssignButton = () => {
		if (!this.automatic || this.state.isAdmin) {
			return (
				<Popover title="" trigger={"click"} key={2}
					visible={(this.state.popoverState & SHOW_ASSIGN_TO) === SHOW_ASSIGN_TO}
					content={<AssignToView
						apiServer={this.props.apiServer}
						user={this.state.ticketModel?.assigned}
						userGroup={this.state.ticketModel?.assignedGroup}
						onAssigned={this.assignTicketTo}
						cancel={() => { this.setState({ popoverState: 0 }) }}
					></AssignToView>}
					placement="leftTop"
					destroyTooltipOnHide
				>
					<Button key="2" type="dashed" onClick={() => this.setState({ popoverState: this.state.popoverState === SHOW_ASSIGN_TO ? 0 : SHOW_ASSIGN_TO })}>{this.props.t("tickets.assign-to")}</Button>
				</Popover>
			);
		}
		else {
			return (<></>)
		}
	}

	getStatusButton = () => {
		return (
			<Popover key="3"
				destroyTooltipOnHide={true}
				visible={(this.state.popoverState & SHOW_STATUS) === SHOW_STATUS}
				content={
					(
						<ChangeStatusView
							type={this.state.ticketModel?.type.id}
							priority={this.state.ticketModel?.priority.id}
							source={this.state.ticketModel?.source.id}
							status={this.state.ticketModel?.status.id}
							typeSelection={this.ticketTypeList}
							prioritySelection={this.ticketPriorityList}
							sourceSelection={this.ticketSourceList}
							statusSelection={this.ticketStatusList}
							saveStatus={this.saveState}
							cancel={() => {
								this.setState({
									popoverState: 0
								})
							}}
						/>)
				}
				placement="bottom"
			>
				{
					this.state.ticketModel?.status.name !== "CLOSE" && !this.automatic &&
					<Dropdown.Button key="5" type="primary"
						menu={this.getQuickAccess()}
						onClick={() => this.setState({ popoverState: this.state.popoverState === SHOW_STATUS ? 0 : SHOW_STATUS })}
						icon={<CaretDownOutlined />} >{this.props.t("tickets.edit")}
					</Dropdown.Button>

				}
				{
					(this.state.ticketModel?.status.name === "CLOSE" || this.automatic) &&
					<Button key="5" type="primary"
						onClick={() => this.setState({ popoverState: this.state.popoverState === SHOW_STATUS ? 0 : SHOW_STATUS })}>{this.props.t("tickets.edit")}
					</Button>
				}


			</Popover>
		)
	}

	resolvedTicket = () => {

		var defaultUserGroups =
			this.state.userGroupModels.filter(x => x.name === "Default").at(0);

		let ugId = this.state.ticketModel?.assignedGroup.id ?? 1;
		if (defaultUserGroups) {
			ugId = defaultUserGroups.id;
		}
		let creator = this.state.ticketModel?.creator?.id ?? 1;

		this.assignTicketTo(
			ugId,
			creator
		);
		let resolvedStatus = this.ticketStatusList.filter(x => x.name === 'RESOLVED').at(0)

		this.saveState(
			this.state.ticketModel?.priority.id,
			this.state.ticketModel?.type.id,
			resolvedStatus?.id,
			this.state.ticketModel?.source.id
		);

	}

	closeTicket = () => {
		let closeStatus = this.ticketStatusList.filter(x => x.name === 'CLOSE').at(0)
		this.saveState(
			this.state.ticketModel?.priority.id,
			this.state.ticketModel?.type.id,
			closeStatus?.id,
			this.state.ticketModel?.source.id
		);
	}

	getQuickAccess = (): MenuProps => {
		if (!this.automatic) {
			let items: any = [

			];
			if (this.state.ticketModel?.status.name === "RESOLVED") {
				items.push({
					key: "closeTicket",
					label: this.props.t("tickets.close"),
					icon: <CheckOutlined />,
				})
			} else if (this.state.ticketModel?.status.name !== "CLOSE") {
				items.push({
					key: "resolvedTicket",
					label: this.props.t("tickets.resolved"),
					icon: <ToolOutlined />,
				})
			}
			return {
				items: items,
				onClick: (info) => {
					this[info.key]();
				}
			}

		}
		return {
			items: [],
		};
	}


	getOperationButton = () => {
		if (this.automatic) {
			return [
				this.getAssignButton(),
				this.state.isAdmin ? this.getStatusButton() : <></>,
				this.props.t("tickets.automatic-desc")
			];
		}

		return [
			/*<Button key="1" type="dashed" >{this.props.t("tickets.assign-to-me")}</Button>,*/
			this.getCopyButton(),
			this.getAssignButton(),
			this.getStatusButton()

		]
	}

	updateVisable = () => {
		this.props.apiServer.apiTickets.postSetTicketVisibility(
			this.state.ticketModel?.id ?? 0,
			!this.state.ticketModel?.isPrivate
		).then(x => {
			if (x.data.data?.success) {
				let ticketModel = this.state.ticketModel;
				if (ticketModel) {
					ticketModel.isPrivate = !x.data.data.public
					this.setState({
						ticketModel: ticketModel
					})
				}
			} else {
				notification.error({ message: this.props.t("tickets.update-visable-fail") })
			}
			this.setState({
				loading: false
			})
		});
	}

	setVisable = () => {
		this.setState({
			loading: true
		})

		if (this.state.ticketModel?.isPrivate) {
			modal.warning({
				"title": this.props.t("tickets.setPublicConfirm"),
				okText: this.props.t("tickets.setPublic"),
				cancelText: this.props.t("tickets.cancle"),
				okCancel: true,
				onCancel: () => this.setState({ loading: false }),
				onOk: () => this.updateVisable()
			});
		} else {
			modal.warning({
				"title": this.props.t("tickets.setPrivateConfirm"),
				okText: this.props.t("tickets.setPrivate"),
				cancelText: this.props.t("tickets.cancle"),
				okCancel: true,
				onCancel: () => this.setState({ loading: false }),
				onOk: () => this.updateVisable()
			});

		}


	}

	render(): React.ReactNode {
		return (
			<div>
				<div className="site-page-header-ghost-wrapper">
					<PageHeader
						ghost={false}
						onBack={() => this.goback()}
						title={<EditableText text={this.state.ticketModel?.subject ?? ""} onSave={this.saveBasicTitle} />}
						subTitle={<Switch
							loading={this.state.loading}
							checkedChildren={<div><LockOutlined />{this.props.t("tickets.private")}</div>}
							unCheckedChildren={<div><UnlockOutlined />{this.props.t("tickets.public")}</div>}
							checked={this.state.ticketModel?.isPrivate}
							onChange={this.setVisable}
						/>}
						extra={this.getOperationButton()}
						tags={this.getTags()}
						footer={this.getFooter()} >
						{this.renderTicketPanel()}
					</PageHeader>
				</div>
			</div>)
	}
}

export default withRouter(withTranslation()(TicketDetailPage))