import { Tag } from "antd";
import React from "react";
import { withTranslation } from "react-i18next";
import { TagModel } from "../@mod";
import './index.less'

export interface TagSelectorViewModel{
	allTags?:TagModel[]
	onTagAdd?:(tagId:number)=>void
}

interface TagSelectorViewState {
	
}

class TagSelectorView extends React.Component<TagSelectorViewModel,TagSelectorViewState>{
	renderTags = ()=>{
		return this.props.allTags?.map(x=>{
			return(
				<Tag className="tag-instance" color={x.color} onClick={()=>this.props.onTagAdd?.(x.id)} >{x.name}</Tag>
			)
		})??<></>;
	}

	render(): React.ReactNode {
		return(
			<div className="tag-editor-ticket">
				{this.renderTags()}
			</div>
		)
	}
}

export default withTranslation()(TagSelectorView)