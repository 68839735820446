import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import './index.less'

export interface ManagementFooterModel extends WithTranslation {
	companyName:string,
	clientVersion:string,
	serverVersion:string
}


class ManagementFooter extends React.Component<ManagementFooterModel,{}>{
	render(): React.ReactNode {
		return (
		<div className="footer-container">
			{this.props.t("footer.copyright")} 2022 © {this.props.companyName} -- {this.props.t("footer.clientBuild")}: {this.props.clientVersion} {this.props.t("footer.serverBuild")}: {this.props.serverVersion}
		</div>);
	}

}

export default withTranslation()(ManagementFooter);