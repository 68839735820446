import { WithTranslation } from "react-i18next";
import { ActionEventHandler } from "../../../../../../components";
import { ColumnType } from "antd/lib/table";
import { Button, Popconfirm } from "antd";
import moment from "moment";

export const deliverableColumnProvider = (props: WithTranslation, handler: ActionEventHandler<any>, enableDelete?: boolean, enableDownload?: boolean): ColumnType<any>[] => {
    let translater = props.t;
    enableDelete = enableDelete === undefined ? true : enableDelete
    enableDownload = enableDownload === undefined ? true : enableDownload
    return [
        {
            title: translater("project.task.task-deliverable.name"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: translater("project.task.task-deliverable.storage"),
            dataIndex: 'storageType',
            key: 'storageType',
            render: ((val, record, index) => record.storageType.cn)
        },
        {
            title: translater("project.task.task-deliverable.uploader"),
            dataIndex: 'creator',
            key: 'creator',
            render: (val, record, index) => record.creator.fullName
        },
        {
            title: translater("project.task.task-deliverable.upload-time"),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (val, record, index) => {
                return moment.parseZone(record.createdAt).format("YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            title: translater("project.task.task-deliverable.operation"),
            render: (val, record, index) => {
                let deleteBtn = <Popconfirm title={translater("project.task.confirm-delete-deliverable")} onConfirm={() => handler(record, "delete")}>
                    <Button
                        size={"small"}
                        danger
                        className={"left-btn"}
                    >
                        {translater("project.task.delete")}
                    </Button>
                </Popconfirm>
                let downloadBtn = <Button
                    size={"small"}
                    onClick={() => handler(record, "download")}
                >
                    {translater("project.task.download")}
                </Button>
                return (
                    <div className={"operation-bar"}>
                        {enableDelete ? deleteBtn : (<></>)}
                        {enableDownload ? downloadBtn : (<></>)}
                    </div>

                )
            }
        }
    ];
}
export const materialColumnProvider = (trans: WithTranslation, handler: ActionEventHandler<any>): ColumnType<any>[] => {
    let translater = trans.t;
    return [
        {
            title: translater("project.task.task-knowledge.name"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: translater("project.task.task-knowledge.storage"),
            dataIndex: 'storageType',
            key: 'storageType',
            render: ((val, record, index) => record.storageType.cn)
        },
        {
            title: translater("project.task.task-knowledge.creator"),
            dataIndex: 'creator',
            key: 'creator',
            render: (val, record, index) => record.creator.fullName
        },
        {
            title: translater("project.task.task-knowledge.create-time"),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (val, record, index) => {
                return moment.parseZone(record.createdAt).format("YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            title: translater("project.task.task-knowledge.operation"),
            render: (val, record, index) => {
                return (
                    <div className={"operation-bar"}>
                        <Button
                            size={"small"}
                            onClick={() => handler(record, "download")}
                        >
                            {translater("project.task.download")}
                        </Button>
                    </div>

                )
            }
        }
    ];
}