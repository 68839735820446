import { withTranslation, WithTranslation } from "react-i18next";
import React from "react";
import { Tree } from "antd";
import { ProjectProcessModel } from "../../@mod";

interface ProjectProcessViewModel extends WithTranslation {
    processList: ProjectProcessModel[]
    expandedKeys: string[]
}

interface ProjectProcessViewState {


}

class ProjectProcessView extends React.Component<ProjectProcessViewModel, ProjectProcessViewState> {
    constructor(props: ProjectProcessViewModel) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <Tree
                    fieldNames={{
                        title: "title",
                        key: "key"
                    }}
                    treeData={this.props.processList}
                    defaultExpandedKeys={this.props.expandedKeys}
                />
            </>
        );
    }
}

export default withTranslation()(ProjectProcessView)