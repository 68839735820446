import React from "react";
import {WithTranslation, withTranslation} from "react-i18next";
import ApiServer from "../../../../ApiServer";
import {Button, Checkbox, Form, Input, Select} from "antd";
import "./index.less"
import {PostServiceInstanceSubscribeServiceResponse, UserDto} from "../../../../api";


interface ProjectSubscribeViewModel extends WithTranslation {
    projectToApply?: number,
    apiServer: ApiServer,
    onSuccess: (response: PostServiceInstanceSubscribeServiceResponse) => void,
    onFail: () => void,
}

interface ProjectSubscribeViewState {
    userList: UserDto[]
}

class ProjectSubscribeView extends React.Component<ProjectSubscribeViewModel, ProjectSubscribeViewState> {
    constructor(props: ProjectSubscribeViewModel) {
        super(props);
        this.state = {
            userList: []
        }
    }


    componentDidMount() {
        this.getUserList()
    }

    getUserList = () => {
        this.props.apiServer.apiUser.getUserList(false).then(r => {
            if (r.data.data?.list) {
                this.setState({
                    userList: r.data.data.list
                })
            }
        })
    }

    subscribeProject = (values) => {
        if (this.props.projectToApply) {
            let requestBody = {
                title: values.applyTitle,
                acceptor: values.applyAcceptor,
                description: values.applyDescription,
                commit: false
            }
            this.props.apiServer.apiProjectMeta.postSubscribeService(this.props.projectToApply, requestBody).then(r => {
                if (r.data.data?.success) {
                    this.props.onSuccess(r.data.data)
                } else {
                    this.props.onFail()
                }
            })
        }
    }


    render() {
        return (<div>
            <Form
                labelCol={{span: 5}}
                onFinish={this.subscribeProject}
            >
                <Form.Item
                    label={this.props.t("project.service.application-title")}
                    name={"applyTitle"}
                    rules={[{required: true, message: this.props.t("project.service.input-application-title")}]}>
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={this.props.t("project.service.application-description")}
                    name={"applyDescription"}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={this.props.t("project.service.fields.acceptor")}
                    name={"applyAcceptor"}
                    rules={[{required: true, message: this.props.t("project.service.select-acceptor")}
                    ]}
                >
                    <Select
                        filterOption={(input, option) => String(option?.children).toLowerCase().includes(input.toLowerCase())}
                        showSearch
                    >
                        {
                            this.state.userList.map(u => {
                                return (
                                    <Select.Option key={u.id} value={u.id}>{u.fullName}</Select.Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item>
                    <div className={"btn-container"}>
                        <Button className={"btn"} htmlType={"submit"}>{this.props.t("project.service.subscribe")}</Button>
                    </div>
                </Form.Item>
            </Form>
        </div>)
    }
}

export default withTranslation()(ProjectSubscribeView)

