import { EscalationRuleDtoV1 } from "../../../../../api"
import ApiServer from "../../../../../ApiServer"

export interface Frequency{
    type:string,
    fixRate?:number
    fixTime?:string
}

export interface EscalationRuleModel {
    id:number,
    order:number,
    name:string,
    enabled:boolean,
    description:string,
    type:number[],
    status:number[],
    priority:number[],
    source:number[]
    timeAxis:string,
    timeConstraint:number,
    timeDirection:string,
    frequency:Frequency,
    actions:string[],
    sectionEdited:number
}


export function convertToViewMode(y:EscalationRuleDtoV1):EscalationRuleModel {
    let freq:Frequency = {
        type:"oneTime"
    };
    Object.assign(freq,y.frequency);
    let escalationModel : EscalationRuleModel= {
        id: y.id??0,
        name:y.name??"",
        order:y.order??0,
        enabled:y.enabled??false,
        description:y.description??"",
        type:y.type??[],
        status:y.status??[],
        priority:y.priority??[],
        source:y.source??[],
        timeAxis:y.timeAxis??"",
        timeConstraint:y.timeConstraint??0,
        timeDirection:y.timeDirection??"",
        frequency:freq,
        actions:y.actions??[],
        sectionEdited:0
    }



    return escalationModel;
}

export const fetchEscalationRules = (apiServer:ApiServer):Promise<EscalationRuleModel[]> =>{

    return apiServer.apiSystem.getEscalationRules().then(x=>{
        let result:EscalationRuleModel[] =[];
        
        x.data.data?.rules?.forEach(y=>{
            result.push(convertToViewMode(y))
        });
        return result;
    })
}