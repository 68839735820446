import React from "react";
import './index.less';
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import { notification } from "antd";
import FormEditor from "../form-editor";
import ApprovalEditor from "../approval-editor";
import DataDisplayView from "../data-display-view";
import { TicketModel, UserModel } from "../../../@mod";

import AssigneeView from "../assignee-view";

interface AutomaticViewModel extends WithTranslation {
	ticketModel?: TicketModel
	ticketId: number,
	contentId: number,
	apiServer: ApiServer,
	userModels: UserModel[],
	onflush?: () => void,
	onback?: () => void
}

interface AutomaticViewState {
	text?: ContentModel,
	popupVisiable: boolean[]
	currentLogin: number,
	ticketModel?: TicketModel
}

interface ContentModel {

	[key: string]: ContentItemModel
}

interface ContentItemModel {
	type: string,
	schema?: number,
	filter?: any,
	value: string,
	editable?: boolean,
	stage?: number,
	reject?: string,
	key?: string,
	anchor?: string,
	proxy?: string


}

class AutomaticView extends React.Component<AutomaticViewModel, AutomaticViewState>{

	constructor(props: AutomaticViewModel) {
		super(props)
		this.state = {
			text: undefined,
			popupVisiable: [false, false],
			currentLogin: 0
		}
	}

	componentDidUpdate() {


	}

	loadData = async () => {
		if (this.props.contentId !== 0) {
			await this.props.apiServer.apiTickets.getAutomaticContent(this.props.ticketId, this.props.contentId)
				.then(x => x.data.data?.contentDto?.content ?? "")
				.then(x => {
					try {
						let contentModel: ContentModel | undefined = JSON.parse(x);
						if (contentModel) {
							this.setState({ text: contentModel })
						}
					} catch {
						notification.error({ message: this.props.t("tickets.content-parse-fail") })
					}
				})
			await this.props.apiServer.apiSystem.getLoginUser()
				.then(x => {
					this.setState(
						{
							currentLogin: x.data.data?.user?.id ?? 0
						}
					)
				})
		}
	}

	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData().finally(() => {
				this.l = undefined
			})
		}


	}

	getSnapshotBeforeUpdate(beforeProps: AutomaticViewModel, beforeState: AutomaticViewState) {
		if (this.props.contentId !== beforeProps.contentId) {
			this.loadData();
		}
		if (this.state.ticketModel != beforeProps.ticketModel) {
			this.setState({
				ticketModel: this.props.ticketModel
			})
		}
		return null;
	}

	saveData = (k: string, v: ContentItemModel) => {
		let contentModel = this.state.text;
		if (contentModel) {
			contentModel[k] = v;
		}
		this.saveDescription(contentModel);
	}

	saveDescription = (v?: ContentModel) => {
		if (v) {
			let contentModel = v;
			this.props.apiServer.apiTickets.putAutomaticProcessContent(this.props.ticketId, this.props.contentId,
				{
					data: JSON.stringify(contentModel)
				}).then(x => {
					if (x.data.data?.succeed) {
						notification.success({ message: this.props.t("tickets.content-update-succeed") })
						this.setState({
							text: contentModel
						});
						this.loadData();
						this.props.onflush?.();
					} else {
						notification.error({ message: this.props.t("tickets.content-update-fail") })
					}
				})
		}
	}

	mergeContents = (primary: string, other: string[]) => {
		let result = {};
		other.forEach(x => {
			result = Object.assign(result, JSON.parse(x));
		})
		result = Object.assign(result, JSON.parse(primary));
		return JSON.stringify(result);
	}

	get getEditor() {
		if (this.state.text) {
			let contentModel: ContentModel = this.state.text;
			let editors: React.ReactNode[] = [];

			let contentItemModelList: ContentItemModel[] = [];
			for (let k in contentModel) {
				contentModel[k].key = k;
				contentItemModelList.push(contentModel[k]);
			}
			contentItemModelList = contentItemModelList.sort((a, b) => Number(a.stage) - Number(b.stage));
			for (let j = 0; j < contentItemModelList.length; j++) {
				let contentItemModel = contentItemModelList[j]
				if (contentItemModel.type === "form") {
					let otherContent = contentItemModelList.filter(x => x.type === "form").map(x => x.value);
					let mergedRef = this.mergeContents(contentItemModel.value, otherContent);
					editors.push((<FormEditor
						onCommit={(v) => {
							this.saveData(contentItemModel.key ?? "", {
								"type": "form",
								value: v,
								stage: contentItemModel.stage,
								schema: contentItemModel.schema,
								editable: contentItemModel.editable
							})
						}}
						onRemove={() => this.removeForm(contentItemModel.key ?? "")}
						apiServer={this.props.apiServer}
						schemaId={contentItemModel.schema ?? 0}
						content={mergedRef}
						canEdit={contentItemModel.editable ?? false}
						key={contentItemModel.key}></FormEditor>))
				}
				if (contentItemModel.type === "approval") {
					editors.push((
						<ApprovalEditor
							onback={this.props.onback}
							userModels={this.props.userModels}
							approverName={this.state.ticketModel?.assigned?.name ?? ""}
							showApprover={this.state.ticketModel?.assigned?.id === this.state.currentLogin}
							key={contentItemModel.key}
							editable={contentItemModel.editable ?? false}
							reject={contentItemModel.reject}
							value={contentItemModel.value}
							onApprovalSubmit={(v) => {
								this.saveData(contentItemModel.key ?? "", {
									"type": "approval",
									value: v,
									stage: contentItemModel.stage,
									editable: contentItemModel.editable,
									reject: contentItemModel.reject
								})
							}}
						></ApprovalEditor>
					))
				}
				if (contentItemModel.type === "assignee") {
					editors.push((
						<AssigneeView
							userModels={this.props.userModels}
							content={contentItemModel.value}
							editable={contentItemModel.editable ?? false}
							onAssigneeSubmit={
								(v) => {
									this.saveData(contentItemModel.key ?? "", {
										"type": "assignee",
										value: v,
										stage: contentItemModel.stage,
										editable: contentItemModel.editable,
									})
								}
							}
						/>
					));
				}

				if (contentItemModel.type === "data") {
					editors.push(<DataDisplayView
						type={contentItemModel.proxy ?? ""}
						anchor={contentItemModel.anchor ?? ""}
						apiServer={this.props.apiServer}
						key={contentItemModel.key}
					></DataDisplayView>)
				}
			}
			return (<div>{editors}</div>)

		}
		return (<></>)

	}

	removeForm = (field: string) => {
		let contentModel = this.state.text;
		if (contentModel) {
			if (contentModel[field]) {
				let newConent: ContentModel = {

				};
				for (let k in contentModel) {
					if (k !== field) {
						newConent[k] = contentModel[k];
					}
				}
				this.setState(
					{
						text: newConent
					}
				)
				this.saveDescription(newConent);
			}
		}
	}

	render(): React.ReactNode {
		return (
			<div className="description-view-container" >
				{this.getEditor}
			</div>
		)
	}
}
export default withTranslation()(AutomaticView)