import React  from "react";

export const i18nMenu = [
	{
		key:"cn",
		languageName:"简体中文",
		languageCode:"cn",
		flag: (<span className="fi fi-cn"></span> )
	},
	{
		key:"en",
		languageName:"English",
		languageCode:"en",
		flag: (<span className="fi fi-us"></span>)
	}
]




