import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { convertTo, TicketModel } from "../@mod";
import AssignedTickets from "../assigned-tickets";


export interface TeamTicketPageModel extends WithTranslation {
	apiServer: ApiServer
}

interface TeamTicketPageState {
	title: string;
	subtitle: string;
	tickets: TicketModel[],
	loading: boolean

}

class TeamTicketPage extends React.Component<TeamTicketPageModel, TeamTicketPageState>{

	constructor(props: TeamTicketPageModel) {
		super(props)
		this.state = {
			title: "team-ticket",
			subtitle: "tickets.assigned-to-team-desc",
			tickets: [],
			loading: true
		}
	}

	async loadData() {
		await this.props.apiServer.apiTickets.getTicketList(false)
			.then(x => {
				let tickets = x.data.data?.tickets?.map(convertTo)
				this.setState({
					tickets: tickets ?? []
				})
			});
	}

	l: Promise<void> | undefined;

	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(
					() => {
						this.setState({
							loading: false
						});
					})
		}
	}


	render(): React.ReactNode {
		return(<>
		<AssignedTickets
				title={this.state.title}
				subtitle={this.state.subtitle}
				tickets = {this.state.tickets}
				loading ={this.state.loading}
				apiServer={this.props.apiServer}
				withCreateNewTicketButton
		></AssignedTickets>
		</>)
	}
}


export default withTranslation()(TeamTicketPage);