import { UserModel } from "./users/@mod";

export const MockUser = ():UserModel[]=>{
	let userModels:UserModel[] = [];
	for(var i=0;i< 100;i++){
		let userModel: UserModel = {
			key:i+"",
			name:"mock-user-"+i,
			fullName:"mock-user-"+i,
			created: new Date().toISOString(),
			updated: new Date().toISOString(),
			source:"db",
			organization:"org1",
			phone:"123131312"+i,
			email:"mock"+i +"@flyamz.com",
			active: i%7===0
			
		}
		userModels.push(userModel);
	}
	return userModels;
}