import { Button, Collapse, Input, Modal, notification, PageHeader, Switch, Tag } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import './index.less'
import { UnorderedListOutlined, RightOutlined } from "@ant-design/icons"
import { TicketStatusModel, TicketTypeModel, TicketSourceModel, TicketPriorityModel, loadMetaData } from "../../@mod";
import TicketSelector from "./ticket-selector";
import TimeAxisSelector from "./time-axis-selector";
import FrequencySelector from "./frequency-selector";
import ActionSelector from "./action-selector";
import {
    SaveOutlined,
    DeleteOutlined
} from "@ant-design/icons"
import { EscalationRuleModel, fetchEscalationRules } from "./@mod";
import { ActionModel, fetchEscalationActions } from "../@mod";

const FILTER_UPDATE = 0x01;
const TIME_SCHDULE_UPDATE = 0x01 << 1;
const TRIGGER_FREQUENCY_UPDATE = 0x01 << 2;
const TRIGGER_ACTION_UPDATE = 0x01 << 3;


export interface EscalationViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface EscalationState {
    ticketTypes: TicketTypeModel[]
    ticketStatus: TicketStatusModel[]
    ticketSource: TicketSourceModel[]
    ticketPriority: TicketPriorityModel[]
    escalactionRuleModels: EscalationRuleModel[],
    actions:ActionModel[],
    dataLoading: boolean,
    newRuleVisiable: boolean,
    dragDisabled: boolean
    dragBounds: any,
    newTitle: string,
    newDescription: string,
    updatedStatus:number
}

class Escalation extends React.Component<EscalationViewModel, EscalationState>{

    constructor(props: EscalationViewModel) {
        super(props)
        this.state = {
            ticketTypes: [],
            ticketStatus: [],
            ticketSource: [],
            ticketPriority: [],
            escalactionRuleModels: [],
            dataLoading: false,
            newRuleVisiable: false,
            dragDisabled: false,
            dragBounds: { left: 0, top: 0, bottom: 0, right: 0 },
            newTitle: "",
            newDescription: "",
            updatedStatus:0,
            actions:[]

        }
    }

    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(
                    () => {
                        this.setState({
                            dataLoading: false
                        })
                    }
                );
        }
    }

    async loadData() {
        let src = await loadMetaData(this.props.apiServer);
        let rules = await fetchEscalationRules(this.props.apiServer);
        let actions = await fetchEscalationActions(this.props.apiServer);
        this.setState(
            {
                actions:actions,
                ticketTypes: src.ticketType,
                ticketPriority: src.ticketPriority,
                ticketSource: src.ticketSource,
                ticketStatus: src.ticketStatus,
                escalactionRuleModels: rules
            }
        )
    }

    id: number = 0
    showNewRulesEditor = () => {
        this.setState({
            newRuleVisiable: true,
            newTitle: "",
            newDescription: ""
        })
    }

    onEditorChanged = (editor:number,model:EscalationRuleModel) => {
        model.sectionEdited = editor | model.sectionEdited
        this.setState({
            escalactionRuleModels:this.state.escalactionRuleModels
        })
    }

    newRules = () => {
        this.setState({
            newRuleVisiable: false
        })
        let rules = this.state.escalactionRuleModels;
        
        let newRule: EscalationRuleModel = {
            id: -1 * (this.id++ + 1),
            order: rules.length,
            name: this.state.newTitle === "" ? "New Rule " + rules.length : this.state.newTitle,
            enabled: false,
            description: this.state.newDescription === "" ? "New Decription " + rules.length : this.state.newDescription,
            type: [],
            status: [],
            priority: [],
            source: [],
            timeAxis: "sla",
            timeConstraint: 60 * 24 * 3,
            timeDirection: "after",
            frequency: {
                type: "oneTime"
            },
            actions: [],
            sectionEdited:0
        }


        rules.push(newRule);
        this.setState({
            escalactionRuleModels: rules
        });
        console.log(this.state.escalactionRuleModels);

    }

    ruleswitchClicked = (e, mouseEvent: React.MouseEvent<HTMLButtonElement>, rule: EscalationRuleModel) => {
        mouseEvent.stopPropagation();
        if (rule.id < 0) {
            notification.warn({ message: this.props.t("tickets.rule-not-save-could-not-enable") })
        } else{
            this.props.apiServer.apiSystem.putEscalationEnabled(rule.id,{
                enabled:e
            }).then(x=>{
                if(x.data.data?.succeed){
                    rule.enabled = x.data.data.rule?.enabled??false;
                    notification.success({ message: this.props.t("tickets.rule-enabled-succeed") })
                    this.flushModel();
                }else{
                    notification.warn({ message: this.props.t("tickets.rule-enabled-fail") })
                }
            });
        }
    }

    updateRule = (rule:EscalationRuleModel)=>{
        if(rule.sectionEdited===0){
            notification.info({message:this.props.t("tickets.rule-no-changed")})
        }else{
            if((rule.sectionEdited & FILTER_UPDATE) ===FILTER_UPDATE){
                this.props.apiServer.apiSystem.putEscalationTicketFilter(rule.id,{
                    type:rule.type,
                    status:rule.status,
                    priority:rule.priority,
                    source:rule.source,
                })
                .then(x=>{
                    if(x.data.data?.succeed){
                        rule.type = x.data.data.rule?.type??[]
                        rule.status = x.data.data.rule?.status??[]
                        rule.priority = x.data.data.rule?.priority??[]
                        rule.source = x.data.data.rule?.source??[]
                        rule.sectionEdited = rule.sectionEdited ^ FILTER_UPDATE;
                        this.flushModel();
                        notification.success({message: rule.name + ":"+this.props.t("tickets.rule-update-ticket-filter-succeed")})
                    }
                })
            }
            if((rule.sectionEdited & TIME_SCHDULE_UPDATE) === TIME_SCHDULE_UPDATE){
                this.props.apiServer.apiSystem.putEscalationTimeSheet(rule.id,{
                    timeAxis:rule.timeAxis,
                    timeConstraint:rule.timeConstraint,
                    timeDirection:rule.timeDirection,
                })
                .then(x=>{
                    if(x.data.data?.succeed){
                        rule.timeAxis= x.data.data?.rule?.timeAxis??"";
                        rule.timeConstraint=x.data.data?.rule?.timeConstraint??0;
                        rule.timeDirection= x.data.data?.rule?.timeDirection??"";
                        rule.sectionEdited = rule.sectionEdited ^ TIME_SCHDULE_UPDATE;
                        this.flushModel();
                        notification.success({message: rule.name + ":"+ this.props.t("tickets.rule-update-time-schedule-succeed")})
                    }
                })
            }
            if((rule.sectionEdited & TRIGGER_FREQUENCY_UPDATE )=== TRIGGER_FREQUENCY_UPDATE){
                let o : any = rule.frequency.type
                let fr :any = rule.frequency.fixRate;
                let ft : any = rule.frequency.fixTime;
                this.props.apiServer.apiSystem.putEscalationTimeFrequency(rule.id,{
                    frequency:{
                        "type": o,
                        "fixRate":fr,
                        "fixTime":ft
                    }
                }).then(x=>{
                    if(x.data.data?.succeed){
                        console.log(x.data.data.rule)
                        Object.assign(rule.frequency,x.data.data.rule?.frequency)
                        rule.sectionEdited = rule.sectionEdited ^ TRIGGER_FREQUENCY_UPDATE;
                        this.flushModel();
                        notification.success({message: rule.name + ":"+ this.props.t("tickets.rule-update-trigger-frequency-succeed")})
                    }
                })
            }

            if((rule.sectionEdited & TRIGGER_ACTION_UPDATE )=== TRIGGER_ACTION_UPDATE){
                this.props.apiServer.apiSystem.putEscalationActions(
                    rule.id,
                    {
                        actions:rule.actions
                    }
                ).then(x=>{
                    if(x.data.data?.succeed){
                        rule.actions = x.data.data.actions?.map(x=>x.name??"")||[];
                        rule.sectionEdited = rule.sectionEdited ^ TRIGGER_ACTION_UPDATE;
                        this.flushModel();
                        notification.success({message: rule.name + ":"+ this.props.t("tickets.rule-update-actions-succeed")})
                    }
                })
                
            }
            
        }
    }


    saveClickedRuleClicked = (mouseEvent, rule:EscalationRuleModel) => {
        mouseEvent.stopPropagation();
        
        if(rule.id<0){
            let o : any = rule.frequency.type
            let fr :any = rule.frequency.fixRate;
            let ft : any = rule.frequency.fixTime;
            this.props.apiServer.apiSystem.postEscalationRule(
                {
                    order:rule.order,
                    name:rule.name,
                    description:rule.description,
                    enabled:false,
                    type:rule.type,
                    status:rule.status,
                    priority:rule.priority,
                    source:rule.source,
                    timeAxis:rule.timeAxis,
                    timeConstraint:rule.timeConstraint,
                    timeDirection:rule.timeDirection,
                    frequency:{
                        "type": o,
                        "fixRate":fr,
                        "fixTime":ft
                    },
                    actions:rule.actions
                }
            ).then(x=>{
                if(x.data.data?.succeed){
                    if(x.data.data.rule){
                        rule.id = x.data.data.rule.id??0
                        rule.sectionEdited=0
                        this.flushModel();
                    }
                    notification.success({message:this.props.t("tickets.new-rule-added-successed")})
                }
            })
        }else{
            this.updateRule(rule);
        }

    }

    deleteRuleClicked = (mouseEvent, id: number) => {
        mouseEvent.stopPropagation();
        let rules = this.state.escalactionRuleModels;
        if (id < 0) {
            rules = rules.filter(x => x.id !== id);
            this.setState({
                escalactionRuleModels: rules
            })
        }
    }

    flushModel = ()=>{
        this.setState({
            escalactionRuleModels: this.state.escalactionRuleModels
        })
    }


    renderRules = () => {

        return this.state.escalactionRuleModels.map(x => {
            return (
                <Collapse.Panel header={(
                    <div style={{textAlign:"left"}}><h3>{x.name}</h3><h5>{x.description}</h5></div>

                )} key={x.id} extra={
                    [
                        x.id < 0 ? <Tag>{this.props.t("tickets.escalation-rule-unsave")}</Tag> : <></>,
                        x.sectionEdited > 0 ? <Tag>{this.props.t("tickets.escalation-rule-unsave-update")}</Tag> : <></>,
                        <Button type="text" icon={<SaveOutlined />}
                            onClick={(event) => this.saveClickedRuleClicked(event, x)}
                        ></Button>,
                        <Button danger type="text"
                            onClick={(event) => this.deleteRuleClicked(event, x.id)}
                            icon={<DeleteOutlined />}></Button>,
                        <Switch checkedChildren={this.props.t("tickets.rule-enable")}
                            unCheckedChildren={this.props.t("tickets.rule-disable")}
                            checked={x.enabled}
                            onClick={(data, event) => this.ruleswitchClicked(data, event, x)}
                        ></Switch>
                    ]

                }>
                    <div className="rules-content" >
                        <div className="ticket-filter">
                            <TicketSelector
                                onChanged={()=> this.onEditorChanged(FILTER_UPDATE,x)}
                                ticketPriority={this.state.ticketPriority}
                                ticketSource={this.state.ticketSource}
                                ticketTypes={this.state.ticketTypes}
                                ticketStatus={this.state.ticketStatus}
                                dataLoading={this.state.dataLoading}
                                rule={x}
                            />
                        </div>
                        <div className="spliter">
                            <RightOutlined style={{ fontSize: "24px" }} />
                        </div>
                        <div className="ticket-axis">
                            <TimeAxisSelector
                            onChanged={()=> this.onEditorChanged(TIME_SCHDULE_UPDATE,x)}
                                key={x.id}
                                rule={x} />
                        </div>
                        <div className="spliter">
                            <RightOutlined style={{ fontSize: "24px" }} />
                        </div>
                        <div className="ticket-frequency-selctor">
                            <FrequencySelector
                                onChanged={()=> this.onEditorChanged(TRIGGER_FREQUENCY_UPDATE,x)}
                                rule={x}
                            />
                        </div>
                        <div className="spliter">
                            <RightOutlined style={{ fontSize: "24px" }} />
                        </div>
                        <div className="ticket-action-selector">
                            <ActionSelector actions={this.state.actions} rule={x}
                                onChanged={()=> this.onEditorChanged(TRIGGER_ACTION_UPDATE,x)}
                            ></ActionSelector>
                        </div>
                    </div>
                </Collapse.Panel>
            )
        }
        ) || [];
    }

    render(): React.ReactNode {
        return (
            <div className="escalation-definition-container">
                <PageHeader title={this.props.t("escalations.self")}
                    subTitle={this.props.t("escalations.description")}
                    extra={[
                        <Button type="primary"
                            loading={this.state.dataLoading}
                            icon={<UnorderedListOutlined />}
                            onClick={this.showNewRulesEditor}
                        >
                            {this.props.t("escalations.add-rules")}
                        </Button>
                    ]}
                >
                    <div>

                        <Collapse defaultActiveKey={['1']} >
                            {this.renderRules()}
                        </Collapse>

                    </div>
                    <Modal visible={this.state.newRuleVisiable}
                        onOk={this.newRules}
                        onCancel={() => { this.setState({ newRuleVisiable: false }) }}
                        centered
                        title={this.props.t("tickets.new-ticket-escalation-rules")}
                    >
                        <div className="field">
                            <div>{this.props.t("tickets.new-ticket-escalation-rule-title")}</div>
                            <Input value={this.state.newTitle} onChange={(v) => this.setState({ newTitle: v.target.value })} ></Input>
                        </div>
                        <div className="field">
                            <div>{this.props.t("tickets.new-ticket-escalation-rule-description")}</div>
                            <Input value={this.state.newDescription} onChange={(v) => this.setState({ newDescription: v.target.value })}></Input>
                        </div>


                    </Modal>
                </PageHeader>

            </div>
        )
    }

}

export default withTranslation()(Escalation);
