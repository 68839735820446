function getLanguageEN(obj) {
	var langEN = {
		"mail-approval": "Approve E-Mail",
		"ticket-new-template": "New Ticket Template",
		"my-related-ticket": "My Related Tickets",
		"start-date-time": "Start Date Time",
		"end-date-time": "End Date Time",
		"statistics": "Statistics",
		"tickets-statistics": "Tickets Statistics",
		"template": "Template",
		"organazation": "Organazation",
		"full-name": "Full Name",
		"account-status": "Account Status",
		"login-as": "Login As",
		"time-pattern-long-second": "YYYY/MM/DD HH:mm:ss",
		"time-pattern-long": "YYYY/MM/DD HH:mm",
		"plugin-installed": "Installed Plugin",
		"ticket-editor": "Ticket Editor",
		"automation": "Automation",
		"from-server": obj,
		"home": "Home",
		"voice":"Voice",
		"system": "System",
		"ticket-search": "Ticket Search",
		"export": "Export",
		"performance": "Performance",
		"escalation": "Escalation",
		"operation": "System Operation",
		"reload-reflex-system": "Reload reflex-system",
		"_reload-reflex-system": {
			"sub-title": "Reload the reflex-system by docker container number after backend published.",
			"reload": "Reload",
			"input-docker-number": "Please input docker container number",
			"reload-success": "Reload success",
			"reload-fail": "Reload fail",
		},
		"clear-abnormal-mailbox": "Clear abnormal mailbox",
		"_clear-abnormal-mailbox": {
			"sub-title": "Clear abnormal status of mailboxes",
			"clear": "Clear Abnormal",
			"request-success": "Request Success",
		},
		"system-setting": {
			"self": "System Settings",
			"description": "System Settings which are avaiable",
			"latest-update": "-Latest updated:",
			"save": "Save",
			"reset": "Reset",
			"save-success": "Save Success",
			"save-failed": "Save Failed",
			"confirm-reset-title": "Confirm reset to the default value?",
			"reset-success": "Reset Success",
			"reset-failed": "Reset Failed"
		},
		"escalations": {
			"self": "Escalation Management",
			"description": "Define how the sink ticket to becoming fresh."
		},
		"enrolled-instances": "Enrolled",
		"withdraw": "Withdrawed",
		"tasks-management": "Tasks MGR",
		"instances-management": "Instance MGR",
		"enrollment": "Enrollment",
		"cluster": "Cluster",
		"enroll": "Enrollment",
		"cluster-mgr": {
			"enroll-pending": "Enroll Pending",
			"enroll-pending-description": "The instances which are waiting for approval to enroll",
			"instance": {
				"name": "Instance Name",
				"identifier": "Unique Identifier",
				"instance-types": {
					"email": "Email",
					"crawler": "Crawler"
				},
				"publicIp": "Public IP",
				"type": "Instance Type",
				"accessIp": "Access IP",
				"tags": "Tags",
				"approval": "Approval",
				"operation": "Operation",
				"approve-enroll": "Approve",
				"reject-enroll": "Reject",
				"status": "Status",
				"instance-status": {
					"enroll-pending": "Enroll Pending"
				},
				"operation-succeed": "Operation Succeed",
				"operation-fail": "Operation Fail",
				"approval-succeed": "Enroll Succeed",
				"approval-fail": "Enroll Fail",
				"reject-succeed": "Reject operation completed",
				"reject-fail": "Reject operation fail"
			},
			"operation-succeed": "Operation succeed",
			"operation-fail": "Operation fail",
			"remove": "Remove",
			"connective-status": {
				"desc": "Connective Status"
			}

		},
		"project": {
			"workbench": {
				"title": "Workbench",
				"sub-title": "Start a happy day",
				"service-count-to-describe": "Number Of Services To Subscribe",
				"service-count-to-approve": "Number Of Services To Approve",
				"service-count-to-start": "Number Of Services To Start",
				"service-count-to-accept": "Number Of Services To Accept",
				"click-to-handle": "Click Here To Handle",
				"deadline-calendar": "Deadline Calendar",
				"deadline-today": "Today's Deadline",
				"deadline-out-date": "Out Of Date",
			},
			"menus": {
				"landing": "Landing Page",
				"market": "Service Market",
				"my-subscribed": "My Subscribed",
				"my-service": "My Service",
				"my-project": "My Project",
				"subscribed-services": "Subscribed",
				"acceptance-services": "Acceptance",
				"launched-services": "Launched",
				"participate-services": "Participating",
				"approval-services": "Approval",
				"start-services": "Start",
				"processing-services": "Processing",
				"project-statistic": "Statistic",
				"project-template": "Template",
			},
			"service": {
				"fields": {
					"id": "ID",
					"title": "Title",
					"project": "Project",
					"description": "Description",
					"status": "Status",
					"status-comment": "Status Comment",
					"subscriber": "Subscriber",
					"acceptor": "Acceptor",
					"subscribe-time": "Subscribe Time",
					"start-time": "Start Time",
				},
				// market
				"market-title": "Service Market",
				"market-sub-title": "Look for the services you need at here.",
				"input-keyword": "Input Keyword For Search",
				"search": "Search",
				"view-details": "Details",
				"subscribe": "Subscribe",
				"subscribe-success": "Subscribe Success",
				"subscribe-fail": "Subscribe Fail",
				// subscribe
				"subscribe-title": "Subscribe Service",
				"subscribe-sub-title": "All the services you subscribe to are here.",
				"submit-application": "Submit Application",
				"submit-application-confirm": "Confirm submit application?",
				"submit-application-success": "The application has been submitted successfully. Please wait for approval",
				"submit-application-fail": "Failure to submit application",
				"delete-draft": "Delete",
				"delete-draft-confirm": "Confirm delete?",
				"delete-draft-success": "Delete success",
				"delete-draft-fail": "Delete fail",
				"application-title": "Title",
				"application-description": "Description",
				"input-application-title": "Please input application title",
				"select-acceptor": "Please select acceptor",
				// accept
				"acceptance-title": "Acceptance Service",
				"acceptance-sub-title": "All the services you need to check are here",
				"acceptance-service": "Acceptance Service",
				"acceptance-success": "Acceptance success",
				"acceptance-fail": "Acceptance fail",
				"whether-pass": "Whether pass",
				"select-whether-pass": "Please select whether to pass the acceptance check",
				"pass": "Pass",
				"not-pass": "No pass",
				"cancel": "Cancel",
				"confirm": "Confirm",
				"accept-desc": "Comment",
				"input-accept-desc": "Please input comment",
				// launched
				"launched-title": "Launched Service",
				"launched-sub-title": "The services you've been launched are here",
				// eng
				"eng-title": "Participate Service",
				"eng-sub-title": "The services you participate in are here",
				// approve
				"approve-title": "Approve Service",
				"approve-sub-title": "All the services you need to approve are here",
				"approve": "Approve",
				"approve-service": "Approve Service",
				"approve-success": "Approve success",
				"approve-fail": "Approve fail",
				"select-whether-pass-approval": "Please determine whether to approve the application",
				"whether-pass-approval": "Whether pass",
				"approve-desc": "Comment",
				"input-approve-desc": "Please input comment",
				"start-time": "Start Time",
				"start-now": "Start Now",
				"start-later": "Start Later",
				//start
				"start-title": "Start Service",
				"start-sub-title": "All the services waiting for you to start are here",
				"start-success": "Start success",
				"start-fail": "Start fail",
				"start-confirm": "Confirm start this service?",
				"start-service": "Start Service",
				// processing
				"processing-title": "Processing Service",
				"processing-sub-title": "Service in process",
				"confirm-terminate-service": "Confirm Terminate This Service?",
				"terminate-service": "Termanate Service",
				"teminated-success": "Terminate service successed.",
				"teminated-failed": "Terminate service failed.",
				"terminate-reason": "Reason For Termination",
				"please-input-reason-for-termination": "please input the reason for termination",
				// statistic
				"statistic-title": "Project service statistics",
				"statistic-sub-title": "Statistics of project services",
				"project-title": "Project Title",
				// details
				"service-details-title": "Service Details",
				"service-details-sub-title": "Show details of service",
				"base-info": "Base Info",
				"status-log": "Status Log",
				"status-log-empty": "No Status Log",
				"milestone": "Milestone",
			},
			"task": {
				"fields": {
					"id": "ID",
					"title": "Title",
					"type": "Type",
					"description": "Description",
					"status": "Status",
					"executor": "Executor",
					"redo-count": "Redo Count",
					"sub-item": "Whether Sub Task",
					"leaf": "Whether Leaf Task",
					"service": "Service",
					"pre": "Pre Task",
					"parent": "Parent Task",
					"start-time": "Started Time",
					"end-time": "Finished Time",
					"lower-end-time": "Shorter Finish Time",
					"higher-end-time": "Longer Finish Time",
				},
				"processing-task": "Processing Task",
				"finished-task": "Finished Task",
				"not-start-task": "Not Start Task",
				"task-progress": "Task Progress",
				"task-detail-title": "Task Details",
				"task-detail-sub-title": "Page for display details of task",
				"base-info": "Base Info",
				"deliverable": "Deliverable",
				"knowledge": "Knowledge",
				"change-executor": "Change Executor",
				"submit-task": "Submit Task",
				"confirm-submit-task": "Confirm submit task?",
				"yes": "Yes",
				"no": "No",
				"task-deliverable": {
					"name": "Name",
					"storage": "Store Type",
					"uploader": "Uploader",
					"upload-time": "Upload Time",
					"operation": "Operation",
				},
				"task-knowledge": {
					"name": "Name",
					"storage": "Store Type",
					"creator": "Creator",
					"create-time": "Create time",
					"operation": "Operation",
				},
				"delete": "Delete",
				"download": "Download",
				"not-start": "Not Start",
				"not-end": "Not End",
				"statusChangeLog": "Status Log",
				"update-executor-success": "Update Executor Successed",
				"update-executor-fail": "Update Executor Failed",
				"submit-success": "Submit Successed",
				"submit-fail": "Submit Failed",
				"delete-success": "Delete Successed",
				"delete-fail": "Delete Failed",
				"confirm": "Confirm",
				"cancel": "Cancel",
				"cannot-delete-submited": "Can't Delete, because task submited.",
				"confirm-delete-deliverable": "Confirm delete this deleverable?",
				"start-follow-up-task": "Start Next",
				"start-follow-up-task-success": "Start next task success",
				"start-follow-up-task-failed": "Start next task failed",
				"current-status": "Current Status",
				"start-time": "Start Time",
				"fast-end-time": "Fast End Time",
				"slow-end-time": "Slow End Time",
				"remaining-time": "Remaining Time",
				"end-time": "End Time",
			},
			"template": {
				"fields": {
					"id": "ID",
					"title": "Title",
					"description": "Description",
					"version": "Version",
					"owner": "Owner",
					"status": "Status",
					"creator": "Creator",
					"create-time": "Create Time",
				},
				"deliverable": {
					"name": "Name",
					"description": "Description",
					"type": "Type",
					"required": "Required",
					"operation": "Operation",
				},
				"task": {
					"fields": {
						"name": "Name",
						"description": "Description",
						"type": "Type",
						"executor": "Executor",
						"required": "Required",
						"estimated": "Estimated",
						"operation": "Operation",
						"lower-limit": "Min Completion Time",
						"higher-limit": "Max Completion Time",
					},
					"hour": " hour(s)",
					"types": {
						"file": "FILE",
						"parallel_task": "PARALLEL TASK",
						"serial_task": "SERIAL TASK",
						"ticket": "TICKET",
						"meeting": "MEETING",
						"serial_milestone": "SERIAL MILESTONE",
						"parallel_milestone": "PARALLEL MILESTONE",
						"form": "FORM",
						"process": "PROCESS",
					},
					"meta": {
						"list-title": "Task Meta Data",
						"fields": {
							"id": "ID",
							"title": "Title",
							"description": "Description",
							"type": "Type",
							"owner": "Owner",
							"executor": "Executor",
							"lowerEstimatedTime": "Fast End Time",
							"higherEstimatedTime": "Slow End Time",
							"createdTime": "Created Time",
						},
						"operation": "Operation",
						"edit": "Edit",
						"upload-knowledge": "Upload Knowledge",
						"hour": " hour(s)",
						"undefined": "undefined",
						"update-success": "Update Success",
					}


				},
				"editor-comment": {
					"title": "Project name",
					"version": "Click to modify, the same version number cannot exist under the same project",
					"owner": "Click to edit, specify the owner of the project, the owner will have the responsibility of approval",
					"save-draft": "Save existing content as a draft and continue editing later",
					"publish-project": "Publish the project, you can choose whether to activate, if you choose to activate, the rest versions of the project will be frozen",
					"project-description": "Describe the main purpose of the project",
					"pre-requirement": "Prerequisites for project initiation, generally used to define the things that service subscribers need to deliver",
					"milestone": "Project milestones, between which will be executed serially in a defined order",
					"deliverable-type": "Deliverable Type",
					"milestone-type": "Milestone type, tasks under sequential milestones will be executed serially in order, and tasks under sequential milestones will be executed simultaneously",
					"task-type": "Task type, the subtasks under the sequential task will be executed serially in order, and the subtasks under the parallel task will be executed simultaneously",
					"task-executor": "Task executor, sequential tasks and parallel tasks do not require executor",
					"batch-operation": "Batch operation for task",
				},
				"yes": "Yes",
				"no": "No",
				"template-title": "Template Management",
				"template-sub-title": "Manage template of project.",
				"active": "Active",
				"freeze": "Freeze",
				"success": " success",
				"fail": " fail",
				"upgrade-template": "Upgrade Template",
				"project-title": "Project Title",
				"search": "Search",
				"editor": "Project Template Editor",
				"publish": "Publish Project",
				"confirm-blank": "Confirm ",
				"continue-editing": "Continue Editing",
				"delete-draft": "Delete",
				"save-success": "Save Success",
				"save-fail": "Save Fail",
				"publish-success": "Publish Success",
				"publish-fail": "Publish Fail",
				"input-title": "Please input project title here",
				"input-description": "Please input project description here",
				"input-version": "Please edit project version here",
				"add-deliverable": "Add Deliverable",
				"edit-deliverable": "Edit Deliverable",
				"add-task": "Add Task",
				"add-task-disable": "Disabled. Only serial or parallel tasks are available",
				"edit-task": "Edit Task",
				"add-milestone": "Add MileStone",
				"delete": "Delete",
				"confirm-delete": "Confirm Delete?",
				"cancel": "Cancel",
				"confirm": "Confirm",
				"move-up": "Move Up",
				"click-edit-version": "Click to edit version",
				"click-edit-owner": "Click to edit owner",
				"save-draft": "Save Draft",
				"pre-requirement": "pre-requirement",
				"template-view-title": "Project Details",
				"template-description-title": "Project Description",
				"milestone": "Milestones",
				"version": "Version",
				"owner": "Owner",
				"deliverable-content": "Deliverables and requirements",
				"input-deliverable-name": "Please input the name of deliverable",
				"select-deliverable-type": "Please select the type of deliverable",
				"select-required": "Please select whether required",
				"required": "Required",
				"optional": "Optional",
				"submit": "Submit",
				"milestone-name": "Name",
				"input-milestone-name": "Please input the name of milestone",
				"milestone-type": "Type",
				"select-milestone-type": "Please select the type of milestone",
				"description": "Description",
				"input-task-name": "Please input the name of task",
				"select-task-type": "Please select the type of task",
				"select-task-executor": "Please select the executor of task",
				"time-placeholder": "Unit: hour",
				"whether-active-after-publish": "Whether active after publishing?",
				"pre-required": "Required",
				"task-manager": "Task Manager",
				"batch-operation": "Batch Operation",
				"update-executor": "Update Executor",
				"greater-than-or-equal-zero": "Non-negative number needed",
				"greater-than-zero": "Greater than zero needed",
				"greater-than-lower-time": "Need not less than the minimum completion time",
				"input-higher-limit": "Please enter the estimated maximum completion time",
				"input-lower-limit": "Please enter the estimated minimum completion time",
				"ticket": {
					"subject": "Ticket Subject",
					"subtitle": "Ticket Subtitle",
					"priority": "Ticket Priority",
					"subject-placeholder": "The ticket subject is set to the project name by default",
					"subtitle-placeholder": "The ticket subtitle is set to the task name by default",
					"priority-placeholder": "The ticket priority is set to normal by default",
				}
			},
			"confirm-select-user": "Confirm use this user?",
		},
		"service": "Services(Preview)",
		"market": "Service Market",
		"subscribed": "Subscribed",
		"accept": "Acceptance",
		"launched": "Launched",
		"eng": "Participating",
		"approve": "Approve",
		"start": "Start",
		"processing": "Processing",
		"statistics-project": "Statistics",
		"templates-project": "Templates",
		"logoff": "Logoff",
		"auto": {
			"please-contact": "Please contact",
			"getting-approval": "to get Approval!",
			"date": "Date",
			"operator": "Approver",
			"message": "Approval Message",
			"reject": "Reject",
			"message-required": "Approval message is required.",
			"approval-message": "Please add the reject reason",
			"cancle": "Cancle",
			"reject-to": "Reject To ",
			"agree": "Agree",
			"approval-process": "Approval Process",
			"owner": "Owner",
			"hands": "Executor",
			"process-editor": "Automatic Process Editor",
			"visiable-at-state": "Visiable",
			"editable-at-state": "Editable",
			"ref-forms": "Reference Form",
			"reject-to-state": "Reject To",
			"ref-approval": "Approval Reference",
			"ref-template": "Template Reference",
			"new-ref-approval": "New Approval Reference",
			"new-ref-forms": "New Template Reference",
			"add-form-validation-fail": "Please select form and names",
			"add-form": "Add Template Reference",
			"ref-form-name": "Refence Form Name",
			"cancel": "Cancel",
			"ref-approval-name": "Approval Reference Name",
			"add-approval": "Add Approval Reference",
			"process-def": "Define Process",
			"process-config": "Configure Process",
			"time-shift-config": "Time Config",
			"done": "Done",
			"process-stage-index": "Index",
			"process-stage-name": "Name",
			"process-stage-type": "Type",
			"form": "Form",
			"approval": "Approval",
			"close-state": "Complete",
			"add-new-process": "New Node",
			"delete": "Delete",
			"arrange-stage": "Arrange Stage",
			"next": "Next",
			"prev": "Prev",
			"process-edit-complete": "Process Configuration Completed!",
			"save-process": "Save Process",
			"time-axis": "Axis",
			"lastUpdated": "Last Update",
			"created": "Created",
			"sla": "SLA",
			"time-condition": "Condition",
			"time-met": "Minutes",
			"assignToParent": "Assign to upset",
			"after": "After",
			"before": "Before",
			"when": "When",
			"time-at": "At",
			"time-shift-title": "Time Shift Configure",
			"add-time-shift-item": "Add Configure",
			"minutes": "After Minutes",
			"ref-data-type": "Reference Data Type",
			"save-succeed": "Save Succeed",
			"verifying": "Verifying.......",
			"please-enter-name": "Please enter the process name",
			"verify-fail": "Verify Fail",
			"verify-succeed": "Verify Succeed",
			"save-template-succeed": "Process Template Save succeed",
			"back-to-edit": "Return",
			"back-to-list": "Return to List",
			"process-list-title": "Automation Ticket Process",
			"process-list-sub-title": "Use for the ticket automation",
			"name": "Process Name",
			"description": "Process description",
			"updateTime": "Updated",
			"createTime": "Created",
			"process-editor-title": "Automation Process Editor",
			"ref-data": "Refernce Data",
			"new-ref-data": "New Data Reference",
			"process-name": "Process Name",
			"process-description": "Process Description",
			"new": "Create New Process"
		},
		"user": "User",
		"installed": "Installed",
		"plugin": "Plugin",
		"accessControl": "Access Control",
		"access-control": "Access Control",
		"landing": "Landing Page",
		"sms": "SMS",
		"notification": "Notification",
		"email": "E-Mail",
		"cropwechat": "Crop-Wechat",
		"feishu": "Feishu",
		"members": "Members",
		"users": "Users",
		"user-group": "User Group",
		"security": "Security",
		"privilege": "Privilege",
		"policy": "Policy",
		"productivity": "Productivity",
		"templates": "Templates",
		"tags": "Tags",
		"ticket": "Ticket",
		"ticket-meta": "Ticket Metas",
		"settings": "Settings",
		"insert": "Insert",
		"delete": "Delete",
		"confirm": "Confirm",
		"confirm-delete": "Confirm Delete",
		"cancel": "Cancel",
		"reset": "Reset",
		"my-ticket": "My Tickets",
		"team-ticket": "Team Tickets",
		"detail": "Detail",
		"knowledge-base": "Knowledge Base",
		"ticket-meta-description": "Ticket meta data Management",
		"hours": "Hours",
		"attachment": "Attachment",
		"upload-text": "Drag and drop the attachment",
		"upload-hit": "It supports Multiple attachments",
		"access-control-landing": "Wellcome-Access Control Module",
		"access-control-landing-desc": "This module allow you to add,change,remove the participant's identity, permission who will coodinate using this system.",
		"translate": {
			"cn": "Chinese",
			"en": "English",
			"color": "Color"
		},
		"details": {
			"edit": "Edit",
			"disable-edit": "Close and Review",
			"save": "Save"
		},
		"header": {
			"search": "Search"
		},
		"ticket-metas": {
			"ticket-type": "Type",
			"ticket-source": "Source",
			"ticket-priority": "Priority",
			"ticket-status": "Status",
			"name": "Name",
			"extern": "Extern Prop"
		},
		"tag": {

			"self": "Tags",
			"description": "The tags that defined",
			"created": "Created",
			"updated": "Updated",
			"name": "Name",
			"new-tag": "New Tag",
			"color": "Color",
			"ok": "OK",
			"cancel": "Cancel",
			"name-empty": "Tag Name could not be empty"
		},
		"user-editor": {
			"search": "Search User",
			"name": "Name",
			"fullName": "FullName",
			"referenceId": "Reference Id",
			"source": "Source",
			"organization": "Organization",
			"phone": "Phone",
			"email": "Email",
			"active": "Is Actived",
			"actived": "Active",
			"inactive": "Freeze",
			"editor-subtitle": "System User Management",
			"created": "Created At",
			"updated": "Updated"
		},
		"plugins": {
			"name": "Plugin Name",
			"description": "Description",
			"version": "Version",
			"installed-plugin": "Installed Plugin",
			"installed-plugin-description": "All plugins that installed to the system",
			"runAs": "Run As"
		},
		"privilege-editor": {
			"self": "Privilege",
			"editor-subtitle": "All system privileges",
			"name": "Privilege Name",
			"path": "Path",
			"verb": "Verb",
			"enabled": "Is Enabled"
		},
		"policy-editor": {
			"self": "Policy",
			"editor-subtitle": "Use for define the system privilege",
			"name": "Policy Name",
			"description": "Description",
			"created": "Created At",
			"updated": "Updated",
			"allowed": "Type",
			"actived": "Allow",
			"inactive": "Deny"
		},
		"user-group-editor": {
			"createdTime": "Created At",
			"updatedTime": "Updated",
			"name": "User Group Name",
			"desc": "Description",
			"editor-subtitle": "User Group Management",
			"user-count": "Users Count",
			"delete": "Delete",
			"user-add": "Add to Group",
			"user-remove": "Remove from Group",
			"user-in-group": "Group Users",
			"user-not-in-group": "Other Users",
			"policy": "Policy",
			"not-attached-policy": "Non Attached",
			"unattach": "Disattach",
			"attach": "Attach",
			"add-user-group": "Add User Group",
			"name-placeholder": "Please input the name of user group",
			"desc-placeholder": "Please input the description of user group",
			"save": "Save",
			"cancel": "Cancel"
		},
		"search": "Search",
		"language": "language",
		"footer": {
			"copyright": "Copyright",
			"clientBuild": "Client Build",
			"serverBuild": "Server Build"
		},
		"content-schemas": {
			"title": "Ticket Content Template",
			"sub-title": "Use Template to create the content description",
			"new": "New Template",
			"description": "Description",
			"updateTime": "Last updated",
			"createTime": "Created At",
			"name": "Template Name",
			"editor": "Template editor",
			"edit": "Edit",
			"preview": "Preview",
			"save": "Save template",
			"save-succeed": "Save template succeed!",
			"new-succeed": "Create new template succeed!",
			"save-fail-server": "Server-Error, Saved fail",
			"new-fail-server": "Server error, Saved fail",
			"fail-save-format": "Invalidate format."
		},
		"dynamic-editor": {
			"submit": "Save",
			"should-be-number": " should be a number."
		},
		"tickets": {
			"copy-new-ticket": "Copy as New Tickets",
			"completed-over-due": "Completed Overdue",
			"rule-update-actions-succeed": "Trigger actions updated successfully",
			"rule-enabled-succeed": "Operation successfully",
			"rule-update-trigger-frequency-succeed": "Trigger frequency successfully",
			"rule-no-changed": "Nothing update",
			"rule-update-ticket-filter-succeed": "Ticket filter update successfully",
			"rule-update-time-schedule-succeed": "Time schedule update successfully",
			"escalation-rule-unsave-update": "Unsaved Change",
			"new-ticket-escalation-rule-title": "Escalation Name",
			"new-ticket-escalation-rule-description": "Escalation Description",
			"new-ticket-escalation-rules": "New Rules",
			"escalation-rule-unsave": "New unsaved",
			"rule-not-save-could-not-enable": "Could not save beacuse of not save",
			"rule-enable": "Enabled",
			"rule-disable": "Disable",
			"action-selector": "Action Options",
			"fix-time-title": "Fix Time(CRON Expression)",
			"fix-rate-title": "Fix Rate(Minutes/Times)",
			"every": "Every",
			"minutes": "Minutes",
			"lastestUpdate": "Latest Update",
			"fix-rate": "Fix Rate",
			"one-time": "Once",
			"fix-time": "Fix Time",
			"created": "Created",
			"sla-time": "Expected Closed",
			"time-schedule": "Time Schedule",
			"axis": "Time Axis",
			"ticket-selector": "Ticket Filter",
			"time-direction": "Time Direction",
			"before": "Before",
			"after": "After",
			"time-limited": "Time Limited(Minutes)",
			"trigger-frequency": "Trigger Frequency",
			"trigger-action": "Trigger Action",
			"frequency-option": "Frequency Option",
			"exports": {
				"flush-jobs": "Flush Jobs",
				"id": "No",
				"jobId": "Job Id",
				"status": "Status",
				"startedAt": "Start",
				"requestedAt": "Requested",
				"completedAt": "Completed",
				"operations": "Operations",
				"download": "Download",
				"cancle": "Cancle"
			},
			"exports-page": "Ticket Export",
			"export-subtitle": "Export ticket jobs",
			"watch-list": "Watch List",
			"add-observer": "Add Observer",
			"setPrivateConfirm": "Set the ticket to private only watch list can see that",
			"setPublicConfirm": "Set the ticket to public, every can see that",
			"cancle": "Cancle",
			"setPrivate": "Set Private",
			"setPublic": "Set Public",
			"sub-title": "Subtitle",
			"private": "Private",
			"public": "Public",
			"id": "Ticket Number",
			"my-related-ticket": "The tickets that you are watcher, creator or assignee",
			"no-ticket-selected": "You do not select any tickets.",
			"export-detail-selected": "Export Seleted",
			"export": "Export List",
			"welcome": "Welcome",
			"data-ref-sku": "SKU",
			"current-time": "Current Time",
			"search": {
				"export": "Export",
				"automatic-re-eval": "Re-Eval Assignee",
				"you-reeval-completed": "Eval Complete,Please re-search",
				"self": "Ticket Search",
				"subtitle": "Use for ticket search",
				"title": "Ticket Subject",
				"sub-title": "Ticket Sub Title",
				"input-title": "Please input key words",
				"input-sub-title": "Please input key words",
				"act": "Search",
				"reset": "Reset",
				"id": "Ticket Id",
				"input-id": "Please input ticket id",
				"source": "Source",
				"priority": "Priority",
				"status": "Status",
				"type": "Type",
				"assigned": "Assigned",
				"tag": "Tag",
			},
			"assign-to-group": "Assign To Group",
			"assign-to-user": "Assign To User",
			"threads": {
				"comment": "Comment",
				"newticket": "New Ticket",
				"assignchange": "Assignee Changed",
				"fileattached": "File Attached",
				"corestatuschange": "Core Status Changed"
			},
			"subject": "Subject",
			"automatic-desc": "Ticket is in automatic process",
			"clean": "Clean",
			"add-comment": "Comment",
			"time-pattern-long": "YYYY/MM/DD HH:mm",
			"time-pattern-short": "MM/DD HH:mm",
			"latest-time": "Lasted Update",
			"add-form": "Add Form",
			"edit-default-detail": "Edit",
			"remove-this-form": "Remove Form",
			"new-tickets-created": "Ticket created successfully",
			"tags": "Tags",
			"input-sla-placeholder": "Please input the Timeliness for the ticket",
			"title-placeholder": "Please enter the title as summary of ticket",
			"subtitle-placeholder": "Please enter the subtitle as more detail",
			"title": "Title",
			"subtitle": "Subtitle",
			"cancel": "Cancel",
			"save-new-tickets": "Save New Ticket",
			"new-ticket": "New Ticket",
			"add-tag-succeed": "Tag added successfully",
			"remove-tag-succeed": "Remove tag successfully",
			"update-status-succeed": "Ticket status update successfully",
			"ticket-status": "Ticket Status",
			"update-succeed": "Update successfully!",
			"update-fail": "Update fail",
			"assigned-to-team-desc": "Tickets that assigned to my teams",
			"assigned-to-me-desc": "Tickets that assigned to me.",
			"assign-to-me": "Assign to me",
			"assignedGroup": "Assign to group",
			"updateTime": "Last Updated",
			"createTime": "Create At",
			"close": "Close",
			"assign-to": "Assign to ...",
			"not-assigned": "Not Assigned",
			"time-up": "Over due",
			"status": {
				"new": "New",
				"self": "Status"
			},
			"details": {
				"edit": "Edit",
				"save": "Save",
				"cancel": "Cancel"
			},
			"time-left": "Dead Line",
			"edit": "Edit Status",
			"assigned-group": "Assigned Team",
			"effected-time": "Effected Time",
			"creator": "Creator",
			"assigned": "Assigned To",
			"hav-assigned": "Assigned",
			"created-at": "Created at",
			"detail": "Detail",
			"thread": "Thread",
			"save": "Save",
			"sla": "SLA",
			"include-closed": "Include Closed",
			"exclude-closed": "Exclude Closed",
			"knowledge-base": "Knowledge Base",
			"priority": {
				"self": "Priority",
				"critical": "Critical",
				"high": "High",
				"normal": "Normal",
				"low": "Low",
				"very-low": "Very Low"
			},
			"type": {
				"incident": "Incident",
				"task": "Task",
				"alarm": "Alarm",
				"self": "Type"
			},
			"attachment": {
				"file-name": "File Name",
				"upload-time": "Upload Time",
				"self": "Attachment",
				"action": "Action",
				"delete": "Delete",
				"storage-type": "Storage Location",
				"delete-title-prefix": "Are you sure to remove the attachment?",
				"delete-attachment-succeed": "Attachment delete Succeed",
				"delete-attachment-fail": "Attachment delete fail",
				"creator": "File creator"
			},
			"source": {
				"self": "Source",
				"realtime-monitor": "Realtime"
			},
			"related": {
				"self": "Related Tickets- Alpha",
				"children-title": "Children",
				"children-button-link": "Link Children",
				"link-title": "Link",
				"link-button-link": "Link Tickets",
				"parent-title": "Parents",
				"parent-button-link": "Link as Child",
				"link": "Link",
				"could-not-link-self": "Could not link self.",
				"link-fail": "Link fail, maybe some link exist",
				"link-succeed": "Link Successfully.",
				"unlink": "Unlink",
				"confirm-unlink-postfix": "Confirm Unlink?",
				"unlink-succeed": "Unlink Successfully.",
				"unlink-fail": "Unlink fail."
			},
		},
		"api-access-key": {
			"access-key": "Access Key",
			"access-secret-pub": "Public Access Secret",
			"asymmetric-method": "Asymmetric Method",
			"createdTime": "Created Time",
			"failed-applying-access-key": "Failed to apply access key",
			"saved-success": "Saved success",
			"result-of-applying-access-key": "Result Of Applying Access Key",
			"save-to-local": "Save To Local",
			"access-key-management": "Access Key Management",
			"apply-access-key": "Apply Access Key",
			"delete-access-key": "Delete Access Key",
			"confirm-delete-access-key": "Confirm delete access keys selected?"
		},
		"notification-setting": {
			"channel-configuration": {
				"config-wechat-parameters": "Config Wechat Parameters",
				"config-feishu-parameters": "Config Feishu Parameters",
				"config-voice-parameters":"Config Voice Parameters",
				"view": "View",
				"update": "Update",
				"cancel": "Cancel",
				"save": "Save",
				"reset": "Reset",
				"not-authorized-to-view": "Not Authorized To View",
				"confirm-save-configuration": "Confirm Save This Configuration?",
				"confirm-reset-configuration": "Confirm Reset This Configuration?",
			},
			"message-template": {
				"edit": "Edit",
				"delete": "Delete",
				"template": "Template",
				"close": "Close",
				"confirm": "Confirm",
				"cancel": "Cancel",
				"confirm-modify": "Confirm Modify?",
				"confirm-delete": "Confirm Delete?",
				"template-name": "Template Name",
				"template-content": "Template Content",
				"event-name": "Event Name",
				"event-name-postfix": "Event Name:",
				"wechat-message-template": "Message Template For Wechat",
				"feishu-message-template": "Message Template For Feishu",
				"voice-message-template":"Message Template for Voice",
				"change-message-template": "Change Message Template",
				"change-template-success": "Success To Change Template!",
				"change-template-fail": "Fail To Change Template!",
				"modify-template-success": "Success To Modify Template!",
				"modify-template-fail": "Fail To Modify Template!",
				"add-template-success": "Success To Add Template!",
				"add-template-fail": "Fail To Add Template!",
				"delete-template-success": "Success To Delete Template!",
				"delete-template-fail": "Fail To Delete Template!",
				"template-name-postfix": "Name Of Template:",
				"input-template-name": "Please Input The Name Of Template",
				"input-template-content": "Please Input The Content Of Template",
				"input-event-name": "Please Input The Name Of Event",
				"add-message-template": "Add Message Template",
				"change-message-template-for-template-postfix": "Change Message Template For Event:",
			}
		},
		"statistic": {
			"ticket": {
				"serial": "Time Serial",
				"handle-time-cost": "Time Cost",
				"time-cost-rate": "Time Cost Rate",
			}
		},
		"mails": {
			"select-template": "Select Template...",
			"select-this-template": "Select",
			"send-need-approval": "The following recipients are not in the contact list bound to the mailbox, and this e-mail need approval before it can be sent, do you want to apply?",
			"cancel-send": "Cancel",
			"continue-send": "Continue",
			"apply-send": "Apply",
			"apply-success": "Apply success",
			"apply-fail": "Apply fail",
			"contact": {
				"fields": {
					"id": "ID",
					"emailAddress": "Email",
					"nickname": "Nickname",
					"tag": "Tags",
					"extendsInfo": "Extends Info",
					"enable": "Enabled",
					"createdTime": "Created Time",
					"createdBy": "Creator",
					"updatedTime": "Updated Time",
					"updatedBy": "Updator",
				},
				"yes": "Yes",
				"no": "No",
				"operation": "Operation",
				"add-contact": "Add Contact",
				"manager-title": "Contact Management",
				"manager-sub-title": "Manage basic information of email contacts",
				"delete-selected": "Delete Selected",
				"input-email-address": "Please input email address",
				"input-nickname": "Please input nickname",
				"extends-add": "Add Extends Info",
				"extends-key": "Name",
				"extends-value": "Value",
				"extends-miss-key": "Name can't be empty",
				"extends-miss-value": "Value can't be empty",
				"submit": "Submit",
				"edit": "Edit",
				"delete": "Delete",
				"save-success": "Save Success",
				"save-failed": "Save Failed",
				"confirm-delete": "Confirm Delete?",
				"delete-success": "Delete Success",
				"delete-fail": "Delete Fail",
				"click-view": "Click To View",
				"extends-info": "Extends Info",
				"to": "TO",
				"cc": "CC",
				"bcc": "BCC",
				"can-not-contains-chiness": "Cannot contain Chinese",
				"binding": {
					"fields": {
						"bindingTime": "Binding Time",
						"operator": " Operator",
					},
					"header-title": "Mailbox Binding",
					"header-sub-title": "Bind contacts and mailboxes",
					"new-binding": "New Binding",
					"unbinding": "Unbinding",
					"confirm-unbinding": "Confirm unbinding?",
					"unbinding-success": "Unbinding Success",
					"unbinding-fail": "Unbinding Fail",
					"binding-editor-title": "New Mailbox Binding",
					"contact": "Contact",
					"select-contact": "Please select contact",
					"binding-type": "Binding Type",
					"select-binding-type": "Please select binding type",
					"binding-type-to": "TO",
					"binding-type-cc": "CC",
					"binding-type-bcc": "BCC",
				}
			},
			"access-control": {
				"new-access-granted": "New Access Granted",
				"delete-access-granted": "Delete Access Granted",
				"search": "Search",
				"desc": "Desc",
				"emailAddress": "Email Address",
				"lastestUpdate": "Latest Updater",
				"created": "Created Time",
				"lastUpdated": "Latest Update Time",
				"serial": "Serial",
				"grant": "Grant",
				"can-recv": "Can Recv",
				"can-not-recv": "Can Not Recv",
				"can-send": "Can Send",
				"can-not-send": "Can Not Send",
				"send-limt": "Send Limit",
				"recv-limt": "Recv Limit",
				"recv-limit-info": "Receiving limit: The unit is the number of days, such as 30, that is, you can only receive messages for 30 days",
				"send-limit-info": "Sending limit: The unit is 30 per day, that is, only a maximum of 30 emails can be sent on the same day",
				"save-access": "Save",
				"reset-access": "Reset",
				"save-access-succeed": "Save Success",
				"save-access-fail": "Save Failed",
				"new-access-added-success": "New Access Success",
				"revoke-access-succeed": "Revoke Access Success",
				"can-approve": "Can Approve",
				"can-not-approve": "Can Not Approve",
			},
			"mail-template": {
				"fields": {
					"id": "ID",
					"content": "Content",
					"creator": " Creator",
					"create-time": " Create Time",
					"updator": "Updator",
					"update-time": "Update Time",
					"title": "Title",
				},
				"base-info": "Base Info",
				"desc": "Mail Template",
				"management": "Mail Template Management",
				"management-sub-title": "Manage template of e-mail",
				"delete": "Delete",
				"binding": "Bind To Mailbox",
				"unbind": "Unbind",
				"confirm-unbind": "Confirm unbind?",
				"edit": "Edit",
				"confirm-delete": "Confirm delete?",
				"binding-title": "Bind Mailbox",
				"editor-title": "Edit E-mail Template",
				"submit": "Submit",
				"submitting": "Submitting",
				"cancel": " Cancel",
				"required-title": "Title is required",
				"required-content": "Content is required",
				"edit-success": "Edit success",
				"edit-fail": "Edit fail",
				"bind-to-mailbox": "Bind To This Mailbox",
				"bind-new-mailbox": "Bind New Mailbox",
				"bound-mailboxes": "Bound Mailboxes",
				"bound-templates": "Bound Templates",
				"mailbox": "Mailbox",
				"select-mailbox": "Please select mailbox",
				"binding-header-title": "Bind To Mailbox",
				"binding-header-sub-title": "Bind mail templates and mailboxes",
				"new-template": "New Template",
				"delete-success": "Delete success",
				"delete-fail": "Delete fail",
				"save-success": "Save success",
				"save-fail": "Save fail",
				"bind-success": "Bind success",
				"bind-fail": "Bind fail",
				"unbind-success": "Unbind success",
				"unbind-fail": "Unbind fail",
				"tags": "Template Tag",
				"loading": "Loading...",
				"all-templates": "All Templates",
				"operation": "Operation",
				"template": "Template",
				"view": "View",
				"template-view": "View Template",
			},
			"approval": {
				"page-title": "Approve E-mail",
				"page-sub-title": "Emails that need to be approved before they can be sent",
				"id": "ID",
				"mailBox": "Mail Box",
				"emailTitle": "Email Title",
				"emailDesc": "Email Description",
				"creator": "Creator",
				"createTime": "Create Time",
				"updater": "Updater",
				"updateTime": "Update Time",
				"operation": "Operation",
				"view-detail": "Details",
				"approve": "Approve",
				"reject": "Reject",
				"confirm-approve": "Confirm Approve?",
				"confirm-reject": "Confirm Reject?",
				"email-details": "Email Details",
				"base-info": "Base Info",
				"contacts": "Contacts (those marked in red indicate that they are not in the contact list of this mailbox)",
				"mail-content": "Mail Content",
				"attachment": "Attachment",
				"send-to": "Send To",
				"send-cc": "CC",
				"send-bcc": "BCC",
				"no-contacts": "No Contacts",
				"approve-success": "Approve Success",
				"approve-fail": "Approve Fail",
			}
		}

	};

	return langEN;

}

export default getLanguageEN;
