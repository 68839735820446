
import axios from 'axios';
import {
	UserApiApi,
	Configuration,
	MetasApiApi,
	UserGroupApiApi,
	PrivilegesApiApi,
	PolicyApiApi,
	ProductivityApiApi,
	TicketApiApi,
	PluginApiApi,
	SystemApiApi,
	AttachmentApiApi,
	DataProxyApi,
	ExportApiApi,
	ClusterApiApi,
	ProjectServiceMetaDataApiApi,
	SecurityApiApi,
	NotificationApiApi, StatisticApiApi, ProjectServiceInstanceApiApi, ProjectServiceItemInstanceApiApi, MailApiApi
} from './api'

import { Resolver } from "@stoplight/json-ref-resolver";
import { BASE_USRL, ID_LINK } from './configure';


export class ApiServer {
	private config: Configuration;
	private userApi?: UserApiApi;
	private metaApi?: MetasApiApi;
	private groupApi?: UserGroupApiApi;
	private privilegeApi?: PrivilegesApiApi
	private policyApi?: PolicyApiApi;
	private productivityApi?: ProductivityApiApi;
	private ticketApi?: TicketApiApi;
	private pluginApi?: PluginApiApi;
	private systemApi?: SystemApiApi;
	private schemaResolver?: Resolver;
	private attachmentApi ?: AttachmentApiApi;
	private dataProxyApi ? : DataProxyApi
	private exportApi? : ExportApiApi;
	private projectMetaApi?: ProjectServiceMetaDataApiApi;
	private projectInstanceApi?: ProjectServiceInstanceApiApi;
	private notificationApiApi?: NotificationApiApi;
	private statisticApi?: StatisticApiApi;
	private clusterApi?:ClusterApiApi;
	private mailApi?:MailApiApi;
	private projectTaskApi?: ProjectServiceItemInstanceApiApi;
	private securityApi?: SecurityApiApi;

	constructor(config: Configuration) {

		this.config = config;
		axios.interceptors.response.use((value => {
			let ua = navigator.userAgent.toLocaleUpperCase();
			if (value.data.code === 403) {
				if(ua.indexOf("wxwork") > 0) {
					window.location.href = config.basePath + "/apps/tickets/login" + "?redirect=" + encodeURIComponent(window.location.href);
				} else {
					window.location.href = config.basePath + "/login"
				}
			}
			return value;
		}));

		this.schemaResolver = new Resolver({
			resolvers: {
				file: {
					async resolve(ref: URI) {
						console.log(ref)
						return axios({
							method: "get",
							url: BASE_USRL + "/api/v1/schema"+ String(ref)
						}).then(x => x.data);
					}
				}
			}
		});
	}
	get apiMail():MailApiApi{
		if(!this.mailApi){
			this.mailApi = new MailApiApi(this.config);
		}
		return this.mailApi;
	}


	get apiAttachment() : AttachmentApiApi{
		if(!this.attachmentApi){
			this.attachmentApi =  new AttachmentApiApi(this.config);
		}
		return this.attachmentApi;
	}

	get apiGroup(): UserGroupApiApi {
		if (!this.groupApi) {

			this.groupApi = new UserGroupApiApi(this.config);
		}
		return this.groupApi;
	}
	get apiUser(): UserApiApi {
		if (!this.userApi) {
			this.userApi = new UserApiApi(this.config);
		}
		return this.userApi;
	}

	get apiMeta(): MetasApiApi {
		if (!this.metaApi) {
			this.metaApi = new MetasApiApi(this.config);
		}
		return this.metaApi;
	}

	get apiPrivilege(): PrivilegesApiApi {
		if (!this.privilegeApi) {
			this.privilegeApi = new PrivilegesApiApi(this.config);

		}
		return this.privilegeApi;
	}
	get apiPolicy(): PolicyApiApi {
		if (!this.policyApi) {
			this.policyApi = new PolicyApiApi(this.config);
		}
		return this.policyApi;
	}
	get apiProductivity(): ProductivityApiApi {
		if (!this.productivityApi) {
			this.productivityApi = new ProductivityApiApi(this.config);
		}
		return this.productivityApi;
	}

	get apiTickets(): TicketApiApi {
		if (!this.ticketApi) {
			this.ticketApi = new TicketApiApi(this.config)
		}
		return this.ticketApi;
	}

	get apiPlugin(): PluginApiApi {
		if (!this.pluginApi) {
			this.pluginApi = new PluginApiApi(this.config)
		}
		return this.pluginApi;
	}
	
	get apiSystem(): SystemApiApi {
		if (!this.systemApi) {
			this.systemApi = new SystemApiApi(this.config);
		}
		return this.systemApi;
	}

	get apiDataProxy() : DataProxyApi {
		if(!this.dataProxyApi){
			this.dataProxyApi = new DataProxyApi(this.config);
		}
		return this.dataProxyApi;
	}

	get apiExport():ExportApiApi{
		if(!this.exportApi){
			this.exportApi = new ExportApiApi(this.config);
		}
		return this.exportApi;
	}
	
	get apiProjectMeta(): ProjectServiceMetaDataApiApi{
		if(!this.projectMetaApi){
			this.projectMetaApi = new ProjectServiceMetaDataApiApi(this.config);

		}
		return this.projectMetaApi;

	}
	get apiProjectInstance(): ProjectServiceInstanceApiApi{
		if(!this.projectInstanceApi){
			this.projectInstanceApi = new ProjectServiceInstanceApiApi(this.config);

		}
		return this.projectInstanceApi;

	}

	get apiNotification(): NotificationApiApi{
		if(!this.notificationApiApi){
			this.notificationApiApi = new NotificationApiApi(this.config);

		}
		return this.notificationApiApi;
	}

	get apiStatistic(): StatisticApiApi{
		if(!this.statisticApi){
			this.statisticApi = new StatisticApiApi(this.config);

		}
		return this.statisticApi;
	}

	get apiProjectTask(): ProjectServiceItemInstanceApiApi{
		if(!this.projectTaskApi){
			this.projectTaskApi = new ProjectServiceItemInstanceApiApi(this.config);

		}
		return this.projectTaskApi;
	}

	get apiCluster() :ClusterApiApi{
		if(!this.clusterApi){
			this.clusterApi = new ClusterApiApi(this.config);
		}
		return this.clusterApi;
	}
	get apiSecurity():SecurityApiApi{
		if(!this.securityApi){
			this.securityApi = new SecurityApiApi(this.config);
		}
		return this.securityApi;

	}

	async resolve(schema: any): Promise<any> {

		var resloveResult = await this.schemaResolver?.resolve(schema);
		return (resloveResult)?.result;
	}

	async logout():Promise<any>{
		axios.get("/logout").then(x=>
			{
				window.location.href=ID_LINK
			});
	}
}



export default ApiServer;