import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";


export interface MailLandingViewModel extends WithTranslation {

}

interface MailLandingViewState {

}

class MailLanding extends React.Component<MailLandingViewModel,MailLandingViewState>{

    render(): React.ReactNode {
        return (
        <div className="mail-management">

        </div>)
    }
}

export default withTranslation()(MailLanding);