import React from "react";
import { Chart } from '@antv/g2';
import { SERIAL_COLOR, GanttValueDataModel } from "./@mod";
import './index.less';
import { withTranslation, WithTranslation } from "react-i18next";
import { SERVICE_STATUS } from "../../@mod";
import { ProjectServiceItemInstanceStatusDto } from "../../../../api";
import moment from "moment";


export interface GanttChartViewModel extends WithTranslation {
    data: GanttValueDataModel[]
    types: string[],
    colors?: string[],
    height?: number
}

interface GanttChartViewState {

}

interface GanttChartRanderModel {
    range: any[],
    group: any,
    name: string,
    state: ProjectServiceItemInstanceStatusDto,
    fatestEnd: string,

}

class GanttChart extends React.Component<GanttChartViewModel, GanttChartViewState>{

    containerId: string
    serialColor = SERIAL_COLOR;

    constructor(props: GanttChartViewModel) {
        super(props)
        this.containerId = "time-chart-container-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random() + "-" +
            Math.random();
    }
    chart: Chart | undefined;

    componentDidUpdate() {
        if (this.chart == null && this.props.data.length > 0) {
            var colors = (this.props.colors) ?? this.props.types.map((x, index) => {
                return this.serialColor[index] ?? "#dfdfdf"
            });

            this.chart = new Chart({
                container: this.containerId,
                autoFit: true,
                height: this.props.height

            });
            this.chart.data(this.getData())
            this.chart.coordinate().transpose().scale(1, -1);
            this.chart.scale('range', {
                type: 'time',
                nice: true,
            });
            this.chart.tooltip({
                showMarkers: false,
                customItems: (originalItems) => {
                    if (originalItems.length === 1) {
                        let aItem = originalItems[0]
                        let toolTipItems = originalItems.map(x => {
                            return {
                                ...x,
                                name: this.props.t("project.task.current-status"),
                                value: x.data.state.desc[this.props.i18n.language],
                                color: ""
                            }
                        })
                        toolTipItems.push({
                            ...aItem,
                            name: this.props.t("project.task.start-time"),
                            value: aItem.data.range[0],
                            color: ""
                        })
                        if (SERVICE_STATUS.PROCESSING === aItem.data.state.code
                            || SERVICE_STATUS.REDOING === aItem.data.state.code) {
                            toolTipItems.push({
                                ...aItem,
                                name: this.props.t("project.task.fast-end-time"),
                                value: aItem.data.fatestEnd,
                                color: ""
                            })
                            toolTipItems.push({
                                ...aItem,
                                name: this.props.t("project.task.slow-end-time"),
                                value: aItem.data.range[1],
                                color: ""
                            })
                            let now = moment()
                            let minRemaining =
                                (moment(aItem.data.fatestEnd.toString()).unix() - now.unix()) / 60
                            let maxRemaining =
                                (moment(aItem.data.range[1].toString()).unix() - now.unix()) / 60
                            toolTipItems.push({
                                ...aItem,
                                name: this.props.t("project.task.remaining-time"),
                                value: (minRemaining.toFixed(1) + " " + this.props.t("minutes")) + " - " + (maxRemaining.toFixed(1) + " " + this.props.t("minutes")),
                                color: ""
                            })
                        } else {
                            toolTipItems.push({
                                ...aItem,
                                name: this.props.t("project.task.end-time"),
                                value: aItem.data.range[1],
                                color: ""
                            })

                            toolTipItems.push({
                                ...aItem,
                                name: this.props.t("time-cost"),
                                value: ((moment(aItem.data.range[1].toString()).unix() -
                                    moment(aItem.data.range[0].toString()).unix()) / 60).toFixed(1) + " " + this.props.t("minutes"),
                                color: ""
                            })
                        }
                        return toolTipItems
                    }
                    return originalItems
                },
            });
            this.chart.interaction('element-active');
            this.chart
                .interval()
                .position('name*range')
                .color('group', colors)
                .animate({
                    appear: {
                        animation: 'scale-in-x',
                    }
                });
            this.chart.render()

        } else if (this.chart) {
            this.chart.changeData(this.getData());
        }
    }


    getData(): GanttChartRanderModel[] {
        return this.props.data.map(x => {
            let model: GanttChartRanderModel = {
                range: [x.start, x.end],
                fatestEnd: x.fastestEndDate,
                group: this.props.types[x.type],
                name: x.name,
                state: x.status
            }
            return model;
        })
    }


    render(): React.ReactNode {

        return (<div id={this.containerId} className="time-chart-container">

        </div>)
    }
}


export default withTranslation()(GanttChart);

