import { Tag } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";


export interface ActiveTagModel extends WithTranslation{
	isActive:boolean
}


class ActiveTag extends React.Component<ActiveTagModel,{}>{

	render(): React.ReactNode {
		return(
			<Tag color={this.props.isActive?"green":"red"}>
				{this.props.t(this.props.isActive?"user-editor.actived":"user-editor.inactive")}
			</Tag>
		)
	}
}


export default withTranslation()(ActiveTag);