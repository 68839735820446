import { Button, PageHeader } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { ActionEventHandler } from "../../../../components";
import WorkBenchTable from "../../../../components/table/workbench-table";
import { RouteContext, withRouter } from "../../../../utils";
import { columnsProvider, ProcessRecord } from "./@mod";



export interface AutomationViewModel extends WithTranslation {
	apiServer: ApiServer
	router?: RouteContext
}

interface AutomationViewState {
	records: ProcessRecord[],
	dataLoading: boolean
}



class AutomationView extends React.Component<AutomationViewModel, AutomationViewState>{

	constructor(props: AutomationViewModel) {
		super(props)
		this.state = {
			records: [],
			dataLoading: true
		}
	}

	loadData = async () => {
		await this.props.apiServer.apiProductivity.getProcessList()
			.then(
				x => {
					let records: ProcessRecord[] = []
					if (x.data.data?.success) {
						x.data.data.processes?.forEach(y => {
							records.push({
								id: y.id ?? 0,
								name: y.name ?? "",
								desc: y.desc ?? "",
								created: y.created ?? "",
								updated: y.updated ?? "",
								key:y.id
							})
						})
					}
					this.setState({
						records: records
					})
				}
			)
	}


	newProcessClicked = () => {
		this.props.router?.navigate("/system/productivity/0/process")
	}

	onDetail = (cs: ProcessRecord) => {
		this.props.router?.navigate("/system/productivity/" + cs.id + "/process");

	}

	l: Promise<void> | undefined;
	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(
					() => {
						this.l = undefined;
						this.setState({
							dataLoading: false
						})

					}
				)

		}
	}

	render(): React.ReactNode {
		return (
			<div className="content-schema-container site-drawer-render-in-current-wrapper" >
				<PageHeader title={this.props.t("auto.process-list-title")}
				subTitle={this.props.t("auto.process-list-sub-title")}
				extra ={
					[
						<Button key={1} type="primary" onClick={this.newProcessClicked}>{this.props.t("auto.new")}</Button>
					]
				}
				>
					<WorkBenchTable<ProcessRecord>
						onDetail = {this.onDetail}
						data = {this.state.records}
						dataLoading ={this.state.dataLoading}
						columnProvider={(a:ActionEventHandler<ProcessRecord>)=>columnsProvider(this.props,a)}
						/>
		
				</PageHeader>
			</div>
			)
	}
}


export default withRouter(withTranslation()(AutomationView));