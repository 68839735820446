import React from "react";
import {withTranslation, WithTranslation} from "react-i18next";
import ApiServer from "../../../ApiServer";
import ServiceListView from "../component/service-list";
import {convertServiceDtoToModel, convertTaskDtoToModel, SERVICE_STATUS, ServiceModel, TaskModel} from "../@mod";
import {Button, Form, message, Modal, Radio, RadioChangeEvent, Switch} from "antd";
import "./index.less"
import TextArea from "antd/lib/input/TextArea";

interface ServiceApproveViewModel extends WithTranslation {
    apiServer: ApiServer
}

interface ServiceApproveViewState {
    serviceList: ServiceModel[]
    isShowApprovalView: boolean
    taskList: TaskModel[]
    showServiceDetails: boolean
    selectedService?: ServiceModel
    dataLoading: boolean
    selectedApprovalStatus?: number
    approveBtnLoading: boolean
}

class ServiceApproveView extends React.Component<ServiceApproveViewModel, ServiceApproveViewState> {
    constructor(props: ServiceApproveViewModel) {
        super(props);
        this.state = {
            serviceList: [],
            isShowApprovalView: false,
            taskList: [],
            showServiceDetails: false,
            dataLoading: false,
            approveBtnLoading: false
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        this.setState({
            dataLoading: true
        })
        this.props.apiServer.apiProjectInstance.getProjectInstanceOfOwnerByStatus([SERVICE_STATUS.UNDER_APPROVAL]).then(r => {
            if (r.data.data?.dtos) {
                let serviceList = r.data.data.dtos.map(model => convertServiceDtoToModel(model))
                this.setState({
                    serviceList: serviceList
                })
            }
        }).finally(() => {
            this.setState({
                dataLoading: false
            })
        })
    }

    onDetail = (model: ServiceModel) => {
        this.setState({
            showServiceDetails: true,
            selectedService: model
        })
        this.loadTaskList(model)
    }

    loadTaskList = (model: ServiceModel) => {
        if (model) {
            this.props.apiServer.apiProjectInstance.getItemInstanceListOfServiceInstance(model.id).then(r => {
                if (r.data.data?.dtos) {
                    let dtos = r.data.data.dtos;
                    let serviceItemList = dtos.map((dto) => convertTaskDtoToModel(dto))
                    this.setState({
                        taskList: serviceItemList
                    })
                }
            })
        }
    }

    genServiceOperationExtra = () => {
        return (
            <>
                <Button onClick={this.showApprovalView}>{this.props.t("project.service.approve")}</Button>
            </>)
    }

    showApprovalView = () => {
        this.setState({
            isShowApprovalView: true
        })
    }

    approveProject = (values) => {
        if (this.state.selectedService) {
            this.setState({
                approveBtnLoading: true
            })
            this.props.apiServer.apiProjectInstance.postExamineAndApproveService(this.state.selectedService?.id, {
                approve: values.approve,
                description: values.description,
                start: values.startTime
            }).then(r => {
                if (r.data.data?.success) {
                    message.success(this.props.t("project.service.approve-success")).then()
                    this.loadData()
                    this.setState({
                        showServiceDetails: false
                    })
                } else {
                    message.error(this.props.t("project.service.approve-fail")).then()
                }
                this.setState({
                    isShowApprovalView: false
                })
            }).finally(() => {
                this.setState({
                    approveBtnLoading: false
                })
            })
        }
    }

    onApproveChange = (e: RadioChangeEvent) => {
        this.setState({
            selectedApprovalStatus: e.target.value
        })
    }

    renderApprovalView(): React.ReactNode {
        let append: React.ReactNode
        if (this.state.selectedApprovalStatus === SERVICE_STATUS.APPROVED) {
            append = (
                <Form.Item name={"startTime"} label={this.props.t("project.service.start-time")} initialValue={false}>
                    <Switch defaultChecked={false} checkedChildren={this.props.t("project.service.start-now")} unCheckedChildren={this.props.t("project.service.start-later")} />
                </Form.Item>
            )
        } else if (this.state.selectedApprovalStatus === SERVICE_STATUS.APPROVAL_FAILED) {
            append = (
                <Form.Item name={"description"} label={this.props.t("project.service.approve-desc")} rules={[{ required: true, message: this.props.t("project.service.input-approve-desc") }]}>
                    <TextArea />
                </Form.Item>
            )
        } else {
            append = (<></>)
        }
        return (
            <>
                <Form onFinish={this.approveProject}>
                    <Form.Item name={"approve"} label={this.props.t("project.service.whether-pass-approval")} rules={[{ required: true, message: this.props.t("project.service.select-whether-pass-approval") }]}>
                        <Radio.Group onChange={this.onApproveChange}>
                            <Radio value={SERVICE_STATUS.APPROVED}>{this.props.t("project.service.pass")}</Radio>
                            <Radio value={SERVICE_STATUS.APPROVAL_FAILED}>{this.props.t("project.service.not-pass")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {append}
                    <Form.Item>
                        <div className={"approve-operate-bar"}>
                            <Button className={"left-btn"}
                                    onClick={() => this.setState({isShowApprovalView: false})}>{this.props.t("project.service.cancel")}</Button>
                            <Button loading={this.state.approveBtnLoading} htmlType={"submit"}
                                    type={"primary"}>{this.props.t("project.service.confirm")}</Button>
                        </div>
                    </Form.Item>
                </Form>
            </>
        )
    }

    render() {
        return (
            <div className={"service-approve-container"}>
                <ServiceListView
                    serviceLoading={this.state.dataLoading}
                    apiServer={this.props.apiServer}
                    serviceList={this.state.serviceList}
                    headerSubTitle={this.props.t("project.service.approve-sub-title")}
                    headerTitle={this.props.t("project.service.approve-title")}
                    genServiceOperationExtra={this.genServiceOperationExtra}
                    taskList={this.state.taskList}
                    onDetail={this.onDetail}
                    reloadTaskList={this.loadTaskList}
                    showServiceDetails={this.state.showServiceDetails}
                    closeServiceDetailView={() => this.setState({ showServiceDetails: false })}
                    selectedService={this.state.selectedService}
                />
                <Modal
                    visible={this.state.isShowApprovalView}
                    onCancel={() => { this.setState({ isShowApprovalView: false }) }}
                    footer={null}
                    zIndex={1100}
                    destroyOnClose={true}
                >
                    {this.renderApprovalView()}
                </Modal>
            </div>
        )
    }
}

export default withTranslation()(ServiceApproveView)