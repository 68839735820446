import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { CaretDownOutlined } from '@ant-design/icons'
import './index.less'

export interface SubjectChangedViewModel extends WithTranslation {
    changedObject : any
}



class SubjectChanged extends React.Component<SubjectChangedViewModel,{}>{



    renderChanged  = ()=>{

        let result: React.ReactNode[] = [];

        for(var i in this.props.changedObject){
            var obj = this.props.changedObject[i];
            let changeTranslate = this.props.t("tickets.threads.subtitle-changed");
            if(obj.type ==="subject"){
                changeTranslate = this.props.t("tickets.threads.subject-changed");
            }
            result.push(
                <div key={obj.type}>
                    <div className="changed-item-type">
                        {changeTranslate}
                    </div>
                    <div className="changed-item">
                        <div className="before">{obj.before}</div>
                        <div className="pointer">
                                <CaretDownOutlined />
                        </div>
                        <div className="after">
                            {obj.after === ""? " ": obj.after}
                        </div>
                    </div>
                </div>
            )

        }
        return result;
    }


    render(): React.ReactNode {
        return (
            <div className="subject-changed">
                {this.renderChanged()}
            </div>
        )
    }
}

export default withTranslation()(SubjectChanged);


