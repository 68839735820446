import { Button, message, Popover, Select, Tag } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../../ApiServer";
import WorkBenchTable from "../../../../../components/table/workbench-table";
import { MailContactModel, mailImportContactColumnProvider, MailContactTag, loadTagList } from "../../@mod";
import './index.less';
import { DeleteOutlined, TagsOutlined } from "@ant-design/icons"


interface ContactEditorViewModel extends WithTranslation {

    apiServer: ApiServer,
    onCompleted: (values: any) => void,
    putImportData: (h: ((importedContractModel: MailContactModel[]) => void)) => void
    putDoImport: (h: () => Promise<void>) => void
}

interface ContactEditorViewState {
    importedContractModel: MailContactModel[],
    selected: MailContactModel[],
    tags: MailContactTag[],
    tagIds: any[]
}

class ContractEditor extends React.Component<ContactEditorViewModel, ContactEditorViewState>{

    constructor(props: ContactEditorViewModel) {
        super(props);
        this.state = {
            importedContractModel: [],
            selected: [],
            tags: [],
            tagIds: []
        }
    }

    componentDidMount() {
        this.props.putImportData(this.importData)
        this.props.putDoImport(this.doImport)
        loadTagList(this.props.apiServer).then(x => {
            this.setState({ tags: x });
        })
    }

    importData = (importedContractModel: MailContactModel[]) => {
        this.setState({
            importedContractModel: importedContractModel
        })
    }

    doImport = async () : Promise<void> =>  {
        for (let i =0;i< this.state.importedContractModel.length;i++){
            let x = this.state.importedContractModel[i];
            await this.props.apiServer.apiMail.postMailContacts({
                emailAddress: x.emailAddress,
                nickname: x.nickname,
                tags: x.tag.map(q => q.id),
                enable: x.enable,
                extendsInfo: JSON.stringify(x.extendsInfo)
            }).then(r => {
                if (r.data.data?.success) {
                    message.success(x.emailAddress + " " + this.props.t("mails.contact.save-success")).then()
                }
                else{
                    message.error(x.emailAddress + " " + this.props.t("mails.contact.save-failed"))
                }
            })
        }
    }

    editOperation = (model: MailContactModel, actionType: string, index?: number) => {
        if (actionType === "remove") {
            let importedModel = this.state.importedContractModel;
            this.setState({ selected: [], importedContractModel: importedModel.filter(x => !(x.emailAddress === model.emailAddress)) });
        }
    }

    selectingData = (selected: MailContactModel[]) => {
        this.setState({ selected: selected })

    }



    onTagSelected = (selected: any[]) => {
        this.setState({ tagIds: selected })
    }

    assignTag = () => {
        let selectedModel = this.state.selected;
        selectedModel.forEach(x => {
            x.tag = [];
            this.state.tagIds.forEach(q => {
                var tagSelected = this.state.tags.find(y => y.id == q);
                if (tagSelected) {
                    x.tag.push(tagSelected);
                }
            })
        })
        this.setState({ importedContractModel: this.state.importedContractModel });
    }

    deleteBatchClick = () => {
        var candidate = this.state.importedContractModel;

        this.state.selected.forEach(v => {
            candidate = candidate.filter(x => !(v.emailAddress === x.emailAddress));
        })
        this.setState({
            importedContractModel: candidate,
            selected: []
        })
    }

    render(): React.ReactNode {
        return (
            <div className="mail-contacts">
                <div className="top-bar">
                    <div className="top-bar-title">
                        <div>{this.props.t("mails.contact.import-title")}</div>
                    </div>
                    <div className="top-bar-buttons">
                        <Popover placement="bottom"
                            trigger="click"
                            content={
                                (
                                    <div>
                                        <Select
                                            mode="multiple"
                                            allowClear={true}
                                            style={{ width: 200 }}
                                            onChange={this.onTagSelected}
                                        >
                                            {this.state.tags.map(tag => {
                                                return <Select.Option children={<Tag color={tag.color} key={tag.id}>{tag.name}</Tag>} key={tag.id} value={tag.id} />
                                            })}
                                        </Select>
                                        <Button onClick={this.assignTag}>{this.props.t("confirm")}</Button>
                                    </div>
                                )
                            }
                        >
                            <Button type="primary" disabled={!(this.state.selected && this.state.selected.length > 0)} icon={<TagsOutlined />}>{this.props.t("mails.contact.tagging")}</Button>
                        </Popover>
                        <Button onClick={this.deleteBatchClick} type="default" disabled={!(this.state.selected && this.state.selected.length > 0)} danger icon={<DeleteOutlined />}>{this.props.t("mails.contact.delete-selected")}</Button>
                    </div>
                </div>
                <div className="contacts-table-body">
                    <WorkBenchTable<MailContactModel>
                        data={this.state.importedContractModel}
                        dataLoading={false}
                        onSelected={this.selectingData}
                        columnProvider={() => mailImportContactColumnProvider(this.props.t, this.editOperation)}
                        rowKey={(record: MailContactModel) => { return record.emailAddress }}
                    />
                </div>
                <div className="bottom-bar">

                </div>
            </div>
        )
    }
}

export default withTranslation()(ContractEditor);