import { Button, Select } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { WithTranslation } from "react-i18next";
import { ActionEventHandler } from "../../../../../components";
import DynamicEditor from "../../../../../components/dynamic-editor";
import ColumnTextFilter from "../../../../../components/table/column-text-filter";


export interface FormSchema {
	schema: string
	uiSchema: string,
	name: string,
	desc: string,
	refName: string,
	def: any
}

export interface ProcessRecord {
	id: number
	name: string,
	desc: string,
	created: string
	updated: string,
	key?: number
}

export interface RequiredModel {
	type: "form" | "approval" | "close"
	ref?: string,
	ownership?: string[]
}

export interface ProcessModel {
	stage: number,
	name: string,
	required: RequiredModel
}

export interface WhenModel {
	axis: string,
	condition: string,
	time: number
}

export interface Escalation {
	when: WhenModel,
	target: string[]
}
export interface DataModel {
	type: string,
	displayAt: number[]
}

export const renderForm = (schema: FormSchema[], formId: string) => {
	if (schema.length > 0) {
		var s = schema.filter(x => x.refName === formId);
		if (s.length === 1) {
			let renderSchema = s[0];
			let formSchema: object
			let uiSchema: object;
			try {
				formSchema = JSON.parse(renderSchema.schema)
			}
			catch {
				formSchema = {}
			}
			try {
				uiSchema = JSON.parse(renderSchema.uiSchema);
			} catch {
				uiSchema = {}
			}
			return (
				<DynamicEditor
					widthPercent={45}
					schema={formSchema}
					uiSchema={uiSchema}
					content={{}}
				/>
			)
		}
	}
	return <></>

}

export const renderAssignee = (assignee: any[], ref: string, stages: any[]) => {
	if(ref && ref!==''){
		let selected =  assignee.filter(x=>x.name===ref)[0]
		return (
			<div>
				<div style={{margin:4}}>
					<Button type="primary">{selected.displayName}</Button>
				</div>
				<div style={{margin:4}}>
					<Select style={{width:100}}></Select>
				</div>
	
			</div>)
	}
	return (<></>)
}

export const renderApproval = (approvals: any[], ref: string, stages: any[]) => {
	if (approvals.length > 0) {
		var a = approvals.filter(x => x.name === ref);
		if (a.length === 1) {
			let approval = a[0];
			let rejects = approval.reject.map(i => {
				let stg = stages.filter(s => s.stage === i);
				if (stg.length === 1) {
					let stage = stg[0];
					return (
						<Button danger key={stage.stage}>打回{stage.name}</Button>
					)
				}
				return (<></>)
			})

			return (
				<div className="approval-buttons">
					<Button type="primary" >同意</Button>
					{rejects}
				</div>
			)
		}
	}

}


export const columnsProvider = (
	trans: WithTranslation, handler: ActionEventHandler<ProcessRecord>): ColumnType<ProcessRecord>[] => {
	let translater: ((d: string) => string) = trans.t;
	return [
		{
			title: translater("auto.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string, record: ProcessRecord) => <Button type="link" onClick={() => handler(record, "detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value: string | number | boolean, record: ProcessRecord) => record.name.indexOf(value.toString()) >= 0,
			filters: [],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-editor.name")} />)
			},

		},
		{
			title: translater("auto.description"),
			dataIndex: 'desc',
			key: 'desc'
		}, {
			title: translater("auto.updateTime"),
			dataIndex: 'updated',
			key: 'updated',
			render: (value: any, record: ProcessRecord, index: number) => {
				return record.updated === "" ? "" : moment.parseZone(record.updated).format("YYYY-MM-DD HH:mm:ss");
			}
		},
		{
			title: translater("auto.createTime"),
			dataIndex: 'created',
			key: 'created',
			render: (value: any, record: ProcessRecord, index: number) => {
				return record.created === "" ? "" : moment.parseZone(record.created).format("YYYY-MM-DD HH:mm:ss");
			}
		}
	];
}