import { Button, notification, Select } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { UserModel } from "../../../@mod";
import './index.less';


export interface AssigneeViewModel extends WithTranslation {
    userModels: UserModel[]
    content: any,
    onAssigneeSubmit?: (content: string) => void,
    editable: boolean
}

interface AssigneeViewState {
    selectedAssignee?: number
}

class AssigneeView extends React.Component<AssigneeViewModel, AssigneeViewState>{

    constructor(props: AssigneeViewModel) {
        super(props)
        let selecteAssignee = JSON.parse(this.props.content);
        console.log(selecteAssignee);
        this.state = {
            selectedAssignee: selecteAssignee["next-resolver"]
        }

    }

    renderUserOptions = () => {
        return this.props.userModels.map(
            x => (
                <Select.Option value={x.id} key={x.id} username={x.name} org={x.organization} >
                    <div className="user-name">{x.name}</div>
                    <div className="user-organzation">{x.organization}</div>
                </Select.Option>

            ));
    }

    filterOption = (v: any, m: any | undefined) => {
        if (m) {
            if (m.username.indexOf(v) >= 0) {
                return true;
            }
            if (m.org.indexOf(v) >= 0) {
                return true
            }
        }
        return false;
    }

    onSelected = (v, o) => {
        this.setState({
            selectedAssignee: v
        })
    }

    onSubmitAssign = () => {
        if (this.state.selectedAssignee) {
            let newContent = {
                "next-resolver": this.state.selectedAssignee
            }
            if (this.props.onAssigneeSubmit) {
                this.props.onAssigneeSubmit(JSON.stringify(newContent));
            }

        } else {
            notification.error({ message: this.props.t("auto.please-select-assignee"), placement: "top" })
        }

    }

    render(): React.ReactNode {

        return <> {this.props.editable &&
            <div className="hard-assign-selector">
                <div>
                    <Select className="selector"
                        showSearch
                        value={this.state.selectedAssignee}
                        filterOption={this.filterOption}
                        onSelect={this.onSelected}>
                            
                        {this.renderUserOptions()}
                    </Select>
                </div>
                <div >
                    <Button type="primary" onClick={this.onSubmitAssign} >
                        {this.props.t("auto.assignee")}
                    </Button>
                </div>
            </div>

        }</>
    }
}




export default withTranslation()(AssigneeView);