import React from "react";
import './index.less'

export interface EditableTextModel {
	text: string,
	onSave?: (str: string) => void,
	className?:string
}

interface EditableTextState {
	enabledEdit: boolean,
	text: string,

}


class EditableText extends React.Component<EditableTextModel, EditableTextState>{


	focus = React.createRef<HTMLDivElement>()
	constructor(props: EditableTextModel) {
		super(props)
		this.state = {
			enabledEdit: false,
			text: props.text
		};
	}

	getSnapshotBeforeUpdate(beforeProp: EditableTextModel, beforeState: EditableTextState) {
		if (beforeProp.text !== this.props.text) {
			this.setState({
				text: this.props.text
			})
		}
		return null;
	}
	componentDidUpdate(){

	}

	save = (event) => {
		this.setState({
			text: event.target.innerText})
		this.props.onSave?.(event.target.innerText);
	}

	getTextField = () => {

		return (<div
			suppressContentEditableWarning={true}
			contentEditable={true}
			ref={this.focus}
			onBlur={this.save}

		>{this.state.text}</div>)
	}

	render(): React.ReactNode {
		return (
			<div className={"editable-text " + this.props.className} >
				{this.getTextField()}
			</div>
		)
	}
}


export default EditableText;

