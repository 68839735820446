import { Badge, Popover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import React, { ReactNode } from "react";

export interface PopupButtonModel {
	content : ReactNode
	placement: TooltipPlacement
	title: string,
	badge?:number,
	buttonIcon : ReactNode,
}

class PopupButton extends React.Component<PopupButtonModel,{}>{
	render(): React.ReactNode {
		return(<>
			<Popover placement={this.props.placement} content={this.props.content}
				title = {this.props.title}
				>
					<Badge count={this.props.badge} size="small">
						{this.props.buttonIcon}
					</Badge>
			</Popover>
		</>)
	}
}


export default PopupButton;

