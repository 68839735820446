import { PageHeader, Select, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ApiServer from "../../../../ApiServer";
import { ActionEventHandler } from "../../../../components";
import WorkbenchTable from "../../../../components/table/workbench-table";
import {
	TicketPriorityModel,
	TicketSourceModel,
	TicketStatusModel,
	TicketTypeModel,
	loadMetaData,
	MetaModel
} from "../../@mod";
import {	columnsProvider} from "./@mod"
import './index.less'

const { Option } = Select;

interface TicketPageModel extends WithTranslation {
	apiServer: ApiServer
}

interface TicketPageState {
	ticketTypes: TicketTypeModel[]
	ticketStatus: TicketStatusModel[]
	ticketSource: TicketSourceModel[]
	ticketPriority: TicketPriorityModel[]
	dataLoading: boolean

}

class TicketPage extends React.Component<TicketPageModel, TicketPageState>{

	constructor(props: TicketPageModel) {
		super(props)
		this.state = {
			ticketTypes: [],
			ticketStatus: [],
			dataLoading: true,
			ticketPriority: [],
			ticketSource: []
		}
	}

	async loadData() {
		let src = await loadMetaData(this.props.apiServer);
		this.setState(
			{
				ticketTypes: src.ticketType,
				ticketPriority: src.ticketPriority,
				ticketSource: src.ticketSource,
				ticketStatus: src.ticketStatus
			}
		)
	}

	l: Promise<void> | undefined;

	componentDidMount() {
		if (!this.l) {
			this.l = this.loadData()
				.finally(
					() => {
						this.setState({
							dataLoading: false
						})
					}
				);
		}

	}

	getFooter = () => {
		return (
			<Tabs>
				<Tabs.TabPane tab={this.props.t("ticket-metas.ticket-type")}
					key="1"
					animated={true}
				>
					<div>
						<WorkbenchTable<MetaModel>
							data={this.state.ticketTypes}
							dataLoading={this.state.dataLoading}
							columnProvider={
								(a: ActionEventHandler<MetaModel>) => columnsProvider(this.props.t, a)
							} />
					</div>
				</Tabs.TabPane>
				<Tabs.TabPane tab={this.props.t("ticket-metas.ticket-source")}
					key="2"
					animated={true}
				>
					<WorkbenchTable<MetaModel>
						data={this.state.ticketSource}
						dataLoading={this.state.dataLoading}
						columnProvider={
							(a: ActionEventHandler<MetaModel>) => columnsProvider(this.props.t, a)
						} />

				</Tabs.TabPane>
				<Tabs.TabPane tab={this.props.t("ticket-metas.ticket-priority")}
					key="3"
					animated={true}
				>
					<WorkbenchTable<MetaModel>
						data={this.state.ticketPriority}
						dataLoading={this.state.dataLoading}
						columnProvider={
							(a: ActionEventHandler<MetaModel>) => columnsProvider(this.props.t, a)
						} />

				</Tabs.TabPane>
				<Tabs.TabPane tab={this.props.t("ticket-metas.ticket-status")}
					key="4"
					animated={true}
				>
					<WorkbenchTable<MetaModel>
						data={this.state.ticketStatus}
						dataLoading={this.state.dataLoading}
						columnProvider={
							(a: ActionEventHandler<MetaModel>) => columnsProvider(this.props.t, a)
						} />

				</Tabs.TabPane>
			</Tabs>
		)
	}

	render(): React.ReactNode {
		return (
			<div className="ticket-meta-container site-drawer-render-in-current-wrapper" id="user-container" >
				<PageHeader title={this.props.t("ticket-meta")}
					subTitle={this.props.t("ticket-meta-description")}
					footer={this.getFooter()}
				>
					<Content>
						<div className="meta-preview">
							<div>
								<span className="title">{this.props.t("ticket-metas.ticket-type")}</span>
								<Select loading={this.state.dataLoading} defaultValue={1}>
									{this.renderTicketTypePreview()}
								</Select>
							</div>
							<div>
								<span className="title">{this.props.t("ticket-metas.ticket-source")}</span>
								<Select loading={this.state.dataLoading} defaultValue={1}>
									{this.renderTicketSourcePreview()}
								</Select>
							</div>
							<div>
								<span className="title">{this.props.t("ticket-metas.ticket-priority")}</span>
								<Select loading={this.state.dataLoading} defaultValue={1}>
									{this.renderTicketPriorityPreview()}
								</Select>
							</div>
							<div>
								<span className="title">{this.props.t("ticket-metas.ticket-status")}</span>
								<Select loading={this.state.dataLoading} defaultValue={1}>
									{this.renderTicketStatusPreview()}
								</Select>
							</div>
						</div>
					</Content>
				</PageHeader>
			</div>)
	}

	renderTicketTypePreview = () => {

		return this.state.ticketTypes.map(
			x => (
				<Option value={x.id} key={x.id}>
					{x.description[this.props.i18n.language]}
				</Option>)
		)
	}

	renderTicketStatusPreview = () => {

		return this.state.ticketStatus.map(
			x => (
				<Option value={x.id} key={x.id}>
					{x.description[this.props.i18n.language]}
				</Option>)
		)
	}


	renderTicketSourcePreview = () => {

		return this.state.ticketSource.map(
			x => (
				<Option value={x.id} key={x.id}>
					{x.description[this.props.i18n.language]}
				</Option>)
		)
	}

	renderTicketPriorityPreview = () => {

		return this.state.ticketPriority.map(
			x => (
				<Option value={x.id} key={x.id}>
					{x.description[this.props.i18n.language]}
				</Option>)
		)
	}
}


export default withTranslation()(TicketPage);