

//欧几里得算法 求两个数a、b的最大公约数
function gcd(a, b) {
	return b === 0 ? a : gcd(b, a % b)
}
//分数类 分子，分母
class Fraction {
	static create(num, den = 1) {
		if (num instanceof Fraction) {
			return num;
		} else if (/(-?\d+)\/(\d+)/.test(num)) {
			return new Fraction(parseInt(RegExp.$1), parseInt(RegExp.$2))
		} else {
			if (/\.(\d+)/.test(num)) {
				num = num * Math.pow(10, RegExp.$1.length);
				den = den * Math.pow(10, RegExp.$1.length);
			}
			if (/\.(\d+)/.test(den.toString())) {
				num = num * Math.pow(10, RegExp.$1.length);
				den = den * Math.pow(10, RegExp.$1.length);
			}
			return new Fraction(num, den)
		}
	}
	num: number = 0
	den: number = 1

	constructor(num = 0, den = 1) {
		if (den < 0) {
			num = -num;
			den = -den;
		}
		if (den === 0) {
			// eslint-disable-next-line no-throw-literal
			throw '分母不能为0'
		}
		let g = gcd(Math.abs(num), den)
		this.num = num / g;
		this.den = den / g;
	}
	//加
	add(o) {
		return new Fraction(this.num * o.den + this.den * o.num, this.den * o.den)
	}
	//减
	sub(o) {
		return new Fraction(this.num * o.den - this.den * o.num, this.den * o.den)
	}
	//乘
	multiply(o) {
		return new Fraction(this.num * o.num, this.den * o.den);
	}
	//除
	divide(o) {
		return new Fraction(this.num * o.den, this.den * o.num);
	}
	//小于
	lessThan(o) {
		return this.num * o.den < this.den * o.num;
	}
	//等于
	equal(o) {
		return this.num * o.den === this.den * o.num;
	}
	toString() {
		return this.num + '/' + this.den;
	}
}

export {
	Fraction
};