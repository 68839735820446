import { PageHeader, Tabs } from "antd";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import ApiServer from "../../../ApiServer";
import { loadVendors, MailVendorModel, vendorColumnProvider } from "../@mod";
import './index.less';
import { CloseOutlined } from "@ant-design/icons";
import WorkBenchTable from "../../../components/table/workbench-table";
import VendorViewer from "./viewer"

export interface VendorViewModel extends WithTranslation {
    apiServer: ApiServer
}


interface VendorViewState {
    vendors: MailVendorModel[],
    vendorOnView: MailVendorModel[],
    dataLoading: boolean,
    activeView: string
}


class VendorView extends React.Component<VendorViewModel, VendorViewState>{


    constructor(props: VendorViewModel) {
        super(props)
        this.state = {
            vendors: [],
            vendorOnView: [],
            dataLoading: false,
            activeView: "default"
        }
    }


    loadData = async () => {
        this.setState(
            {
                vendors: [],
                vendorOnView: []
            }
        )
        var vendors = await loadVendors(this.props.apiServer)
        this.setState(
            {
                vendors: vendors,
                vendorOnView: []
            }
        )
    }

    l: Promise<void> | undefined;
    componentDidMount() {
        if (!this.l) {
            this.l = this.loadData()
                .finally(() => {
                    this.setState({
                        dataLoading: false
                    })
                })
        }
    }
    addNewVendorViewer = (v: MailVendorModel) => {
        if (this.state.vendorOnView.findIndex(x => x.id == v.id) < 0) {
            let vendorOnView = this.state.vendorOnView;
            vendorOnView.push(v);
            this.setState({
                vendorOnView: vendorOnView
            })
        } else {
            this.activeKey(String(v.id))

        }
    }

    activeKey = (k: string) => {
        this.setState({
            activeView: k
        })
    }


    renderTabs = () => {
        let tabs: React.ReactNode[] = [];
        tabs.push(<Tabs.TabPane closable={false} tab={this.props.t("mails.mail-vendor-list")} key={"default"}>
            <div className="email-vendor-all">
                <WorkBenchTable<MailVendorModel>
                    onDetail={this.addNewVendorViewer}
                    data={this.state.vendors}
                    dataLoading={this.state.dataLoading}
                    columnProvider={(x) => vendorColumnProvider(this.props.t, x)}
                />
            </div>
        </Tabs.TabPane>)

        this.state.vendorOnView.forEach(x => {
            tabs.push(
                <Tabs.TabPane tab={x.vendorName} closable={true} key={x.id} closeIcon={<CloseOutlined />}>
                    <VendorViewer apiServer={this.props.apiServer}
                        vendorModel={x}
                    ></VendorViewer>
                </Tabs.TabPane>
            )
        });
        return tabs;
    }

    editTabs = (e, a) => {
        if (a === "remove") {
            let vendorOnView = this.state.vendorOnView;
            vendorOnView = vendorOnView.filter(x => x.id !== Number(e))
            console.log(e, vendorOnView);
            this.setState({
                vendorOnView: vendorOnView
            })
        }
    }



    render(): React.ReactNode {
        return (
            <div className="vendor-view-editor">
                <PageHeader title={this.props.t("mails.mail-vendor")}>
                    <Tabs type="editable-card"
                        hideAdd={true}
                        onTabClick={this.activeKey}
                        activeKey={this.state.activeView}
                        onEdit={this.editTabs}
                        tabPosition="top"
                        className="participator-anaylsis-tab">
                        {this.renderTabs()}
                    </Tabs>
                </PageHeader>
            </div>
        )
    }
}


export default withTranslation()(VendorView);
