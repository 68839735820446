import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import './index.less'
import { MockUser } from '../mock'
import {AutoComplete, Button, Drawer, Input, Modal, PageHeader} from "antd";
import { BaseOptionType } from "antd/lib/cascader";
import { UserModel } from "./@mod";
import UserEditor from "./user-editor";
import { Content } from "antd/lib/layout/layout";
import ApiServer from "../../../../ApiServer";
import { AxiosResponse } from "axios";
import WorkbenchTable from "../../../../components/table/workbench-table";
import { columnsProvider } from "./@mod";
import { ActionEventHandler } from "../../../../components";
import ApiAccessKey from "./api-access-key";
import {ApiResponseGetUserListResponse} from "../../../../api";



interface UsersPageModel extends WithTranslation {
	apiServer: ApiServer
}

interface UserPageState {
	userDataLoading: boolean,
	options: BaseOptionType[],
	users: UserModel[],
	showEditor: boolean
	onEditUser:UserModel
}

class UsersPage extends React.Component<UsersPageModel, UserPageState>{

	constructor(props: UsersPageModel) {
		super(props)
		this.state = {
			userDataLoading: true,
			options: [],
			users: [],
			showEditor: false,
			onEditUser: {
				key:"unknow",
				name:"New User",
				fullName:"New User",
				source: "DB",
				organization:"none",
				phone:"0",
				email:"example@example.com",
				active:true,
				created:"",
				updated:""
			}
		}

	}

	userModel: UserModel[] = [];

	async loadData(){
		await this.props.apiServer.apiUser.getUserList()
		.then((x:AxiosResponse<ApiResponseGetUserListResponse, any>)=>{
				let models =
				x.data.data?.list?.map(
					(u) : UserModel=>  {
						return {
							key: (u.id??0).toString(),
							name: u.name?? "",
							fullName: u.fullName?? "",
							source: (u.source??0).toString(),
							organization: u.organization??"",
							phone: u.phone??"",
							email: u.email??"",
							active: u.active??false,
							created: u.createdTime??"1654732800000",
							updated: u.updatedTime??"1654732800000"
						}
					}
				)
				if(models){
					this.userModel = models;
				}
				else{
					this.userModel = [];
				}
				this.setState({
					users:this.userModel,
					userDataLoading:false
				})

		}).catch(err=>{
			console.log(err);
			this.setState({
				userDataLoading:false
			})
		})
		;
	}

	l:Promise<void>|undefined=undefined;

	componentDidMount() {
		console.log("mount")
		this.userModel = MockUser();
		if(!this.l){
			this.l = this.loadData();
		}
		this.l.then(()=>{
			this.l=undefined;
		})
		
	}


	onSearch = (searchText: string) => {
		console.log(searchText);
		let nameOptions: BaseOptionType[] = [];
		this.userModel.forEach(f => {
			if (f.name.indexOf(searchText) >= 0) {
				nameOptions.push({
					"value": f.name,
					"field": "name"
				})
			}
		});
		this.setState({
			options: nameOptions,
			users: this.userModel.filter(f => f.name.indexOf(searchText) >= 0)
		})
	}

	newUserClicked = () => {
		this.setState({
			showEditor: true,
			onEditUser: {
				key:"unknow",
				name:"New User",
				fullName:"New User",
				source: "DB",
				organization:"none",
				phone:"0",
				email:"example@example.com",
				active:true,
				created:"",
				updated:""
			}
		})
	}

	editorClose = () => {
		this.setState(
			{
				showEditor: false
				
			}
		)
	}

	userSave = (userModel:UserModel)=>{
		this.setState({
			userDataLoading:true,
			showEditor:false
		})
		if(userModel.key ==="unknow"){
			this.props.apiServer.apiUser
				.newUser({
					name: userModel.name,
					phone: userModel.phone,
					email : userModel.email,
					organization: userModel.organization,
					password:""
				})
				.finally(
					()=>{
						this.loadData();
					}
				)
		} else {

			//this.props.apiServer.apiUser.p

			/*
			this.props.apiServer.apiUser.getUser(Number(userModel.key),false)
			.then(


			).catch(()=>{
				this.loadData();
			});
			*/
		}
	}

	showDetail = (user:UserModel)=>{
		console.log("showdetail",user);
		this.setState({
			onEditUser:user,
			showEditor:true
		});
	
	}


	render(): React.ReactNode {
		return (<>
			<div className="user-container site-drawer-render-in-current-wrapper" id="user-container" >

				<PageHeader title={this.props.t("user")} extra={
					[
						<div className="user-global-button" key="global-button">
							<AutoComplete dropdownMatchSelectWidth={252}
								onSearch={this.onSearch}
								options={this.state.options}
								style={{ width: 300 }}
							>
								<Input.Search size="middle" placeholder={this.props.t("search")} enterButton loading={this.state.userDataLoading} />
							</AutoComplete>
							<Button danger loading={this.state.userDataLoading}>{this.props.t("delete")}</Button>
							<Button type="primary" onClick={this.newUserClicked} loading={this.state.userDataLoading} >{this.props.t("insert")}</Button>
						</div>

					]}
					subTitle={this.props.t("user-editor.editor-subtitle")}
				>
					<Content>
						<WorkbenchTable<UserModel>
							data={this.state.users}
							dataLoading ={this.state.userDataLoading}
							onDetail={this.showDetail}
							columnProvider={
								(a:ActionEventHandler<UserModel>)=>columnsProvider(this.props.t,a)
							}/>
					</Content>

				</PageHeader>

				<Drawer
					closable={true}
					placement="right"
					visible={this.state.showEditor}
					getContainer={false}
					onClose={this.editorClose}
					size="large"
					title={this.props.t("user-editor.self")+ " " +this.state.onEditUser.name}
				>
					<UserEditor user={this.state.onEditUser} save={this.userSave} cancel={()=>{this.setState({showEditor:false})} } />
					<ApiAccessKey apiServer={this.props.apiServer} user={this.state.onEditUser}/>
				</Drawer>
			</div>


		</>)
	}
}


export default withTranslation()(UsersPage);
