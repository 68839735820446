import { Button, Input } from "antd";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";



export interface NewApprovalViewModel extends WithTranslation {
	onNewApprovalActed?: (name: string) => void
	cancle?: () => void
}

interface NewApprovalState {
	name: string
}


class NewApprovalEditor extends React.Component<NewApprovalViewModel, NewApprovalState>{
	constructor(props: NewApprovalViewModel) {
		super(props)
		this.state = {
			"name": ""
		}
	}
	render(): React.ReactNode {
		return (
			<div>
				<div>
					<Input value={this.state.name}
						placeholder={this.props.t("auto.ref-approval-name")}
						onChange={(t) => {
							this.setState({
								name: t.target.value
							})
						}}></Input>
				</div>
				<div>
					<Button type="primary" onClick={()=>this.props.onNewApprovalActed?.(this.state.name)}>{this.props.t("auto.add-approval")}</Button>
					<Button onClick={() => this.props.cancle?.()} >{this.props.t("auto.cancel")}</Button>
				</div>

			</div>)
	}
}

export default withTranslation()(NewApprovalEditor);