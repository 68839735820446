import {ColumnType} from "antd/lib/table";
import ApiServer from "../../../ApiServer";
import {ActionEventHandler} from "../../../components";
import templ from './project-template.json';
import {Button, Popconfirm, Popover} from "antd";
import {WithTranslation} from "react-i18next";
import {ArrowUpOutlined, CloudUploadOutlined, EditOutlined, MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {
    ProjectServiceInstanceDto,
    ProjectServiceInstanceStatusDto,
    ProjectServiceItemInstanceDto,
    ProjectServiceItemInstanceStatusDto,
    ProjectServiceItemMetaDataDto,
    ProjectServiceMetaDataModel,
    ProjectStatusDto,
    UserModel,
} from "../../../api";
import moment from "moment";
import ColumnTextFilter from "../../../components/table/column-text-filter";

export interface ServiceTemplateModel {
    id: number,
    title: string,
    description: string,
    serviceOrder: object,
    version: string,
    ownerId: number,
    ownerName: string,
    publishDate: string,
    status: string
}

export interface ProjectMetaDataModel {
    id: number,
    createdBy: number,
    createdByName: string,
    createdTime: string,
    description: string,
    owner: number,
    ownerName: string,
    status: ProjectStatusDto,
    template: string,
    title: string,
    updateBy: number,
    updateByName: string,
    updateByTime: string,
    version: string,

}


export interface ProjectProcessModel {
    title: string,
    key: string,
    description: string,
    children: ProjectProcessModel[]
}

export interface ServiceModel {
    title: string,
    description: string,
    acceptor: UserModel,
    createdBy: UserModel,
    createdTime: string,
    endTime: string,
    id: number,
    project: ProjectServiceMetaDataModel,
    startTime: string,
    status: ProjectServiceInstanceStatusDto,
    subscriber: UserModel,
    updatedBy: UserModel,
    updatedTime: string,
}

export interface TaskModel {
    createdBy: UserModel
    createdTime: string
    description: string
    endTime: string
    estimatedHigherEndTime: string
    estimatedLowerEndTime: string
    estimatedStartTime: string
    id: number
    itemId: number
    leaf: boolean
    orderCode: number
    parameters: { [key: string]: object; }
    priority: number
    redoCount: number
    serial: boolean
    serviceId: number
    serviceInstance: ProjectServiceInstanceDto
    startTime: string
    status: ProjectServiceItemInstanceStatusDto
    subItem: boolean
    title: string
    type: { [key: string]: string; }
    updatedBy: UserModel
    updatedTime: string
    parentItem: number
    preItem: number
    executor: UserModel
}

interface Estimated {
    timeEstimationLowerLimit: number,
    timeEstimationHigherLimit: number

}

export const removeDuplicateUser = (userList: UserModel[]): UserModel[] => {
    let cache = {}
    userList.forEach(u => cache[u.fullName ?? ""] = u)
    let res = new Array<UserModel>()
    for (let key in cache) {
        if (key !== "") {
            res.push(cache[key])
        }
    }
    return res;
}

export const convertTaskDtoToModel = (dto: ProjectServiceItemInstanceDto): TaskModel => {
    return {
        createdBy: dto.createdBy ?? {},
        createdTime: dto.createdTime ?? "",
        description: dto.description ?? "",
        endTime: dto.endTime ?? "",
        estimatedHigherEndTime: dto.estimatedHigherEndTime ?? "",
        estimatedLowerEndTime: dto.estimatedLowerEndTime ?? "",
        estimatedStartTime: dto.estimatedStartTime ?? "",
        id: dto.id ?? 0,
        itemId: dto.itemId ?? 0,
        leaf: dto.leaf ?? false,
        orderCode: dto.orderCode ?? 0,
        parameters: dto.parameters ?? {},
        priority: dto.priority ?? 0,
        redoCount: dto.redoCount ?? 0,
        serial: dto.serial ?? false,
        serviceId: dto.serviceId ?? 0,
        serviceInstance: dto.serviceInstance ?? {},
        startTime: dto.startTime ?? "",
        status: dto.status ?? {},
        subItem: dto.subItem ?? false,
        title: dto.title ?? "",
        type: dto.type ?? {},
        updatedBy: dto.updatedBy ?? {},
        updatedTime: dto.updatedTime ?? "",
        parentItem: 0,
        preItem: 0,
        executor: dto.executor ?? {}
    };
}


export const convertServiceDtoToModel = (dto: ProjectServiceInstanceDto): ServiceModel => {
    return {
        title: dto.title ?? "",
        description: dto.description ?? "",
        createdTime: dto.createdTime ?? "",
        endTime: dto.endTime ?? "",
        id: dto.id ?? 0,
        project: dto.project ?? {},
        startTime: dto.startTime ?? "",
        status: dto.status ?? {},
        updatedTime: dto.updatedTime ?? "",
        acceptor: dto.acceptor ?? {},
        createdBy: dto.createdBy ?? {},
        subscriber: dto.subscriber ?? {},
        updatedBy: dto.updatedBy ?? {},
    }
}

function calculateEstimate(task: any): Estimated {
    let timeEstimationLowerLimit = 0;
    let timeEstimationHigherLimit = 0;
    if (task.children) {
        task.children.forEach(element => {
            if (element.type === "parallel_task" || element.type === "serial_task") {
                let s = calculateEstimate(element);
                element = s;
            }

            if (task.type === "parallel_task") {
                timeEstimationHigherLimit = Math.max(timeEstimationHigherLimit, Number(element.timeEstimationHigherLimit))
                timeEstimationLowerLimit = Math.max(timeEstimationLowerLimit, Number(element.timeEstimationLowerLimit))
            }
            else {
                timeEstimationHigherLimit += Number(element.timeEstimationHigherLimit);
                timeEstimationLowerLimit += Number(element.timeEstimationLowerLimit)
            }
        });
    }
    return {
        timeEstimationLowerLimit,
        timeEstimationHigherLimit
    }
}

export const taskDelveriableColumnProvider = (translater: ((d: string) => string), handler: ActionEventHandler<any>): ColumnType<any>[] => {

    return [{
        title: translater("services.delveriables.title"),
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: translater("services.delveriables.description"),
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: translater("services.delveriables.type"),
        dataIndex: 'type',
        key: 'type',
        render: (v: any, r: any) => {
            return translater("project.template.task.types." + v.toLowerCase())
        }
    },
    {
        title: translater("services.delveriables.required"),
        dataIndex: 'required',
        key: 'required',
        render: (v: any, r: any) => {

            if (r.type === "PARALLEL_TASK" || r.type === "SERIAL_TASK") {
                return translater("services.task-deliever")
            }
            return (v ?? false) ? translater("services.required-deliever") : translater("services.optional-deliever")
        }
    },
    {
        title: translater("services.delveriables.estimated"),
        dataIndex: 'estimated',
        key: 'estimated',
        render: (v: any, r: any) => {

            if (r.type === 'SERIAL_TASK' || r.type === "PARALLEL_TASK") {
                let est = calculateEstimate(r);
                return est.timeEstimationLowerLimit + translater("project.template.task.hour") + "-" + est.timeEstimationHigherLimit + translater("project.template.task.hour")
            }
            if (r.timeEstimationLowerLimit && r.timeEstimationHigherLimit) {
                return r.timeEstimationLowerLimit + translater("project.template.task.hour") + "-" + r.timeEstimationHigherLimit + translater("project.template.task.hour")
            }
            else {
                return translater("services.estimated-undefined")
            }
        }
    }
    ]
}


export const taskColumnProviderEditor = (translater: ((d: string) => string), handler: ActionEventHandler<any>): ColumnType<any>[] => {

    return [{
        title: translater("project.template.task.fields.name"),
        dataIndex: 'title',
        key: 'title',
        render: (value, record, index) => {
            return record.title
        }
    },
    {
        title: translater("project.template.task.fields.description"),
        dataIndex: 'description',
        key: 'description',
        render: (value, record, index) => {
            return record.description
        }
    },
    {
        title: translater("project.template.task.fields.type"),
        dataIndex: 'type',
        key: 'type',
        render: (v: any, r: any) => {
            return translater("project.template.task.types." + r.type.toLocaleLowerCase())
        }
    },
    {
        title: translater("project.template.task.fields.executor"),
        dataIndex: 'defaultExecutor',
        key: 'defaultExecutor',
        render: (value, record, index) => {
            return record.defaultExecutorName ? record.defaultExecutorName : record.defaultExecutor
        }
    },
    {
        title: translater("project.template.task.fields.required"),
        dataIndex: 'required',
        key: 'required',
        render: (v: any, r: any) => {
            return r.required ? translater("project.template.yes") : translater("project.template.no")
        }
    },
    {
        title: translater("project.template.task.fields.estimated"),
        dataIndex: 'estimated',
        key: 'estimated',
        render: (v: any, r: any) => {
            if (r.type === 'serial_task' || r.type === "parallel_task") {
                let est = calculateEstimate(r);
                r["timeEstimationLowerLimit"] = est.timeEstimationLowerLimit
                r["timeEstimationHigherLimit"] = est.timeEstimationHigherLimit
                return est.timeEstimationLowerLimit + translater("project.template.task.hour") + "-" + est.timeEstimationHigherLimit + translater("project.template.task.hour")
            }
            if (r.timeEstimationLowerLimit !== undefined && r.timeEstimationHigherLimit !== undefined) {
                return (<div className="estimate">
                    {r.timeEstimationLowerLimit + translater("project.template.task.hour")} - {r.timeEstimationHigherLimit + translater("project.template.task.hour")}
                </div>)
            } else {
                return translater("services.estimated-undefined")
            }
        }
    },
    {
        title: translater("project.template.task.fields.operation"),
        dataIndex: 'title',
        key: 'title',
        render: (v: any, r: any, index) => {
            let isParentTask = r.type === 'serial_task' || r.type === "parallel_task";
            return (<div style={{ float: "right" }}>
                <Popover
                    mouseLeaveDelay={0}
                    content={translater("project.template.edit-task")}>
                    <Button
                        size={"small"}
                        icon={<EditOutlined />}
                        shape={"circle"}
                        onClick={() => {
                            handler(r, "edit");
                        }}
                        style={{ marginRight: "4px" }}
                    />
                </Popover>
                <Popover
                    mouseLeaveDelay={0}
                    content={isParentTask ? translater("project.template.add-task") : translater("project.template.add-task-disable")}>
                    <Button
                        size={"small"}
                        style={{ borderColor: "#306cd2" }}
                        icon={<PlusOutlined style={{ color: "#306cd2" }} />}
                        shape={"circle"}
                        onClick={() => {
                            handler(r, "new-line");
                        }}
                        disabled={!isParentTask}
                    />
                </Popover>
                <Popover mouseLeaveDelay={0} content={translater("project.template.delete")}>
                    <Popconfirm title={translater("project.template.confirm-delete")} onConfirm={() => {
                        handler(r, "delete", index)
                    }}>
                        <Button
                            shape={"circle"}
                            icon={<MinusOutlined />}
                            danger
                            size={"small"}
                            style={{ marginLeft: "4px" }}
                        />
                    </Popconfirm>
                </Popover>
                <Popover mouseLeaveDelay={0} content={translater("project.template.move-up")}>
                    <Button
                        shape={"circle"}
                        icon={<ArrowUpOutlined />}
                        size={"small"}
                        onClick={() => handler(r, "up", index)}
                        style={{ marginLeft: "4px" }}
                    />
                </Popover>
            </div>);
        }
    }]
}

export const taskSelections = (translater: ((d: string) => string)) => {
    const PARALLEL_TASK = "PARALLEL_TASK";
    const SERIAL_TASK = "SERIAL_TASK";
    const TICKET = "TICKET";
    const MEETING = "MEETING";
    const FILE = "FILE";
    const PROCESS = "PROCESS";
    return ([
        {
            text: translater("project.template.task.types." + PARALLEL_TASK.toLocaleLowerCase()),
            value: PARALLEL_TASK.toLocaleLowerCase()
        },
        {
            text: translater("project.template.task.types." + SERIAL_TASK.toLocaleLowerCase()),
            value: SERIAL_TASK.toLocaleLowerCase()
        },
        {
            text: translater("project.template.task.types." + TICKET.toLocaleLowerCase()),
            value: TICKET.toLocaleLowerCase()
        },
        {
            text: translater("project.template.task.types." + MEETING.toLocaleLowerCase()),
            value: MEETING.toLocaleLowerCase()
        },
        {
            text: translater("project.template.task.types." + FILE.toLocaleLowerCase()),
            value: FILE.toLocaleLowerCase()
        },
        {
            text: translater("project.template.task.types." + PROCESS.toLocaleLowerCase()),
            value: PROCESS.toLocaleLowerCase()
        }
    ])
}

export const executableTaskSelections = (translater: ((d: string) => string)) => {
    const TICKET = "TICKET";
    const MEETING = "MEETING";
    const FILE = "FILE";
    const PROCESS = "PROCESS";
    return ([
        // {
        //     text: translater("project.template.task.types." + TICKET.toLocaleLowerCase()),
        //     value: TICKET.toLocaleLowerCase()
        // },
        {
            text: translater("project.template.task.types." + MEETING.toLocaleLowerCase()),
            value: MEETING.toLocaleLowerCase()
        },
        {
            text: translater("project.template.task.types." + FILE.toLocaleLowerCase()),
            value: FILE.toLocaleLowerCase()
        },
        {
            text: translater("project.template.task.types." + PROCESS.toLocaleLowerCase()),
            value: PROCESS.toLocaleLowerCase()
        }
    ])
}

export const milestoneSelections = (translater: ((d: string) => string)) => {
    const SERIAL_MILESTONE = "SERIAL_MILESTONE";
    const PARALLEL_MILESTONE = "PARALLEL_MILESTONE";
    return ([
        {
            text: translater("project.template.task.types." + SERIAL_MILESTONE.toLocaleLowerCase()),
            value: SERIAL_MILESTONE.toLocaleLowerCase()
        },
        {
            text: translater("project.template.task.types." + PARALLEL_MILESTONE.toLocaleLowerCase()),
            value: PARALLEL_MILESTONE.toLocaleLowerCase()
        }
    ])
}

export const deliverableSelections = (translater: ((d: string) => string)) => {
    const FILE = "FILE";
    const FORM = "FORM";
    return ([
        {
            text: translater("project.template.task.types." + FILE.toLocaleLowerCase()),
            value: FILE.toLocaleLowerCase()
        },
        // {
        //     text: translater("project.template.task.types." + FORM.toLocaleLowerCase()),
        //     value: FORM.toLocaleLowerCase()
        // }
    ])
}

export const judgeParentTask = (type: string): boolean => {
    let typeLowerType = type.toLowerCase()

    return TASK_TYPES[1002].value === typeLowerType || TASK_TYPES[1001].value === typeLowerType
}

export const judgeParentItem = (type: string): boolean => {
    let typeLowerType = type.toLowerCase()
    return judgeParentTask(type) || TASK_TYPES[102].value === typeLowerType || TASK_TYPES[103].value === typeLowerType
}

export const deliverableColumnProviderEditor = (translater: ((d: string) => string), handler: ActionEventHandler<any>): ColumnType<any>[] => {
    return [{
        title: translater("project.template.deliverable.name"),
        dataIndex: 'title',
        key: 'title',
        render: (value, record, index) => {
            return record.title
        }
    },
    {
        title: translater("project.template.deliverable.description"),
        dataIndex: 'description',
        key: 'description',
        render: (value, record, index) => {
            return record.description
        }
    },
    {
        title: translater("project.template.deliverable.type"),
        dataIndex: 'type',
        key: 'type',
        render: (v: any, r: any) => {
            return translater("project.template.task.types." + r.type.toLocaleLowerCase())
        }
    },
    {
        title: translater("project.template.deliverable.required"),
        dataIndex: 'required',
        key: 'required',
        render: (v: any, r: any) => {
            return r.required ? translater("project.template.yes") : translater("project.template.no")
        }
    },
    {
        title: translater("project.template.deliverable.operation"),
        dataIndex: '',
        key: '',
        render: (v: any, r: any, index: number) => {
            return (
                <div style={{ float: "right" }}>
                    <Popover content={translater("project.template.edit-deliverable")}>
                        <Button
                            size={"small"}
                            shape={"circle"}
                            type={"primary"}
                            icon={<EditOutlined />}
                            style={{ marginRight: "4px" }}
                            onClick={() => handler(r, "edit", index)}
                        />
                    </Popover>
                    <Popover content={translater("project.template.delete")}>
                        <Popconfirm title={translater("project.template.confirm-delete")} onConfirm={() => { handler(r, "delete", index) }}>
                            <Button danger size={"small"} shape={"circle"} icon={<MinusOutlined />} />
                        </Popconfirm>
                    </Popover>
                </div>
            )
        }
    }]
}



export const delveriableColumnProvider = (translater: ((d: string) => string), handler: ActionEventHandler<any>): ColumnType<any>[] => {

    return [{
        title: translater("services.delveriables.name"),
        dataIndex: 'name',
        key: 'name',
        render: (value, record, index) => {
            return value;
        }
    },
    {
        title: translater("services.delveriables.description"),
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: translater("services.delveriables.type"),
        dataIndex: 'type',
        key: 'type',
        render: (v: any, r: any) => {
            return translater("project.template.task.types." + v)
        }
    },
    {
        title: translater("services.delveriables.required"),
        dataIndex: 'required',
        key: 'required',
        render: (v: any, r: any) => {

            return (v) ? translater("services.required-deliever") : translater("services.optional-deliever")
        }
    },
    {
        title: translater("services.delveriables.estimated"),
        dataIndex: 'estimated',
        key: 'estimated',
        render: (v: any, r: any) => {
            if (r.timeEstimationLowerLimit && r.timeEstimationHigherLimit) {
                return r.timeEstimationLowerLimit + translater("project.template.task.hour") + "-" + r.timeEstimationHigherLimit + translater("project.template.task.hour")
            }
            else {
                return translater("services.estimated-undefined")
            }
        }
    }]
}

export async function loadServiceTemplate(id: number, apiServer: ApiServer): Promise<ServiceTemplateModel> {
    apiServer.apiProjectMeta.getProcessTemplate(id)
        .then(x => {
            if (x.data.data?.success) {

            }
        });

    let template: any = templ;
    return parseTemplate(template)
}


const doubleLinked = (parent) => {
    if (parent) {
        parent.children.forEach(child => {
            if (!child.children) {
                child.children = []
            }
            doubleLinked(child)
            child.parent = parent
        })
    }
}

export const parseTemplate = (template: any): ServiceTemplateModel => {
    let preRequirements: any[] = []
    let milestoneList: any[] = []
    let project = {
        preRequirements: preRequirements,
        serviceOrder: milestoneList
    }
    let preRequiredArray = template.milestones.filter(x => x.type === "PRE_REQUIRED");
    if (preRequiredArray.length > 0) {
        preRequiredArray.forEach(preRequired => {
            project.preRequirements.push(
                {
                    title: preRequired.title,
                    description: preRequired.description,
                    type: "PRE_REQUIRED",
                    children: preRequired.children.map(x => {
                        return {
                            "type": mapType(x.type),
                            "title": x.title,
                            "description": x.description,
                            "required": x.required
                        }
                    })
                }
            )
        });
    }
    milestoneList = template.milestones.filter(x => x.type !== "PRE_REQUIRED");
    if (milestoneList.length > 0) {
        milestoneList.forEach(element => {
            if (!element.children) {
                element.children = []
            }
            doubleLinked(element)
            project.serviceOrder.push(element)
        });
    }
    return {
        id: NaN,
        title: template.serviceName,
        description: template.serviceDescription,
        serviceOrder: project,
        version: template.templateVersion,
        ownerId: template.templateProvider,
        ownerName: "",
        status: "草稿",
        publishDate: "2022-12-21 12:22",
    }
}



export const projectMetaDataColumnProvider = (props: WithTranslation, projectStatusList: ProjectStatusDto[], handler: ActionEventHandler<any>): ColumnType<any>[] => {
    let translater = props.t;
    return [
        {
            title: translater("project.template.fields.id"),
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: ProjectMetaDataModel) => {
                return (
                    <div className="subject-container">
                        <div className="link" onClick={() => handler(record, "detail")}>{"#" + text}</div>
                    </div>
                )
            },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: ProjectMetaDataModel) => {
                return Number(record.id) === Number(value)
            },
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.template.fields.id")} />)
            }
        },
        {
            title: translater("project.template.fields.title"),
            dataIndex: 'title',
            key: 'title',
            // render: (text: string, record: ProjectMetaDataModel) => {
            //     return (
            //         <div className="subject-container">
            //             <div className="link" onClick={() => handler(record, "detail")}>{text}</div>
            //         </div>
            //     )
            // },
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: ProjectMetaDataModel) => {
                return record.title.indexOf(value.toString()) >= 0
            },
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.template.fields.title")} />)
            }
        },
        {
            title: translater("project.template.fields.description"),
            dataIndex: 'description',
            key: 'description',
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: ProjectMetaDataModel) => {
                return record.description.indexOf(value.toString()) >= 0 || record.description.indexOf(value.toString()) >= 0
            },
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.template.fields.description")} />)
            }
        },
        {
            title: translater("project.template.fields.version"),
            dataIndex: 'version',
            key: 'version',
            filterSearch: true,
            onFilter: (value: string | number | boolean, record: ProjectMetaDataModel) => {
                return record.version.indexOf(value.toString()) >= 0 || record.version.indexOf(value.toString()) >= 0
            },
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.template.fields.version")} />)
            }
        },
        {
            title: translater("project.template.fields.owner"),
            dataIndex: 'ownerName',
            key: 'ownerName'
        },
        {
            title: translater("project.template.fields.status"),
            dataIndex: 'status',
            key: 'status',

            filterMultiple: true,
            filters: projectStatusList?.map(x => {
                let statusDesc = ""
                if (x.desc) {
                    statusDesc = x.desc[props.i18n.language]
                }
                return { text: statusDesc, value: x.code ?? 0 }
            }) ?? [],
            onFilter(value, record) {
                return record.status.code === value;
            },

            render: (value, record, index) => {
                return record.status.desc[props.i18n.language]
            }
        },
        {
            title: translater("project.template.fields.creator"),
            dataIndex: 'createdByName',
            key: 'createdByName'
        },
        {
            title: translater("project.template.fields.create-time"),
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (val, record, index) => {
                return moment.parseZone(record.createdTime).format("YYYY-MM-DD HH:mm:ss")
            }
        }
    ];
}

export const serviceColumnProvider = (props: WithTranslation, statusList: ProjectServiceInstanceStatusDto[], subscriberList: UserModel[],
    acceptor: UserModel[], handler: ActionEventHandler<any>): ColumnType<any>[] => {
    let translater = props.t;
    return [
        {
            title: translater("project.service.fields.id"),
            dataIndex: 'id',
            key: 'id',
            render: ((value, record, index) => {
                return <div className="subject-container">
                    <div className="link" onClick={() => handler(record, "detail")}>{"#" + record.id}</div>
                </div>
            }),
            filterSearch: true,
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.service.fields.id")} />)
            },
            onFilter: (value, record) => {
                return record.id === Number(value)
            },
            filters: []
        },
        {
            title: translater("project.service.fields.title"),
            dataIndex: 'title',
            key: 'title',
            filterSearch: true,
            filters: [],
            onFilter: (val, record) => {
                return record.title.indexOf(val) >= 0
            },
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.service.fields.title")} />)
            }
        },
        {
            title: translater("project.service.fields.project"),
            dataIndex: 'project',
            key: 'project',
            render: (value, record, index) => {
                return record.project.title + ":" + record.project.version
            },
            onFilter: (value, record) => {
                return (record.project.title + ":" + record.project.version).indexOf(value as string) >= 0
            },
            filters: [],
            filterSearch: true,
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.service.fields.project")} />)
            }
        },
        {
            title: translater("project.service.fields.description"),
            dataIndex: 'description',
            key: 'description',
            filters: [],
            filterSearch: true,
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.service.fields.description")} />)
            },
            onFilter: (value, record) => {
                return record.description.indexOf(value) >= 0
            }
        },
        {
            title: translater("project.service.fields.status"),
            dataIndex: 'status',
            key: 'status',
            render: (value, record, index) => {
                let color = ""
                switch (record.status.code) {
                    case SERVICE_STATUS.APPROVAL_FAILED: {
                        color = "red"
                        break
                    }
                    case SERVICE_STATUS.PROCESSING: {
                        color = "green"
                        break
                    }
                    default: {

                    }
                }
                return <span style={{ color: color }}>{record.status.desc[props.i18n.language]}</span>
            },
            filterMultiple: true,
            filters: statusList.map(d => {
                let status = ""
                if (d.desc) {
                    status = d.desc[props.i18n.language]
                }
                return { text: status, value: d.code ?? -1 }
            }),
            onFilter: (value, record) => {
                return record.status.code === Number(value)
            }
        },
        {
            title: translater("project.service.fields.subscriber"),
            dataIndex: 'subscriber',
            key: 'subscriber',
            render: (value, record, index) => {
                return (
                    <>{record.subscriber.fullName}</>
                )
            },
            filters: subscriberList.map(u => {
                return {
                    text: u.fullName, value: u.id ?? -1
                }
            }),
            onFilter: (value, record) => {
                return record.subscriber.id === value
            }
        },
        {
            title: translater("project.service.fields.acceptor"),
            dataIndex: 'acceptor',
            key: 'acceptor',
            render: (value, record, index) => {
                return (
                    <>{record.acceptor.fullName}</>
                )
            }
        },
        {
            title: translater("project.service.fields.subscribe-time"),
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (value, record, index) => {
                return record.createdTime === "" ? "" : moment.parseZone(record.createdTime).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        {
            title: translater("project.service.fields.start-time"),
            dataIndex: 'startTime',
            key: 'startTime',
            render: (value, record, index) => {
                return record.startTime === "" ? "暂未开始" : moment.parseZone(record.startTime).format("YYYY-MM-DD HH:mm:ss")
            }
        }
    ];
}

export const taskColumnProvider = (props: WithTranslation, statusList: ProjectServiceItemInstanceStatusDto[], executorList: UserModel[], handler: ActionEventHandler<any>): ColumnType<any>[] => {
    let translater = props.t;
    return [
        {
            title: translater("project.task.fields.id"),
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: ProjectMetaDataModel) => {
                return (
                    <div className="subject-container">
                        <div className="link" onClick={() => handler(record, "detail")}>{"#" + record.id}</div>
                    </div>
                )
            },
            filters: [],
            filterSearch: true,
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.task.fields.id")} />)
            },
            onFilter: (value, record) => {
                return record.id === Number(value)
            }
        },
        {
            title: translater("project.task.fields.title"),
            dataIndex: 'title',
            key: 'title',
            filterSearch: true,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.task.fields.title")} />)
            },
            onFilter: (value, record) => {
                return record.title.indexOf(value) >= 0
            }
        },
        {
            title: translater("project.task.fields.type"),
            dataIndex: 'type',
            key: 'type',
            render: (v, r, index) => {
                // FIXME i18n
                return r.type["descZh"]
            }
        },
        {
            title: translater("project.task.fields.description"),
            dataIndex: 'description',
            key: 'description',
            filterSearch: true,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.task.fields.description")} />)
            },
            onFilter: (value, record) => {
                return record.description.indexOf(value) >= 0
            }
        },
        {
            title: translater("project.task.fields.status"),
            dataIndex: 'status',
            key: 'status',
            render: (v, record, index) => {
                return record.status.desc[props.i18n.language]
            },
            filterMultiple: true,
            filters: statusList.map(r => {
                let status = ""
                if (r.desc) {
                    status = r.desc[props.i18n.language]
                }
                return { text: status, value: r.code ?? -1 }
            }),
            onFilter: (value, record) => {
                return record.status.code === value
            }
        },
        {
            title: translater("project.task.fields.executor"),
            dataIndex: 'executor',
            key: 'executor',
            render: (value, record, index) => {
                return record.executor.fullName
            },
            filterMultiple: true,
            filters: executorList.map(u => {
                return { text: u.fullName ?? "", value: u.id ?? -1 }
            }),
            onFilter: ((value, record) => {
                return record.executor.id === value
            })

        }
    ];
}

export const taskMetaDataColumnProvider = (props: WithTranslation, handler: ActionEventHandler<ProjectServiceItemMetaDataDto>): ColumnType<any>[] => {
    let translater = props.t;
    return [
        {
            title: translater("project.template.task.meta.fields.id"),
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: ProjectServiceItemMetaDataDto) => {
                return (
                    <div className="subject-container">
                        <div className="link" onClick={() => handler(record, "detail")}>{"#" + record.id}</div>
                    </div>
                )
            },
            filters: [],
            filterSearch: true,
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.template.task.meta.fields.id")} />)
            },
            onFilter: (value, record) => {
                return record.id === Number(value)
            }
        },
        {
            title: translater("project.template.task.meta.fields.title"),
            dataIndex: 'title',
            key: 'title',
            filterSearch: true,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.template.task.meta.fields.title")} />)
            },
            onFilter: (value, record) => {
                return record.title.indexOf(value) >= 0
            }
        },
        {
            title: translater("project.template.task.meta.fields.description"),
            dataIndex: 'description',
            key: 'description',
            filterSearch: true,
            filters: [],
            filterDropdown(props) {
                return (<ColumnTextFilter context={props} title={translater("project.template.task.meta.fields.description")} />)
            },
            onFilter: (value, record) => {
                return record.description.indexOf(value) >= 0
            }
        },
        {
            title: translater("project.template.task.meta.fields.type"),
            dataIndex: 'type',
            key: 'type',
            render: (v, r, index) => {
                return r.type.desc[props.i18n.language]
            }
        },

        {
            title: translater("project.template.task.meta.fields.owner"),
            dataIndex: 'defaultOwnerName',
            key: 'defaultOwnerName',
            render: (v, r: ProjectServiceItemMetaDataDto, index) => {
                return r.defaultOwnerName ? r.defaultOwnerName : translater("project.template.task.meta.undefined")
            }
        },
        {
            title: translater("project.template.task.meta.fields.executor"),
            dataIndex: 'defaultExecutorName',
            key: 'defaultExecutorName',
            render: (v, r: ProjectServiceItemMetaDataDto, index) => {
                return r.defaultExecutorName
            }
        },
        {
            title: translater("project.template.task.meta.fields.lowerEstimatedTime"),
            dataIndex: 'timeEstimationLowerLimit',
            key: 'timeEstimationLowerLimit',
            render: (v, r: ProjectServiceItemMetaDataDto, index) => {
                if (r.timeEstimationLowerLimit) {
                    return r.timeEstimationLowerLimit + translater("project.template.task.meta.hour")
                }
                return translater("project.template.task.meta.undefined")
            }
        },
        {
            title: translater("project.template.task.meta.fields.higherEstimatedTime"),
            dataIndex: 'timeEstimationHigherLimit',
            key: 'timeEstimationHigherLimit',
            render: (v, r: ProjectServiceItemMetaDataDto, index) => {
                if (r.timeEstimationHigherLimit) {
                    return r.timeEstimationHigherLimit + translater("project.template.task.meta.hour")
                }
                return translater("project.template.task.meta.undefined")
            }
        },
        {
            title: translater("project.template.task.meta.fields.createdTime"),
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (v, r: ProjectServiceItemMetaDataDto, index) => {
                return moment.parseZone(r.createdTime).format("YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            title: translater("project.template.task.meta.operation"),
            dataIndex: 'createdTime',
            key: 'createdTime',
            render: (v, r: ProjectServiceItemMetaDataDto, index) => {
                return (
                    <>
                        {/* TODO Conflict with the template editor */}
                        {/* <Popover content={translater("project.template.task.meta.edit")} mouseLeaveDelay={0}>
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                                style={{ marginRight: "8px" }}
                                onClick={() => handler(r, "edit", index)}
                            />
                        </Popover> */}
                        <Popover content={translater("project.template.task.meta.upload-knowledge")} mouseLeaveDelay={0}>
                            <Button
                                shape="circle"
                                icon={<CloudUploadOutlined />}
                                onClick={() => handler(r, "upload-knowledge", index)}
                            />
                        </Popover>
                    </>
                )
            }
        },
    ];
}


export const taskBatchOperationColumnProvider = (props: WithTranslation, handler: ActionEventHandler<any>): ColumnType<any>[] => {
    let translater = props.t
    return [
        {
            title: translater("project.template.task.meta.fields.title"),
            dataIndex: 'title',
            key: 'title',
            render: (text: string, record: any) => {
                return record.title
            }
        },
        {
            title: translater("project.template.task.meta.fields.description"),
            dataIndex: 'description',
            key: 'description',
            render: (text: string, record: any) => {
                return record.description
            }
        },
        {
            title: translater("project.template.task.meta.fields.type"),
            dataIndex: 'type',
            key: 'type',
            render: (text: string, record: any) => {
                return translater("project.template.task.types." + record.type.toLowerCase())
            }
        },
        {
            title: translater("project.template.task.meta.fields.executor"),
            dataIndex: 'defaultExecutor',
            key: 'defaultExecutor',
            render: (text: string, record: any) => {
                return record.defaultExecutorName ?? record.defaultExecutor
            }
        },
        {
            title: translater("project.template.task.meta.fields.lowerEstimatedTime"),
            dataIndex: 'lowerEstimatedTime',
            key: 'lowerEstimatedTime',
            render: (text: string, record: any) => {
                return record.timeEstimationLowerLimit ? record.timeEstimationLowerLimit + translater("project.template.task.hour")
                    : translater("services.estimated-undefined")
            }
        },
        {
            title: translater("project.template.task.meta.fields.higherEstimatedTime"),
            dataIndex: 'higherEstimatedTime',
            key: 'higherEstimatedTime',
            render: (text: string, record: any) => {
                return record.timeEstimationHigherLimit ? record.timeEstimationHigherLimit + translater("project.template.task.hour")
                    : translater("services.estimated-undefined")
            }
        }
    ]

}

export const SERVICE_STATUS = {
    DRAFT: 0,
    UNDER_APPROVAL: 1,
    APPROVED: 2,
    APPROVAL_FAILED: 3,
    PROCESSING: 4,
    PENDING_ACCEPTANCE: 5,
    WAITING_FOR_WHETHER_REDO: 6,
    REDOING: 7,
    FINISHED: 8,
    TERMINATED: 9,
    WAITING: 10,


    isUnfinished: (statusCode?: number): boolean => {
        return statusCode === SERVICE_STATUS.PROCESSING
            || statusCode === SERVICE_STATUS.REDOING
            || statusCode === SERVICE_STATUS.WAITING
            || statusCode === SERVICE_STATUS.WAITING_FOR_WHETHER_REDO
    }
}

export const TASK_TYPES = {
    102: {
        code: 102,
        value: "serial_milestone",
        needUpload: false
    },
    103: {
        code: 103,
        value: "parallel_milestone",
        needUpload: false
    },
    1001: {
        code: 1001,
        value: "serial_task",
        needUpload: false
    },
    1002: {
        code: 1002,
        value: "parallel_task",
        needUpload: false
    },
    1003: {
        code: 1003,
        value: "file",
        needUpload: true
    },
    1004: {
        code: 1004,
        value: "ticket",
        needUpload: false
    },
    1005: {
        code: 1005,
        value: "meeting",
        needUpload: true
    },
    1006: {
        code: 1006,
        value: "form",
        needUpload: false
    },
    1007: {
        code: 1007,
        value: "process",
        needUpload: false
    },
}

function mapType(fType: string) {
    switch (fType.toUpperCase()) {
        case "UPLOAD":
            return "file"
        case "TICKET":
            return "ticket"
        case "MEETING":
            return "meeting"
        case "FORM":
            return "form"
        case "FILE":
            return "file"
        default:
            return ""
    }
}