import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import React from "react";
import '../../../../../components'
import { ActionEventHandler } from "../../../../../components";
import ColumnTextFilter from "../../../../../components/table/column-text-filter";

export interface UserGroupModel {
	id: number,
	name: string
	description: string
	createdTime: string
	updatedTime: string
	userCount:number,
	key:number
}



export const columnsProvider = (translater: ((d: string) => string),handler:ActionEventHandler<UserGroupModel>): ColumnType<UserGroupModel>[] => {

	return [
		{
			title: translater("user-group-editor.name"),
			dataIndex: 'name',
			key: 'name',
			render: (text: string,record:UserGroupModel) => <Button type="link" onClick={()=>handler(record,"detail")}>{text}</Button>,
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserGroupModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-group-editor.name")}/>)
			},
			
		},
		{
			title: translater("user-group-editor.desc"),
			dataIndex: 'description',
			key: 'description',
			filterSearch: true,
			onFilter: (value:string|number|boolean, record:UserGroupModel) => record.name.indexOf(value.toString())>=0,
			filters:[],
			filterDropdown(props) {
				return (<ColumnTextFilter context={props} title={translater("user-group-editor.desc")}/>)
			},
		},
		{
			title: translater("user-group-editor.user-count"),
			dataIndex: 'userCount',
			key: 'userCount'
		},
		{
			title: translater("user-group-editor.createdTime"),
			dataIndex: 'createdTime',
			key: 'createdTime',
			render:(value:any,record:UserGroupModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
		},{
			title: translater("user-group-editor.updatedTime"),
			dataIndex: 'updatedTime',
			key: 'updatedTime',
			render:(value:any,record:UserGroupModel,index:number)=>{
				return (moment.parseZone(value).format(translater("time-pattern-long")))
			}
		},
	]
}