import { Menu } from "antd";
import React, { ReactNode } from "react";



export interface LanguageModel {
	languageName:string,
	languageCode:string,
	flag:ReactNode
}	

export interface LanguageMenuState{
	selectedLanguage:string
}

export interface LanguageMenuModel {
	onLanguageChanged?:(l:string)=>(void),
	languageList: LanguageModel[],
}

class LanguageMenu extends React.Component<LanguageMenuModel,LanguageMenuState>{

	constructor(props:LanguageMenuModel){
		super(props)
		this.state={
			selectedLanguage: localStorage.getItem("lang")?? "cn"
		}
	}

	menuItemClick = (dat:any) =>{
		if(this.props.onLanguageChanged){
			this.props.onLanguageChanged(dat.key);
		}
		this.setState({
			selectedLanguage : dat.key
		})
	}

	getMenuItems = () =>{

		return this.props.languageList.map(x=>{
			return {
				label:x.languageName+"    ",
				itemIcon:x.flag,
				key:x.languageCode
			}
/*			console.log(x);
			return(
				<MenuItem key={x.languageCode}>
					{x.flag} {x.languageName}
				</MenuItem>x
			)*/
		})
	}

	render(): React.ReactNode {
		return (<>
			<Menu onClick={this.menuItemClick} 
				selectedKeys={[this.state.selectedLanguage]}
				items={this.getMenuItems()}
			>
			
			</Menu>
		</>)
	}
}

export default LanguageMenu;